// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/FilterCategory.ts"
);
import.meta.hot.lastModified = "1737935753979.8225";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Filter category.
 */
export enum FilterCategory {
  TEXT = 'text',
  NUMERIC = 'numeric',
  DATE = 'date',
  DATETIME = 'datetime',
  BOOLEAN = 'boolean',
  UNKNOWN = 'unknown',
}
