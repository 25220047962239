// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRefreshSnowflakeConnection.ts"
);
import.meta.hot.lastModified = "1737935769208.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RefreshSnowflakeConnectionMutationResponse,
  RefreshSnowflakeConnectionPathParams,
  RefreshSnowflakeConnection422,
} from '../types/RefreshSnowflakeConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const refreshSnowflakeConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/snowflake/refresh' }] as const

export type RefreshSnowflakeConnectionMutationKey = ReturnType<typeof refreshSnowflakeConnectionMutationKey>

/**
 * @description Create external functions for all snowflake connections.
 * @summary Refresh Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/refresh}
 */
async function refreshSnowflakeConnection(
  { accountName }: { accountName: RefreshSnowflakeConnectionPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RefreshSnowflakeConnectionMutationResponse, RefreshSnowflakeConnection422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/refresh`,
    ...config,
  })
  return res.data
}

/**
 * @description Create external functions for all snowflake connections.
 * @summary Refresh Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/refresh}
 */
export function useRefreshSnowflakeConnection(
  options: {
    mutation?: UseMutationOptions<
      RefreshSnowflakeConnectionMutationResponse,
      RefreshSnowflakeConnection422,
      { accountName: RefreshSnowflakeConnectionPathParams['account_name'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? refreshSnowflakeConnectionMutationKey()

  return useMutation<
    RefreshSnowflakeConnectionMutationResponse,
    RefreshSnowflakeConnection422,
    { accountName: RefreshSnowflakeConnectionPathParams['account_name'] }
  >({
    mutationFn: async ({ accountName }) => {
      return refreshSnowflakeConnection({ accountName }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}