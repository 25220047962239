// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteDimension.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteDimensionMutationResponse, DeleteDimensionPathParams, DeleteDimension422 } from '../types/DeleteDimension'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteDimensionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}' }] as const

export type DeleteDimensionMutationKey = ReturnType<typeof deleteDimensionMutationKey>

/**
 * @description Remove dimension from database.Note: This endpoint is for simple deletion of a dimension. It will not check for dimension usage andhandle the full flow for dimension deletion (invalidating examples and derived dms that use this dimension).Use with caution, delete only when you are sure the dimension is not needed.
 * @summary Delete Dimension
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id}
 */
async function deleteDimension(
  { accountName, dimensionId }: { accountName: DeleteDimensionPathParams['account_name']; dimensionId: DeleteDimensionPathParams['dimension_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteDimensionMutationResponse, DeleteDimension422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions/${dimensionId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Remove dimension from database.Note: This endpoint is for simple deletion of a dimension. It will not check for dimension usage andhandle the full flow for dimension deletion (invalidating examples and derived dms that use this dimension).Use with caution, delete only when you are sure the dimension is not needed.
 * @summary Delete Dimension
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id}
 */
export function useDeleteDimension(
  options: {
    mutation?: UseMutationOptions<
      DeleteDimensionMutationResponse,
      DeleteDimension422,
      { accountName: DeleteDimensionPathParams['account_name']; dimensionId: DeleteDimensionPathParams['dimension_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteDimensionMutationKey()

  return useMutation<
    DeleteDimensionMutationResponse,
    DeleteDimension422,
    { accountName: DeleteDimensionPathParams['account_name']; dimensionId: DeleteDimensionPathParams['dimension_id'] }
  >({
    mutationFn: async ({ accountName, dimensionId }) => {
      return deleteDimension({ accountName, dimensionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}