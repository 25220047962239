// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateWidgetSchema.ts"
);
import.meta.hot.lastModified = "1737759758961.0327";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { widgetApiUpdateRequestSchema } from './widgetApiUpdateRequestSchema'
import { widgetApiUpdateResponseSchema } from './widgetApiUpdateResponseSchema'
import { z } from 'zod'

export const updateWidgetPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  widget_id: z.string(),
})

/**
 * @description Successful Response
 */
export const updateWidget200Schema = z.lazy(() => widgetApiUpdateResponseSchema)

/**
 * @description Validation Error
 */
export const updateWidget422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateWidgetMutationRequestSchema = z.lazy(() => widgetApiUpdateRequestSchema)

export const updateWidgetMutationResponseSchema = z.lazy(() => updateWidget200Schema)