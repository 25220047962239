// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tagApiCreateSchema.ts"
);
import.meta.hot.lastModified = "1737759758958.0327";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Create Tag Schema DTO for API.
 */
export const tagApiCreateSchema = z
  .object({
    name: z.union([z.string(), z.null()]).optional(),
    tag_id: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Create Tag Schema DTO for API.')