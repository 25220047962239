// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createAccountSchema.ts"
);
import.meta.hot.lastModified = "1737759759189.0317";
}
// REMIX HMR END

// @ts-nocheck
import { accountCreateSchema } from './accountCreateSchema'
import { accountSchema } from './accountSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const createAccountQueryParamsSchema = z
  .object({
    preload_demo_dataset: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const createAccount201Schema = z.lazy(() => accountSchema)

/**
 * @description Validation Error
 */
export const createAccount422Schema = z.lazy(() => httpValidationErrorSchema)

export const createAccountMutationRequestSchema = z.lazy(() => accountCreateSchema)

export const createAccountMutationResponseSchema = z.lazy(() => createAccount201Schema)