// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/widgetApiCreateResponseSchema.ts"
);
import.meta.hot.lastModified = "1737759761631.019";
}
// REMIX HMR END

// @ts-nocheck
import { widgetOptionsSchema } from './widgetOptionsSchema'
import { z } from 'zod'

/**
 * @description Properties to receive via API on creation.
 */
export const widgetApiCreateResponseSchema = z
  .object({
    id: z.string(),
    account_id: z.string(),
    options: z.lazy(() => widgetOptionsSchema),
    key: z.union([z.string(), z.null()]),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
  })
  .describe('Properties to receive via API on creation.')