// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/connectionStatusSchema.ts"
);
import.meta.hot.lastModified = "1737935767112.7502";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Enumerates the possible connection statuses.
 */
export const connectionStatusSchema = z.enum(['success', 'failure']).describe('Enumerates the possible connection statuses.')