// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteAccountTag.ts"
);
import.meta.hot.lastModified = "1737935767035.7507";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteAccountTagMutationResponse, DeleteAccountTagPathParams, DeleteAccountTag422 } from '../types/DeleteAccountTag'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteAccountTagMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/tags/{tag_id}' }] as const

export type DeleteAccountTagMutationKey = ReturnType<typeof deleteAccountTagMutationKey>

/**
 * @description Delete a tag for an account.
 * @summary Delete Account Tag
 * {@link /api/v1/accounts/:account_name/tags/:tag_id}
 */
async function deleteAccountTag(
  { tagId, accountName }: { tagId: DeleteAccountTagPathParams['tag_id']; accountName: DeleteAccountTagPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteAccountTagMutationResponse, DeleteAccountTag422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/tags/${tagId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete a tag for an account.
 * @summary Delete Account Tag
 * {@link /api/v1/accounts/:account_name/tags/:tag_id}
 */
export function useDeleteAccountTag(
  options: {
    mutation?: UseMutationOptions<
      DeleteAccountTagMutationResponse,
      DeleteAccountTag422,
      { tagId: DeleteAccountTagPathParams['tag_id']; accountName: DeleteAccountTagPathParams['account_name'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteAccountTagMutationKey()

  return useMutation<
    DeleteAccountTagMutationResponse,
    DeleteAccountTag422,
    { tagId: DeleteAccountTagPathParams['tag_id']; accountName: DeleteAccountTagPathParams['account_name'] }
  >({
    mutationFn: async ({ tagId, accountName }) => {
      return deleteAccountTag({ tagId, accountName }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}