// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readEnterprisesSchema.ts"
);
import.meta.hot.lastModified = "1737759759803.0286";
}
// REMIX HMR END

// @ts-nocheck
import { enterpriseSchema } from './enterpriseSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readEnterprisesQueryParamsSchema = z
  .object({
    skip: z.number().int().default(0),
    limit: z.number().int().default(100),
    sort_by: z.enum(['name', 'created_at', 'updated_at']).default('name'),
    sort_ascending: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readEnterprises200Schema = z.array(z.lazy(() => enterpriseSchema))

/**
 * @description Validation Error
 */
export const readEnterprises422Schema = z.lazy(() => httpValidationErrorSchema)

export const readEnterprisesQueryResponseSchema = z.lazy(() => readEnterprises200Schema)