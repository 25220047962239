// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/MostCommonValueColumnStatistics.ts"
);
import.meta.hot.lastModified = "1737759762281.0156";
}
// REMIX HMR END

import type { ColumnValue } from './ColumnValue'

export enum MostCommonValueColumnStatisticsStatType {
  'most_common_value' = 'most_common_value',
}

/**
 * @description Common column statistics.
 */
export type MostCommonValueColumnStatistics = {
  /**
   * @type string
   */
  name: string
  /**
   * @type string
   */
  type: string
  /**
   * @type integer
   */
  count: number
  /**
   * @type integer
   */
  num_nulls: number
  num_distinct_values?: number | null
  /**
   * @default "most_common_value"
   * @type string
   */
  stat_type: MostCommonValueColumnStatisticsStatType
  /**
   * @type array
   */
  most_common_values: ColumnValue[]
}