// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/loginSessionSchema.ts"
);
import.meta.hot.lastModified = "1737759758958.0327";
}
// REMIX HMR END

// @ts-nocheck
import { bodyLoginSessionApiV1LoginSessionPostSchema } from './bodyLoginSessionApiV1LoginSessionPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const loginSessionQueryParamsSchema = z
  .object({
    third_party: z.boolean().default(false).describe('Whether to set a third-party cookie for cross-origin iframes.'),
    slack_id: z
      .union([z.string(), z.null()])
      .describe('An optional base64-encoded Slack {team_id}:{user_id} to link to (for use by the NS Slack App).')
      .optional(),
    account_name: z
      .union([z.string(), z.null()])
      .describe(
        'The name of the account to validate that the user is authorized to access. If the user does not have access to this account, the endpoint will return a 403 even if the user is successfully authenticated. This is useful for third party applications which want users to be restricted to a particular account.',
      )
      .optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const loginSession200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const loginSession422Schema = z.lazy(() => httpValidationErrorSchema)

export const loginSessionMutationRequestSchema = z.lazy(() => bodyLoginSessionApiV1LoginSessionPostSchema)

export const loginSessionMutationResponseSchema = z.lazy(() => loginSession200Schema)