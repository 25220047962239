// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/logLevelSchema.ts"
);
import.meta.hot.lastModified = "1737935765096.7612";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Enumerates the possible log levels.
 */
export const logLevelSchema = z.enum(['info', 'warning', 'exception']).describe('Enumerates the possible log levels.')