// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/CellRunEventService.ts"
);
import.meta.hot.lastModified = "1737759746328.098";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CellRunEvent } from '../models/CellRunEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CellRunEventService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read Cell Run Events By Cell
   * Read all cell run events by cell id.
   * @param cellId
   * @param accountName
   * @param sessionId
   * @returns CellRunEvent Successful Response
   * @throws ApiError
   */
  public readCellRunEventsByCell(
    cellId: string,
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<CellRunEvent>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/cell_run_events/cell/{cell_id}',
      path: {
        'cell_id': cellId,
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Cell Run Event By Run Id
   * Read cell run event by run id.
   * @param runId
   * @param accountName
   * @param sessionId
   * @returns CellRunEvent Successful Response
   * @throws ApiError
   */
  public readCellRunEventByRunId(
    runId: string,
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<CellRunEvent> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/cell_run_events/run/{run_id}',
      path: {
        'run_id': runId,
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
