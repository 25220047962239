// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bulkCreateConversationsSchema.ts"
);
import.meta.hot.lastModified = "1737935768797.741";
}
// REMIX HMR END

// @ts-nocheck
import { conversationBasicSchema } from './conversationBasicSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const bulkCreateConversationsPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const bulkCreateConversations201Schema = z.array(z.lazy(() => conversationBasicSchema))

/**
 * @description Validation Error
 */
export const bulkCreateConversations422Schema = z.lazy(() => httpValidationErrorSchema)

export const bulkCreateConversationsMutationRequestSchema = z.array(z.lazy(() => conversationBasicSchema))

export const bulkCreateConversationsMutationResponseSchema = z.lazy(() => bulkCreateConversations201Schema)