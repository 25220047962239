// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/promptLogComponentSchema.ts"
);
import.meta.hot.lastModified = "1737935767878.746";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Component of BB being logged.
 */
export const promptLogComponentSchema = z.enum(['QUERY', 'MOONLIGHT', 'REPHRASE', 'ALL', 'MOONLIGHT_REPHRASE']).describe('Component of BB being logged.')