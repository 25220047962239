// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useMergeMetrics.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { MergeMetricsMutationRequest, MergeMetricsMutationResponse, MergeMetricsPathParams, MergeMetrics422 } from '../types/MergeMetrics'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const mergeMetricsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/merge' }] as const

export type MergeMetricsMutationKey = ReturnType<typeof mergeMetricsMutationKey>

/**
 * @description Merge multiple metrics into one metric.This endpoint lets users merge multiple (similar) metrics (ids_to_merge) into one metric(metric) to maintain SSDS better. Users can also update the metric using metric_update.
 * @summary Merge Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/merge}
 */
async function mergeMetrics(
  {
    accountName,
    metricId,
    data,
  }: { accountName: MergeMetricsPathParams['account_name']; metricId: MergeMetricsPathParams['metric_id']; data: MergeMetricsMutationRequest },
  config: Partial<RequestConfig<MergeMetricsMutationRequest>> = {},
) {
  const res = await client<MergeMetricsMutationResponse, MergeMetrics422, MergeMetricsMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/${metricId}/merge`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Merge multiple metrics into one metric.This endpoint lets users merge multiple (similar) metrics (ids_to_merge) into one metric(metric) to maintain SSDS better. Users can also update the metric using metric_update.
 * @summary Merge Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/merge}
 */
export function useMergeMetrics(
  options: {
    mutation?: UseMutationOptions<
      MergeMetricsMutationResponse,
      MergeMetrics422,
      { accountName: MergeMetricsPathParams['account_name']; metricId: MergeMetricsPathParams['metric_id']; data: MergeMetricsMutationRequest }
    >
    client?: Partial<RequestConfig<MergeMetricsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? mergeMetricsMutationKey()

  return useMutation<
    MergeMetricsMutationResponse,
    MergeMetrics422,
    { accountName: MergeMetricsPathParams['account_name']; metricId: MergeMetricsPathParams['metric_id']; data: MergeMetricsMutationRequest }
  >({
    mutationFn: async ({ accountName, metricId, data }) => {
      return mergeMetrics({ accountName, metricId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}