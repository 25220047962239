// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/userRoleSchema.ts"
);
import.meta.hot.lastModified = "1737935765099.7612";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Roles that a user can have.
 */
export const userRoleSchema = z.enum(['USER', 'ADMIN']).describe('Roles that a user can have.')