// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readConnectionsSchema.ts"
);
import.meta.hot.lastModified = "1737935766771.7522";
}
// REMIX HMR END

// @ts-nocheck
import { connectionPageSchema } from './connectionPageSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readConnectionsPathParamsSchema = z.object({
  account_name: z.string(),
})

export const readConnectionsQueryParamsSchema = z
  .object({
    skip: z.number().int().default(0),
    limit: z.number().int().default(100),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readConnections200Schema = z.lazy(() => connectionPageSchema)

/**
 * @description Validation Error
 */
export const readConnections422Schema = z.lazy(() => httpValidationErrorSchema)

export const readConnectionsQueryResponseSchema = z.lazy(() => readConnections200Schema)