// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/semanticLayerViewSchema.ts"
);
import.meta.hot.lastModified = "1737759760436.0251";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionFilterSchema } from './dimensionFilterSchema'
import { dimensionViewSchema } from './dimensionViewSchema'
import { metricFilterSchema } from './metricFilterSchema'
import { metricViewSchema } from './metricViewSchema'
import { z } from 'zod'

/**
 * @description Semantic Query as Metrics/Dimensions in Semantic Layer.\n\nUsed for frontend display of Semantic Layer.
 */
export const semanticLayerViewSchema = z
  .object({
    dimensions: z.array(z.lazy(() => dimensionViewSchema)),
    metrics: z.array(z.lazy(() => metricViewSchema)),
    filters: z.array(z.union([z.lazy(() => metricFilterSchema), z.lazy(() => dimensionFilterSchema)])).optional(),
    order: z.array(z.union([z.lazy(() => dimensionViewSchema), z.lazy(() => metricViewSchema)])),
    limit: z.union([z.number().int(), z.null()]),
  })
  .describe('Semantic Query as Metrics/Dimensions in Semantic Layer.\n\nUsed for frontend display of Semantic Layer.')