// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ReadEnterprises.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import type { Enterprise } from './Enterprise'
import type { HttpValidationError } from './HttpValidationError'

export enum ReadEnterprisesQueryParamsSortBy {
  'name' = 'name',
  'created_at' = 'created_at',
  'updated_at' = 'updated_at',
}

export type ReadEnterprisesQueryParams = {
  /**
   * @default 0
   * @type integer | undefined
   */
  skip?: number
  /**
   * @default 100
   * @type integer | undefined
   */
  limit?: number
  /**
   * @default "name"
   * @type string | undefined
   */
  sort_by?: ReadEnterprisesQueryParamsSortBy
  /**
   * @default true
   * @type boolean | undefined
   */
  sort_ascending?: boolean
}

/**
 * @description Successful Response
 */
export type ReadEnterprises200 = Enterprise[]

/**
 * @description Validation Error
 */
export type ReadEnterprises422 = HttpValidationError

export type ReadEnterprisesQueryResponse = ReadEnterprises200

export type ReadEnterprisesQuery = {
  Response: ReadEnterprises200
  QueryParams: ReadEnterprisesQueryParams
  Errors: ReadEnterprises422
}