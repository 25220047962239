// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/starSchemaDataSourceSchema.ts"
);
import.meta.hot.lastModified = "1737759761028.0222";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetVisibilitySchema } from './dataAssetVisibilitySchema'
import { dataSourceSchema } from './dataSourceSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

/**
 * @description StarSchemaDataSource Schema in DB.
 */
export const starSchemaDataSourceSchema = z
  .object({
    sync_id: z.union([z.string(), z.null()]).optional(),
    name: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    visibility: z.lazy(() => dataAssetVisibilitySchema).default('PUBLISHED'),
    data_sources: z.array(z.lazy(() => dataSourceSchema)),
    num_data_source_created: z.number().int().default(0),
    id: z.string(),
    account_id: z.string(),
    creator_id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    connection_id: z.string(),
    is_demo: z.boolean(),
    tasks: z.array(z.lazy(() => taskSchema)).optional(),
    is_draft: z.union([z.boolean(), z.null()]).optional(),
    is_ready: z.union([z.boolean(), z.null()]).optional(),
  })
  .describe('StarSchemaDataSource Schema in DB.')