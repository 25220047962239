// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUnstarConversation.ts"
);
import.meta.hot.lastModified = "1737935767642.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UnstarConversationMutationResponse, UnstarConversationPathParams, UnstarConversation422 } from '../types/UnstarConversation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const unstarConversationMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/stars' }] as const

export type UnstarConversationMutationKey = ReturnType<typeof unstarConversationMutationKey>

/**
 * @description Unstar a bumblebee conversation.
 * @summary Unstar Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/stars}
 */
async function unstarConversation(
  {
    accountName,
    conversationId,
  }: { accountName: UnstarConversationPathParams['account_name']; conversationId: UnstarConversationPathParams['conversation_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UnstarConversationMutationResponse, UnstarConversation422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/stars`,
    ...config,
  })
  return res.data
}

/**
 * @description Unstar a bumblebee conversation.
 * @summary Unstar Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/stars}
 */
export function useUnstarConversation(
  options: {
    mutation?: UseMutationOptions<
      UnstarConversationMutationResponse,
      UnstarConversation422,
      { accountName: UnstarConversationPathParams['account_name']; conversationId: UnstarConversationPathParams['conversation_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? unstarConversationMutationKey()

  return useMutation<
    UnstarConversationMutationResponse,
    UnstarConversation422,
    { accountName: UnstarConversationPathParams['account_name']; conversationId: UnstarConversationPathParams['conversation_id'] }
  >({
    mutationFn: async ({ accountName, conversationId }) => {
      return unstarConversation({ accountName, conversationId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}