// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/DateTimeColumnStatistics.ts"
);
import.meta.hot.lastModified = "1737935768206.7444";
}
// REMIX HMR END

import type { ColumnValue } from './ColumnValue'
import type { HistogramBucketStr } from './HistogramBucketStr'

export enum DateTimeColumnStatisticsStatType {
  'datetime' = 'datetime',
}

/**
 * @description Statistics for datetime/timestamp columns.
 */
export type DateTimeColumnStatistics = {
  /**
   * @type string
   */
  name: string
  /**
   * @type string
   */
  type: string
  /**
   * @type integer
   */
  count: number
  /**
   * @type integer
   */
  num_nulls: number
  num_distinct_values?: number | null
  /**
   * @default "datetime"
   * @type string
   */
  stat_type: DateTimeColumnStatisticsStatType
  min_datetime?: string | null
  max_datetime?: string | null
  histogram?: HistogramBucketStr[] | null
  /**
   * @type array
   */
  most_common_values: ColumnValue[]
}