// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/NumericColumnStatistics.ts"
);
import.meta.hot.lastModified = "1737935768071.745";
}
// REMIX HMR END

import type { ColumnValue } from './ColumnValue'
import type { HistogramBucketFloat } from './HistogramBucketFloat'

export enum NumericColumnStatisticsStatType {
  'numeric' = 'numeric',
}

/**
 * @description Statistics for numeric columns.
 */
export type NumericColumnStatistics = {
  /**
   * @type string
   */
  name: string
  /**
   * @type string
   */
  type: string
  /**
   * @type integer
   */
  count: number
  /**
   * @type integer
   */
  num_nulls: number
  num_distinct_values?: number | null
  /**
   * @default "numeric"
   * @type string
   */
  stat_type: NumericColumnStatisticsStatType
  num_nans?: number | null
  /**
   * @type integer
   */
  num_zeros: number
  min?: number | null
  mean?: number | null
  max?: number | null
  sum?: number | null
  histogram?: HistogramBucketFloat[] | null
  /**
   * @type array
   */
  most_common_values: ColumnValue[]
}