// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/DatasetsService.ts"
);
import.meta.hot.lastModified = "1737935753873.823";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_dataset_api_v1_accounts__account_name__datasets_upload_post } from '../models/Body_upload_dataset_api_v1_accounts__account_name__datasets_upload_post';
import type { BumblebeeDataset } from '../models/BumblebeeDataset';
import type { ConversationCellFilter } from '../models/ConversationCellFilter';
import type { DatasetsResponse } from '../models/DatasetsResponse';
import type { DMFilter } from '../models/DMFilter';
import type { StarSchemaDataSourceSort } from '../models/StarSchemaDataSourceSort';
import type { StarSchemaDataSourceTableOrViewInfo } from '../models/StarSchemaDataSourceTableOrViewInfo';
import type { Task } from '../models/Task';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DatasetsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read All Datasets
   * Read all datasets.
   *
   * This includes both star schema data sources and reports.
   * @param accountName
   * @param sortAscending
   * @param sortBy
   * @param limit
   * @param offset
   * @param search
   * @param sessionId
   * @returns DatasetsResponse Successful Response
   * @throws ApiError
   */
  public readAllDatasets(
    accountName: string,
    sortAscending: boolean = false,
    sortBy?: (StarSchemaDataSourceSort | null),
    limit?: (number | null),
    offset?: (number | null),
    search?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<DatasetsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/datasets/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'sort_ascending': sortAscending,
        'sort_by': sortBy,
        'limit': limit,
        'offset': offset,
        'search': search,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Dataset
   * Read Dataset.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param fullDataset
   * @param dmFilter
   * @param conversationCellFilter
   * @param sessionId
   * @returns BumblebeeDataset Successful Response
   * @throws ApiError
   */
  public readDataset(
    accountName: string,
    starSchemaDataSourceId: string,
    fullDataset: boolean = true,
    dmFilter?: DMFilter,
    conversationCellFilter?: ConversationCellFilter,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeDataset> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/datasets/{star_schema_data_source_id}',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'full_dataset': fullDataset,
        'dm_filter': dmFilter,
        'conversation_cell_filter': conversationCellFilter,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Demo Dataset
   * Add a demo dataset to the account
   * @param accountName
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public addDemoDataset(
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/datasets/add_demo_dataset',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Upload Dataset
   * Add a dataset from a JSON file.
   *
   * The user must both be an account admin and eval maintainer to upload the dataset.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public uploadDataset(
    accountName: string,
    requestBody: Body_upload_dataset_api_v1_accounts__account_name__datasets_upload_post,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/datasets/upload',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Copy Dataset
   * Copy dataset.
   *
   * [TODO]: remove this endpoint and only use enterprise copy
   *
   * This endpoint lets users create a copy of a dataset in another account. You can specify the
   * destination account name (dest_account_name). The users need to be the admin for both accounts.
   * You can copy to a different account you manage, or even the same account. When copying, you have
   * the option to update the details, including the SSDS name, connection, and data source
   * definition (data source id in the data source definition must be retained).
   *
   * Example:
   *
   * Given the original SSDS definition:
   * ```
   * tables_and_views = [
     * StarSchemaDataSourceTableOrViewInfo(
       * data_source_id="592a2851-f849-4dab-a4ca-614e8709dc8d",
       * name="MAKANA_WAITTIME_1",
       * db="CI_FOR_PYTEST",
       * db_schema="PYTEST",
       * table="MAKANA_WAITTIME_1",
       * )
       * ]
       * new_account_name = "new_account"
       * new_connection_id = uuid.uuid4()
       * new_ssds_name = "MAKANA_WAITTIMES (pytest) - copy from another account"
       * r = client.post(
         * f"{settings.API_V1_STR}/accounts/{account.name}/datasets/{ssds_id}/copy",
         * headers=user_header,
         * params={
           * "dest_account_name": new_account_name,
           * "dest_connection_id": new_connection_id,
           * "dest_ssds_name": new_ssds_name,
           * },
           * json=jsonable_encoder(tables_and_views),
           * )
           * @param accountName
           * @param starSchemaDataSourceId
           * @param destAccountName
           * @param destConnectionId
           * @param destSsdsName
           * @param conversationCellFilter
           * @param overwriteUserQuestionForSensitiveCell
           * @param onlyCopyConversations
           * @param sessionId
           * @param requestBody
           * @returns Task Successful Response
           * @throws ApiError
           */
          public copyDataset(
            accountName: string,
            starSchemaDataSourceId: string,
            destAccountName?: (string | null),
            destConnectionId?: (string | null),
            destSsdsName?: (string | null),
            conversationCellFilter?: ConversationCellFilter,
            overwriteUserQuestionForSensitiveCell: boolean = false,
            onlyCopyConversations: boolean = false,
            sessionId?: (string | null),
            requestBody?: (Array<StarSchemaDataSourceTableOrViewInfo> | null),
          ): CancelablePromise<Task> {
            return this.httpRequest.request({
              method: 'POST',
              url: '/api/v1/accounts/{account_name}/datasets/{star_schema_data_source_id}/copy',
              path: {
                'account_name': accountName,
                'star_schema_data_source_id': starSchemaDataSourceId,
              },
              cookies: {
                'session_id': sessionId,
              },
              query: {
                'dest_account_name': destAccountName,
                'dest_connection_id': destConnectionId,
                'dest_ssds_name': destSsdsName,
                'conversation_cell_filter': conversationCellFilter,
                'overwrite_user_question_for_sensitive_cell': overwriteUserQuestionForSensitiveCell,
                'only_copy_conversations': onlyCopyConversations,
              },
              body: requestBody,
              mediaType: 'application/json',
              errors: {
                422: `Validation Error`,
              },
            });
          }

          /**
           * Update Dataset
           * Update dataset.
           *
           * This endpoint lets users push *new* knowledge from baseline dataset to derived dataset, including
           * SSDS (dimensions, metrics, and named filters), conversations, cells, semantic queries, and feedback.
           *
           * For SSDS, we do incremental update including adding new dimensions, metrics, named filters (DMFs), and
           * align unaligned DMFs that are identical. We have users review any conflicts in aligned DMFs.
           * For conversations, cells, semantic queries, and feedback, we only copy the newly created conversations
           * and associated cells, semantic queries, and feedback. We have user review existing conversations
           * if there are any conflicts.
           * @param accountName
           * @param starSchemaDataSourceId
           * @param destSsdsId
           * @param conversationCellFilter
           * @param overwriteUserQuestionForSensitiveCell
           * @param onlyCopyConversations
           * @param sessionId
           * @returns Task Successful Response
           * @throws ApiError
           */
          public updateDataset(
            accountName: string,
            starSchemaDataSourceId: string,
            destSsdsId: string,
            conversationCellFilter?: ConversationCellFilter,
            overwriteUserQuestionForSensitiveCell: boolean = false,
            onlyCopyConversations: boolean = false,
            sessionId?: (string | null),
          ): CancelablePromise<Task> {
            return this.httpRequest.request({
              method: 'POST',
              url: '/api/v1/accounts/{account_name}/datasets/{star_schema_data_source_id}/update',
              path: {
                'account_name': accountName,
                'star_schema_data_source_id': starSchemaDataSourceId,
              },
              cookies: {
                'session_id': sessionId,
              },
              query: {
                'dest_ssds_id': destSsdsId,
                'conversation_cell_filter': conversationCellFilter,
                'overwrite_user_question_for_sensitive_cell': overwriteUserQuestionForSensitiveCell,
                'only_copy_conversations': onlyCopyConversations,
              },
              errors: {
                422: `Validation Error`,
              },
            });
          }

          /**
           * Clear Open Search Index
           * Clear the open search index for the account.
           *
           * This endpoint is used to clear the open search index for the account. This is useful when the
           * fields in the index are updated and need to be reindexed.
           * @param accountName
           * @param sessionId
           * @returns any Successful Response
           * @throws ApiError
           */
          public clearOpenSearchIndex(
            accountName: string,
            sessionId?: (string | null),
          ): CancelablePromise<any> {
            return this.httpRequest.request({
              method: 'PUT',
              url: '/api/v1/accounts/{account_name}/datasets/clear_open_search_index',
              path: {
                'account_name': accountName,
              },
              cookies: {
                'session_id': sessionId,
              },
              errors: {
                422: `Validation Error`,
              },
            });
          }

        }
