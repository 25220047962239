// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/SystemTag.ts"
);
import.meta.hot.lastModified = "1737759746535.097";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * System tag filter.
 */
export enum SystemTag {
  FIRST_PROMPT = 'First prompt',
  FOLLOW_UP = 'Follow up',
  AI_GENERATED = 'AI generated',
  VERIFIED = 'Verified',
  PENDING_ASSISTANCE = 'Pending assistance',
  EMPTY_QUERY = 'Empty query',
  SENSITIVE = 'Sensitive',
}
