// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateCompositeKeyJoins.ts"
);
import.meta.hot.lastModified = "1737935768622.742";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateCompositeKeyJoinsMutationRequest,
  CreateCompositeKeyJoinsMutationResponse,
  CreateCompositeKeyJoinsPathParams,
  CreateCompositeKeyJoins422,
} from '../types/CreateCompositeKeyJoins'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createCompositeKeyJoinsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/composite_key' }] as const

export type CreateCompositeKeyJoinsMutationKey = ReturnType<typeof createCompositeKeyJoinsMutationKey>

/**
 * @description Create Joins for Star Schema Data Source.
 * @summary Create Composite Key Joins
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/composite_key}
 */
async function createCompositeKeyJoins(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: CreateCompositeKeyJoinsPathParams['account_name']
    starSchemaDataSourceId: CreateCompositeKeyJoinsPathParams['star_schema_data_source_id']
    data: CreateCompositeKeyJoinsMutationRequest
  },
  config: Partial<RequestConfig<CreateCompositeKeyJoinsMutationRequest>> = {},
) {
  const res = await client<CreateCompositeKeyJoinsMutationResponse, CreateCompositeKeyJoins422, CreateCompositeKeyJoinsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/composite_key`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create Joins for Star Schema Data Source.
 * @summary Create Composite Key Joins
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/composite_key}
 */
export function useCreateCompositeKeyJoins(
  options: {
    mutation?: UseMutationOptions<
      CreateCompositeKeyJoinsMutationResponse,
      CreateCompositeKeyJoins422,
      {
        accountName: CreateCompositeKeyJoinsPathParams['account_name']
        starSchemaDataSourceId: CreateCompositeKeyJoinsPathParams['star_schema_data_source_id']
        data: CreateCompositeKeyJoinsMutationRequest
      }
    >
    client?: Partial<RequestConfig<CreateCompositeKeyJoinsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createCompositeKeyJoinsMutationKey()

  return useMutation<
    CreateCompositeKeyJoinsMutationResponse,
    CreateCompositeKeyJoins422,
    {
      accountName: CreateCompositeKeyJoinsPathParams['account_name']
      starSchemaDataSourceId: CreateCompositeKeyJoinsPathParams['star_schema_data_source_id']
      data: CreateCompositeKeyJoinsMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return createCompositeKeyJoins({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}