// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/DataAssetTypeAPI.ts"
);
import.meta.hot.lastModified = "1737759746460.0972";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The type of data asset.
 */
export enum DataAssetTypeAPI {
  DATASET = 'dataset',
  REPORT = 'report',
}
