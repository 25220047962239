// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useValidateSql.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ValidateSqlMutationRequest, ValidateSqlMutationResponse, ValidateSqlPathParams, ValidateSql422 } from '../types/ValidateSql'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const validateSqlMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/validate_sql' }] as const

export type ValidateSqlMutationKey = ReturnType<typeof validateSqlMutationKey>

/**
 * @description Validate the SQL.
 * @summary Validate Sql
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/validate_sql}
 */
async function validateSql(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: ValidateSqlPathParams['account_name']
    starSchemaDataSourceId: ValidateSqlPathParams['star_schema_data_source_id']
    data: ValidateSqlMutationRequest
  },
  config: Partial<RequestConfig<ValidateSqlMutationRequest>> = {},
) {
  const res = await client<ValidateSqlMutationResponse, ValidateSql422, ValidateSqlMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/validate_sql`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Validate the SQL.
 * @summary Validate Sql
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/validate_sql}
 */
export function useValidateSql(
  options: {
    mutation?: UseMutationOptions<
      ValidateSqlMutationResponse,
      ValidateSql422,
      {
        accountName: ValidateSqlPathParams['account_name']
        starSchemaDataSourceId: ValidateSqlPathParams['star_schema_data_source_id']
        data: ValidateSqlMutationRequest
      }
    >
    client?: Partial<RequestConfig<ValidateSqlMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? validateSqlMutationKey()

  return useMutation<
    ValidateSqlMutationResponse,
    ValidateSql422,
    {
      accountName: ValidateSqlPathParams['account_name']
      starSchemaDataSourceId: ValidateSqlPathParams['star_schema_data_source_id']
      data: ValidateSqlMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return validateSql({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}