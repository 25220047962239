// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/mergeMetricsSchema.ts"
);
import.meta.hot.lastModified = "1737759758961.0327";
}
// REMIX HMR END

// @ts-nocheck
import { bodyMergeMetricsApiV1AccountsAccountNameStarSchemaDataSourceMetricsMetricIdMergePatchSchema } from './bodyMergeMetricsApiV1AccountsAccountNameStarSchemaDataSourceMetricsMetricIdMergePatchSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { metricDetailSchema } from './metricDetailSchema'
import { z } from 'zod'

export const mergeMetricsPathParamsSchema = z.object({
  account_name: z.string(),
  metric_id: z.string(),
})

/**
 * @description Successful Response
 */
export const mergeMetrics200Schema = z.lazy(() => metricDetailSchema)

/**
 * @description Validation Error
 */
export const mergeMetrics422Schema = z.lazy(() => httpValidationErrorSchema)

export const mergeMetricsMutationRequestSchema = z.lazy(() => bodyMergeMetricsApiV1AccountsAccountNameStarSchemaDataSourceMetricsMetricIdMergePatchSchema)

export const mergeMetricsMutationResponseSchema = z.lazy(() => mergeMetrics200Schema)