// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/IndexService.ts"
);
import.meta.hot.lastModified = "1737935753875.823";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Report } from '../models/Report';
import type { ReportType } from '../models/ReportType';
import type { Task } from '../models/Task';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IndexService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Index Reports
   * Index reports.
   *
   * This will index the reports in the database and search index.
   * Note: We require the connection_id to be present in the report object so we can index the report
   * with different connections.
   *
   * For tableau workbook, we need to have connection_id and report_id.
   *
   * Args:
   * db: Database session to perform the operation.
   * user_session: User session to perform the operation.
   * account: Account to perform the operation.
   * reports: Reports to index.
   * force: Whether to force the indexing of the reports even the report is already indexed
   * and this will update the index. This is useful when the content of the report is updated.
   * If force=False, the report will be indexed when it is not already indexed or the report
   * is stale.
   * @param accountName
   * @param requestBody
   * @param force
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public indexReports(
    accountName: string,
    requestBody: Array<Report>,
    force: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/index_reports',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'force': force,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Reports
   * Delete reports from the index.
   *
   * Args:
   * db: Database session to perform the operation.
   * user_session: User session to perform the operation.
   * account: Account to perform the operation.
   * reports: Reports to delete.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteReports(
    accountName: string,
    requestBody: Array<Report>,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/delete_reports',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete All Reports
   * Delete all reports from the index.
   *
   * Args:
   * db: Database session to perform the operation.
   * user_session: User session to perform the operation.
   * account: Account to perform the operation.
   * type: Type of the reports to delete. If not provided, all reports are deleted.
   * @param accountName
   * @param type
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteAllReports(
    accountName: string,
    type?: (ReportType | null),
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/delete_all_reports',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'type': type,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Reports
   * Read indexed reports.
   *
   * Args:
   * db: Database session to perform the operation.
   * user_session: User session to perform the operation.
   * account: Account to perform the operation.
   * type: Type of the reports to read. If not provided, all reports are read.
   * limit: Number of reports to read.
   * skip: Number of reports to skip.
   * search: Search string to filter the reports.
   * @param accountName
   * @param type
   * @param limit
   * @param skip
   * @param search
   * @param sessionId
   * @returns Report Successful Response
   * @throws ApiError
   */
  public readReports(
    accountName: string,
    type?: (ReportType | null),
    limit: number = 100,
    skip?: number,
    search?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<Array<Report>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/reports',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'type': type,
        'limit': limit,
        'skip': skip,
        'search': search,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Refresh Reports
   * Refresh reports.
   *
   * Args:
   * db: Database session to perform the operation.
   * user_session: User session to perform the operation.
   * account: Account to perform the operation.
   * force: Whether to force the refresh of the reports even the report is already indexed
   * and this will update the index. This is useful when the content of the report is updated.
   * If force=False, the report will be indexed when it is not already indexed or the report
   * is stale.
   * @param accountName
   * @param force
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public refreshReports(
    accountName: string,
    force: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/refresh_reports',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'force': force,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
