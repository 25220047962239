// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/feedbackFilterSchema.ts"
);
import.meta.hot.lastModified = "1737759759420.0305";
}
// REMIX HMR END

// @ts-nocheck
import { systemTagSchema } from './systemTagSchema'
import { z } from 'zod'

/**
 * @description Feedback filter input args.\n\nThe fields are ANDed when they are not null.\ne.g. if user_feedback is \"positive\" and expert_feedback is \"negative\", then\nthe filter will only return examples that have positive user feedback and negative\nexpert feedback.
 */
export const feedbackFilterSchema = z
  .object({
    tags: z.union([z.array(z.string()), z.null()]).optional(),
    system_tags: z.union([z.array(z.lazy(() => systemTagSchema)), z.null()]).optional(),
    question_search: z.union([z.string(), z.null()]).optional(),
    is_bad_response: z.union([z.boolean(), z.null()]).optional(),
    is_verified: z.union([z.boolean(), z.null()]).optional(),
    is_empty: z.union([z.boolean(), z.null()]).optional(),
    creator_ids: z.union([z.array(z.string()), z.null()]).optional(),
  })
  .describe(
    'Feedback filter input args.\n\nThe fields are ANDed when they are not null.\ne.g. if user_feedback is "positive" and expert_feedback is "negative", then\nthe filter will only return examples that have positive user feedback and negative\nexpert feedback.',
  )