// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRmAccountAdmins.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RmAccountAdminsMutationRequest, RmAccountAdminsMutationResponse, RmAccountAdminsPathParams, RmAccountAdmins422 } from '../types/RmAccountAdmins'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const rmAccountAdminsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/admins' }] as const

export type RmAccountAdminsMutationKey = ReturnType<typeof rmAccountAdminsMutationKey>

/**
 * @description Remove admin privileges from users of the account.Note that this does NOT remove the user themselves from the account.
 * @summary Rm Account Admins
 * {@link /api/v1/accounts/:account_name/admins}
 */
async function rmAccountAdmins(
  { accountName, data }: { accountName: RmAccountAdminsPathParams['account_name']; data?: RmAccountAdminsMutationRequest },
  config: Partial<RequestConfig<RmAccountAdminsMutationRequest>> = {},
) {
  const res = await client<RmAccountAdminsMutationResponse, RmAccountAdmins422, RmAccountAdminsMutationRequest>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/admins`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Remove admin privileges from users of the account.Note that this does NOT remove the user themselves from the account.
 * @summary Rm Account Admins
 * {@link /api/v1/accounts/:account_name/admins}
 */
export function useRmAccountAdmins(
  options: {
    mutation?: UseMutationOptions<
      RmAccountAdminsMutationResponse,
      RmAccountAdmins422,
      { accountName: RmAccountAdminsPathParams['account_name']; data?: RmAccountAdminsMutationRequest }
    >
    client?: Partial<RequestConfig<RmAccountAdminsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? rmAccountAdminsMutationKey()

  return useMutation<
    RmAccountAdminsMutationResponse,
    RmAccountAdmins422,
    { accountName: RmAccountAdminsPathParams['account_name']; data?: RmAccountAdminsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return rmAccountAdmins({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}