// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readReportsSchema.ts"
);
import.meta.hot.lastModified = "1737759758430.0356";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { reportSchema } from './reportSchema'
import { reportTypeSchema } from './reportTypeSchema'
import { z } from 'zod'

export const readReportsPathParamsSchema = z.object({
  account_name: z.string(),
})

export const readReportsQueryParamsSchema = z
  .object({
    type: z.union([z.lazy(() => reportTypeSchema), z.null()]).optional(),
    limit: z.number().int().default(100),
    skip: z.number().int().default(0),
    search: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readReports200Schema = z.array(z.lazy(() => reportSchema))

/**
 * @description Validation Error
 */
export const readReports422Schema = z.lazy(() => httpValidationErrorSchema)

export const readReportsQueryResponseSchema = z.lazy(() => readReports200Schema)