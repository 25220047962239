// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadPostgresConnectionColumns.ts"
);
import.meta.hot.lastModified = "1737759762296.0156";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadPostgresConnectionColumnsQueryResponse,
  ReadPostgresConnectionColumnsPathParams,
  ReadPostgresConnectionColumnsQueryParams,
  ReadPostgresConnectionColumns422,
} from '../types/ReadPostgresConnectionColumns'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readPostgresConnectionColumnsQueryKey = (
  {
    accountName,
    postgresConnectionId,
  }: {
    accountName: ReadPostgresConnectionColumnsPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionColumnsPathParams['postgres_connection_id']
  },
  params: ReadPostgresConnectionColumnsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/:postgres_connection_id/columns',
      params: { accountName: accountName, postgresConnectionId: postgresConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadPostgresConnectionColumnsQueryKey = ReturnType<typeof readPostgresConnectionColumnsQueryKey>

/**
 * @description Read Postgres columns.
 * @summary Read Postgres Connection Columns
 * {@link /api/v1/accounts/:account_name/connections/:postgres_connection_id/columns}
 */
async function readPostgresConnectionColumns(
  {
    accountName,
    postgresConnectionId,
    params,
  }: {
    accountName: ReadPostgresConnectionColumnsPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionColumnsPathParams['postgres_connection_id']
    params: ReadPostgresConnectionColumnsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadPostgresConnectionColumnsQueryResponse, ReadPostgresConnectionColumns422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/${postgresConnectionId}/columns`,
    params,
    ...config,
  })
  return res.data
}

export function readPostgresConnectionColumnsQueryOptions(
  {
    accountName,
    postgresConnectionId,
    params,
  }: {
    accountName: ReadPostgresConnectionColumnsPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionColumnsPathParams['postgres_connection_id']
    params: ReadPostgresConnectionColumnsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readPostgresConnectionColumnsQueryKey({ accountName, postgresConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && postgresConnectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readPostgresConnectionColumns({ accountName, postgresConnectionId, params }, config)
    },
  })
}

/**
 * @description Read Postgres columns.
 * @summary Read Postgres Connection Columns
 * {@link /api/v1/accounts/:account_name/connections/:postgres_connection_id/columns}
 */
export function useReadPostgresConnectionColumns<
  TData = ReadPostgresConnectionColumnsQueryResponse,
  TQueryData = ReadPostgresConnectionColumnsQueryResponse,
  TQueryKey extends QueryKey = ReadPostgresConnectionColumnsQueryKey,
>(
  {
    accountName,
    postgresConnectionId,
    params,
  }: {
    accountName: ReadPostgresConnectionColumnsPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionColumnsPathParams['postgres_connection_id']
    params: ReadPostgresConnectionColumnsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadPostgresConnectionColumnsQueryResponse, ReadPostgresConnectionColumns422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readPostgresConnectionColumnsQueryKey({ accountName, postgresConnectionId }, params)

  const query = useQuery({
    ...(readPostgresConnectionColumnsQueryOptions({ accountName, postgresConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadPostgresConnectionColumns422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}