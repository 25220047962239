// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateSsdsConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759761028.0222";
}
// REMIX HMR END

// @ts-nocheck
import { connectionSchema } from './connectionSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateSsdsConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const updateSsdsConnectionQueryParamsSchema = z.object({
  new_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const updateSsdsConnection200Schema = z.lazy(() => connectionSchema)

/**
 * @description Validation Error
 */
export const updateSsdsConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateSsdsConnectionMutationResponseSchema = z.lazy(() => updateSsdsConnection200Schema)