// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createIdentifiersSchema.ts"
);
import.meta.hot.lastModified = "1737935767642.7473";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { identifierSchema } from './identifierSchema'
import { starSchemaDataSourceSchema } from './starSchemaDataSourceSchema'
import { z } from 'zod'

export const createIdentifiersPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const createIdentifiersQueryParamsSchema = z
  .object({
    update_index: z.boolean().default(true),
    update_expr_types: z.boolean().default(true),
    update_display_types: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const createIdentifiers200Schema = z.lazy(() => starSchemaDataSourceSchema)

/**
 * @description Validation Error
 */
export const createIdentifiers422Schema = z.lazy(() => httpValidationErrorSchema)

export const createIdentifiersMutationRequestSchema = z.array(z.lazy(() => identifierSchema))

export const createIdentifiersMutationResponseSchema = z.lazy(() => createIdentifiers200Schema)