// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/conversationApiCreateResponseSchema.ts"
);
import.meta.hot.lastModified = "1737935769440.7375";
}
// REMIX HMR END

// @ts-nocheck
import { messageApiBatchResponseSchema } from './messageApiBatchResponseSchema'
import { messagePayloadTypeSchema } from './messagePayloadTypeSchema'
import { taskApiSchema } from './taskApiSchema'
import { userBasicApiResponseSchema } from './userBasicApiResponseSchema'
import { z } from 'zod'

/**
 * @description Response from creating a conversation with the API\n\nAttributes:\n    id: The ID of the conversation.\n    creator: The user who created the conversation.\n    can_edit: Whether the user can edit the conversation.\n    accepted_payload_types: The payload types that can be returned in the conversation.\n    messages: The messages in the conversation. They are ordered from neweest to\n        oldest.\n    num_messages_total: The total number of messages in the conversation.\n    ai_responding: Whether the AI is currently responding to the conversation. If\n        True, then new messages will not be added to the conversation until the AI\n        is done.\n    task: The ongoing task associated with the conversation if there is one.
 */
export const conversationApiCreateResponseSchema = z
  .object({
    id: z.string(),
    creator: z.lazy(() => userBasicApiResponseSchema),
    can_edit: z.boolean(),
    accepted_payload_types: z.union([z.array(z.lazy(() => messagePayloadTypeSchema)), z.null()]),
    messages: z.array(z.lazy(() => messageApiBatchResponseSchema)),
    num_messages_total: z.number().int(),
    ai_responding: z.boolean(),
    task: z.union([z.lazy(() => taskApiSchema), z.null()]).optional(),
  })
  .describe(
    'Response from creating a conversation with the API\n\nAttributes:\n    id: The ID of the conversation.\n    creator: The user who created the conversation.\n    can_edit: Whether the user can edit the conversation.\n    accepted_payload_types: The payload types that can be returned in the conversation.\n    messages: The messages in the conversation. They are ordered from neweest to\n        oldest.\n    num_messages_total: The total number of messages in the conversation.\n    ai_responding: Whether the AI is currently responding to the conversation. If\n        True, then new messages will not be added to the conversation until the AI\n        is done.\n    task: The ongoing task associated with the conversation if there is one.',
  )