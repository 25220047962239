// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadDatabricksConnection.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadDatabricksConnectionQueryResponse, ReadDatabricksConnectionPathParams, ReadDatabricksConnection422 } from '../types/ReadDatabricksConnection'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readDatabricksConnectionQueryKey = ({
  accountName,
  databricksConnectionId,
}: {
  accountName: ReadDatabricksConnectionPathParams['account_name']
  databricksConnectionId: ReadDatabricksConnectionPathParams['databricks_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id',
      params: { accountName: accountName, databricksConnectionId: databricksConnectionId },
    },
  ] as const

export type ReadDatabricksConnectionQueryKey = ReturnType<typeof readDatabricksConnectionQueryKey>

/**
 * @description Read Databricks connection.
 * @summary Read Databricks Connection
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id}
 */
async function readDatabricksConnection(
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: ReadDatabricksConnectionPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionPathParams['databricks_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadDatabricksConnectionQueryResponse, ReadDatabricksConnection422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/databricks/${databricksConnectionId}`,
    ...config,
  })
  return res.data
}

export function readDatabricksConnectionQueryOptions(
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: ReadDatabricksConnectionPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionPathParams['databricks_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readDatabricksConnectionQueryKey({ accountName, databricksConnectionId })
  return queryOptions({
    enabled: !!(accountName && databricksConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readDatabricksConnection({ accountName, databricksConnectionId }, config)
    },
  })
}

/**
 * @description Read Databricks connection.
 * @summary Read Databricks Connection
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id}
 */
export function useReadDatabricksConnection<
  TData = ReadDatabricksConnectionQueryResponse,
  TQueryData = ReadDatabricksConnectionQueryResponse,
  TQueryKey extends QueryKey = ReadDatabricksConnectionQueryKey,
>(
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: ReadDatabricksConnectionPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionPathParams['databricks_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadDatabricksConnectionQueryResponse, ReadDatabricksConnection422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readDatabricksConnectionQueryKey({ accountName, databricksConnectionId })

  const query = useQuery({
    ...(readDatabricksConnectionQueryOptions({ accountName, databricksConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadDatabricksConnection422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}