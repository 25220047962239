// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDataSource.ts"
);
import.meta.hot.lastModified = "1737935767038.7507";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateDataSourceMutationRequest,
  UpdateDataSourceMutationResponse,
  UpdateDataSourcePathParams,
  UpdateDataSource422,
} from '../types/UpdateDataSource'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDataSourceMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}' }] as const

export type UpdateDataSourceMutationKey = ReturnType<typeof updateDataSourceMutationKey>

/**
 * @description Update existing data source in the Star Schema Data Source.This endpoint allows users to update existing data source in the existing star schema data schema.It requires the input of StarSchemaDataSourceTableOrViewInfo without data source id.The endpoint will scan the StarSchemaDataSourceTableOrViewInfo and perform cold start for theupdated data source and do enrich_ssds with related dimensions and metrics.
 * @summary Update Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id}
 */
async function updateDataSource(
  {
    accountName,
    starSchemaDataSourceId,
    dataSourceId,
    data,
  }: {
    accountName: UpdateDataSourcePathParams['account_name']
    starSchemaDataSourceId: UpdateDataSourcePathParams['star_schema_data_source_id']
    dataSourceId: UpdateDataSourcePathParams['data_source_id']
    data: UpdateDataSourceMutationRequest
  },
  config: Partial<RequestConfig<UpdateDataSourceMutationRequest>> = {},
) {
  const res = await client<UpdateDataSourceMutationResponse, UpdateDataSource422, UpdateDataSourceMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/data_sources/${dataSourceId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update existing data source in the Star Schema Data Source.This endpoint allows users to update existing data source in the existing star schema data schema.It requires the input of StarSchemaDataSourceTableOrViewInfo without data source id.The endpoint will scan the StarSchemaDataSourceTableOrViewInfo and perform cold start for theupdated data source and do enrich_ssds with related dimensions and metrics.
 * @summary Update Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id}
 */
export function useUpdateDataSource(
  options: {
    mutation?: UseMutationOptions<
      UpdateDataSourceMutationResponse,
      UpdateDataSource422,
      {
        accountName: UpdateDataSourcePathParams['account_name']
        starSchemaDataSourceId: UpdateDataSourcePathParams['star_schema_data_source_id']
        dataSourceId: UpdateDataSourcePathParams['data_source_id']
        data: UpdateDataSourceMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateDataSourceMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDataSourceMutationKey()

  return useMutation<
    UpdateDataSourceMutationResponse,
    UpdateDataSource422,
    {
      accountName: UpdateDataSourcePathParams['account_name']
      starSchemaDataSourceId: UpdateDataSourcePathParams['star_schema_data_source_id']
      dataSourceId: UpdateDataSourcePathParams['data_source_id']
      data: UpdateDataSourceMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, dataSourceId, data }) => {
      return updateDataSource({ accountName, starSchemaDataSourceId, dataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}