// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/TaskName.ts"
);
import.meta.hot.lastModified = "1737935754046.822";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Names for tasks.
 */
export enum TaskName {
  CELERY_TEST = 'celery_test',
  FAKETASK = 'FAKETASK',
  AGENTS_EVAL_REFERENCE_CHAT = 'agents_eval_reference_chat',
  ALERTS_RUN_ALERT = 'alerts_run_alert',
  AUTONAME_CHAT = 'autoname_chat',
  AUTONAME_CONVERSATION = 'autoname_conversation',
  BIGQUERY_CONNECTION_CREATE = 'bigquery_connection_create',
  BUMBLEBEE_CELL_REFRESH = 'bumblebee_cell_refresh',
  BUMBLEBEE_CELL_REPHRASE_QUESTION = 'bumblebee_cell_rephrase_question',
  BUMBLEBEE_CELL_RUN = 'bumblebee_cell_run',
  BUMBLEBEE_EVAL_REFERENCE_CONVERSATION = 'bumblebee_eval_reference_conversation',
  BUMBLEBEE_REGRESSION_TEST = 'bumblebee_regression_test',
  BUMBLEBEE_SSDS_COLD_START = 'bumblebee_ssds_cold_start',
  DATAWAREHOUSE_PRUNE_TABLES = 'datawarehouse_prune_tables',
  DELETE_CELL_TABLES = 'delete_cell_tables',
  DEMONSTRATIONS_PATCH = 'demonstrations_patch',
  ER_UPDATE_RUN_DEMOS = 'er_update_run_demos',
  ENRICH_SSDS = 'enrich_ssds',
  IMPORT_FROM_TWB = 'import_from_twb',
  ENRICH_FROM_TWB = 'enrich_from_twb',
  ENRICH_FROM_MODE = 'enrich_from_mode',
  INFER_DISPLAY_TYPES_SSDS = 'infer_display_types_ssds',
  UPLOAD_EXAMPLES = 'upload_examples',
  UPDATE_SSDS = 'update_ssds',
  VALIDATE_SSDS = 'validate_ssds',
  RUN_MOONLIGHT = 'run_moonlight',
  UPLOAD_DATASET = 'upload_dataset',
  COPY_DATASET = 'copy_dataset',
  UPDATE_DATASET = 'update_dataset',
  LEARN_DMS_FROM_SQL = 'learn_dms_from_sql',
  INJECT_NEW_DIMENSIONS_AND_METRICS = 'inject_new_dimensions_and_metrics',
  RUN_MOONLIGHT_ORCHESTRATOR = 'run_moonlight_orchestrator',
  INDEX_REPORTS = 'index_reports',
  REFRESH_REPORTS = 'refresh_reports',
  SYNC_BB_VERIFIED_CELL_TO_FEEDBACK_CHAT = 'sync_bb_verified_cell_to_feedback_chat',
  SYNC_KNOWLEDGE_SQL = 'sync_knowledge_sql',
  DBT_RUN = 'dbt_run',
  DATAWAREHOUSE_PROTOTYPE_RUN = 'datawarehouse_prototype_run',
  MODEL_DEPLOY = 'model_deploy',
  PIPELINES_BUILD = 'pipelines_build',
  PIPELINES_RUN = 'pipelines_run',
  REFRESH_DBT_SCRIPTS = 'refresh_dbt_scripts',
  SENTRY_AUTOCOMPLETE = 'sentry_autocomplete',
  SENTRY_AUTOCOMPLETE_SNOWFLAKE = 'sentry_autocomplete_snowflake',
  SENTRY_FINETUNE_AUTOCOMPLETE = 'sentry_finetune_autocomplete',
  SQL_GENERATE = 'sql_generate',
  SQL_UPDATE_RUN = 'sql_update_run',
  TRANSFORM_CUSTOM_EVALUATION = 'transform_custom_evaluation',
  TRANSFORM_MODEL_FINETUNE = 'transform_model_finetune',
  TRANSFORM_PROTOTYPE_EVALUATION = 'transform_prototype_evaluation',
}
