// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dataSourceMetadataSchema.ts"
);
import.meta.hot.lastModified = "1737759760680.024";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const dataSourceMetadataSchema = z.object({
  id: z.string(),
  name: z.string(),
  sql_table: z.union([z.string(), z.null()]),
  derived_table_sql: z.union([z.string(), z.null()]),
})