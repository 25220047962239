// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/agentDepSchema.ts"
);
import.meta.hot.lastModified = "1737759757753.039";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description The dependencies for an agent.\n\nNote that conditions with a single AgentDep are always a disjunction (OR).\n\nAttributes:\n   agent_names: A list of agents, one of which must be enabled.\n   require_dataset: Whether selecting a dataset also satisfies this dep.
 */
export const agentDepSchema = z
  .object({
    agent_names: z.array(z.string()),
    require_dataset: z.boolean(),
  })
  .describe(
    'The dependencies for an agent.\n\nNote that conditions with a single AgentDep are always a disjunction (OR).\n\nAttributes:\n   agent_names: A list of agents, one of which must be enabled.\n   require_dataset: Whether selecting a dataset also satisfies this dep.',
  )