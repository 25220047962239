// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyUploadDatasetApiV1AccountsAccountNameDatasetsUploadPostSchema.ts"
);
import.meta.hot.lastModified = "1737759762609.014";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeDatasetSchema } from './bumblebeeDatasetSchema'
import { bumblebeeDatasetUploadOptionsSchema } from './bumblebeeDatasetUploadOptionsSchema'
import { z } from 'zod'

export const bodyUploadDatasetApiV1AccountsAccountNameDatasetsUploadPostSchema = z.object({
  dataset_in: z.lazy(() => bumblebeeDatasetSchema),
  options: z.lazy(() => bumblebeeDatasetUploadOptionsSchema),
})