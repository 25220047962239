// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/coalesceDmsSchema.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { semanticQueryCoalescingStrategySchema } from './semanticQueryCoalescingStrategySchema'
import { semanticQuerySchema } from './semanticQuerySchema'
import { z } from 'zod'

export const coalesceDmsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const coalesceDmsQueryParamsSchema = z.object({
  grouping_strategy: z.lazy(() => semanticQueryCoalescingStrategySchema),
  dry_run: z.boolean().default(false),
})

/**
 * @description Successful Response
 */
export const coalesceDms200Schema = z.array(z.array(z.lazy(() => semanticQuerySchema)))

/**
 * @description Validation Error
 */
export const coalesceDms422Schema = z.lazy(() => httpValidationErrorSchema)

export const coalesceDmsMutationRequestSchema = z.union([z.array(z.string()), z.null()])

export const coalesceDmsMutationResponseSchema = z.lazy(() => coalesceDms200Schema)