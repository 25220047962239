// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadMessageTableData.ts"
);
import.meta.hot.lastModified = "1737759760889.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadMessageTableDataQueryResponse,
  ReadMessageTableDataPathParams,
  ReadMessageTableDataQueryParams,
  ReadMessageTableData400,
  ReadMessageTableData404,
  ReadMessageTableData422,
} from '../types/ReadMessageTableData'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readMessageTableDataQueryKey = (
  { accountName, messageId }: { accountName: ReadMessageTableDataPathParams['account_name']; messageId: ReadMessageTableDataPathParams['message_id'] },
  params?: ReadMessageTableDataQueryParams,
) =>
  [
    { url: '/api/v3/orgs/:account_name/messages/:message_id/data', params: { accountName: accountName, messageId: messageId } },
    ...(params ? [params] : []),
  ] as const

export type ReadMessageTableDataQueryKey = ReturnType<typeof readMessageTableDataQueryKey>

/**
 * @description Get the table data associated with a message.This will return an error if the message does not contain a table ID.
 * @summary Read Message Table Data
 * {@link /api/v3/orgs/:account_name/messages/:message_id/data}
 */
async function readMessageTableData(
  {
    accountName,
    messageId,
    params,
  }: {
    accountName: ReadMessageTableDataPathParams['account_name']
    messageId: ReadMessageTableDataPathParams['message_id']
    params?: ReadMessageTableDataQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadMessageTableDataQueryResponse, ReadMessageTableData400 | ReadMessageTableData404 | ReadMessageTableData422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/messages/${messageId}/data`,
    params,
    ...config,
  })
  return res.data
}

export function readMessageTableDataQueryOptions(
  {
    accountName,
    messageId,
    params,
  }: {
    accountName: ReadMessageTableDataPathParams['account_name']
    messageId: ReadMessageTableDataPathParams['message_id']
    params?: ReadMessageTableDataQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readMessageTableDataQueryKey({ accountName, messageId }, params)
  return queryOptions({
    enabled: !!(accountName && messageId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readMessageTableData({ accountName, messageId, params }, config)
    },
  })
}

/**
 * @description Get the table data associated with a message.This will return an error if the message does not contain a table ID.
 * @summary Read Message Table Data
 * {@link /api/v3/orgs/:account_name/messages/:message_id/data}
 */
export function useReadMessageTableData<
  TData = ReadMessageTableDataQueryResponse,
  TQueryData = ReadMessageTableDataQueryResponse,
  TQueryKey extends QueryKey = ReadMessageTableDataQueryKey,
>(
  {
    accountName,
    messageId,
    params,
  }: {
    accountName: ReadMessageTableDataPathParams['account_name']
    messageId: ReadMessageTableDataPathParams['message_id']
    params?: ReadMessageTableDataQueryParams
  },
  options: {
    query?: Partial<
      QueryObserverOptions<
        ReadMessageTableDataQueryResponse,
        ReadMessageTableData400 | ReadMessageTableData404 | ReadMessageTableData422,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readMessageTableDataQueryKey({ accountName, messageId }, params)

  const query = useQuery({
    ...(readMessageTableDataQueryOptions({ accountName, messageId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadMessageTableData400 | ReadMessageTableData404 | ReadMessageTableData422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}