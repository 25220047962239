// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetCellFilterOptions.ts"
);
import.meta.hot.lastModified = "1737759760889.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetCellFilterOptionsQueryResponse, GetCellFilterOptionsPathParams, GetCellFilterOptions422 } from '../types/GetCellFilterOptions'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCellFilterOptionsQueryKey = ({
  accountName,
  conversationId,
  cellId,
}: {
  accountName: GetCellFilterOptionsPathParams['account_name']
  conversationId: GetCellFilterOptionsPathParams['conversation_id']
  cellId: GetCellFilterOptionsPathParams['cell_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/filter_options',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId },
    },
  ] as const

export type GetCellFilterOptionsQueryKey = ReturnType<typeof getCellFilterOptionsQueryKey>

/**
 * @description Get cell filter options.
 * @summary Get Cell Filter Options
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/filter_options}
 */
async function getCellFilterOptions(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetCellFilterOptionsPathParams['account_name']
    conversationId: GetCellFilterOptionsPathParams['conversation_id']
    cellId: GetCellFilterOptionsPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetCellFilterOptionsQueryResponse, GetCellFilterOptions422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/filter_options`,
    ...config,
  })
  return res.data
}

export function getCellFilterOptionsQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetCellFilterOptionsPathParams['account_name']
    conversationId: GetCellFilterOptionsPathParams['conversation_id']
    cellId: GetCellFilterOptionsPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getCellFilterOptionsQueryKey({ accountName, conversationId, cellId })
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCellFilterOptions({ accountName, conversationId, cellId }, config)
    },
  })
}

/**
 * @description Get cell filter options.
 * @summary Get Cell Filter Options
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/filter_options}
 */
export function useGetCellFilterOptions<
  TData = GetCellFilterOptionsQueryResponse,
  TQueryData = GetCellFilterOptionsQueryResponse,
  TQueryKey extends QueryKey = GetCellFilterOptionsQueryKey,
>(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetCellFilterOptionsPathParams['account_name']
    conversationId: GetCellFilterOptionsPathParams['conversation_id']
    cellId: GetCellFilterOptionsPathParams['cell_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<GetCellFilterOptionsQueryResponse, GetCellFilterOptions422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCellFilterOptionsQueryKey({ accountName, conversationId, cellId })

  const query = useQuery({
    ...(getCellFilterOptionsQueryOptions({ accountName, conversationId, cellId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetCellFilterOptions422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}