// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/taskNameSchema.ts"
);
import.meta.hot.lastModified = "1737759757948.038";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Names for tasks.
 */
export const taskNameSchema = z
  .enum([
    'celery_test',
    'FAKETASK',
    'agents_eval_reference_chat',
    'alerts_run_alert',
    'autoname_chat',
    'autoname_conversation',
    'bigquery_connection_create',
    'bumblebee_cell_refresh',
    'bumblebee_cell_rephrase_question',
    'bumblebee_cell_run',
    'bumblebee_eval_reference_conversation',
    'bumblebee_regression_test',
    'bumblebee_ssds_cold_start',
    'datawarehouse_prune_tables',
    'delete_cell_tables',
    'demonstrations_patch',
    'er_update_run_demos',
    'enrich_ssds',
    'import_from_twb',
    'enrich_from_twb',
    'enrich_from_mode',
    'infer_display_types_ssds',
    'upload_examples',
    'update_ssds',
    'validate_ssds',
    'run_moonlight',
    'upload_dataset',
    'copy_dataset',
    'update_dataset',
    'learn_dms_from_sql',
    'inject_new_dimensions_and_metrics',
    'run_moonlight_orchestrator',
    'index_reports',
    'refresh_reports',
    'sync_bb_verified_cell_to_feedback_chat',
    'sync_knowledge_sql',
    'dbt_run',
    'datawarehouse_prototype_run',
    'model_deploy',
    'pipelines_build',
    'pipelines_run',
    'refresh_dbt_scripts',
    'sentry_autocomplete',
    'sentry_autocomplete_snowflake',
    'sentry_finetune_autocomplete',
    'sql_generate',
    'sql_update_run',
    'transform_custom_evaluation',
    'transform_model_finetune',
    'transform_prototype_evaluation',
  ])
  .describe('Names for tasks.')