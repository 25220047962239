// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useAdminFeedbackClone.ts"
);
import.meta.hot.lastModified = "1737759760463.0251";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { AdminFeedbackCloneMutationResponse, AdminFeedbackClonePathParams, AdminFeedbackClone422 } from '../types/AdminFeedbackClone'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const adminFeedbackCloneMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/messages/{message_id}/admin_feedback_clone' }] as const

export type AdminFeedbackCloneMutationKey = ReturnType<typeof adminFeedbackCloneMutationKey>

/**
 * @description Clone a user chat for admin to provide admin feedback.Args:    message: The user message that has user feedback. The copy will conver messages from the        first one to the response of the response fo this user message.
 * @summary Admin Feedback Clone
 * {@link /api/v3/orgs/:account_name/messages/:message_id/admin_feedback_clone}
 */
async function adminFeedbackClone(
  { accountName, messageId }: { accountName: AdminFeedbackClonePathParams['account_name']; messageId: AdminFeedbackClonePathParams['message_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<AdminFeedbackCloneMutationResponse, AdminFeedbackClone422, unknown>({
    method: 'POST',
    url: `/api/v3/orgs/${accountName}/messages/${messageId}/admin_feedback_clone`,
    ...config,
  })
  return res.data
}

/**
 * @description Clone a user chat for admin to provide admin feedback.Args:    message: The user message that has user feedback. The copy will conver messages from the        first one to the response of the response fo this user message.
 * @summary Admin Feedback Clone
 * {@link /api/v3/orgs/:account_name/messages/:message_id/admin_feedback_clone}
 */
export function useAdminFeedbackClone(
  options: {
    mutation?: UseMutationOptions<
      AdminFeedbackCloneMutationResponse,
      AdminFeedbackClone422,
      { accountName: AdminFeedbackClonePathParams['account_name']; messageId: AdminFeedbackClonePathParams['message_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? adminFeedbackCloneMutationKey()

  return useMutation<
    AdminFeedbackCloneMutationResponse,
    AdminFeedbackClone422,
    { accountName: AdminFeedbackClonePathParams['account_name']; messageId: AdminFeedbackClonePathParams['message_id'] }
  >({
    mutationFn: async ({ accountName, messageId }) => {
      return adminFeedbackClone({ accountName, messageId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}