// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadCell.ts"
);
import.meta.hot.lastModified = "1737759757712.0393";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadCellQueryResponse, ReadCellPathParams, ReadCell422 } from '../types/ReadCell'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readCellQueryKey = ({
  accountName,
  conversationId,
  cellId,
}: {
  accountName: ReadCellPathParams['account_name']
  conversationId: ReadCellPathParams['conversation_id']
  cellId: ReadCellPathParams['cell_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId },
    },
  ] as const

export type ReadCellQueryKey = ReturnType<typeof readCellQueryKey>

/**
 * @description Get Bumblebee Cell.
 * @summary Read Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id}
 */
async function readCell(
  {
    accountName,
    conversationId,
    cellId,
  }: { accountName: ReadCellPathParams['account_name']; conversationId: ReadCellPathParams['conversation_id']; cellId: ReadCellPathParams['cell_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadCellQueryResponse, ReadCell422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}`,
    ...config,
  })
  return res.data
}

export function readCellQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
  }: { accountName: ReadCellPathParams['account_name']; conversationId: ReadCellPathParams['conversation_id']; cellId: ReadCellPathParams['cell_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readCellQueryKey({ accountName, conversationId, cellId })
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readCell({ accountName, conversationId, cellId }, config)
    },
  })
}

/**
 * @description Get Bumblebee Cell.
 * @summary Read Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id}
 */
export function useReadCell<TData = ReadCellQueryResponse, TQueryData = ReadCellQueryResponse, TQueryKey extends QueryKey = ReadCellQueryKey>(
  {
    accountName,
    conversationId,
    cellId,
  }: { accountName: ReadCellPathParams['account_name']; conversationId: ReadCellPathParams['conversation_id']; cellId: ReadCellPathParams['cell_id'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadCellQueryResponse, ReadCell422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readCellQueryKey({ accountName, conversationId, cellId })

  const query = useQuery({
    ...(readCellQueryOptions({ accountName, conversationId, cellId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadCell422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}