// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetWidgetForAccount.ts"
);
import.meta.hot.lastModified = "1737935768059.745";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetWidgetForAccountQueryResponse, GetWidgetForAccountPathParams, GetWidgetForAccount422 } from '../types/GetWidgetForAccount'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getWidgetForAccountQueryKey = ({ accountName }: { accountName: GetWidgetForAccountPathParams['account_name'] }) =>
  [{ url: '/api/v3/orgs/:account_name/widgets/', params: { accountName: accountName } }] as const

export type GetWidgetForAccountQueryKey = ReturnType<typeof getWidgetForAccountQueryKey>

/**
 * @description Get widget by account ID.
 * @summary Get Widget For Account
 * {@link /api/v3/orgs/:account_name/widgets/}
 */
async function getWidgetForAccount({ accountName }: { accountName: GetWidgetForAccountPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<GetWidgetForAccountQueryResponse, GetWidgetForAccount422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/widgets/`,
    ...config,
  })
  return res.data
}

export function getWidgetForAccountQueryOptions(
  { accountName }: { accountName: GetWidgetForAccountPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getWidgetForAccountQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getWidgetForAccount({ accountName }, config)
    },
  })
}

/**
 * @description Get widget by account ID.
 * @summary Get Widget For Account
 * {@link /api/v3/orgs/:account_name/widgets/}
 */
export function useGetWidgetForAccount<
  TData = GetWidgetForAccountQueryResponse,
  TQueryData = GetWidgetForAccountQueryResponse,
  TQueryKey extends QueryKey = GetWidgetForAccountQueryKey,
>(
  { accountName }: { accountName: GetWidgetForAccountPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<GetWidgetForAccountQueryResponse, GetWidgetForAccount422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getWidgetForAccountQueryKey({ accountName })

  const query = useQuery({
    ...(getWidgetForAccountQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetWidgetForAccount422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}