// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetAdminFeedback.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetAdminFeedbackMutationRequest,
  GetAdminFeedbackMutationResponse,
  GetAdminFeedbackPathParams,
  GetAdminFeedbackQueryParams,
  GetAdminFeedback422,
} from '../types/GetAdminFeedback'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const getAdminFeedbackMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/messages/admin_feedback' }] as const

export type GetAdminFeedbackMutationKey = ReturnType<typeof getAdminFeedbackMutationKey>

/**
 * @description Get saved admin feedback in an account.Returns the feedback messages that have positive admin feedback. This is used to populatethe "Admin feedback" tab. We show the rephrased user question for the feedback messages wherethe rephrased user question is the rephrased thumbs down user message if available or therephrased user question of the postive admin feedback message if no thumbs down user message.
 * @summary Get Admin Feedback
 * {@link /api/v3/orgs/:account_name/messages/admin_feedback}
 */
async function getAdminFeedback(
  {
    accountName,
    data,
    params,
  }: { accountName: GetAdminFeedbackPathParams['account_name']; data?: GetAdminFeedbackMutationRequest; params?: GetAdminFeedbackQueryParams },
  config: Partial<RequestConfig<GetAdminFeedbackMutationRequest>> = {},
) {
  const res = await client<GetAdminFeedbackMutationResponse, GetAdminFeedback422, GetAdminFeedbackMutationRequest>({
    method: 'POST',
    url: `/api/v3/orgs/${accountName}/messages/admin_feedback`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Get saved admin feedback in an account.Returns the feedback messages that have positive admin feedback. This is used to populatethe "Admin feedback" tab. We show the rephrased user question for the feedback messages wherethe rephrased user question is the rephrased thumbs down user message if available or therephrased user question of the postive admin feedback message if no thumbs down user message.
 * @summary Get Admin Feedback
 * {@link /api/v3/orgs/:account_name/messages/admin_feedback}
 */
export function useGetAdminFeedback(
  options: {
    mutation?: UseMutationOptions<
      GetAdminFeedbackMutationResponse,
      GetAdminFeedback422,
      { accountName: GetAdminFeedbackPathParams['account_name']; data?: GetAdminFeedbackMutationRequest; params?: GetAdminFeedbackQueryParams }
    >
    client?: Partial<RequestConfig<GetAdminFeedbackMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? getAdminFeedbackMutationKey()

  return useMutation<
    GetAdminFeedbackMutationResponse,
    GetAdminFeedback422,
    { accountName: GetAdminFeedbackPathParams['account_name']; data?: GetAdminFeedbackMutationRequest; params?: GetAdminFeedbackQueryParams }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return getAdminFeedback({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}