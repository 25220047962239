// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteReports.ts"
);
import.meta.hot.lastModified = "1737935766031.756";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteReportsMutationRequest, DeleteReportsMutationResponse, DeleteReportsPathParams, DeleteReports422 } from '../types/DeleteReports'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteReportsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/delete_reports' }] as const

export type DeleteReportsMutationKey = ReturnType<typeof deleteReportsMutationKey>

/**
 * @description Delete reports from the index.Args:    db: Database session to perform the operation.    user_session: User session to perform the operation.    account: Account to perform the operation.    reports: Reports to delete.
 * @summary Delete Reports
 * {@link /api/v1/accounts/:account_name/delete_reports}
 */
async function deleteReports(
  { accountName, data }: { accountName: DeleteReportsPathParams['account_name']; data?: DeleteReportsMutationRequest },
  config: Partial<RequestConfig<DeleteReportsMutationRequest>> = {},
) {
  const res = await client<DeleteReportsMutationResponse, DeleteReports422, DeleteReportsMutationRequest>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/delete_reports`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Delete reports from the index.Args:    db: Database session to perform the operation.    user_session: User session to perform the operation.    account: Account to perform the operation.    reports: Reports to delete.
 * @summary Delete Reports
 * {@link /api/v1/accounts/:account_name/delete_reports}
 */
export function useDeleteReports(
  options: {
    mutation?: UseMutationOptions<
      DeleteReportsMutationResponse,
      DeleteReports422,
      { accountName: DeleteReportsPathParams['account_name']; data?: DeleteReportsMutationRequest }
    >
    client?: Partial<RequestConfig<DeleteReportsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteReportsMutationKey()

  return useMutation<
    DeleteReportsMutationResponse,
    DeleteReports422,
    { accountName: DeleteReportsPathParams['account_name']; data?: DeleteReportsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return deleteReports({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}