// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateChat.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateChatMutationRequest, UpdateChatMutationResponse, UpdateChatPathParams, UpdateChat422 } from '../types/UpdateChat'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateChatMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/chat/{chat_id}' }] as const

export type UpdateChatMutationKey = ReturnType<typeof updateChatMutationKey>

/**
 * @description Update a chat.
 * @summary Update Chat
 * {@link /api/v3/orgs/:account_name/chat/:chat_id}
 */
async function updateChat(
  {
    accountName,
    chatId,
    data,
  }: { accountName: UpdateChatPathParams['account_name']; chatId: UpdateChatPathParams['chat_id']; data?: UpdateChatMutationRequest },
  config: Partial<RequestConfig<UpdateChatMutationRequest>> = {},
) {
  const res = await client<UpdateChatMutationResponse, UpdateChat422, UpdateChatMutationRequest>({
    method: 'PUT',
    url: `/api/v3/orgs/${accountName}/chat/${chatId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a chat.
 * @summary Update Chat
 * {@link /api/v3/orgs/:account_name/chat/:chat_id}
 */
export function useUpdateChat(
  options: {
    mutation?: UseMutationOptions<
      UpdateChatMutationResponse,
      UpdateChat422,
      { accountName: UpdateChatPathParams['account_name']; chatId: UpdateChatPathParams['chat_id']; data?: UpdateChatMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateChatMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateChatMutationKey()

  return useMutation<
    UpdateChatMutationResponse,
    UpdateChat422,
    { accountName: UpdateChatPathParams['account_name']; chatId: UpdateChatPathParams['chat_id']; data?: UpdateChatMutationRequest }
  >({
    mutationFn: async ({ accountName, chatId, data }) => {
      return updateChat({ accountName, chatId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}