// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDimensionState.ts"
);
import.meta.hot.lastModified = "1737759760889.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateDimensionStateMutationRequest,
  UpdateDimensionStateMutationResponse,
  UpdateDimensionStatePathParams,
  UpdateDimensionStateQueryParams,
  UpdateDimensionState422,
} from '../types/UpdateDimensionState'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDimensionStateMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/state' }] as const

export type UpdateDimensionStateMutationKey = ReturnType<typeof updateDimensionStateMutationKey>

/**
 * @description Update a dimension's state.
 * @summary Update Dimension State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/state}
 */
async function updateDimensionState(
  {
    accountName,
    dimensionId,
    data,
    params,
  }: {
    accountName: UpdateDimensionStatePathParams['account_name']
    dimensionId: UpdateDimensionStatePathParams['dimension_id']
    data: UpdateDimensionStateMutationRequest
    params?: UpdateDimensionStateQueryParams
  },
  config: Partial<RequestConfig<UpdateDimensionStateMutationRequest>> = {},
) {
  const res = await client<UpdateDimensionStateMutationResponse, UpdateDimensionState422, UpdateDimensionStateMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions/${dimensionId}/state`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a dimension's state.
 * @summary Update Dimension State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/state}
 */
export function useUpdateDimensionState(
  options: {
    mutation?: UseMutationOptions<
      UpdateDimensionStateMutationResponse,
      UpdateDimensionState422,
      {
        accountName: UpdateDimensionStatePathParams['account_name']
        dimensionId: UpdateDimensionStatePathParams['dimension_id']
        data: UpdateDimensionStateMutationRequest
        params?: UpdateDimensionStateQueryParams
      }
    >
    client?: Partial<RequestConfig<UpdateDimensionStateMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDimensionStateMutationKey()

  return useMutation<
    UpdateDimensionStateMutationResponse,
    UpdateDimensionState422,
    {
      accountName: UpdateDimensionStatePathParams['account_name']
      dimensionId: UpdateDimensionStatePathParams['dimension_id']
      data: UpdateDimensionStateMutationRequest
      params?: UpdateDimensionStateQueryParams
    }
  >({
    mutationFn: async ({ accountName, dimensionId, data, params }) => {
      return updateDimensionState({ accountName, dimensionId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}