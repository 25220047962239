// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSuggestedPrompts.ts"
);
import.meta.hot.lastModified = "1737935767879.746";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetSuggestedPromptsQueryResponse,
  GetSuggestedPromptsPathParams,
  GetSuggestedPromptsQueryParams,
  GetSuggestedPrompts422,
} from '../types/GetSuggestedPrompts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSuggestedPromptsQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSuggestedPromptsPathParams['account_name']; starSchemaDataSourceId: GetSuggestedPromptsPathParams['star_schema_data_source_id'] },
  params?: GetSuggestedPromptsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/suggestions',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetSuggestedPromptsQueryKey = ReturnType<typeof getSuggestedPromptsQueryKey>

/**
 * @description Get a dataset's suggested prompts from the SSDS examples.
 * @summary Get Suggested Prompts
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/suggestions}
 */
async function getSuggestedPrompts(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSuggestedPromptsPathParams['account_name']
    starSchemaDataSourceId: GetSuggestedPromptsPathParams['star_schema_data_source_id']
    params?: GetSuggestedPromptsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSuggestedPromptsQueryResponse, GetSuggestedPrompts422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/suggestions`,
    params,
    ...config,
  })
  return res.data
}

export function getSuggestedPromptsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSuggestedPromptsPathParams['account_name']
    starSchemaDataSourceId: GetSuggestedPromptsPathParams['star_schema_data_source_id']
    params?: GetSuggestedPromptsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSuggestedPromptsQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSuggestedPrompts({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get a dataset's suggested prompts from the SSDS examples.
 * @summary Get Suggested Prompts
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/suggestions}
 */
export function useGetSuggestedPrompts<
  TData = GetSuggestedPromptsQueryResponse,
  TQueryData = GetSuggestedPromptsQueryResponse,
  TQueryKey extends QueryKey = GetSuggestedPromptsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSuggestedPromptsPathParams['account_name']
    starSchemaDataSourceId: GetSuggestedPromptsPathParams['star_schema_data_source_id']
    params?: GetSuggestedPromptsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetSuggestedPromptsQueryResponse, GetSuggestedPrompts422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSuggestedPromptsQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getSuggestedPromptsQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSuggestedPrompts422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}