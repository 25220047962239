// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetAccountEvalMaintainers.ts"
);
import.meta.hot.lastModified = "1737759761850.0178";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetAccountEvalMaintainersQueryResponse,
  GetAccountEvalMaintainersPathParams,
  GetAccountEvalMaintainers422,
} from '../types/GetAccountEvalMaintainers'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAccountEvalMaintainersQueryKey = ({ accountName }: { accountName: GetAccountEvalMaintainersPathParams['account_name'] }) =>
  [{ url: '/api/v1/accounts/:account_name/eval_maintainers', params: { accountName: accountName } }] as const

export type GetAccountEvalMaintainersQueryKey = ReturnType<typeof getAccountEvalMaintainersQueryKey>

/**
 * @description Get account eval maintainers.
 * @summary Get Account Eval Maintainers
 * {@link /api/v1/accounts/:account_name/eval_maintainers}
 */
async function getAccountEvalMaintainers(
  { accountName }: { accountName: GetAccountEvalMaintainersPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetAccountEvalMaintainersQueryResponse, GetAccountEvalMaintainers422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/eval_maintainers`,
    ...config,
  })
  return res.data
}

export function getAccountEvalMaintainersQueryOptions(
  { accountName }: { accountName: GetAccountEvalMaintainersPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getAccountEvalMaintainersQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAccountEvalMaintainers({ accountName }, config)
    },
  })
}

/**
 * @description Get account eval maintainers.
 * @summary Get Account Eval Maintainers
 * {@link /api/v1/accounts/:account_name/eval_maintainers}
 */
export function useGetAccountEvalMaintainers<
  TData = GetAccountEvalMaintainersQueryResponse,
  TQueryData = GetAccountEvalMaintainersQueryResponse,
  TQueryKey extends QueryKey = GetAccountEvalMaintainersQueryKey,
>(
  { accountName }: { accountName: GetAccountEvalMaintainersPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<GetAccountEvalMaintainersQueryResponse, GetAccountEvalMaintainers422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAccountEvalMaintainersQueryKey({ accountName })

  const query = useQuery({
    ...(getAccountEvalMaintainersQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetAccountEvalMaintainers422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}