// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useEnrichFromMode.ts"
);
import.meta.hot.lastModified = "1737759759414.0305";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { EnrichFromModeMutationResponse, EnrichFromModePathParams, EnrichFromModeQueryParams, EnrichFromMode422 } from '../types/EnrichFromMode'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const enrichFromModeMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/enrich_from_mode' }] as const

export type EnrichFromModeMutationKey = ReturnType<typeof enrichFromModeMutationKey>

/**
 * @description Enrich an SSDS from a mode report.This is intended to be applied to data sources that are created via`create_table_from_report` since we assume there is a 1-1 linking betweenthe data source and report.
 * @summary Enrich From Mode
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/enrich_from_mode}
 */
async function enrichFromMode(
  { accountName, params }: { accountName: EnrichFromModePathParams['account_name']; params: EnrichFromModeQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<EnrichFromModeMutationResponse, EnrichFromMode422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/enrich_from_mode`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Enrich an SSDS from a mode report.This is intended to be applied to data sources that are created via`create_table_from_report` since we assume there is a 1-1 linking betweenthe data source and report.
 * @summary Enrich From Mode
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/enrich_from_mode}
 */
export function useEnrichFromMode(
  options: {
    mutation?: UseMutationOptions<
      EnrichFromModeMutationResponse,
      EnrichFromMode422,
      { accountName: EnrichFromModePathParams['account_name']; params: EnrichFromModeQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? enrichFromModeMutationKey()

  return useMutation<
    EnrichFromModeMutationResponse,
    EnrichFromMode422,
    { accountName: EnrichFromModePathParams['account_name']; params: EnrichFromModeQueryParams }
  >({
    mutationFn: async ({ accountName, params }) => {
      return enrichFromMode({ accountName, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}