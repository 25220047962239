// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useListTablesMetadata.ts"
);
import.meta.hot.lastModified = "1737759760438.0251";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ListTablesMetadataMutationRequest,
  ListTablesMetadataMutationResponse,
  ListTablesMetadataPathParams,
  ListTablesMetadata422,
} from '../types/ListTablesMetadata'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const listTablesMetadataMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/{connection_id}/tables/metadata' }] as const

export type ListTablesMetadataMutationKey = ReturnType<typeof listTablesMetadataMutationKey>

/**
 * @description List tables metadata.Tables: list of table names in the format database.schema.table (or project.dataset.table for BigQuery)
 * @summary List Tables Metadata
 * {@link /api/v1/accounts/:account_name/connections/:connection_id/tables/metadata}
 */
async function listTablesMetadata(
  {
    accountName,
    connectionId,
    data,
  }: {
    accountName: ListTablesMetadataPathParams['account_name']
    connectionId: ListTablesMetadataPathParams['connection_id']
    data?: ListTablesMetadataMutationRequest
  },
  config: Partial<RequestConfig<ListTablesMetadataMutationRequest>> = {},
) {
  const res = await client<ListTablesMetadataMutationResponse, ListTablesMetadata422, ListTablesMetadataMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/${connectionId}/tables/metadata`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description List tables metadata.Tables: list of table names in the format database.schema.table (or project.dataset.table for BigQuery)
 * @summary List Tables Metadata
 * {@link /api/v1/accounts/:account_name/connections/:connection_id/tables/metadata}
 */
export function useListTablesMetadata(
  options: {
    mutation?: UseMutationOptions<
      ListTablesMetadataMutationResponse,
      ListTablesMetadata422,
      {
        accountName: ListTablesMetadataPathParams['account_name']
        connectionId: ListTablesMetadataPathParams['connection_id']
        data?: ListTablesMetadataMutationRequest
      }
    >
    client?: Partial<RequestConfig<ListTablesMetadataMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? listTablesMetadataMutationKey()

  return useMutation<
    ListTablesMetadataMutationResponse,
    ListTablesMetadata422,
    {
      accountName: ListTablesMetadataPathParams['account_name']
      connectionId: ListTablesMetadataPathParams['connection_id']
      data?: ListTablesMetadataMutationRequest
    }
  >({
    mutationFn: async ({ accountName, connectionId, data }) => {
      return listTablesMetadata({ accountName, connectionId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}