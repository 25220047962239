// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useListBigqueryTables.ts"
);
import.meta.hot.lastModified = "1737935767642.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ListBigqueryTablesQueryResponse,
  ListBigqueryTablesPathParams,
  ListBigqueryTablesQueryParams,
  ListBigqueryTables422,
} from '../types/ListBigqueryTables'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listBigqueryTablesQueryKey = (
  { accountName, connectionId }: { accountName: ListBigqueryTablesPathParams['account_name']; connectionId: ListBigqueryTablesPathParams['connection_id'] },
  params?: ListBigqueryTablesQueryParams,
) =>
  [
    { url: '/api/v1/accounts/:account_name/connections/bigquery/:connection_id/tables', params: { accountName: accountName, connectionId: connectionId } },
    ...(params ? [params] : []),
  ] as const

export type ListBigqueryTablesQueryKey = ReturnType<typeof listBigqueryTablesQueryKey>

/**
 * @description List BigQuery tables.
 * @summary List Bigquery Tables
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id/tables}
 */
async function listBigqueryTables(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListBigqueryTablesPathParams['account_name']
    connectionId: ListBigqueryTablesPathParams['connection_id']
    params?: ListBigqueryTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ListBigqueryTablesQueryResponse, ListBigqueryTables422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/bigquery/${connectionId}/tables`,
    params,
    ...config,
  })
  return res.data
}

export function listBigqueryTablesQueryOptions(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListBigqueryTablesPathParams['account_name']
    connectionId: ListBigqueryTablesPathParams['connection_id']
    params?: ListBigqueryTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = listBigqueryTablesQueryKey({ accountName, connectionId }, params)
  return queryOptions({
    enabled: !!(accountName && connectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listBigqueryTables({ accountName, connectionId, params }, config)
    },
  })
}

/**
 * @description List BigQuery tables.
 * @summary List Bigquery Tables
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id/tables}
 */
export function useListBigqueryTables<
  TData = ListBigqueryTablesQueryResponse,
  TQueryData = ListBigqueryTablesQueryResponse,
  TQueryKey extends QueryKey = ListBigqueryTablesQueryKey,
>(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListBigqueryTablesPathParams['account_name']
    connectionId: ListBigqueryTablesPathParams['connection_id']
    params?: ListBigqueryTablesQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ListBigqueryTablesQueryResponse, ListBigqueryTables422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listBigqueryTablesQueryKey({ accountName, connectionId }, params)

  const query = useQuery({
    ...(listBigqueryTablesQueryOptions({ accountName, connectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListBigqueryTables422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}