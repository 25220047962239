// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useInjectNewDimensionsAndMetrics.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  InjectNewDimensionsAndMetricsMutationRequest,
  InjectNewDimensionsAndMetricsMutationResponse,
  InjectNewDimensionsAndMetricsPathParams,
  InjectNewDimensionsAndMetricsQueryParams,
  InjectNewDimensionsAndMetrics422,
} from '../types/InjectNewDimensionsAndMetrics'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const injectNewDimensionsAndMetricsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/inject_new_dimensions_and_metrics' }] as const

export type InjectNewDimensionsAndMetricsMutationKey = ReturnType<typeof injectNewDimensionsAndMetricsMutationKey>

/**
 * @description Inject new dimensions and metrics from the current SqlValidation objects in the SSDS.
 * @summary Inject New Dimensions And Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/inject_new_dimensions_and_metrics}
 */
async function injectNewDimensionsAndMetrics(
  {
    accountName,
    data,
    params,
  }: {
    accountName: InjectNewDimensionsAndMetricsPathParams['account_name']
    data: InjectNewDimensionsAndMetricsMutationRequest
    params?: InjectNewDimensionsAndMetricsQueryParams
  },
  config: Partial<RequestConfig<InjectNewDimensionsAndMetricsMutationRequest>> = {},
) {
  const res = await client<InjectNewDimensionsAndMetricsMutationResponse, InjectNewDimensionsAndMetrics422, InjectNewDimensionsAndMetricsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/inject_new_dimensions_and_metrics`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Inject new dimensions and metrics from the current SqlValidation objects in the SSDS.
 * @summary Inject New Dimensions And Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/inject_new_dimensions_and_metrics}
 */
export function useInjectNewDimensionsAndMetrics(
  options: {
    mutation?: UseMutationOptions<
      InjectNewDimensionsAndMetricsMutationResponse,
      InjectNewDimensionsAndMetrics422,
      {
        accountName: InjectNewDimensionsAndMetricsPathParams['account_name']
        data: InjectNewDimensionsAndMetricsMutationRequest
        params?: InjectNewDimensionsAndMetricsQueryParams
      }
    >
    client?: Partial<RequestConfig<InjectNewDimensionsAndMetricsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? injectNewDimensionsAndMetricsMutationKey()

  return useMutation<
    InjectNewDimensionsAndMetricsMutationResponse,
    InjectNewDimensionsAndMetrics422,
    {
      accountName: InjectNewDimensionsAndMetricsPathParams['account_name']
      data: InjectNewDimensionsAndMetricsMutationRequest
      params?: InjectNewDimensionsAndMetricsQueryParams
    }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return injectNewDimensionsAndMetrics({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}