// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/knowledgeOriginFilterSchema.ts"
);
import.meta.hot.lastModified = "1737759761259.021";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Knowledge origin filter.
 */
export const knowledgeOriginFilterSchema = z
  .enum(['all', 'ai', 'admin', 'log', 'schema', 'dashboard', 'dictionary', 'feedback_suggestion'])
  .describe('Knowledge origin filter.')