// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateMetricIsSensitiveSchema.ts"
);
import.meta.hot.lastModified = "1737759761631.019";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { metricDetailSchema } from './metricDetailSchema'
import { z } from 'zod'

export const updateMetricIsSensitivePathParamsSchema = z.object({
  account_name: z.string(),
  metric_id: z.string(),
})

export const updateMetricIsSensitiveQueryParamsSchema = z.object({
  is_sensitive: z.boolean(),
})

/**
 * @description Successful Response
 */
export const updateMetricIsSensitive200Schema = z.lazy(() => metricDetailSchema)

/**
 * @description Validation Error
 */
export const updateMetricIsSensitive422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateMetricIsSensitiveMutationResponseSchema = z.lazy(() => updateMetricIsSensitive200Schema)