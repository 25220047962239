// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateTeradataConnection.ts"
);
import.meta.hot.lastModified = "1737759761660.0188";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateTeradataConnectionMutationRequest,
  UpdateTeradataConnectionMutationResponse,
  UpdateTeradataConnectionPathParams,
  UpdateTeradataConnection422,
} from '../types/UpdateTeradataConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateTeradataConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}' }] as const

export type UpdateTeradataConnectionMutationKey = ReturnType<typeof updateTeradataConnectionMutationKey>

/**
 * @description Update teradata connection.
 * @summary Update Teradata Connection
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id}
 */
async function updateTeradataConnection(
  {
    accountName,
    teradataConnectionId,
    data,
  }: {
    accountName: UpdateTeradataConnectionPathParams['account_name']
    teradataConnectionId: UpdateTeradataConnectionPathParams['teradata_connection_id']
    data?: UpdateTeradataConnectionMutationRequest
  },
  config: Partial<RequestConfig<UpdateTeradataConnectionMutationRequest>> = {},
) {
  const res = await client<UpdateTeradataConnectionMutationResponse, UpdateTeradataConnection422, UpdateTeradataConnectionMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/connections/teradata/${teradataConnectionId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update teradata connection.
 * @summary Update Teradata Connection
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id}
 */
export function useUpdateTeradataConnection(
  options: {
    mutation?: UseMutationOptions<
      UpdateTeradataConnectionMutationResponse,
      UpdateTeradataConnection422,
      {
        accountName: UpdateTeradataConnectionPathParams['account_name']
        teradataConnectionId: UpdateTeradataConnectionPathParams['teradata_connection_id']
        data?: UpdateTeradataConnectionMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateTeradataConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateTeradataConnectionMutationKey()

  return useMutation<
    UpdateTeradataConnectionMutationResponse,
    UpdateTeradataConnection422,
    {
      accountName: UpdateTeradataConnectionPathParams['account_name']
      teradataConnectionId: UpdateTeradataConnectionPathParams['teradata_connection_id']
      data?: UpdateTeradataConnectionMutationRequest
    }
  >({
    mutationFn: async ({ accountName, teradataConnectionId, data }) => {
      return updateTeradataConnection({ accountName, teradataConnectionId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}