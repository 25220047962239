// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/knowledgeLayerContextApiSchema.ts"
);
import.meta.hot.lastModified = "1737935769001.74";
}
// REMIX HMR END

// @ts-nocheck
import { knowledgeLayerEntityApiSchema } from './knowledgeLayerEntityApiSchema'
import { knowledgeLayerLinkedQuestionApiSchema } from './knowledgeLayerLinkedQuestionApiSchema'
import { z } from 'zod'

/**
 * @description Knowledge layer entity response.
 */
export const knowledgeLayerContextApiSchema = z
  .object({
    entity: z.lazy(() => knowledgeLayerEntityApiSchema).describe('The entity associated with the context.'),
    markdown: z.string().describe('The markdown content of the context.'),
    example_questions: z
      .array(z.lazy(() => knowledgeLayerLinkedQuestionApiSchema))
      .describe('The example questions associated with the context. If there are no related example questions, this field will be empty.'),
    embedded_viz_url: z.union([z.string().url(), z.null()]).describe('The URL of an embedded visualization/dashboard.').optional(),
  })
  .describe('Knowledge layer entity response.')