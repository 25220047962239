// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ChatsFilter.ts"
);
import.meta.hot.lastModified = "1737759757712.0393";
}
// REMIX HMR END

export enum ChatsFilter {
  'is_pinned' = 'is_pinned',
  'is_by_user' = 'is_by_user',
  'is_eval' = 'is_eval',
  'is_feedback' = 'is_feedback',
  'all' = 'all',
}