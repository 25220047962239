// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadReportConnections.ts"
);
import.meta.hot.lastModified = "1737759761245.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadReportConnectionsQueryResponse,
  ReadReportConnectionsPathParams,
  ReadReportConnectionsQueryParams,
  ReadReportConnections422,
} from '../types/ReadReportConnections'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readReportConnectionsQueryKey = (
  { accountName }: { accountName: ReadReportConnectionsPathParams['account_name'] },
  params?: ReadReportConnectionsQueryParams,
) => [{ url: '/api/v1/accounts/:account_name/connections/reports/', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type ReadReportConnectionsQueryKey = ReturnType<typeof readReportConnectionsQueryKey>

/**
 * @description List existing report connections.
 * @summary Read Report Connections
 * {@link /api/v1/accounts/:account_name/connections/reports/}
 */
async function readReportConnections(
  { accountName, params }: { accountName: ReadReportConnectionsPathParams['account_name']; params?: ReadReportConnectionsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadReportConnectionsQueryResponse, ReadReportConnections422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/reports/`,
    params,
    ...config,
  })
  return res.data
}

export function readReportConnectionsQueryOptions(
  { accountName, params }: { accountName: ReadReportConnectionsPathParams['account_name']; params?: ReadReportConnectionsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readReportConnectionsQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readReportConnections({ accountName, params }, config)
    },
  })
}

/**
 * @description List existing report connections.
 * @summary Read Report Connections
 * {@link /api/v1/accounts/:account_name/connections/reports/}
 */
export function useReadReportConnections<
  TData = ReadReportConnectionsQueryResponse,
  TQueryData = ReadReportConnectionsQueryResponse,
  TQueryKey extends QueryKey = ReadReportConnectionsQueryKey,
>(
  { accountName, params }: { accountName: ReadReportConnectionsPathParams['account_name']; params?: ReadReportConnectionsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadReportConnectionsQueryResponse, ReadReportConnections422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readReportConnectionsQueryKey({ accountName }, params)

  const query = useQuery({
    ...(readReportConnectionsQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadReportConnections422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}