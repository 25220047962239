// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getSsdsDimensionsSchema.ts"
);
import.meta.hot.lastModified = "1737759760438.0251";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionViewSchema } from './dimensionViewSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { knowledgeOriginFilterSchema } from './knowledgeOriginFilterSchema'
import { knowledgeStateFilterSchema } from './knowledgeStateFilterSchema'
import { z } from 'zod'

export const getSsdsDimensionsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const getSsdsDimensionsQueryParamsSchema = z
  .object({
    limit: z.union([z.number().int(), z.null()]).default(200),
    skip: z.number().int().default(0),
    search: z.string().default(''),
    state: z.union([z.lazy(() => knowledgeStateFilterSchema), z.null()]).default('valid'),
    origin: z.union([z.lazy(() => knowledgeOriginFilterSchema), z.null()]).default('all'),
    data_source_ids: z.union([z.array(z.string()), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getSsdsDimensions200Schema = z.array(z.lazy(() => dimensionViewSchema))

/**
 * @description Validation Error
 */
export const getSsdsDimensions422Schema = z.lazy(() => httpValidationErrorSchema)

export const getSsdsDimensionsQueryResponseSchema = z.lazy(() => getSsdsDimensions200Schema)