// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/KnowledgeOriginFilter.ts"
);
import.meta.hot.lastModified = "1737935767642.7473";
}
// REMIX HMR END

export enum KnowledgeOriginFilter {
  'all' = 'all',
  'ai' = 'ai',
  'admin' = 'admin',
  'log' = 'log',
  'schema' = 'schema',
  'dashboard' = 'dashboard',
  'dictionary' = 'dictionary',
  'feedback_suggestion' = 'feedback_suggestion',
}