// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/verifyCellSchema.ts"
);
import.meta.hot.lastModified = "1737759758214.0366";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellSchema } from './bumblebeeCellSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const verifyCellPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

export const verifyCellQueryParamsSchema = z
  .object({
    allow_conflicting_feedback: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const verifyCell201Schema = z.lazy(() => bumblebeeCellSchema)

/**
 * @description Validation Error
 */
export const verifyCell422Schema = z.lazy(() => httpValidationErrorSchema)

export const verifyCellMutationResponseSchema = z.lazy(() => verifyCell201Schema)