// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getStarSchemaDataSourceConversationsSchema.ts"
);
import.meta.hot.lastModified = "1737759762549.0142";
}
// REMIX HMR END

// @ts-nocheck
import { conversationBasicSchema } from './conversationBasicSchema'
import { conversationsSortSchema } from './conversationsSortSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getStarSchemaDataSourceConversationsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const getStarSchemaDataSourceConversationsQueryParamsSchema = z
  .object({
    limit: z.number().int().default(100),
    skip: z.number().int().default(0),
    show_hidden: z.boolean().default(false),
    show_eval: z.union([z.boolean(), z.null()]).optional(),
    show_moonlight: z.union([z.boolean(), z.null()]).optional(),
    show_training: z.union([z.boolean(), z.null()]).optional(),
    search_query: z.union([z.string(), z.null()]).optional(),
    creator_ids: z.union([z.array(z.string()), z.null()]).optional(),
    order_by: z.lazy(() => conversationsSortSchema).default('last_modified_at'),
    descending: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getStarSchemaDataSourceConversations200Schema = z.array(z.lazy(() => conversationBasicSchema))

/**
 * @description Validation Error
 */
export const getStarSchemaDataSourceConversations422Schema = z.lazy(() => httpValidationErrorSchema)

export const getStarSchemaDataSourceConversationsQueryResponseSchema = z.lazy(() => getStarSchemaDataSourceConversations200Schema)