// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tableSchema.ts"
);
import.meta.hot.lastModified = "1737759757506.0403";
}
// REMIX HMR END

// @ts-nocheck
import { foreignKeySchema } from './foreignKeySchema'
import { tableColumnSchema } from './tableColumnSchema'
import { unionStatisticSchema } from './unionStatisticSchema'
import { z } from 'zod'

/**
 * @description Table.
 */
export const tableSchema = z
  .object({
    name: z.union([z.string(), z.null()]).optional(),
    display_name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    columns: z.union([z.array(z.lazy(() => tableColumnSchema)), z.null()]).optional(),
    schema_name: z.union([z.string(), z.null()]).optional(),
    db_name: z.union([z.string(), z.null()]).optional(),
    pks: z.union([z.array(z.lazy(() => tableColumnSchema)), z.null()]).optional(),
    fks: z.union([z.array(z.lazy(() => foreignKeySchema)), z.null()]).optional(),
    is_reference_table: z.boolean().default(false),
    profiles: z
      .union([
        z
          .object({})
          .strict()
          .catchall(z.lazy(() => unionStatisticSchema)),
        z.null(),
      ])
      .optional(),
    select_from_sql: z.union([z.string(), z.null()]).optional(),
    ds_id: z.union([z.string(), z.null()]).optional(),
    index: z.number().int().default(0),
  })
  .describe('Table.')