// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadReportFolders.ts"
);
import.meta.hot.lastModified = "1737935767415.7485";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadReportFoldersQueryResponse, ReadReportFoldersPathParams, ReadReportFolders422 } from '../types/ReadReportFolders'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readReportFoldersQueryKey = ({
  accountName,
  connectionType,
  connectionId,
}: {
  accountName: ReadReportFoldersPathParams['account_name']
  connectionType: ReadReportFoldersPathParams['connection_type']
  connectionId: ReadReportFoldersPathParams['connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/reports/:connection_type/:connection_id/folders',
      params: { accountName: accountName, connectionType: connectionType, connectionId: connectionId },
    },
  ] as const

export type ReadReportFoldersQueryKey = ReturnType<typeof readReportFoldersQueryKey>

/**
 * @description Read the report folders.These are high-level collections that contain reports (e.g. Tableau projects or Mode collections).
 * @summary Read Report Folders
 * {@link /api/v1/accounts/:account_name/connections/reports/:connection_type/:connection_id/folders}
 */
async function readReportFolders(
  {
    accountName,
    connectionType,
    connectionId,
  }: {
    accountName: ReadReportFoldersPathParams['account_name']
    connectionType: ReadReportFoldersPathParams['connection_type']
    connectionId: ReadReportFoldersPathParams['connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadReportFoldersQueryResponse, ReadReportFolders422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/reports/${connectionType}/${connectionId}/folders`,
    ...config,
  })
  return res.data
}

export function readReportFoldersQueryOptions(
  {
    accountName,
    connectionType,
    connectionId,
  }: {
    accountName: ReadReportFoldersPathParams['account_name']
    connectionType: ReadReportFoldersPathParams['connection_type']
    connectionId: ReadReportFoldersPathParams['connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readReportFoldersQueryKey({ accountName, connectionType, connectionId })
  return queryOptions({
    enabled: !!(accountName && connectionType && connectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readReportFolders({ accountName, connectionType, connectionId }, config)
    },
  })
}

/**
 * @description Read the report folders.These are high-level collections that contain reports (e.g. Tableau projects or Mode collections).
 * @summary Read Report Folders
 * {@link /api/v1/accounts/:account_name/connections/reports/:connection_type/:connection_id/folders}
 */
export function useReadReportFolders<
  TData = ReadReportFoldersQueryResponse,
  TQueryData = ReadReportFoldersQueryResponse,
  TQueryKey extends QueryKey = ReadReportFoldersQueryKey,
>(
  {
    accountName,
    connectionType,
    connectionId,
  }: {
    accountName: ReadReportFoldersPathParams['account_name']
    connectionType: ReadReportFoldersPathParams['connection_type']
    connectionId: ReadReportFoldersPathParams['connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadReportFoldersQueryResponse, ReadReportFolders422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readReportFoldersQueryKey({ accountName, connectionType, connectionId })

  const query = useQuery({
    ...(readReportFoldersQueryOptions({ accountName, connectionType, connectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadReportFolders422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}