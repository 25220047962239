// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readConversationMessagesSchema.ts"
);
import.meta.hot.lastModified = "1737759761850.0178";
}
// REMIX HMR END

// @ts-nocheck
import { conversationApiReadResponseSchema } from './conversationApiReadResponseSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { messagePayloadTypeSchema } from './messagePayloadTypeSchema'
import { z } from 'zod'

export const readConversationMessagesPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  conversation_id: z.string(),
})

export const readConversationMessagesQueryParamsSchema = z
  .object({
    accepted_payload_types: z.union([z.array(z.lazy(() => messagePayloadTypeSchema)), z.null()]).optional(),
    limit: z.number().int().default(20),
    offset: z.number().int().default(0),
    bound: z.number().int().default(0),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readConversationMessages200Schema = z.lazy(() => conversationApiReadResponseSchema)

/**
 * @description Validation Error
 */
export const readConversationMessages422Schema = z.lazy(() => httpValidationErrorSchema)

export const readConversationMessagesQueryResponseSchema = z.lazy(() => readConversationMessages200Schema)