// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/DatasetJobType.ts"
);
import.meta.hot.lastModified = "1737759746464.0972";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumerates the possible dataset job types.
 */
export enum DatasetJobType {
  UPDATE = 'UPDATE',
  COPY = 'COPY',
}
