// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getQuestionModelcallsSchema.ts"
);
import.meta.hot.lastModified = "1737759761259.021";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { promptLogComponentSchema } from './promptLogComponentSchema'
import { z } from 'zod'

export const getQuestionModelcallsQueryParamsSchema = z.object({
  question: z.string(),
  component: z.union([z.lazy(() => promptLogComponentSchema), z.null()]).optional(),
  limit: z.number().int().default(5),
})

/**
 * @description Successful Response
 */
export const getQuestionModelcalls200Schema = z.array(z.unknown())

/**
 * @description Validation Error
 */
export const getQuestionModelcalls422Schema = z.lazy(() => httpValidationErrorSchema)

export const getQuestionModelcallsQueryResponseSchema = z.lazy(() => getQuestionModelcalls200Schema)