// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/joinValidationResponseSchema.ts"
);
import.meta.hot.lastModified = "1737759761448.02";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description The output of the validate_join endpoint.
 */
export const joinValidationResponseSchema = z
  .object({
    num_of_rows: z.number().int(),
    message: z.string().default(''),
  })
  .describe('The output of the validate_join endpoint.')