// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/retrieveEmbeddingSearchSchema.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const retrieveEmbeddingSearchPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const retrieveEmbeddingSearchQueryParamsSchema = z.object({
  question: z.string(),
  num_values_to_retrieve: z.number().int().default(5),
})

/**
 * @description Successful Response
 */
export const retrieveEmbeddingSearch200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const retrieveEmbeddingSearch422Schema = z.lazy(() => httpValidationErrorSchema)

export const retrieveEmbeddingSearchQueryResponseSchema = z.lazy(() => retrieveEmbeddingSearch200Schema)