// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readTableauConnectionWorkbooksSchema.ts"
);
import.meta.hot.lastModified = "1737935769638.7363";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { reportSchema } from './reportSchema'
import { z } from 'zod'

export const readTableauConnectionWorkbooksPathParamsSchema = z.object({
  account_name: z.string(),
  tableau_connection_id: z.string(),
})

export const readTableauConnectionWorkbooksQueryParamsSchema = z
  .object({
    project_name: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readTableauConnectionWorkbooks200Schema = z.array(z.lazy(() => reportSchema))

/**
 * @description Validation Error
 */
export const readTableauConnectionWorkbooks422Schema = z.lazy(() => httpValidationErrorSchema)

export const readTableauConnectionWorkbooksQueryResponseSchema = z.lazy(() => readTableauConnectionWorkbooks200Schema)