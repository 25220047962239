// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateWidget.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateWidgetMutationRequest, UpdateWidgetMutationResponse, UpdateWidgetPathParams, UpdateWidget422 } from '../types/UpdateWidget'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateWidgetMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/widgets/{widget_id}' }] as const

export type UpdateWidgetMutationKey = ReturnType<typeof updateWidgetMutationKey>

/**
 * @description Update widget by ID.Currently this endpoint supports updating the `options` and the `key` fields.
 * @summary Update Widget
 * {@link /api/v3/orgs/:account_name/widgets/:widget_id}
 */
async function updateWidget(
  {
    accountName,
    widgetId,
    data,
  }: { accountName: UpdateWidgetPathParams['account_name']; widgetId: UpdateWidgetPathParams['widget_id']; data?: UpdateWidgetMutationRequest },
  config: Partial<RequestConfig<UpdateWidgetMutationRequest>> = {},
) {
  const res = await client<UpdateWidgetMutationResponse, UpdateWidget422, UpdateWidgetMutationRequest>({
    method: 'PUT',
    url: `/api/v3/orgs/${accountName}/widgets/${widgetId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update widget by ID.Currently this endpoint supports updating the `options` and the `key` fields.
 * @summary Update Widget
 * {@link /api/v3/orgs/:account_name/widgets/:widget_id}
 */
export function useUpdateWidget(
  options: {
    mutation?: UseMutationOptions<
      UpdateWidgetMutationResponse,
      UpdateWidget422,
      { accountName: UpdateWidgetPathParams['account_name']; widgetId: UpdateWidgetPathParams['widget_id']; data?: UpdateWidgetMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateWidgetMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateWidgetMutationKey()

  return useMutation<
    UpdateWidgetMutationResponse,
    UpdateWidget422,
    { accountName: UpdateWidgetPathParams['account_name']; widgetId: UpdateWidgetPathParams['widget_id']; data?: UpdateWidgetMutationRequest }
  >({
    mutationFn: async ({ accountName, widgetId, data }) => {
      return updateWidget({ accountName, widgetId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}