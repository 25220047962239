// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useProfileDataSource.ts"
);
import.meta.hot.lastModified = "1737759760238.0261";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ProfileDataSourceMutationResponse, ProfileDataSourcePathParams, ProfileDataSource422 } from '../types/ProfileDataSource'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const profileDataSourceMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}/profile' }] as const

export type ProfileDataSourceMutationKey = ReturnType<typeof profileDataSourceMutationKey>

/**
 * @description Get the data for a specific data source within an SSDS.
 * @summary Profile Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id/profile}
 */
async function profileDataSource(
  {
    accountName,
    starSchemaDataSourceId,
    dataSourceId,
  }: {
    accountName: ProfileDataSourcePathParams['account_name']
    starSchemaDataSourceId: ProfileDataSourcePathParams['star_schema_data_source_id']
    dataSourceId: ProfileDataSourcePathParams['data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ProfileDataSourceMutationResponse, ProfileDataSource422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/data_sources/${dataSourceId}/profile`,
    ...config,
  })
  return res.data
}

/**
 * @description Get the data for a specific data source within an SSDS.
 * @summary Profile Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id/profile}
 */
export function useProfileDataSource(
  options: {
    mutation?: UseMutationOptions<
      ProfileDataSourceMutationResponse,
      ProfileDataSource422,
      {
        accountName: ProfileDataSourcePathParams['account_name']
        starSchemaDataSourceId: ProfileDataSourcePathParams['star_schema_data_source_id']
        dataSourceId: ProfileDataSourcePathParams['data_source_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? profileDataSourceMutationKey()

  return useMutation<
    ProfileDataSourceMutationResponse,
    ProfileDataSource422,
    {
      accountName: ProfileDataSourcePathParams['account_name']
      starSchemaDataSourceId: ProfileDataSourcePathParams['star_schema_data_source_id']
      dataSourceId: ProfileDataSourcePathParams['data_source_id']
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, dataSourceId }) => {
      return profileDataSource({ accountName, starSchemaDataSourceId, dataSourceId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}