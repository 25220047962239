// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatInDbSchema.ts"
);
import.meta.hot.lastModified = "1737759757943.038";
}
// REMIX HMR END

// @ts-nocheck
import { chatAgentMessageInDbSchema } from './chatAgentMessageInDbSchema'
import { userBasicSchema } from './userBasicSchema'
import { z } from 'zod'

/**
 * @description Base class to map Pydantic Chat to ORM.
 */
export const chatInDbSchema = z
  .object({
    name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    account_id: z.string(),
    creator_id: z.string(),
    is_pinned: z.boolean().default(false),
    is_eval_chat: z.boolean().default(false),
    slack_id: z.union([z.string(), z.null()]).optional(),
    is_feedback_chat: z.boolean().default(false),
    linked_chat_id: z.union([z.string(), z.null()]).optional(),
    id: z.string(),
    sync_id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    messages: z.union([z.array(z.lazy(() => chatAgentMessageInDbSchema)), z.null()]).optional(),
    creator: z.union([z.lazy(() => userBasicSchema), z.null()]).optional(),
  })
  .describe('Base class to map Pydantic Chat to ORM.')