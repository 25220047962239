// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useListBigqueryColumns.ts"
);
import.meta.hot.lastModified = "1737935767879.746";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ListBigqueryColumnsQueryResponse,
  ListBigqueryColumnsPathParams,
  ListBigqueryColumnsQueryParams,
  ListBigqueryColumns422,
} from '../types/ListBigqueryColumns'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listBigqueryColumnsQueryKey = (
  { accountName, connectionId }: { accountName: ListBigqueryColumnsPathParams['account_name']; connectionId: ListBigqueryColumnsPathParams['connection_id'] },
  params: ListBigqueryColumnsQueryParams,
) =>
  [
    { url: '/api/v1/accounts/:account_name/connections/bigquery/:connection_id/columns', params: { accountName: accountName, connectionId: connectionId } },
    ...(params ? [params] : []),
  ] as const

export type ListBigqueryColumnsQueryKey = ReturnType<typeof listBigqueryColumnsQueryKey>

/**
 * @description List BigQuery columns.
 * @summary List Bigquery Columns
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id/columns}
 */
async function listBigqueryColumns(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListBigqueryColumnsPathParams['account_name']
    connectionId: ListBigqueryColumnsPathParams['connection_id']
    params: ListBigqueryColumnsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ListBigqueryColumnsQueryResponse, ListBigqueryColumns422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/bigquery/${connectionId}/columns`,
    params,
    ...config,
  })
  return res.data
}

export function listBigqueryColumnsQueryOptions(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListBigqueryColumnsPathParams['account_name']
    connectionId: ListBigqueryColumnsPathParams['connection_id']
    params: ListBigqueryColumnsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = listBigqueryColumnsQueryKey({ accountName, connectionId }, params)
  return queryOptions({
    enabled: !!(accountName && connectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listBigqueryColumns({ accountName, connectionId, params }, config)
    },
  })
}

/**
 * @description List BigQuery columns.
 * @summary List Bigquery Columns
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id/columns}
 */
export function useListBigqueryColumns<
  TData = ListBigqueryColumnsQueryResponse,
  TQueryData = ListBigqueryColumnsQueryResponse,
  TQueryKey extends QueryKey = ListBigqueryColumnsQueryKey,
>(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListBigqueryColumnsPathParams['account_name']
    connectionId: ListBigqueryColumnsPathParams['connection_id']
    params: ListBigqueryColumnsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ListBigqueryColumnsQueryResponse, ListBigqueryColumns422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listBigqueryColumnsQueryKey({ accountName, connectionId }, params)

  const query = useQuery({
    ...(listBigqueryColumnsQueryOptions({ accountName, connectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListBigqueryColumns422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}