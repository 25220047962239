// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDimensions.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateDimensionsMutationRequest,
  UpdateDimensionsMutationResponse,
  UpdateDimensionsPathParams,
  UpdateDimensions422,
} from '../types/UpdateDimensions'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDimensionsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions' }] as const

export type UpdateDimensionsMutationKey = ReturnType<typeof updateDimensionsMutationKey>

/**
 * @description Bulk update multiple dimensions at once.
 * @summary Update Dimensions
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions}
 */
async function updateDimensions(
  { accountName, data }: { accountName: UpdateDimensionsPathParams['account_name']; data?: UpdateDimensionsMutationRequest },
  config: Partial<RequestConfig<UpdateDimensionsMutationRequest>> = {},
) {
  const res = await client<UpdateDimensionsMutationResponse, UpdateDimensions422, UpdateDimensionsMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Bulk update multiple dimensions at once.
 * @summary Update Dimensions
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions}
 */
export function useUpdateDimensions(
  options: {
    mutation?: UseMutationOptions<
      UpdateDimensionsMutationResponse,
      UpdateDimensions422,
      { accountName: UpdateDimensionsPathParams['account_name']; data?: UpdateDimensionsMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateDimensionsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDimensionsMutationKey()

  return useMutation<
    UpdateDimensionsMutationResponse,
    UpdateDimensions422,
    { accountName: UpdateDimensionsPathParams['account_name']; data?: UpdateDimensionsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return updateDimensions({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}