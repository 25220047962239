// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateMetricIsSensitive.ts"
);
import.meta.hot.lastModified = "1737935768622.742";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateMetricIsSensitiveMutationResponse,
  UpdateMetricIsSensitivePathParams,
  UpdateMetricIsSensitiveQueryParams,
  UpdateMetricIsSensitive422,
} from '../types/UpdateMetricIsSensitive'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateMetricIsSensitiveMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/is_sensitive' }] as const

export type UpdateMetricIsSensitiveMutationKey = ReturnType<typeof updateMetricIsSensitiveMutationKey>

/**
 * @description Update a metric for is_sensitive.
 * @summary Update Metric Is Sensitive
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/is_sensitive}
 */
async function updateMetricIsSensitive(
  {
    accountName,
    metricId,
    params,
  }: {
    accountName: UpdateMetricIsSensitivePathParams['account_name']
    metricId: UpdateMetricIsSensitivePathParams['metric_id']
    params: UpdateMetricIsSensitiveQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateMetricIsSensitiveMutationResponse, UpdateMetricIsSensitive422, unknown>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/${metricId}/is_sensitive`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Update a metric for is_sensitive.
 * @summary Update Metric Is Sensitive
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/is_sensitive}
 */
export function useUpdateMetricIsSensitive(
  options: {
    mutation?: UseMutationOptions<
      UpdateMetricIsSensitiveMutationResponse,
      UpdateMetricIsSensitive422,
      {
        accountName: UpdateMetricIsSensitivePathParams['account_name']
        metricId: UpdateMetricIsSensitivePathParams['metric_id']
        params: UpdateMetricIsSensitiveQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMetricIsSensitiveMutationKey()

  return useMutation<
    UpdateMetricIsSensitiveMutationResponse,
    UpdateMetricIsSensitive422,
    {
      accountName: UpdateMetricIsSensitivePathParams['account_name']
      metricId: UpdateMetricIsSensitivePathParams['metric_id']
      params: UpdateMetricIsSensitiveQueryParams
    }
  >({
    mutationFn: async ({ accountName, metricId, params }) => {
      return updateMetricIsSensitive({ accountName, metricId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}