// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/TableFilter.ts"
);
import.meta.hot.lastModified = "1737935764870.7627";
}
// REMIX HMR END

import type { TableFilterCondition } from './TableFilterCondition'

export enum TableFilterConjunction {
  'AND' = 'AND',
  'OR' = 'OR',
}

/**
 * @description Table filter model.
 */
export type TableFilter = {
  /**
   * @type array
   */
  conditions: TableFilterCondition[]
  /**
   * @default "AND"
   * @type string | undefined
   */
  conjunction?: TableFilterConjunction
}