// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/MessageFeedbackFilter.ts"
);
import.meta.hot.lastModified = "1737759760438.0251";
}
// REMIX HMR END

export enum MessageFeedbackFilter {
  'True' = 'True',
  'False' = 'False',
  'None' = 'None',
}