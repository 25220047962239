// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createEvalCellSchema.ts"
);
import.meta.hot.lastModified = "1737935766487.7537";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellSchema } from './bumblebeeCellSchema'
import { evalBumblebeeCellApiCreateSchema } from './evalBumblebeeCellApiCreateSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const createEvalCellPathParamsSchema = z.object({
  account_name: z.string(),
})

export const createEvalCellQueryParamsSchema = z
  .object({
    source_cell_id: z.union([z.string(), z.null()]).optional(),
    conversation_id: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const createEvalCell201Schema = z.lazy(() => bumblebeeCellSchema)

/**
 * @description Validation Error
 */
export const createEvalCell422Schema = z.lazy(() => httpValidationErrorSchema)

export const createEvalCellMutationRequestSchema = z.union([z.lazy(() => evalBumblebeeCellApiCreateSchema), z.null()])

export const createEvalCellMutationResponseSchema = z.lazy(() => createEvalCell201Schema)