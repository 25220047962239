// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/identifierSuggestionResponseSchema.ts"
);
import.meta.hot.lastModified = "1737759762294.0156";
}
// REMIX HMR END

// @ts-nocheck
import { identifierSchema } from './identifierSchema'
import { z } from 'zod'

/**
 * @description The output of the suggest_ssds_identifiers endpoint.
 */
export const identifierSuggestionResponseSchema = z
  .object({
    suggestions: z.array(z.lazy(() => identifierSchema)),
  })
  .describe('The output of the suggest_ssds_identifiers endpoint.')