// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/conflictStatusSchema.ts"
);
import.meta.hot.lastModified = "1737759759419.0305";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Status for reviewing conflicts.
 */
export const conflictStatusSchema = z.enum(['CONFIRMED', 'UNCONFIRMED']).describe('Status for reviewing conflicts.')