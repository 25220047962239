// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/TasksService.ts"
);
import.meta.hot.lastModified = "1737759746312.0981";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Task } from '../models/Task';
import type { TaskName } from '../models/TaskName';
import type { TaskPage } from '../models/TaskPage';
import type { TaskStatus } from '../models/TaskStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TasksService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read Tasks By Ids
   * Get specific task(s) by id(s).
   * @param accountName The name of the organization or account.
   * @param taskId A list of task ids to retrieve.
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public readTasksByIds(
    accountName: string,
    taskId?: Array<string>,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Task>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/tasks/ids',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'task_id': taskId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Task By Id
   * Get a specific task by id.
   * @param accountName
   * @param taskId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public readTaskById(
    accountName: string,
    taskId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/tasks/{task_id}',
      path: {
        'account_name': accountName,
        'task_id': taskId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Tasks By Account
   * Read tasks by account.
   * @param accountName
   * @param skip
   * @param limit
   * @param name
   * @param status
   * @param sessionId
   * @returns TaskPage Successful Response
   * @throws ApiError
   */
  public readTasksByAccount(
    accountName: string,
    skip?: number,
    limit: number = 100,
    name?: (TaskName | null),
    status?: (TaskStatus | null),
    sessionId?: (string | null),
  ): CancelablePromise<TaskPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/tasks',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'skip': skip,
        'limit': limit,
        'name': name,
        'status': status,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
