// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chartSelectDmmTypeSchema.ts"
);
import.meta.hot.lastModified = "1737935767870.746";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Enumerates the possible DMM types for a chart select.
 */
export const chartSelectDmmTypeSchema = z.enum(['metric', 'dimension']).describe('Enumerates the possible DMM types for a chart select.')