// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateWidget.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateWidgetMutationResponse, CreateWidgetPathParams, CreateWidgetQueryParams, CreateWidget422 } from '../types/CreateWidget'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createWidgetMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/widgets/' }] as const

export type CreateWidgetMutationKey = ReturnType<typeof createWidgetMutationKey>

/**
 * @description Create a widget.For now, we only allow one widget per account.
 * @summary Create Widget
 * {@link /api/v3/orgs/:account_name/widgets/}
 */
async function createWidget(
  { accountName, params }: { accountName: CreateWidgetPathParams['account_name']; params?: CreateWidgetQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<CreateWidgetMutationResponse, CreateWidget422, unknown>({
    method: 'POST',
    url: `/api/v3/orgs/${accountName}/widgets/`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Create a widget.For now, we only allow one widget per account.
 * @summary Create Widget
 * {@link /api/v3/orgs/:account_name/widgets/}
 */
export function useCreateWidget(
  options: {
    mutation?: UseMutationOptions<
      CreateWidgetMutationResponse,
      CreateWidget422,
      { accountName: CreateWidgetPathParams['account_name']; params?: CreateWidgetQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createWidgetMutationKey()

  return useMutation<CreateWidgetMutationResponse, CreateWidget422, { accountName: CreateWidgetPathParams['account_name']; params?: CreateWidgetQueryParams }>({
    mutationFn: async ({ accountName, params }) => {
      return createWidget({ accountName, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}