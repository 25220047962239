// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSetDimensionDisplayType.ts"
);
import.meta.hot.lastModified = "1737759761469.0198";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  SetDimensionDisplayTypeMutationRequest,
  SetDimensionDisplayTypeMutationResponse,
  SetDimensionDisplayTypePathParams,
  SetDimensionDisplayType422,
} from '../types/SetDimensionDisplayType'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const setDimensionDisplayTypeMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/display' }] as const

export type SetDimensionDisplayTypeMutationKey = ReturnType<typeof setDimensionDisplayTypeMutationKey>

/**
 * @description Set a dimension's display_type
 * @summary Set Dimension Display Type
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/display}
 */
async function setDimensionDisplayType(
  {
    dimensionId,
    accountName,
    data,
  }: {
    dimensionId: SetDimensionDisplayTypePathParams['dimension_id']
    accountName: SetDimensionDisplayTypePathParams['account_name']
    data: SetDimensionDisplayTypeMutationRequest
  },
  config: Partial<RequestConfig<SetDimensionDisplayTypeMutationRequest>> = {},
) {
  const res = await client<SetDimensionDisplayTypeMutationResponse, SetDimensionDisplayType422, SetDimensionDisplayTypeMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions/${dimensionId}/display`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Set a dimension's display_type
 * @summary Set Dimension Display Type
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/display}
 */
export function useSetDimensionDisplayType(
  options: {
    mutation?: UseMutationOptions<
      SetDimensionDisplayTypeMutationResponse,
      SetDimensionDisplayType422,
      {
        dimensionId: SetDimensionDisplayTypePathParams['dimension_id']
        accountName: SetDimensionDisplayTypePathParams['account_name']
        data: SetDimensionDisplayTypeMutationRequest
      }
    >
    client?: Partial<RequestConfig<SetDimensionDisplayTypeMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? setDimensionDisplayTypeMutationKey()

  return useMutation<
    SetDimensionDisplayTypeMutationResponse,
    SetDimensionDisplayType422,
    {
      dimensionId: SetDimensionDisplayTypePathParams['dimension_id']
      accountName: SetDimensionDisplayTypePathParams['account_name']
      data: SetDimensionDisplayTypeMutationRequest
    }
  >({
    mutationFn: async ({ dimensionId, accountName, data }) => {
      return setDimensionDisplayType({ dimensionId, accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}