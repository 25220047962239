// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/CellOrderBy.ts"
);
import.meta.hot.lastModified = "1737759757712.0393";
}
// REMIX HMR END

export enum CellOrderBy {
  'id' = 'id',
  'created_at' = 'created_at',
  'creator_id' = 'creator_id',
  'updated_at' = 'updated_at',
  'user_question' = 'user_question',
}