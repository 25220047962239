// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadMessageTableDataAsCsv.ts"
);
import.meta.hot.lastModified = "1737759761863.0178";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadMessageTableDataAsCsvQueryResponse,
  ReadMessageTableDataAsCsvPathParams,
  ReadMessageTableDataAsCsv400,
  ReadMessageTableDataAsCsv404,
  ReadMessageTableDataAsCsv422,
} from '../types/ReadMessageTableDataAsCsv'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readMessageTableDataAsCsvQueryKey = ({
  accountName,
  messageId,
}: {
  accountName: ReadMessageTableDataAsCsvPathParams['account_name']
  messageId: ReadMessageTableDataAsCsvPathParams['message_id']
}) => [{ url: '/api/v3/orgs/:account_name/messages/:message_id/data/csv', params: { accountName: accountName, messageId: messageId } }] as const

export type ReadMessageTableDataAsCsvQueryKey = ReturnType<typeof readMessageTableDataAsCsvQueryKey>

/**
 * @description Read message table data as a CSV.This will return an error if the message does not contain a table ID.
 * @summary Read Message Table Data As Csv
 * {@link /api/v3/orgs/:account_name/messages/:message_id/data/csv}
 */
async function readMessageTableDataAsCsv(
  {
    accountName,
    messageId,
  }: { accountName: ReadMessageTableDataAsCsvPathParams['account_name']; messageId: ReadMessageTableDataAsCsvPathParams['message_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<
    ReadMessageTableDataAsCsvQueryResponse,
    ReadMessageTableDataAsCsv400 | ReadMessageTableDataAsCsv404 | ReadMessageTableDataAsCsv422,
    unknown
  >({ method: 'GET', url: `/api/v3/orgs/${accountName}/messages/${messageId}/data/csv`, ...config })
  return res.data
}

export function readMessageTableDataAsCsvQueryOptions(
  {
    accountName,
    messageId,
  }: { accountName: ReadMessageTableDataAsCsvPathParams['account_name']; messageId: ReadMessageTableDataAsCsvPathParams['message_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readMessageTableDataAsCsvQueryKey({ accountName, messageId })
  return queryOptions({
    enabled: !!(accountName && messageId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readMessageTableDataAsCsv({ accountName, messageId }, config)
    },
  })
}

/**
 * @description Read message table data as a CSV.This will return an error if the message does not contain a table ID.
 * @summary Read Message Table Data As Csv
 * {@link /api/v3/orgs/:account_name/messages/:message_id/data/csv}
 */
export function useReadMessageTableDataAsCsv<
  TData = ReadMessageTableDataAsCsvQueryResponse,
  TQueryData = ReadMessageTableDataAsCsvQueryResponse,
  TQueryKey extends QueryKey = ReadMessageTableDataAsCsvQueryKey,
>(
  {
    accountName,
    messageId,
  }: { accountName: ReadMessageTableDataAsCsvPathParams['account_name']; messageId: ReadMessageTableDataAsCsvPathParams['message_id'] },
  options: {
    query?: Partial<
      QueryObserverOptions<
        ReadMessageTableDataAsCsvQueryResponse,
        ReadMessageTableDataAsCsv400 | ReadMessageTableDataAsCsv404 | ReadMessageTableDataAsCsv422,
        TData,
        TQueryData,
        TQueryKey
      >
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readMessageTableDataAsCsvQueryKey({ accountName, messageId })

  const query = useQuery({
    ...(readMessageTableDataAsCsvQueryOptions({ accountName, messageId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadMessageTableDataAsCsv400 | ReadMessageTableDataAsCsv404 | ReadMessageTableDataAsCsv422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}