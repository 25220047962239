// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/promptOptionsPayloadSchema.ts"
);
import.meta.hot.lastModified = "1737759761027.0222";
}
// REMIX HMR END

// @ts-nocheck
import { suggestedPromptSchema } from './suggestedPromptSchema'
import { z } from 'zod'

/**
 * @description Payload for prompt options.\n\nAttributes:\n    text: The text of a message to accompany the prompt options.\n    options: The suggested prompts.\n    after_text: The text of a message to display after the prompt options.
 */
export const promptOptionsPayloadSchema = z
  .object({
    text: z.union([z.string(), z.null()]),
    options: z.array(z.lazy(() => suggestedPromptSchema)),
    after_text: z.union([z.string(), z.null()]),
  })
  .describe(
    'Payload for prompt options.\n\nAttributes:\n    text: The text of a message to accompany the prompt options.\n    options: The suggested prompts.\n    after_text: The text of a message to display after the prompt options.',
  )