// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useAddAccountsToEnterprise.ts"
);
import.meta.hot.lastModified = "1737935768621.742";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  AddAccountsToEnterpriseMutationRequest,
  AddAccountsToEnterpriseMutationResponse,
  AddAccountsToEnterprisePathParams,
  AddAccountsToEnterprise422,
} from '../types/AddAccountsToEnterprise'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const addAccountsToEnterpriseMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/accounts' }] as const

export type AddAccountsToEnterpriseMutationKey = ReturnType<typeof addAccountsToEnterpriseMutationKey>

/**
 * @description Add existing accounts to the enterprise.This is intended to be only used by the superuser.Returns: A list of account names that were not able to be added.
 * @summary Add Accounts To Enterprise
 * {@link /api/v1/enterprises/:enterprise_name/accounts}
 */
async function addAccountsToEnterprise(
  { enterpriseName, data }: { enterpriseName: AddAccountsToEnterprisePathParams['enterprise_name']; data?: AddAccountsToEnterpriseMutationRequest },
  config: Partial<RequestConfig<AddAccountsToEnterpriseMutationRequest>> = {},
) {
  const res = await client<AddAccountsToEnterpriseMutationResponse, AddAccountsToEnterprise422, AddAccountsToEnterpriseMutationRequest>({
    method: 'PUT',
    url: `/api/v1/enterprises/${enterpriseName}/accounts`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Add existing accounts to the enterprise.This is intended to be only used by the superuser.Returns: A list of account names that were not able to be added.
 * @summary Add Accounts To Enterprise
 * {@link /api/v1/enterprises/:enterprise_name/accounts}
 */
export function useAddAccountsToEnterprise(
  options: {
    mutation?: UseMutationOptions<
      AddAccountsToEnterpriseMutationResponse,
      AddAccountsToEnterprise422,
      { enterpriseName: AddAccountsToEnterprisePathParams['enterprise_name']; data?: AddAccountsToEnterpriseMutationRequest }
    >
    client?: Partial<RequestConfig<AddAccountsToEnterpriseMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? addAccountsToEnterpriseMutationKey()

  return useMutation<
    AddAccountsToEnterpriseMutationResponse,
    AddAccountsToEnterprise422,
    { enterpriseName: AddAccountsToEnterprisePathParams['enterprise_name']; data?: AddAccountsToEnterpriseMutationRequest }
  >({
    mutationFn: async ({ enterpriseName, data }) => {
      return addAccountsToEnterprise({ enterpriseName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}