// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetWidget.ts"
);
import.meta.hot.lastModified = "1737759757951.038";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetWidgetQueryResponse, GetWidgetPathParams, GetWidget422 } from '../types/GetWidget'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getWidgetQueryKey = ({
  accountName,
  widgetId,
}: {
  accountName: GetWidgetPathParams['account_name']
  widgetId: GetWidgetPathParams['widget_id']
}) => [{ url: '/api/v3/orgs/:account_name/widgets/:widget_id', params: { accountName: accountName, widgetId: widgetId } }] as const

export type GetWidgetQueryKey = ReturnType<typeof getWidgetQueryKey>

/**
 * @description Get widget by ID.
 * @summary Get Widget
 * {@link /api/v3/orgs/:account_name/widgets/:widget_id}
 */
async function getWidget(
  { accountName, widgetId }: { accountName: GetWidgetPathParams['account_name']; widgetId: GetWidgetPathParams['widget_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetWidgetQueryResponse, GetWidget422, unknown>({ method: 'GET', url: `/api/v3/orgs/${accountName}/widgets/${widgetId}`, ...config })
  return res.data
}

export function getWidgetQueryOptions(
  { accountName, widgetId }: { accountName: GetWidgetPathParams['account_name']; widgetId: GetWidgetPathParams['widget_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getWidgetQueryKey({ accountName, widgetId })
  return queryOptions({
    enabled: !!(accountName && widgetId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getWidget({ accountName, widgetId }, config)
    },
  })
}

/**
 * @description Get widget by ID.
 * @summary Get Widget
 * {@link /api/v3/orgs/:account_name/widgets/:widget_id}
 */
export function useGetWidget<TData = GetWidgetQueryResponse, TQueryData = GetWidgetQueryResponse, TQueryKey extends QueryKey = GetWidgetQueryKey>(
  { accountName, widgetId }: { accountName: GetWidgetPathParams['account_name']; widgetId: GetWidgetPathParams['widget_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetWidgetQueryResponse, GetWidget422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getWidgetQueryKey({ accountName, widgetId })

  const query = useQuery({
    ...(getWidgetQueryOptions({ accountName, widgetId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetWidget422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}