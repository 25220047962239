// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateCopyJob.ts"
);
import.meta.hot.lastModified = "1737935766031.756";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateCopyJobMutationRequest, CreateCopyJobMutationResponse, CreateCopyJobPathParams, CreateCopyJob422 } from '../types/CreateCopyJob'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createCopyJobMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/copy/' }] as const

export type CreateCopyJobMutationKey = ReturnType<typeof createCopyJobMutationKey>

/**
 * @description Create a copy job.
 * @summary Create Copy Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/copy/}
 */
async function createCopyJob(
  { enterpriseName, data }: { enterpriseName: CreateCopyJobPathParams['enterprise_name']; data?: CreateCopyJobMutationRequest },
  config: Partial<RequestConfig<CreateCopyJobMutationRequest>> = {},
) {
  const res = await client<CreateCopyJobMutationResponse, CreateCopyJob422, CreateCopyJobMutationRequest>({
    method: 'POST',
    url: `/api/v1/enterprises/${enterpriseName}/dataset-jobs/copy/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a copy job.
 * @summary Create Copy Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/copy/}
 */
export function useCreateCopyJob(
  options: {
    mutation?: UseMutationOptions<
      CreateCopyJobMutationResponse,
      CreateCopyJob422,
      { enterpriseName: CreateCopyJobPathParams['enterprise_name']; data?: CreateCopyJobMutationRequest }
    >
    client?: Partial<RequestConfig<CreateCopyJobMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createCopyJobMutationKey()

  return useMutation<
    CreateCopyJobMutationResponse,
    CreateCopyJob422,
    { enterpriseName: CreateCopyJobPathParams['enterprise_name']; data?: CreateCopyJobMutationRequest }
  >({
    mutationFn: async ({ enterpriseName, data }) => {
      return createCopyJob({ enterpriseName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}