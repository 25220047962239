// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteMetrics.ts"
);
import.meta.hot.lastModified = "1737935766031.756";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteMetricsMutationRequest, DeleteMetricsMutationResponse, DeleteMetricsPathParams, DeleteMetrics422 } from '../types/DeleteMetrics'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteMetricsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics' }] as const

export type DeleteMetricsMutationKey = ReturnType<typeof deleteMetricsMutationKey>

/**
 * @description Remove metrics from database.Note: This endpoint is for simple deletion of a metric. It will not check for metric usage andhandle the full flow for metric deletion (invalidating examples and derived dms that use this metric).Use with caution, delete only when you are sure the metric is not needed.
 * @summary Delete Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics}
 */
async function deleteMetrics(
  { accountName, data }: { accountName: DeleteMetricsPathParams['account_name']; data?: DeleteMetricsMutationRequest },
  config: Partial<RequestConfig<DeleteMetricsMutationRequest>> = {},
) {
  const res = await client<DeleteMetricsMutationResponse, DeleteMetrics422, DeleteMetricsMutationRequest>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Remove metrics from database.Note: This endpoint is for simple deletion of a metric. It will not check for metric usage andhandle the full flow for metric deletion (invalidating examples and derived dms that use this metric).Use with caution, delete only when you are sure the metric is not needed.
 * @summary Delete Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics}
 */
export function useDeleteMetrics(
  options: {
    mutation?: UseMutationOptions<
      DeleteMetricsMutationResponse,
      DeleteMetrics422,
      { accountName: DeleteMetricsPathParams['account_name']; data?: DeleteMetricsMutationRequest }
    >
    client?: Partial<RequestConfig<DeleteMetricsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteMetricsMutationKey()

  return useMutation<
    DeleteMetricsMutationResponse,
    DeleteMetrics422,
    { accountName: DeleteMetricsPathParams['account_name']; data?: DeleteMetricsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return deleteMetrics({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}