// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSyncKnowledgeSql.ts"
);
import.meta.hot.lastModified = "1737935767035.7507";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  SyncKnowledgeSqlMutationRequest,
  SyncKnowledgeSqlMutationResponse,
  SyncKnowledgeSqlPathParams,
  SyncKnowledgeSql422,
} from '../types/SyncKnowledgeSql'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const syncKnowledgeSqlMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/sync_knowledge_sql' }] as const

export type SyncKnowledgeSqlMutationKey = ReturnType<typeof syncKnowledgeSqlMutationKey>

/**
 * @description Sync knowledge sql for an account.
 * @summary Sync Knowledge Sql
 * {@link /api/v1/accounts/:account_name/sync_knowledge_sql}
 */
async function syncKnowledgeSql(
  { accountName, data }: { accountName: SyncKnowledgeSqlPathParams['account_name']; data?: SyncKnowledgeSqlMutationRequest },
  config: Partial<RequestConfig<SyncKnowledgeSqlMutationRequest>> = {},
) {
  const res = await client<SyncKnowledgeSqlMutationResponse, SyncKnowledgeSql422, SyncKnowledgeSqlMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/sync_knowledge_sql`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Sync knowledge sql for an account.
 * @summary Sync Knowledge Sql
 * {@link /api/v1/accounts/:account_name/sync_knowledge_sql}
 */
export function useSyncKnowledgeSql(
  options: {
    mutation?: UseMutationOptions<
      SyncKnowledgeSqlMutationResponse,
      SyncKnowledgeSql422,
      { accountName: SyncKnowledgeSqlPathParams['account_name']; data?: SyncKnowledgeSqlMutationRequest }
    >
    client?: Partial<RequestConfig<SyncKnowledgeSqlMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? syncKnowledgeSqlMutationKey()

  return useMutation<
    SyncKnowledgeSqlMutationResponse,
    SyncKnowledgeSql422,
    { accountName: SyncKnowledgeSqlPathParams['account_name']; data?: SyncKnowledgeSqlMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return syncKnowledgeSql({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}