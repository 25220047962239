// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/apiKeyApiCreateSchema.ts"
);
import.meta.hot.lastModified = "1737759759784.0286";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Properties to receive via API on creation.
 */
export const apiKeyApiCreateSchema = z
  .object({
    name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Properties to receive via API on creation.')