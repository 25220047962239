// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateNamedFilter.ts"
);
import.meta.hot.lastModified = "1737935767422.7485";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateNamedFilterMutationRequest,
  UpdateNamedFilterMutationResponse,
  UpdateNamedFilterPathParams,
  UpdateNamedFilter422,
} from '../types/UpdateNamedFilter'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateNamedFilterMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters/{named_filter_id}' }] as const

export type UpdateNamedFilterMutationKey = ReturnType<typeof updateNamedFilterMutationKey>

/**
 * @description Update a Named Filter.
 * @summary Update Named Filter
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters/:named_filter_id}
 */
async function updateNamedFilter(
  {
    accountName,
    namedFilterId,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: UpdateNamedFilterPathParams['account_name']
    namedFilterId: UpdateNamedFilterPathParams['named_filter_id']
    starSchemaDataSourceId: UpdateNamedFilterPathParams['star_schema_data_source_id']
    data?: UpdateNamedFilterMutationRequest
  },
  config: Partial<RequestConfig<UpdateNamedFilterMutationRequest>> = {},
) {
  const res = await client<UpdateNamedFilterMutationResponse, UpdateNamedFilter422, UpdateNamedFilterMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/filters/${namedFilterId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a Named Filter.
 * @summary Update Named Filter
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters/:named_filter_id}
 */
export function useUpdateNamedFilter(
  options: {
    mutation?: UseMutationOptions<
      UpdateNamedFilterMutationResponse,
      UpdateNamedFilter422,
      {
        accountName: UpdateNamedFilterPathParams['account_name']
        namedFilterId: UpdateNamedFilterPathParams['named_filter_id']
        starSchemaDataSourceId: UpdateNamedFilterPathParams['star_schema_data_source_id']
        data?: UpdateNamedFilterMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateNamedFilterMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateNamedFilterMutationKey()

  return useMutation<
    UpdateNamedFilterMutationResponse,
    UpdateNamedFilter422,
    {
      accountName: UpdateNamedFilterPathParams['account_name']
      namedFilterId: UpdateNamedFilterPathParams['named_filter_id']
      starSchemaDataSourceId: UpdateNamedFilterPathParams['star_schema_data_source_id']
      data?: UpdateNamedFilterMutationRequest
    }
  >({
    mutationFn: async ({ accountName, namedFilterId, starSchemaDataSourceId, data }) => {
      return updateNamedFilter({ accountName, namedFilterId, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}