// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dimensionUpdateWithIdSchema.ts"
);
import.meta.hot.lastModified = "1737759761259.021";
}
// REMIX HMR END

// @ts-nocheck
import { displayTypeSchema } from './displayTypeSchema'
import { filterCategorySchema } from './filterCategorySchema'
import { originTypeSchema } from './originTypeSchema'
import { z } from 'zod'

/**
 * @description API schema for bulk editing dimensions.
 */
export const dimensionUpdateWithIdSchema = z
  .object({
    description: z.union([z.string(), z.null()]).optional(),
    sql: z.union([z.string(), z.null()]).optional(),
    display_name: z.union([z.string(), z.null()]).optional(),
    expr_type: z.union([z.lazy(() => filterCategorySchema), z.null()]).optional(),
    display_type: z.union([z.lazy(() => displayTypeSchema), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    group_id: z.union([z.string(), z.null()]).optional(),
    data_source_id: z.union([z.string(), z.null()]).optional(),
    origin: z.union([z.array(z.lazy(() => originTypeSchema)), z.null()]).optional(),
    popularity: z.union([z.number(), z.null()]).optional(),
    id: z.string(),
  })
  .describe('API schema for bulk editing dimensions.')