// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/cellRunEventSchema.ts"
);
import.meta.hot.lastModified = "1737935765791.7576";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Cell Run Event Schema.
 */
export const cellRunEventSchema = z
  .object({
    run_id: z.string(),
    account_id: z.string(),
    ssds_id: z.string(),
    cell_id: z.string(),
    conversation_id: z.string(),
    connection_id: z.string(),
    user_id: z.string(),
    task_id: z.union([z.string(), z.null()]).optional(),
    question: z.string(),
    crud_time: z.union([z.number(), z.null()]).optional(),
    get_bumblebee_data_source_time: z.union([z.number(), z.null()]).optional(),
    query_client_init_time: z.union([z.number(), z.null()]).optional(),
    to_snowflake_data_source_time: z.union([z.number(), z.null()]).optional(),
    get_cell_history_time: z.union([z.number(), z.null()]).optional(),
    get_unified_input_table_time: z.union([z.number(), z.null()]).optional(),
    retrieval_time: z.union([z.number(), z.null()]).optional(),
    pre_query_time: z.union([z.number(), z.null()]).optional(),
    get_query_time: z.union([z.number(), z.null()]).optional(),
    query_execution_time: z.union([z.number(), z.null()]).optional(),
    len_retrieved_columns: z.union([z.number().int(), z.null()]).optional(),
    len_columns: z.union([z.number().int(), z.null()]).optional(),
    len_retrieved_dimensions: z.union([z.number().int(), z.null()]).optional(),
    len_dimensions: z.union([z.number().int(), z.null()]).optional(),
    len_retrieved_metrics: z.union([z.number().int(), z.null()]).optional(),
    len_metrics: z.union([z.number().int(), z.null()]).optional(),
    num_get_query_model_calls: z.union([z.number().int(), z.null()]).optional(),
    moonlight_rephrase_time: z.union([z.number(), z.null()]).optional(),
    moonlight_retrieval_time: z.union([z.number(), z.null()]).optional(),
    moonlight_routing_time: z.union([z.number(), z.null()]).optional(),
    moonlight_pass_to_bb_time: z.union([z.number(), z.null()]).optional(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
  })
  .describe('Cell Run Event Schema.')