// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/UserRole.ts"
);
import.meta.hot.lastModified = "1737759746553.0967";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Roles that a user can have.
 */
export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
}
