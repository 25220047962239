// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/clientSettingUnionBoolIntStrSchema.ts"
);
import.meta.hot.lastModified = "1737759762294.0156";
}
// REMIX HMR END

// @ts-nocheck
import { settingValueUnionBoolIntStrSchema } from './settingValueUnionBoolIntStrSchema'
import { z } from 'zod'

export const clientSettingUnionBoolIntStrSchema = z.object({
  name: z.string(),
  description: z.string(),
  value_object: z.lazy(() => settingValueUnionBoolIntStrSchema),
  is_superuser_editable: z.boolean(),
  is_admin_editable: z.boolean(),
  is_value_sent_to_client: z.boolean().default(true),
  value: z.union([z.boolean(), z.number().int(), z.string(), z.null()]),
  admin_value: z.union([z.boolean(), z.number().int(), z.string(), z.null()]).optional(),
  superuser_value: z.union([z.boolean(), z.number().int(), z.string(), z.null()]).optional(),
  default_value: z.union([z.boolean(), z.number().int(), z.string(), z.null()]).optional(),
  is_test: z.boolean().default(false),
})