// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateCellChartConfigSchema.ts"
);
import.meta.hot.lastModified = "1737759761259.021";
}
// REMIX HMR END

// @ts-nocheck
import { chartConfigApiUpdateSchema } from './chartConfigApiUpdateSchema'
import { chartConfigSchema } from './chartConfigSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateCellChartConfigPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
  chart_config_id: z.string(),
})

/**
 * @description Successful Response
 */
export const updateCellChartConfig200Schema = z.lazy(() => chartConfigSchema)

/**
 * @description Validation Error
 */
export const updateCellChartConfig422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateCellChartConfigMutationRequestSchema = z.lazy(() => chartConfigApiUpdateSchema)

export const updateCellChartConfigMutationResponseSchema = z.lazy(() => updateCellChartConfig200Schema)