// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadUserById.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadUserByIdQueryResponse, ReadUserByIdPathParams, ReadUserByIdQueryParams, ReadUserById422 } from '../types/ReadUserById'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readUserByIdQueryKey = ({ userId }: { userId: ReadUserByIdPathParams['user_id'] }, params?: ReadUserByIdQueryParams) =>
  [{ url: '/api/v1/users/:user_id', params: { userId: userId } }, ...(params ? [params] : [])] as const

export type ReadUserByIdQueryKey = ReturnType<typeof readUserByIdQueryKey>

/**
 * @description Get a specific user by id.
 * @summary Read User By Id
 * {@link /api/v1/users/:user_id}
 */
async function readUserById(
  { userId, params }: { userId: ReadUserByIdPathParams['user_id']; params?: ReadUserByIdQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadUserByIdQueryResponse, ReadUserById422, unknown>({ method: 'GET', url: `/api/v1/users/${userId}`, params, ...config })
  return res.data
}

export function readUserByIdQueryOptions(
  { userId, params }: { userId: ReadUserByIdPathParams['user_id']; params?: ReadUserByIdQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readUserByIdQueryKey({ userId }, params)
  return queryOptions({
    enabled: !!userId,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readUserById({ userId, params }, config)
    },
  })
}

/**
 * @description Get a specific user by id.
 * @summary Read User By Id
 * {@link /api/v1/users/:user_id}
 */
export function useReadUserById<TData = ReadUserByIdQueryResponse, TQueryData = ReadUserByIdQueryResponse, TQueryKey extends QueryKey = ReadUserByIdQueryKey>(
  { userId, params }: { userId: ReadUserByIdPathParams['user_id']; params?: ReadUserByIdQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadUserByIdQueryResponse, ReadUserById422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readUserByIdQueryKey({ userId }, params)

  const query = useQuery({
    ...(readUserByIdQueryOptions({ userId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadUserById422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}