// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/renderTypeSchema.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Enum for the render type of the message\n\nThese types are used to guide the client on how to render the message.
 */
export const renderTypeSchema = z
  .enum(['NONE', 'TEMPORARY', 'SOFT', 'STANDARD'])
  .describe('Enum for the render type of the message\n\nThese types are used to guide the client on how to render the message.')