// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDimensionsState.ts"
);
import.meta.hot.lastModified = "1737759761255.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateDimensionsStateMutationRequest,
  UpdateDimensionsStateMutationResponse,
  UpdateDimensionsStatePathParams,
  UpdateDimensionsStateQueryParams,
  UpdateDimensionsState422,
} from '../types/UpdateDimensionsState'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDimensionsStateMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimension_states' }] as const

export type UpdateDimensionsStateMutationKey = ReturnType<typeof updateDimensionsStateMutationKey>

/**
 * @description Bulk update multiple dimension's state.
 * @summary Update Dimensions State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimension_states}
 */
async function updateDimensionsState(
  {
    accountName,
    data,
    params,
  }: { accountName: UpdateDimensionsStatePathParams['account_name']; data?: UpdateDimensionsStateMutationRequest; params?: UpdateDimensionsStateQueryParams },
  config: Partial<RequestConfig<UpdateDimensionsStateMutationRequest>> = {},
) {
  const res = await client<UpdateDimensionsStateMutationResponse, UpdateDimensionsState422, UpdateDimensionsStateMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimension_states`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Bulk update multiple dimension's state.
 * @summary Update Dimensions State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimension_states}
 */
export function useUpdateDimensionsState(
  options: {
    mutation?: UseMutationOptions<
      UpdateDimensionsStateMutationResponse,
      UpdateDimensionsState422,
      { accountName: UpdateDimensionsStatePathParams['account_name']; data?: UpdateDimensionsStateMutationRequest; params?: UpdateDimensionsStateQueryParams }
    >
    client?: Partial<RequestConfig<UpdateDimensionsStateMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDimensionsStateMutationKey()

  return useMutation<
    UpdateDimensionsStateMutationResponse,
    UpdateDimensionsState422,
    { accountName: UpdateDimensionsStatePathParams['account_name']; data?: UpdateDimensionsStateMutationRequest; params?: UpdateDimensionsStateQueryParams }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return updateDimensionsState({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}