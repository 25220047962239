// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/knowledgeStateFilterSchema.ts"
);
import.meta.hot.lastModified = "1737759761027.0222";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Knowledge type filter.
 */
export const knowledgeStateFilterSchema = z
  .enum(['all', 'deprecated', 'verified', 'unset', 'invalid', 'review', 'valid', 'sensitive'])
  .describe('Knowledge type filter.')