// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteMetric.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteMetricMutationResponse, DeleteMetricPathParams, DeleteMetric422 } from '../types/DeleteMetric'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteMetricMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}' }] as const

export type DeleteMetricMutationKey = ReturnType<typeof deleteMetricMutationKey>

/**
 * @description Remove metric from database.Note: This endpoint is for simple deletion of a metric. It will not check for metric usage andhandle the full flow for metric deletion (invalidating examples and derived dms that use this metric).Use with caution, delete only when you are sure the metric is not needed.
 * @summary Delete Metric
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id}
 */
async function deleteMetric(
  { accountName, metricId }: { accountName: DeleteMetricPathParams['account_name']; metricId: DeleteMetricPathParams['metric_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteMetricMutationResponse, DeleteMetric422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/${metricId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Remove metric from database.Note: This endpoint is for simple deletion of a metric. It will not check for metric usage andhandle the full flow for metric deletion (invalidating examples and derived dms that use this metric).Use with caution, delete only when you are sure the metric is not needed.
 * @summary Delete Metric
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id}
 */
export function useDeleteMetric(
  options: {
    mutation?: UseMutationOptions<
      DeleteMetricMutationResponse,
      DeleteMetric422,
      { accountName: DeleteMetricPathParams['account_name']; metricId: DeleteMetricPathParams['metric_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteMetricMutationKey()

  return useMutation<
    DeleteMetricMutationResponse,
    DeleteMetric422,
    { accountName: DeleteMetricPathParams['account_name']; metricId: DeleteMetricPathParams['metric_id'] }
  >({
    mutationFn: async ({ accountName, metricId }) => {
      return deleteMetric({ accountName, metricId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}