// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getDataAssetsForAccountSchema.ts"
);
import.meta.hot.lastModified = "1737759761632.019";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetApiPageSchema } from './dataAssetApiPageSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getDataAssetsForAccountPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

export const getDataAssetsForAccountQueryParamsSchema = z
  .object({
    limit: z.number().int().min(1).max(500).default(100),
    offset: z.number().int().min(0).default(0),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getDataAssetsForAccount200Schema = z.lazy(() => dataAssetApiPageSchema)

/**
 * @description Validation Error
 */
export const getDataAssetsForAccount422Schema = z.lazy(() => httpValidationErrorSchema)

export const getDataAssetsForAccountQueryResponseSchema = z.lazy(() => getDataAssetsForAccount200Schema)