// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/Dialect.ts"
);
import.meta.hot.lastModified = "1737935764660.7637";
}
// REMIX HMR END

export enum Dialect {
  'bigquery' = 'bigquery',
  'databricks' = 'databricks',
  'casesensitivebigquery' = 'casesensitivebigquery',
  'casesensitivedatabricks' = 'casesensitivedatabricks',
  'casesensitiveredshift' = 'casesensitiveredshift',
  'postgres' = 'postgres',
  'redshift' = 'redshift',
  'snowflake' = 'snowflake',
  'teradata' = 'teradata',
  'unknown' = 'unknown',
}