// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/runEvalOnReferenceConversationsSchema.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

// @ts-nocheck
import { bodyRunEvalOnReferenceConversationsApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdEvalReferenceConversationsPostSchema } from './bodyRunEvalOnReferenceConversationsApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdEvalReferenceConversationsPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const runEvalOnReferenceConversationsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const runEvalOnReferenceConversationsQueryParamsSchema = z
  .object({
    max_eval_conversations: z.union([z.number().int(), z.null()]).optional(),
    run_in_reverse_order: z.boolean().default(true),
    delete_new_chats: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const runEvalOnReferenceConversations200Schema = z.lazy(() => taskSchema)

/**
 * @description Validation Error
 */
export const runEvalOnReferenceConversations422Schema = z.lazy(() => httpValidationErrorSchema)

export const runEvalOnReferenceConversationsMutationRequestSchema = z.lazy(
  () => bodyRunEvalOnReferenceConversationsApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdEvalReferenceConversationsPostSchema,
)

export const runEvalOnReferenceConversationsMutationResponseSchema = z.lazy(() => runEvalOnReferenceConversations200Schema)