// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/errorInfoSchema.ts"
);
import.meta.hot.lastModified = "1737935765108.7612";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Error information.
 */
export const errorInfoSchema = z
  .object({
    code: z.string(),
    description: z.string(),
    field: z.union([z.string(), z.null()]).optional(),
    line_no: z.union([z.number().int(), z.null()]).optional(),
    line_pos: z.union([z.number().int(), z.null()]).optional(),
  })
  .describe('Error information.')