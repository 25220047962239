// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyCreateUserOpenApiV1UsersOpenPostSchema.ts"
);
import.meta.hot.lastModified = "1737935769669.7363";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const bodyCreateUserOpenApiV1UsersOpenPostSchema = z.object({
  password: z.string(),
  email: z.string().email(),
  name: z.string(),
  avatar: z.union([z.string(), z.null()]).optional(),
  preload_demo_dataset: z.boolean().default(false),
})