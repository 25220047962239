// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chartConfigDmmSelectSchema.ts"
);
import.meta.hot.lastModified = "1737935768204.7444";
}
// REMIX HMR END

// @ts-nocheck
import { axisSchema } from './axisSchema'
import { z } from 'zod'

/**
 * @description Properties to receive on Chart Config DMM select.
 */
export const chartConfigDmmSelectSchema = z
  .object({
    dm_id: z.string(),
    axis: z.lazy(() => axisSchema),
  })
  .describe('Properties to receive on Chart Config DMM select.')