// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/uploadExamplesSchema.ts"
);
import.meta.hot.lastModified = "1737759759433.0303";
}
// REMIX HMR END

// @ts-nocheck
import { bbExampleSchema } from './bbExampleSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceSchema } from './starSchemaDataSourceSchema'
import { z } from 'zod'

export const uploadExamplesPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const uploadExamples201Schema = z.lazy(() => starSchemaDataSourceSchema)

/**
 * @description Validation Error
 */
export const uploadExamples422Schema = z.lazy(() => httpValidationErrorSchema)

export const uploadExamplesMutationRequestSchema = z.array(z.lazy(() => bbExampleSchema))

export const uploadExamplesMutationResponseSchema = z.lazy(() => uploadExamples201Schema)