// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/displayFieldsUpdateApiSchema.ts"
);
import.meta.hot.lastModified = "1737935768612.742";
}
// REMIX HMR END

// @ts-nocheck
import { displayTypeSchema } from './displayTypeSchema'
import { z } from 'zod'

/**
 * @description API schema for updating the display fields of a dimension/metric.
 */
export const displayFieldsUpdateApiSchema = z
  .object({
    display_type: z.lazy(() => displayTypeSchema),
  })
  .describe('API schema for updating the display fields of a dimension/metric.')