// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRunRegressionTest.ts"
);
import.meta.hot.lastModified = "1737759760238.0261";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RunRegressionTestMutationRequest,
  RunRegressionTestMutationResponse,
  RunRegressionTestPathParams,
  RunRegressionTestQueryParams,
  RunRegressionTest422,
} from '../types/RunRegressionTest'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const runRegressionTestMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/regression_test' }] as const

export type RunRegressionTestMutationKey = ReturnType<typeof runRegressionTestMutationKey>

/**
 * @description Run regression test on verified cells.Args:    max_eval_messages: The upper limit of messages to run.    delete_new_chats: Remove newly-created chats after evaluation.
 * @summary Run Regression Test
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/regression_test}
 */
async function runRegressionTest(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: RunRegressionTestPathParams['account_name']
    starSchemaDataSourceId: RunRegressionTestPathParams['star_schema_data_source_id']
    data?: RunRegressionTestMutationRequest
    params?: RunRegressionTestQueryParams
  },
  config: Partial<RequestConfig<RunRegressionTestMutationRequest>> = {},
) {
  const res = await client<RunRegressionTestMutationResponse, RunRegressionTest422, RunRegressionTestMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/regression_test`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Run regression test on verified cells.Args:    max_eval_messages: The upper limit of messages to run.    delete_new_chats: Remove newly-created chats after evaluation.
 * @summary Run Regression Test
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/regression_test}
 */
export function useRunRegressionTest(
  options: {
    mutation?: UseMutationOptions<
      RunRegressionTestMutationResponse,
      RunRegressionTest422,
      {
        accountName: RunRegressionTestPathParams['account_name']
        starSchemaDataSourceId: RunRegressionTestPathParams['star_schema_data_source_id']
        data?: RunRegressionTestMutationRequest
        params?: RunRegressionTestQueryParams
      }
    >
    client?: Partial<RequestConfig<RunRegressionTestMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? runRegressionTestMutationKey()

  return useMutation<
    RunRegressionTestMutationResponse,
    RunRegressionTest422,
    {
      accountName: RunRegressionTestPathParams['account_name']
      starSchemaDataSourceId: RunRegressionTestPathParams['star_schema_data_source_id']
      data?: RunRegressionTestMutationRequest
      params?: RunRegressionTestQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data, params }) => {
      return runRegressionTest({ accountName, starSchemaDataSourceId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}