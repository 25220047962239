// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/combinationSchema.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Combination Strategies for Filter Conditions.
 */
export const combinationSchema = z.enum(['ALL', 'ANY']).describe('Combination Strategies for Filter Conditions.')