// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dimensionMetricValidationSchema.ts"
);
import.meta.hot.lastModified = "1737759761867.0178";
}
// REMIX HMR END

// @ts-nocheck
import { fieldTypeSchema } from './fieldTypeSchema'
import { z } from 'zod'

/**
 * @description Properties of DimensionMetricValidation.
 */
export const dimensionMetricValidationSchema = z
  .object({
    id: z.string(),
    account_id: z.string(),
    name: z.string(),
    dimension_id: z.union([z.string(), z.null()]).optional(),
    metric_id: z.union([z.string(), z.null()]).optional(),
    field_type: z.lazy(() => fieldTypeSchema),
    description: z.union([z.string(), z.null()]).optional(),
    display_name: z.string(),
    expr: z.string(),
    original_expr: z.string(),
    is_valid: z.boolean(),
    error: z.union([z.string(), z.null()]).optional(),
    ssds_id: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Properties of DimensionMetricValidation.')