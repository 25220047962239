// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readDataSourceDataSchema.ts"
);
import.meta.hot.lastModified = "1737759760689.024";
}
// REMIX HMR END

// @ts-nocheck
import { bodyReadDataSourceDataApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDataSourcesDataSourceIdDataPostSchema } from './bodyReadDataSourceDataApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDataSourcesDataSourceIdDataPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { nodeDataPageSchema } from './nodeDataPageSchema'
import { z } from 'zod'

export const readDataSourceDataPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
  data_source_id: z.string(),
})

export const readDataSourceDataQueryParamsSchema = z
  .object({
    limit: z.number().int().default(100),
    skip: z.number().int().default(0),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readDataSourceData200Schema = z.lazy(() => nodeDataPageSchema)

/**
 * @description Validation Error
 */
export const readDataSourceData422Schema = z.lazy(() => httpValidationErrorSchema)

export const readDataSourceDataMutationRequestSchema = z.lazy(
  () => bodyReadDataSourceDataApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDataSourcesDataSourceIdDataPostSchema,
)

export const readDataSourceDataMutationResponseSchema = z.lazy(() => readDataSourceData200Schema)