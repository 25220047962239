// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useListRedshiftSchemas.ts"
);
import.meta.hot.lastModified = "1737759760700.024";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ListRedshiftSchemasQueryResponse, ListRedshiftSchemasPathParams, ListRedshiftSchemas422 } from '../types/ListRedshiftSchemas'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listRedshiftSchemasQueryKey = ({
  accountName,
  connectionId,
}: {
  accountName: ListRedshiftSchemasPathParams['account_name']
  connectionId: ListRedshiftSchemasPathParams['connection_id']
}) =>
  [
    { url: '/api/v1/accounts/:account_name/connections/redshift/:connection_id/schemas', params: { accountName: accountName, connectionId: connectionId } },
  ] as const

export type ListRedshiftSchemasQueryKey = ReturnType<typeof listRedshiftSchemasQueryKey>

/**
 * @description List Redshift schemas.
 * @summary List Redshift Schemas
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id/schemas}
 */
async function listRedshiftSchemas(
  { accountName, connectionId }: { accountName: ListRedshiftSchemasPathParams['account_name']; connectionId: ListRedshiftSchemasPathParams['connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ListRedshiftSchemasQueryResponse, ListRedshiftSchemas422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/redshift/${connectionId}/schemas`,
    ...config,
  })
  return res.data
}

export function listRedshiftSchemasQueryOptions(
  { accountName, connectionId }: { accountName: ListRedshiftSchemasPathParams['account_name']; connectionId: ListRedshiftSchemasPathParams['connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = listRedshiftSchemasQueryKey({ accountName, connectionId })
  return queryOptions({
    enabled: !!(accountName && connectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listRedshiftSchemas({ accountName, connectionId }, config)
    },
  })
}

/**
 * @description List Redshift schemas.
 * @summary List Redshift Schemas
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id/schemas}
 */
export function useListRedshiftSchemas<
  TData = ListRedshiftSchemasQueryResponse,
  TQueryData = ListRedshiftSchemasQueryResponse,
  TQueryKey extends QueryKey = ListRedshiftSchemasQueryKey,
>(
  { accountName, connectionId }: { accountName: ListRedshiftSchemasPathParams['account_name']; connectionId: ListRedshiftSchemasPathParams['connection_id'] },
  options: {
    query?: Partial<QueryObserverOptions<ListRedshiftSchemasQueryResponse, ListRedshiftSchemas422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listRedshiftSchemasQueryKey({ accountName, connectionId })

  const query = useQuery({
    ...(listRedshiftSchemasQueryOptions({ accountName, connectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListRedshiftSchemas422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}