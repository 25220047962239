// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readPostgresConnectionColumnsSchema.ts"
);
import.meta.hot.lastModified = "1737759762343.0154";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tableColumnSchema } from './tableColumnSchema'
import { z } from 'zod'

export const readPostgresConnectionColumnsPathParamsSchema = z.object({
  account_name: z.string(),
  postgres_connection_id: z.string(),
})

export const readPostgresConnectionColumnsQueryParamsSchema = z.object({
  schema: z.string().min(1),
  table: z.string().min(1),
})

/**
 * @description Successful Response
 */
export const readPostgresConnectionColumns200Schema = z.array(z.lazy(() => tableColumnSchema))

/**
 * @description Validation Error
 */
export const readPostgresConnectionColumns422Schema = z.lazy(() => httpValidationErrorSchema)

export const readPostgresConnectionColumnsQueryResponseSchema = z.lazy(() => readPostgresConnectionColumns200Schema)