// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadDimension.ts"
);
import.meta.hot.lastModified = "1737935766031.756";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadDimensionQueryResponse, ReadDimensionPathParams, ReadDimension422 } from '../types/ReadDimension'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readDimensionQueryKey = ({
  dimensionId,
  accountName,
}: {
  dimensionId: ReadDimensionPathParams['dimension_id']
  accountName: ReadDimensionPathParams['account_name']
}) =>
  [
    { url: '/api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id', params: { accountName: accountName, dimensionId: dimensionId } },
  ] as const

export type ReadDimensionQueryKey = ReturnType<typeof readDimensionQueryKey>

/**
 * @description Read a dimension.
 * @summary Read Dimension
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id}
 */
async function readDimension(
  { dimensionId, accountName }: { dimensionId: ReadDimensionPathParams['dimension_id']; accountName: ReadDimensionPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadDimensionQueryResponse, ReadDimension422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions/${dimensionId}`,
    ...config,
  })
  return res.data
}

export function readDimensionQueryOptions(
  { dimensionId, accountName }: { dimensionId: ReadDimensionPathParams['dimension_id']; accountName: ReadDimensionPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readDimensionQueryKey({ dimensionId, accountName })
  return queryOptions({
    enabled: !!(dimensionId && accountName),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readDimension({ dimensionId, accountName }, config)
    },
  })
}

/**
 * @description Read a dimension.
 * @summary Read Dimension
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id}
 */
export function useReadDimension<
  TData = ReadDimensionQueryResponse,
  TQueryData = ReadDimensionQueryResponse,
  TQueryKey extends QueryKey = ReadDimensionQueryKey,
>(
  { dimensionId, accountName }: { dimensionId: ReadDimensionPathParams['dimension_id']; accountName: ReadDimensionPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadDimensionQueryResponse, ReadDimension422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readDimensionQueryKey({ dimensionId, accountName })

  const query = useQuery({
    ...(readDimensionQueryOptions({ dimensionId, accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadDimension422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}