// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/histogramBucketFloatSchema.ts"
);
import.meta.hot.lastModified = "1737935768205.7444";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Histogram bucket with float boundaries.
 */
export const histogramBucketFloatSchema = z
  .object({
    count: z.number().int(),
    lbound: z.number(),
  })
  .describe('Histogram bucket with float boundaries.')