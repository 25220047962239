// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/baseColumnStatisticsSchema.ts"
);
import.meta.hot.lastModified = "1737935768204.7444";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Common column statistics with discriminator.
 */
export const baseColumnStatisticsSchema = z
  .object({
    name: z.string(),
    type: z.string(),
    count: z.number().int(),
    num_nulls: z.number().int(),
    num_distinct_values: z.union([z.number().int(), z.null()]).optional(),
    stat_type: z.enum(['base']).default('base'),
  })
  .describe('Common column statistics with discriminator.')