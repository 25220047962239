// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/joinSchema.ts"
);
import.meta.hot.lastModified = "1737935764662.7637";
}
// REMIX HMR END

// @ts-nocheck
import { identifierJoinTypeSchema } from './identifierJoinTypeSchema'
import { joinConditionSchema } from './joinConditionSchema'
import { z } from 'zod'

/**
 * @description Join class.\n\nThis class is used in the FE. User can define joins so that we can add\nidentifiers into the star schema data source.
 */
export const joinSchema = z
  .object({
    left_data_source_name: z.string(),
    right_data_source_name: z.string(),
    left_data_source_id: z.union([z.string(), z.null()]).optional(),
    right_data_source_id: z.union([z.string(), z.null()]).optional(),
    join_type: z.lazy(() => identifierJoinTypeSchema),
    conditions: z.array(z.lazy(() => joinConditionSchema)),
    expression: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Join class.\n\nThis class is used in the FE. User can define joins so that we can add\nidentifiers into the star schema data source.')