// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/moonlightStreamedOutputSchema.ts"
);
import.meta.hot.lastModified = "1737935768791.741";
}
// REMIX HMR END

// @ts-nocheck
import { moonlightStreamedOutputQuestionSchema } from './moonlightStreamedOutputQuestionSchema'
import { z } from 'zod'

/**
 * @description The output from moonlight.\n\nInput_type is the type of the question the user asked (1-5)\nintro is the generated introductory text\nquestions is a list of questions\noutro is typically used when sending questions to bb. \"Okay, I will run..\".
 */
export const moonlightStreamedOutputSchema = z
  .object({
    rephrased_question: z.union([z.string(), z.null()]).optional(),
    input_type: z.union([z.number().int(), z.null()]).optional(),
    intro: z.string(),
    questions: z.array(z.lazy(() => moonlightStreamedOutputQuestionSchema)),
    outro: z.string(),
  })
  .describe(
    'The output from moonlight.\n\nInput_type is the type of the question the user asked (1-5)\nintro is the generated introductory text\nquestions is a list of questions\noutro is typically used when sending questions to bb. "Okay, I will run..".',
  )