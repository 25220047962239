// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bumblebeeCellRunParamsSchema.ts"
);
import.meta.hot.lastModified = "1737759761448.02";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Bumblebee Celll Run parameters args.
 */
export const bumblebeeCellRunParamsSchema = z
  .object({
    bb_model: z.string().default('gpt-4o-2024-08-06'),
    overwrite_cache: z.boolean().default(false),
    skip_query_execution: z.boolean().default(false),
    copy_from_examples: z.boolean().default(true),
    max_historical_cells: z.number().int().default(0),
  })
  .describe('Bumblebee Celll Run parameters args.')