// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/ssdsSuggestIdentifiersSchema.ts"
);
import.meta.hot.lastModified = "1737759761449.02";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { identifierSuggestionResponseSchema } from './identifierSuggestionResponseSchema'
import { z } from 'zod'

export const ssdsSuggestIdentifiersPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const ssdsSuggestIdentifiersQueryParamsSchema = z
  .object({
    max_tokens: z.number().int().default(4000),
    temperature: z.number().default(0),
    overwrite_cache: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const ssdsSuggestIdentifiers200Schema = z.lazy(() => identifierSuggestionResponseSchema)

/**
 * @description Validation Error
 */
export const ssdsSuggestIdentifiers422Schema = z.lazy(() => httpValidationErrorSchema)

export const ssdsSuggestIdentifiersMutationResponseSchema = z.lazy(() => ssdsSuggestIdentifiers200Schema)