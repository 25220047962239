// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateMetricIsSensitiveImpact.ts"
);
import.meta.hot.lastModified = "1737759762299.0156";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateMetricIsSensitiveImpactMutationResponse,
  UpdateMetricIsSensitiveImpactPathParams,
  UpdateMetricIsSensitiveImpactQueryParams,
  UpdateMetricIsSensitiveImpact422,
} from '../types/UpdateMetricIsSensitiveImpact'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateMetricIsSensitiveImpactMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/is_sensitive_impact' }] as const

export type UpdateMetricIsSensitiveImpactMutationKey = ReturnType<typeof updateMetricIsSensitiveImpactMutationKey>

/**
 * @description Compute the impact of updating a metric for is_sensitive.
 * @summary Update Metric Is Sensitive Impact
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/is_sensitive_impact}
 */
async function updateMetricIsSensitiveImpact(
  {
    accountName,
    metricId,
    params,
  }: {
    accountName: UpdateMetricIsSensitiveImpactPathParams['account_name']
    metricId: UpdateMetricIsSensitiveImpactPathParams['metric_id']
    params: UpdateMetricIsSensitiveImpactQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateMetricIsSensitiveImpactMutationResponse, UpdateMetricIsSensitiveImpact422, unknown>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/${metricId}/is_sensitive_impact`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Compute the impact of updating a metric for is_sensitive.
 * @summary Update Metric Is Sensitive Impact
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/is_sensitive_impact}
 */
export function useUpdateMetricIsSensitiveImpact(
  options: {
    mutation?: UseMutationOptions<
      UpdateMetricIsSensitiveImpactMutationResponse,
      UpdateMetricIsSensitiveImpact422,
      {
        accountName: UpdateMetricIsSensitiveImpactPathParams['account_name']
        metricId: UpdateMetricIsSensitiveImpactPathParams['metric_id']
        params: UpdateMetricIsSensitiveImpactQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMetricIsSensitiveImpactMutationKey()

  return useMutation<
    UpdateMetricIsSensitiveImpactMutationResponse,
    UpdateMetricIsSensitiveImpact422,
    {
      accountName: UpdateMetricIsSensitiveImpactPathParams['account_name']
      metricId: UpdateMetricIsSensitiveImpactPathParams['metric_id']
      params: UpdateMetricIsSensitiveImpactQueryParams
    }
  >({
    mutationFn: async ({ accountName, metricId, params }) => {
      return updateMetricIsSensitiveImpact({ accountName, metricId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}