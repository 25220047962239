// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useMarkCellSensitive.ts"
);
import.meta.hot.lastModified = "1737759760212.0264";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { MarkCellSensitiveMutationResponse, MarkCellSensitivePathParams, MarkCellSensitive422 } from '../types/MarkCellSensitive'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const markCellSensitiveMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/is_user_sensitive' }] as const

export type MarkCellSensitiveMutationKey = ReturnType<typeof markCellSensitiveMutationKey>

/**
 * @description Add positive feedback to a bumblebee cell.
 * @summary Mark Cell Sensitive
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/is_user_sensitive}
 */
async function markCellSensitive(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: MarkCellSensitivePathParams['account_name']
    conversationId: MarkCellSensitivePathParams['conversation_id']
    cellId: MarkCellSensitivePathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<MarkCellSensitiveMutationResponse, MarkCellSensitive422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/is_user_sensitive`,
    ...config,
  })
  return res.data
}

/**
 * @description Add positive feedback to a bumblebee cell.
 * @summary Mark Cell Sensitive
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/is_user_sensitive}
 */
export function useMarkCellSensitive(
  options: {
    mutation?: UseMutationOptions<
      MarkCellSensitiveMutationResponse,
      MarkCellSensitive422,
      {
        accountName: MarkCellSensitivePathParams['account_name']
        conversationId: MarkCellSensitivePathParams['conversation_id']
        cellId: MarkCellSensitivePathParams['cell_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? markCellSensitiveMutationKey()

  return useMutation<
    MarkCellSensitiveMutationResponse,
    MarkCellSensitive422,
    {
      accountName: MarkCellSensitivePathParams['account_name']
      conversationId: MarkCellSensitivePathParams['conversation_id']
      cellId: MarkCellSensitivePathParams['cell_id']
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId }) => {
      return markCellSensitive({ accountName, conversationId, cellId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}