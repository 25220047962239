// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/suggestionApiSchema.ts"
);
import.meta.hot.lastModified = "1737759759186.0317";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetTypeApiSchema } from './dataAssetTypeApiSchema'
import { z } from 'zod'

/**
 * @description A suggested prompt.
 */
export const suggestionApiSchema = z
  .object({
    asset_id: z.string().describe('The UUID for the data asset.'),
    asset_type: z.lazy(() => dataAssetTypeApiSchema),
    asset_name: z.string().describe('The display name of the data asset.'),
    prompt: z.string().describe('The suggested prompt to use with this asset.'),
  })
  .describe('A suggested prompt.')