// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateMessageAdminFeedbackSchema.ts"
);
import.meta.hot.lastModified = "1737935769211.7388";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { messageApiResponseSchema } from './messageApiResponseSchema'
import { messageUpdateAdminFeedbackApiSchema } from './messageUpdateAdminFeedbackApiSchema'
import { z } from 'zod'

export const updateMessageAdminFeedbackPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  message_id: z.string().describe('The id of a message in a chat.'),
})

/**
 * @description Successful Response
 */
export const updateMessageAdminFeedback200Schema = z.lazy(() => messageApiResponseSchema)

/**
 * @description Validation Error
 */
export const updateMessageAdminFeedback422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateMessageAdminFeedbackMutationRequestSchema = z.lazy(() => messageUpdateAdminFeedbackApiSchema)

export const updateMessageAdminFeedbackMutationResponseSchema = z.lazy(() => updateMessageAdminFeedback200Schema)