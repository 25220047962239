// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/validateTableAndViewInfosSchema.ts"
);
import.meta.hot.lastModified = "1737759761869.0178";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceTableOrViewInfoSchema } from './starSchemaDataSourceTableOrViewInfoSchema'
import { tableOrViewInfoValidationSchema } from './tableOrViewInfoValidationSchema'
import { z } from 'zod'

export const validateTableAndViewInfosPathParamsSchema = z.object({
  account_name: z.string(),
})

export const validateTableAndViewInfosQueryParamsSchema = z.object({
  connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const validateTableAndViewInfos200Schema = z.array(z.lazy(() => tableOrViewInfoValidationSchema))

/**
 * @description Validation Error
 */
export const validateTableAndViewInfos422Schema = z.lazy(() => httpValidationErrorSchema)

export const validateTableAndViewInfosMutationRequestSchema = z.array(z.lazy(() => starSchemaDataSourceTableOrViewInfoSchema))

export const validateTableAndViewInfosMutationResponseSchema = z.lazy(() => validateTableAndViewInfos200Schema)