// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/CellState.ts"
);
import.meta.hot.lastModified = "1737759757506.0403";
}
// REMIX HMR END

export enum CellState {
  'CREATED' = 'CREATED',
  'FAILED' = 'FAILED',
  'RUNNING' = 'RUNNING',
  'SUCCESS' = 'SUCCESS',
  'LOCKED' = 'LOCKED',
}