// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ChatMessageType.ts"
);
import.meta.hot.lastModified = "1737759746442.0974";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Chat Message Type.
 */
export enum ChatMessageType {
  STANDARD = 'STANDARD',
  SIGNAL = 'SIGNAL',
  EPHEMERAL = 'EPHEMERAL',
  ERROR = 'ERROR',
}
