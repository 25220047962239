// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createFeedbackSchema.ts"
);
import.meta.hot.lastModified = "1737759759433.0303";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { messageApiGetResponseSchema } from './messageApiGetResponseSchema'
import { negativeFeedbackApiSendSchema } from './negativeFeedbackApiSendSchema'
import { z } from 'zod'

export const createFeedbackPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  message_id: z.string(),
})

/**
 * @description Successful Response
 */
export const createFeedback201Schema = z.lazy(() => messageApiGetResponseSchema)

/**
 * @description Validation Error
 */
export const createFeedback422Schema = z.lazy(() => httpValidationErrorSchema)

export const createFeedbackMutationRequestSchema = z.lazy(() => negativeFeedbackApiSendSchema)

export const createFeedbackMutationResponseSchema = z.lazy(() => createFeedback201Schema)