// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetUserFeedback.ts"
);
import.meta.hot.lastModified = "1737759759784.0286";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetUserFeedbackMutationRequest,
  GetUserFeedbackMutationResponse,
  GetUserFeedbackPathParams,
  GetUserFeedbackQueryParams,
  GetUserFeedback422,
} from '../types/GetUserFeedback'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const getUserFeedbackMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/messages/user_feedback' }] as const

export type GetUserFeedbackMutationKey = ReturnType<typeof getUserFeedbackMutationKey>

/**
 * @description Get user feedback in an account.
 * @summary Get User Feedback
 * {@link /api/v3/orgs/:account_name/messages/user_feedback}
 */
async function getUserFeedback(
  {
    accountName,
    data,
    params,
  }: { accountName: GetUserFeedbackPathParams['account_name']; data?: GetUserFeedbackMutationRequest; params?: GetUserFeedbackQueryParams },
  config: Partial<RequestConfig<GetUserFeedbackMutationRequest>> = {},
) {
  const res = await client<GetUserFeedbackMutationResponse, GetUserFeedback422, GetUserFeedbackMutationRequest>({
    method: 'POST',
    url: `/api/v3/orgs/${accountName}/messages/user_feedback`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Get user feedback in an account.
 * @summary Get User Feedback
 * {@link /api/v3/orgs/:account_name/messages/user_feedback}
 */
export function useGetUserFeedback(
  options: {
    mutation?: UseMutationOptions<
      GetUserFeedbackMutationResponse,
      GetUserFeedback422,
      { accountName: GetUserFeedbackPathParams['account_name']; data?: GetUserFeedbackMutationRequest; params?: GetUserFeedbackQueryParams }
    >
    client?: Partial<RequestConfig<GetUserFeedbackMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? getUserFeedbackMutationKey()

  return useMutation<
    GetUserFeedbackMutationResponse,
    GetUserFeedback422,
    { accountName: GetUserFeedbackPathParams['account_name']; data?: GetUserFeedbackMutationRequest; params?: GetUserFeedbackQueryParams }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return getUserFeedback({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}