// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsGroupedDimensions.ts"
);
import.meta.hot.lastModified = "1737935768806.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetSsdsGroupedDimensionsQueryResponse,
  GetSsdsGroupedDimensionsPathParams,
  GetSsdsGroupedDimensionsQueryParams,
  GetSsdsGroupedDimensions422,
} from '../types/GetSsdsGroupedDimensions'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsGroupedDimensionsQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetSsdsGroupedDimensionsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedDimensionsPathParams['star_schema_data_source_id']
  },
  params?: GetSsdsGroupedDimensionsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_dimensions',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetSsdsGroupedDimensionsQueryKey = ReturnType<typeof getSsdsGroupedDimensionsQueryKey>

/**
 * @description Get Dimensions for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are returned.The first dimension in each list will be the one to display in the UI.If cell_id is specified, then the displayed dim is chosen to be relevantfor that specific cell, otherwise the first dimension in the group is chosen.
 * @summary Get Ssds Grouped Dimensions
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_dimensions}
 */
async function getSsdsGroupedDimensions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedDimensionsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedDimensionsPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedDimensionsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsGroupedDimensionsQueryResponse, GetSsdsGroupedDimensions422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/grouped_dimensions`,
    params,
    ...config,
  })
  return res.data
}

export function getSsdsGroupedDimensionsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedDimensionsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedDimensionsPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedDimensionsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsGroupedDimensionsQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsGroupedDimensions({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get Dimensions for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are returned.The first dimension in each list will be the one to display in the UI.If cell_id is specified, then the displayed dim is chosen to be relevantfor that specific cell, otherwise the first dimension in the group is chosen.
 * @summary Get Ssds Grouped Dimensions
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_dimensions}
 */
export function useGetSsdsGroupedDimensions<
  TData = GetSsdsGroupedDimensionsQueryResponse,
  TQueryData = GetSsdsGroupedDimensionsQueryResponse,
  TQueryKey extends QueryKey = GetSsdsGroupedDimensionsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedDimensionsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedDimensionsPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedDimensionsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsGroupedDimensionsQueryResponse, GetSsdsGroupedDimensions422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsGroupedDimensionsQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getSsdsGroupedDimensionsQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsGroupedDimensions422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}