// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readTasksByAccountSchema.ts"
);
import.meta.hot.lastModified = "1737759760685.024";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { taskNameSchema } from './taskNameSchema'
import { taskPageSchema } from './taskPageSchema'
import { taskStatusSchema } from './taskStatusSchema'
import { z } from 'zod'

export const readTasksByAccountPathParamsSchema = z.object({
  account_name: z.string(),
})

export const readTasksByAccountQueryParamsSchema = z
  .object({
    skip: z.number().int().default(0),
    limit: z.number().int().default(100),
    name: z.union([z.lazy(() => taskNameSchema), z.null()]).optional(),
    status: z.union([z.lazy(() => taskStatusSchema), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readTasksByAccount200Schema = z.lazy(() => taskPageSchema)

/**
 * @description Validation Error
 */
export const readTasksByAccount422Schema = z.lazy(() => httpValidationErrorSchema)

export const readTasksByAccountQueryResponseSchema = z.lazy(() => readTasksByAccount200Schema)