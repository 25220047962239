// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getChatsSchema.ts"
);
import.meta.hot.lastModified = "1737759757950.038";
}
// REMIX HMR END

// @ts-nocheck
import { chatApiResponsePageSchema } from './chatApiResponsePageSchema'
import { chatsFilterSchema } from './chatsFilterSchema'
import { chatsSortSchema } from './chatsSortSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getChatsPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

export const getChatsQueryParamsSchema = z
  .object({
    sort_ascending: z.boolean().default(true).describe('Sort ascending. If false, sort descending.'),
    sort_by: z
      .lazy(() => chatsSortSchema)
      .default('created_at')
      .describe('The field to sort by.'),
    filter_by: z
      .lazy(() => chatsFilterSchema)
      .default('is_by_user')
      .describe('Filter for a particular kind of chats.'),
    limit: z.number().int().min(1).max(1000).default(100).describe('The maximum number of chats to include in the response.'),
    offset: z.number().int().min(0).default(0).describe('The maximum number of chats to include in the response.'),
    creator_ids: z.array(z.string()).optional(),
    before_timestamp: z.union([z.string().datetime(), z.null()]).optional(),
    after_timestamp: z.union([z.string().datetime(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getChats200Schema = z.lazy(() => chatApiResponsePageSchema)

/**
 * @description Validation Error
 */
export const getChats422Schema = z.lazy(() => httpValidationErrorSchema)

export const getChatsQueryResponseSchema = z.lazy(() => getChats200Schema)