// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/postgresConnectionUpdateSchema.ts"
);
import.meta.hot.lastModified = "1737935769005.74";
}
// REMIX HMR END

// @ts-nocheck
import { connectionPolyTypeSchema } from './connectionPolyTypeSchema'
import { z } from 'zod'

/**
 * @description Properties to receive via API on update.
 */
export const postgresConnectionUpdateSchema = z
  .object({
    name: z.union([z.string(), z.null()]).optional(),
    account_id: z.union([z.string(), z.null()]).optional(),
    is_demo: z.boolean().default(false),
    is_draft: z.boolean().default(false),
    status: z.union([z.string(), z.null()]).optional(),
    status_at: z.union([z.string().datetime(), z.null()]).optional(),
    type: z.lazy(() => connectionPolyTypeSchema).default('postgres'),
    max_tables: z.number().int().default(8000),
    warehouse_access: z.union([z.boolean(), z.null()]).optional(),
    result_cache_access: z.union([z.boolean(), z.null()]).optional(),
    error_msg: z.union([z.string(), z.null()]).optional(),
    user: z.union([z.string(), z.null()]).optional(),
    dbname: z.union([z.string(), z.null()]).optional(),
    host: z.union([z.string(), z.null()]).optional(),
    port: z.union([z.number().int(), z.null()]).optional(),
    ns_database: z.string().default('NUMBERS_STATION'),
    ns_schema: z.string().default('NUMBERS_STATION'),
    password: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Properties to receive via API on update.')