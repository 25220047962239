// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/KnowledgeSort.ts"
);
import.meta.hot.lastModified = "1737935765325.76";
}
// REMIX HMR END

export enum KnowledgeSort {
  'display_name' = 'display_name',
  'updated_at' = 'updated_at',
  'created_at' = 'created_at',
}