// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/addTagSchema.ts"
);
import.meta.hot.lastModified = "1737935764850.7627";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellSchema } from './bumblebeeCellSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tagApiCreateSchema } from './tagApiCreateSchema'
import { z } from 'zod'

export const addTagPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

/**
 * @description Successful Response
 */
export const addTag201Schema = z.lazy(() => bumblebeeCellSchema)

/**
 * @description Validation Error
 */
export const addTag422Schema = z.lazy(() => httpValidationErrorSchema)

export const addTagMutationRequestSchema = z.lazy(() => tagApiCreateSchema)

export const addTagMutationResponseSchema = z.lazy(() => addTag201Schema)