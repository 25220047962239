// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSendMessage.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  SendMessageMutationRequest,
  SendMessageMutationResponse,
  SendMessagePathParams,
  SendMessage404,
  SendMessage409,
  SendMessage422,
} from '../types/SendMessage'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const sendMessageMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/messages/' }] as const

export type SendMessageMutationKey = ReturnType<typeof sendMessageMutationKey>

/**
 * @description Send a message.This endpoint returns a server sent event stream composed of `MessageAPIResponse` objects.The stream will close when the model is done generating responses and is ready for moreinput.If a chat_id is provided, the message will be sent to that chat. A chat will be created andthe MessageAPIResponse will contain the new chat_id. If the chat does not have a name, thena name will be generated asynchronously. It should be available within a few seconds.If the message_text is empty, then the message will be ignored. If the chat is currentlybusy, then this will trigger a 409 CONFLICT response. This can be useful to reattach to achat that is currently running.Note that messages can only be sent by the owner of the chat or by an admin in certain cases.
 * @summary Send Message
 * {@link /api/v3/orgs/:account_name/messages/}
 */
async function sendMessage(
  { accountName, data }: { accountName: SendMessagePathParams['account_name']; data: SendMessageMutationRequest },
  config: Partial<RequestConfig<SendMessageMutationRequest>> = {},
) {
  const res = await client<SendMessageMutationResponse, SendMessage404 | SendMessage409 | SendMessage422, SendMessageMutationRequest>({
    method: 'POST',
    url: `/api/v3/orgs/${accountName}/messages/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Send a message.This endpoint returns a server sent event stream composed of `MessageAPIResponse` objects.The stream will close when the model is done generating responses and is ready for moreinput.If a chat_id is provided, the message will be sent to that chat. A chat will be created andthe MessageAPIResponse will contain the new chat_id. If the chat does not have a name, thena name will be generated asynchronously. It should be available within a few seconds.If the message_text is empty, then the message will be ignored. If the chat is currentlybusy, then this will trigger a 409 CONFLICT response. This can be useful to reattach to achat that is currently running.Note that messages can only be sent by the owner of the chat or by an admin in certain cases.
 * @summary Send Message
 * {@link /api/v3/orgs/:account_name/messages/}
 */
export function useSendMessage(
  options: {
    mutation?: UseMutationOptions<
      SendMessageMutationResponse,
      SendMessage404 | SendMessage409 | SendMessage422,
      { accountName: SendMessagePathParams['account_name']; data: SendMessageMutationRequest }
    >
    client?: Partial<RequestConfig<SendMessageMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? sendMessageMutationKey()

  return useMutation<
    SendMessageMutationResponse,
    SendMessage404 | SendMessage409 | SendMessage422,
    { accountName: SendMessagePathParams['account_name']; data: SendMessageMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return sendMessage({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}