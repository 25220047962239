// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/TaskName.ts"
);
import.meta.hot.lastModified = "1737759757506.0403";
}
// REMIX HMR END

export enum TaskName {
  'celery_test' = 'celery_test',
  'FAKETASK' = 'FAKETASK',
  'agents_eval_reference_chat' = 'agents_eval_reference_chat',
  'alerts_run_alert' = 'alerts_run_alert',
  'autoname_chat' = 'autoname_chat',
  'autoname_conversation' = 'autoname_conversation',
  'bigquery_connection_create' = 'bigquery_connection_create',
  'bumblebee_cell_refresh' = 'bumblebee_cell_refresh',
  'bumblebee_cell_rephrase_question' = 'bumblebee_cell_rephrase_question',
  'bumblebee_cell_run' = 'bumblebee_cell_run',
  'bumblebee_eval_reference_conversation' = 'bumblebee_eval_reference_conversation',
  'bumblebee_regression_test' = 'bumblebee_regression_test',
  'bumblebee_ssds_cold_start' = 'bumblebee_ssds_cold_start',
  'datawarehouse_prune_tables' = 'datawarehouse_prune_tables',
  'delete_cell_tables' = 'delete_cell_tables',
  'demonstrations_patch' = 'demonstrations_patch',
  'er_update_run_demos' = 'er_update_run_demos',
  'enrich_ssds' = 'enrich_ssds',
  'import_from_twb' = 'import_from_twb',
  'enrich_from_twb' = 'enrich_from_twb',
  'enrich_from_mode' = 'enrich_from_mode',
  'infer_display_types_ssds' = 'infer_display_types_ssds',
  'upload_examples' = 'upload_examples',
  'update_ssds' = 'update_ssds',
  'validate_ssds' = 'validate_ssds',
  'run_moonlight' = 'run_moonlight',
  'upload_dataset' = 'upload_dataset',
  'copy_dataset' = 'copy_dataset',
  'update_dataset' = 'update_dataset',
  'learn_dms_from_sql' = 'learn_dms_from_sql',
  'inject_new_dimensions_and_metrics' = 'inject_new_dimensions_and_metrics',
  'run_moonlight_orchestrator' = 'run_moonlight_orchestrator',
  'index_reports' = 'index_reports',
  'refresh_reports' = 'refresh_reports',
  'sync_bb_verified_cell_to_feedback_chat' = 'sync_bb_verified_cell_to_feedback_chat',
  'sync_knowledge_sql' = 'sync_knowledge_sql',
  'dbt_run' = 'dbt_run',
  'datawarehouse_prototype_run' = 'datawarehouse_prototype_run',
  'model_deploy' = 'model_deploy',
  'pipelines_build' = 'pipelines_build',
  'pipelines_run' = 'pipelines_run',
  'refresh_dbt_scripts' = 'refresh_dbt_scripts',
  'sentry_autocomplete' = 'sentry_autocomplete',
  'sentry_autocomplete_snowflake' = 'sentry_autocomplete_snowflake',
  'sentry_finetune_autocomplete' = 'sentry_finetune_autocomplete',
  'sql_generate' = 'sql_generate',
  'sql_update_run' = 'sql_update_run',
  'transform_custom_evaluation' = 'transform_custom_evaluation',
  'transform_model_finetune' = 'transform_model_finetune',
  'transform_prototype_evaluation' = 'transform_prototype_evaluation',
}