// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetRelevantConversationsCellsByDimension.ts"
);
import.meta.hot.lastModified = "1737935769673.7363";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetRelevantConversationsCellsByDimensionQueryResponse,
  GetRelevantConversationsCellsByDimensionPathParams,
  GetRelevantConversationsCellsByDimensionQueryParams,
  GetRelevantConversationsCellsByDimension422,
} from '../types/GetRelevantConversationsCellsByDimension'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getRelevantConversationsCellsByDimensionQueryKey = (
  {
    accountName,
    dimensionId,
  }: {
    accountName: GetRelevantConversationsCellsByDimensionPathParams['account_name']
    dimensionId: GetRelevantConversationsCellsByDimensionPathParams['dimension_id']
  },
  params?: GetRelevantConversationsCellsByDimensionQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/relevant_conversations_cells',
      params: { accountName: accountName, dimensionId: dimensionId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetRelevantConversationsCellsByDimensionQueryKey = ReturnType<typeof getRelevantConversationsCellsByDimensionQueryKey>

/**
 * @description Get conversations and cells that use the given dimension.
 * @summary Get Relevant Conversations Cells By Dimension
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/relevant_conversations_cells}
 */
async function getRelevantConversationsCellsByDimension(
  {
    accountName,
    dimensionId,
    params,
  }: {
    accountName: GetRelevantConversationsCellsByDimensionPathParams['account_name']
    dimensionId: GetRelevantConversationsCellsByDimensionPathParams['dimension_id']
    params?: GetRelevantConversationsCellsByDimensionQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetRelevantConversationsCellsByDimensionQueryResponse, GetRelevantConversationsCellsByDimension422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions/${dimensionId}/relevant_conversations_cells`,
    params,
    ...config,
  })
  return res.data
}

export function getRelevantConversationsCellsByDimensionQueryOptions(
  {
    accountName,
    dimensionId,
    params,
  }: {
    accountName: GetRelevantConversationsCellsByDimensionPathParams['account_name']
    dimensionId: GetRelevantConversationsCellsByDimensionPathParams['dimension_id']
    params?: GetRelevantConversationsCellsByDimensionQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getRelevantConversationsCellsByDimensionQueryKey({ accountName, dimensionId }, params)
  return queryOptions({
    enabled: !!(accountName && dimensionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getRelevantConversationsCellsByDimension({ accountName, dimensionId, params }, config)
    },
  })
}

/**
 * @description Get conversations and cells that use the given dimension.
 * @summary Get Relevant Conversations Cells By Dimension
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/relevant_conversations_cells}
 */
export function useGetRelevantConversationsCellsByDimension<
  TData = GetRelevantConversationsCellsByDimensionQueryResponse,
  TQueryData = GetRelevantConversationsCellsByDimensionQueryResponse,
  TQueryKey extends QueryKey = GetRelevantConversationsCellsByDimensionQueryKey,
>(
  {
    accountName,
    dimensionId,
    params,
  }: {
    accountName: GetRelevantConversationsCellsByDimensionPathParams['account_name']
    dimensionId: GetRelevantConversationsCellsByDimensionPathParams['dimension_id']
    params?: GetRelevantConversationsCellsByDimensionQueryParams
  },
  options: {
    query?: Partial<
      QueryObserverOptions<GetRelevantConversationsCellsByDimensionQueryResponse, GetRelevantConversationsCellsByDimension422, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getRelevantConversationsCellsByDimensionQueryKey({ accountName, dimensionId }, params)

  const query = useQuery({
    ...(getRelevantConversationsCellsByDimensionQueryOptions({ accountName, dimensionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetRelevantConversationsCellsByDimension422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}