// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/defaultSettingUpdateSchema.ts"
);
import.meta.hot.lastModified = "1737935768205.7444";
}
// REMIX HMR END

// @ts-nocheck
import { settingValueSchema } from './settingValueSchema'
import { z } from 'zod'

/**
 * @description Update a default setting.
 */
export const defaultSettingUpdateSchema = z
  .object({
    description: z.union([z.string(), z.null()]).optional(),
    value_object: z.union([z.lazy(() => settingValueSchema), z.null()]).optional(),
    is_superuser_editable: z.union([z.boolean(), z.null()]).optional(),
    is_admin_editable: z.union([z.boolean(), z.null()]).optional(),
    is_value_sent_to_client: z.union([z.boolean(), z.null()]).optional(),
    is_deprecated: z.union([z.boolean(), z.null()]).optional(),
  })
  .describe('Update a default setting.')