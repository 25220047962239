// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/ChatService.ts"
);
import.meta.hot.lastModified = "1737935753888.823";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentAPI } from '../models/AgentAPI';
import type { ChatAPICreate } from '../models/ChatAPICreate';
import type { ChatAPIResponse } from '../models/ChatAPIResponse';
import type { ChatAPIResponsePage } from '../models/ChatAPIResponsePage';
import type { ChatAPIUpdate } from '../models/ChatAPIUpdate';
import type { ChatMessagePromptlogInDB } from '../models/ChatMessagePromptlogInDB';
import type { ChatsFilter } from '../models/ChatsFilter';
import type { ChatsSort } from '../models/ChatsSort';
import type { FeedbackChatLinkedChatIdResponse } from '../models/FeedbackChatLinkedChatIdResponse';
import type { MessageAPIResponsePage } from '../models/MessageAPIResponsePage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChatService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Chat Agents
   * Get list of available agents by name.
   * @param accountName The name of the organization or account.
   * @param sessionId
   * @returns AgentAPI Successful Response
   * @throws ApiError
   */
  public getChatAgents(
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<AgentAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/agents',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Chat
   * Create a chat. This will not send a message to the chat.
   *
   * Note that you can also create a chat using the send message endpoint. Doing so will
   * let you skip making a separate call to create the chat.
   * @param accountName The name of the organization or account.
   * @param requestBody
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public createChat(
    accountName: string,
    requestBody: ChatAPICreate,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Chats
   * Read all chats.
   * @param accountName The name of the organization or account.
   * @param sortAscending Sort ascending. If false, sort descending.
   * @param sortBy The field to sort by.
   * @param filterBy Filter for a particular kind of chats.
   * @param limit The maximum number of chats to include in the response.
   * @param offset The maximum number of chats to include in the response.
   * @param creatorIds
   * @param beforeTimestamp
   * @param afterTimestamp
   * @param sessionId
   * @returns ChatAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getChats(
    accountName: string,
    sortAscending: boolean = true,
    sortBy?: ChatsSort,
    filterBy?: ChatsFilter,
    limit: number = 100,
    offset?: number,
    creatorIds?: Array<string>,
    beforeTimestamp?: (string | null),
    afterTimestamp?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponsePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'sort_ascending': sortAscending,
        'sort_by': sortBy,
        'filter_by': filterBy,
        'limit': limit,
        'offset': offset,
        'creator_ids': creatorIds,
        'before_timestamp': beforeTimestamp,
        'after_timestamp': afterTimestamp,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Chat
   * Delete a chat.
   *
   * CAUTION: This is unrecoverable. All messages associated with the chat will be deleted.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public deleteChat(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Chat
   * Update a chat.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param requestBody
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public updateChat(
    accountName: string,
    chatId: string,
    requestBody: ChatAPIUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Chat
   * Read a single chat.
   *
   * Note that this does NOT include the messages of the chat. See the get
   * chat messages endpoint (GET api/v3/orgs/{account_name}/chats/{chat_id}/messages/) for that.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public getChat(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get By Slack Id
   * Read a single chat by slack ID.
   *
   * Note that this does NOT include the messages of the chat. See the get
   * chat messages endpoint (GET api/v3/orgs/{account_name}/chats/{chat_id}/messages/) for that.
   * If a chat with the given slack ID does not exist, then this will 404.
   * @param accountName The name of the organization or account.
   * @param slackId The slack ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public getBySlackId(
    accountName: string,
    slackId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/slack',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'slack_id': slackId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Chat Promptlogs
   * Get chat message promptlogs sorted in descending order.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatMessagePromptlogInDB Successful Response
   * @throws ApiError
   */
  public getChatPromptlogs(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<ChatMessagePromptlogInDB>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/promptlogs',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Chat Messages
   * Get messages in a chat.
   *
   * This paginated endpoint returns a chat with up to the requested number of
   * messages. Note that the messages are ordered from newest to oldest.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param limit The maximum number of messages to include in the response.
   * @param offset The messages to skip. The response will not include indexes greater than the offset. Equivalent of 'skip' in database queries.
   * @param sessionId
   * @returns MessageAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getChatMessages(
    accountName: string,
    chatId: string,
    limit: number = 20,
    offset?: number,
    sessionId?: (string | null),
  ): CancelablePromise<MessageAPIResponsePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/messages',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'offset': offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Send Interrupt
   * Send a interrupt signal in a chat.
   *
   * Note that this sends the signal regardless of whether the AI is currently
   * generating
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public sendInterrupt(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/interrupt',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Clone Chat
   * Clone a chat with the new user as the creator.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public cloneChat(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/clone',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Check Feedback Chat Has Admin Feedback
   * Check if the feedback chat has admin feedback.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public checkFeedbackChatHasAdminFeedback(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/has_admin_feedback',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Linked User Chat Id
   * Check if the feedback chat has admin feedback.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns FeedbackChatLinkedChatIdResponse Successful Response
   * @throws ApiError
   */
  public getLinkedUserChatId(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<FeedbackChatLinkedChatIdResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/linked_user_chat_id',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
