// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/reportFolderSchema.ts"
);
import.meta.hot.lastModified = "1737935766026.7563";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Folder that contains reports.\n\nThis represents a Tableau project or a Mode collection.\n\nid -- An identifier unique to this specific folder.\nname -- A user-friendly display name for the folder. Shown in the UI.
 */
export const reportFolderSchema = z
  .object({
    id: z.string(),
    name: z.string(),
  })
  .describe(
    'Folder that contains reports.\n\nThis represents a Tableau project or a Mode collection.\n\nid -- An identifier unique to this specific folder.\nname -- A user-friendly display name for the folder. Shown in the UI.',
  )