// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/copyJobUpdateSchema.ts"
);
import.meta.hot.lastModified = "1737759759186.0317";
}
// REMIX HMR END

// @ts-nocheck
import { conversationCellFilterSchema } from './conversationCellFilterSchema'
import { copyReportSchema } from './copyReportSchema'
import { datasetJobTypeSchema } from './datasetJobTypeSchema'
import { dimensionToReviewUpdateSchema } from './dimensionToReviewUpdateSchema'
import { metricToReviewUpdateSchema } from './metricToReviewUpdateSchema'
import { tableOrViewInfoCreateSchema } from './tableOrViewInfoCreateSchema'
import { tableOrViewInfoUpdateSchema } from './tableOrViewInfoUpdateSchema'
import { z } from 'zod'

/**
 * @description Properties to receive on item update.
 */
export const copyJobUpdateSchema = z
  .object({
    enterprise_id: z.union([z.string(), z.null()]).optional(),
    type: z.lazy(() => datasetJobTypeSchema).default('COPY'),
    src_account_name: z.union([z.string(), z.null()]).optional(),
    src_ssds_id: z.union([z.string(), z.null()]).optional(),
    dst_connection_id: z.union([z.string(), z.null()]).optional(),
    dst_account_name: z.union([z.string(), z.null()]).optional(),
    dst_ssds_name: z.union([z.string(), z.null()]).optional(),
    conversation_cell_filter: z.lazy(() => conversationCellFilterSchema).default('ALL'),
    overwrite_user_question_for_sensitive_cell: z.boolean().default(false),
    max_eval_messages: z.union([z.number().int(), z.null()]).optional(),
    tables_and_views: z.union([z.array(z.union([z.lazy(() => tableOrViewInfoUpdateSchema), z.lazy(() => tableOrViewInfoCreateSchema)])), z.null()]).optional(),
    dst_ssds_id: z.union([z.string(), z.null()]).optional(),
    sensitive_dimensions: z.union([z.array(z.lazy(() => dimensionToReviewUpdateSchema)), z.null()]).optional(),
    sensitive_metrics: z.union([z.array(z.lazy(() => metricToReviewUpdateSchema)), z.null()]).optional(),
    report: z.union([z.lazy(() => copyReportSchema), z.null()]).optional(),
  })
  .describe('Properties to receive on item update.')