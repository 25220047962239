// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createMetricSchema.ts"
);
import.meta.hot.lastModified = "1737759758961.0327";
}
// REMIX HMR END

// @ts-nocheck
import { bodyCreateMetricApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdMetricsPostSchema } from './bodyCreateMetricApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdMetricsPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { metricDetailSchema } from './metricDetailSchema'
import { z } from 'zod'

export const createMetricPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const createMetric201Schema = z.union([z.lazy(() => metricDetailSchema), z.null()])

/**
 * @description Validation Error
 */
export const createMetric422Schema = z.lazy(() => httpValidationErrorSchema)

export const createMetricMutationRequestSchema = z.lazy(
  () => bodyCreateMetricApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdMetricsPostSchema,
)

export const createMetricMutationResponseSchema = z.lazy(() => createMetric201Schema)