// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messagePayloadTypeSchema.ts"
);
import.meta.hot.lastModified = "1737935767874.746";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description The type of payload that can be sent in a message.\n\nAttributes:\n    TEXT: A text message.\n    SEMANTIC_QUERY: A semantic query.\n    TABLE: A table of data.\n    ERROR: An error message.\n    PROMPT_OPTIONS: A message with prompt options.
 */
export const messagePayloadTypeSchema = z
  .enum(['TEXT', 'SEMANTIC_QUERY', 'TABLE', 'ERROR', 'PROMPT_OPTIONS'])
  .describe(
    'The type of payload that can be sent in a message.\n\nAttributes:\n    TEXT: A text message.\n    SEMANTIC_QUERY: A semantic query.\n    TABLE: A table of data.\n    ERROR: An error message.\n    PROMPT_OPTIONS: A message with prompt options.',
  )