// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ChatsSort.ts"
);
import.meta.hot.lastModified = "1737935753939.8225";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Sort chats by.
 */
export enum ChatsSort {
  CREATED_AT = 'created_at',
  LAST_MODIFIED_AT = 'last_modified_at',
  CREATOR_NAME = 'creator_name',
  NAME = 'name',
}
