// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useLoginAccessToken.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  LoginAccessTokenMutationRequest,
  LoginAccessTokenMutationResponse,
  LoginAccessTokenQueryParams,
  LoginAccessToken422,
} from '../types/LoginAccessToken'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const loginAccessTokenMutationKey = () => [{ url: '/api/v1/login/access-token' }] as const

export type LoginAccessTokenMutationKey = ReturnType<typeof loginAccessTokenMutationKey>

/**
 * @description OAuth2-compatible token login, get a bearer token for future requests.The bearer token must be included in the Authorization header of all future requests.Note that access token authentication cannot be used to access user-specific resourcessuch as user passwords and superuser status.
 * @summary Login Access Token
 * {@link /api/v1/login/access-token}
 */
async function loginAccessToken(
  { data, params }: { data: LoginAccessTokenMutationRequest; params?: LoginAccessTokenQueryParams },
  config: Partial<RequestConfig<LoginAccessTokenMutationRequest>> = {},
) {
  const res = await client<LoginAccessTokenMutationResponse, LoginAccessToken422, LoginAccessTokenMutationRequest>({
    method: 'POST',
    url: `/api/v1/login/access-token`,
    params,
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...config.headers },
    ...config,
  })
  return res.data
}

/**
 * @description OAuth2-compatible token login, get a bearer token for future requests.The bearer token must be included in the Authorization header of all future requests.Note that access token authentication cannot be used to access user-specific resourcessuch as user passwords and superuser status.
 * @summary Login Access Token
 * {@link /api/v1/login/access-token}
 */
export function useLoginAccessToken(
  options: {
    mutation?: UseMutationOptions<
      LoginAccessTokenMutationResponse,
      LoginAccessToken422,
      { data: LoginAccessTokenMutationRequest; params?: LoginAccessTokenQueryParams }
    >
    client?: Partial<RequestConfig<LoginAccessTokenMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? loginAccessTokenMutationKey()

  return useMutation<LoginAccessTokenMutationResponse, LoginAccessToken422, { data: LoginAccessTokenMutationRequest; params?: LoginAccessTokenQueryParams }>({
    mutationFn: async ({ data, params }) => {
      return loginAccessToken({ data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}