// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chartConfigSchema.ts"
);
import.meta.hot.lastModified = "1737759758412.0356";
}
// REMIX HMR END

// @ts-nocheck
import { chartConfigDmmSchema } from './chartConfigDmmSchema'
import { chartTypeSchema } from './chartTypeSchema'
import { z } from 'zod'

/**
 * @description Properties to receive on Chart Config read.
 */
export const chartConfigSchema = z
  .object({
    type: z.lazy(() => chartTypeSchema),
    flip_axis: z.boolean(),
    semantic_query_id: z.string(),
    country_name: z.union([z.string(), z.null()]).optional(),
    limit: z.union([z.number().int(), z.null()]).optional(),
    id: z.string(),
    selected: z.array(z.lazy(() => chartConfigDmmSchema)),
  })
  .describe('Properties to receive on Chart Config read.')