// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRemoveTag.ts"
);
import.meta.hot.lastModified = "1737935765108.7612";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RemoveTagMutationResponse, RemoveTagPathParams, RemoveTag422 } from '../types/RemoveTag'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const removeTagMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/tags/{tag_id}' }] as const

export type RemoveTagMutationKey = ReturnType<typeof removeTagMutationKey>

/**
 * @description Remove a tag from a bumblebee cell.
 * @summary Remove Tag
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/tags/:tag_id}
 */
async function removeTag(
  {
    tagId,
    accountName,
    conversationId,
    cellId,
  }: {
    tagId: RemoveTagPathParams['tag_id']
    accountName: RemoveTagPathParams['account_name']
    conversationId: RemoveTagPathParams['conversation_id']
    cellId: RemoveTagPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RemoveTagMutationResponse, RemoveTag422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/tags/${tagId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Remove a tag from a bumblebee cell.
 * @summary Remove Tag
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/tags/:tag_id}
 */
export function useRemoveTag(
  options: {
    mutation?: UseMutationOptions<
      RemoveTagMutationResponse,
      RemoveTag422,
      {
        tagId: RemoveTagPathParams['tag_id']
        accountName: RemoveTagPathParams['account_name']
        conversationId: RemoveTagPathParams['conversation_id']
        cellId: RemoveTagPathParams['cell_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? removeTagMutationKey()

  return useMutation<
    RemoveTagMutationResponse,
    RemoveTag422,
    {
      tagId: RemoveTagPathParams['tag_id']
      accountName: RemoveTagPathParams['account_name']
      conversationId: RemoveTagPathParams['conversation_id']
      cellId: RemoveTagPathParams['cell_id']
    }
  >({
    mutationFn: async ({ tagId, accountName, conversationId, cellId }) => {
      return removeTag({ tagId, accountName, conversationId, cellId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}