// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/loginAccessTokenApiKeySchema.ts"
);
import.meta.hot.lastModified = "1737759761449.02";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tokenSchema } from './tokenSchema'
import { z } from 'zod'

export const loginAccessTokenApiKeyQueryParamsSchema = z.object({
  account_name: z.string().describe('The name of the account to authenticate the API key against.'),
  email: z.string().email().describe('The email address of the user in this account to authenticate.'),
  create: z
    .boolean()
    .default(false)
    .describe(
      "Whether to create the user if it doesn't exist. If set to false and the user with the email is not part of the account, the endpoint will return a 404. If set to true and the user does not exist, the endpoint will create the user and add them to the account. If set to true and the user exists, the endpoint will add the user to the account.",
    ),
  m2m_token: z
    .union([z.string(), z.null()])
    .describe(
      "A custom M2M access token in case one is needed to access resources in your environment. This is not relevant for cloud customers. It's only used for VPC deployments.",
    )
    .optional(),
})

/**
 * @description Successful Response
 */
export const loginAccessTokenApiKey200Schema = z.lazy(() => tokenSchema)

/**
 * @description Validation Error
 */
export const loginAccessTokenApiKey422Schema = z.lazy(() => httpValidationErrorSchema)

export const loginAccessTokenApiKeyMutationResponseSchema = z.lazy(() => loginAccessTokenApiKey200Schema)