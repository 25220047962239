// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUploadDataset.ts"
);
import.meta.hot.lastModified = "1737935766031.756";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UploadDatasetMutationRequest, UploadDatasetMutationResponse, UploadDatasetPathParams, UploadDataset422 } from '../types/UploadDataset'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const uploadDatasetMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/datasets/upload' }] as const

export type UploadDatasetMutationKey = ReturnType<typeof uploadDatasetMutationKey>

/**
 * @description Add a dataset from a JSON file.The user must both be an account admin and eval maintainer to upload the dataset.
 * @summary Upload Dataset
 * {@link /api/v1/accounts/:account_name/datasets/upload}
 */
async function uploadDataset(
  { accountName, data }: { accountName: UploadDatasetPathParams['account_name']; data: UploadDatasetMutationRequest },
  config: Partial<RequestConfig<UploadDatasetMutationRequest>> = {},
) {
  const res = await client<UploadDatasetMutationResponse, UploadDataset422, UploadDatasetMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/datasets/upload`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Add a dataset from a JSON file.The user must both be an account admin and eval maintainer to upload the dataset.
 * @summary Upload Dataset
 * {@link /api/v1/accounts/:account_name/datasets/upload}
 */
export function useUploadDataset(
  options: {
    mutation?: UseMutationOptions<
      UploadDatasetMutationResponse,
      UploadDataset422,
      { accountName: UploadDatasetPathParams['account_name']; data: UploadDatasetMutationRequest }
    >
    client?: Partial<RequestConfig<UploadDatasetMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? uploadDatasetMutationKey()

  return useMutation<
    UploadDatasetMutationResponse,
    UploadDataset422,
    { accountName: UploadDatasetPathParams['account_name']; data: UploadDatasetMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return uploadDataset({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}