// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateUserSchema.ts"
);
import.meta.hot.lastModified = "1737759758213.0366";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { userSchema } from './userSchema'
import { userUpdateSchema } from './userUpdateSchema'
import { z } from 'zod'

export const updateUserPathParamsSchema = z.object({
  user_id: z.union([z.string(), z.null()]),
})

export const updateUserQueryParamsSchema = z
  .object({
    user_email: z.union([z.string().email(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const updateUser200Schema = z.lazy(() => userSchema)

/**
 * @description Validation Error
 */
export const updateUser422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateUserMutationRequestSchema = z.lazy(() => userUpdateSchema)

export const updateUserMutationResponseSchema = z.lazy(() => updateUser200Schema)