// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/connectionPolyTypeSchema.ts"
);
import.meta.hot.lastModified = "1737759760680.024";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Enumerates the possible connection types.
 */
export const connectionPolyTypeSchema = z
  .enum(['bigquery', 'databricks', 'connection', 'postgres', 'redshift', 's3', 'snowflake', 'teradata'])
  .describe('Enumerates the possible connection types.')