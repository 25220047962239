// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/loginAccessTokenSchema.ts"
);
import.meta.hot.lastModified = "1737759760038.0273";
}
// REMIX HMR END

// @ts-nocheck
import { bodyLoginAccessTokenApiV1LoginAccessTokenPostSchema } from './bodyLoginAccessTokenApiV1LoginAccessTokenPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tokenSchema } from './tokenSchema'
import { z } from 'zod'

export const loginAccessTokenQueryParamsSchema = z
  .object({
    account_name: z
      .union([z.string(), z.null()])
      .describe(
        'The name of the account to validate that the user is authorized to access. If the user does not have access to this account, the endpoint will return a 403 even if the user is successfully authenticated. This is useful for third party applications which want users to be restricted to a particular account.',
      )
      .optional(),
    m2m_token: z
      .union([z.string(), z.null()])
      .describe(
        "A custom M2M access token in case one is needed to access resources in your environment. This is not relevant for cloud customers. It's only used for VPC deployments.",
      )
      .optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const loginAccessToken200Schema = z.lazy(() => tokenSchema)

/**
 * @description Validation Error
 */
export const loginAccessToken422Schema = z.lazy(() => httpValidationErrorSchema)

export const loginAccessTokenMutationRequestSchema = z.lazy(() => bodyLoginAccessTokenApiV1LoginAccessTokenPostSchema)

export const loginAccessTokenMutationResponseSchema = z.lazy(() => loginAccessToken200Schema)