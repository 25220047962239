// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useResetAccountSuperuserSettings.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ResetAccountSuperuserSettingsMutationRequest,
  ResetAccountSuperuserSettingsMutationResponse,
  ResetAccountSuperuserSettingsPathParams,
  ResetAccountSuperuserSettings422,
} from '../types/ResetAccountSuperuserSettings'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const resetAccountSuperuserSettingsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/superuser-settings/reset' }] as const

export type ResetAccountSuperuserSettingsMutationKey = ReturnType<typeof resetAccountSuperuserSettingsMutationKey>

/**
 * @description Reset superuser settings for an account.Resets the specified settings to their default values. Note thatsuperuser settings take precedence over regular account settings.
 * @summary Reset Account Superuser Settings
 * {@link /api/v1/accounts/:account_name/superuser-settings/reset}
 */
async function resetAccountSuperuserSettings(
  { accountName, data }: { accountName: ResetAccountSuperuserSettingsPathParams['account_name']; data?: ResetAccountSuperuserSettingsMutationRequest },
  config: Partial<RequestConfig<ResetAccountSuperuserSettingsMutationRequest>> = {},
) {
  const res = await client<ResetAccountSuperuserSettingsMutationResponse, ResetAccountSuperuserSettings422, ResetAccountSuperuserSettingsMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/superuser-settings/reset`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Reset superuser settings for an account.Resets the specified settings to their default values. Note thatsuperuser settings take precedence over regular account settings.
 * @summary Reset Account Superuser Settings
 * {@link /api/v1/accounts/:account_name/superuser-settings/reset}
 */
export function useResetAccountSuperuserSettings(
  options: {
    mutation?: UseMutationOptions<
      ResetAccountSuperuserSettingsMutationResponse,
      ResetAccountSuperuserSettings422,
      { accountName: ResetAccountSuperuserSettingsPathParams['account_name']; data?: ResetAccountSuperuserSettingsMutationRequest }
    >
    client?: Partial<RequestConfig<ResetAccountSuperuserSettingsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? resetAccountSuperuserSettingsMutationKey()

  return useMutation<
    ResetAccountSuperuserSettingsMutationResponse,
    ResetAccountSuperuserSettings422,
    { accountName: ResetAccountSuperuserSettingsPathParams['account_name']; data?: ResetAccountSuperuserSettingsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return resetAccountSuperuserSettings({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}