// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dimensionDetailSchema.ts"
);
import.meta.hot.lastModified = "1737935766766.7522";
}
// REMIX HMR END

// @ts-nocheck
import { displayTypeSchema } from './displayTypeSchema'
import { filterCategorySchema } from './filterCategorySchema'
import { originTypeSchema } from './originTypeSchema'
import { publishStateSchema } from './publishStateSchema'
import { userReferenceSchema } from './userReferenceSchema'
import { z } from 'zod'

/**
 * @description API schema of a dimension containing all details.\n\nUsed in frontend.
 */
export const dimensionDetailSchema = z
  .object({
    id: z.string(),
    data_source_id: z.string(),
    name: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    expr: z.string(),
    display_name: z.string(),
    expr_type: z.lazy(() => filterCategorySchema),
    display_type: z.lazy(() => displayTypeSchema),
    state: z.lazy(() => publishStateSchema),
    state_updated_by: z.union([z.lazy(() => userReferenceSchema), z.null()]).optional(),
    updated_by: z.union([z.lazy(() => userReferenceSchema), z.null()]).optional(),
    is_sensitive: z.boolean().default(false),
    is_literal_sensitive: z.boolean().default(false),
    origin: z.array(z.lazy(() => originTypeSchema)),
    popularity: z.number().min(0).max(1),
    user_updated_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    created_at: z.string().datetime(),
  })
  .describe('API schema of a dimension containing all details.\n\nUsed in frontend.')