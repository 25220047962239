// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createChatSchema.ts"
);
import.meta.hot.lastModified = "1737935765531.759";
}
// REMIX HMR END

// @ts-nocheck
import { chatApiCreateSchema } from './chatApiCreateSchema'
import { chatApiResponseSchema } from './chatApiResponseSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const createChatPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

/**
 * @description Successful Response
 */
export const createChat201Schema = z.lazy(() => chatApiResponseSchema)

/**
 * @description Validation Error
 */
export const createChat422Schema = z.lazy(() => httpValidationErrorSchema)

export const createChatMutationRequestSchema = z.lazy(() => chatApiCreateSchema)

export const createChatMutationResponseSchema = z.lazy(() => createChat201Schema)