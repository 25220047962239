// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/importFromTwbSchema.ts"
);
import.meta.hot.lastModified = "1737759759189.0317";
}
// REMIX HMR END

// @ts-nocheck
import { bodyImportFromTwbApiV1AccountsAccountNameStarSchemaDataSourceImportFromTwbPostSchema } from './bodyImportFromTwbApiV1AccountsAccountNameStarSchemaDataSourceImportFromTwbPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const importFromTwbPathParamsSchema = z.object({
  account_name: z.string(),
})

export const importFromTwbQueryParamsSchema = z.object({
  ssds_id: z.string(),
  update_identifiers: z.boolean().default(true),
  connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const importFromTwb200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const importFromTwb422Schema = z.lazy(() => httpValidationErrorSchema)

export const importFromTwbMutationRequestSchema = z.lazy(() => bodyImportFromTwbApiV1AccountsAccountNameStarSchemaDataSourceImportFromTwbPostSchema)

export const importFromTwbMutationResponseSchema = z.lazy(() => importFromTwb200Schema)