// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useValidateBigqueryConnection.ts"
);
import.meta.hot.lastModified = "1737759762053.0168";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ValidateBigqueryConnectionMutationResponse,
  ValidateBigqueryConnectionPathParams,
  ValidateBigqueryConnection422,
} from '../types/ValidateBigqueryConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const validateBigqueryConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}/validate' }] as const

export type ValidateBigqueryConnectionMutationKey = ReturnType<typeof validateBigqueryConnectionMutationKey>

/**
 * @description Validate a BigQuery connection.
 * @summary Validate Bigquery Connection
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id/validate}
 */
async function validateBigqueryConnection(
  {
    accountName,
    connectionId,
  }: { accountName: ValidateBigqueryConnectionPathParams['account_name']; connectionId: ValidateBigqueryConnectionPathParams['connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ValidateBigqueryConnectionMutationResponse, ValidateBigqueryConnection422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/bigquery/${connectionId}/validate`,
    ...config,
  })
  return res.data
}

/**
 * @description Validate a BigQuery connection.
 * @summary Validate Bigquery Connection
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id/validate}
 */
export function useValidateBigqueryConnection(
  options: {
    mutation?: UseMutationOptions<
      ValidateBigqueryConnectionMutationResponse,
      ValidateBigqueryConnection422,
      { accountName: ValidateBigqueryConnectionPathParams['account_name']; connectionId: ValidateBigqueryConnectionPathParams['connection_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? validateBigqueryConnectionMutationKey()

  return useMutation<
    ValidateBigqueryConnectionMutationResponse,
    ValidateBigqueryConnection422,
    { accountName: ValidateBigqueryConnectionPathParams['account_name']; connectionId: ValidateBigqueryConnectionPathParams['connection_id'] }
  >({
    mutationFn: async ({ accountName, connectionId }) => {
      return validateBigqueryConnection({ accountName, connectionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}