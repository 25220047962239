// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messagePayloadOptionalSchema.ts"
);
import.meta.hot.lastModified = "1737935768612.742";
}
// REMIX HMR END

// @ts-nocheck
import { errorPayloadSchema } from './errorPayloadSchema'
import { messagePayloadTypeSchema } from './messagePayloadTypeSchema'
import { promptOptionsPayloadSchema } from './promptOptionsPayloadSchema'
import { tablePayloadSchema } from './tablePayloadSchema'
import { taskApiSchema } from './taskApiSchema'
import { textPayloadSchema } from './textPayloadSchema'
import { z } from 'zod'

/**
 * @description A message payload.\n\nAttributes:\n    payload_type: The type of payload.\n    task: The ongoing task associated with the message if the payload is being\n        computed. None if the payload is ready.\n    payload: The payload of the message. May be None.
 */
export const messagePayloadOptionalSchema = z
  .object({
    payload_type: z.lazy(() => messagePayloadTypeSchema),
    task: z.union([z.lazy(() => taskApiSchema), z.null()]),
    payload: z.union([
      z.lazy(() => tablePayloadSchema),
      z.lazy(() => promptOptionsPayloadSchema),
      z.lazy(() => errorPayloadSchema),
      z.lazy(() => textPayloadSchema),
      z.null(),
    ]),
  })
  .describe(
    'A message payload.\n\nAttributes:\n    payload_type: The type of payload.\n    task: The ongoing task associated with the message if the payload is being\n        computed. None if the payload is ready.\n    payload: The payload of the message. May be None.',
  )