// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/ReportsService.ts"
);
import.meta.hot.lastModified = "1737759746342.098";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModeConnection } from '../models/ModeConnection';
import type { Report } from '../models/Report';
import type { ReportFolder } from '../models/ReportFolder';
import type { ReportType } from '../models/ReportType';
import type { TableauConnection } from '../models/TableauConnection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReportsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read Report Connections
   * List existing report connections.
   * @param accountName
   * @param limit
   * @param skip
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readReportConnections(
    accountName: string,
    limit: number = 100,
    skip?: number,
    sessionId?: (string | null),
  ): CancelablePromise<Array<(TableauConnection | ModeConnection)>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/reports/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Report Folders
   * Read the report folders.
   *
   * These are high-level collections that contain reports (e.g. Tableau projects or Mode collections).
   * @param accountName
   * @param connectionType
   * @param connectionId
   * @param sessionId
   * @returns ReportFolder Successful Response
   * @throws ApiError
   */
  public readReportFolders(
    accountName: string,
    connectionType: ReportType,
    connectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<ReportFolder>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/reports/{connection_type}/{connection_id}/folders',
      path: {
        'account_name': accountName,
        'connection_type': connectionType,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Reports From Folder
   * Read the reports in the given folder.
   * @param accountName
   * @param connectionType
   * @param connectionId
   * @param requestBody
   * @param sessionId
   * @returns Report Successful Response
   * @throws ApiError
   */
  public readReportsFromFolder(
    accountName: string,
    connectionType: ReportType,
    connectionId: string,
    requestBody: ReportFolder,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Report>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/reports/{connection_type}/{connection_id}/folders/reports',
      path: {
        'account_name': accountName,
        'connection_type': connectionType,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
