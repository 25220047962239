// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ZenView.ts"
);
import.meta.hot.lastModified = "1737935764662.7637";
}
// REMIX HMR END

import type { ZenDimension } from './ZenDimension'
import type { ZenIdentifier } from './ZenIdentifier'
import type { ZenMetric } from './ZenMetric'

export enum ZenViewType {
  'view' = 'view',
}

/**
 * @description Metrics layer view.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/view
 */
export type ZenView = {
  sync_id?: string | null
  /**
   * @default "view"
   * @type string | undefined
   */
  type?: ZenViewType
  /**
   * @type string
   */
  name: string
  /**
   * @default 0
   * @type integer | undefined
   */
  index?: number
  model_name?: string | null
  description?: string | null
  sql_table?: string | null
  derived_table_sql?: string | null
  /**
   * @type array | undefined
   */
  identifiers?: ZenIdentifier[]
  /**
   * @type array | undefined
   */
  dimensions?: ZenDimension[]
  /**
   * @type array | undefined
   */
  metrics?: ZenMetric[]
  default_date?: ZenDimension | null
}