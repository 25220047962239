// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/semanticQuerySchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { dialectSchema } from './dialectSchema'
import { dimensionFilterSchema } from './dimensionFilterSchema'
import { dimensionViewSchema } from './dimensionViewSchema'
import { metricFilterSchema } from './metricFilterSchema'
import { metricViewSchema } from './metricViewSchema'
import { z } from 'zod'

/**
 * @description Semantic Query class.
 */
export const semanticQuerySchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    dimensions: z.array(z.lazy(() => dimensionViewSchema)),
    metrics: z.array(z.lazy(() => metricViewSchema)),
    dimension_where: z.array(z.lazy(() => dimensionFilterSchema)),
    metric_where: z.array(z.lazy(() => metricFilterSchema)),
    order: z.array(z.union([z.lazy(() => dimensionViewSchema), z.lazy(() => metricViewSchema)])),
    limit: z.union([z.number().int(), z.null()]).optional(),
    dialect: z.lazy(() => dialectSchema),
  })
  .describe('Semantic Query class.')