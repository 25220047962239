// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/recoverPasswordSchema.ts"
);
import.meta.hot.lastModified = "1737935766766.7522";
}
// REMIX HMR END

// @ts-nocheck
import { bodyRecoverPasswordApiV1PasswordRecoveryPostSchema } from './bodyRecoverPasswordApiV1PasswordRecoveryPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { msgSchema } from './msgSchema'
import { z } from 'zod'

/**
 * @description Successful Response
 */
export const recoverPassword200Schema = z.lazy(() => msgSchema)

/**
 * @description Validation Error
 */
export const recoverPassword422Schema = z.lazy(() => httpValidationErrorSchema)

export const recoverPasswordMutationRequestSchema = z.lazy(() => bodyRecoverPasswordApiV1PasswordRecoveryPostSchema)

export const recoverPasswordMutationResponseSchema = z.lazy(() => recoverPassword200Schema)