// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/Provider.ts"
);
import.meta.hot.lastModified = "1737935764663.7637";
}
// REMIX HMR END

export enum Provider {
  'okta' = 'okta',
  'google' = 'google',
}