// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDatabricksConnection.ts"
);
import.meta.hot.lastModified = "1737759762053.0168";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateDatabricksConnectionMutationRequest,
  UpdateDatabricksConnectionMutationResponse,
  UpdateDatabricksConnectionPathParams,
  UpdateDatabricksConnection422,
} from '../types/UpdateDatabricksConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDatabricksConnectionMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}' }] as const

export type UpdateDatabricksConnectionMutationKey = ReturnType<typeof updateDatabricksConnectionMutationKey>

/**
 * @description Update Databricks connection.
 * @summary Update Databricks Connection
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id}
 */
async function updateDatabricksConnection(
  {
    accountName,
    databricksConnectionId,
    data,
  }: {
    accountName: UpdateDatabricksConnectionPathParams['account_name']
    databricksConnectionId: UpdateDatabricksConnectionPathParams['databricks_connection_id']
    data?: UpdateDatabricksConnectionMutationRequest
  },
  config: Partial<RequestConfig<UpdateDatabricksConnectionMutationRequest>> = {},
) {
  const res = await client<UpdateDatabricksConnectionMutationResponse, UpdateDatabricksConnection422, UpdateDatabricksConnectionMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/connections/databricks/${databricksConnectionId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update Databricks connection.
 * @summary Update Databricks Connection
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id}
 */
export function useUpdateDatabricksConnection(
  options: {
    mutation?: UseMutationOptions<
      UpdateDatabricksConnectionMutationResponse,
      UpdateDatabricksConnection422,
      {
        accountName: UpdateDatabricksConnectionPathParams['account_name']
        databricksConnectionId: UpdateDatabricksConnectionPathParams['databricks_connection_id']
        data?: UpdateDatabricksConnectionMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateDatabricksConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDatabricksConnectionMutationKey()

  return useMutation<
    UpdateDatabricksConnectionMutationResponse,
    UpdateDatabricksConnection422,
    {
      accountName: UpdateDatabricksConnectionPathParams['account_name']
      databricksConnectionId: UpdateDatabricksConnectionPathParams['databricks_connection_id']
      data?: UpdateDatabricksConnectionMutationRequest
    }
  >({
    mutationFn: async ({ accountName, databricksConnectionId, data }) => {
      return updateDatabricksConnection({ accountName, databricksConnectionId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}