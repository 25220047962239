// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadDatabricksConnectionSchemas.ts"
);
import.meta.hot.lastModified = "1737759762506.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadDatabricksConnectionSchemasQueryResponse,
  ReadDatabricksConnectionSchemasPathParams,
  ReadDatabricksConnectionSchemasQueryParams,
  ReadDatabricksConnectionSchemas422,
} from '../types/ReadDatabricksConnectionSchemas'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readDatabricksConnectionSchemasQueryKey = (
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: ReadDatabricksConnectionSchemasPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionSchemasPathParams['databricks_connection_id']
  },
  params: ReadDatabricksConnectionSchemasQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/schemas',
      params: { accountName: accountName, databricksConnectionId: databricksConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadDatabricksConnectionSchemasQueryKey = ReturnType<typeof readDatabricksConnectionSchemasQueryKey>

/**
 * @description Read Databricks schemas.
 * @summary Read Databricks Connection Schemas
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/schemas}
 */
async function readDatabricksConnectionSchemas(
  {
    accountName,
    databricksConnectionId,
    params,
  }: {
    accountName: ReadDatabricksConnectionSchemasPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionSchemasPathParams['databricks_connection_id']
    params: ReadDatabricksConnectionSchemasQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadDatabricksConnectionSchemasQueryResponse, ReadDatabricksConnectionSchemas422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/databricks/${databricksConnectionId}/schemas`,
    params,
    ...config,
  })
  return res.data
}

export function readDatabricksConnectionSchemasQueryOptions(
  {
    accountName,
    databricksConnectionId,
    params,
  }: {
    accountName: ReadDatabricksConnectionSchemasPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionSchemasPathParams['databricks_connection_id']
    params: ReadDatabricksConnectionSchemasQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readDatabricksConnectionSchemasQueryKey({ accountName, databricksConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && databricksConnectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readDatabricksConnectionSchemas({ accountName, databricksConnectionId, params }, config)
    },
  })
}

/**
 * @description Read Databricks schemas.
 * @summary Read Databricks Connection Schemas
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/schemas}
 */
export function useReadDatabricksConnectionSchemas<
  TData = ReadDatabricksConnectionSchemasQueryResponse,
  TQueryData = ReadDatabricksConnectionSchemasQueryResponse,
  TQueryKey extends QueryKey = ReadDatabricksConnectionSchemasQueryKey,
>(
  {
    accountName,
    databricksConnectionId,
    params,
  }: {
    accountName: ReadDatabricksConnectionSchemasPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionSchemasPathParams['databricks_connection_id']
    params: ReadDatabricksConnectionSchemasQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadDatabricksConnectionSchemasQueryResponse, ReadDatabricksConnectionSchemas422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readDatabricksConnectionSchemasQueryKey({ accountName, databricksConnectionId }, params)

  const query = useQuery({
    ...(readDatabricksConnectionSchemasQueryOptions({ accountName, databricksConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadDatabricksConnectionSchemas422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}