// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messageApiGetResponseSchema.ts"
);
import.meta.hot.lastModified = "1737935768445.743";
}
// REMIX HMR END

// @ts-nocheck
import { messagePayloadOptionalSchema } from './messagePayloadOptionalSchema'
import { senderBasicApiResponseSchema } from './senderBasicApiResponseSchema'
import { z } from 'zod'

/**
 * @description A message returned from the API as part of a get response.\n\nNote that it inherits from MessageAPIBatchResponse, so it has the same attributes.
 */
export const messageApiGetResponseSchema = z
  .object({
    id: z.string(),
    index: z.number().int(),
    responds_to: z.union([z.string(), z.null()]),
    created_at: z.string().datetime(),
    sender: z.lazy(() => senderBasicApiResponseSchema),
    message_payload: z.lazy(() => messagePayloadOptionalSchema),
    feedback_thumb_down: z.boolean(),
  })
  .describe('A message returned from the API as part of a get response.\n\nNote that it inherits from MessageAPIBatchResponse, so it has the same attributes.')