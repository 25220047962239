// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/TeradataService.ts"
);
import.meta.hot.lastModified = "1737759746340.098";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TeradataConnection } from '../models/TeradataConnection';
import type { TeradataConnectionCreate } from '../models/TeradataConnectionCreate';
import type { TeradataConnectionUpdate } from '../models/TeradataConnectionUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TeradataService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Teradata Connection
   * Create new teradata connection.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns TeradataConnection Successful Response
   * @throws ApiError
   */
  public createTeradataConnection(
    accountName: string,
    requestBody: TeradataConnectionCreate,
    sessionId?: (string | null),
  ): CancelablePromise<TeradataConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/teradata/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Teradata Connection
   * Read teradata connection.
   * @param accountName
   * @param teradataConnectionId
   * @param sessionId
   * @returns TeradataConnection Successful Response
   * @throws ApiError
   */
  public readTeradataConnection(
    accountName: string,
    teradataConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<TeradataConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}',
      path: {
        'account_name': accountName,
        'teradata_connection_id': teradataConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Teradata Connection
   * Update teradata connection.
   * @param accountName
   * @param teradataConnectionId
   * @param requestBody
   * @param sessionId
   * @returns TeradataConnection Successful Response
   * @throws ApiError
   */
  public updateTeradataConnection(
    accountName: string,
    teradataConnectionId: string,
    requestBody: TeradataConnectionUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<TeradataConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}',
      path: {
        'account_name': accountName,
        'teradata_connection_id': teradataConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Teradata Connection
   * Delete teradata connection.
   * @param accountName
   * @param teradataConnectionId
   * @param sessionId
   * @returns TeradataConnection Successful Response
   * @throws ApiError
   */
  public deleteTeradataConnection(
    accountName: string,
    teradataConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<TeradataConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}',
      path: {
        'account_name': accountName,
        'teradata_connection_id': teradataConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Teradata Connection Databases
   * Read Teradata databases.
   *
   * Note: Because Teradata has only a single level of qualification for tables,
   * we return a hard-coded list here for consistency with the other connectors.
   * @param accountName
   * @param teradataConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readTeradataConnectionDatabases(
    accountName: string,
    teradataConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}/databases',
      path: {
        'account_name': accountName,
        'teradata_connection_id': teradataConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Teradata Connection Schemas
   * Read teradata schemas.
   * @param accountName
   * @param teradataConnectionId
   * @param database
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readTeradataConnectionSchemas(
    accountName: string,
    teradataConnectionId: string,
    database: string,
    sessionId?: (string | null),
  ): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}/schemas',
      path: {
        'account_name': accountName,
        'teradata_connection_id': teradataConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'database': database,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Teradata Connection Tables
   * Read teradata tables.
   * @param accountName
   * @param teradataConnectionId
   * @param database
   * @param schema
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readTeradataConnectionTables(
    accountName: string,
    teradataConnectionId: string,
    database: string,
    schema?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}/tables',
      path: {
        'account_name': accountName,
        'teradata_connection_id': teradataConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'database': database,
        'schema': schema,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
