// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readSqlValidationsByAccountSchema.ts"
);
import.meta.hot.lastModified = "1737759762281.0156";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { originTypeSchema } from './originTypeSchema'
import { sqlValidationSchema } from './sqlValidationSchema'
import { z } from 'zod'

export const readSqlValidationsByAccountPathParamsSchema = z.object({
  account_name: z.string(),
})

export const readSqlValidationsByAccountQueryParamsSchema = z
  .object({
    origin: z.union([z.lazy(() => originTypeSchema), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readSqlValidationsByAccount200Schema = z.array(z.lazy(() => sqlValidationSchema))

/**
 * @description Validation Error
 */
export const readSqlValidationsByAccount422Schema = z.lazy(() => httpValidationErrorSchema)

export const readSqlValidationsByAccountQueryResponseSchema = z.lazy(() => readSqlValidationsByAccount200Schema)