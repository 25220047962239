// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getSsdsJoinSqlsSchema.ts"
);
import.meta.hot.lastModified = "1737935766773.7522";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { ssdsJoinSchema } from './ssdsJoinSchema'
import { z } from 'zod'

export const getSsdsJoinSqlsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const getSsdsJoinSqls200Schema = z.array(z.lazy(() => ssdsJoinSchema))

/**
 * @description Validation Error
 */
export const getSsdsJoinSqls422Schema = z.lazy(() => httpValidationErrorSchema)

export const getSsdsJoinSqlsQueryResponseSchema = z.lazy(() => getSsdsJoinSqls200Schema)