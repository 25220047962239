// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/DimensionView.ts"
);
import.meta.hot.lastModified = "1737935765324.76";
}
// REMIX HMR END

import type { Dialect } from './Dialect'
import type { DisplayType } from './DisplayType'
import type { FilterCategory } from './FilterCategory'
import type { OriginType } from './OriginType'
import type { PublishState } from './PublishState'

export enum DimensionViewFieldType {
  'dimension' = 'dimension',
}

/**
 * @description Simplified Dimension View.\n\nUsed in SemanticLayerView.
 */
export type DimensionView = {
  id?: string | null
  sync_id?: string | null
  group_id?: string | null
  /**
   * @type string
   */
  name: string
  description?: string | null
  /**
   * @type string
   */
  dialect: Dialect
  expr?: string | null
  zen_sql?: string | null
  /**
   * @default "unknown"
   */
  expr_type?: FilterCategory
  display_name?: string | null
  data_source_id?: string | null
  /**
   * @type array | undefined
   */
  origin?: OriginType[]
  top_values?: string[] | null
  /**
   * @default "unknown"
   */
  display_type?: DisplayType
  /**
   * @default 1
   * @type number | undefined
   */
  popularity?: number
  /**
   * @default false
   * @type boolean | undefined
   */
  is_sensitive?: boolean
  /**
   * @default false
   * @type boolean | undefined
   */
  is_literal_sensitive?: boolean
  /**
   * @default false
   * @type boolean | undefined
   */
  is_display_sensitive?: boolean
  updated_at?: string | null
  created_at?: string | null
  /**
   * @default "dimension"
   * @type string | undefined
   */
  field_type?: DimensionViewFieldType
  /**
   * @default "Verified"
   */
  state?: PublishState
}