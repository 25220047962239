// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/postgresConnectionLookupSchema.ts"
);
import.meta.hot.lastModified = "1737759761840.0178";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Postgres Connection Lookup.
 */
export const postgresConnectionLookupSchema = z
  .object({
    dbname: z.string(),
    host: z.string(),
    port: z.number().int(),
  })
  .describe('Postgres Connection Lookup.')