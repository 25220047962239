// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadSqlValidationsByAccount.ts"
);
import.meta.hot.lastModified = "1737759762071.0168";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadSqlValidationsByAccountQueryResponse,
  ReadSqlValidationsByAccountPathParams,
  ReadSqlValidationsByAccountQueryParams,
  ReadSqlValidationsByAccount422,
} from '../types/ReadSqlValidationsByAccount'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readSqlValidationsByAccountQueryKey = (
  { accountName }: { accountName: ReadSqlValidationsByAccountPathParams['account_name'] },
  params?: ReadSqlValidationsByAccountQueryParams,
) =>
  [{ url: '/api/v1/accounts/:account_name/star_schema_data_source/sql_validation', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type ReadSqlValidationsByAccountQueryKey = ReturnType<typeof readSqlValidationsByAccountQueryKey>

/**
 * @description Get the learned DMs from an account.
 * @summary Read Sql Validations By Account
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/sql_validation}
 */
async function readSqlValidationsByAccount(
  { accountName, params }: { accountName: ReadSqlValidationsByAccountPathParams['account_name']; params?: ReadSqlValidationsByAccountQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadSqlValidationsByAccountQueryResponse, ReadSqlValidationsByAccount422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/sql_validation`,
    params,
    ...config,
  })
  return res.data
}

export function readSqlValidationsByAccountQueryOptions(
  { accountName, params }: { accountName: ReadSqlValidationsByAccountPathParams['account_name']; params?: ReadSqlValidationsByAccountQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readSqlValidationsByAccountQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readSqlValidationsByAccount({ accountName, params }, config)
    },
  })
}

/**
 * @description Get the learned DMs from an account.
 * @summary Read Sql Validations By Account
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/sql_validation}
 */
export function useReadSqlValidationsByAccount<
  TData = ReadSqlValidationsByAccountQueryResponse,
  TQueryData = ReadSqlValidationsByAccountQueryResponse,
  TQueryKey extends QueryKey = ReadSqlValidationsByAccountQueryKey,
>(
  { accountName, params }: { accountName: ReadSqlValidationsByAccountPathParams['account_name']; params?: ReadSqlValidationsByAccountQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadSqlValidationsByAccountQueryResponse, ReadSqlValidationsByAccount422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readSqlValidationsByAccountQueryKey({ accountName }, params)

  const query = useQuery({
    ...(readSqlValidationsByAccountQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadSqlValidationsByAccount422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}