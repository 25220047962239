// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createRemappedSsdsSchema.ts"
);
import.meta.hot.lastModified = "1737935767878.746";
}
// REMIX HMR END

// @ts-nocheck
import { bodyCreateRemappedSsdsApiV1AccountsAccountNameStarSchemaDataSourceRemapSsdsPostSchema } from './bodyCreateRemappedSsdsApiV1AccountsAccountNameStarSchemaDataSourceRemapSsdsPostSchema'
import { dialectSchema } from './dialectSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceBaseSchema } from './starSchemaDataSourceBaseSchema'
import { z } from 'zod'

export const createRemappedSsdsPathParamsSchema = z.object({
  account_name: z.string(),
})

export const createRemappedSsdsQueryParamsSchema = z.object({
  default_database: z.string(),
  default_schema: z.string(),
  dialect: z.lazy(() => dialectSchema),
  slim_ssds: z.boolean().default(false),
})

/**
 * @description Successful Response
 */
export const createRemappedSsds201Schema = z.lazy(() => starSchemaDataSourceBaseSchema)

/**
 * @description Validation Error
 */
export const createRemappedSsds422Schema = z.lazy(() => httpValidationErrorSchema)

export const createRemappedSsdsMutationRequestSchema = z.lazy(() => bodyCreateRemappedSsdsApiV1AccountsAccountNameStarSchemaDataSourceRemapSsdsPostSchema)

export const createRemappedSsdsMutationResponseSchema = z.lazy(() => createRemappedSsds201Schema)