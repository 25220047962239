// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readTasksByIdsSchema.ts"
);
import.meta.hot.lastModified = "1737935766487.7537";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const readTasksByIdsPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

export const readTasksByIdsQueryParamsSchema = z
  .object({
    task_id: z.array(z.string()).describe('A list of task ids to retrieve.').optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readTasksByIds200Schema = z.array(z.lazy(() => taskSchema))

/**
 * @description Validation Error
 */
export const readTasksByIds422Schema = z.lazy(() => httpValidationErrorSchema)

export const readTasksByIdsQueryResponseSchema = z.lazy(() => readTasksByIds200Schema)