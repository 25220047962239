// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/remappedQueryJoinInfoSchema.ts"
);
import.meta.hot.lastModified = "1737935768445.743";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const remappedQueryJoinInfoSchema = z.object({
  left_right: z.union([z.tuple([z.string(), z.string()]), z.null()]),
  sql_on: z.union([z.string(), z.null()]),
})