// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ZenDimension.ts"
);
import.meta.hot.lastModified = "1737935754060.822";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Dialect } from './Dialect';
import type { DisplayType } from './DisplayType';
import type { FilterCategory } from './FilterCategory';
import type { OriginType } from './OriginType';
import type { PublishState } from './PublishState';
import type { ZenFieldFilter } from './ZenFieldFilter';

/**
 * Metrics layer dimension.
 *
 * Ref: https://docs.zenlytic.com/docs/data_modeling/dimension
 */
export type ZenDimension = {
  id?: (string | null);
  sync_id?: (string | null);
  group_id?: (string | null);
  name: string;
  field_type?: ZenDimension.field_type;
  description?: (string | null);
  primary_key?: boolean;
  sql: string;
  ns_expr?: (string | null);
  filters?: (Array<ZenFieldFilter> | null);
  top_values?: (Array<string> | null);
  state?: PublishState;
  expr_type?: FilterCategory;
  display_name?: (string | null);
  data_source_id?: (string | null);
  is_sensitive?: boolean;
  is_literal_sensitive?: boolean;
  origin?: Array<OriginType>;
  dialect?: Dialect;
  display_type?: DisplayType;
  popularity?: number;
  updated_at?: (string | null);
  created_at?: (string | null);
};

export namespace ZenDimension {

  export enum field_type {
    DIMENSION = 'dimension',
  }


}

