// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useTestCeleryResponse.ts"
);
import.meta.hot.lastModified = "1737759760438.0251";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  TestCeleryResponseMutationRequest,
  TestCeleryResponseMutationResponse,
  TestCeleryResponseQueryParams,
  TestCeleryResponse422,
} from '../types/TestCeleryResponse'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const testCeleryResponseMutationKey = () => [{ url: '/api/v1/utils/test-celery/' }] as const

export type TestCeleryResponseMutationKey = ReturnType<typeof testCeleryResponseMutationKey>

/**
 * @description Test Celery worker.
 * @summary Test Celery Response
 * {@link /api/v1/utils/test-celery/}
 */
async function testCeleryResponse(
  { data, params }: { data: TestCeleryResponseMutationRequest; params: TestCeleryResponseQueryParams },
  config: Partial<RequestConfig<TestCeleryResponseMutationRequest>> = {},
) {
  const res = await client<TestCeleryResponseMutationResponse, TestCeleryResponse422, TestCeleryResponseMutationRequest>({
    method: 'POST',
    url: `/api/v1/utils/test-celery/`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Test Celery worker.
 * @summary Test Celery Response
 * {@link /api/v1/utils/test-celery/}
 */
export function useTestCeleryResponse(
  options: {
    mutation?: UseMutationOptions<
      TestCeleryResponseMutationResponse,
      TestCeleryResponse422,
      { data: TestCeleryResponseMutationRequest; params: TestCeleryResponseQueryParams }
    >
    client?: Partial<RequestConfig<TestCeleryResponseMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? testCeleryResponseMutationKey()

  return useMutation<
    TestCeleryResponseMutationResponse,
    TestCeleryResponse422,
    { data: TestCeleryResponseMutationRequest; params: TestCeleryResponseQueryParams }
  >({
    mutationFn: async ({ data, params }) => {
      return testCeleryResponse({ data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}