// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useStartConversation.ts"
);
import.meta.hot.lastModified = "1737935767422.7485";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  StartConversationMutationRequest,
  StartConversationMutationResponse,
  StartConversationPathParams,
  StartConversation422,
} from '../types/StartConversation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const startConversationMutationKey = () => [{ url: '/api/v2/orgs/{account_name}/conversations/' }] as const

export type StartConversationMutationKey = ReturnType<typeof startConversationMutationKey>

/**
 * @description Start a conversation.This endpoint will start a new conversation with the NS application. Ifaccepted_payload_types is null then all types are accepted. The response willinclude the details of the message and the conversation.
 * @summary Start Conversation
 * {@link /api/v2/orgs/:account_name/conversations/}
 */
async function startConversation(
  { accountName, data }: { accountName: StartConversationPathParams['account_name']; data: StartConversationMutationRequest },
  config: Partial<RequestConfig<StartConversationMutationRequest>> = {},
) {
  const res = await client<StartConversationMutationResponse, StartConversation422, StartConversationMutationRequest>({
    method: 'POST',
    url: `/api/v2/orgs/${accountName}/conversations/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Start a conversation.This endpoint will start a new conversation with the NS application. Ifaccepted_payload_types is null then all types are accepted. The response willinclude the details of the message and the conversation.
 * @summary Start Conversation
 * {@link /api/v2/orgs/:account_name/conversations/}
 */
export function useStartConversation(
  options: {
    mutation?: UseMutationOptions<
      StartConversationMutationResponse,
      StartConversation422,
      { accountName: StartConversationPathParams['account_name']; data: StartConversationMutationRequest }
    >
    client?: Partial<RequestConfig<StartConversationMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? startConversationMutationKey()

  return useMutation<
    StartConversationMutationResponse,
    StartConversation422,
    { accountName: StartConversationPathParams['account_name']; data: StartConversationMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return startConversation({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}