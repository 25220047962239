// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSearchSimilarVerifiedCells.ts"
);
import.meta.hot.lastModified = "1737935769206.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  SearchSimilarVerifiedCellsQueryResponse,
  SearchSimilarVerifiedCellsPathParams,
  SearchSimilarVerifiedCellsQueryParams,
  SearchSimilarVerifiedCells422,
} from '../types/SearchSimilarVerifiedCells'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const searchSimilarVerifiedCellsQueryKey = (
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: SearchSimilarVerifiedCellsPathParams['account_name']
    conversationId: SearchSimilarVerifiedCellsPathParams['conversation_id']
    cellId: SearchSimilarVerifiedCellsPathParams['cell_id']
  },
  params?: SearchSimilarVerifiedCellsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/search_similar',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId },
    },
    ...(params ? [params] : []),
  ] as const

export type SearchSimilarVerifiedCellsQueryKey = ReturnType<typeof searchSimilarVerifiedCellsQueryKey>

/**
 * @description Search for similar verified cells.NOTE: These are not the same cells used during retrieval when the cell was created.This uses the same function to retrieve similar cells, but because this endpoint isused each time the cell is loaded, it is possible to retrieve cells that are newly verifiedand similar to the current cell (which the cell did not use when it was run.)Ideally we can make the BBRetrievedMetadata.retrieved_examples stateful and persistedin the cell objects which would allow us to retrieve the same examples each time the cell is loaded.
 * @summary Search Similar Verified Cells
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/search_similar}
 */
async function searchSimilarVerifiedCells(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: SearchSimilarVerifiedCellsPathParams['account_name']
    conversationId: SearchSimilarVerifiedCellsPathParams['conversation_id']
    cellId: SearchSimilarVerifiedCellsPathParams['cell_id']
    params?: SearchSimilarVerifiedCellsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<SearchSimilarVerifiedCellsQueryResponse, SearchSimilarVerifiedCells422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/search_similar`,
    params,
    ...config,
  })
  return res.data
}

export function searchSimilarVerifiedCellsQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: SearchSimilarVerifiedCellsPathParams['account_name']
    conversationId: SearchSimilarVerifiedCellsPathParams['conversation_id']
    cellId: SearchSimilarVerifiedCellsPathParams['cell_id']
    params?: SearchSimilarVerifiedCellsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = searchSimilarVerifiedCellsQueryKey({ accountName, conversationId, cellId }, params)
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return searchSimilarVerifiedCells({ accountName, conversationId, cellId, params }, config)
    },
  })
}

/**
 * @description Search for similar verified cells.NOTE: These are not the same cells used during retrieval when the cell was created.This uses the same function to retrieve similar cells, but because this endpoint isused each time the cell is loaded, it is possible to retrieve cells that are newly verifiedand similar to the current cell (which the cell did not use when it was run.)Ideally we can make the BBRetrievedMetadata.retrieved_examples stateful and persistedin the cell objects which would allow us to retrieve the same examples each time the cell is loaded.
 * @summary Search Similar Verified Cells
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/search_similar}
 */
export function useSearchSimilarVerifiedCells<
  TData = SearchSimilarVerifiedCellsQueryResponse,
  TQueryData = SearchSimilarVerifiedCellsQueryResponse,
  TQueryKey extends QueryKey = SearchSimilarVerifiedCellsQueryKey,
>(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: SearchSimilarVerifiedCellsPathParams['account_name']
    conversationId: SearchSimilarVerifiedCellsPathParams['conversation_id']
    cellId: SearchSimilarVerifiedCellsPathParams['cell_id']
    params?: SearchSimilarVerifiedCellsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<SearchSimilarVerifiedCellsQueryResponse, SearchSimilarVerifiedCells422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? searchSimilarVerifiedCellsQueryKey({ accountName, conversationId, cellId }, params)

  const query = useQuery({
    ...(searchSimilarVerifiedCellsQueryOptions({ accountName, conversationId, cellId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, SearchSimilarVerifiedCells422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}