// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createApiKeySchema.ts"
);
import.meta.hot.lastModified = "1737759758959.0327";
}
// REMIX HMR END

// @ts-nocheck
import { apiKeyApiCreateSchema } from './apiKeyApiCreateSchema'
import { apiKeyCreateResponseSchema } from './apiKeyCreateResponseSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const createApiKeyPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const createApiKey200Schema = z.lazy(() => apiKeyCreateResponseSchema)

/**
 * @description Validation Error
 */
export const createApiKey422Schema = z.lazy(() => httpValidationErrorSchema)

export const createApiKeyMutationRequestSchema = z.lazy(() => apiKeyApiCreateSchema)

export const createApiKeyMutationResponseSchema = z.lazy(() => createApiKey200Schema)