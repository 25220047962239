// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readTasksByIdsV2Schema.ts"
);
import.meta.hot.lastModified = "1737935767414.7485";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { taskApiSchema } from './taskApiSchema'
import { z } from 'zod'

export const readTasksByIdsV2PathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

export const readTasksByIdsV2QueryParamsSchema = z
  .object({
    task_id: z.array(z.string()).describe('A list of task ids to retrieve.').optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readTasksByIdsV2200Schema = z.array(z.lazy(() => taskApiSchema))

/**
 * @description Validation Error
 */
export const readTasksByIdsV2422Schema = z.lazy(() => httpValidationErrorSchema)

export const readTasksByIdsV2QueryResponseSchema = z.lazy(() => readTasksByIdsV2200Schema)