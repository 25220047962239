// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateMessageFeedbackSchema.ts"
);
import.meta.hot.lastModified = "1737759761269.021";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { messageApiResponseSchema } from './messageApiResponseSchema'
import { messageUpdateFeedbackApiSchema } from './messageUpdateFeedbackApiSchema'
import { z } from 'zod'

export const updateMessageFeedbackPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  message_id: z.string().describe('The id of a message in a chat.'),
})

/**
 * @description Successful Response
 */
export const updateMessageFeedback200Schema = z.lazy(() => messageApiResponseSchema)

/**
 * @description Validation Error
 */
export const updateMessageFeedback422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateMessageFeedbackMutationRequestSchema = z.lazy(() => messageUpdateFeedbackApiSchema)

export const updateMessageFeedbackMutationResponseSchema = z.lazy(() => updateMessageFeedback200Schema)