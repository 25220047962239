// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bbExampleSchema.ts"
);
import.meta.hot.lastModified = "1737759757951.038";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionViewWithoutDialectSchema } from './dimensionViewWithoutDialectSchema'
import { metricViewWithoutDialectSchema } from './metricViewWithoutDialectSchema'
import { queryWithValidationSchema } from './queryWithValidationSchema'
import { z } from 'zod'

/**
 * @description Bumblebee Example.
 */
export const bbExampleSchema = z
  .object({
    question: z.string(),
    question_openai_embedding: z.union([z.array(z.number()), z.null()]).optional(),
    question_variants: z.array(z.string()).optional(),
    id: z.union([z.string(), z.null()]).optional(),
    ssds_id: z.union([z.string(), z.null()]).optional(),
    summary: z.union([z.string(), z.null()]).optional(),
    query: z.union([z.lazy(() => queryWithValidationSchema), z.null()]).optional(),
    additional_dimensions: z.union([z.array(z.lazy(() => dimensionViewWithoutDialectSchema)), z.null()]).optional(),
    additional_metrics: z.union([z.array(z.lazy(() => metricViewWithoutDialectSchema)), z.null()]).optional(),
  })
  .describe('Bumblebee Example.')