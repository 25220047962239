// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useEvents.ts"
);
import.meta.hot.lastModified = "1737935764670.7637";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { EventsMutationResponse } from '../types/Events'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const eventsMutationKey = () => [{ url: '/api/v3/slack/events' }] as const

export type EventsMutationKey = ReturnType<typeof eventsMutationKey>

/**
 * @summary Events
 * {@link /api/v3/slack/events}
 */
async function events(config: Partial<RequestConfig> = {}) {
  const res = await client<EventsMutationResponse, Error, unknown>({ method: 'POST', url: `/api/v3/slack/events`, ...config })
  return res.data
}

/**
 * @summary Events
 * {@link /api/v3/slack/events}
 */
export function useEvents(
  options: {
    mutation?: UseMutationOptions<EventsMutationResponse, Error>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? eventsMutationKey()

  return useMutation<EventsMutationResponse, Error>({
    mutationFn: async () => {
      return events(config)
    },
    mutationKey,
    ...mutationOptions,
  })
}