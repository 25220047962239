// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateCopyJob.ts"
);
import.meta.hot.lastModified = "1737759758962.0327";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateCopyJobMutationRequest, UpdateCopyJobMutationResponse, UpdateCopyJobPathParams, UpdateCopyJob422 } from '../types/UpdateCopyJob'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateCopyJobMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/copy/{job_id}' }] as const

export type UpdateCopyJobMutationKey = ReturnType<typeof updateCopyJobMutationKey>

/**
 * @description Update a copy job.
 * @summary Update Copy Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/copy/:job_id}
 */
async function updateCopyJob(
  {
    enterpriseName,
    jobId,
    data,
  }: { enterpriseName: UpdateCopyJobPathParams['enterprise_name']; jobId: UpdateCopyJobPathParams['job_id']; data?: UpdateCopyJobMutationRequest },
  config: Partial<RequestConfig<UpdateCopyJobMutationRequest>> = {},
) {
  const res = await client<UpdateCopyJobMutationResponse, UpdateCopyJob422, UpdateCopyJobMutationRequest>({
    method: 'PUT',
    url: `/api/v1/enterprises/${enterpriseName}/dataset-jobs/copy/${jobId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a copy job.
 * @summary Update Copy Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/copy/:job_id}
 */
export function useUpdateCopyJob(
  options: {
    mutation?: UseMutationOptions<
      UpdateCopyJobMutationResponse,
      UpdateCopyJob422,
      { enterpriseName: UpdateCopyJobPathParams['enterprise_name']; jobId: UpdateCopyJobPathParams['job_id']; data?: UpdateCopyJobMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateCopyJobMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateCopyJobMutationKey()

  return useMutation<
    UpdateCopyJobMutationResponse,
    UpdateCopyJob422,
    { enterpriseName: UpdateCopyJobPathParams['enterprise_name']; jobId: UpdateCopyJobPathParams['job_id']; data?: UpdateCopyJobMutationRequest }
  >({
    mutationFn: async ({ enterpriseName, jobId, data }) => {
      return updateCopyJob({ enterpriseName, jobId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}