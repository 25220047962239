// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRefreshTableFromMode.ts"
);
import.meta.hot.lastModified = "1737759760889.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RefreshTableFromModeMutationResponse,
  RefreshTableFromModePathParams,
  RefreshTableFromModeQueryParams,
  RefreshTableFromMode422,
} from '../types/RefreshTableFromMode'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const refreshTableFromModeMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/refresh_table_from_mode' }] as const

export type RefreshTableFromModeMutationKey = ReturnType<typeof refreshTableFromModeMutationKey>

/**
 * @description Refresh a table from a mode report.We read the query token from the data source's table and use it to refresh the table.This assumes that the table was created via `create_table_from_mode`.
 * @summary Refresh Table From Mode
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/refresh_table_from_mode}
 */
async function refreshTableFromMode(
  { accountName, params }: { accountName: RefreshTableFromModePathParams['account_name']; params: RefreshTableFromModeQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RefreshTableFromModeMutationResponse, RefreshTableFromMode422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/refresh_table_from_mode`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Refresh a table from a mode report.We read the query token from the data source's table and use it to refresh the table.This assumes that the table was created via `create_table_from_mode`.
 * @summary Refresh Table From Mode
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/refresh_table_from_mode}
 */
export function useRefreshTableFromMode(
  options: {
    mutation?: UseMutationOptions<
      RefreshTableFromModeMutationResponse,
      RefreshTableFromMode422,
      { accountName: RefreshTableFromModePathParams['account_name']; params: RefreshTableFromModeQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? refreshTableFromModeMutationKey()

  return useMutation<
    RefreshTableFromModeMutationResponse,
    RefreshTableFromMode422,
    { accountName: RefreshTableFromModePathParams['account_name']; params: RefreshTableFromModeQueryParams }
  >({
    mutationFn: async ({ accountName, params }) => {
      return refreshTableFromMode({ accountName, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}