// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteDataSource.ts"
);
import.meta.hot.lastModified = "1737935767038.7507";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteDataSourceMutationResponse, DeleteDataSourcePathParams, DeleteDataSource422 } from '../types/DeleteDataSource'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteDataSourceMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}' }] as const

export type DeleteDataSourceMutationKey = ReturnType<typeof deleteDataSourceMutationKey>

/**
 * @description Delete a data source and set the states of all related dimensions and metrics invalid.
 * @summary Delete Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id}
 */
async function deleteDataSource(
  {
    accountName,
    starSchemaDataSourceId,
    dataSourceId,
  }: {
    accountName: DeleteDataSourcePathParams['account_name']
    starSchemaDataSourceId: DeleteDataSourcePathParams['star_schema_data_source_id']
    dataSourceId: DeleteDataSourcePathParams['data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteDataSourceMutationResponse, DeleteDataSource422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/data_sources/${dataSourceId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete a data source and set the states of all related dimensions and metrics invalid.
 * @summary Delete Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id}
 */
export function useDeleteDataSource(
  options: {
    mutation?: UseMutationOptions<
      DeleteDataSourceMutationResponse,
      DeleteDataSource422,
      {
        accountName: DeleteDataSourcePathParams['account_name']
        starSchemaDataSourceId: DeleteDataSourcePathParams['star_schema_data_source_id']
        dataSourceId: DeleteDataSourcePathParams['data_source_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteDataSourceMutationKey()

  return useMutation<
    DeleteDataSourceMutationResponse,
    DeleteDataSource422,
    {
      accountName: DeleteDataSourcePathParams['account_name']
      starSchemaDataSourceId: DeleteDataSourcePathParams['star_schema_data_source_id']
      dataSourceId: DeleteDataSourcePathParams['data_source_id']
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, dataSourceId }) => {
      return deleteDataSource({ accountName, starSchemaDataSourceId, dataSourceId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}