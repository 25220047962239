// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createPostgresConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935769010.7397";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { postgresConnectionCreateSchema } from './postgresConnectionCreateSchema'
import { postgresConnectionSchema } from './postgresConnectionSchema'
import { z } from 'zod'

export const createPostgresConnectionPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const createPostgresConnection200Schema = z.lazy(() => postgresConnectionSchema)

/**
 * @description Validation Error
 */
export const createPostgresConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const createPostgresConnectionMutationRequestSchema = z.lazy(() => postgresConnectionCreateSchema)

export const createPostgresConnectionMutationResponseSchema = z.lazy(() => createPostgresConnection200Schema)