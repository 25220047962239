// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/conversationCellTupleSchema.ts"
);
import.meta.hot.lastModified = "1737759761259.021";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellBasicSchema } from './bumblebeeCellBasicSchema'
import { conversationBasicSchema } from './conversationBasicSchema'
import { z } from 'zod'

/**
 * @description Conversation and cell tuple.
 */
export const conversationCellTupleSchema = z
  .object({
    conversation: z.lazy(() => conversationBasicSchema),
    cell: z.lazy(() => bumblebeeCellBasicSchema),
  })
  .describe('Conversation and cell tuple.')