// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateMetric.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateMetricMutationRequest, UpdateMetricMutationResponse, UpdateMetricPathParams, UpdateMetric422 } from '../types/UpdateMetric'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateMetricMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}' }] as const

export type UpdateMetricMutationKey = ReturnType<typeof updateMetricMutationKey>

/**
 * @description Update a metric.
 * @summary Update Metric
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id}
 */
async function updateMetric(
  {
    accountName,
    metricId,
    data,
  }: { accountName: UpdateMetricPathParams['account_name']; metricId: UpdateMetricPathParams['metric_id']; data?: UpdateMetricMutationRequest },
  config: Partial<RequestConfig<UpdateMetricMutationRequest>> = {},
) {
  const res = await client<UpdateMetricMutationResponse, UpdateMetric422, UpdateMetricMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/${metricId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a metric.
 * @summary Update Metric
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id}
 */
export function useUpdateMetric(
  options: {
    mutation?: UseMutationOptions<
      UpdateMetricMutationResponse,
      UpdateMetric422,
      { accountName: UpdateMetricPathParams['account_name']; metricId: UpdateMetricPathParams['metric_id']; data?: UpdateMetricMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateMetricMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMetricMutationKey()

  return useMutation<
    UpdateMetricMutationResponse,
    UpdateMetric422,
    { accountName: UpdateMetricPathParams['account_name']; metricId: UpdateMetricPathParams['metric_id']; data?: UpdateMetricMutationRequest }
  >({
    mutationFn: async ({ accountName, metricId, data }) => {
      return updateMetric({ accountName, metricId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}