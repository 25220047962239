// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRunEvalOnReferenceConversations.ts"
);
import.meta.hot.lastModified = "1737935769648.7363";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RunEvalOnReferenceConversationsMutationRequest,
  RunEvalOnReferenceConversationsMutationResponse,
  RunEvalOnReferenceConversationsPathParams,
  RunEvalOnReferenceConversationsQueryParams,
  RunEvalOnReferenceConversations422,
} from '../types/RunEvalOnReferenceConversations'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const runEvalOnReferenceConversationsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/eval_reference_conversations' }] as const

export type RunEvalOnReferenceConversationsMutationKey = ReturnType<typeof runEvalOnReferenceConversationsMutationKey>

/**
 * @description Run eval on reference conversations.Args:    max_eval_conversations: The upper limit of conversations to run.    eval_filter: Select which cells to include as ground-truth cells.    moonlight_run_params: Run parameters for cells.    run_in_reverse_order: Preserve upstream context by running cells in reverse order to independently evaluate each cell in a conversation.        We clone a conversation from reference and then rerun from the last (when this is set True) to reuse the reference context instead of the predicted context.    delete_conversations: Remove newly-created conversations after evaluation.
 * @summary Run Eval On Reference Conversations
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/eval_reference_conversations}
 */
async function runEvalOnReferenceConversations(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: RunEvalOnReferenceConversationsPathParams['account_name']
    starSchemaDataSourceId: RunEvalOnReferenceConversationsPathParams['star_schema_data_source_id']
    data: RunEvalOnReferenceConversationsMutationRequest
    params?: RunEvalOnReferenceConversationsQueryParams
  },
  config: Partial<RequestConfig<RunEvalOnReferenceConversationsMutationRequest>> = {},
) {
  const res = await client<RunEvalOnReferenceConversationsMutationResponse, RunEvalOnReferenceConversations422, RunEvalOnReferenceConversationsMutationRequest>(
    {
      method: 'POST',
      url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/eval_reference_conversations`,
      params,
      data,
      ...config,
    },
  )
  return res.data
}

/**
 * @description Run eval on reference conversations.Args:    max_eval_conversations: The upper limit of conversations to run.    eval_filter: Select which cells to include as ground-truth cells.    moonlight_run_params: Run parameters for cells.    run_in_reverse_order: Preserve upstream context by running cells in reverse order to independently evaluate each cell in a conversation.        We clone a conversation from reference and then rerun from the last (when this is set True) to reuse the reference context instead of the predicted context.    delete_conversations: Remove newly-created conversations after evaluation.
 * @summary Run Eval On Reference Conversations
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/eval_reference_conversations}
 */
export function useRunEvalOnReferenceConversations(
  options: {
    mutation?: UseMutationOptions<
      RunEvalOnReferenceConversationsMutationResponse,
      RunEvalOnReferenceConversations422,
      {
        accountName: RunEvalOnReferenceConversationsPathParams['account_name']
        starSchemaDataSourceId: RunEvalOnReferenceConversationsPathParams['star_schema_data_source_id']
        data: RunEvalOnReferenceConversationsMutationRequest
        params?: RunEvalOnReferenceConversationsQueryParams
      }
    >
    client?: Partial<RequestConfig<RunEvalOnReferenceConversationsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? runEvalOnReferenceConversationsMutationKey()

  return useMutation<
    RunEvalOnReferenceConversationsMutationResponse,
    RunEvalOnReferenceConversations422,
    {
      accountName: RunEvalOnReferenceConversationsPathParams['account_name']
      starSchemaDataSourceId: RunEvalOnReferenceConversationsPathParams['star_schema_data_source_id']
      data: RunEvalOnReferenceConversationsMutationRequest
      params?: RunEvalOnReferenceConversationsQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data, params }) => {
      return runEvalOnReferenceConversations({ accountName, starSchemaDataSourceId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}