// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateRedshiftConnection.ts"
);
import.meta.hot.lastModified = "1737759761660.0188";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateRedshiftConnectionMutationRequest,
  UpdateRedshiftConnectionMutationResponse,
  UpdateRedshiftConnectionPathParams,
  UpdateRedshiftConnection422,
} from '../types/UpdateRedshiftConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateRedshiftConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}' }] as const

export type UpdateRedshiftConnectionMutationKey = ReturnType<typeof updateRedshiftConnectionMutationKey>

/**
 * @description Update Resfhit connection.Note that all fields for a valid Redshift connection are required.
 * @summary Update Redshift Connection
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id}
 */
async function updateRedshiftConnection(
  {
    accountName,
    connectionId,
    data,
  }: {
    accountName: UpdateRedshiftConnectionPathParams['account_name']
    connectionId: UpdateRedshiftConnectionPathParams['connection_id']
    data?: UpdateRedshiftConnectionMutationRequest
  },
  config: Partial<RequestConfig<UpdateRedshiftConnectionMutationRequest>> = {},
) {
  const res = await client<UpdateRedshiftConnectionMutationResponse, UpdateRedshiftConnection422, UpdateRedshiftConnectionMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/connections/redshift/${connectionId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update Resfhit connection.Note that all fields for a valid Redshift connection are required.
 * @summary Update Redshift Connection
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id}
 */
export function useUpdateRedshiftConnection(
  options: {
    mutation?: UseMutationOptions<
      UpdateRedshiftConnectionMutationResponse,
      UpdateRedshiftConnection422,
      {
        accountName: UpdateRedshiftConnectionPathParams['account_name']
        connectionId: UpdateRedshiftConnectionPathParams['connection_id']
        data?: UpdateRedshiftConnectionMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateRedshiftConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateRedshiftConnectionMutationKey()

  return useMutation<
    UpdateRedshiftConnectionMutationResponse,
    UpdateRedshiftConnection422,
    {
      accountName: UpdateRedshiftConnectionPathParams['account_name']
      connectionId: UpdateRedshiftConnectionPathParams['connection_id']
      data?: UpdateRedshiftConnectionMutationRequest
    }
  >({
    mutationFn: async ({ accountName, connectionId, data }) => {
      return updateRedshiftConnection({ accountName, connectionId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}