// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useEnrichSsds.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { EnrichSsdsMutationRequest, EnrichSsdsMutationResponse, EnrichSsdsPathParams, EnrichSsds422 } from '../types/EnrichSsds'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const enrichSsdsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/enrich_ssds' }] as const

export type EnrichSsdsMutationKey = ReturnType<typeof enrichSsdsMutationKey>

/**
 * @description Upload dms to an existing ssds.
 * @summary Enrich Ssds
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/enrich_ssds}
 */
async function enrichSsds(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: EnrichSsdsPathParams['account_name']
    starSchemaDataSourceId: EnrichSsdsPathParams['star_schema_data_source_id']
    data?: EnrichSsdsMutationRequest
  },
  config: Partial<RequestConfig<EnrichSsdsMutationRequest>> = {},
) {
  const res = await client<EnrichSsdsMutationResponse, EnrichSsds422, EnrichSsdsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/enrich_ssds`,
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...config.headers },
    ...config,
  })
  return res.data
}

/**
 * @description Upload dms to an existing ssds.
 * @summary Enrich Ssds
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/enrich_ssds}
 */
export function useEnrichSsds(
  options: {
    mutation?: UseMutationOptions<
      EnrichSsdsMutationResponse,
      EnrichSsds422,
      {
        accountName: EnrichSsdsPathParams['account_name']
        starSchemaDataSourceId: EnrichSsdsPathParams['star_schema_data_source_id']
        data?: EnrichSsdsMutationRequest
      }
    >
    client?: Partial<RequestConfig<EnrichSsdsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? enrichSsdsMutationKey()

  return useMutation<
    EnrichSsdsMutationResponse,
    EnrichSsds422,
    {
      accountName: EnrichSsdsPathParams['account_name']
      starSchemaDataSourceId: EnrichSsdsPathParams['star_schema_data_source_id']
      data?: EnrichSsdsMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return enrichSsds({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}