// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messageApiSendResponseSchema.ts"
);
import.meta.hot.lastModified = "1737935768612.742";
}
// REMIX HMR END

// @ts-nocheck
import { messageApiBatchResponseSchema } from './messageApiBatchResponseSchema'
import { messagePayloadTypeSchema } from './messagePayloadTypeSchema'
import { taskApiSchema } from './taskApiSchema'
import { userBasicApiResponseSchema } from './userBasicApiResponseSchema'
import { z } from 'zod'

/**
 * @description Response from sending a message to the API\n\nNote that it inherits from the ConversationAPICreateResponse. This response will only\ninclude the latest message from the user and any messages after that.
 */
export const messageApiSendResponseSchema = z
  .object({
    id: z.string(),
    creator: z.lazy(() => userBasicApiResponseSchema),
    can_edit: z.boolean(),
    accepted_payload_types: z.union([z.array(z.lazy(() => messagePayloadTypeSchema)), z.null()]),
    messages: z.array(z.lazy(() => messageApiBatchResponseSchema)),
    num_messages_total: z.number().int(),
    ai_responding: z.boolean(),
    task: z.union([z.lazy(() => taskApiSchema), z.null()]).optional(),
  })
  .describe(
    'Response from sending a message to the API\n\nNote that it inherits from the ConversationAPICreateResponse. This response will only\ninclude the latest message from the user and any messages after that.',
  )