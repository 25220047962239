// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/DisplayTypeV2.ts"
);
import.meta.hot.lastModified = "1737759758189.0369";
}
// REMIX HMR END

export enum DisplayTypeV2 {
  'unknown' = 'unknown',
  'text' = 'text',
  'numeric' = 'numeric',
  'float' = 'float',
  'date' = 'date',
  'datetime' = 'datetime',
  'boolean' = 'boolean',
  'month' = 'month',
  'week' = 'week',
  'quarter' = 'quarter',
  'day_of_week' = 'day_of_week',
  'year' = 'year',
  'year_quarter' = 'year_quarter',
  'year_month' = 'year_month',
  'year_week' = 'year_week',
  'year_month_day' = 'year_month_day',
  'country' = 'country',
  'country_code' = 'country_code',
  'continent' = 'continent',
  'continent_code' = 'continent_code',
  'region' = 'region',
  'region_code' = 'region_code',
  'lat_long' = 'lat_long',
  'zip_code' = 'zip_code',
  'id' = 'id',
  'url' = 'url',
  'email' = 'email',
  'phone' = 'phone',
  'count' = 'count',
  'percent' = 'percent',
  'feet' = 'feet',
  'meters' = 'meters',
  'square_feet' = 'square_feet',
  'square_meters' = 'square_meters',
  'currency_usd' = 'currency_usd',
  'currency_gbp' = 'currency_gbp',
  'currency_eur' = 'currency_eur',
  'currency_aud' = 'currency_aud',
  'currency_usd_per_square_feet' = 'currency_usd_per_square_feet',
  'currency_usd_per_square_meters' = 'currency_usd_per_square_meters',
}