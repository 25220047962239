// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteAllReports.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteAllReportsMutationResponse, DeleteAllReportsPathParams, DeleteAllReportsQueryParams, DeleteAllReports422 } from '../types/DeleteAllReports'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteAllReportsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/delete_all_reports' }] as const

export type DeleteAllReportsMutationKey = ReturnType<typeof deleteAllReportsMutationKey>

/**
 * @description Delete all reports from the index.Args:    db: Database session to perform the operation.    user_session: User session to perform the operation.    account: Account to perform the operation.    type: Type of the reports to delete. If not provided, all reports are deleted.
 * @summary Delete All Reports
 * {@link /api/v1/accounts/:account_name/delete_all_reports}
 */
async function deleteAllReports(
  { accountName, params }: { accountName: DeleteAllReportsPathParams['account_name']; params?: DeleteAllReportsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteAllReportsMutationResponse, DeleteAllReports422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/delete_all_reports`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Delete all reports from the index.Args:    db: Database session to perform the operation.    user_session: User session to perform the operation.    account: Account to perform the operation.    type: Type of the reports to delete. If not provided, all reports are deleted.
 * @summary Delete All Reports
 * {@link /api/v1/accounts/:account_name/delete_all_reports}
 */
export function useDeleteAllReports(
  options: {
    mutation?: UseMutationOptions<
      DeleteAllReportsMutationResponse,
      DeleteAllReports422,
      { accountName: DeleteAllReportsPathParams['account_name']; params?: DeleteAllReportsQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteAllReportsMutationKey()

  return useMutation<
    DeleteAllReportsMutationResponse,
    DeleteAllReports422,
    { accountName: DeleteAllReportsPathParams['account_name']; params?: DeleteAllReportsQueryParams }
  >({
    mutationFn: async ({ accountName, params }) => {
      return deleteAllReports({ accountName, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}