// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readAllDatasetsSchema.ts"
);
import.meta.hot.lastModified = "1737759760003.0273";
}
// REMIX HMR END

// @ts-nocheck
import { datasetsResponseSchema } from './datasetsResponseSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceSortSchema } from './starSchemaDataSourceSortSchema'
import { z } from 'zod'

export const readAllDatasetsPathParamsSchema = z.object({
  account_name: z.string(),
})

export const readAllDatasetsQueryParamsSchema = z
  .object({
    sort_ascending: z.boolean().default(false),
    sort_by: z.union([z.lazy(() => starSchemaDataSourceSortSchema), z.null()]).optional(),
    limit: z.union([z.number().int(), z.null()]).optional(),
    offset: z.union([z.number().int(), z.null()]).optional(),
    search: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readAllDatasets200Schema = z.lazy(() => datasetsResponseSchema)

/**
 * @description Validation Error
 */
export const readAllDatasets422Schema = z.lazy(() => httpValidationErrorSchema)

export const readAllDatasetsQueryResponseSchema = z.lazy(() => readAllDatasets200Schema)