// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/env.ts"
);
import.meta.hot.lastModified = "1733386248013.5847";
}
// REMIX HMR END

import { useRouteLoaderData } from '@remix-run/react'

import { type Env } from '@/types'

import { type loader } from '~/root'

export function useEnv(): Env | null {
  const data = useRouteLoaderData<typeof loader>('root')
  return data?.env ?? null
}
