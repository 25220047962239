// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadAccountsByCurrentUser.ts"
);
import.meta.hot.lastModified = "1737759761859.0178";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadAccountsByCurrentUserQueryResponse, ReadAccountsByCurrentUser422 } from '../types/ReadAccountsByCurrentUser'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readAccountsByCurrentUserQueryKey = () => [{ url: '/api/v1/users/accounts' }] as const

export type ReadAccountsByCurrentUserQueryKey = ReturnType<typeof readAccountsByCurrentUserQueryKey>

/**
 * @description Get accounts by user.
 * @summary Read Accounts By Current User
 * {@link /api/v1/users/accounts}
 */
async function readAccountsByCurrentUser(config: Partial<RequestConfig> = {}) {
  const res = await client<ReadAccountsByCurrentUserQueryResponse, ReadAccountsByCurrentUser422, unknown>({
    method: 'GET',
    url: `/api/v1/users/accounts`,
    ...config,
  })
  return res.data
}

export function readAccountsByCurrentUserQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = readAccountsByCurrentUserQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readAccountsByCurrentUser(config)
    },
  })
}

/**
 * @description Get accounts by user.
 * @summary Read Accounts By Current User
 * {@link /api/v1/users/accounts}
 */
export function useReadAccountsByCurrentUser<
  TData = ReadAccountsByCurrentUserQueryResponse,
  TQueryData = ReadAccountsByCurrentUserQueryResponse,
  TQueryKey extends QueryKey = ReadAccountsByCurrentUserQueryKey,
>(
  options: {
    query?: Partial<QueryObserverOptions<ReadAccountsByCurrentUserQueryResponse, ReadAccountsByCurrentUser422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readAccountsByCurrentUserQueryKey()

  const query = useQuery({
    ...(readAccountsByCurrentUserQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadAccountsByCurrentUser422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}