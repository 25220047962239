// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadUserMe.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadUserMeQueryResponse, ReadUserMe422 } from '../types/ReadUserMe'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readUserMeQueryKey = () => [{ url: '/api/v1/users/me' }] as const

export type ReadUserMeQueryKey = ReturnType<typeof readUserMeQueryKey>

/**
 * @description Get current user.
 * @summary Read User Me
 * {@link /api/v1/users/me}
 */
async function readUserMe(config: Partial<RequestConfig> = {}) {
  const res = await client<ReadUserMeQueryResponse, ReadUserMe422, unknown>({ method: 'GET', url: `/api/v1/users/me`, ...config })
  return res.data
}

export function readUserMeQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = readUserMeQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readUserMe(config)
    },
  })
}

/**
 * @description Get current user.
 * @summary Read User Me
 * {@link /api/v1/users/me}
 */
export function useReadUserMe<TData = ReadUserMeQueryResponse, TQueryData = ReadUserMeQueryResponse, TQueryKey extends QueryKey = ReadUserMeQueryKey>(
  options: {
    query?: Partial<QueryObserverOptions<ReadUserMeQueryResponse, ReadUserMe422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readUserMeQueryKey()

  const query = useQuery({
    ...(readUserMeQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadUserMe422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}