// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getEnterpriseAccountsSchema.ts"
);
import.meta.hot.lastModified = "1737935768446.743";
}
// REMIX HMR END

// @ts-nocheck
import { accountSchema } from './accountSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getEnterpriseAccountsPathParamsSchema = z.object({
  enterprise_name: z.string(),
})

export const getEnterpriseAccountsQueryParamsSchema = z
  .object({
    skip: z.number().int().default(0),
    limit: z.number().int().default(100),
    sort_by: z.enum(['name', 'created_at', 'updated_at']).default('name'),
    sort_ascending: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getEnterpriseAccounts200Schema = z.array(z.lazy(() => accountSchema))

/**
 * @description Validation Error
 */
export const getEnterpriseAccounts422Schema = z.lazy(() => httpValidationErrorSchema)

export const getEnterpriseAccountsQueryResponseSchema = z.lazy(() => getEnterpriseAccounts200Schema)