// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readFullSnowflakeConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935769345.738";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { snowflakeConnectionWithSecretsSchema } from './snowflakeConnectionWithSecretsSchema'
import { z } from 'zod'

export const readFullSnowflakeConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  snowflake_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const readFullSnowflakeConnection200Schema = z.lazy(() => snowflakeConnectionWithSecretsSchema)

/**
 * @description Validation Error
 */
export const readFullSnowflakeConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const readFullSnowflakeConnectionQueryResponseSchema = z.lazy(() => readFullSnowflakeConnection200Schema)