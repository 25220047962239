// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/UtilsService.ts"
);
import.meta.hot.lastModified = "1737935753813.8232";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Msg } from '../models/Msg';
import type { Task } from '../models/Task';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UtilsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Test Celery Response
   * Test Celery worker.
   * @param accountName
   * @param requestBody
   * @returns Task Successful Response
   * @throws ApiError
   */
  public testCeleryResponse(
    accountName: string,
    requestBody: Msg,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/utils/test-celery/',
      query: {
        'account_name': accountName,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
