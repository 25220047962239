// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDimensionName.ts"
);
import.meta.hot.lastModified = "1737759760701.024";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateDimensionNameMutationResponse,
  UpdateDimensionNamePathParams,
  UpdateDimensionNameQueryParams,
  UpdateDimensionName422,
} from '../types/UpdateDimensionName'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDimensionNameMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/name' }] as const

export type UpdateDimensionNameMutationKey = ReturnType<typeof updateDimensionNameMutationKey>

/**
 * @description Update a dimension's name.
 * @summary Update Dimension Name
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/name}
 */
async function updateDimensionName(
  {
    accountName,
    dimensionId,
    params,
  }: {
    accountName: UpdateDimensionNamePathParams['account_name']
    dimensionId: UpdateDimensionNamePathParams['dimension_id']
    params: UpdateDimensionNameQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateDimensionNameMutationResponse, UpdateDimensionName422, unknown>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions/${dimensionId}/name`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Update a dimension's name.
 * @summary Update Dimension Name
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/name}
 */
export function useUpdateDimensionName(
  options: {
    mutation?: UseMutationOptions<
      UpdateDimensionNameMutationResponse,
      UpdateDimensionName422,
      {
        accountName: UpdateDimensionNamePathParams['account_name']
        dimensionId: UpdateDimensionNamePathParams['dimension_id']
        params: UpdateDimensionNameQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDimensionNameMutationKey()

  return useMutation<
    UpdateDimensionNameMutationResponse,
    UpdateDimensionName422,
    {
      accountName: UpdateDimensionNamePathParams['account_name']
      dimensionId: UpdateDimensionNamePathParams['dimension_id']
      params: UpdateDimensionNameQueryParams
    }
  >({
    mutationFn: async ({ accountName, dimensionId, params }) => {
      return updateDimensionName({ accountName, dimensionId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}