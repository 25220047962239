// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDefaultSetting.ts"
);
import.meta.hot.lastModified = "1737935768071.745";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateDefaultSettingMutationRequest,
  UpdateDefaultSettingMutationResponse,
  UpdateDefaultSettingPathParams,
  UpdateDefaultSetting422,
} from '../types/UpdateDefaultSetting'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDefaultSettingMutationKey = () => [{ url: '/api/v1/admin/default-settings/{setting_name}' }] as const

export type UpdateDefaultSettingMutationKey = ReturnType<typeof updateDefaultSettingMutationKey>

/**
 * @description Admin endpoint to update a default setting.
 * @summary Update Default Setting
 * {@link /api/v1/admin/default-settings/:setting_name}
 */
async function updateDefaultSetting(
  { settingName, data }: { settingName: UpdateDefaultSettingPathParams['setting_name']; data?: UpdateDefaultSettingMutationRequest },
  config: Partial<RequestConfig<UpdateDefaultSettingMutationRequest>> = {},
) {
  const res = await client<UpdateDefaultSettingMutationResponse, UpdateDefaultSetting422, UpdateDefaultSettingMutationRequest>({
    method: 'PUT',
    url: `/api/v1/admin/default-settings/${settingName}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Admin endpoint to update a default setting.
 * @summary Update Default Setting
 * {@link /api/v1/admin/default-settings/:setting_name}
 */
export function useUpdateDefaultSetting(
  options: {
    mutation?: UseMutationOptions<
      UpdateDefaultSettingMutationResponse,
      UpdateDefaultSetting422,
      { settingName: UpdateDefaultSettingPathParams['setting_name']; data?: UpdateDefaultSettingMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateDefaultSettingMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDefaultSettingMutationKey()

  return useMutation<
    UpdateDefaultSettingMutationResponse,
    UpdateDefaultSetting422,
    { settingName: UpdateDefaultSettingPathParams['setting_name']; data?: UpdateDefaultSettingMutationRequest }
  >({
    mutationFn: async ({ settingName, data }) => {
      return updateDefaultSetting({ settingName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}