// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatAgentMessageInDbSchema.ts"
);
import.meta.hot.lastModified = "1737759761027.0222";
}
// REMIX HMR END

// @ts-nocheck
import { chatMessageTypeSchema } from './chatMessageTypeSchema'
import { completionUsageSchema } from './completionUsageSchema'
import { errorContentSchema } from './errorContentSchema'
import { userBasicSchema } from './userBasicSchema'
import { z } from 'zod'

/**
 * @description AgentMessage in database.
 */
export const chatAgentMessageInDbSchema = z
  .object({
    id: z.string(),
    sync_id: z.string().optional(),
    content: z.string(),
    sending_agent: z.string(),
    message_type: z.lazy(() => chatMessageTypeSchema),
    schema_name: z.string(),
    schema_hash: z.union([z.string(), z.null()]).optional(),
    chat_id: z.union([z.string(), z.null()]),
    is_positive_feedback: z.union([z.boolean(), z.null()]).optional(),
    is_positive_admin_feedback: z.union([z.boolean(), z.null()]).optional(),
    feedback_chat_id: z.union([z.string(), z.null()]).optional(),
    is_last_user_message_for_feedback_chat: z.boolean().default(false),
    is_admin_feedback_incorporated: z.boolean().default(false),
    error: z.union([z.lazy(() => errorContentSchema), z.null()]).optional(),
    usage: z.union([z.lazy(() => completionUsageSchema), z.null()]).optional(),
    timestamp: z.string().datetime().optional(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    creator: z.union([z.lazy(() => userBasicSchema), z.null()]).optional(),
  })
  .describe('AgentMessage in database.')