// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateAccount.ts"
);
import.meta.hot.lastModified = "1737759758962.0327";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateAccountMutationRequest, CreateAccountMutationResponse, CreateAccountQueryParams, CreateAccount422 } from '../types/CreateAccount'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createAccountMutationKey = () => [{ url: '/api/v1/accounts/' }] as const

export type CreateAccountMutationKey = ReturnType<typeof createAccountMutationKey>

/**
 * @description Create new account.
 * @summary Create Account
 * {@link /api/v1/accounts/}
 */
async function createAccount(
  { data, params }: { data: CreateAccountMutationRequest; params?: CreateAccountQueryParams },
  config: Partial<RequestConfig<CreateAccountMutationRequest>> = {},
) {
  const res = await client<CreateAccountMutationResponse, CreateAccount422, CreateAccountMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create new account.
 * @summary Create Account
 * {@link /api/v1/accounts/}
 */
export function useCreateAccount(
  options: {
    mutation?: UseMutationOptions<CreateAccountMutationResponse, CreateAccount422, { data: CreateAccountMutationRequest; params?: CreateAccountQueryParams }>
    client?: Partial<RequestConfig<CreateAccountMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createAccountMutationKey()

  return useMutation<CreateAccountMutationResponse, CreateAccount422, { data: CreateAccountMutationRequest; params?: CreateAccountQueryParams }>({
    mutationFn: async ({ data, params }) => {
      return createAccount({ data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}