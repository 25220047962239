// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ReportType.ts"
);
import.meta.hot.lastModified = "1737935764859.7627";
}
// REMIX HMR END

export enum ReportType {
  'tableau' = 'tableau',
  'mode' = 'mode',
}