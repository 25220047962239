// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetCopyJob.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetCopyJobQueryResponse, GetCopyJobPathParams, GetCopyJob422 } from '../types/GetCopyJob'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCopyJobQueryKey = ({
  enterpriseName,
  jobId,
}: {
  enterpriseName: GetCopyJobPathParams['enterprise_name']
  jobId: GetCopyJobPathParams['job_id']
}) => [{ url: '/api/v1/enterprises/:enterprise_name/dataset-jobs/copy/:job_id', params: { enterpriseName: enterpriseName, jobId: jobId } }] as const

export type GetCopyJobQueryKey = ReturnType<typeof getCopyJobQueryKey>

/**
 * @description Get a copy job.
 * @summary Get Copy Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/copy/:job_id}
 */
async function getCopyJob(
  { enterpriseName, jobId }: { enterpriseName: GetCopyJobPathParams['enterprise_name']; jobId: GetCopyJobPathParams['job_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetCopyJobQueryResponse, GetCopyJob422, unknown>({
    method: 'GET',
    url: `/api/v1/enterprises/${enterpriseName}/dataset-jobs/copy/${jobId}`,
    ...config,
  })
  return res.data
}

export function getCopyJobQueryOptions(
  { enterpriseName, jobId }: { enterpriseName: GetCopyJobPathParams['enterprise_name']; jobId: GetCopyJobPathParams['job_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getCopyJobQueryKey({ enterpriseName, jobId })
  return queryOptions({
    enabled: !!(enterpriseName && jobId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCopyJob({ enterpriseName, jobId }, config)
    },
  })
}

/**
 * @description Get a copy job.
 * @summary Get Copy Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/copy/:job_id}
 */
export function useGetCopyJob<TData = GetCopyJobQueryResponse, TQueryData = GetCopyJobQueryResponse, TQueryKey extends QueryKey = GetCopyJobQueryKey>(
  { enterpriseName, jobId }: { enterpriseName: GetCopyJobPathParams['enterprise_name']; jobId: GetCopyJobPathParams['job_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetCopyJobQueryResponse, GetCopyJob422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCopyJobQueryKey({ enterpriseName, jobId })

  const query = useQuery({
    ...(getCopyJobQueryOptions({ enterpriseName, jobId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetCopyJob422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}