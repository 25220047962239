// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetRelevantConversationsCellsByMetric.ts"
);
import.meta.hot.lastModified = "1737759762549.0142";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetRelevantConversationsCellsByMetricQueryResponse,
  GetRelevantConversationsCellsByMetricPathParams,
  GetRelevantConversationsCellsByMetricQueryParams,
  GetRelevantConversationsCellsByMetric422,
} from '../types/GetRelevantConversationsCellsByMetric'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getRelevantConversationsCellsByMetricQueryKey = (
  {
    accountName,
    metricId,
  }: { accountName: GetRelevantConversationsCellsByMetricPathParams['account_name']; metricId: GetRelevantConversationsCellsByMetricPathParams['metric_id'] },
  params?: GetRelevantConversationsCellsByMetricQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/relevant_conversations_cells',
      params: { accountName: accountName, metricId: metricId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetRelevantConversationsCellsByMetricQueryKey = ReturnType<typeof getRelevantConversationsCellsByMetricQueryKey>

/**
 * @description Get conversations and cells that use the given metric.
 * @summary Get Relevant Conversations Cells By Metric
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/relevant_conversations_cells}
 */
async function getRelevantConversationsCellsByMetric(
  {
    accountName,
    metricId,
    params,
  }: {
    accountName: GetRelevantConversationsCellsByMetricPathParams['account_name']
    metricId: GetRelevantConversationsCellsByMetricPathParams['metric_id']
    params?: GetRelevantConversationsCellsByMetricQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetRelevantConversationsCellsByMetricQueryResponse, GetRelevantConversationsCellsByMetric422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/${metricId}/relevant_conversations_cells`,
    params,
    ...config,
  })
  return res.data
}

export function getRelevantConversationsCellsByMetricQueryOptions(
  {
    accountName,
    metricId,
    params,
  }: {
    accountName: GetRelevantConversationsCellsByMetricPathParams['account_name']
    metricId: GetRelevantConversationsCellsByMetricPathParams['metric_id']
    params?: GetRelevantConversationsCellsByMetricQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getRelevantConversationsCellsByMetricQueryKey({ accountName, metricId }, params)
  return queryOptions({
    enabled: !!(accountName && metricId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getRelevantConversationsCellsByMetric({ accountName, metricId, params }, config)
    },
  })
}

/**
 * @description Get conversations and cells that use the given metric.
 * @summary Get Relevant Conversations Cells By Metric
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/relevant_conversations_cells}
 */
export function useGetRelevantConversationsCellsByMetric<
  TData = GetRelevantConversationsCellsByMetricQueryResponse,
  TQueryData = GetRelevantConversationsCellsByMetricQueryResponse,
  TQueryKey extends QueryKey = GetRelevantConversationsCellsByMetricQueryKey,
>(
  {
    accountName,
    metricId,
    params,
  }: {
    accountName: GetRelevantConversationsCellsByMetricPathParams['account_name']
    metricId: GetRelevantConversationsCellsByMetricPathParams['metric_id']
    params?: GetRelevantConversationsCellsByMetricQueryParams
  },
  options: {
    query?: Partial<
      QueryObserverOptions<GetRelevantConversationsCellsByMetricQueryResponse, GetRelevantConversationsCellsByMetric422, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getRelevantConversationsCellsByMetricQueryKey({ accountName, metricId }, params)

  const query = useQuery({
    ...(getRelevantConversationsCellsByMetricQueryOptions({ accountName, metricId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetRelevantConversationsCellsByMetric422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}