// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetAccountSsdsNamedFilters.ts"
);
import.meta.hot.lastModified = "1737759762053.0168";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetAccountSsdsNamedFiltersQueryResponse,
  GetAccountSsdsNamedFiltersPathParams,
  GetAccountSsdsNamedFiltersQueryParams,
  GetAccountSsdsNamedFilters422,
} from '../types/GetAccountSsdsNamedFilters'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAccountSsdsNamedFiltersQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetAccountSsdsNamedFiltersPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsNamedFiltersPathParams['star_schema_data_source_id']
  },
  params?: GetAccountSsdsNamedFiltersQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetAccountSsdsNamedFiltersQueryKey = ReturnType<typeof getAccountSsdsNamedFiltersQueryKey>

/**
 * @description Get Named Filters from the given account and ssds.If DataSourceIds are specified, then only metrics that referencethe specified Data Sources are returned.
 * @summary Get Account Ssds Named Filters
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters}
 */
async function getAccountSsdsNamedFilters(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetAccountSsdsNamedFiltersPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsNamedFiltersPathParams['star_schema_data_source_id']
    params?: GetAccountSsdsNamedFiltersQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetAccountSsdsNamedFiltersQueryResponse, GetAccountSsdsNamedFilters422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/filters`,
    params,
    ...config,
  })
  return res.data
}

export function getAccountSsdsNamedFiltersQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetAccountSsdsNamedFiltersPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsNamedFiltersPathParams['star_schema_data_source_id']
    params?: GetAccountSsdsNamedFiltersQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getAccountSsdsNamedFiltersQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAccountSsdsNamedFilters({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get Named Filters from the given account and ssds.If DataSourceIds are specified, then only metrics that referencethe specified Data Sources are returned.
 * @summary Get Account Ssds Named Filters
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters}
 */
export function useGetAccountSsdsNamedFilters<
  TData = GetAccountSsdsNamedFiltersQueryResponse,
  TQueryData = GetAccountSsdsNamedFiltersQueryResponse,
  TQueryKey extends QueryKey = GetAccountSsdsNamedFiltersQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetAccountSsdsNamedFiltersPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsNamedFiltersPathParams['star_schema_data_source_id']
    params?: GetAccountSsdsNamedFiltersQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetAccountSsdsNamedFiltersQueryResponse, GetAccountSsdsNamedFilters422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAccountSsdsNamedFiltersQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getAccountSsdsNamedFiltersQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetAccountSsdsNamedFilters422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}