// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/taskStatusV2Schema.ts"
);
import.meta.hot.lastModified = "1737759758958.0327";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Enumerates the possible task statuses.\n\nAttributes:\n    PROGRESS: The task started and is in progress.\n    SUCCESS: The task completed successfully.\n    FAILURE: The task failed.\n    PENDING: The task is waiting to start.\n    CANCELLED: The task was cancelled.
 */
export const taskStatusV2Schema = z
  .enum(['PROGRESS', 'SUCCESS', 'FAILURE', 'PENDING', 'CANCELLED'])
  .describe(
    'Enumerates the possible task statuses.\n\nAttributes:\n    PROGRESS: The task started and is in progress.\n    SUCCESS: The task completed successfully.\n    FAILURE: The task failed.\n    PENDING: The task is waiting to start.\n    CANCELLED: The task was cancelled.',
  )