// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dataAssetApiSchema.ts"
);
import.meta.hot.lastModified = "1737759758722.034";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetConnectionTypeApiSchema } from './dataAssetConnectionTypeApiSchema'
import { dataAssetTypeApiSchema } from './dataAssetTypeApiSchema'
import { z } from 'zod'

/**
 * @description A data asset within the application.\n\nA data asset is a referenceable entity that can contain data. This might be a\ndataset (also sometimes referred to as a Star Schema Data Source) or a report.
 */
export const dataAssetApiSchema = z
  .object({
    id: z.string().describe('The UUID for the data asset.'),
    name: z.string().describe('The display name of the data asset.'),
    description: z.union([z.string(), z.null()]).describe('The description of the data asset.'),
    asset_type: z.lazy(() => dataAssetTypeApiSchema),
    connection_type: z.lazy(() => dataAssetConnectionTypeApiSchema),
  })
  .describe(
    'A data asset within the application.\n\nA data asset is a referenceable entity that can contain data. This might be a\ndataset (also sometimes referred to as a Star Schema Data Source) or a report.',
  )