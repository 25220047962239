// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadUsers.ts"
);
import.meta.hot.lastModified = "1737935765108.7612";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadUsersQueryResponse, ReadUsersQueryParams, ReadUsers422 } from '../types/ReadUsers'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readUsersQueryKey = (params?: ReadUsersQueryParams) => [{ url: '/api/v1/users/' }, ...(params ? [params] : [])] as const

export type ReadUsersQueryKey = ReturnType<typeof readUsersQueryKey>

/**
 * @description Retrieve users.
 * @summary Read Users
 * {@link /api/v1/users/}
 */
async function readUsers({ params }: { params?: ReadUsersQueryParams }, config: Partial<RequestConfig> = {}) {
  const res = await client<ReadUsersQueryResponse, ReadUsers422, unknown>({ method: 'GET', url: `/api/v1/users/`, params, ...config })
  return res.data
}

export function readUsersQueryOptions({ params }: { params?: ReadUsersQueryParams }, config: Partial<RequestConfig> = {}) {
  const queryKey = readUsersQueryKey(params)
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readUsers({ params }, config)
    },
  })
}

/**
 * @description Retrieve users.
 * @summary Read Users
 * {@link /api/v1/users/}
 */
export function useReadUsers<TData = ReadUsersQueryResponse, TQueryData = ReadUsersQueryResponse, TQueryKey extends QueryKey = ReadUsersQueryKey>(
  { params }: { params?: ReadUsersQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadUsersQueryResponse, ReadUsers422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readUsersQueryKey(params)

  const query = useQuery({
    ...(readUsersQueryOptions({ params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadUsers422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}