// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDmPopularity.ts"
);
import.meta.hot.lastModified = "1737935767656.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateDmPopularityMutationResponse,
  UpdateDmPopularityPathParams,
  UpdateDmPopularityQueryParams,
  UpdateDmPopularity422,
} from '../types/UpdateDmPopularity'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDmPopularityMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/refresh_dm_popularity' }] as const

export type UpdateDmPopularityMutationKey = ReturnType<typeof updateDmPopularityMutationKey>

/**
 * @description Refresh Dimension and Metric Popularity scores from verified questions.
 * @summary Update Dm Popularity
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/refresh_dm_popularity}
 */
async function updateDmPopularity(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: UpdateDmPopularityPathParams['account_name']
    starSchemaDataSourceId: UpdateDmPopularityPathParams['star_schema_data_source_id']
    params?: UpdateDmPopularityQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateDmPopularityMutationResponse, UpdateDmPopularity422, unknown>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/refresh_dm_popularity`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Refresh Dimension and Metric Popularity scores from verified questions.
 * @summary Update Dm Popularity
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/refresh_dm_popularity}
 */
export function useUpdateDmPopularity(
  options: {
    mutation?: UseMutationOptions<
      UpdateDmPopularityMutationResponse,
      UpdateDmPopularity422,
      {
        accountName: UpdateDmPopularityPathParams['account_name']
        starSchemaDataSourceId: UpdateDmPopularityPathParams['star_schema_data_source_id']
        params?: UpdateDmPopularityQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDmPopularityMutationKey()

  return useMutation<
    UpdateDmPopularityMutationResponse,
    UpdateDmPopularity422,
    {
      accountName: UpdateDmPopularityPathParams['account_name']
      starSchemaDataSourceId: UpdateDmPopularityPathParams['star_schema_data_source_id']
      params?: UpdateDmPopularityQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params }) => {
      return updateDmPopularity({ accountName, starSchemaDataSourceId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}