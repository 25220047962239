// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/starSchemaDataSourceColdStartSchema.ts"
);
import.meta.hot.lastModified = "1737759762337.0154";
}
// REMIX HMR END

// @ts-nocheck
import { publishStateSchema } from './publishStateSchema'
import { starSchemaDataSourceEnrichmentSchema } from './starSchemaDataSourceEnrichmentSchema'
import { starSchemaDataSourceTableOrViewInfoSchema } from './starSchemaDataSourceTableOrViewInfoSchema'
import { z } from 'zod'

/**
 * @description Cold start SSDS API parameters.
 */
export const starSchemaDataSourceColdStartSchema = z
  .object({
    ssds_name: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    tables_and_views: z.array(z.lazy(() => starSchemaDataSourceTableOrViewInfoSchema)),
    update_existing_dimensions_metrics: z.union([z.boolean(), z.null()]).optional(),
    update_existing_state: z.boolean().default(false),
    update_identifiers: z.boolean().default(false),
    skip_metric_check: z.boolean().default(false),
    include_metrics: z.union([z.boolean(), z.null()]).optional(),
    initial_dimension_metric_state: z.union([z.lazy(() => publishStateSchema), z.null()]).optional(),
    ssds_enrich_params: z.lazy(() => starSchemaDataSourceEnrichmentSchema).default({}),
  })
  .describe('Cold start SSDS API parameters.')