// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/PostgresService.ts"
);
import.meta.hot.lastModified = "1737935753840.8232";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostgresConnection } from '../models/PostgresConnection';
import type { PostgresConnectionCreate } from '../models/PostgresConnectionCreate';
import type { PostgresConnectionUpdate } from '../models/PostgresConnectionUpdate';
import type { TableColumn } from '../models/TableColumn';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PostgresService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Postgres Connection
   * Create new Postgres connection.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns PostgresConnection Successful Response
   * @throws ApiError
   */
  public createPostgresConnection(
    accountName: string,
    requestBody: PostgresConnectionCreate,
    sessionId?: (string | null),
  ): CancelablePromise<PostgresConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/postgres/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Postgres Connection
   * Read a Postgres connection.
   * @param accountName
   * @param postgresConnectionId
   * @param sessionId
   * @returns PostgresConnection Successful Response
   * @throws ApiError
   */
  public readPostgresConnection(
    accountName: string,
    postgresConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<PostgresConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}',
      path: {
        'account_name': accountName,
        'postgres_connection_id': postgresConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Postgres Connection
   * Update Postgres connection.
   * @param accountName
   * @param postgresConnectionId
   * @param requestBody
   * @param sessionId
   * @returns PostgresConnection Successful Response
   * @throws ApiError
   */
  public updatePostgresConnection(
    accountName: string,
    postgresConnectionId: string,
    requestBody: PostgresConnectionUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<PostgresConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}',
      path: {
        'account_name': accountName,
        'postgres_connection_id': postgresConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Postgres Connection
   * Delete Postgres connection.
   * @param accountName
   * @param postgresConnectionId
   * @param sessionId
   * @returns PostgresConnection Successful Response
   * @throws ApiError
   */
  public deletePostgresConnection(
    accountName: string,
    postgresConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<PostgresConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}',
      path: {
        'account_name': accountName,
        'postgres_connection_id': postgresConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Postgres Connection Schemas
   * Read Postgres schemas.
   * @param accountName
   * @param postgresConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readPostgresConnectionSchemas(
    accountName: string,
    postgresConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}/schemas',
      path: {
        'account_name': accountName,
        'postgres_connection_id': postgresConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Postgres Connection Tables
   * Read Postgres tables.
   *
   * This endpoint returns a dictionary of tables for each schema in the database.
   *
   * Args:
   * db: SQLAlchemy session.
   * current_user: The current user.
   * account: The current account.
   * connection: The Postgres connection.
   * schema: Optional schema to filter tables by.
   * @param accountName
   * @param postgresConnectionId
   * @param schema
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readPostgresConnectionTables(
    accountName: string,
    postgresConnectionId: string,
    schema?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}/tables',
      path: {
        'account_name': accountName,
        'postgres_connection_id': postgresConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'schema': schema,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Postgres Connection Columns
   * Read Postgres columns.
   * @param accountName
   * @param postgresConnectionId
   * @param schema
   * @param table
   * @param sessionId
   * @returns TableColumn Successful Response
   * @throws ApiError
   */
  public readPostgresConnectionColumns(
    accountName: string,
    postgresConnectionId: string,
    schema: string,
    table: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<TableColumn>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}/columns',
      path: {
        'account_name': accountName,
        'postgres_connection_id': postgresConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'schema': schema,
        'table': table,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
