// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateCellSemanticlayerview.ts"
);
import.meta.hot.lastModified = "1737935769212.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateCellSemanticlayerviewMutationRequest,
  UpdateCellSemanticlayerviewMutationResponse,
  UpdateCellSemanticlayerviewPathParams,
  UpdateCellSemanticlayerviewQueryParams,
  UpdateCellSemanticlayerview422,
} from '../types/UpdateCellSemanticlayerview'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateCellSemanticlayerviewMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/semanticlayerview' }] as const

export type UpdateCellSemanticlayerviewMutationKey = ReturnType<typeof updateCellSemanticlayerviewMutationKey>

/**
 * @description Update the semantic layer view of a cell.The `name` field of each Dimension or Metric in the `order` parameter should be prefixed    with '-' if the sort order is descending.Args:    skip_cell_refresh: instead of issuing a cell refresh, delete the underlying cell table and don't issue a new SQL query.    remove_feedback: remove any existing cell feedback when we update the cell. This defaults to false, but should be set to true for user edits.Raises:    NSRuntimeError if the semantic layer view is invalid.    NSUnauthorizedError if the cell is verified and the user is not an admin.
 * @summary Update Cell Semanticlayerview
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/semanticlayerview}
 */
async function updateCellSemanticlayerview(
  {
    accountName,
    conversationId,
    cellId,
    data,
    params,
  }: {
    accountName: UpdateCellSemanticlayerviewPathParams['account_name']
    conversationId: UpdateCellSemanticlayerviewPathParams['conversation_id']
    cellId: UpdateCellSemanticlayerviewPathParams['cell_id']
    data: UpdateCellSemanticlayerviewMutationRequest
    params?: UpdateCellSemanticlayerviewQueryParams
  },
  config: Partial<RequestConfig<UpdateCellSemanticlayerviewMutationRequest>> = {},
) {
  const res = await client<UpdateCellSemanticlayerviewMutationResponse, UpdateCellSemanticlayerview422, UpdateCellSemanticlayerviewMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/semanticlayerview`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update the semantic layer view of a cell.The `name` field of each Dimension or Metric in the `order` parameter should be prefixed    with '-' if the sort order is descending.Args:    skip_cell_refresh: instead of issuing a cell refresh, delete the underlying cell table and don't issue a new SQL query.    remove_feedback: remove any existing cell feedback when we update the cell. This defaults to false, but should be set to true for user edits.Raises:    NSRuntimeError if the semantic layer view is invalid.    NSUnauthorizedError if the cell is verified and the user is not an admin.
 * @summary Update Cell Semanticlayerview
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/semanticlayerview}
 */
export function useUpdateCellSemanticlayerview(
  options: {
    mutation?: UseMutationOptions<
      UpdateCellSemanticlayerviewMutationResponse,
      UpdateCellSemanticlayerview422,
      {
        accountName: UpdateCellSemanticlayerviewPathParams['account_name']
        conversationId: UpdateCellSemanticlayerviewPathParams['conversation_id']
        cellId: UpdateCellSemanticlayerviewPathParams['cell_id']
        data: UpdateCellSemanticlayerviewMutationRequest
        params?: UpdateCellSemanticlayerviewQueryParams
      }
    >
    client?: Partial<RequestConfig<UpdateCellSemanticlayerviewMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateCellSemanticlayerviewMutationKey()

  return useMutation<
    UpdateCellSemanticlayerviewMutationResponse,
    UpdateCellSemanticlayerview422,
    {
      accountName: UpdateCellSemanticlayerviewPathParams['account_name']
      conversationId: UpdateCellSemanticlayerviewPathParams['conversation_id']
      cellId: UpdateCellSemanticlayerviewPathParams['cell_id']
      data: UpdateCellSemanticlayerviewMutationRequest
      params?: UpdateCellSemanticlayerviewQueryParams
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, data, params }) => {
      return updateCellSemanticlayerview({ accountName, conversationId, cellId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}