// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateTeradataConnection.ts"
);
import.meta.hot.lastModified = "1737935768806.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateTeradataConnectionMutationRequest,
  CreateTeradataConnectionMutationResponse,
  CreateTeradataConnectionPathParams,
  CreateTeradataConnection422,
} from '../types/CreateTeradataConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createTeradataConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/teradata/' }] as const

export type CreateTeradataConnectionMutationKey = ReturnType<typeof createTeradataConnectionMutationKey>

/**
 * @description Create new teradata connection.
 * @summary Create Teradata Connection
 * {@link /api/v1/accounts/:account_name/connections/teradata/}
 */
async function createTeradataConnection(
  { accountName, data }: { accountName: CreateTeradataConnectionPathParams['account_name']; data: CreateTeradataConnectionMutationRequest },
  config: Partial<RequestConfig<CreateTeradataConnectionMutationRequest>> = {},
) {
  const res = await client<CreateTeradataConnectionMutationResponse, CreateTeradataConnection422, CreateTeradataConnectionMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/teradata/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create new teradata connection.
 * @summary Create Teradata Connection
 * {@link /api/v1/accounts/:account_name/connections/teradata/}
 */
export function useCreateTeradataConnection(
  options: {
    mutation?: UseMutationOptions<
      CreateTeradataConnectionMutationResponse,
      CreateTeradataConnection422,
      { accountName: CreateTeradataConnectionPathParams['account_name']; data: CreateTeradataConnectionMutationRequest }
    >
    client?: Partial<RequestConfig<CreateTeradataConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createTeradataConnectionMutationKey()

  return useMutation<
    CreateTeradataConnectionMutationResponse,
    CreateTeradataConnection422,
    { accountName: CreateTeradataConnectionPathParams['account_name']; data: CreateTeradataConnectionMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return createTeradataConnection({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}