// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/completionUsageSchema.ts"
);
import.meta.hot.lastModified = "1737935766766.7522";
}
// REMIX HMR END

// @ts-nocheck
import { completionTokensDetailsSchema } from './completionTokensDetailsSchema'
import { promptTokensDetailsSchema } from './promptTokensDetailsSchema'
import { z } from 'zod'

export const completionUsageSchema = z
  .object({
    completion_tokens: z.number().int(),
    prompt_tokens: z.number().int(),
    total_tokens: z.number().int(),
    completion_tokens_details: z.union([z.lazy(() => completionTokensDetailsSchema), z.null()]).optional(),
    prompt_tokens_details: z.union([z.lazy(() => promptTokensDetailsSchema), z.null()]).optional(),
  })
  .catchall(z.unknown())