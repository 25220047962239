// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateStarSchemaDataSourceSchema.ts"
);
import.meta.hot.lastModified = "1737759762067.0168";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceColdStartSchema } from './starSchemaDataSourceColdStartSchema'
import { starSchemaDataSourceSchema } from './starSchemaDataSourceSchema'
import { z } from 'zod'

export const updateStarSchemaDataSourcePathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const updateStarSchemaDataSourceQueryParamsSchema = z
  .object({
    force_update: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const updateStarSchemaDataSource200Schema = z.lazy(() => starSchemaDataSourceSchema)

/**
 * @description Validation Error
 */
export const updateStarSchemaDataSource422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateStarSchemaDataSourceMutationRequestSchema = z.union([z.lazy(() => starSchemaDataSourceColdStartSchema), z.null()])

export const updateStarSchemaDataSourceMutationResponseSchema = z.lazy(() => updateStarSchemaDataSource200Schema)