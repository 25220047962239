// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadAccountSettings.ts"
);
import.meta.hot.lastModified = "1737759760700.024";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadAccountSettingsQueryResponse, ReadAccountSettingsPathParams, ReadAccountSettings422 } from '../types/ReadAccountSettings'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readAccountSettingsQueryKey = ({ accountName }: { accountName: ReadAccountSettingsPathParams['account_name'] }) =>
  [{ url: '/api/v1/accounts/:account_name/settings', params: { accountName: accountName } }] as const

export type ReadAccountSettingsQueryKey = ReturnType<typeof readAccountSettingsQueryKey>

/**
 * @description Get all client visible settings for an account.
 * @summary Read Account Settings
 * {@link /api/v1/accounts/:account_name/settings}
 */
async function readAccountSettings({ accountName }: { accountName: ReadAccountSettingsPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<ReadAccountSettingsQueryResponse, ReadAccountSettings422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/settings`,
    ...config,
  })
  return res.data
}

export function readAccountSettingsQueryOptions(
  { accountName }: { accountName: ReadAccountSettingsPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readAccountSettingsQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readAccountSettings({ accountName }, config)
    },
  })
}

/**
 * @description Get all client visible settings for an account.
 * @summary Read Account Settings
 * {@link /api/v1/accounts/:account_name/settings}
 */
export function useReadAccountSettings<
  TData = ReadAccountSettingsQueryResponse,
  TQueryData = ReadAccountSettingsQueryResponse,
  TQueryKey extends QueryKey = ReadAccountSettingsQueryKey,
>(
  { accountName }: { accountName: ReadAccountSettingsPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadAccountSettingsQueryResponse, ReadAccountSettings422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readAccountSettingsQueryKey({ accountName })

  const query = useQuery({
    ...(readAccountSettingsQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadAccountSettings422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}