// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/PromptLogComponent.ts"
);
import.meta.hot.lastModified = "1737935766493.7537";
}
// REMIX HMR END

export enum PromptLogComponent {
  'QUERY' = 'QUERY',
  'MOONLIGHT' = 'MOONLIGHT',
  'REPHRASE' = 'REPHRASE',
  'ALL' = 'ALL',
  'MOONLIGHT_REPHRASE' = 'MOONLIGHT_REPHRASE',
}