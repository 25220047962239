// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteModeConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759761028.0222";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { modeConnectionSchema } from './modeConnectionSchema'
import { z } from 'zod'

export const deleteModeConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  mode_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const deleteModeConnection200Schema = z.lazy(() => modeConnectionSchema)

/**
 * @description Validation Error
 */
export const deleteModeConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteModeConnectionMutationResponseSchema = z.lazy(() => deleteModeConnection200Schema)