// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsJoinSqls.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetSsdsJoinSqlsQueryResponse, GetSsdsJoinSqlsPathParams, GetSsdsJoinSqls422 } from '../types/GetSsdsJoinSqls'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsJoinSqlsQueryKey = ({
  accountName,
  starSchemaDataSourceId,
}: {
  accountName: GetSsdsJoinSqlsPathParams['account_name']
  starSchemaDataSourceId: GetSsdsJoinSqlsPathParams['star_schema_data_source_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/join_sqls',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
  ] as const

export type GetSsdsJoinSqlsQueryKey = ReturnType<typeof getSsdsJoinSqlsQueryKey>

/**
 * @description Get the joins implied by the identifiers on an ssds.Zenlytics always does <foreign key> left join <primary key>.This function makes a lot of assumptions e.g.there must always exist exactly one foreign key for each identifier nameThis assumption should always be true in the datasets we control. This may breaklater for datasets we don't control.
 * @summary Get Ssds Join Sqls
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/join_sqls}
 */
async function getSsdsJoinSqls(
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsJoinSqlsPathParams['account_name']; starSchemaDataSourceId: GetSsdsJoinSqlsPathParams['star_schema_data_source_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsJoinSqlsQueryResponse, GetSsdsJoinSqls422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/join_sqls`,
    ...config,
  })
  return res.data
}

export function getSsdsJoinSqlsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsJoinSqlsPathParams['account_name']; starSchemaDataSourceId: GetSsdsJoinSqlsPathParams['star_schema_data_source_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsJoinSqlsQueryKey({ accountName, starSchemaDataSourceId })
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsJoinSqls({ accountName, starSchemaDataSourceId }, config)
    },
  })
}

/**
 * @description Get the joins implied by the identifiers on an ssds.Zenlytics always does <foreign key> left join <primary key>.This function makes a lot of assumptions e.g.there must always exist exactly one foreign key for each identifier nameThis assumption should always be true in the datasets we control. This may breaklater for datasets we don't control.
 * @summary Get Ssds Join Sqls
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/join_sqls}
 */
export function useGetSsdsJoinSqls<
  TData = GetSsdsJoinSqlsQueryResponse,
  TQueryData = GetSsdsJoinSqlsQueryResponse,
  TQueryKey extends QueryKey = GetSsdsJoinSqlsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsJoinSqlsPathParams['account_name']; starSchemaDataSourceId: GetSsdsJoinSqlsPathParams['star_schema_data_source_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsJoinSqlsQueryResponse, GetSsdsJoinSqls422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsJoinSqlsQueryKey({ accountName, starSchemaDataSourceId })

  const query = useQuery({
    ...(getSsdsJoinSqlsQueryOptions({ accountName, starSchemaDataSourceId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsJoinSqls422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}