// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetBackingServices.ts"
);
import.meta.hot.lastModified = "1737935767645.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetBackingServicesQueryResponse, GetBackingServices422 } from '../types/GetBackingServices'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getBackingServicesQueryKey = () => [{ url: '/api/v1/admin/backing-services' }] as const

export type GetBackingServicesQueryKey = ReturnType<typeof getBackingServicesQueryKey>

/**
 * @description Admin endpoint to get all backing services.
 * @summary Get Backing Services
 * {@link /api/v1/admin/backing-services}
 */
async function getBackingServices(config: Partial<RequestConfig> = {}) {
  const res = await client<GetBackingServicesQueryResponse, GetBackingServices422, unknown>({ method: 'GET', url: `/api/v1/admin/backing-services`, ...config })
  return res.data
}

export function getBackingServicesQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = getBackingServicesQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getBackingServices(config)
    },
  })
}

/**
 * @description Admin endpoint to get all backing services.
 * @summary Get Backing Services
 * {@link /api/v1/admin/backing-services}
 */
export function useGetBackingServices<
  TData = GetBackingServicesQueryResponse,
  TQueryData = GetBackingServicesQueryResponse,
  TQueryKey extends QueryKey = GetBackingServicesQueryKey,
>(
  options: {
    query?: Partial<QueryObserverOptions<GetBackingServicesQueryResponse, GetBackingServices422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getBackingServicesQueryKey()

  const query = useQuery({
    ...(getBackingServicesQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetBackingServices422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}