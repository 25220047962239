// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/mergeDimensionsSchema.ts"
);
import.meta.hot.lastModified = "1737935766771.7522";
}
// REMIX HMR END

// @ts-nocheck
import { bodyMergeDimensionsApiV1AccountsAccountNameStarSchemaDataSourceDimensionsDimensionIdMergePatchSchema } from './bodyMergeDimensionsApiV1AccountsAccountNameStarSchemaDataSourceDimensionsDimensionIdMergePatchSchema'
import { dimensionDetailSchema } from './dimensionDetailSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const mergeDimensionsPathParamsSchema = z.object({
  account_name: z.string(),
  dimension_id: z.string(),
})

/**
 * @description Successful Response
 */
export const mergeDimensions200Schema = z.lazy(() => dimensionDetailSchema)

/**
 * @description Validation Error
 */
export const mergeDimensions422Schema = z.lazy(() => httpValidationErrorSchema)

export const mergeDimensionsMutationRequestSchema = z.lazy(
  () => bodyMergeDimensionsApiV1AccountsAccountNameStarSchemaDataSourceDimensionsDimensionIdMergePatchSchema,
)

export const mergeDimensionsMutationResponseSchema = z.lazy(() => mergeDimensions200Schema)