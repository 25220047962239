// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/OriginType.ts"
);
import.meta.hot.lastModified = "1737759757707.0393";
}
// REMIX HMR END

export enum OriginType {
  'DATABRICKS_TABLE' = 'DATABRICKS_TABLE',
  'SNOWFLAKE_LOGS' = 'SNOWFLAKE_LOGS',
  'SNOWFLAKE_TABLE' = 'SNOWFLAKE_TABLE',
  'BIGQUERY_TABLE' = 'BIGQUERY_TABLE',
  'POSTGRES_TABLE' = 'POSTGRES_TABLE',
  'REDSHIFT_TABLE' = 'REDSHIFT_TABLE',
  'TERADATA_TABLE' = 'TERADATA_TABLE',
  'AI' = 'AI',
  'ADMIN' = 'ADMIN',
  'TABLEAU' = 'TABLEAU',
  'MODE' = 'MODE',
  'SQL_CSV' = 'SQL_CSV',
  'FEEDBACK_SUGGESTION' = 'FEEDBACK_SUGGESTION',
}