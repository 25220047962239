// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateUpdateJob.ts"
);
import.meta.hot.lastModified = "1737759759782.0286";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateUpdateJobMutationRequest, UpdateUpdateJobMutationResponse, UpdateUpdateJobPathParams, UpdateUpdateJob422 } from '../types/UpdateUpdateJob'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateUpdateJobMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/update/{job_id}' }] as const

export type UpdateUpdateJobMutationKey = ReturnType<typeof updateUpdateJobMutationKey>

/**
 * @description Update an update job.
 * @summary Update Update Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/update/:job_id}
 */
async function updateUpdateJob(
  {
    enterpriseName,
    jobId,
    data,
  }: { enterpriseName: UpdateUpdateJobPathParams['enterprise_name']; jobId: UpdateUpdateJobPathParams['job_id']; data?: UpdateUpdateJobMutationRequest },
  config: Partial<RequestConfig<UpdateUpdateJobMutationRequest>> = {},
) {
  const res = await client<UpdateUpdateJobMutationResponse, UpdateUpdateJob422, UpdateUpdateJobMutationRequest>({
    method: 'PUT',
    url: `/api/v1/enterprises/${enterpriseName}/dataset-jobs/update/${jobId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update an update job.
 * @summary Update Update Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/update/:job_id}
 */
export function useUpdateUpdateJob(
  options: {
    mutation?: UseMutationOptions<
      UpdateUpdateJobMutationResponse,
      UpdateUpdateJob422,
      { enterpriseName: UpdateUpdateJobPathParams['enterprise_name']; jobId: UpdateUpdateJobPathParams['job_id']; data?: UpdateUpdateJobMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateUpdateJobMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateUpdateJobMutationKey()

  return useMutation<
    UpdateUpdateJobMutationResponse,
    UpdateUpdateJob422,
    { enterpriseName: UpdateUpdateJobPathParams['enterprise_name']; jobId: UpdateUpdateJobPathParams['job_id']; data?: UpdateUpdateJobMutationRequest }
  >({
    mutationFn: async ({ enterpriseName, jobId, data }) => {
      return updateUpdateJob({ enterpriseName, jobId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}