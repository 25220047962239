// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useLoginSessionFirebase.ts"
);
import.meta.hot.lastModified = "1737759760888.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { LoginSessionFirebaseMutationResponse, LoginSessionFirebaseQueryParams, LoginSessionFirebase422 } from '../types/LoginSessionFirebase'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const loginSessionFirebaseMutationKey = () => [{ url: '/api/v1/login/session/firebase' }] as const

export type LoginSessionFirebaseMutationKey = ReturnType<typeof loginSessionFirebaseMutationKey>

/**
 * @description Login via Firebase.
 * @summary Login Session Firebase
 * {@link /api/v1/login/session/firebase}
 */
async function loginSessionFirebase({ params }: { params: LoginSessionFirebaseQueryParams }, config: Partial<RequestConfig> = {}) {
  const res = await client<LoginSessionFirebaseMutationResponse, LoginSessionFirebase422, unknown>({
    method: 'POST',
    url: `/api/v1/login/session/firebase`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Login via Firebase.
 * @summary Login Session Firebase
 * {@link /api/v1/login/session/firebase}
 */
export function useLoginSessionFirebase(
  options: {
    mutation?: UseMutationOptions<LoginSessionFirebaseMutationResponse, LoginSessionFirebase422, { params: LoginSessionFirebaseQueryParams }>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? loginSessionFirebaseMutationKey()

  return useMutation<LoginSessionFirebaseMutationResponse, LoginSessionFirebase422, { params: LoginSessionFirebaseQueryParams }>({
    mutationFn: async ({ params }) => {
      return loginSessionFirebase({ params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}