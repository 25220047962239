// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteCell.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteCellMutationResponse, DeleteCellPathParams, DeleteCell422 } from '../types/DeleteCell'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteCellMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}' }] as const

export type DeleteCellMutationKey = ReturnType<typeof deleteCellMutationKey>

/**
 * @description Delete a Bumblebee Cell.
 * @summary Delete Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id}
 */
async function deleteCell(
  {
    accountName,
    conversationId,
    cellId,
  }: { accountName: DeleteCellPathParams['account_name']; conversationId: DeleteCellPathParams['conversation_id']; cellId: DeleteCellPathParams['cell_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteCellMutationResponse, DeleteCell422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete a Bumblebee Cell.
 * @summary Delete Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id}
 */
export function useDeleteCell(
  options: {
    mutation?: UseMutationOptions<
      DeleteCellMutationResponse,
      DeleteCell422,
      { accountName: DeleteCellPathParams['account_name']; conversationId: DeleteCellPathParams['conversation_id']; cellId: DeleteCellPathParams['cell_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteCellMutationKey()

  return useMutation<
    DeleteCellMutationResponse,
    DeleteCell422,
    { accountName: DeleteCellPathParams['account_name']; conversationId: DeleteCellPathParams['conversation_id']; cellId: DeleteCellPathParams['cell_id'] }
  >({
    mutationFn: async ({ accountName, conversationId, cellId }) => {
      return deleteCell({ accountName, conversationId, cellId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}