// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetDatasetJobs.ts"
);
import.meta.hot.lastModified = "1737935766295.755";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetDatasetJobsQueryResponse, GetDatasetJobsPathParams, GetDatasetJobsQueryParams, GetDatasetJobs422 } from '../types/GetDatasetJobs'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getDatasetJobsQueryKey = (
  { enterpriseName }: { enterpriseName: GetDatasetJobsPathParams['enterprise_name'] },
  params?: GetDatasetJobsQueryParams,
) => [{ url: '/api/v1/enterprises/:enterprise_name/dataset-jobs/', params: { enterpriseName: enterpriseName } }, ...(params ? [params] : [])] as const

export type GetDatasetJobsQueryKey = ReturnType<typeof getDatasetJobsQueryKey>

/**
 * @description Get all dataset jobs, paginated.
 * @summary Get Dataset Jobs
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/}
 */
async function getDatasetJobs(
  { enterpriseName, params }: { enterpriseName: GetDatasetJobsPathParams['enterprise_name']; params?: GetDatasetJobsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetDatasetJobsQueryResponse, GetDatasetJobs422, unknown>({
    method: 'GET',
    url: `/api/v1/enterprises/${enterpriseName}/dataset-jobs/`,
    params,
    ...config,
  })
  return res.data
}

export function getDatasetJobsQueryOptions(
  { enterpriseName, params }: { enterpriseName: GetDatasetJobsPathParams['enterprise_name']; params?: GetDatasetJobsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getDatasetJobsQueryKey({ enterpriseName }, params)
  return queryOptions({
    enabled: !!enterpriseName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getDatasetJobs({ enterpriseName, params }, config)
    },
  })
}

/**
 * @description Get all dataset jobs, paginated.
 * @summary Get Dataset Jobs
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/}
 */
export function useGetDatasetJobs<
  TData = GetDatasetJobsQueryResponse,
  TQueryData = GetDatasetJobsQueryResponse,
  TQueryKey extends QueryKey = GetDatasetJobsQueryKey,
>(
  { enterpriseName, params }: { enterpriseName: GetDatasetJobsPathParams['enterprise_name']; params?: GetDatasetJobsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetDatasetJobsQueryResponse, GetDatasetJobs422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getDatasetJobsQueryKey({ enterpriseName }, params)

  const query = useQuery({
    ...(getDatasetJobsQueryOptions({ enterpriseName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetDatasetJobs422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}