// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateDimensionStateSchema.ts"
);
import.meta.hot.lastModified = "1737935768206.7444";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionDetailSchema } from './dimensionDetailSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { stateUpdateSchema } from './stateUpdateSchema'
import { z } from 'zod'

export const updateDimensionStatePathParamsSchema = z.object({
  account_name: z.string(),
  dimension_id: z.string(),
})

export const updateDimensionStateQueryParamsSchema = z
  .object({
    validate_transition: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const updateDimensionState200Schema = z.lazy(() => dimensionDetailSchema)

/**
 * @description Validation Error
 */
export const updateDimensionState422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateDimensionStateMutationRequestSchema = z.lazy(() => stateUpdateSchema)

export const updateDimensionStateMutationResponseSchema = z.lazy(() => updateDimensionState200Schema)