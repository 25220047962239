// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useProfileCellData.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ProfileCellDataMutationRequest,
  ProfileCellDataMutationResponse,
  ProfileCellDataPathParams,
  ProfileCellDataQueryParams,
  ProfileCellData422,
} from '../types/ProfileCellData'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const profileCellDataMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/profile' }] as const

export type ProfileCellDataMutationKey = ReturnType<typeof profileCellDataMutationKey>

/**
 * @description Profile the bumblebee cell table columns.Raises:NSCannotReadNodeDataError: If the bumblebee cell table does not exist.
 * @summary Profile Cell Data
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/profile}
 */
async function profileCellData(
  {
    accountName,
    conversationId,
    cellId,
    data,
    params,
  }: {
    accountName: ProfileCellDataPathParams['account_name']
    conversationId: ProfileCellDataPathParams['conversation_id']
    cellId: ProfileCellDataPathParams['cell_id']
    data?: ProfileCellDataMutationRequest
    params?: ProfileCellDataQueryParams
  },
  config: Partial<RequestConfig<ProfileCellDataMutationRequest>> = {},
) {
  const res = await client<ProfileCellDataMutationResponse, ProfileCellData422, ProfileCellDataMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/profile`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Profile the bumblebee cell table columns.Raises:NSCannotReadNodeDataError: If the bumblebee cell table does not exist.
 * @summary Profile Cell Data
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/profile}
 */
export function useProfileCellData(
  options: {
    mutation?: UseMutationOptions<
      ProfileCellDataMutationResponse,
      ProfileCellData422,
      {
        accountName: ProfileCellDataPathParams['account_name']
        conversationId: ProfileCellDataPathParams['conversation_id']
        cellId: ProfileCellDataPathParams['cell_id']
        data?: ProfileCellDataMutationRequest
        params?: ProfileCellDataQueryParams
      }
    >
    client?: Partial<RequestConfig<ProfileCellDataMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? profileCellDataMutationKey()

  return useMutation<
    ProfileCellDataMutationResponse,
    ProfileCellData422,
    {
      accountName: ProfileCellDataPathParams['account_name']
      conversationId: ProfileCellDataPathParams['conversation_id']
      cellId: ProfileCellDataPathParams['cell_id']
      data?: ProfileCellDataMutationRequest
      params?: ProfileCellDataQueryParams
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, data, params }) => {
      return profileCellData({ accountName, conversationId, cellId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}