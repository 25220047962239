// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRmAccountsFromEnterprise.ts"
);
import.meta.hot.lastModified = "1737759761632.019";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RmAccountsFromEnterpriseMutationRequest,
  RmAccountsFromEnterpriseMutationResponse,
  RmAccountsFromEnterprisePathParams,
  RmAccountsFromEnterprise422,
} from '../types/RmAccountsFromEnterprise'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const rmAccountsFromEnterpriseMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/accounts' }] as const

export type RmAccountsFromEnterpriseMutationKey = ReturnType<typeof rmAccountsFromEnterpriseMutationKey>

/**
 * @description Remove accounts from the enterprise.This is intended to be only used by the superuser.Returns: A list of account names that were not able to be removed.
 * @summary Rm Accounts From Enterprise
 * {@link /api/v1/enterprises/:enterprise_name/accounts}
 */
async function rmAccountsFromEnterprise(
  { enterpriseName, data }: { enterpriseName: RmAccountsFromEnterprisePathParams['enterprise_name']; data?: RmAccountsFromEnterpriseMutationRequest },
  config: Partial<RequestConfig<RmAccountsFromEnterpriseMutationRequest>> = {},
) {
  const res = await client<RmAccountsFromEnterpriseMutationResponse, RmAccountsFromEnterprise422, RmAccountsFromEnterpriseMutationRequest>({
    method: 'DELETE',
    url: `/api/v1/enterprises/${enterpriseName}/accounts`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Remove accounts from the enterprise.This is intended to be only used by the superuser.Returns: A list of account names that were not able to be removed.
 * @summary Rm Accounts From Enterprise
 * {@link /api/v1/enterprises/:enterprise_name/accounts}
 */
export function useRmAccountsFromEnterprise(
  options: {
    mutation?: UseMutationOptions<
      RmAccountsFromEnterpriseMutationResponse,
      RmAccountsFromEnterprise422,
      { enterpriseName: RmAccountsFromEnterprisePathParams['enterprise_name']; data?: RmAccountsFromEnterpriseMutationRequest }
    >
    client?: Partial<RequestConfig<RmAccountsFromEnterpriseMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? rmAccountsFromEnterpriseMutationKey()

  return useMutation<
    RmAccountsFromEnterpriseMutationResponse,
    RmAccountsFromEnterprise422,
    { enterpriseName: RmAccountsFromEnterprisePathParams['enterprise_name']; data?: RmAccountsFromEnterpriseMutationRequest }
  >({
    mutationFn: async ({ enterpriseName, data }) => {
      return rmAccountsFromEnterprise({ enterpriseName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}