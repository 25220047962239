// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/filterCategorySchema.ts"
);
import.meta.hot.lastModified = "1737759759420.0305";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Filter category.
 */
export const filterCategorySchema = z.enum(['text', 'numeric', 'date', 'datetime', 'boolean', 'unknown']).describe('Filter category.')