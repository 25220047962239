// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/ConnectionsService.ts"
);
import.meta.hot.lastModified = "1737759746330.098";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BigQueryConnection } from '../models/BigQueryConnection';
import type { Body_read_connection_data_api_v1_accounts__account_name__connections__connection_id__data_get } from '../models/Body_read_connection_data_api_v1_accounts__account_name__connections__connection_id__data_get';
import type { Body_write_csv_to_connection_api_v1_accounts__account_name__connections_csv__post } from '../models/Body_write_csv_to_connection_api_v1_accounts__account_name__connections_csv__post';
import type { Connection } from '../models/Connection';
import type { ConnectionPage } from '../models/ConnectionPage';
import type { DatabricksConnection } from '../models/DatabricksConnection';
import type { NodeDataPage } from '../models/NodeDataPage';
import type { PostgresConnection } from '../models/PostgresConnection';
import type { RedshiftConnection } from '../models/RedshiftConnection';
import type { SnowflakeConnection } from '../models/SnowflakeConnection';
import type { TableLocation } from '../models/TableLocation';
import type { TeradataConnection } from '../models/TeradataConnection';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConnectionsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read Connections
   * Read all connections, independent of type.
   * @param accountName
   * @param skip
   * @param limit
   * @param sessionId
   * @returns ConnectionPage Successful Response
   * @throws ApiError
   */
  public readConnections(
    accountName: string,
    skip?: number,
    limit: number = 100,
    sessionId?: (string | null),
  ): CancelablePromise<ConnectionPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'skip': skip,
        'limit': limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Connection Data
   * Read data from a data warehouse table without creating a node.
   * @param accountName
   * @param connectionId
   * @param database
   * @param schema
   * @param table
   * @param skip
   * @param limit
   * @param sessionId
   * @param requestBody
   * @returns NodeDataPage Successful Response
   * @throws ApiError
   */
  public readConnectionData(
    accountName: string,
    connectionId: string,
    database: string,
    schema: string,
    table: string,
    skip?: number,
    limit: number = 100,
    sessionId?: (string | null),
    requestBody?: Body_read_connection_data_api_v1_accounts__account_name__connections__connection_id__data_get,
  ): CancelablePromise<NodeDataPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{connection_id}/data',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'skip': skip,
        'limit': limit,
        'database': database,
        'schema': schema,
        'table': table,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Connection
   * Read Connection, independent of type.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readConnection(
    accountName: string,
    connectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<(BigQueryConnection | PostgresConnection | RedshiftConnection | SnowflakeConnection | DatabricksConnection | TeradataConnection | Connection)> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{connection_id}',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Copy Connection
   * Copy an existing connection to another account.
   * @param accountName
   * @param connectionId
   * @param destAccountName
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public copyConnection(
    accountName: string,
    connectionId: string,
    destAccountName?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<(BigQueryConnection | PostgresConnection | RedshiftConnection | SnowflakeConnection | DatabricksConnection | TeradataConnection | Connection)> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/{connection_id}/copy',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'dest_account_name': destAccountName,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Write Csv To Connection
   * Upload CSV to the warehouse creating a table.
   *
   * If no connection_id is provided, a demo connection will be used/created.
   *
   * We use File and Form data together since we cannot declare Body fields we want to
   * accept as JSON to do the HTTP protocol specification.
   *
   * Ref: https://fastapi.tiangolo.com/tutorial/request-forms-and-files/
   * @param accountName
   * @param formData
   * @param sessionId
   * @returns TableLocation Successful Response
   * @throws ApiError
   */
  public writeCsvToConnection(
    accountName: string,
    formData: Body_write_csv_to_connection_api_v1_accounts__account_name__connections_csv__post,
    sessionId?: (string | null),
  ): CancelablePromise<TableLocation> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/csv/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Tables Metadata
   * List tables metadata.
   *
   * Tables: list of table names in the format database.schema.table (or project.dataset.table for BigQuery)
   * @param accountName
   * @param connectionId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public listTablesMetadata(
    accountName: string,
    connectionId: string,
    requestBody: Array<string>,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/{connection_id}/tables/metadata',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
