// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetChatPromptlogs.ts"
);
import.meta.hot.lastModified = "1737935767422.7485";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetChatPromptlogsQueryResponse, GetChatPromptlogsPathParams, GetChatPromptlogs422 } from '../types/GetChatPromptlogs'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getChatPromptlogsQueryKey = ({
  accountName,
  chatId,
}: {
  accountName: GetChatPromptlogsPathParams['account_name']
  chatId: GetChatPromptlogsPathParams['chat_id']
}) => [{ url: '/api/v3/orgs/:account_name/chat/:chat_id/promptlogs', params: { accountName: accountName, chatId: chatId } }] as const

export type GetChatPromptlogsQueryKey = ReturnType<typeof getChatPromptlogsQueryKey>

/**
 * @description Get chat message promptlogs sorted in descending order.
 * @summary Get Chat Promptlogs
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/promptlogs}
 */
async function getChatPromptlogs(
  { accountName, chatId }: { accountName: GetChatPromptlogsPathParams['account_name']; chatId: GetChatPromptlogsPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetChatPromptlogsQueryResponse, GetChatPromptlogs422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/chat/${chatId}/promptlogs`,
    ...config,
  })
  return res.data
}

export function getChatPromptlogsQueryOptions(
  { accountName, chatId }: { accountName: GetChatPromptlogsPathParams['account_name']; chatId: GetChatPromptlogsPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getChatPromptlogsQueryKey({ accountName, chatId })
  return queryOptions({
    enabled: !!(accountName && chatId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getChatPromptlogs({ accountName, chatId }, config)
    },
  })
}

/**
 * @description Get chat message promptlogs sorted in descending order.
 * @summary Get Chat Promptlogs
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/promptlogs}
 */
export function useGetChatPromptlogs<
  TData = GetChatPromptlogsQueryResponse,
  TQueryData = GetChatPromptlogsQueryResponse,
  TQueryKey extends QueryKey = GetChatPromptlogsQueryKey,
>(
  { accountName, chatId }: { accountName: GetChatPromptlogsPathParams['account_name']; chatId: GetChatPromptlogsPathParams['chat_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetChatPromptlogsQueryResponse, GetChatPromptlogs422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getChatPromptlogsQueryKey({ accountName, chatId })

  const query = useQuery({
    ...(getChatPromptlogsQueryOptions({ accountName, chatId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetChatPromptlogs422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}