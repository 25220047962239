// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useImportFromTwb.ts"
);
import.meta.hot.lastModified = "1737759758962.0327";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ImportFromTwbMutationRequest,
  ImportFromTwbMutationResponse,
  ImportFromTwbPathParams,
  ImportFromTwbQueryParams,
  ImportFromTwb422,
} from '../types/ImportFromTwb'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const importFromTwbMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/import_from_twb' }] as const

export type ImportFromTwbMutationKey = ReturnType<typeof importFromTwbMutationKey>

/**
 * @description Import DMs from a twb/twbx file.This parses the twb and creates a star schema data source.
 * @summary Import From Twb
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/import_from_twb}
 */
async function importFromTwb(
  { accountName, data, params }: { accountName: ImportFromTwbPathParams['account_name']; data: ImportFromTwbMutationRequest; params: ImportFromTwbQueryParams },
  config: Partial<RequestConfig<ImportFromTwbMutationRequest>> = {},
) {
  const formData = new FormData()
  if (data) {
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof typeof data]
      if (typeof key === 'string' && (typeof value === 'string' || value instanceof Blob)) {
        formData.append(key, value)
      }
    })
  }
  const res = await client<ImportFromTwbMutationResponse, ImportFromTwb422, ImportFromTwbMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/import_from_twb`,
    params,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data', ...config.headers },
    ...config,
  })
  return res.data
}

/**
 * @description Import DMs from a twb/twbx file.This parses the twb and creates a star schema data source.
 * @summary Import From Twb
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/import_from_twb}
 */
export function useImportFromTwb(
  options: {
    mutation?: UseMutationOptions<
      ImportFromTwbMutationResponse,
      ImportFromTwb422,
      { accountName: ImportFromTwbPathParams['account_name']; data: ImportFromTwbMutationRequest; params: ImportFromTwbQueryParams }
    >
    client?: Partial<RequestConfig<ImportFromTwbMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? importFromTwbMutationKey()

  return useMutation<
    ImportFromTwbMutationResponse,
    ImportFromTwb422,
    { accountName: ImportFromTwbPathParams['account_name']; data: ImportFromTwbMutationRequest; params: ImportFromTwbQueryParams }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return importFromTwb({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}