// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadReports.ts"
);
import.meta.hot.lastModified = "1737759758412.0356";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadReportsQueryResponse, ReadReportsPathParams, ReadReportsQueryParams, ReadReports422 } from '../types/ReadReports'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readReportsQueryKey = ({ accountName }: { accountName: ReadReportsPathParams['account_name'] }, params?: ReadReportsQueryParams) =>
  [{ url: '/api/v1/accounts/:account_name/reports', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type ReadReportsQueryKey = ReturnType<typeof readReportsQueryKey>

/**
 * @description Read indexed reports.Args:    db: Database session to perform the operation.    user_session: User session to perform the operation.    account: Account to perform the operation.    type: Type of the reports to read. If not provided, all reports are read.    limit: Number of reports to read.    skip: Number of reports to skip.    search: Search string to filter the reports.
 * @summary Read Reports
 * {@link /api/v1/accounts/:account_name/reports}
 */
async function readReports(
  { accountName, params }: { accountName: ReadReportsPathParams['account_name']; params?: ReadReportsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadReportsQueryResponse, ReadReports422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/reports`,
    params,
    ...config,
  })
  return res.data
}

export function readReportsQueryOptions(
  { accountName, params }: { accountName: ReadReportsPathParams['account_name']; params?: ReadReportsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readReportsQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readReports({ accountName, params }, config)
    },
  })
}

/**
 * @description Read indexed reports.Args:    db: Database session to perform the operation.    user_session: User session to perform the operation.    account: Account to perform the operation.    type: Type of the reports to read. If not provided, all reports are read.    limit: Number of reports to read.    skip: Number of reports to skip.    search: Search string to filter the reports.
 * @summary Read Reports
 * {@link /api/v1/accounts/:account_name/reports}
 */
export function useReadReports<TData = ReadReportsQueryResponse, TQueryData = ReadReportsQueryResponse, TQueryKey extends QueryKey = ReadReportsQueryKey>(
  { accountName, params }: { accountName: ReadReportsPathParams['account_name']; params?: ReadReportsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadReportsQueryResponse, ReadReports422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readReportsQueryKey({ accountName }, params)

  const query = useQuery({
    ...(readReportsQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadReports422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}