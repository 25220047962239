// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteWidgetSchema.ts"
);
import.meta.hot.lastModified = "1737935766030.756";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const deleteWidgetPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  widget_id: z.string(),
})

/**
 * @description Successful Response
 */
export const deleteWidget204Schema = z.unknown()

/**
 * @description Validation Error
 */
export const deleteWidget422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteWidgetMutationResponseSchema = z.lazy(() => deleteWidget204Schema)