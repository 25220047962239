// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/refreshQueryMessageSchema.ts"
);
import.meta.hot.lastModified = "1737935768070.745";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { messageApiResponseSchema } from './messageApiResponseSchema'
import { z } from 'zod'

export const refreshQueryMessagePathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  message_id: z.string().describe('The id of a message in a chat.'),
})

/**
 * @description The table associated with the message was refreshed successfully.
 */
export const refreshQueryMessage200Schema = z.lazy(() => messageApiResponseSchema)

/**
 * @description Bad request. Verify that the message contains a table ID.
 */
export const refreshQueryMessage400Schema = z.unknown()

/**
 * @description Validation Error
 */
export const refreshQueryMessage422Schema = z.lazy(() => httpValidationErrorSchema)

export const refreshQueryMessageMutationResponseSchema = z.lazy(() => refreshQueryMessage200Schema)