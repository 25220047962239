// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRefreshQueryMessage.ts"
);
import.meta.hot.lastModified = "1737935767947.7456";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RefreshQueryMessageMutationResponse,
  RefreshQueryMessagePathParams,
  RefreshQueryMessage400,
  RefreshQueryMessage422,
} from '../types/RefreshQueryMessage'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const refreshQueryMessageMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/messages/{message_id}/refresh' }] as const

export type RefreshQueryMessageMutationKey = ReturnType<typeof refreshQueryMessageMutationKey>

/**
 * @description Refresh the SQL table in a chat message.This will return an error if the message does not contain a table ID.Note that you will need to refetch the data from the data endpoint to see the updates.
 * @summary Refresh Query Message
 * {@link /api/v3/orgs/:account_name/messages/:message_id/refresh}
 */
async function refreshQueryMessage(
  { accountName, messageId }: { accountName: RefreshQueryMessagePathParams['account_name']; messageId: RefreshQueryMessagePathParams['message_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RefreshQueryMessageMutationResponse, RefreshQueryMessage400 | RefreshQueryMessage422, unknown>({
    method: 'PUT',
    url: `/api/v3/orgs/${accountName}/messages/${messageId}/refresh`,
    ...config,
  })
  return res.data
}

/**
 * @description Refresh the SQL table in a chat message.This will return an error if the message does not contain a table ID.Note that you will need to refetch the data from the data endpoint to see the updates.
 * @summary Refresh Query Message
 * {@link /api/v3/orgs/:account_name/messages/:message_id/refresh}
 */
export function useRefreshQueryMessage(
  options: {
    mutation?: UseMutationOptions<
      RefreshQueryMessageMutationResponse,
      RefreshQueryMessage400 | RefreshQueryMessage422,
      { accountName: RefreshQueryMessagePathParams['account_name']; messageId: RefreshQueryMessagePathParams['message_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? refreshQueryMessageMutationKey()

  return useMutation<
    RefreshQueryMessageMutationResponse,
    RefreshQueryMessage400 | RefreshQueryMessage422,
    { accountName: RefreshQueryMessagePathParams['account_name']; messageId: RefreshQueryMessagePathParams['message_id'] }
  >({
    mutationFn: async ({ accountName, messageId }) => {
      return refreshQueryMessage({ accountName, messageId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}