// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/IdentifierJoinType.ts"
);
import.meta.hot.lastModified = "1737759759440.0303";
}
// REMIX HMR END

export enum IdentifierJoinType {
  'left_outer' = 'left_outer',
  'inner' = 'inner',
  'full_outer' = 'full_outer',
  'cross' = 'cross',
}