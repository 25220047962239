// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRunBumblebeeCell.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RunBumblebeeCellMutationRequest,
  RunBumblebeeCellMutationResponse,
  RunBumblebeeCellPathParams,
  RunBumblebeeCell422,
} from '../types/RunBumblebeeCell'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const runBumblebeeCellMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cell/{cell_id}/run_bumblebee_cell' }] as const

export type RunBumblebeeCellMutationKey = ReturnType<typeof runBumblebeeCellMutationKey>

/**
 * @description Run bumblebee for an already created cell.
 * @summary Run Bumblebee Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cell/:cell_id/run_bumblebee_cell}
 */
async function runBumblebeeCell(
  {
    accountName,
    conversationId,
    cellId,
    data,
  }: {
    accountName: RunBumblebeeCellPathParams['account_name']
    conversationId: RunBumblebeeCellPathParams['conversation_id']
    cellId: RunBumblebeeCellPathParams['cell_id']
    data?: RunBumblebeeCellMutationRequest
  },
  config: Partial<RequestConfig<RunBumblebeeCellMutationRequest>> = {},
) {
  const res = await client<RunBumblebeeCellMutationResponse, RunBumblebeeCell422, RunBumblebeeCellMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cell/${cellId}/run_bumblebee_cell`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Run bumblebee for an already created cell.
 * @summary Run Bumblebee Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cell/:cell_id/run_bumblebee_cell}
 */
export function useRunBumblebeeCell(
  options: {
    mutation?: UseMutationOptions<
      RunBumblebeeCellMutationResponse,
      RunBumblebeeCell422,
      {
        accountName: RunBumblebeeCellPathParams['account_name']
        conversationId: RunBumblebeeCellPathParams['conversation_id']
        cellId: RunBumblebeeCellPathParams['cell_id']
        data?: RunBumblebeeCellMutationRequest
      }
    >
    client?: Partial<RequestConfig<RunBumblebeeCellMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? runBumblebeeCellMutationKey()

  return useMutation<
    RunBumblebeeCellMutationResponse,
    RunBumblebeeCell422,
    {
      accountName: RunBumblebeeCellPathParams['account_name']
      conversationId: RunBumblebeeCellPathParams['conversation_id']
      cellId: RunBumblebeeCellPathParams['cell_id']
      data?: RunBumblebeeCellMutationRequest
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, data }) => {
      return runBumblebeeCell({ accountName, conversationId, cellId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}