// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsJoins.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetSsdsJoinsQueryResponse, GetSsdsJoinsPathParams, GetSsdsJoins422 } from '../types/GetSsdsJoins'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsJoinsQueryKey = ({
  accountName,
  starSchemaDataSourceId,
}: {
  accountName: GetSsdsJoinsPathParams['account_name']
  starSchemaDataSourceId: GetSsdsJoinsPathParams['star_schema_data_source_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/joins',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
  ] as const

export type GetSsdsJoinsQueryKey = ReturnType<typeof getSsdsJoinsQueryKey>

/**
 * @description Get Joins for Star Schema Data Source.
 * @summary Get Ssds Joins
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/joins}
 */
async function getSsdsJoins(
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsJoinsPathParams['account_name']; starSchemaDataSourceId: GetSsdsJoinsPathParams['star_schema_data_source_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsJoinsQueryResponse, GetSsdsJoins422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/joins`,
    ...config,
  })
  return res.data
}

export function getSsdsJoinsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsJoinsPathParams['account_name']; starSchemaDataSourceId: GetSsdsJoinsPathParams['star_schema_data_source_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsJoinsQueryKey({ accountName, starSchemaDataSourceId })
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsJoins({ accountName, starSchemaDataSourceId }, config)
    },
  })
}

/**
 * @description Get Joins for Star Schema Data Source.
 * @summary Get Ssds Joins
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/joins}
 */
export function useGetSsdsJoins<TData = GetSsdsJoinsQueryResponse, TQueryData = GetSsdsJoinsQueryResponse, TQueryKey extends QueryKey = GetSsdsJoinsQueryKey>(
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsJoinsPathParams['account_name']; starSchemaDataSourceId: GetSsdsJoinsPathParams['star_schema_data_source_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsJoinsQueryResponse, GetSsdsJoins422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsJoinsQueryKey({ accountName, starSchemaDataSourceId })

  const query = useQuery({
    ...(getSsdsJoinsQueryOptions({ accountName, starSchemaDataSourceId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsJoins422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}