// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRemoveDmGroups.ts"
);
import.meta.hot.lastModified = "1737759759414.0305";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RemoveDmGroupsMutationResponse, RemoveDmGroupsPathParams, RemoveDmGroups422 } from '../types/RemoveDmGroups'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const removeDmGroupsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/remove_dimension_groups' }] as const

export type RemoveDmGroupsMutationKey = ReturnType<typeof removeDmGroupsMutationKey>

/**
 * @description Create dimension groups for Star Schema Data Source.
 * @summary Remove Dm Groups
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/remove_dimension_groups}
 */
async function removeDmGroups(
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: RemoveDmGroupsPathParams['account_name']; starSchemaDataSourceId: RemoveDmGroupsPathParams['star_schema_data_source_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RemoveDmGroupsMutationResponse, RemoveDmGroups422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/remove_dimension_groups`,
    ...config,
  })
  return res.data
}

/**
 * @description Create dimension groups for Star Schema Data Source.
 * @summary Remove Dm Groups
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/remove_dimension_groups}
 */
export function useRemoveDmGroups(
  options: {
    mutation?: UseMutationOptions<
      RemoveDmGroupsMutationResponse,
      RemoveDmGroups422,
      { accountName: RemoveDmGroupsPathParams['account_name']; starSchemaDataSourceId: RemoveDmGroupsPathParams['star_schema_data_source_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? removeDmGroupsMutationKey()

  return useMutation<
    RemoveDmGroupsMutationResponse,
    RemoveDmGroups422,
    { accountName: RemoveDmGroupsPathParams['account_name']; starSchemaDataSourceId: RemoveDmGroupsPathParams['star_schema_data_source_id'] }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId }) => {
      return removeDmGroups({ accountName, starSchemaDataSourceId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}