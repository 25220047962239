// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/addAccountUsersSchema.ts"
);
import.meta.hot.lastModified = "1737759759802.0286";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const addAccountUsersPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const addAccountUsers200Schema = z.array(z.string())

/**
 * @description Validation Error
 */
export const addAccountUsers422Schema = z.lazy(() => httpValidationErrorSchema)

export const addAccountUsersMutationRequestSchema = z.array(z.string())

export const addAccountUsersMutationResponseSchema = z.lazy(() => addAccountUsers200Schema)