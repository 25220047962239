// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/addDataSourcesSchema.ts"
);
import.meta.hot.lastModified = "1737759759433.0303";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceSchema } from './starSchemaDataSourceSchema'
import { starSchemaDataSourceTableOrViewInfoSchema } from './starSchemaDataSourceTableOrViewInfoSchema'
import { z } from 'zod'

export const addDataSourcesPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const addDataSources200Schema = z.lazy(() => starSchemaDataSourceSchema)

/**
 * @description Validation Error
 */
export const addDataSources422Schema = z.lazy(() => httpValidationErrorSchema)

export const addDataSourcesMutationRequestSchema = z.array(z.lazy(() => starSchemaDataSourceTableOrViewInfoSchema))

export const addDataSourcesMutationResponseSchema = z.lazy(() => addDataSources200Schema)