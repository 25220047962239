// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/evalFilterSchema.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const evalFilterSchema = z.object({
  conversation_ids: z.union([z.array(z.string()), z.null()]).optional(),
  all_conversations_with_verified_cells: z.union([z.boolean(), z.null()]).optional(),
  all_eval_conversations: z.union([z.boolean(), z.null()]).optional(),
})