// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/browserLogSchema.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

// @ts-nocheck
import { logLevelSchema } from './logLevelSchema'
import { z } from 'zod'

/**
 * @description An object sent from the client so we can get client information logged on the server.
 */
export const browserLogSchema = z
  .object({
    current_url: z.string(),
    timestamp: z.number().int(),
    level: z.lazy(() => logLevelSchema),
    message: z.string(),
    data: z.union([z.object({}).strict(), z.null()]),
  })
  .describe('An object sent from the client so we can get client information logged on the server.')