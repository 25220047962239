// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createTableauConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tableauConnectionCreateSchema } from './tableauConnectionCreateSchema'
import { tableauConnectionSchema } from './tableauConnectionSchema'
import { z } from 'zod'

export const createTableauConnectionPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const createTableauConnection200Schema = z.lazy(() => tableauConnectionSchema)

/**
 * @description Validation Error
 */
export const createTableauConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const createTableauConnectionMutationRequestSchema = z.lazy(() => tableauConnectionCreateSchema)

export const createTableauConnectionMutationResponseSchema = z.lazy(() => createTableauConnection200Schema)