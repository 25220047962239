// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadRedshiftConnection.ts"
);
import.meta.hot.lastModified = "1737759761269.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadRedshiftConnectionQueryResponse, ReadRedshiftConnectionPathParams, ReadRedshiftConnection422 } from '../types/ReadRedshiftConnection'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readRedshiftConnectionQueryKey = ({
  accountName,
  connectionId,
}: {
  accountName: ReadRedshiftConnectionPathParams['account_name']
  connectionId: ReadRedshiftConnectionPathParams['connection_id']
}) => [{ url: '/api/v1/accounts/:account_name/connections/redshift/:connection_id', params: { accountName: accountName, connectionId: connectionId } }] as const

export type ReadRedshiftConnectionQueryKey = ReturnType<typeof readRedshiftConnectionQueryKey>

/**
 * @description Read a Redshift connection.
 * @summary Read Redshift Connection
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id}
 */
async function readRedshiftConnection(
  {
    accountName,
    connectionId,
  }: { accountName: ReadRedshiftConnectionPathParams['account_name']; connectionId: ReadRedshiftConnectionPathParams['connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadRedshiftConnectionQueryResponse, ReadRedshiftConnection422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/redshift/${connectionId}`,
    ...config,
  })
  return res.data
}

export function readRedshiftConnectionQueryOptions(
  {
    accountName,
    connectionId,
  }: { accountName: ReadRedshiftConnectionPathParams['account_name']; connectionId: ReadRedshiftConnectionPathParams['connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readRedshiftConnectionQueryKey({ accountName, connectionId })
  return queryOptions({
    enabled: !!(accountName && connectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readRedshiftConnection({ accountName, connectionId }, config)
    },
  })
}

/**
 * @description Read a Redshift connection.
 * @summary Read Redshift Connection
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id}
 */
export function useReadRedshiftConnection<
  TData = ReadRedshiftConnectionQueryResponse,
  TQueryData = ReadRedshiftConnectionQueryResponse,
  TQueryKey extends QueryKey = ReadRedshiftConnectionQueryKey,
>(
  {
    accountName,
    connectionId,
  }: { accountName: ReadRedshiftConnectionPathParams['account_name']; connectionId: ReadRedshiftConnectionPathParams['connection_id'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadRedshiftConnectionQueryResponse, ReadRedshiftConnection422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readRedshiftConnectionQueryKey({ accountName, connectionId })

  const query = useQuery({
    ...(readRedshiftConnectionQueryOptions({ accountName, connectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadRedshiftConnection422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}