// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateAccountSettings.ts"
);
import.meta.hot.lastModified = "1737759761240.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateAccountSettingsMutationRequest,
  UpdateAccountSettingsMutationResponse,
  UpdateAccountSettingsPathParams,
  UpdateAccountSettings422,
} from '../types/UpdateAccountSettings'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateAccountSettingsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/settings' }] as const

export type UpdateAccountSettingsMutationKey = ReturnType<typeof updateAccountSettingsMutationKey>

/**
 * @description Update settings for an account.
 * @summary Update Account Settings
 * {@link /api/v1/accounts/:account_name/settings}
 */
async function updateAccountSettings(
  { accountName, data }: { accountName: UpdateAccountSettingsPathParams['account_name']; data?: UpdateAccountSettingsMutationRequest },
  config: Partial<RequestConfig<UpdateAccountSettingsMutationRequest>> = {},
) {
  const res = await client<UpdateAccountSettingsMutationResponse, UpdateAccountSettings422, UpdateAccountSettingsMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/settings`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update settings for an account.
 * @summary Update Account Settings
 * {@link /api/v1/accounts/:account_name/settings}
 */
export function useUpdateAccountSettings(
  options: {
    mutation?: UseMutationOptions<
      UpdateAccountSettingsMutationResponse,
      UpdateAccountSettings422,
      { accountName: UpdateAccountSettingsPathParams['account_name']; data?: UpdateAccountSettingsMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateAccountSettingsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateAccountSettingsMutationKey()

  return useMutation<
    UpdateAccountSettingsMutationResponse,
    UpdateAccountSettings422,
    { accountName: UpdateAccountSettingsPathParams['account_name']; data?: UpdateAccountSettingsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return updateAccountSettings({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}