// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateUser.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateUserMutationRequest, UpdateUserMutationResponse, UpdateUserPathParams, UpdateUserQueryParams, UpdateUser422 } from '../types/UpdateUser'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateUserMutationKey = () => [{ url: '/api/v1/users/{user_id}' }] as const

export type UpdateUserMutationKey = ReturnType<typeof updateUserMutationKey>

/**
 * @description Update a user.
 * @summary Update User
 * {@link /api/v1/users/:user_id}
 */
async function updateUser(
  { userId, data, params }: { userId: UpdateUserPathParams['user_id']; data?: UpdateUserMutationRequest; params?: UpdateUserQueryParams },
  config: Partial<RequestConfig<UpdateUserMutationRequest>> = {},
) {
  const res = await client<UpdateUserMutationResponse, UpdateUser422, UpdateUserMutationRequest>({
    method: 'PUT',
    url: `/api/v1/users/${userId}`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a user.
 * @summary Update User
 * {@link /api/v1/users/:user_id}
 */
export function useUpdateUser(
  options: {
    mutation?: UseMutationOptions<
      UpdateUserMutationResponse,
      UpdateUser422,
      { userId: UpdateUserPathParams['user_id']; data?: UpdateUserMutationRequest; params?: UpdateUserQueryParams }
    >
    client?: Partial<RequestConfig<UpdateUserMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateUserMutationKey()

  return useMutation<
    UpdateUserMutationResponse,
    UpdateUser422,
    { userId: UpdateUserPathParams['user_id']; data?: UpdateUserMutationRequest; params?: UpdateUserQueryParams }
  >({
    mutationFn: async ({ userId, data, params }) => {
      return updateUser({ userId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}