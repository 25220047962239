// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messageApiResponseSchema.ts"
);
import.meta.hot.lastModified = "1737935767874.746";
}
// REMIX HMR END

// @ts-nocheck
import { renderTypeSchema } from './renderTypeSchema'
import { signalTypeApiSchema } from './signalTypeApiSchema'
import { streamedOutputQuestionApiSchema } from './streamedOutputQuestionApiSchema'
import { userBasicApiResponseSchema } from './userBasicApiResponseSchema'
import { z } from 'zod'

/**
 * @description The API response corresponding to a message.\n\nThe intent of this class is to provide a unified message format for clients.\nTherefore every response can contain a number of fields and it is up to the client\nto render them accordingly.
 */
export const messageApiResponseSchema = z
  .object({
    id: z
      .string()
      .describe(
        'The ID of the message. Note that if two messages have the same ID and response index, they are the same message even if the content differs. Prefer the newest message.',
      ),
    creator: z
      .union([z.lazy(() => userBasicApiResponseSchema), z.null()])
      .describe('The user who created the message. Note that this is only available for user messages.')
      .optional(),
    response_index: z
      .number()
      .int()
      .default(0)
      .describe('The index of the response. This is non-zero if a single agent message (one ID) has multiple responses.'),
    chat_id: z.string().describe('The ID of the chat.'),
    render_type: z
      .lazy(() => renderTypeSchema)
      .default('STANDARD')
      .describe('The type of rendering to use for the message.'),
    signal_type: z
      .union([z.lazy(() => signalTypeApiSchema), z.null()])
      .describe('The type of signal message. If this is None, then the message is not a signal message.')
      .optional(),
    sending_agent: z.string().describe('The agent that sent the message.'),
    receiving_agent: z.union([z.string(), z.null()]).describe('The agent that received the message.').optional(),
    timestamp: z.string().datetime().describe('The time the message was sent.'),
    markdown: z.union([z.string(), z.null()]).describe('The markdown content of the message.').optional(),
    questions: z
      .union([z.array(z.lazy(() => streamedOutputQuestionApiSchema)), z.null()])
      .describe('A list of suggested questions.')
      .optional(),
    rephrased_user_question: z
      .union([z.string(), z.null()])
      .describe('The rephrased user question. Only available for user questions and used for feedback chat.')
      .optional(),
    sql: z.union([z.string(), z.null()]).describe('The SQL content of the message.').optional(),
    embedded_viz_url: z.union([z.string(), z.null()]).describe('The URL of an embedded visualization/dashboard.').optional(),
    vega_spec: z.union([z.string(), z.null()]).describe('The Vega chart specification.').optional(),
    message_table_id: z
      .union([z.string(), z.null()])
      .describe(
        'The ID of the table containing data associated with the message. The presence of this field indicates that the message contains a table.\n\nNote that this field is not actually used in the frontend, and the message ID should be used when retrieving the table data. This field will be removed in the future.',
      )
      .optional(),
    is_positive_feedback: z.union([z.boolean(), z.null()]).describe('User provided thumbs up or down feedback.').optional(),
    is_positive_admin_feedback: z.union([z.boolean(), z.null()]).describe('Admin provided thumbs up or down feedback.').optional(),
    feedback_chat_id: z.union([z.string(), z.null()]).describe('The feedback chat id associated with this message').optional(),
    is_last_user_message_for_feedback_chat: z
      .boolean()
      .default(false)
      .describe('Whether the (cloned) message is the last user message from the original user chat.'),
    is_admin_feedback_incorporated: z.union([z.boolean(), z.null()]).describe('Whether the admin feedback is incorporated into the system.').optional(),
  })
  .describe(
    'The API response corresponding to a message.\n\nThe intent of this class is to provide a unified message format for clients.\nTherefore every response can contain a number of fields and it is up to the client\nto render them accordingly.',
  )