// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/runRegressionTestSchema.ts"
);
import.meta.hot.lastModified = "1737759760438.0251";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { m2MTokenCredsSchema } from './m2MTokenCredsSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const runRegressionTestPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const runRegressionTestQueryParamsSchema = z
  .object({
    max_eval_messages: z.union([z.number().int(), z.null()]).optional(),
    delete_new_chats: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const runRegressionTest200Schema = z.lazy(() => taskSchema)

/**
 * @description Validation Error
 */
export const runRegressionTest422Schema = z.lazy(() => httpValidationErrorSchema)

export const runRegressionTestMutationRequestSchema = z.union([z.lazy(() => m2MTokenCredsSchema), z.null()])

export const runRegressionTestMutationResponseSchema = z.lazy(() => runRegressionTest200Schema)