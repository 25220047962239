// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createTableFromModeSchema.ts"
);
import.meta.hot.lastModified = "1737935768070.745";
}
// REMIX HMR END

// @ts-nocheck
import { createTableFromReportReturnInfoSchema } from './createTableFromReportReturnInfoSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const createTableFromModePathParamsSchema = z.object({
  account_name: z.string(),
})

export const createTableFromModeQueryParamsSchema = z.object({
  report_id: z.string(),
  table_database: z.string(),
  table_schema: z.string(),
  table_name: z.string(),
  create_table: z.boolean().default(true),
  connection_id: z.string(),
  mode_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const createTableFromMode200Schema = z.lazy(() => createTableFromReportReturnInfoSchema)

/**
 * @description Validation Error
 */
export const createTableFromMode422Schema = z.lazy(() => httpValidationErrorSchema)

export const createTableFromModeMutationResponseSchema = z.lazy(() => createTableFromMode200Schema)