// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/searchZenDimensionValueSchema.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const searchZenDimensionValueQueryParamsSchema = z.object({
  value: z.string(),
  limit: z.number().int().default(10),
  explain_analyze: z.boolean().default(false),
})

/**
 * @description Successful Response
 */
export const searchZenDimensionValue200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const searchZenDimensionValue422Schema = z.lazy(() => httpValidationErrorSchema)

export const searchZenDimensionValueQueryResponseSchema = z.lazy(() => searchZenDimensionValue200Schema)