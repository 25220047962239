// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/PublishStateFilterOption.ts"
);
import.meta.hot.lastModified = "1737935768218.7441";
}
// REMIX HMR END

export enum PublishStateFilterOption {
  'only_verified' = 'only_verified',
  'not_deprecated' = 'not_deprecated',
  'only_validatable' = 'only_validatable',
  'all' = 'all',
}