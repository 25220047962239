// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tableOrViewInfoSchema.ts"
);
import.meta.hot.lastModified = "1737935766766.7522";
}
// REMIX HMR END

// @ts-nocheck
import { conflictStatusSchema } from './conflictStatusSchema'
import { z } from 'zod'

/**
 * @description Properties to return to client.
 */
export const tableOrViewInfoSchema = z
  .object({
    name: z.string(),
    data_source_id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    sql: z.union([z.string(), z.null()]).optional(),
    db: z.union([z.string(), z.null()]).optional(),
    db_schema: z.union([z.string(), z.null()]).optional(),
    table: z.union([z.string(), z.null()]).optional(),
    index: z.number().int().default(0),
    status: z.lazy(() => conflictStatusSchema),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
  })
  .describe('Properties to return to client.')