// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateDimensionsStateSchema.ts"
);
import.meta.hot.lastModified = "1737935768446.743";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionDetailSchema } from './dimensionDetailSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { stateUpdateWithIdSchema } from './stateUpdateWithIdSchema'
import { z } from 'zod'

export const updateDimensionsStatePathParamsSchema = z.object({
  account_name: z.string(),
})

export const updateDimensionsStateQueryParamsSchema = z
  .object({
    validate_transition: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const updateDimensionsState200Schema = z.array(z.lazy(() => dimensionDetailSchema))

/**
 * @description Validation Error
 */
export const updateDimensionsState422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateDimensionsStateMutationRequestSchema = z.array(z.lazy(() => stateUpdateWithIdSchema))

export const updateDimensionsStateMutationResponseSchema = z.lazy(() => updateDimensionsState200Schema)