// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/namedFilterOptionBaseSchema.ts"
);
import.meta.hot.lastModified = "1737759761259.021";
}
// REMIX HMR END

// @ts-nocheck
import { combinationSchema } from './combinationSchema'
import { filterOriginSchema } from './filterOriginSchema'
import { namedFilterOptionConditionBaseSchema } from './namedFilterOptionConditionBaseSchema'
import { z } from 'zod'

/**
 * @description NamedFilterOption Base Schema.
 */
export const namedFilterOptionBaseSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    query_id: z.union([z.string(), z.null()]).optional(),
    name: z.string(),
    combination: z.lazy(() => combinationSchema).default('ALL'),
    description: z.union([z.string(), z.null()]).optional(),
    filter_origin: z.lazy(() => filterOriginSchema).default('NAMED_FILTER'),
    is_enabled: z.boolean().default(true),
    field_type: z.enum(['dimension']).default('dimension'),
    is_result_filter: z.boolean().default(false),
    conditions: z.array(z.lazy(() => namedFilterOptionConditionBaseSchema)),
    named_filter_id: z.union([z.string(), z.null()]).optional(),
  })
  .describe('NamedFilterOption Base Schema.')