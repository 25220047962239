// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readSnowflakeConnectionRolesSchema.ts"
);
import.meta.hot.lastModified = "1737759762294.0156";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readSnowflakeConnectionRolesPathParamsSchema = z.object({
  account_name: z.string(),
  snowflake_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const readSnowflakeConnectionRoles200Schema = z.array(z.string())

/**
 * @description Validation Error
 */
export const readSnowflakeConnectionRoles422Schema = z.lazy(() => httpValidationErrorSchema)

export const readSnowflakeConnectionRolesQueryResponseSchema = z.lazy(() => readSnowflakeConnectionRoles200Schema)