// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateTableFromMode.ts"
);
import.meta.hot.lastModified = "1737935767879.746";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateTableFromModeMutationResponse,
  CreateTableFromModePathParams,
  CreateTableFromModeQueryParams,
  CreateTableFromMode422,
} from '../types/CreateTableFromMode'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createTableFromModeMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/create_table_from_mode' }] as const

export type CreateTableFromModeMutationKey = ReturnType<typeof createTableFromModeMutationKey>

/**
 * @description Create a table from a mode report.The database, schema, and table name indicate the path at which the table will be created.
 * @summary Create Table From Mode
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/create_table_from_mode}
 */
async function createTableFromMode(
  { accountName, params }: { accountName: CreateTableFromModePathParams['account_name']; params: CreateTableFromModeQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<CreateTableFromModeMutationResponse, CreateTableFromMode422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/create_table_from_mode`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Create a table from a mode report.The database, schema, and table name indicate the path at which the table will be created.
 * @summary Create Table From Mode
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/create_table_from_mode}
 */
export function useCreateTableFromMode(
  options: {
    mutation?: UseMutationOptions<
      CreateTableFromModeMutationResponse,
      CreateTableFromMode422,
      { accountName: CreateTableFromModePathParams['account_name']; params: CreateTableFromModeQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createTableFromModeMutationKey()

  return useMutation<
    CreateTableFromModeMutationResponse,
    CreateTableFromMode422,
    { accountName: CreateTableFromModePathParams['account_name']; params: CreateTableFromModeQueryParams }
  >({
    mutationFn: async ({ accountName, params }) => {
      return createTableFromMode({ accountName, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}