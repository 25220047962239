// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createDimensionSchema.ts"
);
import.meta.hot.lastModified = "1737759759807.0283";
}
// REMIX HMR END

// @ts-nocheck
import { bodyCreateDimensionApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDimensionsPostSchema } from './bodyCreateDimensionApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDimensionsPostSchema'
import { dimensionDetailSchema } from './dimensionDetailSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const createDimensionPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const createDimension201Schema = z.union([z.lazy(() => dimensionDetailSchema), z.null()])

/**
 * @description Validation Error
 */
export const createDimension422Schema = z.lazy(() => httpValidationErrorSchema)

export const createDimensionMutationRequestSchema = z.lazy(
  () => bodyCreateDimensionApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDimensionsPostSchema,
)

export const createDimensionMutationResponseSchema = z.lazy(() => createDimension201Schema)