// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadTeradataConnectionDatabases.ts"
);
import.meta.hot.lastModified = "1737935769644.7363";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadTeradataConnectionDatabasesQueryResponse,
  ReadTeradataConnectionDatabasesPathParams,
  ReadTeradataConnectionDatabases422,
} from '../types/ReadTeradataConnectionDatabases'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readTeradataConnectionDatabasesQueryKey = ({
  accountName,
  teradataConnectionId,
}: {
  accountName: ReadTeradataConnectionDatabasesPathParams['account_name']
  teradataConnectionId: ReadTeradataConnectionDatabasesPathParams['teradata_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id/databases',
      params: { accountName: accountName, teradataConnectionId: teradataConnectionId },
    },
  ] as const

export type ReadTeradataConnectionDatabasesQueryKey = ReturnType<typeof readTeradataConnectionDatabasesQueryKey>

/**
 * @description Read Teradata databases.Note: Because Teradata has only a single level of qualification for tables,we return a hard-coded list here for consistency with the other connectors.
 * @summary Read Teradata Connection Databases
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id/databases}
 */
async function readTeradataConnectionDatabases(
  {
    accountName,
    teradataConnectionId,
  }: {
    accountName: ReadTeradataConnectionDatabasesPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionDatabasesPathParams['teradata_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadTeradataConnectionDatabasesQueryResponse, ReadTeradataConnectionDatabases422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/teradata/${teradataConnectionId}/databases`,
    ...config,
  })
  return res.data
}

export function readTeradataConnectionDatabasesQueryOptions(
  {
    accountName,
    teradataConnectionId,
  }: {
    accountName: ReadTeradataConnectionDatabasesPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionDatabasesPathParams['teradata_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readTeradataConnectionDatabasesQueryKey({ accountName, teradataConnectionId })
  return queryOptions({
    enabled: !!(accountName && teradataConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readTeradataConnectionDatabases({ accountName, teradataConnectionId }, config)
    },
  })
}

/**
 * @description Read Teradata databases.Note: Because Teradata has only a single level of qualification for tables,we return a hard-coded list here for consistency with the other connectors.
 * @summary Read Teradata Connection Databases
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id/databases}
 */
export function useReadTeradataConnectionDatabases<
  TData = ReadTeradataConnectionDatabasesQueryResponse,
  TQueryData = ReadTeradataConnectionDatabasesQueryResponse,
  TQueryKey extends QueryKey = ReadTeradataConnectionDatabasesQueryKey,
>(
  {
    accountName,
    teradataConnectionId,
  }: {
    accountName: ReadTeradataConnectionDatabasesPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionDatabasesPathParams['teradata_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadTeradataConnectionDatabasesQueryResponse, ReadTeradataConnectionDatabases422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readTeradataConnectionDatabasesQueryKey({ accountName, teradataConnectionId })

  const query = useQuery({
    ...(readTeradataConnectionDatabasesQueryOptions({ accountName, teradataConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadTeradataConnectionDatabases422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}