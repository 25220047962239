// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/Dialect.ts"
);
import.meta.hot.lastModified = "1737759746469.0972";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * SQLGlot dialects.
 *
 * Ref: https://github.com/tobymao/sqlglot/blob/main/sqlglot/dialects/__init__.py
 */
export enum Dialect {
  BIGQUERY = 'bigquery',
  DATABRICKS = 'databricks',
  CASESENSITIVEBIGQUERY = 'casesensitivebigquery',
  CASESENSITIVEDATABRICKS = 'casesensitivedatabricks',
  CASESENSITIVEREDSHIFT = 'casesensitiveredshift',
  POSTGRES = 'postgres',
  REDSHIFT = 'redshift',
  SNOWFLAKE = 'snowflake',
  TERADATA = 'teradata',
  UNKNOWN = 'unknown',
}
