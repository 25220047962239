// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/SnowflakeService.ts"
);
import.meta.hot.lastModified = "1737759746338.098";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SnowflakeConnection } from '../models/SnowflakeConnection';
import type { SnowflakeConnectionAPICreate } from '../models/SnowflakeConnectionAPICreate';
import type { SnowflakeConnectionPage } from '../models/SnowflakeConnectionPage';
import type { SnowflakeConnectionUpdate } from '../models/SnowflakeConnectionUpdate';
import type { SnowflakeConnectionWithSecrets } from '../models/SnowflakeConnectionWithSecrets';
import type { TableColumn } from '../models/TableColumn';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SnowflakeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Snowflake Connection
   * Create new Snowflake connection.
   * @param accountName
   * @param requestBody
   * @param validate
   * @param sessionId
   * @returns SnowflakeConnection Successful Response
   * @throws ApiError
   */
  public createSnowflakeConnection(
    accountName: string,
    requestBody: SnowflakeConnectionAPICreate,
    validate: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<SnowflakeConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'validate': validate,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * @deprecated
   * Read Snowflake Connections
   * Read Snowflake connections.
   *
   * This endpoint is deprecated. Use the /connections endpoint to get all connections instead.
   * This endpoint will be removed in a coming version.
   * @param accountName
   * @param skip
   * @param limit
   * @param sessionId
   * @returns SnowflakeConnectionPage Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnections(
    accountName: string,
    skip?: number,
    limit: number = 100,
    sessionId?: (string | null),
  ): CancelablePromise<SnowflakeConnectionPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'skip': skip,
        'limit': limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Validate Snowflake Connection
   * Validate Snowflake connection.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns SnowflakeConnection Successful Response
   * @throws ApiError
   */
  public validateSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<SnowflakeConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/validate',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Snowflake Connection
   * Read Snowflake connection.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns SnowflakeConnection Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<SnowflakeConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Snowflake Connection
   * Update Snowflake connection.
   *
   * Note that all fields for a valid Snowflake connection are required.
   * @param accountName
   * @param snowflakeConnectionId
   * @param requestBody
   * @param sessionId
   * @returns SnowflakeConnection Successful Response
   * @throws ApiError
   */
  public updateSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    requestBody: SnowflakeConnectionUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<SnowflakeConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Snowflake Connection
   * Delete Snowflake connection.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns SnowflakeConnection Successful Response
   * @throws ApiError
   */
  public deleteSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<SnowflakeConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Full Snowflake Connection
   * Read Snowflake connection with secrets.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns SnowflakeConnectionWithSecrets Successful Response
   * @throws ApiError
   */
  public readFullSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<SnowflakeConnectionWithSecrets> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/full_connection_info',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Snowflake Connection Warehouses
   * Read Snowflake warehouses.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionWarehouses(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/warehouses',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Snowflake Connection Roles
   * Read Snowflake roles.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionRoles(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/roles',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Snowflake Connection Region
   * Read Snowflake regions.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionRegion(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/region',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Snowflake Connection Databases
   * Read Snowflake databases.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionDatabases(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/databases',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Snowflake Connection Schemas
   * Read Snowflake schemas.
   * @param accountName
   * @param snowflakeConnectionId
   * @param database
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionSchemas(
    accountName: string,
    snowflakeConnectionId: string,
    database?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/schemas',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'database': database,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Snowflake Connection Tables
   * Read Snowflake tables.
   * @param accountName
   * @param snowflakeConnectionId
   * @param database
   * @param schema
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionTables(
    accountName: string,
    snowflakeConnectionId: string,
    database: string,
    schema?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/tables',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'database': database,
        'schema': schema,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Snowflake Connection Columns
   * Read Snowflake table columns.
   * @param accountName
   * @param snowflakeConnectionId
   * @param database
   * @param schema
   * @param table
   * @param sessionId
   * @returns TableColumn Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionColumns(
    accountName: string,
    snowflakeConnectionId: string,
    database: string,
    schema: string,
    table: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<TableColumn>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/columns',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'database': database,
        'schema': schema,
        'table': table,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Test Snowflake Connection
   * Test Snowflake connection.
   *
   * Throws an HTTP error if the connection is broken.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public testSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/test',
      path: {
        'account_name': accountName,
        'snowflake_connection_id': snowflakeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Refresh Snowflake Connection
   * Create external functions for all snowflake connections.
   * @param accountName
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public refreshSnowflakeConnection(
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/refresh',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
