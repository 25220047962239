// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ReceivingAgentFilter.ts"
);
import.meta.hot.lastModified = "1737935754016.8223";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Receviing Agent filter.
 */
export enum ReceivingAgentFilter {
  ALL = 'all',
  SQL_QUERY_AGENT = 'sql_query_agent',
}
