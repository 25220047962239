// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/accountSettingsSchema.ts"
);
import.meta.hot.lastModified = "1737935766766.7522";
}
// REMIX HMR END

// @ts-nocheck
import { accountSettingUnionBoolIntStrSchema } from './accountSettingUnionBoolIntStrSchema'
import { z } from 'zod'

/**
 * @description Object containing all account settings.\n\nThis object overrides the seeded account setting values with the values in the database.\nIt combines the default settings, the account settings, and the superuser account settings\nusing the following rules in order of precendence:\n1. If the setting is editable by the superuser and the superuser has set the value, then\n    the superuser value is used.\n2. If the setting is editable by the admin and the admin has set the value, then the admin\n    value is used.\n3. Otherwise, the default value is used.
 */
export const accountSettingsSchema = z
  .object({
    test_setting: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    test_int_setting: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    setting_admin_and_superuser_can_edit: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    setting_admin_can_edit: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    setting_superuser_can_edit: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    setting_not_sent_to_client: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    fuzzy_filter_cardinality_threshold: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    dataset_update_existing_dimensions_metrics: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    dataset_include_metrics: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    dataset_initial_dimension_metric_state: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    dataset_num_rows_to_profile: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    dataset_top_k_profile_values_for_retrieval: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    is_widget_default: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    use_onboarding_agent: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    use_predictive_model_agent: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    use_vega_chart_agent: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    use_opensearch_agents: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    add_dms_from_reports: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    use_knowledge_sql: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    grouping_strategy: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    hide_bumblebee_verification_ui: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    hide_v1_ui: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
    show_try_widget_button: z.lazy(() => accountSettingUnionBoolIntStrSchema).default({}),
  })
  .describe(
    'Object containing all account settings.\n\nThis object overrides the seeded account setting values with the values in the database.\nIt combines the default settings, the account settings, and the superuser account settings\nusing the following rules in order of precendence:\n1. If the setting is editable by the superuser and the superuser has set the value, then\n    the superuser value is used.\n2. If the setting is editable by the admin and the admin has set the value, then the admin\n    value is used.\n3. Otherwise, the default value is used.',
  )