// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/accountSchema.ts"
);
import.meta.hot.lastModified = "1737759757712.0393";
}
// REMIX HMR END

// @ts-nocheck
import { domainSchema } from './domainSchema'
import { userBasicSchema } from './userBasicSchema'
import { z } from 'zod'

/**
 * @description Additional properties to return via API.
 */
export const accountSchema = z
  .object({
    name: z.string().min(1),
    display_name: z.string().min(1),
    description: z.union([z.string(), z.null()]).optional(),
    avatar: z.union([z.string().url(), z.null()]).optional(),
    colors: z.union([z.array(z.string()).min(10), z.null()]).optional(),
    id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    allowed_domains: z.array(z.lazy(() => domainSchema)),
    num_users: z.union([z.number().int(), z.null()]).optional(),
    admin_users: z.union([z.array(z.lazy(() => userBasicSchema)), z.null()]).optional(),
  })
  .describe('Additional properties to return via API.')