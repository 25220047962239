// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getChatMessagesSchema.ts"
);
import.meta.hot.lastModified = "1737935767010.7507";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { messageApiResponsePageSchema } from './messageApiResponsePageSchema'
import { z } from 'zod'

export const getChatMessagesPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  chat_id: z.string().describe('The ID of the chat.'),
})

export const getChatMessagesQueryParamsSchema = z
  .object({
    limit: z.number().int().min(1).max(1000).default(20).describe('The maximum number of messages to include in the response.'),
    offset: z
      .number()
      .int()
      .min(0)
      .default(0)
      .describe("The messages to skip. The response will not include indexes greater than the offset. Equivalent of 'skip' in database queries."),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getChatMessages200Schema = z.lazy(() => messageApiResponsePageSchema)

/**
 * @description Validation Error
 */
export const getChatMessages422Schema = z.lazy(() => httpValidationErrorSchema)

export const getChatMessagesQueryResponseSchema = z.lazy(() => getChatMessages200Schema)