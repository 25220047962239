// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadTeradataConnection.ts"
);
import.meta.hot.lastModified = "1737759761269.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadTeradataConnectionQueryResponse, ReadTeradataConnectionPathParams, ReadTeradataConnection422 } from '../types/ReadTeradataConnection'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readTeradataConnectionQueryKey = ({
  accountName,
  teradataConnectionId,
}: {
  accountName: ReadTeradataConnectionPathParams['account_name']
  teradataConnectionId: ReadTeradataConnectionPathParams['teradata_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id',
      params: { accountName: accountName, teradataConnectionId: teradataConnectionId },
    },
  ] as const

export type ReadTeradataConnectionQueryKey = ReturnType<typeof readTeradataConnectionQueryKey>

/**
 * @description Read teradata connection.
 * @summary Read Teradata Connection
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id}
 */
async function readTeradataConnection(
  {
    accountName,
    teradataConnectionId,
  }: { accountName: ReadTeradataConnectionPathParams['account_name']; teradataConnectionId: ReadTeradataConnectionPathParams['teradata_connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadTeradataConnectionQueryResponse, ReadTeradataConnection422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/teradata/${teradataConnectionId}`,
    ...config,
  })
  return res.data
}

export function readTeradataConnectionQueryOptions(
  {
    accountName,
    teradataConnectionId,
  }: { accountName: ReadTeradataConnectionPathParams['account_name']; teradataConnectionId: ReadTeradataConnectionPathParams['teradata_connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readTeradataConnectionQueryKey({ accountName, teradataConnectionId })
  return queryOptions({
    enabled: !!(accountName && teradataConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readTeradataConnection({ accountName, teradataConnectionId }, config)
    },
  })
}

/**
 * @description Read teradata connection.
 * @summary Read Teradata Connection
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id}
 */
export function useReadTeradataConnection<
  TData = ReadTeradataConnectionQueryResponse,
  TQueryData = ReadTeradataConnectionQueryResponse,
  TQueryKey extends QueryKey = ReadTeradataConnectionQueryKey,
>(
  {
    accountName,
    teradataConnectionId,
  }: { accountName: ReadTeradataConnectionPathParams['account_name']; teradataConnectionId: ReadTeradataConnectionPathParams['teradata_connection_id'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadTeradataConnectionQueryResponse, ReadTeradataConnection422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readTeradataConnectionQueryKey({ accountName, teradataConnectionId })

  const query = useQuery({
    ...(readTeradataConnectionQueryOptions({ accountName, teradataConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadTeradataConnection422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}