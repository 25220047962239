// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetChat.ts"
);
import.meta.hot.lastModified = "1737935764870.7627";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetChatQueryResponse, GetChatPathParams, GetChat422 } from '../types/GetChat'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getChatQueryKey = ({ accountName, chatId }: { accountName: GetChatPathParams['account_name']; chatId: GetChatPathParams['chat_id'] }) =>
  [{ url: '/api/v3/orgs/:account_name/chat/:chat_id', params: { accountName: accountName, chatId: chatId } }] as const

export type GetChatQueryKey = ReturnType<typeof getChatQueryKey>

/**
 * @description Read a single chat.Note that this does NOT include the messages of the chat. See the getchat messages endpoint (GET api/v3/orgs/{account_name}/chats/{chat_id}/messages/) for that.
 * @summary Get Chat
 * {@link /api/v3/orgs/:account_name/chat/:chat_id}
 */
async function getChat(
  { accountName, chatId }: { accountName: GetChatPathParams['account_name']; chatId: GetChatPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetChatQueryResponse, GetChat422, unknown>({ method: 'GET', url: `/api/v3/orgs/${accountName}/chat/${chatId}`, ...config })
  return res.data
}

export function getChatQueryOptions(
  { accountName, chatId }: { accountName: GetChatPathParams['account_name']; chatId: GetChatPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getChatQueryKey({ accountName, chatId })
  return queryOptions({
    enabled: !!(accountName && chatId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getChat({ accountName, chatId }, config)
    },
  })
}

/**
 * @description Read a single chat.Note that this does NOT include the messages of the chat. See the getchat messages endpoint (GET api/v3/orgs/{account_name}/chats/{chat_id}/messages/) for that.
 * @summary Get Chat
 * {@link /api/v3/orgs/:account_name/chat/:chat_id}
 */
export function useGetChat<TData = GetChatQueryResponse, TQueryData = GetChatQueryResponse, TQueryKey extends QueryKey = GetChatQueryKey>(
  { accountName, chatId }: { accountName: GetChatPathParams['account_name']; chatId: GetChatPathParams['chat_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetChatQueryResponse, GetChat422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getChatQueryKey({ accountName, chatId })

  const query = useQuery({
    ...(getChatQueryOptions({ accountName, chatId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetChat422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}