// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/RenderType.ts"
);
import.meta.hot.lastModified = "1737935764858.7627";
}
// REMIX HMR END

export enum RenderType {
  'NONE' = 'NONE',
  'TEMPORARY' = 'TEMPORARY',
  'SOFT' = 'SOFT',
  'STANDARD' = 'STANDARD',
}