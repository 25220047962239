// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadEnterprises.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadEnterprisesQueryResponse, ReadEnterprisesQueryParams, ReadEnterprises422 } from '../types/ReadEnterprises'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readEnterprisesQueryKey = (params?: ReadEnterprisesQueryParams) => [{ url: '/api/v1/enterprises/' }, ...(params ? [params] : [])] as const

export type ReadEnterprisesQueryKey = ReturnType<typeof readEnterprisesQueryKey>

/**
 * @description Retrieve enterprises.
 * @summary Read Enterprises
 * {@link /api/v1/enterprises/}
 */
async function readEnterprises({ params }: { params?: ReadEnterprisesQueryParams }, config: Partial<RequestConfig> = {}) {
  const res = await client<ReadEnterprisesQueryResponse, ReadEnterprises422, unknown>({ method: 'GET', url: `/api/v1/enterprises/`, params, ...config })
  return res.data
}

export function readEnterprisesQueryOptions({ params }: { params?: ReadEnterprisesQueryParams }, config: Partial<RequestConfig> = {}) {
  const queryKey = readEnterprisesQueryKey(params)
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readEnterprises({ params }, config)
    },
  })
}

/**
 * @description Retrieve enterprises.
 * @summary Read Enterprises
 * {@link /api/v1/enterprises/}
 */
export function useReadEnterprises<
  TData = ReadEnterprisesQueryResponse,
  TQueryData = ReadEnterprisesQueryResponse,
  TQueryKey extends QueryKey = ReadEnterprisesQueryKey,
>(
  { params }: { params?: ReadEnterprisesQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadEnterprisesQueryResponse, ReadEnterprises422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readEnterprisesQueryKey(params)

  const query = useQuery({
    ...(readEnterprisesQueryOptions({ params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadEnterprises422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}