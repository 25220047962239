// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tableOrViewInfoUpdateSchema.ts"
);
import.meta.hot.lastModified = "1737759761259.021";
}
// REMIX HMR END

// @ts-nocheck
import { conflictStatusSchema } from './conflictStatusSchema'
import { z } from 'zod'

/**
 * @description Properties to receive on item update.
 */
export const tableOrViewInfoUpdateSchema = z
  .object({
    name: z.union([z.string(), z.null()]).optional(),
    data_source_id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    sql: z.union([z.string(), z.null()]).optional(),
    db: z.union([z.string(), z.null()]).optional(),
    db_schema: z.union([z.string(), z.null()]).optional(),
    table: z.union([z.string(), z.null()]).optional(),
    index: z.number().int().default(0),
    status: z.lazy(() => conflictStatusSchema),
  })
  .describe('Properties to receive on item update.')