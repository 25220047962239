// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadCellFilter.ts"
);
import.meta.hot.lastModified = "1737935766469.754";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadCellFilterQueryResponse, ReadCellFilterPathParams, ReadCellFilter422 } from '../types/ReadCellFilter'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readCellFilterQueryKey = ({
  accountName,
  conversationId,
  cellId,
  filterId,
}: {
  accountName: ReadCellFilterPathParams['account_name']
  conversationId: ReadCellFilterPathParams['conversation_id']
  cellId: ReadCellFilterPathParams['cell_id']
  filterId: ReadCellFilterPathParams['filter_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/filters/:filter_id',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId, filterId: filterId },
    },
  ] as const

export type ReadCellFilterQueryKey = ReturnType<typeof readCellFilterQueryKey>

/**
 * @description Read the specified filter.
 * @summary Read Cell Filter
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/filters/:filter_id}
 */
async function readCellFilter(
  {
    accountName,
    conversationId,
    cellId,
    filterId,
  }: {
    accountName: ReadCellFilterPathParams['account_name']
    conversationId: ReadCellFilterPathParams['conversation_id']
    cellId: ReadCellFilterPathParams['cell_id']
    filterId: ReadCellFilterPathParams['filter_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadCellFilterQueryResponse, ReadCellFilter422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/filters/${filterId}`,
    ...config,
  })
  return res.data
}

export function readCellFilterQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
    filterId,
  }: {
    accountName: ReadCellFilterPathParams['account_name']
    conversationId: ReadCellFilterPathParams['conversation_id']
    cellId: ReadCellFilterPathParams['cell_id']
    filterId: ReadCellFilterPathParams['filter_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readCellFilterQueryKey({ accountName, conversationId, cellId, filterId })
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId && filterId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readCellFilter({ accountName, conversationId, cellId, filterId }, config)
    },
  })
}

/**
 * @description Read the specified filter.
 * @summary Read Cell Filter
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/filters/:filter_id}
 */
export function useReadCellFilter<
  TData = ReadCellFilterQueryResponse,
  TQueryData = ReadCellFilterQueryResponse,
  TQueryKey extends QueryKey = ReadCellFilterQueryKey,
>(
  {
    accountName,
    conversationId,
    cellId,
    filterId,
  }: {
    accountName: ReadCellFilterPathParams['account_name']
    conversationId: ReadCellFilterPathParams['conversation_id']
    cellId: ReadCellFilterPathParams['cell_id']
    filterId: ReadCellFilterPathParams['filter_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadCellFilterQueryResponse, ReadCellFilter422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readCellFilterQueryKey({ accountName, conversationId, cellId, filterId })

  const query = useQuery({
    ...(readCellFilterQueryOptions({ accountName, conversationId, cellId, filterId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadCellFilter422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}