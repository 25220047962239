// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCallback.ts"
);
import.meta.hot.lastModified = "1737759757712.0393";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CallbackQueryResponse } from '../types/Callback'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const callbackQueryKey = () => [{ url: '/api/v1/auth/oauth' }] as const

export type CallbackQueryKey = ReturnType<typeof callbackQueryKey>

/**
 * @description Callback handler.Do not call this endpoint directly. It is used by the OAuth2.0 authorization code flow with PKCE.This will redirect the user to the redirect_uri specified in the original request. If the authis successful, then a session cookie will be set. If the auth is unsuccessful, then an errorparameter will be set in the redirect_uri query params.e.g. app.numbersstation.ai/login?error=An%20error%20occurred
 * @summary Callback
 * {@link /api/v1/auth/oauth}
 */
async function callback(config: Partial<RequestConfig> = {}) {
  const res = await client<CallbackQueryResponse, Error, unknown>({ method: 'GET', url: `/api/v1/auth/oauth`, ...config })
  return res.data
}

export function callbackQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = callbackQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return callback(config)
    },
  })
}

/**
 * @description Callback handler.Do not call this endpoint directly. It is used by the OAuth2.0 authorization code flow with PKCE.This will redirect the user to the redirect_uri specified in the original request. If the authis successful, then a session cookie will be set. If the auth is unsuccessful, then an errorparameter will be set in the redirect_uri query params.e.g. app.numbersstation.ai/login?error=An%20error%20occurred
 * @summary Callback
 * {@link /api/v1/auth/oauth}
 */
export function useCallback<TData = CallbackQueryResponse, TQueryData = CallbackQueryResponse, TQueryKey extends QueryKey = CallbackQueryKey>(
  options: {
    query?: Partial<QueryObserverOptions<CallbackQueryResponse, Error, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? callbackQueryKey()

  const query = useQuery({
    ...(callbackQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, Error> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}