// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/runEvalChatsSchema.ts"
);
import.meta.hot.lastModified = "1737759758961.0327";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const runEvalChatsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const runEvalChatsQueryParamsSchema = z
  .object({
    max_eval_chats: z.union([z.number().int(), z.null()]).optional(),
    delete_new_chat: z.boolean().default(true),
    run_group_chat: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const runEvalChats200Schema = z.lazy(() => taskSchema)

/**
 * @description Validation Error
 */
export const runEvalChats422Schema = z.lazy(() => httpValidationErrorSchema)

export const runEvalChatsMutationResponseSchema = z.lazy(() => runEvalChats200Schema)