// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bumblebeeDatasetSchema.ts"
);
import.meta.hot.lastModified = "1737759760028.0273";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellBasicSchema } from './bumblebeeCellBasicSchema'
import { chatInDbSchema } from './chatInDbSchema'
import { connectionLookupSchema } from './connectionLookupSchema'
import { conversationBasicSchema } from './conversationBasicSchema'
import { feedbackSchema } from './feedbackSchema'
import { knowledgeSqlInDbSchema } from './knowledgeSqlInDbSchema'
import { namedFilterBaseSchema } from './namedFilterBaseSchema'
import { semanticQuerySchema } from './semanticQuerySchema'
import { zenStarSchemaDataSourceBaseSchema } from './zenStarSchemaDataSourceBaseSchema'
import { z } from 'zod'

/**
 * @description BumblebeeDataset class.
 */
export const bumblebeeDatasetSchema = z
  .object({
    ssds: z.lazy(() => zenStarSchemaDataSourceBaseSchema),
    named_filters: z.array(z.lazy(() => namedFilterBaseSchema)),
    connection: z.lazy(() => connectionLookupSchema),
    semantic_queries: z.union([z.array(z.lazy(() => semanticQuerySchema)), z.null()]).optional(),
    conversations: z.union([z.array(z.lazy(() => conversationBasicSchema)), z.null()]).optional(),
    cells: z.union([z.array(z.lazy(() => bumblebeeCellBasicSchema)), z.null()]).optional(),
    feedback: z.union([z.array(z.lazy(() => feedbackSchema)), z.null()]).optional(),
    chats: z.array(z.lazy(() => chatInDbSchema)).optional(),
    knowledge_sqls: z.array(z.lazy(() => knowledgeSqlInDbSchema)).optional(),
  })
  .describe('BumblebeeDataset class.')