// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/GetEntityContext.ts"
);
import.meta.hot.lastModified = "1737759758962.0327";
}
// REMIX HMR END

import type { HttpValidationError } from './HttpValidationError'
import type { KnowledgeLayerContextApi } from './KnowledgeLayerContextApi'

export type GetEntityContextPathParams = {
  /**
   * @description The name of the organization or account.
   * @type string
   */
  account_name: string
}

export enum GetEntityContextQueryParamsEntityType {
  'dimension' = 'dimension',
  'metric' = 'metric',
  'dataset' = 'dataset',
  'tableau' = 'tableau',
  'mode' = 'mode',
}

export type GetEntityContextQueryParams = {
  /**
   * @description Type of the entity
   * @type string
   */
  entity_type: GetEntityContextQueryParamsEntityType
  /**
   * @type string, uuid4
   */
  entity_id: string
}

/**
 * @description The context associated with the entity was fetched successfully.
 */
export type GetEntityContext200 = KnowledgeLayerContextApi

/**
 * @description The entity was not found.
 */
export type GetEntityContext404 = unknown

/**
 * @description Validation Error
 */
export type GetEntityContext422 = HttpValidationError

export type GetEntityContextQueryResponse = GetEntityContext200

export type GetEntityContextQuery = {
  Response: GetEntityContext200
  PathParams: GetEntityContextPathParams
  QueryParams: GetEntityContextQueryParams
  Errors: GetEntityContext404 | GetEntityContext422
}