// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/KnowledgeStateFilter.ts"
);
import.meta.hot.lastModified = "1737935753987.8223";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Knowledge type filter.
 */
export enum KnowledgeStateFilter {
  ALL = 'all',
  DEPRECATED = 'deprecated',
  VERIFIED = 'verified',
  UNSET = 'unset',
  INVALID = 'invalid',
  REVIEW = 'review',
  VALID = 'valid',
  SENSITIVE = 'sensitive',
}
