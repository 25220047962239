// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/senderBasicApiResponseSchema.ts"
);
import.meta.hot.lastModified = "1737935768612.742";
}
// REMIX HMR END

// @ts-nocheck
import { senderTypeSchema } from './senderTypeSchema'
import { z } from 'zod'

/**
 * @description Basic information about a sender from the API\n\nNote that it inherits from UserBasicAPIResponse, so it has the same attributes.\n\nAttributes:\n    id: None if the sender type is AI.\n    sender_type: The type of sender that sent the message.
 */
export const senderBasicApiResponseSchema = z
  .object({
    id: z.union([z.string(), z.null()]),
    name: z.string(),
    avatar: z.union([z.string().url(), z.null()]),
    sender_type: z.lazy(() => senderTypeSchema),
  })
  .describe(
    'Basic information about a sender from the API\n\nNote that it inherits from UserBasicAPIResponse, so it has the same attributes.\n\nAttributes:\n    id: None if the sender type is AI.\n    sender_type: The type of sender that sent the message.',
  )