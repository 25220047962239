// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/copyJobCreateSchema.ts"
);
import.meta.hot.lastModified = "1737759759186.0317";
}
// REMIX HMR END

// @ts-nocheck
import { conversationCellFilterSchema } from './conversationCellFilterSchema'
import { datasetJobTypeSchema } from './datasetJobTypeSchema'
import { tableOrViewInfoCreateSchema } from './tableOrViewInfoCreateSchema'
import { z } from 'zod'

/**
 * @description Properties to receive on item creation.
 */
export const copyJobCreateSchema = z
  .object({
    enterprise_id: z.union([z.string(), z.null()]).optional(),
    type: z.lazy(() => datasetJobTypeSchema).default('COPY'),
    src_account_name: z.union([z.string(), z.null()]).optional(),
    src_ssds_id: z.union([z.string(), z.null()]).optional(),
    dst_connection_id: z.union([z.string(), z.null()]).optional(),
    dst_account_name: z.union([z.string(), z.null()]).optional(),
    dst_ssds_name: z.union([z.string(), z.null()]).optional(),
    conversation_cell_filter: z.lazy(() => conversationCellFilterSchema).default('ALL'),
    overwrite_user_question_for_sensitive_cell: z.boolean().default(false),
    max_eval_messages: z.union([z.number().int(), z.null()]).optional(),
    tables_and_views: z.array(z.lazy(() => tableOrViewInfoCreateSchema)).optional(),
  })
  .describe('Properties to receive on item creation.')