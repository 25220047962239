// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatApiResponsePageSchema.ts"
);
import.meta.hot.lastModified = "1737759760885.023";
}
// REMIX HMR END

// @ts-nocheck
import { chatApiResponseSchema } from './chatApiResponseSchema'
import { z } from 'zod'

/**
 * @description A list of chats used in a paginated response.
 */
export const chatApiResponsePageSchema = z
  .object({
    data: z.array(z.lazy(() => chatApiResponseSchema)).describe('The list of `ChatAPIResponse` objects.'),
    total: z.number().int().describe('The total number of chats. This may be greater than the number of chats returned.'),
  })
  .describe('A list of chats used in a paginated response.')