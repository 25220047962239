// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetStarSchemaDataSourceCommonValues.ts"
);
import.meta.hot.lastModified = "1737759762548.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetStarSchemaDataSourceCommonValuesQueryResponse,
  GetStarSchemaDataSourceCommonValuesPathParams,
  GetStarSchemaDataSourceCommonValues422,
} from '../types/GetStarSchemaDataSourceCommonValues'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getStarSchemaDataSourceCommonValuesQueryKey = ({
  accountName,
  starSchemaDataSourceId,
}: {
  accountName: GetStarSchemaDataSourceCommonValuesPathParams['account_name']
  starSchemaDataSourceId: GetStarSchemaDataSourceCommonValuesPathParams['star_schema_data_source_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/common_values',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
  ] as const

export type GetStarSchemaDataSourceCommonValuesQueryKey = ReturnType<typeof getStarSchemaDataSourceCommonValuesQueryKey>

/**
 * @description Get the common values for an SSDS's columns.Returns:    A dictionary mapping the column name to ColumnValue's. Only    the value attribute on each ColumnValue is meaningful. The    count in each ColumnValue is hard-coded to -1.
 * @summary Get Star Schema Data Source Common Values
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/common_values}
 */
async function getStarSchemaDataSourceCommonValues(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetStarSchemaDataSourceCommonValuesPathParams['account_name']
    starSchemaDataSourceId: GetStarSchemaDataSourceCommonValuesPathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetStarSchemaDataSourceCommonValuesQueryResponse, GetStarSchemaDataSourceCommonValues422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/common_values`,
    ...config,
  })
  return res.data
}

export function getStarSchemaDataSourceCommonValuesQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetStarSchemaDataSourceCommonValuesPathParams['account_name']
    starSchemaDataSourceId: GetStarSchemaDataSourceCommonValuesPathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getStarSchemaDataSourceCommonValuesQueryKey({ accountName, starSchemaDataSourceId })
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getStarSchemaDataSourceCommonValues({ accountName, starSchemaDataSourceId }, config)
    },
  })
}

/**
 * @description Get the common values for an SSDS's columns.Returns:    A dictionary mapping the column name to ColumnValue's. Only    the value attribute on each ColumnValue is meaningful. The    count in each ColumnValue is hard-coded to -1.
 * @summary Get Star Schema Data Source Common Values
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/common_values}
 */
export function useGetStarSchemaDataSourceCommonValues<
  TData = GetStarSchemaDataSourceCommonValuesQueryResponse,
  TQueryData = GetStarSchemaDataSourceCommonValuesQueryResponse,
  TQueryKey extends QueryKey = GetStarSchemaDataSourceCommonValuesQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetStarSchemaDataSourceCommonValuesPathParams['account_name']
    starSchemaDataSourceId: GetStarSchemaDataSourceCommonValuesPathParams['star_schema_data_source_id']
  },
  options: {
    query?: Partial<
      QueryObserverOptions<GetStarSchemaDataSourceCommonValuesQueryResponse, GetStarSchemaDataSourceCommonValues422, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getStarSchemaDataSourceCommonValuesQueryKey({ accountName, starSchemaDataSourceId })

  const query = useQuery({
    ...(getStarSchemaDataSourceCommonValuesQueryOptions({ accountName, starSchemaDataSourceId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetStarSchemaDataSourceCommonValues422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}