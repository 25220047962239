// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/starSchemaDataSourceSortSchema.ts"
);
import.meta.hot.lastModified = "1737759761848.0178";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Sort datasets by.
 */
export const starSchemaDataSourceSortSchema = z.enum(['created_at', 'updated_at', 'name']).describe('Sort datasets by.')