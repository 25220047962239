// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ReceivingAgentFilter.ts"
);
import.meta.hot.lastModified = "1737759760212.0264";
}
// REMIX HMR END

export enum ReceivingAgentFilter {
  'all' = 'all',
  'sql_query_agent' = 'sql_query_agent',
}