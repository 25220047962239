// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/publishStateSchema.ts"
);
import.meta.hot.lastModified = "1737935766026.7563";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description PublishState class.
 */
export const publishStateSchema = z.enum(['New', 'Verified', 'Invalid', 'Deprecated', 'Review']).describe('PublishState class.')