// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/userSchema.ts"
);
import.meta.hot.lastModified = "1737935764663.7637";
}
// REMIX HMR END

// @ts-nocheck
import { accountSchema } from './accountSchema'
import { enterpriseSchema } from './enterpriseSchema'
import { z } from 'zod'

/**
 * @description Additional properties to return via API.
 */
export const userSchema = z
  .object({
    name: z.string(),
    email: z.string().email(),
    avatar: z.union([z.string().url(), z.null()]).optional(),
    is_superuser: z.boolean(),
    id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    accounts: z.array(z.lazy(() => accountSchema)),
    is_admin_for: z.array(z.lazy(() => accountSchema)),
    is_eval_maintainer_for: z.array(z.lazy(() => accountSchema)),
    is_enterprise_admin_for: z.array(z.lazy(() => enterpriseSchema)),
  })
  .describe('Additional properties to return via API.')