// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/useRedirectToLogin.ts"
);
import.meta.hot.lastModified = "1734478474421.1372";
}
// REMIX HMR END

import { useLocation, useNavigate } from '@remix-run/react'
import { useCallback, useEffect } from 'react'

import { useUserMe } from '~/client/hooks/user'

export function useRequireAuth() {
  const { error } = useUserMe()
  const redirectToLogin = useRedirectToLogin()
  useEffect(() => {
    if (error?.status_code === 401) redirectToLogin({ expired: true })
  }, [redirectToLogin, error?.status_code])
}

export function useRedirectToLogin() {
  const location = useLocation()
  const navigate = useNavigate()
  const page = `${location.pathname}${location.search}${location.hash}`
  return useCallback(
    ({
      expired = true,
      noRedirect = false,
      error,
    }: {
      expired?: boolean
      noRedirect?: boolean
      error?: string
    }) => {
      const searchParams = new URLSearchParams()
      if (expired) searchParams.append('expired', 'true')
      if (noRedirect) searchParams.append('noRedirect', 'true')
      if (error) searchParams.append('error', error)
      searchParams.append('redirectTo', page)
      navigate(`/login?${searchParams.toString()}`)
    },
    [page, navigate],
  )
}
