// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadDatabricksConnectionDatabases.ts"
);
import.meta.hot.lastModified = "1737759762545.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadDatabricksConnectionDatabasesQueryResponse,
  ReadDatabricksConnectionDatabasesPathParams,
  ReadDatabricksConnectionDatabases422,
} from '../types/ReadDatabricksConnectionDatabases'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readDatabricksConnectionDatabasesQueryKey = ({
  accountName,
  databricksConnectionId,
}: {
  accountName: ReadDatabricksConnectionDatabasesPathParams['account_name']
  databricksConnectionId: ReadDatabricksConnectionDatabasesPathParams['databricks_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/databases',
      params: { accountName: accountName, databricksConnectionId: databricksConnectionId },
    },
  ] as const

export type ReadDatabricksConnectionDatabasesQueryKey = ReturnType<typeof readDatabricksConnectionDatabasesQueryKey>

/**
 * @description Read Databricks databases.
 * @summary Read Databricks Connection Databases
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/databases}
 */
async function readDatabricksConnectionDatabases(
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: ReadDatabricksConnectionDatabasesPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionDatabasesPathParams['databricks_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadDatabricksConnectionDatabasesQueryResponse, ReadDatabricksConnectionDatabases422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/databricks/${databricksConnectionId}/databases`,
    ...config,
  })
  return res.data
}

export function readDatabricksConnectionDatabasesQueryOptions(
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: ReadDatabricksConnectionDatabasesPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionDatabasesPathParams['databricks_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readDatabricksConnectionDatabasesQueryKey({ accountName, databricksConnectionId })
  return queryOptions({
    enabled: !!(accountName && databricksConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readDatabricksConnectionDatabases({ accountName, databricksConnectionId }, config)
    },
  })
}

/**
 * @description Read Databricks databases.
 * @summary Read Databricks Connection Databases
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/databases}
 */
export function useReadDatabricksConnectionDatabases<
  TData = ReadDatabricksConnectionDatabasesQueryResponse,
  TQueryData = ReadDatabricksConnectionDatabasesQueryResponse,
  TQueryKey extends QueryKey = ReadDatabricksConnectionDatabasesQueryKey,
>(
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: ReadDatabricksConnectionDatabasesPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionDatabasesPathParams['databricks_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadDatabricksConnectionDatabasesQueryResponse, ReadDatabricksConnectionDatabases422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readDatabricksConnectionDatabasesQueryKey({ accountName, databricksConnectionId })

  const query = useQuery({
    ...(readDatabricksConnectionDatabasesQueryOptions({ accountName, databricksConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadDatabricksConnectionDatabases422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}