// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteStarSchemaDataSource.ts"
);
import.meta.hot.lastModified = "1737935769208.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  DeleteStarSchemaDataSourceMutationResponse,
  DeleteStarSchemaDataSourcePathParams,
  DeleteStarSchemaDataSource422,
} from '../types/DeleteStarSchemaDataSource'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteStarSchemaDataSourceMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}' }] as const

export type DeleteStarSchemaDataSourceMutationKey = ReturnType<typeof deleteStarSchemaDataSourceMutationKey>

/**
 * @description Delete Star Schema Data Source.This will delete all associated conversations that end up empty after cells are removed.
 * @summary Delete Star Schema Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id}
 */
async function deleteStarSchemaDataSource(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: DeleteStarSchemaDataSourcePathParams['account_name']
    starSchemaDataSourceId: DeleteStarSchemaDataSourcePathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteStarSchemaDataSourceMutationResponse, DeleteStarSchemaDataSource422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete Star Schema Data Source.This will delete all associated conversations that end up empty after cells are removed.
 * @summary Delete Star Schema Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id}
 */
export function useDeleteStarSchemaDataSource(
  options: {
    mutation?: UseMutationOptions<
      DeleteStarSchemaDataSourceMutationResponse,
      DeleteStarSchemaDataSource422,
      {
        accountName: DeleteStarSchemaDataSourcePathParams['account_name']
        starSchemaDataSourceId: DeleteStarSchemaDataSourcePathParams['star_schema_data_source_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteStarSchemaDataSourceMutationKey()

  return useMutation<
    DeleteStarSchemaDataSourceMutationResponse,
    DeleteStarSchemaDataSource422,
    {
      accountName: DeleteStarSchemaDataSourcePathParams['account_name']
      starSchemaDataSourceId: DeleteStarSchemaDataSourcePathParams['star_schema_data_source_id']
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId }) => {
      return deleteStarSchemaDataSource({ accountName, starSchemaDataSourceId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}