// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDataset.ts"
);
import.meta.hot.lastModified = "1737935766031.756";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateDatasetMutationResponse, UpdateDatasetPathParams, UpdateDatasetQueryParams, UpdateDataset422 } from '../types/UpdateDataset'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDatasetMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/datasets/{star_schema_data_source_id}/update' }] as const

export type UpdateDatasetMutationKey = ReturnType<typeof updateDatasetMutationKey>

/**
 * @description Update dataset.This endpoint lets users push *new* knowledge from baseline dataset to derived dataset, includingSSDS (dimensions, metrics, and named filters), conversations, cells, semantic queries, and feedback.For SSDS, we do incremental update including adding new dimensions, metrics, named filters (DMFs), andalign unaligned DMFs that are identical. We have users review any conflicts in aligned DMFs.For conversations, cells, semantic queries, and feedback, we only copy the newly created conversationsand associated cells, semantic queries, and feedback. We have user review existing conversationsif there are any conflicts.
 * @summary Update Dataset
 * {@link /api/v1/accounts/:account_name/datasets/:star_schema_data_source_id/update}
 */
async function updateDataset(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: UpdateDatasetPathParams['account_name']
    starSchemaDataSourceId: UpdateDatasetPathParams['star_schema_data_source_id']
    params: UpdateDatasetQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateDatasetMutationResponse, UpdateDataset422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/datasets/${starSchemaDataSourceId}/update`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Update dataset.This endpoint lets users push *new* knowledge from baseline dataset to derived dataset, includingSSDS (dimensions, metrics, and named filters), conversations, cells, semantic queries, and feedback.For SSDS, we do incremental update including adding new dimensions, metrics, named filters (DMFs), andalign unaligned DMFs that are identical. We have users review any conflicts in aligned DMFs.For conversations, cells, semantic queries, and feedback, we only copy the newly created conversationsand associated cells, semantic queries, and feedback. We have user review existing conversationsif there are any conflicts.
 * @summary Update Dataset
 * {@link /api/v1/accounts/:account_name/datasets/:star_schema_data_source_id/update}
 */
export function useUpdateDataset(
  options: {
    mutation?: UseMutationOptions<
      UpdateDatasetMutationResponse,
      UpdateDataset422,
      {
        accountName: UpdateDatasetPathParams['account_name']
        starSchemaDataSourceId: UpdateDatasetPathParams['star_schema_data_source_id']
        params: UpdateDatasetQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDatasetMutationKey()

  return useMutation<
    UpdateDatasetMutationResponse,
    UpdateDataset422,
    {
      accountName: UpdateDatasetPathParams['account_name']
      starSchemaDataSourceId: UpdateDatasetPathParams['star_schema_data_source_id']
      params: UpdateDatasetQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params }) => {
      return updateDataset({ accountName, starSchemaDataSourceId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}