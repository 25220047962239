// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/V2Service.ts"
);
import.meta.hot.lastModified = "1737759746380.0977";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConversationAPICreateResponse } from '../models/ConversationAPICreateResponse';
import type { ConversationAPIReadResponse } from '../models/ConversationAPIReadResponse';
import type { ConversationAPIStart } from '../models/ConversationAPIStart';
import type { MessageAPIGetResponse } from '../models/MessageAPIGetResponse';
import type { MessageAPISend } from '../models/MessageAPISend';
import type { MessageAPISendResponse } from '../models/MessageAPISendResponse';
import type { MessagePayloadType } from '../models/MessagePayloadType';
import type { NegativeFeedbackAPISend } from '../models/NegativeFeedbackAPISend';
import type { TaskAPI } from '../models/TaskAPI';
import type { Token } from '../models/Token';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class V2Service {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Login Api Key
   * API key login, get an access token for future requests.
   *
   * Args:
   * email: The email address of the user to associate usage with.
   * display_name: The display name of the user.
   * m2m_token: The machine-to-machine token to store in the cache.
   * @param accountName
   * @param email
   * @param displayName
   * @param m2MToken
   * @returns Token Successful Response
   * @throws ApiError
   */
  public loginApiKey(
    accountName: string,
    email: string,
    displayName: string,
    m2MToken?: (string | null),
  ): CancelablePromise<Token> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orgs/{account_name}/login/api-key',
      path: {
        'account_name': accountName,
      },
      query: {
        'email': email,
        'display_name': displayName,
        'm2m_token': m2MToken,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Start Conversation
   * Start a conversation.
   *
   * This endpoint will start a new conversation with the NS application. If
   * accepted_payload_types is null then all types are accepted. The response will
   * include the details of the message and the conversation.
   * @param accountName The name of the organization or account.
   * @param requestBody
   * @returns ConversationAPICreateResponse Successful Response
   * @throws ApiError
   */
  public startConversation(
    accountName: string,
    requestBody: ConversationAPIStart,
  ): CancelablePromise<ConversationAPICreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orgs/{account_name}/conversations/',
      path: {
        'account_name': accountName,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Send Message To Conversation
   * Send a message to a conversation.
   *
   * This endpoint sends a message to an existing application. The response includes the
   * metadata about the created message. Note that the only message payload type that
   * can be sent is TEXT.
   * @param accountName The name of the organization or account.
   * @param conversationId
   * @param requestBody
   * @returns MessageAPISendResponse Successful Response
   * @throws ApiError
   */
  public sendMessageToConversation(
    accountName: string,
    conversationId: string,
    requestBody: MessageAPISend,
  ): CancelablePromise<MessageAPISendResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orgs/{account_name}/conversations/{conversation_id}/messages',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Conversation Messages
   * Get messages in a conversation.
   *
   * This paginated endpoint returns a conversation with up to the requested number of
   * messages. Note that the messages are ordered from newest to oldest. The client can
   * specify that they want messages after a particular index by providing an offset in
   * order to only get new messages. Note that the server can choose to exclude the payload
   * (returning null) from some messages for performance reasons. The client must then make
   * individual requests for those messages to get the contents.
   *
   * Params:
   * accepted_payload_types: The payload types that can be used in the conversation.
   * If included in the params then it must include the TEXT type. Note that
   * excluding a type can cause messages of that type not to be sent. Messages are not
   * always converted to an accepted type (i.e. if you want table contents, you
   * have to have the “TABLE” type in the accepted types). If None then all types
   * are accepted.
   * limit: The maximum number of messages to return.
   * offset: The number of messages to skip.
   * bound: The index of the last message to return. The response will not include
   * indexes less than this value.
   * @param accountName The name of the organization or account.
   * @param conversationId
   * @param acceptedPayloadTypes
   * @param limit
   * @param offset
   * @param bound
   * @returns ConversationAPIReadResponse Successful Response
   * @throws ApiError
   */
  public readConversationMessages(
    accountName: string,
    conversationId: string,
    acceptedPayloadTypes?: (Array<MessagePayloadType> | null),
    limit: number = 20,
    offset?: number,
    bound?: number,
  ): CancelablePromise<ConversationAPIReadResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/orgs/{account_name}/conversations/{conversation_id}',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      query: {
        'accepted_payload_types': acceptedPayloadTypes,
        'limit': limit,
        'offset': offset,
        'bound': bound,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Suggested Prompts For Dataset
   * Get suggested prompts for a star schema data source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param numberSuggestions
   * @returns string Successful Response
   * @throws ApiError
   */
  public getSuggestedPromptsForDataset(
    accountName: string,
    starSchemaDataSourceId: string,
    numberSuggestions: number = 5,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/orgs/{account_name}/datasets/{star_schema_data_source_id}/suggestions',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      query: {
        'number_suggestions': numberSuggestions,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Tasks By Ids V2
   * Get specific task(s) by id(s).
   * @param accountName The name of the organization or account.
   * @param taskId A list of task ids to retrieve.
   * @returns TaskAPI Successful Response
   * @throws ApiError
   */
  public readTasksByIdsV2(
    accountName: string,
    taskId?: Array<string>,
  ): CancelablePromise<Array<TaskAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/orgs/{account_name}/tasks/ids',
      path: {
        'account_name': accountName,
      },
      query: {
        'task_id': taskId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Message
   * Read a message.
   *
   * Get the message including the contents of its payload. This endpoint is needed for
   * reading the contents of messages after their tasks finish and messages that would
   * cause performance issues for the backend to return in batches. Note that this endpoint
   * accepts different params that will be used/ignored depending on payload type. Each
   * parameter will apply only to one payload type.
   * @param accountName The name of the organization or account.
   * @param messageId
   * @param acceptedPayloadTypes
   * @param tableLimit
   * @param tableOffset
   * @returns MessageAPIGetResponse Successful Response
   * @throws ApiError
   */
  public readMessage(
    accountName: string,
    messageId: string,
    acceptedPayloadTypes?: (Array<MessagePayloadType> | null),
    tableLimit: number = 20,
    tableOffset?: number,
  ): CancelablePromise<MessageAPIGetResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/orgs/{account_name}/messages/{message_id}',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      query: {
        'accepted_payload_types': acceptedPayloadTypes,
        'table_limit': tableLimit,
        'table_offset': tableOffset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Feedback
   * Create negative feedback for a message.
   *
   * Note that feedback is shared amongst all messages in the AI response. Providing
   * feedback for on message will affect all messages that share the same `responds_to`.
   * @param accountName The name of the organization or account.
   * @param messageId
   * @param requestBody
   * @returns MessageAPIGetResponse Successful Response
   * @throws ApiError
   */
  public createFeedback(
    accountName: string,
    messageId: string,
    requestBody: NegativeFeedbackAPISend,
  ): CancelablePromise<MessageAPIGetResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orgs/{account_name}/messages/{message_id}/feedback',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove Feedback
   * Remove feedback for a message.
   * @param accountName The name of the organization or account.
   * @param messageId
   * @returns MessageAPIGetResponse Successful Response
   * @throws ApiError
   */
  public removeFeedback(
    accountName: string,
    messageId: string,
  ): CancelablePromise<MessageAPIGetResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v2/orgs/{account_name}/messages/{message_id}/feedback',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
