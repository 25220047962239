// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/debugLogSchema.ts"
);
import.meta.hot.lastModified = "1737759757945.038";
}
// REMIX HMR END

// @ts-nocheck
import { chatStepSchema } from './chatStepSchema'
import { dataSourceSchema } from './dataSourceSchema'
import { debugLogResponseSchema } from './debugLogResponseSchema'
import { manifestCallSchema } from './manifestCallSchema'
import { tableSchema } from './tableSchema'
import { z } from 'zod'

/**
 * @description Schema for the debug log itself.
 */
export const debugLogSchema = z
  .object({
    question: z.string(),
    data_source: z.union([z.lazy(() => dataSourceSchema), z.null()]).optional(),
    table: z.union([z.lazy(() => tableSchema), z.null()]).optional(),
    history: z.union([z.array(z.lazy(() => chatStepSchema)), z.null()]).optional(),
    ssds_id: z.union([z.string(), z.null()]).optional(),
    max_retries: z.number().int().default(10),
    num_paraphrases: z.number().int().default(1),
    model_calls: z.array(z.lazy(() => manifestCallSchema)).optional(),
    response: z.union([z.lazy(() => debugLogResponseSchema), z.null()]).optional(),
  })
  .describe('Schema for the debug log itself.')