// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/userAddToAccountSchema.ts"
);
import.meta.hot.lastModified = "1737935767112.7502";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Properties to receive via API when adding a user to an account.
 */
export const userAddToAccountSchema = z
  .object({
    email: z.union([z.string().email(), z.null()]).optional(),
    sso: z.boolean().default(false),
  })
  .describe('Properties to receive via API when adding a user to an account.')