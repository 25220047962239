// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateReportVisibility.ts"
);
import.meta.hot.lastModified = "1737759761269.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateReportVisibilityMutationResponse,
  UpdateReportVisibilityPathParams,
  UpdateReportVisibilityQueryParams,
  UpdateReportVisibility422,
} from '../types/UpdateReportVisibility'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateReportVisibilityMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/reports/{report_id}/visibility' }] as const

export type UpdateReportVisibilityMutationKey = ReturnType<typeof updateReportVisibilityMutationKey>

/**
 * @description Update Report visibility.
 * @summary Update Report Visibility
 * {@link /api/v1/accounts/:account_name/reports/:report_id/visibility}
 */
async function updateReportVisibility(
  {
    accountName,
    reportId,
    params,
  }: {
    accountName: UpdateReportVisibilityPathParams['account_name']
    reportId: UpdateReportVisibilityPathParams['report_id']
    params: UpdateReportVisibilityQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateReportVisibilityMutationResponse, UpdateReportVisibility422, unknown>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/reports/${reportId}/visibility`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Update Report visibility.
 * @summary Update Report Visibility
 * {@link /api/v1/accounts/:account_name/reports/:report_id/visibility}
 */
export function useUpdateReportVisibility(
  options: {
    mutation?: UseMutationOptions<
      UpdateReportVisibilityMutationResponse,
      UpdateReportVisibility422,
      {
        accountName: UpdateReportVisibilityPathParams['account_name']
        reportId: UpdateReportVisibilityPathParams['report_id']
        params: UpdateReportVisibilityQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateReportVisibilityMutationKey()

  return useMutation<
    UpdateReportVisibilityMutationResponse,
    UpdateReportVisibility422,
    {
      accountName: UpdateReportVisibilityPathParams['account_name']
      reportId: UpdateReportVisibilityPathParams['report_id']
      params: UpdateReportVisibilityQueryParams
    }
  >({
    mutationFn: async ({ accountName, reportId, params }) => {
      return updateReportVisibility({ accountName, reportId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}