// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useInstall.ts"
);
import.meta.hot.lastModified = "1737935764870.7627";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { InstallQueryResponse, InstallPathParams, Install422 } from '../types/Install'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const installQueryKey = ({ accountName }: { accountName: InstallPathParams['account_name'] }) =>
  [{ url: '/api/v3/orgs/:account_name/slack/install', params: { accountName: accountName } }] as const

export type InstallQueryKey = ReturnType<typeof installQueryKey>

/**
 * @summary Install
 * {@link /api/v3/orgs/:account_name/slack/install}
 */
async function install({ accountName }: { accountName: InstallPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<InstallQueryResponse, Install422, unknown>({ method: 'GET', url: `/api/v3/orgs/${accountName}/slack/install`, ...config })
  return res.data
}

export function installQueryOptions({ accountName }: { accountName: InstallPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const queryKey = installQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return install({ accountName }, config)
    },
  })
}

/**
 * @summary Install
 * {@link /api/v3/orgs/:account_name/slack/install}
 */
export function useInstall<TData = InstallQueryResponse, TQueryData = InstallQueryResponse, TQueryKey extends QueryKey = InstallQueryKey>(
  { accountName }: { accountName: InstallPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<InstallQueryResponse, Install422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? installQueryKey({ accountName })

  const query = useQuery({
    ...(installQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, Install422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}