import * as accountSchemas from './account'
import * as cellSchemas from './cell'
import * as connectionSchemas from './connection'
import * as datasetSchemas from './datasets'
import * as errorContentSchemas from './errorContent'
import * as taskSchemas from './task'

export const schemas = {
  ...accountSchemas,
  ...cellSchemas,
  ...connectionSchemas,
  ...datasetSchemas,
  ...errorContentSchemas,
  ...taskSchemas,
}
