// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/ConversationsService.ts"
);
import.meta.hot.lastModified = "1737759746320.098";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BumblebeeCell } from '../models/BumblebeeCell';
import type { ConversationAPICreate } from '../models/ConversationAPICreate';
import type { ConversationAPIUpdate } from '../models/ConversationAPIUpdate';
import type { ConversationBasic } from '../models/ConversationBasic';
import type { ConversationFull } from '../models/ConversationFull';
import type { ConversationsFilter } from '../models/ConversationsFilter';
import type { ConversationsSort } from '../models/ConversationsSort';
import type { EvalBumblebeeCellAPICreate } from '../models/EvalBumblebeeCellAPICreate';
import type { MoonlightRunInputAPI } from '../models/MoonlightRunInputAPI';
import type { MoonlightRunParams } from '../models/MoonlightRunParams';
import type { RunBBCellResponse } from '../models/RunBBCellResponse';
import type { Task } from '../models/Task';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConversationsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Clone Conversation
   * Clone a conversation with the new user as the creator.
   * @param accountName
   * @param conversationId
   * @param isEvalConversation
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public cloneConversation(
    accountName: string,
    conversationId: string,
    isEvalConversation: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<ConversationBasic> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/clone',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'is_eval_conversation': isEvalConversation,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Conversation
   * Get a full conversation.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns ConversationFull Successful Response
   * @throws ApiError
   */
  public getConversation(
    accountName: string,
    conversationId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ConversationFull> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Conversation
   * Delete a conversation.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public deleteConversation(
    accountName: string,
    conversationId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Conversation
   * Update a conversation.
   * @param accountName
   * @param conversationId
   * @param requestBody
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public updateConversation(
    accountName: string,
    conversationId: string,
    requestBody: ConversationAPIUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<ConversationBasic> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Conversation Basic
   * Get a basic conversation.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public getConversationBasic(
    accountName: string,
    conversationId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ConversationBasic> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/basic',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Conversation
   * Create new bumblebee conversation.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ConversationFull Successful Response
   * @throws ApiError
   */
  public createConversation(
    accountName: string,
    requestBody: ConversationAPICreate,
    sessionId?: (string | null),
  ): CancelablePromise<ConversationFull> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Conversations
   * Get all conversations for the bumblebee dashboard.
   *
   * If show_moonlight is True, show only moonlight conversations
   * If show_moonlight is False, show only non-moonlight conversations
   * If show_moonlight is None, show everything.
   * @param accountName
   * @param sortAscending
   * @param sortBy
   * @param filterBy
   * @param limit
   * @param skip
   * @param showMoonlight
   * @param showEval
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public getConversations(
    accountName: string,
    sortAscending: boolean = true,
    sortBy?: (ConversationsSort | null),
    filterBy?: (ConversationsFilter | null),
    limit?: (number | null),
    skip?: number,
    showMoonlight?: (boolean | null),
    showEval: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<Array<ConversationBasic>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'sort_ascending': sortAscending,
        'sort_by': sortBy,
        'filter_by': filterBy,
        'limit': limit,
        'skip': skip,
        'show_moonlight': showMoonlight,
        'show_eval': showEval,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Pin Conversation
   * Pin a bumblebee conversation to the account home page.
   * @param accountName
   * @param conversationId
   * @param requestBody
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public pinConversation(
    accountName: string,
    conversationId: string,
    requestBody: ConversationAPIUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/pins',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Unpin Conversation
   * Unpin a bumblebee conversation to the account home page.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public unpinConversation(
    accountName: string,
    conversationId: string,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/pins',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Star Conversation
   * Star a bumblebee conversation.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public starConversation(
    accountName: string,
    conversationId: string,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/stars',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Unstar Conversation
   * Unstar a bumblebee conversation.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public unstarConversation(
    accountName: string,
    conversationId: string,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/stars',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Run Bumblebee Cell
   * Run bumblebee for an already created cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @param requestBody
   * @returns RunBBCellResponse Successful Response
   * @throws ApiError
   */
  public runBumblebeeCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
    requestBody?: (MoonlightRunParams | null),
  ): CancelablePromise<RunBBCellResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cell/{cell_id}/run_bumblebee_cell',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Run Moonlight
   * Run Moonlight.
   * @param accountName
   * @param conversationId
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public runMoonlight(
    accountName: string,
    conversationId: string,
    requestBody: MoonlightRunInputAPI,
    sessionId?: (string | null),
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/run_moonlight',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Run Moonlight Cell
   * Run moonlight for an already created cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public runMoonlightCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: MoonlightRunInputAPI,
    sessionId?: (string | null),
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cell/{cell_id}/run_moonlight_cell',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Eval Cell
   * Create a new Evaluation Bumblebee Cell in an Evaluation Conversation.
   *
   * Args:
   * eval_bb_cell_in: Eval cell creation API. Only required if cell_id is None.
   * source_cell_id: source id to create eval cell from.
   * conversation_id: Optional, to append cell to an existing eval conversation.
   * @param accountName
   * @param sourceCellId
   * @param conversationId
   * @param sessionId
   * @param requestBody
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public createEvalCell(
    accountName: string,
    sourceCellId?: (string | null),
    conversationId?: (string | null),
    sessionId?: (string | null),
    requestBody?: (EvalBumblebeeCellAPICreate | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/eval_cell',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'source_cell_id': sourceCellId,
        'conversation_id': conversationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
