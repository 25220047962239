// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyCreateDimensionApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDimensionsPostSchema.ts"
);
import.meta.hot.lastModified = "1737935769735.7358";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionViewWithoutDialectSchema } from './dimensionViewWithoutDialectSchema'
import { starSchemaDataSourceEnrichmentSchema } from './starSchemaDataSourceEnrichmentSchema'
import { z } from 'zod'

export const bodyCreateDimensionApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDimensionsPostSchema = z.object({
  dimension_in: z.lazy(() => dimensionViewWithoutDialectSchema),
  enrich_params: z.union([z.lazy(() => starSchemaDataSourceEnrichmentSchema), z.null()]).optional(),
})