// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getEntityContextSchema.ts"
);
import.meta.hot.lastModified = "1737935767415.7485";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { knowledgeLayerContextApiSchema } from './knowledgeLayerContextApiSchema'
import { z } from 'zod'

export const getEntityContextPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

export const getEntityContextQueryParamsSchema = z.object({
  entity_type: z.enum(['dimension', 'metric', 'dataset', 'tableau', 'mode']).describe('Type of the entity'),
  entity_id: z.string(),
})

/**
 * @description The context associated with the entity was fetched successfully.
 */
export const getEntityContext200Schema = z.lazy(() => knowledgeLayerContextApiSchema)

/**
 * @description The entity was not found.
 */
export const getEntityContext404Schema = z.unknown()

/**
 * @description Validation Error
 */
export const getEntityContext422Schema = z.lazy(() => httpValidationErrorSchema)

export const getEntityContextQueryResponseSchema = z.lazy(() => getEntityContext200Schema)