// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetAccountPreregistrations.ts"
);
import.meta.hot.lastModified = "1737935769206.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetAccountPreregistrationsQueryResponse,
  GetAccountPreregistrationsPathParams,
  GetAccountPreregistrations422,
} from '../types/GetAccountPreregistrations'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAccountPreregistrationsQueryKey = ({ accountName }: { accountName: GetAccountPreregistrationsPathParams['account_name'] }) =>
  [{ url: '/api/v1/accounts/:account_name/preregistrations', params: { accountName: accountName } }] as const

export type GetAccountPreregistrationsQueryKey = ReturnType<typeof getAccountPreregistrationsQueryKey>

/**
 * @description Get all preregistrations in the account.
 * @summary Get Account Preregistrations
 * {@link /api/v1/accounts/:account_name/preregistrations}
 */
async function getAccountPreregistrations(
  { accountName }: { accountName: GetAccountPreregistrationsPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetAccountPreregistrationsQueryResponse, GetAccountPreregistrations422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/preregistrations`,
    ...config,
  })
  return res.data
}

export function getAccountPreregistrationsQueryOptions(
  { accountName }: { accountName: GetAccountPreregistrationsPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getAccountPreregistrationsQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAccountPreregistrations({ accountName }, config)
    },
  })
}

/**
 * @description Get all preregistrations in the account.
 * @summary Get Account Preregistrations
 * {@link /api/v1/accounts/:account_name/preregistrations}
 */
export function useGetAccountPreregistrations<
  TData = GetAccountPreregistrationsQueryResponse,
  TQueryData = GetAccountPreregistrationsQueryResponse,
  TQueryKey extends QueryKey = GetAccountPreregistrationsQueryKey,
>(
  { accountName }: { accountName: GetAccountPreregistrationsPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<GetAccountPreregistrationsQueryResponse, GetAccountPreregistrations422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAccountPreregistrationsQueryKey({ accountName })

  const query = useQuery({
    ...(getAccountPreregistrationsQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetAccountPreregistrations422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}