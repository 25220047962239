// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/usePinConversation.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { PinConversationMutationRequest, PinConversationMutationResponse, PinConversationPathParams, PinConversation422 } from '../types/PinConversation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const pinConversationMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/pins' }] as const

export type PinConversationMutationKey = ReturnType<typeof pinConversationMutationKey>

/**
 * @description Pin a bumblebee conversation to the account home page.
 * @summary Pin Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/pins}
 */
async function pinConversation(
  {
    accountName,
    conversationId,
    data,
  }: {
    accountName: PinConversationPathParams['account_name']
    conversationId: PinConversationPathParams['conversation_id']
    data?: PinConversationMutationRequest
  },
  config: Partial<RequestConfig<PinConversationMutationRequest>> = {},
) {
  const res = await client<PinConversationMutationResponse, PinConversation422, PinConversationMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/pins`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Pin a bumblebee conversation to the account home page.
 * @summary Pin Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/pins}
 */
export function usePinConversation(
  options: {
    mutation?: UseMutationOptions<
      PinConversationMutationResponse,
      PinConversation422,
      {
        accountName: PinConversationPathParams['account_name']
        conversationId: PinConversationPathParams['conversation_id']
        data?: PinConversationMutationRequest
      }
    >
    client?: Partial<RequestConfig<PinConversationMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? pinConversationMutationKey()

  return useMutation<
    PinConversationMutationResponse,
    PinConversation422,
    {
      accountName: PinConversationPathParams['account_name']
      conversationId: PinConversationPathParams['conversation_id']
      data?: PinConversationMutationRequest
    }
  >({
    mutationFn: async ({ accountName, conversationId, data }) => {
      return pinConversation({ accountName, conversationId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}