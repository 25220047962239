// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteDimensionsSchema.ts"
);
import.meta.hot.lastModified = "1737935767407.7488";
}
// REMIX HMR END

// @ts-nocheck
import { deleteRequestSchema } from './deleteRequestSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const deleteDimensionsPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const deleteDimensions200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const deleteDimensions422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteDimensionsMutationRequestSchema = z.array(z.lazy(() => deleteRequestSchema))

export const deleteDimensionsMutationResponseSchema = z.lazy(() => deleteDimensions200Schema)