// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/modeCollectionSchema.ts"
);
import.meta.hot.lastModified = "1737935766487.7537";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description A pydantic object for Mode collection.\n\nRef: https://mode.com/developer/api-reference/management/collections/#object-collections
 */
export const modeCollectionSchema = z
  .object({
    token: z.string(),
    name: z.string(),
    description: z.union([z.string(), z.null()]),
  })
  .describe('A pydantic object for Mode collection.\n\nRef: https://mode.com/developer/api-reference/management/collections/#object-collections')