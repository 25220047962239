// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdatePostgresConnection.ts"
);
import.meta.hot.lastModified = "1737935768806.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdatePostgresConnectionMutationRequest,
  UpdatePostgresConnectionMutationResponse,
  UpdatePostgresConnectionPathParams,
  UpdatePostgresConnection422,
} from '../types/UpdatePostgresConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updatePostgresConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}' }] as const

export type UpdatePostgresConnectionMutationKey = ReturnType<typeof updatePostgresConnectionMutationKey>

/**
 * @description Update Postgres connection.
 * @summary Update Postgres Connection
 * {@link /api/v1/accounts/:account_name/connections/:postgres_connection_id}
 */
async function updatePostgresConnection(
  {
    accountName,
    postgresConnectionId,
    data,
  }: {
    accountName: UpdatePostgresConnectionPathParams['account_name']
    postgresConnectionId: UpdatePostgresConnectionPathParams['postgres_connection_id']
    data?: UpdatePostgresConnectionMutationRequest
  },
  config: Partial<RequestConfig<UpdatePostgresConnectionMutationRequest>> = {},
) {
  const res = await client<UpdatePostgresConnectionMutationResponse, UpdatePostgresConnection422, UpdatePostgresConnectionMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/connections/${postgresConnectionId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update Postgres connection.
 * @summary Update Postgres Connection
 * {@link /api/v1/accounts/:account_name/connections/:postgres_connection_id}
 */
export function useUpdatePostgresConnection(
  options: {
    mutation?: UseMutationOptions<
      UpdatePostgresConnectionMutationResponse,
      UpdatePostgresConnection422,
      {
        accountName: UpdatePostgresConnectionPathParams['account_name']
        postgresConnectionId: UpdatePostgresConnectionPathParams['postgres_connection_id']
        data?: UpdatePostgresConnectionMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdatePostgresConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updatePostgresConnectionMutationKey()

  return useMutation<
    UpdatePostgresConnectionMutationResponse,
    UpdatePostgresConnection422,
    {
      accountName: UpdatePostgresConnectionPathParams['account_name']
      postgresConnectionId: UpdatePostgresConnectionPathParams['postgres_connection_id']
      data?: UpdatePostgresConnectionMutationRequest
    }
  >({
    mutationFn: async ({ accountName, postgresConnectionId, data }) => {
      return updatePostgresConnection({ accountName, postgresConnectionId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}