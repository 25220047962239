// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetEvalOnReferenceConversationResultById.ts"
);
import.meta.hot.lastModified = "1737935769674.7363";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetEvalOnReferenceConversationResultByIdQueryResponse,
  GetEvalOnReferenceConversationResultByIdPathParams,
  GetEvalOnReferenceConversationResultById422,
} from '../types/GetEvalOnReferenceConversationResultById'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getEvalOnReferenceConversationResultByIdQueryKey = ({
  accountName,
  starSchemaDataSourceId,
  evalResultId,
}: {
  accountName: GetEvalOnReferenceConversationResultByIdPathParams['account_name']
  starSchemaDataSourceId: GetEvalOnReferenceConversationResultByIdPathParams['star_schema_data_source_id']
  evalResultId: GetEvalOnReferenceConversationResultByIdPathParams['eval_result_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/eval_reference_conversations_results/:eval_result_id',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId, evalResultId: evalResultId },
    },
  ] as const

export type GetEvalOnReferenceConversationResultByIdQueryKey = ReturnType<typeof getEvalOnReferenceConversationResultByIdQueryKey>

/**
 * @description Get eval result for an SSDS by ID.
 * @summary Get Eval On Reference Conversation Result By Id
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/eval_reference_conversations_results/:eval_result_id}
 */
async function getEvalOnReferenceConversationResultById(
  {
    accountName,
    starSchemaDataSourceId,
    evalResultId,
  }: {
    accountName: GetEvalOnReferenceConversationResultByIdPathParams['account_name']
    starSchemaDataSourceId: GetEvalOnReferenceConversationResultByIdPathParams['star_schema_data_source_id']
    evalResultId: GetEvalOnReferenceConversationResultByIdPathParams['eval_result_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetEvalOnReferenceConversationResultByIdQueryResponse, GetEvalOnReferenceConversationResultById422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/eval_reference_conversations_results/${evalResultId}`,
    ...config,
  })
  return res.data
}

export function getEvalOnReferenceConversationResultByIdQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    evalResultId,
  }: {
    accountName: GetEvalOnReferenceConversationResultByIdPathParams['account_name']
    starSchemaDataSourceId: GetEvalOnReferenceConversationResultByIdPathParams['star_schema_data_source_id']
    evalResultId: GetEvalOnReferenceConversationResultByIdPathParams['eval_result_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getEvalOnReferenceConversationResultByIdQueryKey({ accountName, starSchemaDataSourceId, evalResultId })
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId && evalResultId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getEvalOnReferenceConversationResultById({ accountName, starSchemaDataSourceId, evalResultId }, config)
    },
  })
}

/**
 * @description Get eval result for an SSDS by ID.
 * @summary Get Eval On Reference Conversation Result By Id
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/eval_reference_conversations_results/:eval_result_id}
 */
export function useGetEvalOnReferenceConversationResultById<
  TData = GetEvalOnReferenceConversationResultByIdQueryResponse,
  TQueryData = GetEvalOnReferenceConversationResultByIdQueryResponse,
  TQueryKey extends QueryKey = GetEvalOnReferenceConversationResultByIdQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    evalResultId,
  }: {
    accountName: GetEvalOnReferenceConversationResultByIdPathParams['account_name']
    starSchemaDataSourceId: GetEvalOnReferenceConversationResultByIdPathParams['star_schema_data_source_id']
    evalResultId: GetEvalOnReferenceConversationResultByIdPathParams['eval_result_id']
  },
  options: {
    query?: Partial<
      QueryObserverOptions<GetEvalOnReferenceConversationResultByIdQueryResponse, GetEvalOnReferenceConversationResultById422, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getEvalOnReferenceConversationResultByIdQueryKey({ accountName, starSchemaDataSourceId, evalResultId })

  const query = useQuery({
    ...(getEvalOnReferenceConversationResultByIdQueryOptions({ accountName, starSchemaDataSourceId, evalResultId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetEvalOnReferenceConversationResultById422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}