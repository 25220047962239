// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/pinConversationSchema.ts"
);
import.meta.hot.lastModified = "1737759759806.0283";
}
// REMIX HMR END

// @ts-nocheck
import { conversationApiUpdateSchema } from './conversationApiUpdateSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const pinConversationPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
})

/**
 * @description Successful Response
 */
export const pinConversation204Schema = z.unknown()

/**
 * @description Validation Error
 */
export const pinConversation422Schema = z.lazy(() => httpValidationErrorSchema)

export const pinConversationMutationRequestSchema = z.lazy(() => conversationApiUpdateSchema)

export const pinConversationMutationResponseSchema = z.lazy(() => pinConversation204Schema)