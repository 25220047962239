// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/DmFilter.ts"
);
import.meta.hot.lastModified = "1737759757506.0403";
}
// REMIX HMR END

export enum DmFilter {
  'ALL' = 'ALL',
  'VERIFIED_ONLY' = 'VERIFIED_ONLY',
}