// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRefreshBumblebeeCell.ts"
);
import.meta.hot.lastModified = "1737759760889.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RefreshBumblebeeCellMutationResponse, RefreshBumblebeeCellPathParams, RefreshBumblebeeCell422 } from '../types/RefreshBumblebeeCell'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const refreshBumblebeeCellMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/refresh' }] as const

export type RefreshBumblebeeCellMutationKey = ReturnType<typeof refreshBumblebeeCellMutationKey>

/**
 * @description Refresh the bumblebee cell's data.
 * @summary Refresh Bumblebee Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/refresh}
 */
async function refreshBumblebeeCell(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: RefreshBumblebeeCellPathParams['account_name']
    conversationId: RefreshBumblebeeCellPathParams['conversation_id']
    cellId: RefreshBumblebeeCellPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RefreshBumblebeeCellMutationResponse, RefreshBumblebeeCell422, unknown>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/refresh`,
    ...config,
  })
  return res.data
}

/**
 * @description Refresh the bumblebee cell's data.
 * @summary Refresh Bumblebee Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/refresh}
 */
export function useRefreshBumblebeeCell(
  options: {
    mutation?: UseMutationOptions<
      RefreshBumblebeeCellMutationResponse,
      RefreshBumblebeeCell422,
      {
        accountName: RefreshBumblebeeCellPathParams['account_name']
        conversationId: RefreshBumblebeeCellPathParams['conversation_id']
        cellId: RefreshBumblebeeCellPathParams['cell_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? refreshBumblebeeCellMutationKey()

  return useMutation<
    RefreshBumblebeeCellMutationResponse,
    RefreshBumblebeeCell422,
    {
      accountName: RefreshBumblebeeCellPathParams['account_name']
      conversationId: RefreshBumblebeeCellPathParams['conversation_id']
      cellId: RefreshBumblebeeCellPathParams['cell_id']
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId }) => {
      return refreshBumblebeeCell({ accountName, conversationId, cellId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}