// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRetrieveEmbeddingSearch.ts"
);
import.meta.hot.lastModified = "1737935768622.742";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RetrieveEmbeddingSearchQueryResponse,
  RetrieveEmbeddingSearchPathParams,
  RetrieveEmbeddingSearchQueryParams,
  RetrieveEmbeddingSearch422,
} from '../types/RetrieveEmbeddingSearch'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const retrieveEmbeddingSearchQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: RetrieveEmbeddingSearchPathParams['account_name']
    starSchemaDataSourceId: RetrieveEmbeddingSearchPathParams['star_schema_data_source_id']
  },
  params: RetrieveEmbeddingSearchQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/retrieve/embedding',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type RetrieveEmbeddingSearchQueryKey = ReturnType<typeof retrieveEmbeddingSearchQueryKey>

/**
 * @description Retrieve sample values using embedding search.
 * @summary Retrieve Embedding Search
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/retrieve/embedding}
 */
async function retrieveEmbeddingSearch(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: RetrieveEmbeddingSearchPathParams['account_name']
    starSchemaDataSourceId: RetrieveEmbeddingSearchPathParams['star_schema_data_source_id']
    params: RetrieveEmbeddingSearchQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RetrieveEmbeddingSearchQueryResponse, RetrieveEmbeddingSearch422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/retrieve/embedding`,
    params,
    ...config,
  })
  return res.data
}

export function retrieveEmbeddingSearchQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: RetrieveEmbeddingSearchPathParams['account_name']
    starSchemaDataSourceId: RetrieveEmbeddingSearchPathParams['star_schema_data_source_id']
    params: RetrieveEmbeddingSearchQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = retrieveEmbeddingSearchQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return retrieveEmbeddingSearch({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Retrieve sample values using embedding search.
 * @summary Retrieve Embedding Search
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/retrieve/embedding}
 */
export function useRetrieveEmbeddingSearch<
  TData = RetrieveEmbeddingSearchQueryResponse,
  TQueryData = RetrieveEmbeddingSearchQueryResponse,
  TQueryKey extends QueryKey = RetrieveEmbeddingSearchQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: RetrieveEmbeddingSearchPathParams['account_name']
    starSchemaDataSourceId: RetrieveEmbeddingSearchPathParams['star_schema_data_source_id']
    params: RetrieveEmbeddingSearchQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<RetrieveEmbeddingSearchQueryResponse, RetrieveEmbeddingSearch422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? retrieveEmbeddingSearchQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(retrieveEmbeddingSearchQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, RetrieveEmbeddingSearch422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}