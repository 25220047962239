// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetDataAssetsForAccount.ts"
);
import.meta.hot.lastModified = "1737759761469.0198";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetDataAssetsForAccountQueryResponse,
  GetDataAssetsForAccountPathParams,
  GetDataAssetsForAccountQueryParams,
  GetDataAssetsForAccount422,
} from '../types/GetDataAssetsForAccount'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getDataAssetsForAccountQueryKey = (
  { accountName }: { accountName: GetDataAssetsForAccountPathParams['account_name'] },
  params?: GetDataAssetsForAccountQueryParams,
) => [{ url: '/api/v3/orgs/:account_name/data_assets/', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type GetDataAssetsForAccountQueryKey = ReturnType<typeof getDataAssetsForAccountQueryKey>

/**
 * @description Get data assets for an account.
 * @summary Get Data Assets For Account
 * {@link /api/v3/orgs/:account_name/data_assets/}
 */
async function getDataAssetsForAccount(
  { accountName, params }: { accountName: GetDataAssetsForAccountPathParams['account_name']; params?: GetDataAssetsForAccountQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetDataAssetsForAccountQueryResponse, GetDataAssetsForAccount422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/data_assets/`,
    params,
    ...config,
  })
  return res.data
}

export function getDataAssetsForAccountQueryOptions(
  { accountName, params }: { accountName: GetDataAssetsForAccountPathParams['account_name']; params?: GetDataAssetsForAccountQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getDataAssetsForAccountQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getDataAssetsForAccount({ accountName, params }, config)
    },
  })
}

/**
 * @description Get data assets for an account.
 * @summary Get Data Assets For Account
 * {@link /api/v3/orgs/:account_name/data_assets/}
 */
export function useGetDataAssetsForAccount<
  TData = GetDataAssetsForAccountQueryResponse,
  TQueryData = GetDataAssetsForAccountQueryResponse,
  TQueryKey extends QueryKey = GetDataAssetsForAccountQueryKey,
>(
  { accountName, params }: { accountName: GetDataAssetsForAccountPathParams['account_name']; params?: GetDataAssetsForAccountQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetDataAssetsForAccountQueryResponse, GetDataAssetsForAccount422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getDataAssetsForAccountQueryKey({ accountName }, params)

  const query = useQuery({
    ...(getDataAssetsForAccountQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetDataAssetsForAccount422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}