// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadDatabricksConnectionTables.ts"
);
import.meta.hot.lastModified = "1737759762343.0154";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadDatabricksConnectionTablesQueryResponse,
  ReadDatabricksConnectionTablesPathParams,
  ReadDatabricksConnectionTablesQueryParams,
  ReadDatabricksConnectionTables422,
} from '../types/ReadDatabricksConnectionTables'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readDatabricksConnectionTablesQueryKey = (
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: ReadDatabricksConnectionTablesPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionTablesPathParams['databricks_connection_id']
  },
  params: ReadDatabricksConnectionTablesQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/tables',
      params: { accountName: accountName, databricksConnectionId: databricksConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadDatabricksConnectionTablesQueryKey = ReturnType<typeof readDatabricksConnectionTablesQueryKey>

/**
 * @description Read Databricks tables.
 * @summary Read Databricks Connection Tables
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/tables}
 */
async function readDatabricksConnectionTables(
  {
    accountName,
    databricksConnectionId,
    params,
  }: {
    accountName: ReadDatabricksConnectionTablesPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionTablesPathParams['databricks_connection_id']
    params: ReadDatabricksConnectionTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadDatabricksConnectionTablesQueryResponse, ReadDatabricksConnectionTables422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/databricks/${databricksConnectionId}/tables`,
    params,
    ...config,
  })
  return res.data
}

export function readDatabricksConnectionTablesQueryOptions(
  {
    accountName,
    databricksConnectionId,
    params,
  }: {
    accountName: ReadDatabricksConnectionTablesPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionTablesPathParams['databricks_connection_id']
    params: ReadDatabricksConnectionTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readDatabricksConnectionTablesQueryKey({ accountName, databricksConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && databricksConnectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readDatabricksConnectionTables({ accountName, databricksConnectionId, params }, config)
    },
  })
}

/**
 * @description Read Databricks tables.
 * @summary Read Databricks Connection Tables
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/tables}
 */
export function useReadDatabricksConnectionTables<
  TData = ReadDatabricksConnectionTablesQueryResponse,
  TQueryData = ReadDatabricksConnectionTablesQueryResponse,
  TQueryKey extends QueryKey = ReadDatabricksConnectionTablesQueryKey,
>(
  {
    accountName,
    databricksConnectionId,
    params,
  }: {
    accountName: ReadDatabricksConnectionTablesPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionTablesPathParams['databricks_connection_id']
    params: ReadDatabricksConnectionTablesQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadDatabricksConnectionTablesQueryResponse, ReadDatabricksConnectionTables422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readDatabricksConnectionTablesQueryKey({ accountName, databricksConnectionId }, params)

  const query = useQuery({
    ...(readDatabricksConnectionTablesQueryOptions({ accountName, databricksConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadDatabricksConnectionTables422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}