// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetBySlackId.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetBySlackIdQueryResponse, GetBySlackIdPathParams, GetBySlackIdQueryParams, GetBySlackId422 } from '../types/GetBySlackId'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getBySlackIdQueryKey = ({ accountName }: { accountName: GetBySlackIdPathParams['account_name'] }, params: GetBySlackIdQueryParams) =>
  [{ url: '/api/v3/orgs/:account_name/chat/slack', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type GetBySlackIdQueryKey = ReturnType<typeof getBySlackIdQueryKey>

/**
 * @description Read a single chat by slack ID.Note that this does NOT include the messages of the chat. See the getchat messages endpoint (GET api/v3/orgs/{account_name}/chats/{chat_id}/messages/) for that.If a chat with the given slack ID does not exist, then this will 404.
 * @summary Get By Slack Id
 * {@link /api/v3/orgs/:account_name/chat/slack}
 */
async function getBySlackId(
  { accountName, params }: { accountName: GetBySlackIdPathParams['account_name']; params: GetBySlackIdQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetBySlackIdQueryResponse, GetBySlackId422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/chat/slack`,
    params,
    ...config,
  })
  return res.data
}

export function getBySlackIdQueryOptions(
  { accountName, params }: { accountName: GetBySlackIdPathParams['account_name']; params: GetBySlackIdQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getBySlackIdQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!(accountName && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getBySlackId({ accountName, params }, config)
    },
  })
}

/**
 * @description Read a single chat by slack ID.Note that this does NOT include the messages of the chat. See the getchat messages endpoint (GET api/v3/orgs/{account_name}/chats/{chat_id}/messages/) for that.If a chat with the given slack ID does not exist, then this will 404.
 * @summary Get By Slack Id
 * {@link /api/v3/orgs/:account_name/chat/slack}
 */
export function useGetBySlackId<TData = GetBySlackIdQueryResponse, TQueryData = GetBySlackIdQueryResponse, TQueryKey extends QueryKey = GetBySlackIdQueryKey>(
  { accountName, params }: { accountName: GetBySlackIdPathParams['account_name']; params: GetBySlackIdQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetBySlackIdQueryResponse, GetBySlackId422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getBySlackIdQueryKey({ accountName }, params)

  const query = useQuery({
    ...(getBySlackIdQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetBySlackId422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}