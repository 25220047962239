// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useLearnDmsFromSqls.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  LearnDmsFromSqlsMutationRequest,
  LearnDmsFromSqlsMutationResponse,
  LearnDmsFromSqlsPathParams,
  LearnDmsFromSqls422,
} from '../types/LearnDmsFromSqls'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const learnDmsFromSqlsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/learn_dms_from_sqls' }] as const

export type LearnDmsFromSqlsMutationKey = ReturnType<typeof learnDmsFromSqlsMutationKey>

/**
 * @description Learn DMs from list of SQLs.This endpoint will first remove all temporarily extracted DMs from SQLs from previous run in the SSDS.
 * @summary Learn Dms From Sqls
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/learn_dms_from_sqls}
 */
async function learnDmsFromSqls(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: LearnDmsFromSqlsPathParams['account_name']
    starSchemaDataSourceId: LearnDmsFromSqlsPathParams['star_schema_data_source_id']
    data?: LearnDmsFromSqlsMutationRequest
  },
  config: Partial<RequestConfig<LearnDmsFromSqlsMutationRequest>> = {},
) {
  const res = await client<LearnDmsFromSqlsMutationResponse, LearnDmsFromSqls422, LearnDmsFromSqlsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/learn_dms_from_sqls`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Learn DMs from list of SQLs.This endpoint will first remove all temporarily extracted DMs from SQLs from previous run in the SSDS.
 * @summary Learn Dms From Sqls
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/learn_dms_from_sqls}
 */
export function useLearnDmsFromSqls(
  options: {
    mutation?: UseMutationOptions<
      LearnDmsFromSqlsMutationResponse,
      LearnDmsFromSqls422,
      {
        accountName: LearnDmsFromSqlsPathParams['account_name']
        starSchemaDataSourceId: LearnDmsFromSqlsPathParams['star_schema_data_source_id']
        data?: LearnDmsFromSqlsMutationRequest
      }
    >
    client?: Partial<RequestConfig<LearnDmsFromSqlsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? learnDmsFromSqlsMutationKey()

  return useMutation<
    LearnDmsFromSqlsMutationResponse,
    LearnDmsFromSqls422,
    {
      accountName: LearnDmsFromSqlsPathParams['account_name']
      starSchemaDataSourceId: LearnDmsFromSqlsPathParams['star_schema_data_source_id']
      data?: LearnDmsFromSqlsMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return learnDmsFromSqls({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}