// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getCellMetricOptionsSchema.ts"
);
import.meta.hot.lastModified = "1737759761028.0222";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { metricViewSchema } from './metricViewSchema'
import { z } from 'zod'

export const getCellMetricOptionsPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

export const getCellMetricOptionsQueryParamsSchema = z
  .object({
    limit: z.number().int().default(100),
    skip: z.number().int().default(0),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getCellMetricOptions200Schema = z.array(z.lazy(() => metricViewSchema))

/**
 * @description Validation Error
 */
export const getCellMetricOptions422Schema = z.lazy(() => httpValidationErrorSchema)

export const getCellMetricOptionsQueryResponseSchema = z.lazy(() => getCellMetricOptions200Schema)