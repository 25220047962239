// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/syncBbVerifiedCellToFeedbackChatSchema.ts"
);
import.meta.hot.lastModified = "1737935769655.7363";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { m2MTokenCredsSchema } from './m2MTokenCredsSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const syncBbVerifiedCellToFeedbackChatPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const syncBbVerifiedCellToFeedbackChat201Schema = z.lazy(() => taskSchema)

/**
 * @description Validation Error
 */
export const syncBbVerifiedCellToFeedbackChat422Schema = z.lazy(() => httpValidationErrorSchema)

export const syncBbVerifiedCellToFeedbackChatMutationRequestSchema = z.union([z.lazy(() => m2MTokenCredsSchema), z.null()])

export const syncBbVerifiedCellToFeedbackChatMutationResponseSchema = z.lazy(() => syncBbVerifiedCellToFeedbackChat201Schema)