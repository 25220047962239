// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsTableAndViewInfo.ts"
);
import.meta.hot.lastModified = "1737935768622.742";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetSsdsTableAndViewInfoQueryResponse, GetSsdsTableAndViewInfoPathParams, GetSsdsTableAndViewInfo422 } from '../types/GetSsdsTableAndViewInfo'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsTableAndViewInfoQueryKey = ({
  accountName,
  starSchemaDataSourceId,
}: {
  accountName: GetSsdsTableAndViewInfoPathParams['account_name']
  starSchemaDataSourceId: GetSsdsTableAndViewInfoPathParams['star_schema_data_source_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/table_and_view_info',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
  ] as const

export type GetSsdsTableAndViewInfoQueryKey = ReturnType<typeof getSsdsTableAndViewInfoQueryKey>

/**
 * @description Get table and view info for the given ssds.
 * @summary Get Ssds Table And View Info
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/table_and_view_info}
 */
async function getSsdsTableAndViewInfo(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetSsdsTableAndViewInfoPathParams['account_name']
    starSchemaDataSourceId: GetSsdsTableAndViewInfoPathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsTableAndViewInfoQueryResponse, GetSsdsTableAndViewInfo422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/table_and_view_info`,
    ...config,
  })
  return res.data
}

export function getSsdsTableAndViewInfoQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetSsdsTableAndViewInfoPathParams['account_name']
    starSchemaDataSourceId: GetSsdsTableAndViewInfoPathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsTableAndViewInfoQueryKey({ accountName, starSchemaDataSourceId })
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsTableAndViewInfo({ accountName, starSchemaDataSourceId }, config)
    },
  })
}

/**
 * @description Get table and view info for the given ssds.
 * @summary Get Ssds Table And View Info
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/table_and_view_info}
 */
export function useGetSsdsTableAndViewInfo<
  TData = GetSsdsTableAndViewInfoQueryResponse,
  TQueryData = GetSsdsTableAndViewInfoQueryResponse,
  TQueryKey extends QueryKey = GetSsdsTableAndViewInfoQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetSsdsTableAndViewInfoPathParams['account_name']
    starSchemaDataSourceId: GetSsdsTableAndViewInfoPathParams['star_schema_data_source_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsTableAndViewInfoQueryResponse, GetSsdsTableAndViewInfo422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsTableAndViewInfoQueryKey({ accountName, starSchemaDataSourceId })

  const query = useQuery({
    ...(getSsdsTableAndViewInfoQueryOptions({ accountName, starSchemaDataSourceId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsTableAndViewInfo422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}