// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateDatabricksConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935769208.7388";
}
// REMIX HMR END

// @ts-nocheck
import { databricksConnectionSchema } from './databricksConnectionSchema'
import { databricksConnectionUpdateSchema } from './databricksConnectionUpdateSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateDatabricksConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  databricks_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const updateDatabricksConnection200Schema = z.lazy(() => databricksConnectionSchema)

/**
 * @description Validation Error
 */
export const updateDatabricksConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateDatabricksConnectionMutationRequestSchema = z.lazy(() => databricksConnectionUpdateSchema)

export const updateDatabricksConnectionMutationResponseSchema = z.lazy(() => updateDatabricksConnection200Schema)