// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ConversationsSort.ts"
);
import.meta.hot.lastModified = "1737935753951.8225";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Sort conversations by.
 */
export enum ConversationsSort {
  CREATED_AT = 'created_at',
  LAST_MODIFIED_AT = 'last_modified_at',
  CREATOR_ID = 'creator_id',
  NAME = 'name',
}
