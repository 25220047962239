// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tableauProjectSchema.ts"
);
import.meta.hot.lastModified = "1737935766487.7537";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Tableau project.
 */
export const tableauProjectSchema = z
  .object({
    id: z.string(),
    name: z.string(),
  })
  .describe('Tableau project.')