// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateBigqueryConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935769010.7397";
}
// REMIX HMR END

// @ts-nocheck
import { bigQueryConnectionApiUpdateSchema } from './bigQueryConnectionApiUpdateSchema'
import { bigQueryConnectionSchema } from './bigQueryConnectionSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateBigqueryConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  connection_id: z.string(),
})

export const updateBigqueryConnectionQueryParamsSchema = z
  .object({
    validate: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const updateBigqueryConnection200Schema = z.lazy(() => bigQueryConnectionSchema)

/**
 * @description Validation Error
 */
export const updateBigqueryConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateBigqueryConnectionMutationRequestSchema = z.lazy(() => bigQueryConnectionApiUpdateSchema)

export const updateBigqueryConnectionMutationResponseSchema = z.lazy(() => updateBigqueryConnection200Schema)