// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadAccounts.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadAccountsQueryResponse, ReadAccountsQueryParams, ReadAccounts422 } from '../types/ReadAccounts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readAccountsQueryKey = (params?: ReadAccountsQueryParams) => [{ url: '/api/v1/accounts/' }, ...(params ? [params] : [])] as const

export type ReadAccountsQueryKey = ReturnType<typeof readAccountsQueryKey>

/**
 * @description Retrieve accounts.If name is provided, case-insensitive substring matching is performed.
 * @summary Read Accounts
 * {@link /api/v1/accounts/}
 */
async function readAccounts({ params }: { params?: ReadAccountsQueryParams }, config: Partial<RequestConfig> = {}) {
  const res = await client<ReadAccountsQueryResponse, ReadAccounts422, unknown>({ method: 'GET', url: `/api/v1/accounts/`, params, ...config })
  return res.data
}

export function readAccountsQueryOptions({ params }: { params?: ReadAccountsQueryParams }, config: Partial<RequestConfig> = {}) {
  const queryKey = readAccountsQueryKey(params)
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readAccounts({ params }, config)
    },
  })
}

/**
 * @description Retrieve accounts.If name is provided, case-insensitive substring matching is performed.
 * @summary Read Accounts
 * {@link /api/v1/accounts/}
 */
export function useReadAccounts<TData = ReadAccountsQueryResponse, TQueryData = ReadAccountsQueryResponse, TQueryKey extends QueryKey = ReadAccountsQueryKey>(
  { params }: { params?: ReadAccountsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadAccountsQueryResponse, ReadAccounts422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readAccountsQueryKey(params)

  const query = useQuery({
    ...(readAccountsQueryOptions({ params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadAccounts422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}