// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/sqlValidationSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionMetricValidationSchema } from './dimensionMetricValidationSchema'
import { z } from 'zod'

/**
 * @description Properties of SqlValidation.
 */
export const sqlValidationSchema = z
  .object({
    id: z.string(),
    account_id: z.string(),
    ssds_id: z.string(),
    message_id: z.union([z.string(), z.null()]).optional(),
    origin: z.enum(['SQL_CSV', 'FEEDBACK_SUGGESTION']),
    sql: z.string(),
    is_valid: z.boolean(),
    error: z.union([z.string(), z.null()]).optional(),
    name: z.union([z.string(), z.null()]).optional(),
    display_name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    extracted: z.array(z.lazy(() => dimensionMetricValidationSchema)),
  })
  .describe('Properties of SqlValidation.')