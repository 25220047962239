// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/identifierJoinTypeSchema.ts"
);
import.meta.hot.lastModified = "1737759760683.024";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Possible join types.\n\nhttps://docs.zenlytic.com/docs/data_modeling/join#properties
 */
export const identifierJoinTypeSchema = z
  .enum(['left_outer', 'inner', 'full_outer', 'cross'])
  .describe('Possible join types.\n\nhttps://docs.zenlytic.com/docs/data_modeling/join#properties')