// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useValidateCellFilter.ts"
);
import.meta.hot.lastModified = "1737759760438.0251";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ValidateCellFilterMutationRequest,
  ValidateCellFilterMutationResponse,
  ValidateCellFilterPathParams,
  ValidateCellFilter422,
} from '../types/ValidateCellFilter'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const validateCellFilterMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/validate-filter' }] as const

export type ValidateCellFilterMutationKey = ReturnType<typeof validateCellFilterMutationKey>

/**
 * @description Check that the filter is valid.Raises:    NSInvalidFilterSQLError if the filter leads to an error in the data warehouse.
 * @summary Validate Cell Filter
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/validate-filter}
 */
async function validateCellFilter(
  {
    accountName,
    conversationId,
    cellId,
    data,
  }: {
    accountName: ValidateCellFilterPathParams['account_name']
    conversationId: ValidateCellFilterPathParams['conversation_id']
    cellId: ValidateCellFilterPathParams['cell_id']
    data?: ValidateCellFilterMutationRequest
  },
  config: Partial<RequestConfig<ValidateCellFilterMutationRequest>> = {},
) {
  const res = await client<ValidateCellFilterMutationResponse, ValidateCellFilter422, ValidateCellFilterMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/validate-filter`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Check that the filter is valid.Raises:    NSInvalidFilterSQLError if the filter leads to an error in the data warehouse.
 * @summary Validate Cell Filter
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/validate-filter}
 */
export function useValidateCellFilter(
  options: {
    mutation?: UseMutationOptions<
      ValidateCellFilterMutationResponse,
      ValidateCellFilter422,
      {
        accountName: ValidateCellFilterPathParams['account_name']
        conversationId: ValidateCellFilterPathParams['conversation_id']
        cellId: ValidateCellFilterPathParams['cell_id']
        data?: ValidateCellFilterMutationRequest
      }
    >
    client?: Partial<RequestConfig<ValidateCellFilterMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? validateCellFilterMutationKey()

  return useMutation<
    ValidateCellFilterMutationResponse,
    ValidateCellFilter422,
    {
      accountName: ValidateCellFilterPathParams['account_name']
      conversationId: ValidateCellFilterPathParams['conversation_id']
      cellId: ValidateCellFilterPathParams['cell_id']
      data?: ValidateCellFilterMutationRequest
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, data }) => {
      return validateCellFilter({ accountName, conversationId, cellId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}