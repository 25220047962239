// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadMessage.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadMessageQueryResponse, ReadMessagePathParams, ReadMessageQueryParams, ReadMessage422 } from '../types/ReadMessage'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readMessageQueryKey = (
  { accountName, messageId }: { accountName: ReadMessagePathParams['account_name']; messageId: ReadMessagePathParams['message_id'] },
  params?: ReadMessageQueryParams,
) =>
  [{ url: '/api/v2/orgs/:account_name/messages/:message_id', params: { accountName: accountName, messageId: messageId } }, ...(params ? [params] : [])] as const

export type ReadMessageQueryKey = ReturnType<typeof readMessageQueryKey>

/**
 * @description Read a message.Get the message including the contents of its payload. This endpoint is needed forreading the contents of messages after their tasks finish and messages that wouldcause performance issues for the backend to return in batches. Note that this endpointaccepts different params that will be used/ignored depending on payload type. Eachparameter will apply only to one payload type.
 * @summary Read Message
 * {@link /api/v2/orgs/:account_name/messages/:message_id}
 */
async function readMessage(
  {
    accountName,
    messageId,
    params,
  }: { accountName: ReadMessagePathParams['account_name']; messageId: ReadMessagePathParams['message_id']; params?: ReadMessageQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadMessageQueryResponse, ReadMessage422, unknown>({
    method: 'GET',
    url: `/api/v2/orgs/${accountName}/messages/${messageId}`,
    params,
    ...config,
  })
  return res.data
}

export function readMessageQueryOptions(
  {
    accountName,
    messageId,
    params,
  }: { accountName: ReadMessagePathParams['account_name']; messageId: ReadMessagePathParams['message_id']; params?: ReadMessageQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readMessageQueryKey({ accountName, messageId }, params)
  return queryOptions({
    enabled: !!(accountName && messageId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readMessage({ accountName, messageId, params }, config)
    },
  })
}

/**
 * @description Read a message.Get the message including the contents of its payload. This endpoint is needed forreading the contents of messages after their tasks finish and messages that wouldcause performance issues for the backend to return in batches. Note that this endpointaccepts different params that will be used/ignored depending on payload type. Eachparameter will apply only to one payload type.
 * @summary Read Message
 * {@link /api/v2/orgs/:account_name/messages/:message_id}
 */
export function useReadMessage<TData = ReadMessageQueryResponse, TQueryData = ReadMessageQueryResponse, TQueryKey extends QueryKey = ReadMessageQueryKey>(
  {
    accountName,
    messageId,
    params,
  }: { accountName: ReadMessagePathParams['account_name']; messageId: ReadMessagePathParams['message_id']; params?: ReadMessageQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadMessageQueryResponse, ReadMessage422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readMessageQueryKey({ accountName, messageId }, params)

  const query = useQuery({
    ...(readMessageQueryOptions({ accountName, messageId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadMessage422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}