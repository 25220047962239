// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteChat.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteChatMutationResponse, DeleteChatPathParams, DeleteChat422 } from '../types/DeleteChat'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteChatMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/chat/{chat_id}' }] as const

export type DeleteChatMutationKey = ReturnType<typeof deleteChatMutationKey>

/**
 * @description Delete a chat.CAUTION: This is unrecoverable. All messages associated with the chat will be deleted.
 * @summary Delete Chat
 * {@link /api/v3/orgs/:account_name/chat/:chat_id}
 */
async function deleteChat(
  { accountName, chatId }: { accountName: DeleteChatPathParams['account_name']; chatId: DeleteChatPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteChatMutationResponse, DeleteChat422, unknown>({
    method: 'DELETE',
    url: `/api/v3/orgs/${accountName}/chat/${chatId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete a chat.CAUTION: This is unrecoverable. All messages associated with the chat will be deleted.
 * @summary Delete Chat
 * {@link /api/v3/orgs/:account_name/chat/:chat_id}
 */
export function useDeleteChat(
  options: {
    mutation?: UseMutationOptions<
      DeleteChatMutationResponse,
      DeleteChat422,
      { accountName: DeleteChatPathParams['account_name']; chatId: DeleteChatPathParams['chat_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteChatMutationKey()

  return useMutation<DeleteChatMutationResponse, DeleteChat422, { accountName: DeleteChatPathParams['account_name']; chatId: DeleteChatPathParams['chat_id'] }>(
    {
      mutationFn: async ({ accountName, chatId }) => {
        return deleteChat({ accountName, chatId }, config)
      },
      mutationKey,
      ...mutationOptions,
    },
  )
}