// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createBigqueryConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759761849.0178";
}
// REMIX HMR END

// @ts-nocheck
import { bigQueryConnectionApiCreateSchema } from './bigQueryConnectionApiCreateSchema'
import { bigQueryConnectionSchema } from './bigQueryConnectionSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const createBigqueryConnectionPathParamsSchema = z.object({
  account_name: z.string(),
})

export const createBigqueryConnectionQueryParamsSchema = z
  .object({
    validate: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const createBigqueryConnection200Schema = z.lazy(() => bigQueryConnectionSchema)

/**
 * @description Validation Error
 */
export const createBigqueryConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const createBigqueryConnectionMutationRequestSchema = z.lazy(() => bigQueryConnectionApiCreateSchema)

export const createBigqueryConnectionMutationResponseSchema = z.lazy(() => createBigqueryConnection200Schema)