// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/DimensionView.ts"
);
import.meta.hot.lastModified = "1737935753971.8225";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Dialect } from './Dialect';
import type { DisplayType } from './DisplayType';
import type { FilterCategory } from './FilterCategory';
import type { OriginType } from './OriginType';
import type { PublishState } from './PublishState';

/**
 * Simplified Dimension View.
 *
 * Used in SemanticLayerView.
 */
export type DimensionView = {
  id?: (string | null);
  sync_id?: (string | null);
  group_id?: (string | null);
  name: string;
  description?: (string | null);
  dialect: Dialect;
  expr?: (string | null);
  zen_sql?: (string | null);
  expr_type?: FilterCategory;
  display_name?: (string | null);
  data_source_id?: (string | null);
  origin?: Array<OriginType>;
  top_values?: (Array<string> | null);
  display_type?: DisplayType;
  popularity?: number;
  is_sensitive?: boolean;
  is_literal_sensitive?: boolean;
  is_display_sensitive?: boolean;
  updated_at?: (string | null);
  created_at?: (string | null);
  field_type?: DimensionView.field_type;
  state?: PublishState;
};

export namespace DimensionView {

  export enum field_type {
    DIMENSION = 'dimension',
  }


}

