// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/FilterType.ts"
);
import.meta.hot.lastModified = "1737759757706.0393";
}
// REMIX HMR END

export enum FilterType {
  'dimension' = 'dimension',
  'metric' = 'metric',
}