// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetCandidateDemonstrationCellsCount.ts"
);
import.meta.hot.lastModified = "1737759762548.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetCandidateDemonstrationCellsCountMutationRequest,
  GetCandidateDemonstrationCellsCountMutationResponse,
  GetCandidateDemonstrationCellsCountPathParams,
  GetCandidateDemonstrationCellsCount422,
} from '../types/GetCandidateDemonstrationCellsCount'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const getCandidateDemonstrationCellsCountMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/candidate_demonstration_cells_count' }] as const

export type GetCandidateDemonstrationCellsCountMutationKey = ReturnType<typeof getCandidateDemonstrationCellsCountMutationKey>

/**
 * @description Count candidate demonstration cells for Star Schema Data Source.
 * @summary Get Candidate Demonstration Cells Count
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/candidate_demonstration_cells_count}
 */
async function getCandidateDemonstrationCellsCount(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: GetCandidateDemonstrationCellsCountPathParams['account_name']
    starSchemaDataSourceId: GetCandidateDemonstrationCellsCountPathParams['star_schema_data_source_id']
    data?: GetCandidateDemonstrationCellsCountMutationRequest
  },
  config: Partial<RequestConfig<GetCandidateDemonstrationCellsCountMutationRequest>> = {},
) {
  const res = await client<
    GetCandidateDemonstrationCellsCountMutationResponse,
    GetCandidateDemonstrationCellsCount422,
    GetCandidateDemonstrationCellsCountMutationRequest
  >({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/candidate_demonstration_cells_count`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Count candidate demonstration cells for Star Schema Data Source.
 * @summary Get Candidate Demonstration Cells Count
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/candidate_demonstration_cells_count}
 */
export function useGetCandidateDemonstrationCellsCount(
  options: {
    mutation?: UseMutationOptions<
      GetCandidateDemonstrationCellsCountMutationResponse,
      GetCandidateDemonstrationCellsCount422,
      {
        accountName: GetCandidateDemonstrationCellsCountPathParams['account_name']
        starSchemaDataSourceId: GetCandidateDemonstrationCellsCountPathParams['star_schema_data_source_id']
        data?: GetCandidateDemonstrationCellsCountMutationRequest
      }
    >
    client?: Partial<RequestConfig<GetCandidateDemonstrationCellsCountMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? getCandidateDemonstrationCellsCountMutationKey()

  return useMutation<
    GetCandidateDemonstrationCellsCountMutationResponse,
    GetCandidateDemonstrationCellsCount422,
    {
      accountName: GetCandidateDemonstrationCellsCountPathParams['account_name']
      starSchemaDataSourceId: GetCandidateDemonstrationCellsCountPathParams['star_schema_data_source_id']
      data?: GetCandidateDemonstrationCellsCountMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return getCandidateDemonstrationCellsCount({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}