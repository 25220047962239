// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetPromptKnowledgeSql.ts"
);
import.meta.hot.lastModified = "1737935768423.7432";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetPromptKnowledgeSqlQueryResponse, GetPromptKnowledgeSqlPathParams, GetPromptKnowledgeSql422 } from '../types/GetPromptKnowledgeSql'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getPromptKnowledgeSqlQueryKey = ({
  accountName,
  conversationId,
  cellId,
}: {
  accountName: GetPromptKnowledgeSqlPathParams['account_name']
  conversationId: GetPromptKnowledgeSqlPathParams['conversation_id']
  cellId: GetPromptKnowledgeSqlPathParams['cell_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/prompt_knowledge_sql',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId },
    },
  ] as const

export type GetPromptKnowledgeSqlQueryKey = ReturnType<typeof getPromptKnowledgeSqlQueryKey>

/**
 * @description Get the prompt knowledge sql from the semantic query.This is the knowledge sql that is used as demonstration for the prompt.
 * @summary Get Prompt Knowledge Sql
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/prompt_knowledge_sql}
 */
async function getPromptKnowledgeSql(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetPromptKnowledgeSqlPathParams['account_name']
    conversationId: GetPromptKnowledgeSqlPathParams['conversation_id']
    cellId: GetPromptKnowledgeSqlPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetPromptKnowledgeSqlQueryResponse, GetPromptKnowledgeSql422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/prompt_knowledge_sql`,
    ...config,
  })
  return res.data
}

export function getPromptKnowledgeSqlQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetPromptKnowledgeSqlPathParams['account_name']
    conversationId: GetPromptKnowledgeSqlPathParams['conversation_id']
    cellId: GetPromptKnowledgeSqlPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getPromptKnowledgeSqlQueryKey({ accountName, conversationId, cellId })
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getPromptKnowledgeSql({ accountName, conversationId, cellId }, config)
    },
  })
}

/**
 * @description Get the prompt knowledge sql from the semantic query.This is the knowledge sql that is used as demonstration for the prompt.
 * @summary Get Prompt Knowledge Sql
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/prompt_knowledge_sql}
 */
export function useGetPromptKnowledgeSql<
  TData = GetPromptKnowledgeSqlQueryResponse,
  TQueryData = GetPromptKnowledgeSqlQueryResponse,
  TQueryKey extends QueryKey = GetPromptKnowledgeSqlQueryKey,
>(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetPromptKnowledgeSqlPathParams['account_name']
    conversationId: GetPromptKnowledgeSqlPathParams['conversation_id']
    cellId: GetPromptKnowledgeSqlPathParams['cell_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<GetPromptKnowledgeSqlQueryResponse, GetPromptKnowledgeSql422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getPromptKnowledgeSqlQueryKey({ accountName, conversationId, cellId })

  const query = useQuery({
    ...(getPromptKnowledgeSqlQueryOptions({ accountName, conversationId, cellId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetPromptKnowledgeSql422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}