// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRemoveSqlValidation.ts"
);
import.meta.hot.lastModified = "1737759760701.024";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RemoveSqlValidationMutationResponse, RemoveSqlValidationPathParams, RemoveSqlValidation422 } from '../types/RemoveSqlValidation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const removeSqlValidationMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation/{sql_validation_id}' }] as const

export type RemoveSqlValidationMutationKey = ReturnType<typeof removeSqlValidationMutationKey>

/**
 * @description Delete one SqlValidation.
 * @summary Remove Sql Validation
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sql_validation/:sql_validation_id}
 */
async function removeSqlValidation(
  {
    accountName,
    starSchemaDataSourceId,
    sqlValidationId,
  }: {
    accountName: RemoveSqlValidationPathParams['account_name']
    starSchemaDataSourceId: RemoveSqlValidationPathParams['star_schema_data_source_id']
    sqlValidationId: RemoveSqlValidationPathParams['sql_validation_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RemoveSqlValidationMutationResponse, RemoveSqlValidation422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/sql_validation/${sqlValidationId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete one SqlValidation.
 * @summary Remove Sql Validation
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sql_validation/:sql_validation_id}
 */
export function useRemoveSqlValidation(
  options: {
    mutation?: UseMutationOptions<
      RemoveSqlValidationMutationResponse,
      RemoveSqlValidation422,
      {
        accountName: RemoveSqlValidationPathParams['account_name']
        starSchemaDataSourceId: RemoveSqlValidationPathParams['star_schema_data_source_id']
        sqlValidationId: RemoveSqlValidationPathParams['sql_validation_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? removeSqlValidationMutationKey()

  return useMutation<
    RemoveSqlValidationMutationResponse,
    RemoveSqlValidation422,
    {
      accountName: RemoveSqlValidationPathParams['account_name']
      starSchemaDataSourceId: RemoveSqlValidationPathParams['star_schema_data_source_id']
      sqlValidationId: RemoveSqlValidationPathParams['sql_validation_id']
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, sqlValidationId }) => {
      return removeSqlValidation({ accountName, starSchemaDataSourceId, sqlValidationId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}