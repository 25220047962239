// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadSnowflakeConnectionRoles.ts"
);
import.meta.hot.lastModified = "1737759762293.0156";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadSnowflakeConnectionRolesQueryResponse,
  ReadSnowflakeConnectionRolesPathParams,
  ReadSnowflakeConnectionRoles422,
} from '../types/ReadSnowflakeConnectionRoles'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readSnowflakeConnectionRolesQueryKey = ({
  accountName,
  snowflakeConnectionId,
}: {
  accountName: ReadSnowflakeConnectionRolesPathParams['account_name']
  snowflakeConnectionId: ReadSnowflakeConnectionRolesPathParams['snowflake_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/roles',
      params: { accountName: accountName, snowflakeConnectionId: snowflakeConnectionId },
    },
  ] as const

export type ReadSnowflakeConnectionRolesQueryKey = ReturnType<typeof readSnowflakeConnectionRolesQueryKey>

/**
 * @description Read Snowflake roles.
 * @summary Read Snowflake Connection Roles
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/roles}
 */
async function readSnowflakeConnectionRoles(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionRolesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionRolesPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadSnowflakeConnectionRolesQueryResponse, ReadSnowflakeConnectionRoles422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/${snowflakeConnectionId}/roles`,
    ...config,
  })
  return res.data
}

export function readSnowflakeConnectionRolesQueryOptions(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionRolesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionRolesPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readSnowflakeConnectionRolesQueryKey({ accountName, snowflakeConnectionId })
  return queryOptions({
    enabled: !!(accountName && snowflakeConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readSnowflakeConnectionRoles({ accountName, snowflakeConnectionId }, config)
    },
  })
}

/**
 * @description Read Snowflake roles.
 * @summary Read Snowflake Connection Roles
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/roles}
 */
export function useReadSnowflakeConnectionRoles<
  TData = ReadSnowflakeConnectionRolesQueryResponse,
  TQueryData = ReadSnowflakeConnectionRolesQueryResponse,
  TQueryKey extends QueryKey = ReadSnowflakeConnectionRolesQueryKey,
>(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionRolesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionRolesPathParams['snowflake_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadSnowflakeConnectionRolesQueryResponse, ReadSnowflakeConnectionRoles422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readSnowflakeConnectionRolesQueryKey({ accountName, snowflakeConnectionId })

  const query = useQuery({
    ...(readSnowflakeConnectionRolesQueryOptions({ accountName, snowflakeConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadSnowflakeConnectionRoles422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}