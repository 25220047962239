// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getDatasetJobsSchema.ts"
);
import.meta.hot.lastModified = "1737759759420.0305";
}
// REMIX HMR END

// @ts-nocheck
import { copyJobSchema } from './copyJobSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { updateJobSchema } from './updateJobSchema'
import { z } from 'zod'

export const getDatasetJobsPathParamsSchema = z.object({
  enterprise_name: z.string(),
})

export const getDatasetJobsQueryParamsSchema = z
  .object({
    skip: z.number().int().default(0),
    limit: z.number().int().default(100),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getDatasetJobs200Schema = z.array(z.union([z.lazy(() => copyJobSchema), z.lazy(() => updateJobSchema)]))

/**
 * @description Validation Error
 */
export const getDatasetJobs422Schema = z.lazy(() => httpValidationErrorSchema)

export const getDatasetJobsQueryResponseSchema = z.lazy(() => getDatasetJobs200Schema)