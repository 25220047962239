// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/conversationCellFilterSchema.ts"
);
import.meta.hot.lastModified = "1737759761448.02";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const conversationCellFilterSchema = z.enum(['ALL', 'VERIFIED_CONV_ONLY', 'VERIFIED_CELL_ONLY'])