// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetChatMessages.ts"
);
import.meta.hot.lastModified = "1737759759784.0286";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetChatMessagesQueryResponse, GetChatMessagesPathParams, GetChatMessagesQueryParams, GetChatMessages422 } from '../types/GetChatMessages'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getChatMessagesQueryKey = (
  { accountName, chatId }: { accountName: GetChatMessagesPathParams['account_name']; chatId: GetChatMessagesPathParams['chat_id'] },
  params?: GetChatMessagesQueryParams,
) => [{ url: '/api/v3/orgs/:account_name/chat/:chat_id/messages', params: { accountName: accountName, chatId: chatId } }, ...(params ? [params] : [])] as const

export type GetChatMessagesQueryKey = ReturnType<typeof getChatMessagesQueryKey>

/**
 * @description Get messages in a chat.This paginated endpoint returns a chat with up to the requested number ofmessages. Note that the messages are ordered from newest to oldest.
 * @summary Get Chat Messages
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/messages}
 */
async function getChatMessages(
  {
    accountName,
    chatId,
    params,
  }: { accountName: GetChatMessagesPathParams['account_name']; chatId: GetChatMessagesPathParams['chat_id']; params?: GetChatMessagesQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetChatMessagesQueryResponse, GetChatMessages422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/chat/${chatId}/messages`,
    params,
    ...config,
  })
  return res.data
}

export function getChatMessagesQueryOptions(
  {
    accountName,
    chatId,
    params,
  }: { accountName: GetChatMessagesPathParams['account_name']; chatId: GetChatMessagesPathParams['chat_id']; params?: GetChatMessagesQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getChatMessagesQueryKey({ accountName, chatId }, params)
  return queryOptions({
    enabled: !!(accountName && chatId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getChatMessages({ accountName, chatId, params }, config)
    },
  })
}

/**
 * @description Get messages in a chat.This paginated endpoint returns a chat with up to the requested number ofmessages. Note that the messages are ordered from newest to oldest.
 * @summary Get Chat Messages
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/messages}
 */
export function useGetChatMessages<
  TData = GetChatMessagesQueryResponse,
  TQueryData = GetChatMessagesQueryResponse,
  TQueryKey extends QueryKey = GetChatMessagesQueryKey,
>(
  {
    accountName,
    chatId,
    params,
  }: { accountName: GetChatMessagesPathParams['account_name']; chatId: GetChatMessagesPathParams['chat_id']; params?: GetChatMessagesQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetChatMessagesQueryResponse, GetChatMessages422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getChatMessagesQueryKey({ accountName, chatId }, params)

  const query = useQuery({
    ...(getChatMessagesQueryOptions({ accountName, chatId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetChatMessages422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}