// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteDatasetJob.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteDatasetJobMutationResponse, DeleteDatasetJobPathParams, DeleteDatasetJob422 } from '../types/DeleteDatasetJob'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteDatasetJobMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/copy/{job_id}' }] as const

export type DeleteDatasetJobMutationKey = ReturnType<typeof deleteDatasetJobMutationKey>

/**
 * @description Delete a copy job.
 * @summary Delete Dataset Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/copy/:job_id}
 */
async function deleteDatasetJob(
  { enterpriseName, jobId }: { enterpriseName: DeleteDatasetJobPathParams['enterprise_name']; jobId: DeleteDatasetJobPathParams['job_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteDatasetJobMutationResponse, DeleteDatasetJob422, unknown>({
    method: 'DELETE',
    url: `/api/v1/enterprises/${enterpriseName}/dataset-jobs/copy/${jobId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete a copy job.
 * @summary Delete Dataset Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/copy/:job_id}
 */
export function useDeleteDatasetJob(
  options: {
    mutation?: UseMutationOptions<
      DeleteDatasetJobMutationResponse,
      DeleteDatasetJob422,
      { enterpriseName: DeleteDatasetJobPathParams['enterprise_name']; jobId: DeleteDatasetJobPathParams['job_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteDatasetJobMutationKey()

  return useMutation<
    DeleteDatasetJobMutationResponse,
    DeleteDatasetJob422,
    { enterpriseName: DeleteDatasetJobPathParams['enterprise_name']; jobId: DeleteDatasetJobPathParams['job_id'] }
  >({
    mutationFn: async ({ enterpriseName, jobId }) => {
      return deleteDatasetJob({ enterpriseName, jobId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}