// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useAddEnterpriseAdmins.ts"
);
import.meta.hot.lastModified = "1737759760700.024";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  AddEnterpriseAdminsMutationRequest,
  AddEnterpriseAdminsMutationResponse,
  AddEnterpriseAdminsPathParams,
  AddEnterpriseAdmins422,
} from '../types/AddEnterpriseAdmins'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const addEnterpriseAdminsMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/admins' }] as const

export type AddEnterpriseAdminsMutationKey = ReturnType<typeof addEnterpriseAdminsMutationKey>

/**
 * @description Add admin users to the enterprise.This propogates by adding the user as both a user and admin to all accounts in the enterprise.Returns: A list of UUIDs of users that were not able to be added.
 * @summary Add Enterprise Admins
 * {@link /api/v1/enterprises/:enterprise_name/admins}
 */
async function addEnterpriseAdmins(
  { enterpriseName, data }: { enterpriseName: AddEnterpriseAdminsPathParams['enterprise_name']; data?: AddEnterpriseAdminsMutationRequest },
  config: Partial<RequestConfig<AddEnterpriseAdminsMutationRequest>> = {},
) {
  const res = await client<AddEnterpriseAdminsMutationResponse, AddEnterpriseAdmins422, AddEnterpriseAdminsMutationRequest>({
    method: 'PUT',
    url: `/api/v1/enterprises/${enterpriseName}/admins`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Add admin users to the enterprise.This propogates by adding the user as both a user and admin to all accounts in the enterprise.Returns: A list of UUIDs of users that were not able to be added.
 * @summary Add Enterprise Admins
 * {@link /api/v1/enterprises/:enterprise_name/admins}
 */
export function useAddEnterpriseAdmins(
  options: {
    mutation?: UseMutationOptions<
      AddEnterpriseAdminsMutationResponse,
      AddEnterpriseAdmins422,
      { enterpriseName: AddEnterpriseAdminsPathParams['enterprise_name']; data?: AddEnterpriseAdminsMutationRequest }
    >
    client?: Partial<RequestConfig<AddEnterpriseAdminsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? addEnterpriseAdminsMutationKey()

  return useMutation<
    AddEnterpriseAdminsMutationResponse,
    AddEnterpriseAdmins422,
    { enterpriseName: AddEnterpriseAdminsPathParams['enterprise_name']; data?: AddEnterpriseAdminsMutationRequest }
  >({
    mutationFn: async ({ enterpriseName, data }) => {
      return addEnterpriseAdmins({ enterpriseName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}