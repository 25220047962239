// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bulkCreateCellsSchema.ts"
);
import.meta.hot.lastModified = "1737935766861.7517";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellBasicSchema } from './bumblebeeCellBasicSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const bulkCreateCellsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const bulkCreateCells201Schema = z.array(z.lazy(() => bumblebeeCellBasicSchema))

/**
 * @description Validation Error
 */
export const bulkCreateCells422Schema = z.lazy(() => httpValidationErrorSchema)

export const bulkCreateCellsMutationRequestSchema = z.array(z.lazy(() => bumblebeeCellBasicSchema))

export const bulkCreateCellsMutationResponseSchema = z.lazy(() => bulkCreateCells201Schema)