// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readDatabricksConnectionSchemasSchema.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readDatabricksConnectionSchemasPathParamsSchema = z.object({
  account_name: z.string(),
  databricks_connection_id: z.string(),
})

export const readDatabricksConnectionSchemasQueryParamsSchema = z.object({
  database: z.string().min(1),
})

/**
 * @description Successful Response
 */
export const readDatabricksConnectionSchemas200Schema = z.object({}).catchall(z.array(z.string()))

/**
 * @description Validation Error
 */
export const readDatabricksConnectionSchemas422Schema = z.lazy(() => httpValidationErrorSchema)

export const readDatabricksConnectionSchemasQueryResponseSchema = z.lazy(() => readDatabricksConnectionSchemas200Schema)