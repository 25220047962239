// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/settingValueSchema.ts"
);
import.meta.hot.lastModified = "1737759758958.0327";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Default value for a setting.\n\nTODO: support specifying constraints on the value.
 */
export const settingValueSchema = z
  .object({
    value: z.union([z.boolean(), z.number().int(), z.string(), z.null()]),
  })
  .describe('Default value for a setting.\n\nTODO: support specifying constraints on the value.')