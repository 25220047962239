// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dimensionPairToReviewSchema.ts"
);
import.meta.hot.lastModified = "1737759761259.021";
}
// REMIX HMR END

// @ts-nocheck
import { conflictStatusSchema } from './conflictStatusSchema'
import { dimensionDetailSchema } from './dimensionDetailSchema'
import { z } from 'zod'

/**
 * @description Properties to return to client.
 */
export const dimensionPairToReviewSchema = z
  .object({
    update_job_id_for_dimensions: z.union([z.string(), z.null()]).optional(),
    update_job_id_for_non_verified_dimensions: z.union([z.string(), z.null()]).optional(),
    src_id: z.string(),
    dst_id: z.string(),
    status: z.lazy(() => conflictStatusSchema),
    id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    src: z.lazy(() => dimensionDetailSchema),
    dst: z.lazy(() => dimensionDetailSchema),
  })
  .describe('Properties to return to client.')