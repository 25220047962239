// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useValidateTableAndViewInfos.ts"
);
import.meta.hot.lastModified = "1737759761863.0178";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ValidateTableAndViewInfosMutationRequest,
  ValidateTableAndViewInfosMutationResponse,
  ValidateTableAndViewInfosPathParams,
  ValidateTableAndViewInfosQueryParams,
  ValidateTableAndViewInfos422,
} from '../types/ValidateTableAndViewInfos'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const validateTableAndViewInfosMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/validate_table_and_view_infos' }] as const

export type ValidateTableAndViewInfosMutationKey = ReturnType<typeof validateTableAndViewInfosMutationKey>

/**
 * @description Validate the table or view definition.
 * @summary Validate Table And View Infos
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/validate_table_and_view_infos}
 */
async function validateTableAndViewInfos(
  {
    accountName,
    params,
    data,
  }: {
    accountName: ValidateTableAndViewInfosPathParams['account_name']
    params: ValidateTableAndViewInfosQueryParams
    data?: ValidateTableAndViewInfosMutationRequest
  },
  config: Partial<RequestConfig<ValidateTableAndViewInfosMutationRequest>> = {},
) {
  const res = await client<ValidateTableAndViewInfosMutationResponse, ValidateTableAndViewInfos422, ValidateTableAndViewInfosMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/validate_table_and_view_infos`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Validate the table or view definition.
 * @summary Validate Table And View Infos
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/validate_table_and_view_infos}
 */
export function useValidateTableAndViewInfos(
  options: {
    mutation?: UseMutationOptions<
      ValidateTableAndViewInfosMutationResponse,
      ValidateTableAndViewInfos422,
      {
        accountName: ValidateTableAndViewInfosPathParams['account_name']
        params: ValidateTableAndViewInfosQueryParams
        data?: ValidateTableAndViewInfosMutationRequest
      }
    >
    client?: Partial<RequestConfig<ValidateTableAndViewInfosMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? validateTableAndViewInfosMutationKey()

  return useMutation<
    ValidateTableAndViewInfosMutationResponse,
    ValidateTableAndViewInfos422,
    {
      accountName: ValidateTableAndViewInfosPathParams['account_name']
      params: ValidateTableAndViewInfosQueryParams
      data?: ValidateTableAndViewInfosMutationRequest
    }
  >({
    mutationFn: async ({ accountName, params, data }) => {
      return validateTableAndViewInfos({ accountName, params, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}