// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bulkCreateNamedFiltersSchema.ts"
);
import.meta.hot.lastModified = "1737935768613.742";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { namedFilterBaseSchema } from './namedFilterBaseSchema'
import { namedFilterCreateSchema } from './namedFilterCreateSchema'
import { z } from 'zod'

export const bulkCreateNamedFiltersPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const bulkCreateNamedFilters201Schema = z.array(z.lazy(() => namedFilterBaseSchema))

/**
 * @description Validation Error
 */
export const bulkCreateNamedFilters422Schema = z.lazy(() => httpValidationErrorSchema)

export const bulkCreateNamedFiltersMutationRequestSchema = z.array(z.lazy(() => namedFilterCreateSchema))

export const bulkCreateNamedFiltersMutationResponseSchema = z.lazy(() => bulkCreateNamedFilters201Schema)