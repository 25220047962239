// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateDimensionSchema.ts"
);
import.meta.hot.lastModified = "1737759759807.0283";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionDetailSchema } from './dimensionDetailSchema'
import { dimensionUpdateSchema } from './dimensionUpdateSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateDimensionPathParamsSchema = z.object({
  account_name: z.string(),
  dimension_id: z.string(),
})

/**
 * @description Successful Response
 */
export const updateDimension200Schema = z.lazy(() => dimensionDetailSchema)

/**
 * @description Validation Error
 */
export const updateDimension422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateDimensionMutationRequestSchema = z.lazy(() => dimensionUpdateSchema)

export const updateDimensionMutationResponseSchema = z.lazy(() => updateDimension200Schema)