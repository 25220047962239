// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/userBasicApiResponseSchema.ts"
);
import.meta.hot.lastModified = "1737935768205.7444";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Basic information about a user from the API\n\nAttributes:\n    id: The ID of the user.\n    name: The name of the user.\n    avatar: The URL of the user\'s avatar if they have one.
 */
export const userBasicApiResponseSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    avatar: z.union([z.string().url(), z.null()]),
  })
  .describe(
    "Basic information about a user from the API\n\nAttributes:\n    id: The ID of the user.\n    name: The name of the user.\n    avatar: The URL of the user's avatar if they have one.",
  )