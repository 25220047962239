// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/index.tsx");
  import.meta.hot.lastModified = "1734072415104.7712";
}
// REMIX HMR END

import { useNavigate } from '@remix-run/react';
import { useEffect } from 'react';
import { useUserMe } from '~/client/hooks/user';
import { useRedirectToLogin } from '~/utils/useRedirectToLogin';
export default function IndexPage() {
  _s();
  const {
    data: user,
    error
  } = useUserMe();
  const redirectToLogin = useRedirectToLogin();
  const navigate = useNavigate();
  useEffect(() => {
    if (error?.status_code === 401) {
      redirectToLogin({
        expired: true
      });
    } else if (user) {
      if (user.accounts.length === 0) {
        const message = `No accounts found for user: ${user.email}.`;
        redirectToLogin({
          expired: true,
          error: message,
          noRedirect: true
        });
      } else {
        navigate(`/${user.accounts[0].name}`);
      }
    }
  }, [navigate, redirectToLogin, error?.status_code, user]);
  return null;
}
_s(IndexPage, "hxAG1gdLHsq+U9QxVN2JVv77iPI=", false, function () {
  return [useUserMe, useRedirectToLogin, useNavigate];
});
_c = IndexPage;
var _c;
$RefreshReg$(_c, "IndexPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;