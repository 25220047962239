// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyCreateMetricApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdMetricsPostSchema.ts"
);
import.meta.hot.lastModified = "1737759762609.014";
}
// REMIX HMR END

// @ts-nocheck
import { metricViewWithoutDialectSchema } from './metricViewWithoutDialectSchema'
import { starSchemaDataSourceEnrichmentSchema } from './starSchemaDataSourceEnrichmentSchema'
import { z } from 'zod'

export const bodyCreateMetricApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdMetricsPostSchema = z.object({
  metric_in: z.lazy(() => metricViewWithoutDialectSchema),
  enrich_params: z.union([z.lazy(() => starSchemaDataSourceEnrichmentSchema), z.null()]).optional(),
})