// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteStarSchemaDataSources.ts"
);
import.meta.hot.lastModified = "1737935769212.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  DeleteStarSchemaDataSourcesMutationResponse,
  DeleteStarSchemaDataSourcesPathParams,
  DeleteStarSchemaDataSources422,
} from '../types/DeleteStarSchemaDataSources'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteStarSchemaDataSourcesMutationKey = () => [{ url: '/api/v1/admin/star_schema_data_source/{account_name}' }] as const

export type DeleteStarSchemaDataSourcesMutationKey = ReturnType<typeof deleteStarSchemaDataSourcesMutationKey>

/**
 * @description Delete all SSDSs in an account.
 * @summary Delete Star Schema Data Sources
 * {@link /api/v1/admin/star_schema_data_source/:account_name}
 */
async function deleteStarSchemaDataSources(
  { accountName }: { accountName: DeleteStarSchemaDataSourcesPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteStarSchemaDataSourcesMutationResponse, DeleteStarSchemaDataSources422, unknown>({
    method: 'DELETE',
    url: `/api/v1/admin/star_schema_data_source/${accountName}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete all SSDSs in an account.
 * @summary Delete Star Schema Data Sources
 * {@link /api/v1/admin/star_schema_data_source/:account_name}
 */
export function useDeleteStarSchemaDataSources(
  options: {
    mutation?: UseMutationOptions<
      DeleteStarSchemaDataSourcesMutationResponse,
      DeleteStarSchemaDataSources422,
      { accountName: DeleteStarSchemaDataSourcesPathParams['account_name'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteStarSchemaDataSourcesMutationKey()

  return useMutation<
    DeleteStarSchemaDataSourcesMutationResponse,
    DeleteStarSchemaDataSources422,
    { accountName: DeleteStarSchemaDataSourcesPathParams['account_name'] }
  >({
    mutationFn: async ({ accountName }) => {
      return deleteStarSchemaDataSources({ accountName }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}