// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readTeradataConnectionSchemasSchema.ts"
);
import.meta.hot.lastModified = "1737759762338.0154";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readTeradataConnectionSchemasPathParamsSchema = z.object({
  account_name: z.string(),
  teradata_connection_id: z.string(),
})

export const readTeradataConnectionSchemasQueryParamsSchema = z.object({
  database: z.string().min(1),
})

/**
 * @description Successful Response
 */
export const readTeradataConnectionSchemas200Schema = z.object({}).catchall(z.array(z.string()))

/**
 * @description Validation Error
 */
export const readTeradataConnectionSchemas422Schema = z.lazy(() => httpValidationErrorSchema)

export const readTeradataConnectionSchemasQueryResponseSchema = z.lazy(() => readTeradataConnectionSchemas200Schema)