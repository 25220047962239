// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRecoverPassword.ts"
);
import.meta.hot.lastModified = "1737759759781.0286";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RecoverPasswordMutationRequest, RecoverPasswordMutationResponse, RecoverPassword422 } from '../types/RecoverPassword'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const recoverPasswordMutationKey = () => [{ url: '/api/v1/password-recovery/' }] as const

export type RecoverPasswordMutationKey = ReturnType<typeof recoverPasswordMutationKey>

/**
 * @description Send a password recovery email to a user.
 * @summary Recover Password
 * {@link /api/v1/password-recovery/}
 */
async function recoverPassword({ data }: { data: RecoverPasswordMutationRequest }, config: Partial<RequestConfig<RecoverPasswordMutationRequest>> = {}) {
  const res = await client<RecoverPasswordMutationResponse, RecoverPassword422, RecoverPasswordMutationRequest>({
    method: 'POST',
    url: `/api/v1/password-recovery/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Send a password recovery email to a user.
 * @summary Recover Password
 * {@link /api/v1/password-recovery/}
 */
export function useRecoverPassword(
  options: {
    mutation?: UseMutationOptions<RecoverPasswordMutationResponse, RecoverPassword422, { data: RecoverPasswordMutationRequest }>
    client?: Partial<RequestConfig<RecoverPasswordMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? recoverPasswordMutationKey()

  return useMutation<RecoverPasswordMutationResponse, RecoverPassword422, { data: RecoverPasswordMutationRequest }>({
    mutationFn: async ({ data }) => {
      return recoverPassword({ data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}