// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadCellFilters.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadCellFiltersQueryResponse, ReadCellFiltersPathParams, ReadCellFilters422 } from '../types/ReadCellFilters'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readCellFiltersQueryKey = ({
  accountName,
  conversationId,
  cellId,
}: {
  accountName: ReadCellFiltersPathParams['account_name']
  conversationId: ReadCellFiltersPathParams['conversation_id']
  cellId: ReadCellFiltersPathParams['cell_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/filters',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId },
    },
  ] as const

export type ReadCellFiltersQueryKey = ReturnType<typeof readCellFiltersQueryKey>

/**
 * @description Get the filters for a cell.
 * @summary Read Cell Filters
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/filters}
 */
async function readCellFilters(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: ReadCellFiltersPathParams['account_name']
    conversationId: ReadCellFiltersPathParams['conversation_id']
    cellId: ReadCellFiltersPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadCellFiltersQueryResponse, ReadCellFilters422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/filters`,
    ...config,
  })
  return res.data
}

export function readCellFiltersQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: ReadCellFiltersPathParams['account_name']
    conversationId: ReadCellFiltersPathParams['conversation_id']
    cellId: ReadCellFiltersPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readCellFiltersQueryKey({ accountName, conversationId, cellId })
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readCellFilters({ accountName, conversationId, cellId }, config)
    },
  })
}

/**
 * @description Get the filters for a cell.
 * @summary Read Cell Filters
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/filters}
 */
export function useReadCellFilters<
  TData = ReadCellFiltersQueryResponse,
  TQueryData = ReadCellFiltersQueryResponse,
  TQueryKey extends QueryKey = ReadCellFiltersQueryKey,
>(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: ReadCellFiltersPathParams['account_name']
    conversationId: ReadCellFiltersPathParams['conversation_id']
    cellId: ReadCellFiltersPathParams['cell_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadCellFiltersQueryResponse, ReadCellFilters422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readCellFiltersQueryKey({ accountName, conversationId, cellId })

  const query = useQuery({
    ...(readCellFiltersQueryOptions({ accountName, conversationId, cellId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadCellFilters422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}