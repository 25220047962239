// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ZenIdentifierType.ts"
);
import.meta.hot.lastModified = "1737935754061.822";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible ZenIdentifier types.
 */
export enum ZenIdentifierType {
  PRIMARY = 'primary',
  FOREIGN = 'foreign',
  JOIN = 'join',
}
