// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chartSchema.ts"
);
import.meta.hot.lastModified = "1737759757506.0403";
}
// REMIX HMR END

// @ts-nocheck
import { chartSeriesSchema } from './chartSeriesSchema'
import { z } from 'zod'

/**
 * @description Properties of Chart.
 */
export const chartSchema = z
  .object({
    data: z.lazy(() => chartSeriesSchema),
  })
  .describe('Properties of Chart.')