// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatMessageModelParametersSchema.ts"
);
import.meta.hot.lastModified = "1737759762054.0168";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Model Parameters for Chat Message Promptlogs.
 */
export const chatMessageModelParametersSchema = z
  .object({
    model: z.string(),
    max_tokens: z.number().int(),
    temperature: z.number(),
    seed: z.number().int(),
    response_format: z.string(),
    tools: z.array(z.object({})),
  })
  .describe('Model Parameters for Chat Message Promptlogs.')