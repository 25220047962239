// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/snowflakeConnectionPageSchema.ts"
);
import.meta.hot.lastModified = "1737935768791.741";
}
// REMIX HMR END

// @ts-nocheck
import { snowflakeConnectionSchema } from './snowflakeConnectionSchema'
import { z } from 'zod'

/**
 * @description Paginated SnowflakeConnection response.
 */
export const snowflakeConnectionPageSchema = z
  .object({
    snowflake_connections: z.array(z.lazy(() => snowflakeConnectionSchema)),
    total: z.number().int(),
  })
  .describe('Paginated SnowflakeConnection response.')