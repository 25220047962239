// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteTableauConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tableauConnectionSchema } from './tableauConnectionSchema'
import { z } from 'zod'

export const deleteTableauConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  tableau_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const deleteTableauConnection200Schema = z.lazy(() => tableauConnectionSchema)

/**
 * @description Validation Error
 */
export const deleteTableauConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteTableauConnectionMutationResponseSchema = z.lazy(() => deleteTableauConnection200Schema)