// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateFeedback.ts"
);
import.meta.hot.lastModified = "1737935766475.7537";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateFeedbackMutationRequest, CreateFeedbackMutationResponse, CreateFeedbackPathParams, CreateFeedback422 } from '../types/CreateFeedback'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createFeedbackMutationKey = () => [{ url: '/api/v2/orgs/{account_name}/messages/{message_id}/feedback' }] as const

export type CreateFeedbackMutationKey = ReturnType<typeof createFeedbackMutationKey>

/**
 * @description Create negative feedback for a message.Note that feedback is shared amongst all messages in the AI response. Providingfeedback for on message will affect all messages that share the same `responds_to`.
 * @summary Create Feedback
 * {@link /api/v2/orgs/:account_name/messages/:message_id/feedback}
 */
async function createFeedback(
  {
    accountName,
    messageId,
    data,
  }: { accountName: CreateFeedbackPathParams['account_name']; messageId: CreateFeedbackPathParams['message_id']; data: CreateFeedbackMutationRequest },
  config: Partial<RequestConfig<CreateFeedbackMutationRequest>> = {},
) {
  const res = await client<CreateFeedbackMutationResponse, CreateFeedback422, CreateFeedbackMutationRequest>({
    method: 'POST',
    url: `/api/v2/orgs/${accountName}/messages/${messageId}/feedback`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create negative feedback for a message.Note that feedback is shared amongst all messages in the AI response. Providingfeedback for on message will affect all messages that share the same `responds_to`.
 * @summary Create Feedback
 * {@link /api/v2/orgs/:account_name/messages/:message_id/feedback}
 */
export function useCreateFeedback(
  options: {
    mutation?: UseMutationOptions<
      CreateFeedbackMutationResponse,
      CreateFeedback422,
      { accountName: CreateFeedbackPathParams['account_name']; messageId: CreateFeedbackPathParams['message_id']; data: CreateFeedbackMutationRequest }
    >
    client?: Partial<RequestConfig<CreateFeedbackMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createFeedbackMutationKey()

  return useMutation<
    CreateFeedbackMutationResponse,
    CreateFeedback422,
    { accountName: CreateFeedbackPathParams['account_name']; messageId: CreateFeedbackPathParams['message_id']; data: CreateFeedbackMutationRequest }
  >({
    mutationFn: async ({ accountName, messageId, data }) => {
      return createFeedback({ accountName, messageId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}