// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/filterOperatorSchema.ts"
);
import.meta.hot.lastModified = "1737759759420.0305";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Filter operator.
 */
export const filterOperatorSchema = z
  .enum([
    'equals',
    'not_equals',
    'case-insensitive_equals',
    'not_case-insensitive_equals',
    'greater_than',
    'greater_than_or_equal_to',
    'less_than',
    'less_than_or_equal_to',
    'contains',
    'not_contains',
    'starts_with',
    'not_starts_with',
    'ends_with',
    'not_ends_with',
    'blank',
    'not_blank',
    'in',
    'not_in',
    'between',
    'not_between',
  ])
  .describe('Filter operator.')