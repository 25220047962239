// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useAddBadResponse.ts"
);
import.meta.hot.lastModified = "1737759759405.0305";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { AddBadResponseMutationRequest, AddBadResponseMutationResponse, AddBadResponsePathParams, AddBadResponse422 } from '../types/AddBadResponse'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const addBadResponseMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/bad_response' }] as const

export type AddBadResponseMutationKey = ReturnType<typeof addBadResponseMutationKey>

/**
 * @description Add bad repsonse to a bumblebee cell.
 * @summary Add Bad Response
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/bad_response}
 */
async function addBadResponse(
  {
    accountName,
    conversationId,
    cellId,
    data,
  }: {
    accountName: AddBadResponsePathParams['account_name']
    conversationId: AddBadResponsePathParams['conversation_id']
    cellId: AddBadResponsePathParams['cell_id']
    data: AddBadResponseMutationRequest
  },
  config: Partial<RequestConfig<AddBadResponseMutationRequest>> = {},
) {
  const res = await client<AddBadResponseMutationResponse, AddBadResponse422, AddBadResponseMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/bad_response`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Add bad repsonse to a bumblebee cell.
 * @summary Add Bad Response
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/bad_response}
 */
export function useAddBadResponse(
  options: {
    mutation?: UseMutationOptions<
      AddBadResponseMutationResponse,
      AddBadResponse422,
      {
        accountName: AddBadResponsePathParams['account_name']
        conversationId: AddBadResponsePathParams['conversation_id']
        cellId: AddBadResponsePathParams['cell_id']
        data: AddBadResponseMutationRequest
      }
    >
    client?: Partial<RequestConfig<AddBadResponseMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? addBadResponseMutationKey()

  return useMutation<
    AddBadResponseMutationResponse,
    AddBadResponse422,
    {
      accountName: AddBadResponsePathParams['account_name']
      conversationId: AddBadResponsePathParams['conversation_id']
      cellId: AddBadResponsePathParams['cell_id']
      data: AddBadResponseMutationRequest
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, data }) => {
      return addBadResponse({ accountName, conversationId, cellId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}