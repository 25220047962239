// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateSnowflakeConnection.ts"
);
import.meta.hot.lastModified = "1737759761860.0178";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateSnowflakeConnectionMutationRequest,
  CreateSnowflakeConnectionMutationResponse,
  CreateSnowflakeConnectionPathParams,
  CreateSnowflakeConnectionQueryParams,
  CreateSnowflakeConnection422,
} from '../types/CreateSnowflakeConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createSnowflakeConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/snowflake/' }] as const

export type CreateSnowflakeConnectionMutationKey = ReturnType<typeof createSnowflakeConnectionMutationKey>

/**
 * @description Create new Snowflake connection.
 * @summary Create Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/}
 */
async function createSnowflakeConnection(
  {
    accountName,
    data,
    params,
  }: {
    accountName: CreateSnowflakeConnectionPathParams['account_name']
    data: CreateSnowflakeConnectionMutationRequest
    params?: CreateSnowflakeConnectionQueryParams
  },
  config: Partial<RequestConfig<CreateSnowflakeConnectionMutationRequest>> = {},
) {
  const res = await client<CreateSnowflakeConnectionMutationResponse, CreateSnowflakeConnection422, CreateSnowflakeConnectionMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create new Snowflake connection.
 * @summary Create Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/}
 */
export function useCreateSnowflakeConnection(
  options: {
    mutation?: UseMutationOptions<
      CreateSnowflakeConnectionMutationResponse,
      CreateSnowflakeConnection422,
      {
        accountName: CreateSnowflakeConnectionPathParams['account_name']
        data: CreateSnowflakeConnectionMutationRequest
        params?: CreateSnowflakeConnectionQueryParams
      }
    >
    client?: Partial<RequestConfig<CreateSnowflakeConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createSnowflakeConnectionMutationKey()

  return useMutation<
    CreateSnowflakeConnectionMutationResponse,
    CreateSnowflakeConnection422,
    {
      accountName: CreateSnowflakeConnectionPathParams['account_name']
      data: CreateSnowflakeConnectionMutationRequest
      params?: CreateSnowflakeConnectionQueryParams
    }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return createSnowflakeConnection({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}