// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetAccountEngines.ts"
);
import.meta.hot.lastModified = "1737759760212.0264";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetAccountEnginesQueryResponse, GetAccountEnginesPathParams, GetAccountEngines422 } from '../types/GetAccountEngines'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAccountEnginesQueryKey = ({ accountName }: { accountName: GetAccountEnginesPathParams['account_name'] }) =>
  [{ url: '/api/v1/accounts/:account_name/engines', params: { accountName: accountName } }] as const

export type GetAccountEnginesQueryKey = ReturnType<typeof getAccountEnginesQueryKey>

/**
 * @description Get manifest engines of the account.
 * @summary Get Account Engines
 * {@link /api/v1/accounts/:account_name/engines}
 */
async function getAccountEngines({ accountName }: { accountName: GetAccountEnginesPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<GetAccountEnginesQueryResponse, GetAccountEngines422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/engines`,
    ...config,
  })
  return res.data
}

export function getAccountEnginesQueryOptions(
  { accountName }: { accountName: GetAccountEnginesPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getAccountEnginesQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAccountEngines({ accountName }, config)
    },
  })
}

/**
 * @description Get manifest engines of the account.
 * @summary Get Account Engines
 * {@link /api/v1/accounts/:account_name/engines}
 */
export function useGetAccountEngines<
  TData = GetAccountEnginesQueryResponse,
  TQueryData = GetAccountEnginesQueryResponse,
  TQueryKey extends QueryKey = GetAccountEnginesQueryKey,
>(
  { accountName }: { accountName: GetAccountEnginesPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<GetAccountEnginesQueryResponse, GetAccountEngines422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAccountEnginesQueryKey({ accountName })

  const query = useQuery({
    ...(getAccountEnginesQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetAccountEngines422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}