// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/DimensionFilter.ts"
);
import.meta.hot.lastModified = "1737759758679.0342";
}
// REMIX HMR END

import type { Combination } from './Combination'
import type { DimensionFilterCondition } from './DimensionFilterCondition'
import type { FilterOrigin } from './FilterOrigin'

export enum DimensionFilterFieldType {
  'dimension' = 'dimension',
}

/**
 * @description Filter operating over dimensions.
 */
export type DimensionFilter = {
  id?: string | null
  query_id?: string | null
  name?: string | null
  /**
   * @default "ALL"
   */
  combination?: Combination
  description?: string | null
  /**
   * @default "AI"
   */
  filter_origin?: FilterOrigin
  /**
   * @default true
   * @type boolean | undefined
   */
  is_enabled?: boolean
  /**
   * @default "dimension"
   * @type string | undefined
   */
  field_type?: DimensionFilterFieldType
  /**
   * @default false
   * @type boolean | undefined
   */
  is_result_filter?: boolean
  /**
   * @type array
   */
  conditions: DimensionFilterCondition[]
}