// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tableColumnSchema.ts"
);
import.meta.hot.lastModified = "1737759758413.0356";
}
// REMIX HMR END

// @ts-nocheck
import { columnValueSchema } from './columnValueSchema'
import { filterCategorySchema } from './filterCategorySchema'
import { z } from 'zod'

/**
 * @description Table column.
 */
export const tableColumnSchema = z
  .object({
    name: z.string(),
    dtype: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    is_nullable: z.union([z.boolean(), z.null()]).optional(),
    column_default: z.union([z.string(), z.null()]).optional(),
    data_type_category: z.union([z.lazy(() => filterCategorySchema), z.null()]).optional(),
    ds_id: z.union([z.string(), z.null()]).optional(),
    top_values: z.union([z.array(z.lazy(() => columnValueSchema)), z.null()]).optional(),
    total_unique_values: z.union([z.number().int(), z.null()]).optional(),
    unique_value_ratio: z.union([z.number(), z.null()]).optional(),
  })
  .describe('Table column.')