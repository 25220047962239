// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateCellAdminOnly.ts"
);
import.meta.hot.lastModified = "1737935767879.746";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateCellAdminOnlyMutationRequest,
  UpdateCellAdminOnlyMutationResponse,
  UpdateCellAdminOnlyPathParams,
  UpdateCellAdminOnly422,
} from '../types/UpdateCellAdminOnly'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateCellAdminOnlyMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/admin_only' }] as const

export type UpdateCellAdminOnlyMutationKey = ReturnType<typeof updateCellAdminOnlyMutationKey>

/**
 * @description Update a Bumblebee Cell's question only.This is an admin endpoint. One use case is question-only update of sensitive cells.
 * @summary Update Cell Admin Only
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/admin_only}
 */
async function updateCellAdminOnly(
  {
    accountName,
    conversationId,
    cellId,
    data,
  }: {
    accountName: UpdateCellAdminOnlyPathParams['account_name']
    conversationId: UpdateCellAdminOnlyPathParams['conversation_id']
    cellId: UpdateCellAdminOnlyPathParams['cell_id']
    data?: UpdateCellAdminOnlyMutationRequest
  },
  config: Partial<RequestConfig<UpdateCellAdminOnlyMutationRequest>> = {},
) {
  const res = await client<UpdateCellAdminOnlyMutationResponse, UpdateCellAdminOnly422, UpdateCellAdminOnlyMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/admin_only`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a Bumblebee Cell's question only.This is an admin endpoint. One use case is question-only update of sensitive cells.
 * @summary Update Cell Admin Only
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/admin_only}
 */
export function useUpdateCellAdminOnly(
  options: {
    mutation?: UseMutationOptions<
      UpdateCellAdminOnlyMutationResponse,
      UpdateCellAdminOnly422,
      {
        accountName: UpdateCellAdminOnlyPathParams['account_name']
        conversationId: UpdateCellAdminOnlyPathParams['conversation_id']
        cellId: UpdateCellAdminOnlyPathParams['cell_id']
        data?: UpdateCellAdminOnlyMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateCellAdminOnlyMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateCellAdminOnlyMutationKey()

  return useMutation<
    UpdateCellAdminOnlyMutationResponse,
    UpdateCellAdminOnly422,
    {
      accountName: UpdateCellAdminOnlyPathParams['account_name']
      conversationId: UpdateCellAdminOnlyPathParams['conversation_id']
      cellId: UpdateCellAdminOnlyPathParams['cell_id']
      data?: UpdateCellAdminOnlyMutationRequest
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, data }) => {
      return updateCellAdminOnly({ accountName, conversationId, cellId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}