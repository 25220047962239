// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/conversationApiCreateSchema.ts"
);
import.meta.hot.lastModified = "1737935768445.743";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Properties to use in API for creating.
 */
export const conversationApiCreateSchema = z
  .object({
    is_pinned: z.union([z.boolean(), z.null()]).optional(),
    name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    is_moonlight: z.boolean().default(false),
    is_training: z.boolean().default(false),
    is_eval_conversation: z.boolean().default(false),
    first_cell_question: z.union([z.string(), z.null()]).optional(),
    first_cell_ssds_id: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Properties to use in API for creating.')