// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadTaskById.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadTaskByIdQueryResponse, ReadTaskByIdPathParams, ReadTaskById422 } from '../types/ReadTaskById'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readTaskByIdQueryKey = ({
  accountName,
  taskId,
}: {
  accountName: ReadTaskByIdPathParams['account_name']
  taskId: ReadTaskByIdPathParams['task_id']
}) => [{ url: '/api/v1/accounts/:account_name/tasks/:task_id', params: { accountName: accountName, taskId: taskId } }] as const

export type ReadTaskByIdQueryKey = ReturnType<typeof readTaskByIdQueryKey>

/**
 * @description Get a specific task by id.
 * @summary Read Task By Id
 * {@link /api/v1/accounts/:account_name/tasks/:task_id}
 */
async function readTaskById(
  { accountName, taskId }: { accountName: ReadTaskByIdPathParams['account_name']; taskId: ReadTaskByIdPathParams['task_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadTaskByIdQueryResponse, ReadTaskById422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/tasks/${taskId}`,
    ...config,
  })
  return res.data
}

export function readTaskByIdQueryOptions(
  { accountName, taskId }: { accountName: ReadTaskByIdPathParams['account_name']; taskId: ReadTaskByIdPathParams['task_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readTaskByIdQueryKey({ accountName, taskId })
  return queryOptions({
    enabled: !!(accountName && taskId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readTaskById({ accountName, taskId }, config)
    },
  })
}

/**
 * @description Get a specific task by id.
 * @summary Read Task By Id
 * {@link /api/v1/accounts/:account_name/tasks/:task_id}
 */
export function useReadTaskById<TData = ReadTaskByIdQueryResponse, TQueryData = ReadTaskByIdQueryResponse, TQueryKey extends QueryKey = ReadTaskByIdQueryKey>(
  { accountName, taskId }: { accountName: ReadTaskByIdPathParams['account_name']; taskId: ReadTaskByIdPathParams['task_id'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadTaskByIdQueryResponse, ReadTaskById422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readTaskByIdQueryKey({ accountName, taskId })

  const query = useQuery({
    ...(readTaskByIdQueryOptions({ accountName, taskId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadTaskById422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}