// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRunMoonlight.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RunMoonlightMutationRequest, RunMoonlightMutationResponse, RunMoonlightPathParams, RunMoonlight422 } from '../types/RunMoonlight'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const runMoonlightMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/run_moonlight' }] as const

export type RunMoonlightMutationKey = ReturnType<typeof runMoonlightMutationKey>

/**
 * @description Run Moonlight.
 * @summary Run Moonlight
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/run_moonlight}
 */
async function runMoonlight(
  {
    accountName,
    conversationId,
    data,
  }: { accountName: RunMoonlightPathParams['account_name']; conversationId: RunMoonlightPathParams['conversation_id']; data: RunMoonlightMutationRequest },
  config: Partial<RequestConfig<RunMoonlightMutationRequest>> = {},
) {
  const res = await client<RunMoonlightMutationResponse, RunMoonlight422, RunMoonlightMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/run_moonlight`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Run Moonlight.
 * @summary Run Moonlight
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/run_moonlight}
 */
export function useRunMoonlight(
  options: {
    mutation?: UseMutationOptions<
      RunMoonlightMutationResponse,
      RunMoonlight422,
      { accountName: RunMoonlightPathParams['account_name']; conversationId: RunMoonlightPathParams['conversation_id']; data: RunMoonlightMutationRequest }
    >
    client?: Partial<RequestConfig<RunMoonlightMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? runMoonlightMutationKey()

  return useMutation<
    RunMoonlightMutationResponse,
    RunMoonlight422,
    { accountName: RunMoonlightPathParams['account_name']; conversationId: RunMoonlightPathParams['conversation_id']; data: RunMoonlightMutationRequest }
  >({
    mutationFn: async ({ accountName, conversationId, data }) => {
      return runMoonlight({ accountName, conversationId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}