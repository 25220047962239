// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyEnrichSsdsApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdEnrichSsdsPostSchema.ts"
);
import.meta.hot.lastModified = "1737759762609.014";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const bodyEnrichSsdsApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdEnrichSsdsPostSchema = z.object({
  enable: z.boolean().default(true),
  update_index: z.boolean().default(true),
  update_popularity: z.boolean().default(true),
  update_expr_types: z.boolean().default(true),
  update_display_types: z.boolean().default(true),
  update_profiles: z.boolean().default(true),
  force_update_profiles: z.boolean().default(false),
  num_rows_to_profile: z.union([z.number().int(), z.null()]).optional(),
  top_k_profile_values_for_retrieval: z.union([z.number().int(), z.null()]).optional(),
})