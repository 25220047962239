// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadEnterpriseByName.ts"
);
import.meta.hot.lastModified = "1737759760888.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadEnterpriseByNameQueryResponse, ReadEnterpriseByNamePathParams, ReadEnterpriseByName422 } from '../types/ReadEnterpriseByName'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readEnterpriseByNameQueryKey = ({ enterpriseName }: { enterpriseName: ReadEnterpriseByNamePathParams['enterprise_name'] }) =>
  [{ url: '/api/v1/enterprises/:enterprise_name', params: { enterpriseName: enterpriseName } }] as const

export type ReadEnterpriseByNameQueryKey = ReturnType<typeof readEnterpriseByNameQueryKey>

/**
 * @description Get a specific enterprise by name.
 * @summary Read Enterprise By Name
 * {@link /api/v1/enterprises/:enterprise_name}
 */
async function readEnterpriseByName(
  { enterpriseName }: { enterpriseName: ReadEnterpriseByNamePathParams['enterprise_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadEnterpriseByNameQueryResponse, ReadEnterpriseByName422, unknown>({
    method: 'GET',
    url: `/api/v1/enterprises/${enterpriseName}`,
    ...config,
  })
  return res.data
}

export function readEnterpriseByNameQueryOptions(
  { enterpriseName }: { enterpriseName: ReadEnterpriseByNamePathParams['enterprise_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readEnterpriseByNameQueryKey({ enterpriseName })
  return queryOptions({
    enabled: !!enterpriseName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readEnterpriseByName({ enterpriseName }, config)
    },
  })
}

/**
 * @description Get a specific enterprise by name.
 * @summary Read Enterprise By Name
 * {@link /api/v1/enterprises/:enterprise_name}
 */
export function useReadEnterpriseByName<
  TData = ReadEnterpriseByNameQueryResponse,
  TQueryData = ReadEnterpriseByNameQueryResponse,
  TQueryKey extends QueryKey = ReadEnterpriseByNameQueryKey,
>(
  { enterpriseName }: { enterpriseName: ReadEnterpriseByNamePathParams['enterprise_name'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadEnterpriseByNameQueryResponse, ReadEnterpriseByName422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readEnterpriseByNameQueryKey({ enterpriseName })

  const query = useQuery({
    ...(readEnterpriseByNameQueryOptions({ enterpriseName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadEnterpriseByName422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}