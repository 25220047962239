// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetEnterpriseAccounts.ts"
);
import.meta.hot.lastModified = "1737759761240.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetEnterpriseAccountsQueryResponse,
  GetEnterpriseAccountsPathParams,
  GetEnterpriseAccountsQueryParams,
  GetEnterpriseAccounts422,
} from '../types/GetEnterpriseAccounts'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getEnterpriseAccountsQueryKey = (
  { enterpriseName }: { enterpriseName: GetEnterpriseAccountsPathParams['enterprise_name'] },
  params?: GetEnterpriseAccountsQueryParams,
) => [{ url: '/api/v1/enterprises/:enterprise_name/accounts', params: { enterpriseName: enterpriseName } }, ...(params ? [params] : [])] as const

export type GetEnterpriseAccountsQueryKey = ReturnType<typeof getEnterpriseAccountsQueryKey>

/**
 * @description Get all accounts in the enterprise.
 * @summary Get Enterprise Accounts
 * {@link /api/v1/enterprises/:enterprise_name/accounts}
 */
async function getEnterpriseAccounts(
  { enterpriseName, params }: { enterpriseName: GetEnterpriseAccountsPathParams['enterprise_name']; params?: GetEnterpriseAccountsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetEnterpriseAccountsQueryResponse, GetEnterpriseAccounts422, unknown>({
    method: 'GET',
    url: `/api/v1/enterprises/${enterpriseName}/accounts`,
    params,
    ...config,
  })
  return res.data
}

export function getEnterpriseAccountsQueryOptions(
  { enterpriseName, params }: { enterpriseName: GetEnterpriseAccountsPathParams['enterprise_name']; params?: GetEnterpriseAccountsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getEnterpriseAccountsQueryKey({ enterpriseName }, params)
  return queryOptions({
    enabled: !!enterpriseName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getEnterpriseAccounts({ enterpriseName, params }, config)
    },
  })
}

/**
 * @description Get all accounts in the enterprise.
 * @summary Get Enterprise Accounts
 * {@link /api/v1/enterprises/:enterprise_name/accounts}
 */
export function useGetEnterpriseAccounts<
  TData = GetEnterpriseAccountsQueryResponse,
  TQueryData = GetEnterpriseAccountsQueryResponse,
  TQueryKey extends QueryKey = GetEnterpriseAccountsQueryKey,
>(
  { enterpriseName, params }: { enterpriseName: GetEnterpriseAccountsPathParams['enterprise_name']; params?: GetEnterpriseAccountsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetEnterpriseAccountsQueryResponse, GetEnterpriseAccounts422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getEnterpriseAccountsQueryKey({ enterpriseName }, params)

  const query = useQuery({
    ...(getEnterpriseAccountsQueryOptions({ enterpriseName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetEnterpriseAccounts422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}