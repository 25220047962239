// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadCellRunEventsByCell.ts"
);
import.meta.hot.lastModified = "1737759761466.0198";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadCellRunEventsByCellQueryResponse, ReadCellRunEventsByCellPathParams, ReadCellRunEventsByCell422 } from '../types/ReadCellRunEventsByCell'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readCellRunEventsByCellQueryKey = ({
  cellId,
  accountName,
}: {
  cellId: ReadCellRunEventsByCellPathParams['cell_id']
  accountName: ReadCellRunEventsByCellPathParams['account_name']
}) => [{ url: '/api/v1/accounts/:account_name/cell_run_events/cell/:cell_id', params: { accountName: accountName, cellId: cellId } }] as const

export type ReadCellRunEventsByCellQueryKey = ReturnType<typeof readCellRunEventsByCellQueryKey>

/**
 * @description Read all cell run events by cell id.
 * @summary Read Cell Run Events By Cell
 * {@link /api/v1/accounts/:account_name/cell_run_events/cell/:cell_id}
 */
async function readCellRunEventsByCell(
  { cellId, accountName }: { cellId: ReadCellRunEventsByCellPathParams['cell_id']; accountName: ReadCellRunEventsByCellPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadCellRunEventsByCellQueryResponse, ReadCellRunEventsByCell422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/cell_run_events/cell/${cellId}`,
    ...config,
  })
  return res.data
}

export function readCellRunEventsByCellQueryOptions(
  { cellId, accountName }: { cellId: ReadCellRunEventsByCellPathParams['cell_id']; accountName: ReadCellRunEventsByCellPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readCellRunEventsByCellQueryKey({ cellId, accountName })
  return queryOptions({
    enabled: !!(cellId && accountName),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readCellRunEventsByCell({ cellId, accountName }, config)
    },
  })
}

/**
 * @description Read all cell run events by cell id.
 * @summary Read Cell Run Events By Cell
 * {@link /api/v1/accounts/:account_name/cell_run_events/cell/:cell_id}
 */
export function useReadCellRunEventsByCell<
  TData = ReadCellRunEventsByCellQueryResponse,
  TQueryData = ReadCellRunEventsByCellQueryResponse,
  TQueryKey extends QueryKey = ReadCellRunEventsByCellQueryKey,
>(
  { cellId, accountName }: { cellId: ReadCellRunEventsByCellPathParams['cell_id']; accountName: ReadCellRunEventsByCellPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadCellRunEventsByCellQueryResponse, ReadCellRunEventsByCell422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readCellRunEventsByCellQueryKey({ cellId, accountName })

  const query = useQuery({
    ...(readCellRunEventsByCellQueryOptions({ cellId, accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadCellRunEventsByCell422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}