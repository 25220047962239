// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useClearBbVerifiedCellFeedbackChat.ts"
);
import.meta.hot.lastModified = "1737759762512.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ClearBbVerifiedCellFeedbackChatMutationResponse,
  ClearBbVerifiedCellFeedbackChatPathParams,
  ClearBbVerifiedCellFeedbackChat422,
} from '../types/ClearBbVerifiedCellFeedbackChat'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const clearBbVerifiedCellFeedbackChatMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/clear_bb_verified_cell_feedback_chat' }] as const

export type ClearBbVerifiedCellFeedbackChatMutationKey = ReturnType<typeof clearBbVerifiedCellFeedbackChatMutationKey>

/**
 * @description Clear bumblebee verified question converted feedback chats.
 * @summary Clear Bb Verified Cell Feedback Chat
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/clear_bb_verified_cell_feedback_chat}
 */
async function clearBbVerifiedCellFeedbackChat(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: ClearBbVerifiedCellFeedbackChatPathParams['account_name']
    starSchemaDataSourceId: ClearBbVerifiedCellFeedbackChatPathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ClearBbVerifiedCellFeedbackChatMutationResponse, ClearBbVerifiedCellFeedbackChat422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/clear_bb_verified_cell_feedback_chat`,
    ...config,
  })
  return res.data
}

/**
 * @description Clear bumblebee verified question converted feedback chats.
 * @summary Clear Bb Verified Cell Feedback Chat
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/clear_bb_verified_cell_feedback_chat}
 */
export function useClearBbVerifiedCellFeedbackChat(
  options: {
    mutation?: UseMutationOptions<
      ClearBbVerifiedCellFeedbackChatMutationResponse,
      ClearBbVerifiedCellFeedbackChat422,
      {
        accountName: ClearBbVerifiedCellFeedbackChatPathParams['account_name']
        starSchemaDataSourceId: ClearBbVerifiedCellFeedbackChatPathParams['star_schema_data_source_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? clearBbVerifiedCellFeedbackChatMutationKey()

  return useMutation<
    ClearBbVerifiedCellFeedbackChatMutationResponse,
    ClearBbVerifiedCellFeedbackChat422,
    {
      accountName: ClearBbVerifiedCellFeedbackChatPathParams['account_name']
      starSchemaDataSourceId: ClearBbVerifiedCellFeedbackChatPathParams['star_schema_data_source_id']
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId }) => {
      return clearBbVerifiedCellFeedbackChat({ accountName, starSchemaDataSourceId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}