// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getDmUsageSchema.ts"
);
import.meta.hot.lastModified = "1737935765531.759";
}
// REMIX HMR END

// @ts-nocheck
import { feedbackFilterSchema } from './feedbackFilterSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getDmUsagePathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const getDmUsageQueryParamsSchema = z
  .object({
    augment_dms: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getDmUsage200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const getDmUsage422Schema = z.lazy(() => httpValidationErrorSchema)

export const getDmUsageQueryRequestSchema = z.union([z.lazy(() => feedbackFilterSchema), z.null()])

export const getDmUsageQueryResponseSchema = z.lazy(() => getDmUsage200Schema)