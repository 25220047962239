// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/axisSchema.ts"
);
import.meta.hot.lastModified = "1737759757505.0403";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Enumerates the X and Y axis.
 */
export const axisSchema = z.enum(['x', 'y', 'size', 'group']).describe('Enumerates the X and Y axis.')