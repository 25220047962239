// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dataSourceSchema.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionViewSchema } from './dimensionViewSchema'
import { identifierSchema } from './identifierSchema'
import { metricViewSchema } from './metricViewSchema'
import { z } from 'zod'

/**
 * @description DataSource class.
 */
export const dataSourceSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    index: z.number().int().default(0),
    name: z.union([z.string(), z.null()]).optional(),
    sql_table: z.union([z.string(), z.null()]).optional(),
    derived_table_sql: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    identifiers: z.array(z.lazy(() => identifierSchema)).optional(),
    dimensions: z.array(z.lazy(() => dimensionViewSchema)).optional(),
    metrics: z.array(z.lazy(() => metricViewSchema)).optional(),
  })
  .describe('DataSource class.')