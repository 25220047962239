// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateSqlValidation.ts"
);
import.meta.hot.lastModified = "1737935767879.746";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateSqlValidationMutationRequest,
  UpdateSqlValidationMutationResponse,
  UpdateSqlValidationPathParams,
  UpdateSqlValidation422,
} from '../types/UpdateSqlValidation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateSqlValidationMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation/{sql_validation_id}' }] as const

export type UpdateSqlValidationMutationKey = ReturnType<typeof updateSqlValidationMutationKey>

/**
 * @description Recreates the SQL validation, effectively updating it.
 * @summary Update Sql Validation
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sql_validation/:sql_validation_id}
 */
async function updateSqlValidation(
  {
    accountName,
    starSchemaDataSourceId,
    sqlValidationId,
    data,
  }: {
    accountName: UpdateSqlValidationPathParams['account_name']
    starSchemaDataSourceId: UpdateSqlValidationPathParams['star_schema_data_source_id']
    sqlValidationId: UpdateSqlValidationPathParams['sql_validation_id']
    data: UpdateSqlValidationMutationRequest
  },
  config: Partial<RequestConfig<UpdateSqlValidationMutationRequest>> = {},
) {
  const res = await client<UpdateSqlValidationMutationResponse, UpdateSqlValidation422, UpdateSqlValidationMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/sql_validation/${sqlValidationId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Recreates the SQL validation, effectively updating it.
 * @summary Update Sql Validation
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sql_validation/:sql_validation_id}
 */
export function useUpdateSqlValidation(
  options: {
    mutation?: UseMutationOptions<
      UpdateSqlValidationMutationResponse,
      UpdateSqlValidation422,
      {
        accountName: UpdateSqlValidationPathParams['account_name']
        starSchemaDataSourceId: UpdateSqlValidationPathParams['star_schema_data_source_id']
        sqlValidationId: UpdateSqlValidationPathParams['sql_validation_id']
        data: UpdateSqlValidationMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateSqlValidationMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateSqlValidationMutationKey()

  return useMutation<
    UpdateSqlValidationMutationResponse,
    UpdateSqlValidation422,
    {
      accountName: UpdateSqlValidationPathParams['account_name']
      starSchemaDataSourceId: UpdateSqlValidationPathParams['star_schema_data_source_id']
      sqlValidationId: UpdateSqlValidationPathParams['sql_validation_id']
      data: UpdateSqlValidationMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, sqlValidationId, data }) => {
      return updateSqlValidation({ accountName, starSchemaDataSourceId, sqlValidationId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}