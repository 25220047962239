// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tablePayloadSchema.ts"
);
import.meta.hot.lastModified = "1737935766026.7563";
}
// REMIX HMR END

// @ts-nocheck
import { columnMetadataV2Schema } from './columnMetadataV2Schema'
import { z } from 'zod'

/**
 * @description Payload for a paginated table of data.\n\nAttributes:\n    data: The data in the table. Each element in the list is a row in the table.\n        The keys are the column names and the values are the values in the row.\n    total: The total number of rows in the table.\n    column_metadata: The metadata for the columns in the table.
 */
export const tablePayloadSchema = z
  .object({
    data: z.array(z.object({})),
    total: z.number().int(),
    column_metadata: z.array(z.lazy(() => columnMetadataV2Schema)),
  })
  .describe(
    'Payload for a paginated table of data.\n\nAttributes:\n    data: The data in the table. Each element in the list is a row in the table.\n        The keys are the column names and the values are the values in the row.\n    total: The total number of rows in the table.\n    column_metadata: The metadata for the columns in the table.',
  )