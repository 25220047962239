// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsMetrics.ts"
);
import.meta.hot.lastModified = "1737935766472.754";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetSsdsMetricsQueryResponse, GetSsdsMetricsPathParams, GetSsdsMetricsQueryParams, GetSsdsMetrics422 } from '../types/GetSsdsMetrics'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsMetricsQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsMetricsPathParams['account_name']; starSchemaDataSourceId: GetSsdsMetricsPathParams['star_schema_data_source_id'] },
  params?: GetSsdsMetricsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/metrics',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetSsdsMetricsQueryKey = ReturnType<typeof getSsdsMetricsQueryKey>

/**
 * @description Get Metrics for Star Schema Data Source.This endpoint combines the metrics from the SSDS with all the metrics from each Data Source.If DataSourceIds are specified, then only metrics that referencethe specified Data Sources are returned.If cell_id is specified, then the metrics will be filtered out if they arestate NEW and not present in the cell's conversation.
 * @summary Get Ssds Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/metrics}
 */
async function getSsdsMetrics(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsMetricsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsMetricsPathParams['star_schema_data_source_id']
    params?: GetSsdsMetricsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsMetricsQueryResponse, GetSsdsMetrics422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/metrics`,
    params,
    ...config,
  })
  return res.data
}

export function getSsdsMetricsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsMetricsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsMetricsPathParams['star_schema_data_source_id']
    params?: GetSsdsMetricsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsMetricsQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsMetrics({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get Metrics for Star Schema Data Source.This endpoint combines the metrics from the SSDS with all the metrics from each Data Source.If DataSourceIds are specified, then only metrics that referencethe specified Data Sources are returned.If cell_id is specified, then the metrics will be filtered out if they arestate NEW and not present in the cell's conversation.
 * @summary Get Ssds Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/metrics}
 */
export function useGetSsdsMetrics<
  TData = GetSsdsMetricsQueryResponse,
  TQueryData = GetSsdsMetricsQueryResponse,
  TQueryKey extends QueryKey = GetSsdsMetricsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsMetricsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsMetricsPathParams['star_schema_data_source_id']
    params?: GetSsdsMetricsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsMetricsQueryResponse, GetSsdsMetrics422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsMetricsQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getSsdsMetricsQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsMetrics422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}