// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSendMessageToConversation.ts"
);
import.meta.hot.lastModified = "1737759761863.0178";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  SendMessageToConversationMutationRequest,
  SendMessageToConversationMutationResponse,
  SendMessageToConversationPathParams,
  SendMessageToConversation422,
} from '../types/SendMessageToConversation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const sendMessageToConversationMutationKey = () => [{ url: '/api/v2/orgs/{account_name}/conversations/{conversation_id}/messages' }] as const

export type SendMessageToConversationMutationKey = ReturnType<typeof sendMessageToConversationMutationKey>

/**
 * @description Send a message to a conversation.This endpoint sends a message to an existing application. The response includes themetadata about the created message. Note that the only message payload type thatcan be sent is TEXT.
 * @summary Send Message To Conversation
 * {@link /api/v2/orgs/:account_name/conversations/:conversation_id/messages}
 */
async function sendMessageToConversation(
  {
    accountName,
    conversationId,
    data,
  }: {
    accountName: SendMessageToConversationPathParams['account_name']
    conversationId: SendMessageToConversationPathParams['conversation_id']
    data: SendMessageToConversationMutationRequest
  },
  config: Partial<RequestConfig<SendMessageToConversationMutationRequest>> = {},
) {
  const res = await client<SendMessageToConversationMutationResponse, SendMessageToConversation422, SendMessageToConversationMutationRequest>({
    method: 'POST',
    url: `/api/v2/orgs/${accountName}/conversations/${conversationId}/messages`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Send a message to a conversation.This endpoint sends a message to an existing application. The response includes themetadata about the created message. Note that the only message payload type thatcan be sent is TEXT.
 * @summary Send Message To Conversation
 * {@link /api/v2/orgs/:account_name/conversations/:conversation_id/messages}
 */
export function useSendMessageToConversation(
  options: {
    mutation?: UseMutationOptions<
      SendMessageToConversationMutationResponse,
      SendMessageToConversation422,
      {
        accountName: SendMessageToConversationPathParams['account_name']
        conversationId: SendMessageToConversationPathParams['conversation_id']
        data: SendMessageToConversationMutationRequest
      }
    >
    client?: Partial<RequestConfig<SendMessageToConversationMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? sendMessageToConversationMutationKey()

  return useMutation<
    SendMessageToConversationMutationResponse,
    SendMessageToConversation422,
    {
      accountName: SendMessageToConversationPathParams['account_name']
      conversationId: SendMessageToConversationPathParams['conversation_id']
      data: SendMessageToConversationMutationRequest
    }
  >({
    mutationFn: async ({ accountName, conversationId, data }) => {
      return sendMessageToConversation({ accountName, conversationId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}