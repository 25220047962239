// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ZenMetric.ts"
);
import.meta.hot.lastModified = "1737935754062.822";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Dialect } from './Dialect';
import type { DisplayType } from './DisplayType';
import type { FilterCategory } from './FilterCategory';
import type { OriginType } from './OriginType';
import type { PublishState } from './PublishState';
import type { TimeGranularity } from './TimeGranularity';
import type { ZenDimension } from './ZenDimension';
import type { ZenFieldFilter } from './ZenFieldFilter';

/**
 * Metrics layer metric.
 *
 * Ref: https://docs.zenlytic.com/docs/data_modeling/measure
 */
export type ZenMetric = {
  id?: (string | null);
  sync_id?: (string | null);
  group_id?: (string | null);
  name: string;
  field_type?: ZenMetric.field_type;
  description?: (string | null);
  sql: string;
  ns_expr?: (string | null);
  measure?: (string | null);
  filters?: (Array<ZenFieldFilter> | null);
  canon_date?: (ZenDimension | null);
  window_size?: (number | null);
  window_granularity?: (TimeGranularity | null);
  display_name?: (string | null);
  expr_type?: FilterCategory;
  state?: PublishState;
  display_type?: DisplayType;
  data_source_id?: (string | null);
  is_sensitive?: boolean;
  is_literal_sensitive?: boolean;
  origin?: Array<OriginType>;
  dialect?: Dialect;
  popularity?: number;
  updated_at?: (string | null);
  created_at?: (string | null);
};

export namespace ZenMetric {

  export enum field_type {
    MEASURE = 'measure',
  }


}

