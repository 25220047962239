// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCheckFeedbackChatHasAdminFeedback.ts"
);
import.meta.hot.lastModified = "1737935769655.7363";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CheckFeedbackChatHasAdminFeedbackQueryResponse,
  CheckFeedbackChatHasAdminFeedbackPathParams,
  CheckFeedbackChatHasAdminFeedback422,
} from '../types/CheckFeedbackChatHasAdminFeedback'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const checkFeedbackChatHasAdminFeedbackQueryKey = ({
  accountName,
  chatId,
}: {
  accountName: CheckFeedbackChatHasAdminFeedbackPathParams['account_name']
  chatId: CheckFeedbackChatHasAdminFeedbackPathParams['chat_id']
}) => [{ url: '/api/v3/orgs/:account_name/chat/:chat_id/has_admin_feedback', params: { accountName: accountName, chatId: chatId } }] as const

export type CheckFeedbackChatHasAdminFeedbackQueryKey = ReturnType<typeof checkFeedbackChatHasAdminFeedbackQueryKey>

/**
 * @description Check if the feedback chat has admin feedback.
 * @summary Check Feedback Chat Has Admin Feedback
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/has_admin_feedback}
 */
async function checkFeedbackChatHasAdminFeedback(
  {
    accountName,
    chatId,
  }: { accountName: CheckFeedbackChatHasAdminFeedbackPathParams['account_name']; chatId: CheckFeedbackChatHasAdminFeedbackPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<CheckFeedbackChatHasAdminFeedbackQueryResponse, CheckFeedbackChatHasAdminFeedback422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/chat/${chatId}/has_admin_feedback`,
    ...config,
  })
  return res.data
}

export function checkFeedbackChatHasAdminFeedbackQueryOptions(
  {
    accountName,
    chatId,
  }: { accountName: CheckFeedbackChatHasAdminFeedbackPathParams['account_name']; chatId: CheckFeedbackChatHasAdminFeedbackPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = checkFeedbackChatHasAdminFeedbackQueryKey({ accountName, chatId })
  return queryOptions({
    enabled: !!(accountName && chatId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return checkFeedbackChatHasAdminFeedback({ accountName, chatId }, config)
    },
  })
}

/**
 * @description Check if the feedback chat has admin feedback.
 * @summary Check Feedback Chat Has Admin Feedback
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/has_admin_feedback}
 */
export function useCheckFeedbackChatHasAdminFeedback<
  TData = CheckFeedbackChatHasAdminFeedbackQueryResponse,
  TQueryData = CheckFeedbackChatHasAdminFeedbackQueryResponse,
  TQueryKey extends QueryKey = CheckFeedbackChatHasAdminFeedbackQueryKey,
>(
  {
    accountName,
    chatId,
  }: { accountName: CheckFeedbackChatHasAdminFeedbackPathParams['account_name']; chatId: CheckFeedbackChatHasAdminFeedbackPathParams['chat_id'] },
  options: {
    query?: Partial<QueryObserverOptions<CheckFeedbackChatHasAdminFeedbackQueryResponse, CheckFeedbackChatHasAdminFeedback422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? checkFeedbackChatHasAdminFeedbackQueryKey({ accountName, chatId })

  const query = useQuery({
    ...(checkFeedbackChatHasAdminFeedbackQueryOptions({ accountName, chatId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, CheckFeedbackChatHasAdminFeedback422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}