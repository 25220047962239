// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteAllNamedFilters.ts"
);
import.meta.hot.lastModified = "1737759761256.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteAllNamedFiltersMutationResponse, DeleteAllNamedFiltersPathParams, DeleteAllNamedFilters422 } from '../types/DeleteAllNamedFilters'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteAllNamedFiltersMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/named_filters' }] as const

export type DeleteAllNamedFiltersMutationKey = ReturnType<typeof deleteAllNamedFiltersMutationKey>

/**
 * @description Delete all Named Filters matching SSDS id.
 * @summary Delete All Named Filters
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/named_filters}
 */
async function deleteAllNamedFilters(
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: DeleteAllNamedFiltersPathParams['account_name']; starSchemaDataSourceId: DeleteAllNamedFiltersPathParams['star_schema_data_source_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteAllNamedFiltersMutationResponse, DeleteAllNamedFilters422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/named_filters`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete all Named Filters matching SSDS id.
 * @summary Delete All Named Filters
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/named_filters}
 */
export function useDeleteAllNamedFilters(
  options: {
    mutation?: UseMutationOptions<
      DeleteAllNamedFiltersMutationResponse,
      DeleteAllNamedFilters422,
      { accountName: DeleteAllNamedFiltersPathParams['account_name']; starSchemaDataSourceId: DeleteAllNamedFiltersPathParams['star_schema_data_source_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteAllNamedFiltersMutationKey()

  return useMutation<
    DeleteAllNamedFiltersMutationResponse,
    DeleteAllNamedFilters422,
    { accountName: DeleteAllNamedFiltersPathParams['account_name']; starSchemaDataSourceId: DeleteAllNamedFiltersPathParams['star_schema_data_source_id'] }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId }) => {
      return deleteAllNamedFilters({ accountName, starSchemaDataSourceId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}