// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetAccountSsdsSemanticQueries.ts"
);
import.meta.hot.lastModified = "1737935769433.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetAccountSsdsSemanticQueriesQueryResponse,
  GetAccountSsdsSemanticQueriesPathParams,
  GetAccountSsdsSemanticQueries422,
} from '../types/GetAccountSsdsSemanticQueries'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAccountSsdsSemanticQueriesQueryKey = ({
  accountName,
  starSchemaDataSourceId,
}: {
  accountName: GetAccountSsdsSemanticQueriesPathParams['account_name']
  starSchemaDataSourceId: GetAccountSsdsSemanticQueriesPathParams['star_schema_data_source_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/semantic_queries',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
  ] as const

export type GetAccountSsdsSemanticQueriesQueryKey = ReturnType<typeof getAccountSsdsSemanticQueriesQueryKey>

/**
 * @description Get semantic queries for the given account and ssds.
 * @summary Get Account Ssds Semantic Queries
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/semantic_queries}
 */
async function getAccountSsdsSemanticQueries(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetAccountSsdsSemanticQueriesPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsSemanticQueriesPathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetAccountSsdsSemanticQueriesQueryResponse, GetAccountSsdsSemanticQueries422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/semantic_queries`,
    ...config,
  })
  return res.data
}

export function getAccountSsdsSemanticQueriesQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetAccountSsdsSemanticQueriesPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsSemanticQueriesPathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getAccountSsdsSemanticQueriesQueryKey({ accountName, starSchemaDataSourceId })
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAccountSsdsSemanticQueries({ accountName, starSchemaDataSourceId }, config)
    },
  })
}

/**
 * @description Get semantic queries for the given account and ssds.
 * @summary Get Account Ssds Semantic Queries
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/semantic_queries}
 */
export function useGetAccountSsdsSemanticQueries<
  TData = GetAccountSsdsSemanticQueriesQueryResponse,
  TQueryData = GetAccountSsdsSemanticQueriesQueryResponse,
  TQueryKey extends QueryKey = GetAccountSsdsSemanticQueriesQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetAccountSsdsSemanticQueriesPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsSemanticQueriesPathParams['star_schema_data_source_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<GetAccountSsdsSemanticQueriesQueryResponse, GetAccountSsdsSemanticQueries422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAccountSsdsSemanticQueriesQueryKey({ accountName, starSchemaDataSourceId })

  const query = useQuery({
    ...(getAccountSsdsSemanticQueriesQueryOptions({ accountName, starSchemaDataSourceId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetAccountSsdsSemanticQueries422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}