// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/taskApiSchema.ts"
);
import.meta.hot.lastModified = "1737759757712.0393";
}
// REMIX HMR END

// @ts-nocheck
import { taskStatusV2Schema } from './taskStatusV2Schema'
import { z } from 'zod'

/**
 * @description The ongoing task associated with some long running job.\n\nAttributes:\n    id: The ID of the task.\n    name: The name of the task.\n    status: The status of the task.\n    completed_at: The time the task was completed. None if the task is not completed.\n    created_at: The time the task was created.\n    updated_at: The time the task was last updated.
 */
export const taskApiSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    status: z.lazy(() => taskStatusV2Schema),
    completed_at: z.union([z.string().datetime(), z.null()]),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
  })
  .describe(
    'The ongoing task associated with some long running job.\n\nAttributes:\n    id: The ID of the task.\n    name: The name of the task.\n    status: The status of the task.\n    completed_at: The time the task was completed. None if the task is not completed.\n    created_at: The time the task was created.\n    updated_at: The time the task was last updated.',
  )