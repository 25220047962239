// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/namedFilterBaseSchema.ts"
);
import.meta.hot.lastModified = "1737935766766.7522";
}
// REMIX HMR END

// @ts-nocheck
import { namedFilterOptionBaseSchema } from './namedFilterOptionBaseSchema'
import { publishStateSchema } from './publishStateSchema'
import { z } from 'zod'

/**
 * @description NamedFilter Base Schema.
 */
export const namedFilterBaseSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    name: z.string(),
    ssds_id: z.union([z.string(), z.null()]).optional(),
    filter_options: z.array(z.lazy(() => namedFilterOptionBaseSchema)),
    description: z.union([z.string(), z.null()]).optional(),
    state: z.lazy(() => publishStateSchema).default('Verified'),
    updated_at: z.union([z.string().datetime(), z.null()]).optional(),
    created_at: z.union([z.string().datetime(), z.null()]).optional(),
  })
  .describe('NamedFilter Base Schema.')