// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/DmmType.ts"
);
import.meta.hot.lastModified = "1737759757505.0403";
}
// REMIX HMR END

export enum DmmType {
  'zendimension' = 'zendimension',
  'zenmeasure' = 'zenmeasure',
}