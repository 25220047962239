// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/PublishState.ts"
);
import.meta.hot.lastModified = "1737759757950.038";
}
// REMIX HMR END

export enum PublishState {
  'New' = 'New',
  'Verified' = 'Verified',
  'Invalid' = 'Invalid',
  'Deprecated' = 'Deprecated',
  'Review' = 'Review',
}