// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tableOrViewInfoValidationSchema.ts"
);
import.meta.hot.lastModified = "1737759761867.0178";
}
// REMIX HMR END

// @ts-nocheck
import { errorContentSchema } from './errorContentSchema'
import { starSchemaDataSourceTableOrViewInfoSchema } from './starSchemaDataSourceTableOrViewInfoSchema'
import { z } from 'zod'

export const tableOrViewInfoValidationSchema = z.object({
  info: z.lazy(() => starSchemaDataSourceTableOrViewInfoSchema),
  error: z.union([z.lazy(() => errorContentSchema), z.null()]),
})