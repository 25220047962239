// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadDatabricksConnectionColumns.ts"
);
import.meta.hot.lastModified = "1737759762506.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadDatabricksConnectionColumnsQueryResponse,
  ReadDatabricksConnectionColumnsPathParams,
  ReadDatabricksConnectionColumnsQueryParams,
  ReadDatabricksConnectionColumns422,
} from '../types/ReadDatabricksConnectionColumns'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readDatabricksConnectionColumnsQueryKey = (
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: ReadDatabricksConnectionColumnsPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionColumnsPathParams['databricks_connection_id']
  },
  params: ReadDatabricksConnectionColumnsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/columns',
      params: { accountName: accountName, databricksConnectionId: databricksConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadDatabricksConnectionColumnsQueryKey = ReturnType<typeof readDatabricksConnectionColumnsQueryKey>

/**
 * @description Read Databricks table columns.
 * @summary Read Databricks Connection Columns
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/columns}
 */
async function readDatabricksConnectionColumns(
  {
    accountName,
    databricksConnectionId,
    params,
  }: {
    accountName: ReadDatabricksConnectionColumnsPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionColumnsPathParams['databricks_connection_id']
    params: ReadDatabricksConnectionColumnsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadDatabricksConnectionColumnsQueryResponse, ReadDatabricksConnectionColumns422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/databricks/${databricksConnectionId}/columns`,
    params,
    ...config,
  })
  return res.data
}

export function readDatabricksConnectionColumnsQueryOptions(
  {
    accountName,
    databricksConnectionId,
    params,
  }: {
    accountName: ReadDatabricksConnectionColumnsPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionColumnsPathParams['databricks_connection_id']
    params: ReadDatabricksConnectionColumnsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readDatabricksConnectionColumnsQueryKey({ accountName, databricksConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && databricksConnectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readDatabricksConnectionColumns({ accountName, databricksConnectionId, params }, config)
    },
  })
}

/**
 * @description Read Databricks table columns.
 * @summary Read Databricks Connection Columns
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/columns}
 */
export function useReadDatabricksConnectionColumns<
  TData = ReadDatabricksConnectionColumnsQueryResponse,
  TQueryData = ReadDatabricksConnectionColumnsQueryResponse,
  TQueryKey extends QueryKey = ReadDatabricksConnectionColumnsQueryKey,
>(
  {
    accountName,
    databricksConnectionId,
    params,
  }: {
    accountName: ReadDatabricksConnectionColumnsPathParams['account_name']
    databricksConnectionId: ReadDatabricksConnectionColumnsPathParams['databricks_connection_id']
    params: ReadDatabricksConnectionColumnsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadDatabricksConnectionColumnsQueryResponse, ReadDatabricksConnectionColumns422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readDatabricksConnectionColumnsQueryKey({ accountName, databricksConnectionId }, params)

  const query = useQuery({
    ...(readDatabricksConnectionColumnsQueryOptions({ accountName, databricksConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadDatabricksConnectionColumns422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}