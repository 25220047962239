// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messageApiBatchResponseSchema.ts"
);
import.meta.hot.lastModified = "1737935768791.741";
}
// REMIX HMR END

// @ts-nocheck
import { messagePayloadOptionalSchema } from './messagePayloadOptionalSchema'
import { senderBasicApiResponseSchema } from './senderBasicApiResponseSchema'
import { z } from 'zod'

/**
 * @description A message returned from the API as part of a batch response.\n\nAttributes:\n    id: The ID of the message.\n    index: The index of the message in the conversation. Used for ordering.\n    responds_to: The id of the message that this message responds to. None if it\n        is the first message in the conversation.\n    created_at: The time the message was created.\n    sender: The sender of the message.\n    message_payload: The payload of the message.\n    feedback_thumbs_down: True if thumbs down by current user feedback else False. If\n        user is unknown, then False.
 */
export const messageApiBatchResponseSchema = z
  .object({
    id: z.string(),
    index: z.number().int(),
    responds_to: z.union([z.string(), z.null()]),
    created_at: z.string().datetime(),
    sender: z.lazy(() => senderBasicApiResponseSchema),
    message_payload: z.lazy(() => messagePayloadOptionalSchema),
    feedback_thumb_down: z.boolean(),
  })
  .describe(
    'A message returned from the API as part of a batch response.\n\nAttributes:\n    id: The ID of the message.\n    index: The index of the message in the conversation. Used for ordering.\n    responds_to: The id of the message that this message responds to. None if it\n        is the first message in the conversation.\n    created_at: The time the message was created.\n    sender: The sender of the message.\n    message_payload: The payload of the message.\n    feedback_thumbs_down: True if thumbs down by current user feedback else False. If\n        user is unknown, then False.',
  )