// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bumblebeeDatasetUploadOptionsSchema.ts"
);
import.meta.hot.lastModified = "1737935769440.7375";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetVisibilitySchema } from './dataAssetVisibilitySchema'
import { m2MTokenCredsSchema } from './m2MTokenCredsSchema'
import { starSchemaDataSourceEnrichmentSchema } from './starSchemaDataSourceEnrichmentSchema'
import { z } from 'zod'

/**
 * @description Options for BumblebeeDataset Upload.
 */
export const bumblebeeDatasetUploadOptionsSchema = z
  .object({
    existing_dataset: z.enum(['clear', 'ignore', 'reuse']).default('ignore'),
    skip_validation_check: z.boolean().default(false),
    enrich_params: z.lazy(() => starSchemaDataSourceEnrichmentSchema).default({}),
    visibility: z.union([z.lazy(() => dataAssetVisibilitySchema), z.null()]).optional(),
    allow_conflicting_feedback: z.boolean().default(false),
    clear_named_filters: z.boolean().default(false),
    clear_cells: z.boolean().default(false),
    only_upload_conversations: z.boolean().default(false),
    mark_as_demo: z.boolean().default(false),
    m2m_creds: z.union([z.lazy(() => m2MTokenCredsSchema), z.null()]).optional(),
  })
  .describe('Options for BumblebeeDataset Upload.')