// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/negativeFeedbackApiSendSchema.ts"
);
import.meta.hot.lastModified = "1737759761629.019";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Binary feedback and optional additional text.\n\nAttributes:\n    feedback_text: Optional text to describe the feedback.
 */
export const negativeFeedbackApiSendSchema = z
  .object({
    feedback_text: z.union([z.string(), z.null()]),
  })
  .describe('Binary feedback and optional additional text.\n\nAttributes:\n    feedback_text: Optional text to describe the feedback.')