// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUnmarkCellSensitive.ts"
);
import.meta.hot.lastModified = "1737935767879.746";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UnmarkCellSensitiveMutationResponse, UnmarkCellSensitivePathParams, UnmarkCellSensitive422 } from '../types/UnmarkCellSensitive'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const unmarkCellSensitiveMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/is_user_sensitive' }] as const

export type UnmarkCellSensitiveMutationKey = ReturnType<typeof unmarkCellSensitiveMutationKey>

/**
 * @description Unmark cell sensitive.If the cell uses DMs that are sensitive, it cannot be unmarked.
 * @summary Unmark Cell Sensitive
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/is_user_sensitive}
 */
async function unmarkCellSensitive(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: UnmarkCellSensitivePathParams['account_name']
    conversationId: UnmarkCellSensitivePathParams['conversation_id']
    cellId: UnmarkCellSensitivePathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UnmarkCellSensitiveMutationResponse, UnmarkCellSensitive422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/is_user_sensitive`,
    ...config,
  })
  return res.data
}

/**
 * @description Unmark cell sensitive.If the cell uses DMs that are sensitive, it cannot be unmarked.
 * @summary Unmark Cell Sensitive
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/is_user_sensitive}
 */
export function useUnmarkCellSensitive(
  options: {
    mutation?: UseMutationOptions<
      UnmarkCellSensitiveMutationResponse,
      UnmarkCellSensitive422,
      {
        accountName: UnmarkCellSensitivePathParams['account_name']
        conversationId: UnmarkCellSensitivePathParams['conversation_id']
        cellId: UnmarkCellSensitivePathParams['cell_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? unmarkCellSensitiveMutationKey()

  return useMutation<
    UnmarkCellSensitiveMutationResponse,
    UnmarkCellSensitive422,
    {
      accountName: UnmarkCellSensitivePathParams['account_name']
      conversationId: UnmarkCellSensitivePathParams['conversation_id']
      cellId: UnmarkCellSensitivePathParams['cell_id']
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId }) => {
      return unmarkCellSensitive({ accountName, conversationId, cellId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}