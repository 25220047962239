// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateDimensionIsSensitiveImpactSchema.ts"
);
import.meta.hot.lastModified = "1737759762532.0144";
}
// REMIX HMR END

// @ts-nocheck
import { dmSensitiveKnowledgeImpactSchema } from './dmSensitiveKnowledgeImpactSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateDimensionIsSensitiveImpactPathParamsSchema = z.object({
  account_name: z.string(),
  dimension_id: z.string(),
})

export const updateDimensionIsSensitiveImpactQueryParamsSchema = z.object({
  is_sensitive: z.boolean(),
})

/**
 * @description Successful Response
 */
export const updateDimensionIsSensitiveImpact200Schema = z.lazy(() => dmSensitiveKnowledgeImpactSchema)

/**
 * @description Validation Error
 */
export const updateDimensionIsSensitiveImpact422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateDimensionIsSensitiveImpactMutationResponseSchema = z.lazy(() => updateDimensionIsSensitiveImpact200Schema)