// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteDatabricksConnection.ts"
);
import.meta.hot.lastModified = "1737935769208.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  DeleteDatabricksConnectionMutationResponse,
  DeleteDatabricksConnectionPathParams,
  DeleteDatabricksConnection422,
} from '../types/DeleteDatabricksConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteDatabricksConnectionMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}' }] as const

export type DeleteDatabricksConnectionMutationKey = ReturnType<typeof deleteDatabricksConnectionMutationKey>

/**
 * @description Delete Databricks connection.
 * @summary Delete Databricks Connection
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id}
 */
async function deleteDatabricksConnection(
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: DeleteDatabricksConnectionPathParams['account_name']
    databricksConnectionId: DeleteDatabricksConnectionPathParams['databricks_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteDatabricksConnectionMutationResponse, DeleteDatabricksConnection422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/connections/databricks/${databricksConnectionId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete Databricks connection.
 * @summary Delete Databricks Connection
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id}
 */
export function useDeleteDatabricksConnection(
  options: {
    mutation?: UseMutationOptions<
      DeleteDatabricksConnectionMutationResponse,
      DeleteDatabricksConnection422,
      {
        accountName: DeleteDatabricksConnectionPathParams['account_name']
        databricksConnectionId: DeleteDatabricksConnectionPathParams['databricks_connection_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteDatabricksConnectionMutationKey()

  return useMutation<
    DeleteDatabricksConnectionMutationResponse,
    DeleteDatabricksConnection422,
    {
      accountName: DeleteDatabricksConnectionPathParams['account_name']
      databricksConnectionId: DeleteDatabricksConnectionPathParams['databricks_connection_id']
    }
  >({
    mutationFn: async ({ accountName, databricksConnectionId }) => {
      return deleteDatabricksConnection({ accountName, databricksConnectionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}