// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/reportSchema.ts"
);
import.meta.hot.lastModified = "1737935764850.7627";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetVisibilitySchema } from './dataAssetVisibilitySchema'
import { reportStateSchema } from './reportStateSchema'
import { reportTypeSchema } from './reportTypeSchema'
import { userReferenceSchema } from './userReferenceSchema'
import { z } from 'zod'

/**
 * @description Report Schema.
 */
export const reportSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    account_id: z.union([z.string(), z.null()]).optional(),
    connection_id: z.union([z.string(), z.null()]).optional(),
    type: z.lazy(() => reportTypeSchema),
    report_id: z.string(),
    name: z.string(),
    url: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    report_created_at: z.string().datetime(),
    report_updated_at: z.string().datetime(),
    created_at: z.union([z.string().datetime(), z.null()]).optional(),
    updated_at: z.union([z.string().datetime(), z.null()]).optional(),
    updated_by: z.union([z.lazy(() => userReferenceSchema), z.null()]).optional(),
    state: z.union([z.lazy(() => reportStateSchema), z.null()]).optional(),
    visibility: z.lazy(() => dataAssetVisibilitySchema),
  })
  .describe('Report Schema.')