// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateUpdateJob.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateUpdateJobMutationRequest, CreateUpdateJobMutationResponse, CreateUpdateJobPathParams, CreateUpdateJob422 } from '../types/CreateUpdateJob'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createUpdateJobMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/update/' }] as const

export type CreateUpdateJobMutationKey = ReturnType<typeof createUpdateJobMutationKey>

/**
 * @description Create an update job.
 * @summary Create Update Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/update/}
 */
async function createUpdateJob(
  { enterpriseName, data }: { enterpriseName: CreateUpdateJobPathParams['enterprise_name']; data?: CreateUpdateJobMutationRequest },
  config: Partial<RequestConfig<CreateUpdateJobMutationRequest>> = {},
) {
  const res = await client<CreateUpdateJobMutationResponse, CreateUpdateJob422, CreateUpdateJobMutationRequest>({
    method: 'POST',
    url: `/api/v1/enterprises/${enterpriseName}/dataset-jobs/update/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create an update job.
 * @summary Create Update Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/update/}
 */
export function useCreateUpdateJob(
  options: {
    mutation?: UseMutationOptions<
      CreateUpdateJobMutationResponse,
      CreateUpdateJob422,
      { enterpriseName: CreateUpdateJobPathParams['enterprise_name']; data?: CreateUpdateJobMutationRequest }
    >
    client?: Partial<RequestConfig<CreateUpdateJobMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createUpdateJobMutationKey()

  return useMutation<
    CreateUpdateJobMutationResponse,
    CreateUpdateJob422,
    { enterpriseName: CreateUpdateJobPathParams['enterprise_name']; data?: CreateUpdateJobMutationRequest }
  >({
    mutationFn: async ({ enterpriseName, data }) => {
      return createUpdateJob({ enterpriseName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}