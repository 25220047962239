// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readStarSchemaDataSourceSchema.ts"
);
import.meta.hot.lastModified = "1737935769010.7397";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { publishStateFilterOptionSchema } from './publishStateFilterOptionSchema'
import { starSchemaDataSourceSchema } from './starSchemaDataSourceSchema'
import { z } from 'zod'

export const readStarSchemaDataSourcePathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const readStarSchemaDataSourceQueryParamsSchema = z
  .object({
    publish_state_filter: z.lazy(() => publishStateFilterOptionSchema).default('only_verified'),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readStarSchemaDataSource200Schema = z.lazy(() => starSchemaDataSourceSchema)

/**
 * @description Validation Error
 */
export const readStarSchemaDataSource422Schema = z.lazy(() => httpValidationErrorSchema)

export const readStarSchemaDataSourceQueryResponseSchema = z.lazy(() => readStarSchemaDataSource200Schema)