// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/datasetsResponseSchema.ts"
);
import.meta.hot.lastModified = "1737759760036.0273";
}
// REMIX HMR END

// @ts-nocheck
import { reportSchema } from './reportSchema'
import { starSchemaDataSourceBasicSchema } from './starSchemaDataSourceBasicSchema'
import { z } from 'zod'

/**
 * @description Star Schema Data Sources Response.
 */
export const datasetsResponseSchema = z
  .object({
    data: z.array(z.union([z.lazy(() => starSchemaDataSourceBasicSchema), z.lazy(() => reportSchema)])),
    count: z.number().int(),
  })
  .describe('Star Schema Data Sources Response.')