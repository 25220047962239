// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useLoginApiKey.ts"
);
import.meta.hot.lastModified = "1737759758412.0356";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { LoginApiKeyMutationResponse, LoginApiKeyPathParams, LoginApiKeyQueryParams, LoginApiKey422 } from '../types/LoginApiKey'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const loginApiKeyMutationKey = () => [{ url: '/api/v2/orgs/{account_name}/login/api-key' }] as const

export type LoginApiKeyMutationKey = ReturnType<typeof loginApiKeyMutationKey>

/**
 * @description API key login, get an access token for future requests.Args:    email: The email address of the user to associate usage with.    display_name: The display name of the user.    m2m_token: The machine-to-machine token to store in the cache.
 * @summary Login Api Key
 * {@link /api/v2/orgs/:account_name/login/api-key}
 */
async function loginApiKey(
  { accountName, params }: { accountName: LoginApiKeyPathParams['account_name']; params: LoginApiKeyQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<LoginApiKeyMutationResponse, LoginApiKey422, unknown>({
    method: 'POST',
    url: `/api/v2/orgs/${accountName}/login/api-key`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description API key login, get an access token for future requests.Args:    email: The email address of the user to associate usage with.    display_name: The display name of the user.    m2m_token: The machine-to-machine token to store in the cache.
 * @summary Login Api Key
 * {@link /api/v2/orgs/:account_name/login/api-key}
 */
export function useLoginApiKey(
  options: {
    mutation?: UseMutationOptions<
      LoginApiKeyMutationResponse,
      LoginApiKey422,
      { accountName: LoginApiKeyPathParams['account_name']; params: LoginApiKeyQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? loginApiKeyMutationKey()

  return useMutation<LoginApiKeyMutationResponse, LoginApiKey422, { accountName: LoginApiKeyPathParams['account_name']; params: LoginApiKeyQueryParams }>({
    mutationFn: async ({ accountName, params }) => {
      return loginApiKey({ accountName, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}