// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteTeradataConnection.ts"
);
import.meta.hot.lastModified = "1737759761660.0188";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  DeleteTeradataConnectionMutationResponse,
  DeleteTeradataConnectionPathParams,
  DeleteTeradataConnection422,
} from '../types/DeleteTeradataConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteTeradataConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}' }] as const

export type DeleteTeradataConnectionMutationKey = ReturnType<typeof deleteTeradataConnectionMutationKey>

/**
 * @description Delete teradata connection.
 * @summary Delete Teradata Connection
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id}
 */
async function deleteTeradataConnection(
  {
    accountName,
    teradataConnectionId,
  }: { accountName: DeleteTeradataConnectionPathParams['account_name']; teradataConnectionId: DeleteTeradataConnectionPathParams['teradata_connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteTeradataConnectionMutationResponse, DeleteTeradataConnection422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/connections/teradata/${teradataConnectionId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete teradata connection.
 * @summary Delete Teradata Connection
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id}
 */
export function useDeleteTeradataConnection(
  options: {
    mutation?: UseMutationOptions<
      DeleteTeradataConnectionMutationResponse,
      DeleteTeradataConnection422,
      { accountName: DeleteTeradataConnectionPathParams['account_name']; teradataConnectionId: DeleteTeradataConnectionPathParams['teradata_connection_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteTeradataConnectionMutationKey()

  return useMutation<
    DeleteTeradataConnectionMutationResponse,
    DeleteTeradataConnection422,
    { accountName: DeleteTeradataConnectionPathParams['account_name']; teradataConnectionId: DeleteTeradataConnectionPathParams['teradata_connection_id'] }
  >({
    mutationFn: async ({ accountName, teradataConnectionId }) => {
      return deleteTeradataConnection({ accountName, teradataConnectionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}