// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/DMMType.ts"
);
import.meta.hot.lastModified = "1737935753954.8225";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible types.
 */
export enum DMMType {
  ZENDIMENSION = 'zendimension',
  ZENMEASURE = 'zenmeasure',
}
