// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/validateJoinSchema.ts"
);
import.meta.hot.lastModified = "1737935766030.756";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { joinSchema } from './joinSchema'
import { joinValidationResponseSchema } from './joinValidationResponseSchema'
import { z } from 'zod'

export const validateJoinPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const validateJoinQueryParamsSchema = z
  .object({
    num_rows_of_left_table: z.union([z.number().int(), z.null()]).optional(),
    num_rows_of_right_table: z.union([z.number().int(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const validateJoin200Schema = z.lazy(() => joinValidationResponseSchema)

/**
 * @description Validation Error
 */
export const validateJoin422Schema = z.lazy(() => httpValidationErrorSchema)

export const validateJoinMutationRequestSchema = z.lazy(() => joinSchema)

export const validateJoinMutationResponseSchema = z.lazy(() => validateJoin200Schema)