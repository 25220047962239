// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/ModeService.ts"
);
import.meta.hot.lastModified = "1737759746344.098";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModeCollection } from '../models/ModeCollection';
import type { ModeConnection } from '../models/ModeConnection';
import type { ModeConnectionCreate } from '../models/ModeConnectionCreate';
import type { Report } from '../models/Report';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ModeService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Mode Connection
   * Create new mode connection.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ModeConnection Successful Response
   * @throws ApiError
   */
  public createModeConnection(
    accountName: string,
    requestBody: ModeConnectionCreate,
    sessionId?: (string | null),
  ): CancelablePromise<ModeConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/mode/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Mode Connections
   * List existing mode connections.
   * @param accountName
   * @param limit
   * @param skip
   * @param sessionId
   * @returns ModeConnection Successful Response
   * @throws ApiError
   */
  public readModeConnections(
    accountName: string,
    limit: number = 100,
    skip?: number,
    sessionId?: (string | null),
  ): CancelablePromise<Array<ModeConnection>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/mode/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Mode Connection
   * Delete Mode connection.
   *
   * Note: This will delete the connection only if it is not used by any report.
   * @param accountName
   * @param modeConnectionId
   * @param sessionId
   * @returns ModeConnection Successful Response
   * @throws ApiError
   */
  public deleteModeConnection(
    accountName: string,
    modeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ModeConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/mode/{mode_connection_id}',
      path: {
        'account_name': accountName,
        'mode_connection_id': modeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Mode Collections
   * Read the mode collections.
   * @param accountName
   * @param modeConnectionId
   * @param sessionId
   * @returns ModeCollection Successful Response
   * @throws ApiError
   */
  public readModeCollections(
    accountName: string,
    modeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<ModeCollection>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/mode/{mode_connection_id}/collections',
      path: {
        'account_name': accountName,
        'mode_connection_id': modeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Mode Reports From Collection
   * Read the mode reports in the given collection.
   * @param collectionToken
   * @param accountName
   * @param modeConnectionId
   * @param sessionId
   * @returns Report Successful Response
   * @throws ApiError
   */
  public readModeReportsFromCollection(
    collectionToken: string,
    accountName: string,
    modeConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Report>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/mode/{mode_connection_id}/collections/{collection_token}/reports',
      path: {
        'collection_token': collectionToken,
        'account_name': accountName,
        'mode_connection_id': modeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Mode Reports
   * Read the specified mode reports.
   * @param accountName
   * @param modeConnectionId
   * @param reportTokens
   * @param sessionId
   * @returns Report Successful Response
   * @throws ApiError
   */
  public readModeReports(
    accountName: string,
    modeConnectionId: string,
    reportTokens: Array<string>,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Report>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/mode/{mode_connection_id}/reports',
      path: {
        'account_name': accountName,
        'mode_connection_id': modeConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'report_tokens': reportTokens,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
