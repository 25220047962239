// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/columnMetadataSchema.ts"
);
import.meta.hot.lastModified = "1737759759419.0305";
}
// REMIX HMR END

// @ts-nocheck
import { displayTypeSchema } from './displayTypeSchema'
import { filterCategorySchema } from './filterCategorySchema'
import { z } from 'zod'

/**
 * @description Column metadata.
 */
export const columnMetadataSchema = z
  .object({
    name: z.string(),
    display_name: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    display_type: z.union([z.lazy(() => displayTypeSchema), z.null()]).optional(),
    data_type_category: z.union([z.lazy(() => filterCategorySchema), z.null()]).optional(),
    is_nullable: z.union([z.boolean(), z.null()]).optional(),
    column_default: z.union([z.string(), z.null()]).optional(),
    dataset_id: z.union([z.string(), z.null()]).optional(),
    cell_id: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Column metadata.')