// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/validationErrorSchema.ts"
);
import.meta.hot.lastModified = "1737759759789.0286";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const validationErrorSchema = z.object({
  loc: z.array(z.union([z.number().int(), z.string()])),
  msg: z.string(),
  type: z.string(),
})