// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/MessageFeedbackFilter.ts"
);
import.meta.hot.lastModified = "1737935753991.8223";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Message feedback filter.
 */
export enum MessageFeedbackFilter {
  TRUE = 'True',
  FALSE = 'False',
  NONE = 'None',
}
