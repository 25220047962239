// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyGetUserFeedbackApiV3OrgsAccountNameMessagesUserFeedbackPostSchema.ts"
);
import.meta.hot.lastModified = "1737759762609.014";
}
// REMIX HMR END

// @ts-nocheck
import { messageFeedbackFilterSchema } from './messageFeedbackFilterSchema'
import { z } from 'zod'

export const bodyGetUserFeedbackApiV3OrgsAccountNameMessagesUserFeedbackPostSchema = z.object({
  feedback_filter: z.union([z.array(z.lazy(() => messageFeedbackFilterSchema)), z.null()]).optional(),
  ssds_ids: z.union([z.array(z.string()), z.null()]).optional(),
  creator_ids: z.union([z.array(z.string()), z.null()]).optional(),
})