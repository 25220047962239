// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ConversationsFilter.ts"
);
import.meta.hot.lastModified = "1737759760003.0273";
}
// REMIX HMR END

export enum ConversationsFilter {
  'is_pinned' = 'is_pinned',
  'is_by_user' = 'is_by_user',
  'is_starred' = 'is_starred',
  'is_eval' = 'is_eval',
}