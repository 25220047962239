// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/DataAssetConnectionTypeApi.ts"
);
import.meta.hot.lastModified = "1737935768612.742";
}
// REMIX HMR END

export enum DataAssetConnectionTypeApi {
  'snowflake' = 'snowflake',
  'bigquery' = 'bigquery',
  'databricks' = 'databricks',
  'redshift' = 'redshift',
  'tableau' = 'tableau',
  'mode' = 'mode',
  'unknown' = 'unknown',
}