// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/EnterprisesService.ts"
);
import.meta.hot.lastModified = "1737935753808.8232";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account';
import type { AccountCreate } from '../models/AccountCreate';
import type { CopyJob } from '../models/CopyJob';
import type { CopyJobCreate } from '../models/CopyJobCreate';
import type { CopyJobUpdate } from '../models/CopyJobUpdate';
import type { Enterprise } from '../models/Enterprise';
import type { EnterpriseCreate } from '../models/EnterpriseCreate';
import type { EnterpriseUpdate } from '../models/EnterpriseUpdate';
import type { Task } from '../models/Task';
import type { UpdateJob } from '../models/UpdateJob';
import type { UpdateJobCreate } from '../models/UpdateJobCreate';
import type { UpdateJobUpdate } from '../models/UpdateJobUpdate';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EnterprisesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read Enterprises
   * Retrieve enterprises.
   * @param skip
   * @param limit
   * @param sortBy
   * @param sortAscending
   * @param sessionId
   * @returns Enterprise Successful Response
   * @throws ApiError
   */
  public readEnterprises(
    skip?: number,
    limit: number = 100,
    sortBy: 'name' | 'created_at' | 'updated_at' = 'name',
    sortAscending: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Enterprise>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/enterprises/',
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'skip': skip,
        'limit': limit,
        'sort_by': sortBy,
        'sort_ascending': sortAscending,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Enterprise
   * Create new enterprise.
   * @param requestBody
   * @param sessionId
   * @returns Enterprise Successful Response
   * @throws ApiError
   */
  public createEnterprise(
    requestBody: EnterpriseCreate,
    sessionId?: (string | null),
  ): CancelablePromise<Enterprise> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/enterprises/',
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Enterprise By Name
   * Get a specific enterprise by name.
   * @param enterpriseName
   * @param sessionId
   * @returns Enterprise Successful Response
   * @throws ApiError
   */
  public readEnterpriseByName(
    enterpriseName: string,
    sessionId?: (string | null),
  ): CancelablePromise<Enterprise> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/enterprises/{enterprise_name}',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Enterprise
   * Update a enterprise.
   * @param enterpriseName
   * @param requestBody
   * @param sessionId
   * @returns Enterprise Successful Response
   * @throws ApiError
   */
  public updateEnterprise(
    enterpriseName: string,
    requestBody: EnterpriseUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<Enterprise> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/enterprises/{enterprise_name}',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Enterprise Users
   * Get distinct users in the enterprise.
   *
   * Note that the User schema is filtered to ONLY showing accounts in the enterprise.
   * @param enterpriseName
   * @param skip
   * @param limit
   * @param sortBy
   * @param sortAscending
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public getEnterpriseUsers(
    enterpriseName: string,
    skip?: number,
    limit: number = 100,
    sortBy: 'name' | 'created_at' | 'updated_at' = 'name',
    sortAscending: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<Array<User>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/enterprises/{enterprise_name}/users',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'skip': skip,
        'limit': limit,
        'sort_by': sortBy,
        'sort_ascending': sortAscending,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Enterprise Accounts
   * Get all accounts in the enterprise.
   * @param enterpriseName
   * @param skip
   * @param limit
   * @param sortBy
   * @param sortAscending
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public getEnterpriseAccounts(
    enterpriseName: string,
    skip?: number,
    limit: number = 100,
    sortBy: 'name' | 'created_at' | 'updated_at' = 'name',
    sortAscending: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Account>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/enterprises/{enterprise_name}/accounts',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'skip': skip,
        'limit': limit,
        'sort_by': sortBy,
        'sort_ascending': sortAscending,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add New Account To Enterprise
   * Create a new account in the enterprise.
   * @param enterpriseName
   * @param requestBody
   * @param preloadDemoDataset
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public addNewAccountToEnterprise(
    enterpriseName: string,
    requestBody: AccountCreate,
    preloadDemoDataset: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<Account> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/enterprises/{enterprise_name}/accounts',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'preload_demo_dataset': preloadDemoDataset,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Accounts To Enterprise
   * Add existing accounts to the enterprise.
   *
   * This is intended to be only used by the superuser.
   *
   * Returns: A list of account names that were not able to be added.
   * @param enterpriseName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public addAccountsToEnterprise(
    enterpriseName: string,
    requestBody: Array<string>,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/enterprises/{enterprise_name}/accounts',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Rm Accounts From Enterprise
   * Remove accounts from the enterprise.
   *
   * This is intended to be only used by the superuser.
   *
   * Returns: A list of account names that were not able to be removed.
   * @param enterpriseName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public rmAccountsFromEnterprise(
    enterpriseName: string,
    requestBody: Array<string>,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/enterprises/{enterprise_name}/accounts',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Enterprise Admins
   * Add admin users to the enterprise.
   *
   * This propogates by adding the user as both a user and admin to all accounts in the enterprise.
   *
   * Returns: A list of UUIDs of users that were not able to be added.
   * @param enterpriseName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public addEnterpriseAdmins(
    enterpriseName: string,
    requestBody: Array<string>,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/enterprises/{enterprise_name}/admins',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Rm Enterprise Admins
   * Remove admin privileges from the specified users.
   *
   * This does not propogate. That is, users remain in the accounts in the enterprise.
   *
   * Returns: a list of the IDs which were invalid.
   * @param enterpriseName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public rmEnterpriseAdmins(
    enterpriseName: string,
    requestBody: Array<string>,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/enterprises/{enterprise_name}/admins',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Rm Enterprise User
   * Remove the user from all accounts in the enterprise.
   *
   * This will NOT delete the user itself.
   * @param enterpriseName
   * @param userId
   * @param userEmail
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public rmEnterpriseUser(
    enterpriseName: string,
    userId: (string | null),
    userEmail?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/enterprises/{enterprise_name}/users/{user_id}',
      path: {
        'enterprise_name': enterpriseName,
        'user_id': userId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'user_email': userEmail,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Rm Account From Enterprise
   * Remove the account from the enterprise, and its users.
   *
   * This does not actually delete the account.
   * @param enterpriseName
   * @param accountName
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public rmAccountFromEnterprise(
    enterpriseName: string,
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<Account> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/enterprises/{enterprise_name}/accounts/{account_name}',
      path: {
        'enterprise_name': enterpriseName,
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Dataset Jobs
   * Get all dataset jobs, paginated.
   * @param enterpriseName
   * @param skip
   * @param limit
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getDatasetJobs(
    enterpriseName: string,
    skip?: number,
    limit: number = 100,
    sessionId?: (string | null),
  ): CancelablePromise<Array<(CopyJob | UpdateJob)>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'skip': skip,
        'limit': limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Update Job
   * Create an update job.
   * @param enterpriseName
   * @param requestBody
   * @param sessionId
   * @returns UpdateJob Successful Response
   * @throws ApiError
   */
  public createUpdateJob(
    enterpriseName: string,
    requestBody: UpdateJobCreate,
    sessionId?: (string | null),
  ): CancelablePromise<UpdateJob> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/update/',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Copy Job
   * Create a copy job.
   * @param enterpriseName
   * @param requestBody
   * @param sessionId
   * @returns CopyJob Successful Response
   * @throws ApiError
   */
  public createCopyJob(
    enterpriseName: string,
    requestBody: CopyJobCreate,
    sessionId?: (string | null),
  ): CancelablePromise<CopyJob> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/copy/',
      path: {
        'enterprise_name': enterpriseName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Update Job
   * Get an update job.
   * @param enterpriseName
   * @param jobId
   * @param sessionId
   * @returns UpdateJob Successful Response
   * @throws ApiError
   */
  public getUpdateJob(
    enterpriseName: string,
    jobId: string,
    sessionId?: (string | null),
  ): CancelablePromise<UpdateJob> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/update/{job_id}',
      path: {
        'enterprise_name': enterpriseName,
        'job_id': jobId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Execute Update Job
   * Execute an update job.
   * @param enterpriseName
   * @param jobId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public executeUpdateJob(
    enterpriseName: string,
    jobId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Task>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/update/{job_id}',
      path: {
        'enterprise_name': enterpriseName,
        'job_id': jobId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Update Job
   * Update an update job.
   * @param enterpriseName
   * @param jobId
   * @param requestBody
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public updateUpdateJob(
    enterpriseName: string,
    jobId: string,
    requestBody: UpdateJobUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/update/{job_id}',
      path: {
        'enterprise_name': enterpriseName,
        'job_id': jobId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Update Job
   * Delete a dataset job.
   * @param enterpriseName
   * @param jobId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteUpdateJob(
    enterpriseName: string,
    jobId: string,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/update/{job_id}',
      path: {
        'enterprise_name': enterpriseName,
        'job_id': jobId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Copy Job
   * Get a copy job.
   * @param enterpriseName
   * @param jobId
   * @param sessionId
   * @returns CopyJob Successful Response
   * @throws ApiError
   */
  public getCopyJob(
    enterpriseName: string,
    jobId: string,
    sessionId?: (string | null),
  ): CancelablePromise<CopyJob> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/copy/{job_id}',
      path: {
        'enterprise_name': enterpriseName,
        'job_id': jobId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Execute Copy Job
   * Execute a copy job.
   * @param enterpriseName
   * @param jobId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public executeCopyJob(
    enterpriseName: string,
    jobId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Task>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/copy/{job_id}',
      path: {
        'enterprise_name': enterpriseName,
        'job_id': jobId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Copy Job
   * Update a copy job.
   * @param enterpriseName
   * @param jobId
   * @param requestBody
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public updateCopyJob(
    enterpriseName: string,
    jobId: string,
    requestBody: CopyJobUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/copy/{job_id}',
      path: {
        'enterprise_name': enterpriseName,
        'job_id': jobId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Dataset Job
   * Delete a copy job.
   * @param enterpriseName
   * @param jobId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteDatasetJob(
    enterpriseName: string,
    jobId: string,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/copy/{job_id}',
      path: {
        'enterprise_name': enterpriseName,
        'job_id': jobId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
