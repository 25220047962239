// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetDimensionExprType.ts"
);
import.meta.hot.lastModified = "1737759760889.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetDimensionExprTypeQueryResponse,
  GetDimensionExprTypePathParams,
  GetDimensionExprTypeQueryParams,
  GetDimensionExprType422,
} from '../types/GetDimensionExprType'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getDimensionExprTypeQueryKey = (
  { accountName }: { accountName: GetDimensionExprTypePathParams['account_name'] },
  params: GetDimensionExprTypeQueryParams,
) =>
  [
    { url: '/api/v1/accounts/:account_name/star_schema_data_source/dimensions/expr_type', params: { accountName: accountName } },
    ...(params ? [params] : []),
  ] as const

export type GetDimensionExprTypeQueryKey = ReturnType<typeof getDimensionExprTypeQueryKey>

/**
 * @description Get the expr_type of a dimension, then check if already exists.Returns:    A 409 if the sql/data_source_id combo already exists    The expr_type of the expr if a dimension with the given sql doesn't exist
 * @summary Get Dimension Expr Type
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/expr_type}
 */
async function getDimensionExprType(
  { accountName, params }: { accountName: GetDimensionExprTypePathParams['account_name']; params: GetDimensionExprTypeQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetDimensionExprTypeQueryResponse, GetDimensionExprType422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions/expr_type`,
    params,
    ...config,
  })
  return res.data
}

export function getDimensionExprTypeQueryOptions(
  { accountName, params }: { accountName: GetDimensionExprTypePathParams['account_name']; params: GetDimensionExprTypeQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getDimensionExprTypeQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!(accountName && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getDimensionExprType({ accountName, params }, config)
    },
  })
}

/**
 * @description Get the expr_type of a dimension, then check if already exists.Returns:    A 409 if the sql/data_source_id combo already exists    The expr_type of the expr if a dimension with the given sql doesn't exist
 * @summary Get Dimension Expr Type
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/expr_type}
 */
export function useGetDimensionExprType<
  TData = GetDimensionExprTypeQueryResponse,
  TQueryData = GetDimensionExprTypeQueryResponse,
  TQueryKey extends QueryKey = GetDimensionExprTypeQueryKey,
>(
  { accountName, params }: { accountName: GetDimensionExprTypePathParams['account_name']; params: GetDimensionExprTypeQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetDimensionExprTypeQueryResponse, GetDimensionExprType422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getDimensionExprTypeQueryKey({ accountName }, params)

  const query = useQuery({
    ...(getDimensionExprTypeQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetDimensionExprType422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}