// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/cellStateSchema.ts"
);
import.meta.hot.lastModified = "1737759757951.038";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Cell states.
 */
export const cellStateSchema = z.enum(['CREATED', 'FAILED', 'RUNNING', 'SUCCESS', 'LOCKED']).describe('Cell states.')