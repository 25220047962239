// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readStarSchemaDataSourceDataSchema.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

// @ts-nocheck
import { bodyReadStarSchemaDataSourceDataApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDataPostSchema } from './bodyReadStarSchemaDataSourceDataApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDataPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { nodeDataPageSchema } from './nodeDataPageSchema'
import { z } from 'zod'

export const readStarSchemaDataSourceDataPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const readStarSchemaDataSourceDataQueryParamsSchema = z
  .object({
    limit: z.number().int().default(100),
    skip: z.number().int().default(0),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readStarSchemaDataSourceData200Schema = z.lazy(() => nodeDataPageSchema)

/**
 * @description Validation Error
 */
export const readStarSchemaDataSourceData422Schema = z.lazy(() => httpValidationErrorSchema)

export const readStarSchemaDataSourceDataMutationRequestSchema = z.lazy(
  () => bodyReadStarSchemaDataSourceDataApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdDataPostSchema,
)

export const readStarSchemaDataSourceDataMutationResponseSchema = z.lazy(() => readStarSchemaDataSourceData200Schema)