// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/addAccountsToEnterpriseSchema.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const addAccountsToEnterprisePathParamsSchema = z.object({
  enterprise_name: z.string(),
})

/**
 * @description Successful Response
 */
export const addAccountsToEnterprise200Schema = z.array(z.string())

/**
 * @description Validation Error
 */
export const addAccountsToEnterprise422Schema = z.lazy(() => httpValidationErrorSchema)

export const addAccountsToEnterpriseMutationRequestSchema = z.array(z.string())

export const addAccountsToEnterpriseMutationResponseSchema = z.lazy(() => addAccountsToEnterprise200Schema)