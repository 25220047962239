// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteConversation.ts"
);
import.meta.hot.lastModified = "1737935767642.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteConversationMutationResponse, DeleteConversationPathParams, DeleteConversation422 } from '../types/DeleteConversation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteConversationMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}' }] as const

export type DeleteConversationMutationKey = ReturnType<typeof deleteConversationMutationKey>

/**
 * @description Delete a conversation.
 * @summary Delete Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id}
 */
async function deleteConversation(
  {
    accountName,
    conversationId,
  }: { accountName: DeleteConversationPathParams['account_name']; conversationId: DeleteConversationPathParams['conversation_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteConversationMutationResponse, DeleteConversation422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete a conversation.
 * @summary Delete Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id}
 */
export function useDeleteConversation(
  options: {
    mutation?: UseMutationOptions<
      DeleteConversationMutationResponse,
      DeleteConversation422,
      { accountName: DeleteConversationPathParams['account_name']; conversationId: DeleteConversationPathParams['conversation_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteConversationMutationKey()

  return useMutation<
    DeleteConversationMutationResponse,
    DeleteConversation422,
    { accountName: DeleteConversationPathParams['account_name']; conversationId: DeleteConversationPathParams['conversation_id'] }
  >({
    mutationFn: async ({ accountName, conversationId }) => {
      return deleteConversation({ accountName, conversationId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}