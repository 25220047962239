// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/moonlightStreamedOutputQuestionSchema.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Moonlight suggested Questions.
 */
export const moonlightStreamedOutputQuestionSchema = z
  .object({
    question: z.string(),
    why: z.string(),
    dimensions: z.string(),
    metrics: z.string(),
    filters: z.string(),
  })
  .describe('Moonlight suggested Questions.')