// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateConversation.ts"
);
import.meta.hot.lastModified = "1737935767642.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateConversationMutationRequest,
  UpdateConversationMutationResponse,
  UpdateConversationPathParams,
  UpdateConversation422,
} from '../types/UpdateConversation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateConversationMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}' }] as const

export type UpdateConversationMutationKey = ReturnType<typeof updateConversationMutationKey>

/**
 * @description Update a conversation.
 * @summary Update Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id}
 */
async function updateConversation(
  {
    accountName,
    conversationId,
    data,
  }: {
    accountName: UpdateConversationPathParams['account_name']
    conversationId: UpdateConversationPathParams['conversation_id']
    data?: UpdateConversationMutationRequest
  },
  config: Partial<RequestConfig<UpdateConversationMutationRequest>> = {},
) {
  const res = await client<UpdateConversationMutationResponse, UpdateConversation422, UpdateConversationMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a conversation.
 * @summary Update Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id}
 */
export function useUpdateConversation(
  options: {
    mutation?: UseMutationOptions<
      UpdateConversationMutationResponse,
      UpdateConversation422,
      {
        accountName: UpdateConversationPathParams['account_name']
        conversationId: UpdateConversationPathParams['conversation_id']
        data?: UpdateConversationMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateConversationMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateConversationMutationKey()

  return useMutation<
    UpdateConversationMutationResponse,
    UpdateConversation422,
    {
      accountName: UpdateConversationPathParams['account_name']
      conversationId: UpdateConversationPathParams['conversation_id']
      data?: UpdateConversationMutationRequest
    }
  >({
    mutationFn: async ({ accountName, conversationId, data }) => {
      return updateConversation({ accountName, conversationId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}