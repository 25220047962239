// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/profileDataSourceSchema.ts"
);
import.meta.hot.lastModified = "1737759760438.0251";
}
// REMIX HMR END

// @ts-nocheck
import { dataSourceProfileInfoSchema } from './dataSourceProfileInfoSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const profileDataSourcePathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
  data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const profileDataSource200Schema = z.lazy(() => dataSourceProfileInfoSchema)

/**
 * @description Validation Error
 */
export const profileDataSource422Schema = z.lazy(() => httpValidationErrorSchema)

export const profileDataSourceMutationResponseSchema = z.lazy(() => profileDataSource200Schema)