// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/debugLogResponseSchema.ts"
);
import.meta.hot.lastModified = "1737935767112.7502";
}
// REMIX HMR END

// @ts-nocheck
import { dataSourceSchema } from './dataSourceSchema'
import { semanticQuerySchema } from './semanticQuerySchema'
import { usageSchema } from './usageSchema'
import { z } from 'zod'

/**
 * @description Schema encapsulating the return of BB.
 */
export const debugLogResponseSchema = z
  .object({
    data_source: z.union([z.lazy(() => dataSourceSchema), z.null()]).optional(),
    semantic_query: z.union([z.lazy(() => semanticQuerySchema), z.null()]).optional(),
    moonlight: z.union([z.string(), z.null()]).optional(),
    usage: z.lazy(() => usageSchema),
    paraphrased_questions: z.array(z.string()).optional(),
    rephrased_question: z.union([z.string(), z.null()]).optional(),
    moonlight_rephrased_question: z.union([z.string(), z.null()]).optional(),
    all_sqls: z.array(z.string()).optional(),
  })
  .describe('Schema encapsulating the return of BB.')