// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/clientSafeAccountSettingsSchema.ts"
);
import.meta.hot.lastModified = "1737935769016.7397";
}
// REMIX HMR END

// @ts-nocheck
import { clientSettingUnionBoolIntStrSchema } from './clientSettingUnionBoolIntStrSchema'
import { z } from 'zod'

/**
 * @description Client-safe account settings.
 */
export const clientSafeAccountSettingsSchema = z
  .object({
    test_setting: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    test_int_setting: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    setting_admin_and_superuser_can_edit: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    setting_admin_can_edit: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    setting_superuser_can_edit: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    setting_not_sent_to_client: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    fuzzy_filter_cardinality_threshold: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    dataset_update_existing_dimensions_metrics: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    dataset_include_metrics: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    dataset_initial_dimension_metric_state: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    dataset_num_rows_to_profile: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    dataset_top_k_profile_values_for_retrieval: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    is_widget_default: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    use_onboarding_agent: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    use_predictive_model_agent: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    use_vega_chart_agent: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    use_opensearch_agents: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    add_dms_from_reports: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    use_knowledge_sql: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    grouping_strategy: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    hide_bumblebee_verification_ui: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    hide_v1_ui: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
    show_try_widget_button: z.lazy(() => clientSettingUnionBoolIntStrSchema).default({}),
  })
  .describe('Client-safe account settings.')