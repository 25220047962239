// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useLoginSession.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { LoginSessionMutationRequest, LoginSessionMutationResponse, LoginSessionQueryParams, LoginSession422 } from '../types/LoginSession'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const loginSessionMutationKey = () => [{ url: '/api/v1/login/session' }] as const

export type LoginSessionMutationKey = ReturnType<typeof loginSessionMutationKey>

/**
 * @description Login and get a session-based cookie.The response headers will contain the cookie. Note that you must use the cookie for allother requests to the API.
 * @summary Login Session
 * {@link /api/v1/login/session}
 */
async function loginSession(
  { data, params }: { data: LoginSessionMutationRequest; params?: LoginSessionQueryParams },
  config: Partial<RequestConfig<LoginSessionMutationRequest>> = {},
) {
  const res = await client<LoginSessionMutationResponse, LoginSession422, LoginSessionMutationRequest>({
    method: 'POST',
    url: `/api/v1/login/session`,
    params,
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...config.headers },
    ...config,
  })
  return res.data
}

/**
 * @description Login and get a session-based cookie.The response headers will contain the cookie. Note that you must use the cookie for allother requests to the API.
 * @summary Login Session
 * {@link /api/v1/login/session}
 */
export function useLoginSession(
  options: {
    mutation?: UseMutationOptions<LoginSessionMutationResponse, LoginSession422, { data: LoginSessionMutationRequest; params?: LoginSessionQueryParams }>
    client?: Partial<RequestConfig<LoginSessionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? loginSessionMutationKey()

  return useMutation<LoginSessionMutationResponse, LoginSession422, { data: LoginSessionMutationRequest; params?: LoginSessionQueryParams }>({
    mutationFn: async ({ data, params }) => {
      return loginSession({ data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}