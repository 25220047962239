// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateCellChartConfig.ts"
);
import.meta.hot.lastModified = "1737759761240.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateCellChartConfigMutationRequest,
  UpdateCellChartConfigMutationResponse,
  UpdateCellChartConfigPathParams,
  UpdateCellChartConfig422,
} from '../types/UpdateCellChartConfig'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateCellChartConfigMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/chart/{chart_config_id}' }] as const

export type UpdateCellChartConfigMutationKey = ReturnType<typeof updateCellChartConfigMutationKey>

/**
 * @description Update Bumblebee Cell Chart Config.
 * @summary Update Cell Chart Config
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/chart/:chart_config_id}
 */
async function updateCellChartConfig(
  {
    accountName,
    conversationId,
    cellId,
    chartConfigId,
    data,
  }: {
    accountName: UpdateCellChartConfigPathParams['account_name']
    conversationId: UpdateCellChartConfigPathParams['conversation_id']
    cellId: UpdateCellChartConfigPathParams['cell_id']
    chartConfigId: UpdateCellChartConfigPathParams['chart_config_id']
    data?: UpdateCellChartConfigMutationRequest
  },
  config: Partial<RequestConfig<UpdateCellChartConfigMutationRequest>> = {},
) {
  const res = await client<UpdateCellChartConfigMutationResponse, UpdateCellChartConfig422, UpdateCellChartConfigMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/chart/${chartConfigId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update Bumblebee Cell Chart Config.
 * @summary Update Cell Chart Config
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/chart/:chart_config_id}
 */
export function useUpdateCellChartConfig(
  options: {
    mutation?: UseMutationOptions<
      UpdateCellChartConfigMutationResponse,
      UpdateCellChartConfig422,
      {
        accountName: UpdateCellChartConfigPathParams['account_name']
        conversationId: UpdateCellChartConfigPathParams['conversation_id']
        cellId: UpdateCellChartConfigPathParams['cell_id']
        chartConfigId: UpdateCellChartConfigPathParams['chart_config_id']
        data?: UpdateCellChartConfigMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateCellChartConfigMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateCellChartConfigMutationKey()

  return useMutation<
    UpdateCellChartConfigMutationResponse,
    UpdateCellChartConfig422,
    {
      accountName: UpdateCellChartConfigPathParams['account_name']
      conversationId: UpdateCellChartConfigPathParams['conversation_id']
      cellId: UpdateCellChartConfigPathParams['cell_id']
      chartConfigId: UpdateCellChartConfigPathParams['chart_config_id']
      data?: UpdateCellChartConfigMutationRequest
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, chartConfigId, data }) => {
      return updateCellChartConfig({ accountName, conversationId, cellId, chartConfigId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}