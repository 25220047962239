// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/API.ts"
);
import.meta.hot.lastModified = "1737759746561.0967";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AccountsService } from './services/AccountsService';
import { AdminService } from './services/AdminService';
import { ApiKeyService } from './services/ApiKeyService';
import { BigqueryService } from './services/BigqueryService';
import { CellRunEventService } from './services/CellRunEventService';
import { CellsService } from './services/CellsService';
import { ChatService } from './services/ChatService';
import { ConnectionsService } from './services/ConnectionsService';
import { ConversationsService } from './services/ConversationsService';
import { ConversationsV2Service } from './services/ConversationsV2Service';
import { DataAssetsService } from './services/DataAssetsService';
import { DatabricksService } from './services/DatabricksService';
import { DatasetsService } from './services/DatasetsService';
import { DatasetsV2Service } from './services/DatasetsV2Service';
import { EnterprisesService } from './services/EnterprisesService';
import { EvalV3Service } from './services/EvalV3Service';
import { HealthzService } from './services/HealthzService';
import { IndexService } from './services/IndexService';
import { KnowledgeLayerService } from './services/KnowledgeLayerService';
import { LoggingService } from './services/LoggingService';
import { LoginService } from './services/LoginService';
import { LoginV2Service } from './services/LoginV2Service';
import { MessagesService } from './services/MessagesService';
import { MessagesV2Service } from './services/MessagesV2Service';
import { ModeService } from './services/ModeService';
import { PostgresService } from './services/PostgresService';
import { PublicService } from './services/PublicService';
import { RedshiftService } from './services/RedshiftService';
import { ReportService } from './services/ReportService';
import { ReportsService } from './services/ReportsService';
import { RetrievalService } from './services/RetrievalService';
import { SemanticQueryService } from './services/SemanticQueryService';
import { SlackService } from './services/SlackService';
import { SnowflakeService } from './services/SnowflakeService';
import { StarSchemaDataSourceService } from './services/StarSchemaDataSourceService';
import { TableauService } from './services/TableauService';
import { TasksService } from './services/TasksService';
import { TasksV2Service } from './services/TasksV2Service';
import { TeradataService } from './services/TeradataService';
import { UserService } from './services/UserService';
import { UsersService } from './services/UsersService';
import { UtilsService } from './services/UtilsService';
import { V1Service } from './services/V1Service';
import { V2Service } from './services/V2Service';
import { V3Service } from './services/V3Service';
import { WidgetService } from './services/WidgetService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class API {

  public readonly accounts: AccountsService;
  public readonly admin: AdminService;
  public readonly apiKey: ApiKeyService;
  public readonly bigquery: BigqueryService;
  public readonly cellRunEvent: CellRunEventService;
  public readonly cells: CellsService;
  public readonly chat: ChatService;
  public readonly connections: ConnectionsService;
  public readonly conversations: ConversationsService;
  public readonly conversationsV2: ConversationsV2Service;
  public readonly dataAssets: DataAssetsService;
  public readonly databricks: DatabricksService;
  public readonly datasets: DatasetsService;
  public readonly datasetsV2: DatasetsV2Service;
  public readonly enterprises: EnterprisesService;
  public readonly evalV3: EvalV3Service;
  public readonly healthz: HealthzService;
  public readonly index: IndexService;
  public readonly knowledgeLayer: KnowledgeLayerService;
  public readonly logging: LoggingService;
  public readonly login: LoginService;
  public readonly loginV2: LoginV2Service;
  public readonly messages: MessagesService;
  public readonly messagesV2: MessagesV2Service;
  public readonly mode: ModeService;
  public readonly postgres: PostgresService;
  public readonly public: PublicService;
  public readonly redshift: RedshiftService;
  public readonly report: ReportService;
  public readonly reports: ReportsService;
  public readonly retrieval: RetrievalService;
  public readonly semanticQuery: SemanticQueryService;
  public readonly slack: SlackService;
  public readonly snowflake: SnowflakeService;
  public readonly starSchemaDataSource: StarSchemaDataSourceService;
  public readonly tableau: TableauService;
  public readonly tasks: TasksService;
  public readonly tasksV2: TasksV2Service;
  public readonly teradata: TeradataService;
  public readonly user: UserService;
  public readonly users: UsersService;
  public readonly utils: UtilsService;
  public readonly v1: V1Service;
  public readonly v2: V2Service;
  public readonly v3: V3Service;
  public readonly widget: WidgetService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '176.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.accounts = new AccountsService(this.request);
    this.admin = new AdminService(this.request);
    this.apiKey = new ApiKeyService(this.request);
    this.bigquery = new BigqueryService(this.request);
    this.cellRunEvent = new CellRunEventService(this.request);
    this.cells = new CellsService(this.request);
    this.chat = new ChatService(this.request);
    this.connections = new ConnectionsService(this.request);
    this.conversations = new ConversationsService(this.request);
    this.conversationsV2 = new ConversationsV2Service(this.request);
    this.dataAssets = new DataAssetsService(this.request);
    this.databricks = new DatabricksService(this.request);
    this.datasets = new DatasetsService(this.request);
    this.datasetsV2 = new DatasetsV2Service(this.request);
    this.enterprises = new EnterprisesService(this.request);
    this.evalV3 = new EvalV3Service(this.request);
    this.healthz = new HealthzService(this.request);
    this.index = new IndexService(this.request);
    this.knowledgeLayer = new KnowledgeLayerService(this.request);
    this.logging = new LoggingService(this.request);
    this.login = new LoginService(this.request);
    this.loginV2 = new LoginV2Service(this.request);
    this.messages = new MessagesService(this.request);
    this.messagesV2 = new MessagesV2Service(this.request);
    this.mode = new ModeService(this.request);
    this.postgres = new PostgresService(this.request);
    this.public = new PublicService(this.request);
    this.redshift = new RedshiftService(this.request);
    this.report = new ReportService(this.request);
    this.reports = new ReportsService(this.request);
    this.retrieval = new RetrievalService(this.request);
    this.semanticQuery = new SemanticQueryService(this.request);
    this.slack = new SlackService(this.request);
    this.snowflake = new SnowflakeService(this.request);
    this.starSchemaDataSource = new StarSchemaDataSourceService(this.request);
    this.tableau = new TableauService(this.request);
    this.tasks = new TasksService(this.request);
    this.tasksV2 = new TasksV2Service(this.request);
    this.teradata = new TeradataService(this.request);
    this.user = new UserService(this.request);
    this.users = new UsersService(this.request);
    this.utils = new UtilsService(this.request);
    this.v1 = new V1Service(this.request);
    this.v2 = new V2Service(this.request);
    this.v3 = new V3Service(this.request);
    this.widget = new WidgetService(this.request);
  }
}

