// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/FilterOperator.ts"
);
import.meta.hot.lastModified = "1737759758410.0356";
}
// REMIX HMR END

export enum FilterOperator {
  'equals' = 'equals',
  'not_equals' = 'not_equals',
  'case-insensitive_equals' = 'case-insensitive_equals',
  'not_case-insensitive_equals' = 'not_case-insensitive_equals',
  'greater_than' = 'greater_than',
  'greater_than_or_equal_to' = 'greater_than_or_equal_to',
  'less_than' = 'less_than',
  'less_than_or_equal_to' = 'less_than_or_equal_to',
  'contains' = 'contains',
  'not_contains' = 'not_contains',
  'starts_with' = 'starts_with',
  'not_starts_with' = 'not_starts_with',
  'ends_with' = 'ends_with',
  'not_ends_with' = 'not_ends_with',
  'blank' = 'blank',
  'not_blank' = 'not_blank',
  'in' = 'in',
  'not_in' = 'not_in',
  'between' = 'between',
  'not_between' = 'not_between',
}