// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/usePreregisterUser.ts"
);
import.meta.hot.lastModified = "1737759759782.0286";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { PreregisterUserMutationRequest, PreregisterUserMutationResponse, PreregisterUserQueryParams, PreregisterUser422 } from '../types/PreregisterUser'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const preregisterUserMutationKey = () => [{ url: '/api/v1/users/preregistration' }] as const

export type PreregisterUserMutationKey = ReturnType<typeof preregisterUserMutationKey>

/**
 * @description Create a preregistration.
 * @summary Preregister User
 * {@link /api/v1/users/preregistration}
 */
async function preregisterUser(
  { data, params }: { data: PreregisterUserMutationRequest; params: PreregisterUserQueryParams },
  config: Partial<RequestConfig<PreregisterUserMutationRequest>> = {},
) {
  const res = await client<PreregisterUserMutationResponse, PreregisterUser422, PreregisterUserMutationRequest>({
    method: 'POST',
    url: `/api/v1/users/preregistration`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a preregistration.
 * @summary Preregister User
 * {@link /api/v1/users/preregistration}
 */
export function usePreregisterUser(
  options: {
    mutation?: UseMutationOptions<
      PreregisterUserMutationResponse,
      PreregisterUser422,
      { data: PreregisterUserMutationRequest; params: PreregisterUserQueryParams }
    >
    client?: Partial<RequestConfig<PreregisterUserMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? preregisterUserMutationKey()

  return useMutation<PreregisterUserMutationResponse, PreregisterUser422, { data: PreregisterUserMutationRequest; params: PreregisterUserQueryParams }>({
    mutationFn: async ({ data, params }) => {
      return preregisterUser({ data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}