// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyGetAdminFeedbackApiV3OrgsAccountNameMessagesAdminFeedbackPostSchema.ts"
);
import.meta.hot.lastModified = "1737759762609.014";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const bodyGetAdminFeedbackApiV3OrgsAccountNameMessagesAdminFeedbackPostSchema = z.object({
  ssds_ids: z.union([z.array(z.string()), z.null()]).optional(),
  creator_ids: z.union([z.array(z.string()), z.null()]).optional(),
})