// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useValidateJoin.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ValidateJoinMutationRequest,
  ValidateJoinMutationResponse,
  ValidateJoinPathParams,
  ValidateJoinQueryParams,
  ValidateJoin422,
} from '../types/ValidateJoin'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const validateJoinMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/joins/validate' }] as const

export type ValidateJoinMutationKey = ReturnType<typeof validateJoinMutationKey>

/**
 * @description Validate Joins for Star Schema Data Source.
 * @summary Validate Join
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/joins/validate}
 */
async function validateJoin(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: ValidateJoinPathParams['account_name']
    starSchemaDataSourceId: ValidateJoinPathParams['star_schema_data_source_id']
    data: ValidateJoinMutationRequest
    params?: ValidateJoinQueryParams
  },
  config: Partial<RequestConfig<ValidateJoinMutationRequest>> = {},
) {
  const res = await client<ValidateJoinMutationResponse, ValidateJoin422, ValidateJoinMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/joins/validate`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Validate Joins for Star Schema Data Source.
 * @summary Validate Join
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/joins/validate}
 */
export function useValidateJoin(
  options: {
    mutation?: UseMutationOptions<
      ValidateJoinMutationResponse,
      ValidateJoin422,
      {
        accountName: ValidateJoinPathParams['account_name']
        starSchemaDataSourceId: ValidateJoinPathParams['star_schema_data_source_id']
        data: ValidateJoinMutationRequest
        params?: ValidateJoinQueryParams
      }
    >
    client?: Partial<RequestConfig<ValidateJoinMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? validateJoinMutationKey()

  return useMutation<
    ValidateJoinMutationResponse,
    ValidateJoin422,
    {
      accountName: ValidateJoinPathParams['account_name']
      starSchemaDataSourceId: ValidateJoinPathParams['star_schema_data_source_id']
      data: ValidateJoinMutationRequest
      params?: ValidateJoinQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data, params }) => {
      return validateJoin({ accountName, starSchemaDataSourceId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}