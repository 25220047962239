// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/PublishStateFilterOption.ts"
);
import.meta.hot.lastModified = "1737759746514.097";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * DMF state.
 *
 * This is the flag to determine the DMF state to extract.
 */
export enum PublishStateFilterOption {
  ONLY_VERIFIED = 'only_verified',
  NOT_DEPRECATED = 'not_deprecated',
  ONLY_VALIDATABLE = 'only_validatable',
  ALL = 'all',
}
