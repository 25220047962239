// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteReportsSchema.ts"
);
import.meta.hot.lastModified = "1737935766269.755";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { reportSchema } from './reportSchema'
import { z } from 'zod'

export const deleteReportsPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const deleteReports200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const deleteReports422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteReportsMutationRequestSchema = z.array(z.lazy(() => reportSchema))

export const deleteReportsMutationResponseSchema = z.lazy(() => deleteReports200Schema)