// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/EvalV3Service.ts"
);
import.meta.hot.lastModified = "1737759746393.0977";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Task } from '../models/Task';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EvalV3Service {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Run Eval On Chat
   * Run eval on all of the messages sent in the reference chat.
   * @param accountName
   * @param chatId The ID of the chat.
   * @param deleteNewChat
   * @param runGroupChat
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public runEvalOnChat(
    accountName: string,
    chatId: string,
    deleteNewChat: boolean = true,
    runGroupChat: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/eval',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'delete_new_chat': deleteNewChat,
        'run_group_chat': runGroupChat,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Run Eval Chats
   * Run eval on reference chats.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param maxEvalChats
   * @param deleteNewChat
   * @param runGroupChat
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public runEvalChats(
    accountName: string,
    starSchemaDataSourceId: string,
    maxEvalChats?: (number | null),
    deleteNewChat: boolean = true,
    runGroupChat: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{star_schema_data_source_id}/eval_chats',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'max_eval_chats': maxEvalChats,
        'delete_new_chat': deleteNewChat,
        'run_group_chat': runGroupChat,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
