// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteBigqueryConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935769010.7397";
}
// REMIX HMR END

// @ts-nocheck
import { bigQueryConnectionSchema } from './bigQueryConnectionSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const deleteBigqueryConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const deleteBigqueryConnection200Schema = z.lazy(() => bigQueryConnectionSchema)

/**
 * @description Validation Error
 */
export const deleteBigqueryConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteBigqueryConnectionMutationResponseSchema = z.lazy(() => deleteBigqueryConnection200Schema)