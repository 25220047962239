// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetQuestionModelcalls.ts"
);
import.meta.hot.lastModified = "1737935768429.7432";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetQuestionModelcallsQueryResponse, GetQuestionModelcallsQueryParams, GetQuestionModelcalls422 } from '../types/GetQuestionModelcalls'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getQuestionModelcallsQueryKey = (params: GetQuestionModelcallsQueryParams) =>
  [{ url: '/api/v1/admin/promptlog/modelcalls' }, ...(params ? [params] : [])] as const

export type GetQuestionModelcallsQueryKey = ReturnType<typeof getQuestionModelcallsQueryKey>

/**
 * @description Admin endpoint to get model call history for a question.Admin endpoints require a superuser token. It's intended that an NS admin could log in, thenpass in any bumblebee cell id.Returns:    This returns a JSON list of model calls in reverse chronological order, by    default only the most recent.
 * @summary Get Question Modelcalls
 * {@link /api/v1/admin/promptlog/modelcalls}
 */
async function getQuestionModelcalls({ params }: { params: GetQuestionModelcallsQueryParams }, config: Partial<RequestConfig> = {}) {
  const res = await client<GetQuestionModelcallsQueryResponse, GetQuestionModelcalls422, unknown>({
    method: 'GET',
    url: `/api/v1/admin/promptlog/modelcalls`,
    params,
    ...config,
  })
  return res.data
}

export function getQuestionModelcallsQueryOptions({ params }: { params: GetQuestionModelcallsQueryParams }, config: Partial<RequestConfig> = {}) {
  const queryKey = getQuestionModelcallsQueryKey(params)
  return queryOptions({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getQuestionModelcalls({ params }, config)
    },
  })
}

/**
 * @description Admin endpoint to get model call history for a question.Admin endpoints require a superuser token. It's intended that an NS admin could log in, thenpass in any bumblebee cell id.Returns:    This returns a JSON list of model calls in reverse chronological order, by    default only the most recent.
 * @summary Get Question Modelcalls
 * {@link /api/v1/admin/promptlog/modelcalls}
 */
export function useGetQuestionModelcalls<
  TData = GetQuestionModelcallsQueryResponse,
  TQueryData = GetQuestionModelcallsQueryResponse,
  TQueryKey extends QueryKey = GetQuestionModelcallsQueryKey,
>(
  { params }: { params: GetQuestionModelcallsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetQuestionModelcallsQueryResponse, GetQuestionModelcalls422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getQuestionModelcallsQueryKey(params)

  const query = useQuery({
    ...(getQuestionModelcallsQueryOptions({ params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetQuestionModelcalls422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}