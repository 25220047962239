// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadConversationMessages.ts"
);
import.meta.hot.lastModified = "1737935768812.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadConversationMessagesQueryResponse,
  ReadConversationMessagesPathParams,
  ReadConversationMessagesQueryParams,
  ReadConversationMessages422,
} from '../types/ReadConversationMessages'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readConversationMessagesQueryKey = (
  {
    accountName,
    conversationId,
  }: { accountName: ReadConversationMessagesPathParams['account_name']; conversationId: ReadConversationMessagesPathParams['conversation_id'] },
  params?: ReadConversationMessagesQueryParams,
) =>
  [
    { url: '/api/v2/orgs/:account_name/conversations/:conversation_id', params: { accountName: accountName, conversationId: conversationId } },
    ...(params ? [params] : []),
  ] as const

export type ReadConversationMessagesQueryKey = ReturnType<typeof readConversationMessagesQueryKey>

/**
 * @description Get messages in a conversation.This paginated endpoint returns a conversation with up to the requested number ofmessages. Note that the messages are ordered from newest to oldest. The client canspecify that they want messages after a particular index by providing an offset inorder to only get new messages. Note that the server can choose to exclude the payload(returning null) from some messages for performance reasons. The client must then makeindividual requests for those messages to get the contents.Params:    accepted_payload_types: The payload types that can be used in the conversation.        If included in the params then it must include the TEXT type. Note that        excluding a type can cause messages of that type not to be sent. Messages are not        always converted to an accepted type (i.e. if you want table contents, you        have to have the “TABLE” type in the accepted types). If None then all types        are accepted.    limit: The maximum number of messages to return.    offset: The number of messages to skip.    bound: The index of the last message to return. The response will not include        indexes less than this value.
 * @summary Read Conversation Messages
 * {@link /api/v2/orgs/:account_name/conversations/:conversation_id}
 */
async function readConversationMessages(
  {
    accountName,
    conversationId,
    params,
  }: {
    accountName: ReadConversationMessagesPathParams['account_name']
    conversationId: ReadConversationMessagesPathParams['conversation_id']
    params?: ReadConversationMessagesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadConversationMessagesQueryResponse, ReadConversationMessages422, unknown>({
    method: 'GET',
    url: `/api/v2/orgs/${accountName}/conversations/${conversationId}`,
    params,
    ...config,
  })
  return res.data
}

export function readConversationMessagesQueryOptions(
  {
    accountName,
    conversationId,
    params,
  }: {
    accountName: ReadConversationMessagesPathParams['account_name']
    conversationId: ReadConversationMessagesPathParams['conversation_id']
    params?: ReadConversationMessagesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readConversationMessagesQueryKey({ accountName, conversationId }, params)
  return queryOptions({
    enabled: !!(accountName && conversationId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readConversationMessages({ accountName, conversationId, params }, config)
    },
  })
}

/**
 * @description Get messages in a conversation.This paginated endpoint returns a conversation with up to the requested number ofmessages. Note that the messages are ordered from newest to oldest. The client canspecify that they want messages after a particular index by providing an offset inorder to only get new messages. Note that the server can choose to exclude the payload(returning null) from some messages for performance reasons. The client must then makeindividual requests for those messages to get the contents.Params:    accepted_payload_types: The payload types that can be used in the conversation.        If included in the params then it must include the TEXT type. Note that        excluding a type can cause messages of that type not to be sent. Messages are not        always converted to an accepted type (i.e. if you want table contents, you        have to have the “TABLE” type in the accepted types). If None then all types        are accepted.    limit: The maximum number of messages to return.    offset: The number of messages to skip.    bound: The index of the last message to return. The response will not include        indexes less than this value.
 * @summary Read Conversation Messages
 * {@link /api/v2/orgs/:account_name/conversations/:conversation_id}
 */
export function useReadConversationMessages<
  TData = ReadConversationMessagesQueryResponse,
  TQueryData = ReadConversationMessagesQueryResponse,
  TQueryKey extends QueryKey = ReadConversationMessagesQueryKey,
>(
  {
    accountName,
    conversationId,
    params,
  }: {
    accountName: ReadConversationMessagesPathParams['account_name']
    conversationId: ReadConversationMessagesPathParams['conversation_id']
    params?: ReadConversationMessagesQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadConversationMessagesQueryResponse, ReadConversationMessages422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readConversationMessagesQueryKey({ accountName, conversationId }, params)

  const query = useQuery({
    ...(readConversationMessagesQueryOptions({ accountName, conversationId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadConversationMessages422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}