// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/TableauService.ts"
);
import.meta.hot.lastModified = "1737935753842.8232";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Report } from '../models/Report';
import type { TableauConnection } from '../models/TableauConnection';
import type { TableauConnectionCreate } from '../models/TableauConnectionCreate';
import type { TableauProject } from '../models/TableauProject';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TableauService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Tableau Connection
   * Create new tableau connection.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns TableauConnection Successful Response
   * @throws ApiError
   */
  public createTableauConnection(
    accountName: string,
    requestBody: TableauConnectionCreate,
    sessionId?: (string | null),
  ): CancelablePromise<TableauConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/tableau/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Tableau Connections
   * List existing tableau connections.
   * @param accountName
   * @param limit
   * @param skip
   * @param sessionId
   * @returns TableauConnection Successful Response
   * @throws ApiError
   */
  public readTableauConnections(
    accountName: string,
    limit: number = 100,
    skip?: number,
    sessionId?: (string | null),
  ): CancelablePromise<Array<TableauConnection>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/tableau/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Tableau Connection
   * Delete Tableau connection.
   *
   * Note: This will delete the connection only if it is not used by any report.
   * @param accountName
   * @param tableauConnectionId
   * @param sessionId
   * @returns TableauConnection Successful Response
   * @throws ApiError
   */
  public deleteTableauConnection(
    accountName: string,
    tableauConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<TableauConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/tableau/{tableau_connection_id}',
      path: {
        'account_name': accountName,
        'tableau_connection_id': tableauConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Tableau Connection Projects
   * Read Tableau projects.
   * @param accountName
   * @param tableauConnectionId
   * @param sessionId
   * @returns TableauProject Successful Response
   * @throws ApiError
   */
  public readTableauConnectionProjects(
    accountName: string,
    tableauConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<TableauProject>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/tableau/{tableau_connection_id}/projects',
      path: {
        'account_name': accountName,
        'tableau_connection_id': tableauConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Tableau Connection Workbooks
   * Read Tableau workbooks.
   *
   * If project is provided, only workbooks in that project are returned. Otherwise, all workbooks
   * are returned.
   * @param accountName
   * @param tableauConnectionId
   * @param projectName
   * @param sessionId
   * @returns Report Successful Response
   * @throws ApiError
   */
  public readTableauConnectionWorkbooks(
    accountName: string,
    tableauConnectionId: string,
    projectName?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<Array<Report>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/tableau/{tableau_connection_id}/workbooks',
      path: {
        'account_name': accountName,
        'tableau_connection_id': tableauConnectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'project_name': projectName,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
