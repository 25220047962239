// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deletePostgresConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759761850.0178";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { postgresConnectionSchema } from './postgresConnectionSchema'
import { z } from 'zod'

export const deletePostgresConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  postgres_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const deletePostgresConnection200Schema = z.lazy(() => postgresConnectionSchema)

/**
 * @description Validation Error
 */
export const deletePostgresConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const deletePostgresConnectionMutationResponseSchema = z.lazy(() => deletePostgresConnection200Schema)