// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetApiKey.ts"
);
import.meta.hot.lastModified = "1737935765108.7612";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetApiKeyQueryResponse, GetApiKeyPathParams, GetApiKey422 } from '../types/GetApiKey'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getApiKeyQueryKey = ({ accountName }: { accountName: GetApiKeyPathParams['account_name'] }) =>
  [{ url: '/api/v1/accounts/:account_name/api_key', params: { accountName: accountName } }] as const

export type GetApiKeyQueryKey = ReturnType<typeof getApiKeyQueryKey>

/**
 * @description Get the account's API Key.
 * @summary Get Api Key
 * {@link /api/v1/accounts/:account_name/api_key}
 */
async function getApiKey({ accountName }: { accountName: GetApiKeyPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<GetApiKeyQueryResponse, GetApiKey422, unknown>({ method: 'GET', url: `/api/v1/accounts/${accountName}/api_key`, ...config })
  return res.data
}

export function getApiKeyQueryOptions({ accountName }: { accountName: GetApiKeyPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const queryKey = getApiKeyQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getApiKey({ accountName }, config)
    },
  })
}

/**
 * @description Get the account's API Key.
 * @summary Get Api Key
 * {@link /api/v1/accounts/:account_name/api_key}
 */
export function useGetApiKey<TData = GetApiKeyQueryResponse, TQueryData = GetApiKeyQueryResponse, TQueryKey extends QueryKey = GetApiKeyQueryKey>(
  { accountName }: { accountName: GetApiKeyPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<GetApiKeyQueryResponse, GetApiKey422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getApiKeyQueryKey({ accountName })

  const query = useQuery({
    ...(getApiKeyQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetApiKey422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}