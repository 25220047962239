// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readSnowflakeConnectionColumnsSchema.ts"
);
import.meta.hot.lastModified = "1737759762506.0144";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tableColumnSchema } from './tableColumnSchema'
import { z } from 'zod'

export const readSnowflakeConnectionColumnsPathParamsSchema = z.object({
  account_name: z.string(),
  snowflake_connection_id: z.string(),
})

export const readSnowflakeConnectionColumnsQueryParamsSchema = z.object({
  database: z.string().min(1),
  schema: z.string().min(1),
  table: z.string().min(1),
})

/**
 * @description Successful Response
 */
export const readSnowflakeConnectionColumns200Schema = z.array(z.lazy(() => tableColumnSchema))

/**
 * @description Validation Error
 */
export const readSnowflakeConnectionColumns422Schema = z.lazy(() => httpValidationErrorSchema)

export const readSnowflakeConnectionColumnsQueryResponseSchema = z.lazy(() => readSnowflakeConnectionColumns200Schema)