// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadMetric.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadMetricQueryResponse, ReadMetricPathParams, ReadMetric422 } from '../types/ReadMetric'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readMetricQueryKey = ({
  metricId,
  accountName,
}: {
  metricId: ReadMetricPathParams['metric_id']
  accountName: ReadMetricPathParams['account_name']
}) => [{ url: '/api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id', params: { accountName: accountName, metricId: metricId } }] as const

export type ReadMetricQueryKey = ReturnType<typeof readMetricQueryKey>

/**
 * @description Read a metric.
 * @summary Read Metric
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id}
 */
async function readMetric(
  { metricId, accountName }: { metricId: ReadMetricPathParams['metric_id']; accountName: ReadMetricPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadMetricQueryResponse, ReadMetric422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/${metricId}`,
    ...config,
  })
  return res.data
}

export function readMetricQueryOptions(
  { metricId, accountName }: { metricId: ReadMetricPathParams['metric_id']; accountName: ReadMetricPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readMetricQueryKey({ metricId, accountName })
  return queryOptions({
    enabled: !!(metricId && accountName),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readMetric({ metricId, accountName }, config)
    },
  })
}

/**
 * @description Read a metric.
 * @summary Read Metric
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id}
 */
export function useReadMetric<TData = ReadMetricQueryResponse, TQueryData = ReadMetricQueryResponse, TQueryKey extends QueryKey = ReadMetricQueryKey>(
  { metricId, accountName }: { metricId: ReadMetricPathParams['metric_id']; accountName: ReadMetricPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadMetricQueryResponse, ReadMetric422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readMetricQueryKey({ metricId, accountName })

  const query = useQuery({
    ...(readMetricQueryOptions({ metricId, accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadMetric422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}