// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useResetAccountSettings.ts"
);
import.meta.hot.lastModified = "1737935768071.745";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ResetAccountSettingsMutationRequest,
  ResetAccountSettingsMutationResponse,
  ResetAccountSettingsPathParams,
  ResetAccountSettings422,
} from '../types/ResetAccountSettings'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const resetAccountSettingsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/settings/reset' }] as const

export type ResetAccountSettingsMutationKey = ReturnType<typeof resetAccountSettingsMutationKey>

/**
 * @description Reset settings for an account.Resets the specified settings to their default values.
 * @summary Reset Account Settings
 * {@link /api/v1/accounts/:account_name/settings/reset}
 */
async function resetAccountSettings(
  { accountName, data }: { accountName: ResetAccountSettingsPathParams['account_name']; data?: ResetAccountSettingsMutationRequest },
  config: Partial<RequestConfig<ResetAccountSettingsMutationRequest>> = {},
) {
  const res = await client<ResetAccountSettingsMutationResponse, ResetAccountSettings422, ResetAccountSettingsMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/settings/reset`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Reset settings for an account.Resets the specified settings to their default values.
 * @summary Reset Account Settings
 * {@link /api/v1/accounts/:account_name/settings/reset}
 */
export function useResetAccountSettings(
  options: {
    mutation?: UseMutationOptions<
      ResetAccountSettingsMutationResponse,
      ResetAccountSettings422,
      { accountName: ResetAccountSettingsPathParams['account_name']; data?: ResetAccountSettingsMutationRequest }
    >
    client?: Partial<RequestConfig<ResetAccountSettingsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? resetAccountSettingsMutationKey()

  return useMutation<
    ResetAccountSettingsMutationResponse,
    ResetAccountSettings422,
    { accountName: ResetAccountSettingsPathParams['account_name']; data?: ResetAccountSettingsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return resetAccountSettings({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}