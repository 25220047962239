// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateCell.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateCellMutationRequest, CreateCellMutationResponse, CreateCellPathParams, CreateCell422 } from '../types/CreateCell'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createCellMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/' }] as const

export type CreateCellMutationKey = ReturnType<typeof createCellMutationKey>

/**
 * @description Create a new Bumblebee Cell.
 * @summary Create Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/}
 */
async function createCell(
  {
    accountName,
    conversationId,
    data,
  }: { accountName: CreateCellPathParams['account_name']; conversationId: CreateCellPathParams['conversation_id']; data: CreateCellMutationRequest },
  config: Partial<RequestConfig<CreateCellMutationRequest>> = {},
) {
  const res = await client<CreateCellMutationResponse, CreateCell422, CreateCellMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a new Bumblebee Cell.
 * @summary Create Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/}
 */
export function useCreateCell(
  options: {
    mutation?: UseMutationOptions<
      CreateCellMutationResponse,
      CreateCell422,
      { accountName: CreateCellPathParams['account_name']; conversationId: CreateCellPathParams['conversation_id']; data: CreateCellMutationRequest }
    >
    client?: Partial<RequestConfig<CreateCellMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createCellMutationKey()

  return useMutation<
    CreateCellMutationResponse,
    CreateCell422,
    { accountName: CreateCellPathParams['account_name']; conversationId: CreateCellPathParams['conversation_id']; data: CreateCellMutationRequest }
  >({
    mutationFn: async ({ accountName, conversationId, data }) => {
      return createCell({ accountName, conversationId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}