// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadModeConnections.ts"
);
import.meta.hot.lastModified = "1737935767879.746";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadModeConnectionsQueryResponse,
  ReadModeConnectionsPathParams,
  ReadModeConnectionsQueryParams,
  ReadModeConnections422,
} from '../types/ReadModeConnections'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readModeConnectionsQueryKey = (
  { accountName }: { accountName: ReadModeConnectionsPathParams['account_name'] },
  params?: ReadModeConnectionsQueryParams,
) => [{ url: '/api/v1/accounts/:account_name/connections/mode/', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type ReadModeConnectionsQueryKey = ReturnType<typeof readModeConnectionsQueryKey>

/**
 * @description List existing mode connections.
 * @summary Read Mode Connections
 * {@link /api/v1/accounts/:account_name/connections/mode/}
 */
async function readModeConnections(
  { accountName, params }: { accountName: ReadModeConnectionsPathParams['account_name']; params?: ReadModeConnectionsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadModeConnectionsQueryResponse, ReadModeConnections422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/mode/`,
    params,
    ...config,
  })
  return res.data
}

export function readModeConnectionsQueryOptions(
  { accountName, params }: { accountName: ReadModeConnectionsPathParams['account_name']; params?: ReadModeConnectionsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readModeConnectionsQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readModeConnections({ accountName, params }, config)
    },
  })
}

/**
 * @description List existing mode connections.
 * @summary Read Mode Connections
 * {@link /api/v1/accounts/:account_name/connections/mode/}
 */
export function useReadModeConnections<
  TData = ReadModeConnectionsQueryResponse,
  TQueryData = ReadModeConnectionsQueryResponse,
  TQueryKey extends QueryKey = ReadModeConnectionsQueryKey,
>(
  { accountName, params }: { accountName: ReadModeConnectionsPathParams['account_name']; params?: ReadModeConnectionsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadModeConnectionsQueryResponse, ReadModeConnections422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readModeConnectionsQueryKey({ accountName }, params)

  const query = useQuery({
    ...(readModeConnectionsQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadModeConnections422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}