// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/accountCreateSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Properties to receive via API on creation.
 */
export const accountCreateSchema = z
  .object({
    name: z.string().min(1),
    display_name: z.string().min(1),
    description: z.union([z.string(), z.null()]).optional(),
    avatar: z.union([z.string().url(), z.null()]).optional(),
    colors: z.union([z.array(z.string()).min(10), z.null()]).optional(),
  })
  .describe('Properties to receive via API on creation.')