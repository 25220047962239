// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateIdentifiers.ts"
);
import.meta.hot.lastModified = "1737759760238.0261";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateIdentifiersMutationRequest,
  CreateIdentifiersMutationResponse,
  CreateIdentifiersPathParams,
  CreateIdentifiersQueryParams,
  CreateIdentifiers422,
} from '../types/CreateIdentifiers'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createIdentifiersMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/identifiers' }] as const

export type CreateIdentifiersMutationKey = ReturnType<typeof createIdentifiersMutationKey>

/**
 * @summary Create Identifiers
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/identifiers}
 */
async function createIdentifiers(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: CreateIdentifiersPathParams['account_name']
    starSchemaDataSourceId: CreateIdentifiersPathParams['star_schema_data_source_id']
    data?: CreateIdentifiersMutationRequest
    params?: CreateIdentifiersQueryParams
  },
  config: Partial<RequestConfig<CreateIdentifiersMutationRequest>> = {},
) {
  const res = await client<CreateIdentifiersMutationResponse, CreateIdentifiers422, CreateIdentifiersMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/identifiers`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Create Identifiers
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/identifiers}
 */
export function useCreateIdentifiers(
  options: {
    mutation?: UseMutationOptions<
      CreateIdentifiersMutationResponse,
      CreateIdentifiers422,
      {
        accountName: CreateIdentifiersPathParams['account_name']
        starSchemaDataSourceId: CreateIdentifiersPathParams['star_schema_data_source_id']
        data?: CreateIdentifiersMutationRequest
        params?: CreateIdentifiersQueryParams
      }
    >
    client?: Partial<RequestConfig<CreateIdentifiersMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createIdentifiersMutationKey()

  return useMutation<
    CreateIdentifiersMutationResponse,
    CreateIdentifiers422,
    {
      accountName: CreateIdentifiersPathParams['account_name']
      starSchemaDataSourceId: CreateIdentifiersPathParams['star_schema_data_source_id']
      data?: CreateIdentifiersMutationRequest
      params?: CreateIdentifiersQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data, params }) => {
      return createIdentifiers({ accountName, starSchemaDataSourceId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}