// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateNamedFiltersSchema.ts"
);
import.meta.hot.lastModified = "1737935767878.746";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { namedFilterBaseSchema } from './namedFilterBaseSchema'
import { namedFilterUpdateApiWithIdSchema } from './namedFilterUpdateApiWithIdSchema'
import { z } from 'zod'

export const updateNamedFiltersPathParamsSchema = z.object({
  star_schema_data_source_id: z.string(),
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const updateNamedFilters200Schema = z.array(z.lazy(() => namedFilterBaseSchema))

/**
 * @description Validation Error
 */
export const updateNamedFilters422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateNamedFiltersMutationRequestSchema = z.array(z.lazy(() => namedFilterUpdateApiWithIdSchema))

export const updateNamedFiltersMutationResponseSchema = z.lazy(() => updateNamedFilters200Schema)