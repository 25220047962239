// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRegisterUser.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RegisterUserMutationRequest, RegisterUserMutationResponse, RegisterUserPathParams, RegisterUser422 } from '../types/RegisterUser'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const registerUserMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/register_user' }] as const

export type RegisterUserMutationKey = ReturnType<typeof registerUserMutationKey>

/**
 * @description Register a user to an account.If it is an SSO environment, the user will be created if it doesn'talready exist, then it will be added to the account.If it is not an SSO environment and the user already exists on a different account,the user will be added to the new account, and they will be sent an account invite emailIf it is not an SSO environment and the user does NOT exist,the user will be preregistered, and they will be sent a preregistration email
 * @summary Register User
 * {@link /api/v1/accounts/:account_name/register_user}
 */
async function registerUser(
  { accountName, data }: { accountName: RegisterUserPathParams['account_name']; data?: RegisterUserMutationRequest },
  config: Partial<RequestConfig<RegisterUserMutationRequest>> = {},
) {
  const res = await client<RegisterUserMutationResponse, RegisterUser422, RegisterUserMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/register_user`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Register a user to an account.If it is an SSO environment, the user will be created if it doesn'talready exist, then it will be added to the account.If it is not an SSO environment and the user already exists on a different account,the user will be added to the new account, and they will be sent an account invite emailIf it is not an SSO environment and the user does NOT exist,the user will be preregistered, and they will be sent a preregistration email
 * @summary Register User
 * {@link /api/v1/accounts/:account_name/register_user}
 */
export function useRegisterUser(
  options: {
    mutation?: UseMutationOptions<
      RegisterUserMutationResponse,
      RegisterUser422,
      { accountName: RegisterUserPathParams['account_name']; data?: RegisterUserMutationRequest }
    >
    client?: Partial<RequestConfig<RegisterUserMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? registerUserMutationKey()

  return useMutation<
    RegisterUserMutationResponse,
    RegisterUser422,
    { accountName: RegisterUserPathParams['account_name']; data?: RegisterUserMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return registerUser({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}