// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsMetricsCount.ts"
);
import.meta.hot.lastModified = "1737935767879.746";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetSsdsMetricsCountQueryResponse,
  GetSsdsMetricsCountPathParams,
  GetSsdsMetricsCountQueryParams,
  GetSsdsMetricsCount422,
} from '../types/GetSsdsMetricsCount'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsMetricsCountQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsMetricsCountPathParams['account_name']; starSchemaDataSourceId: GetSsdsMetricsCountPathParams['star_schema_data_source_id'] },
  params?: GetSsdsMetricsCountQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/metrics_count',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetSsdsMetricsCountQueryKey = ReturnType<typeof getSsdsMetricsCountQueryKey>

/**
 * @description Get the count of Metrics for Star Schema Data Source.This endpoint combines the metrics from the SSDS with all the metrics from each Data Source.If DataSourceIds are specified, then only metrics that referencethe specified Data Sources are counted.
 * @summary Get Ssds Metrics Count
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/metrics_count}
 */
async function getSsdsMetricsCount(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsMetricsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsMetricsCountPathParams['star_schema_data_source_id']
    params?: GetSsdsMetricsCountQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsMetricsCountQueryResponse, GetSsdsMetricsCount422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/metrics_count`,
    params,
    ...config,
  })
  return res.data
}

export function getSsdsMetricsCountQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsMetricsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsMetricsCountPathParams['star_schema_data_source_id']
    params?: GetSsdsMetricsCountQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsMetricsCountQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsMetricsCount({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get the count of Metrics for Star Schema Data Source.This endpoint combines the metrics from the SSDS with all the metrics from each Data Source.If DataSourceIds are specified, then only metrics that referencethe specified Data Sources are counted.
 * @summary Get Ssds Metrics Count
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/metrics_count}
 */
export function useGetSsdsMetricsCount<
  TData = GetSsdsMetricsCountQueryResponse,
  TQueryData = GetSsdsMetricsCountQueryResponse,
  TQueryKey extends QueryKey = GetSsdsMetricsCountQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsMetricsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsMetricsCountPathParams['star_schema_data_source_id']
    params?: GetSsdsMetricsCountQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsMetricsCountQueryResponse, GetSsdsMetricsCount422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsMetricsCountQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getSsdsMetricsCountQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsMetricsCount422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}