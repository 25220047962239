// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteRedshiftConnection.ts"
);
import.meta.hot.lastModified = "1737935768803.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  DeleteRedshiftConnectionMutationResponse,
  DeleteRedshiftConnectionPathParams,
  DeleteRedshiftConnection422,
} from '../types/DeleteRedshiftConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteRedshiftConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}' }] as const

export type DeleteRedshiftConnectionMutationKey = ReturnType<typeof deleteRedshiftConnectionMutationKey>

/**
 * @description Delete a Redshift connection.
 * @summary Delete Redshift Connection
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id}
 */
async function deleteRedshiftConnection(
  {
    accountName,
    connectionId,
  }: { accountName: DeleteRedshiftConnectionPathParams['account_name']; connectionId: DeleteRedshiftConnectionPathParams['connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteRedshiftConnectionMutationResponse, DeleteRedshiftConnection422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/connections/redshift/${connectionId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete a Redshift connection.
 * @summary Delete Redshift Connection
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id}
 */
export function useDeleteRedshiftConnection(
  options: {
    mutation?: UseMutationOptions<
      DeleteRedshiftConnectionMutationResponse,
      DeleteRedshiftConnection422,
      { accountName: DeleteRedshiftConnectionPathParams['account_name']; connectionId: DeleteRedshiftConnectionPathParams['connection_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteRedshiftConnectionMutationKey()

  return useMutation<
    DeleteRedshiftConnectionMutationResponse,
    DeleteRedshiftConnection422,
    { accountName: DeleteRedshiftConnectionPathParams['account_name']; connectionId: DeleteRedshiftConnectionPathParams['connection_id'] }
  >({
    mutationFn: async ({ accountName, connectionId }) => {
      return deleteRedshiftConnection({ accountName, connectionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}