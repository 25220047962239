// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadModeReports.ts"
);
import.meta.hot.lastModified = "1737759759782.0286";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadModeReportsQueryResponse, ReadModeReportsPathParams, ReadModeReportsQueryParams, ReadModeReports422 } from '../types/ReadModeReports'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readModeReportsQueryKey = (
  {
    accountName,
    modeConnectionId,
  }: { accountName: ReadModeReportsPathParams['account_name']; modeConnectionId: ReadModeReportsPathParams['mode_connection_id'] },
  params: ReadModeReportsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/mode/:mode_connection_id/reports',
      params: { accountName: accountName, modeConnectionId: modeConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadModeReportsQueryKey = ReturnType<typeof readModeReportsQueryKey>

/**
 * @description Read the specified mode reports.
 * @summary Read Mode Reports
 * {@link /api/v1/accounts/:account_name/connections/mode/:mode_connection_id/reports}
 */
async function readModeReports(
  {
    accountName,
    modeConnectionId,
    params,
  }: {
    accountName: ReadModeReportsPathParams['account_name']
    modeConnectionId: ReadModeReportsPathParams['mode_connection_id']
    params: ReadModeReportsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadModeReportsQueryResponse, ReadModeReports422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/mode/${modeConnectionId}/reports`,
    params,
    ...config,
  })
  return res.data
}

export function readModeReportsQueryOptions(
  {
    accountName,
    modeConnectionId,
    params,
  }: {
    accountName: ReadModeReportsPathParams['account_name']
    modeConnectionId: ReadModeReportsPathParams['mode_connection_id']
    params: ReadModeReportsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readModeReportsQueryKey({ accountName, modeConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && modeConnectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readModeReports({ accountName, modeConnectionId, params }, config)
    },
  })
}

/**
 * @description Read the specified mode reports.
 * @summary Read Mode Reports
 * {@link /api/v1/accounts/:account_name/connections/mode/:mode_connection_id/reports}
 */
export function useReadModeReports<
  TData = ReadModeReportsQueryResponse,
  TQueryData = ReadModeReportsQueryResponse,
  TQueryKey extends QueryKey = ReadModeReportsQueryKey,
>(
  {
    accountName,
    modeConnectionId,
    params,
  }: {
    accountName: ReadModeReportsPathParams['account_name']
    modeConnectionId: ReadModeReportsPathParams['mode_connection_id']
    params: ReadModeReportsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadModeReportsQueryResponse, ReadModeReports422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readModeReportsQueryKey({ accountName, modeConnectionId }, params)

  const query = useQuery({
    ...(readModeReportsQueryOptions({ accountName, modeConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadModeReports422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}