// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/copyReportSchema.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

// @ts-nocheck
import { datasetStatsSchema } from './datasetStatsSchema'
import { z } from 'zod'

/**
 * @description Report comparing the stats of source dataset and its copy.
 */
export const copyReportSchema = z
  .object({
    src: z.lazy(() => datasetStatsSchema),
    dst: z.lazy(() => datasetStatsSchema),
    execution_accuracy: z.number().min(0).max(1),
    smoke_test_passed: z.boolean(),
  })
  .describe('Report comparing the stats of source dataset and its copy.')