// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadSnowflakeConnectionRegion.ts"
);
import.meta.hot.lastModified = "1737759762296.0156";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadSnowflakeConnectionRegionQueryResponse,
  ReadSnowflakeConnectionRegionPathParams,
  ReadSnowflakeConnectionRegion422,
} from '../types/ReadSnowflakeConnectionRegion'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readSnowflakeConnectionRegionQueryKey = ({
  accountName,
  snowflakeConnectionId,
}: {
  accountName: ReadSnowflakeConnectionRegionPathParams['account_name']
  snowflakeConnectionId: ReadSnowflakeConnectionRegionPathParams['snowflake_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/region',
      params: { accountName: accountName, snowflakeConnectionId: snowflakeConnectionId },
    },
  ] as const

export type ReadSnowflakeConnectionRegionQueryKey = ReturnType<typeof readSnowflakeConnectionRegionQueryKey>

/**
 * @description Read Snowflake regions.
 * @summary Read Snowflake Connection Region
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/region}
 */
async function readSnowflakeConnectionRegion(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionRegionPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionRegionPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadSnowflakeConnectionRegionQueryResponse, ReadSnowflakeConnectionRegion422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/${snowflakeConnectionId}/region`,
    ...config,
  })
  return res.data
}

export function readSnowflakeConnectionRegionQueryOptions(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionRegionPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionRegionPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readSnowflakeConnectionRegionQueryKey({ accountName, snowflakeConnectionId })
  return queryOptions({
    enabled: !!(accountName && snowflakeConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readSnowflakeConnectionRegion({ accountName, snowflakeConnectionId }, config)
    },
  })
}

/**
 * @description Read Snowflake regions.
 * @summary Read Snowflake Connection Region
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/region}
 */
export function useReadSnowflakeConnectionRegion<
  TData = ReadSnowflakeConnectionRegionQueryResponse,
  TQueryData = ReadSnowflakeConnectionRegionQueryResponse,
  TQueryKey extends QueryKey = ReadSnowflakeConnectionRegionQueryKey,
>(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionRegionPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionRegionPathParams['snowflake_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadSnowflakeConnectionRegionQueryResponse, ReadSnowflakeConnectionRegion422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readSnowflakeConnectionRegionQueryKey({ accountName, snowflakeConnectionId })

  const query = useQuery({
    ...(readSnowflakeConnectionRegionQueryOptions({ accountName, snowflakeConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadSnowflakeConnectionRegion422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}