// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/namedFilterOptionConditionValidOperatorsWithGroupSchema.ts"
);
import.meta.hot.lastModified = "1737935769735.7358";
}
// REMIX HMR END

// @ts-nocheck
import { filterCategorySchema } from './filterCategorySchema'
import { filterOperatorSchema } from './filterOperatorSchema'
import { filterTypeSchema } from './filterTypeSchema'
import { namedFilterOptionConditionValidOperatorsSchema } from './namedFilterOptionConditionValidOperatorsSchema'
import { publishStateSchema } from './publishStateSchema'
import { z } from 'zod'

/**
 * @description Valid Operators for a NamedFilterOptionCondition with group.
 */
export const namedFilterOptionConditionValidOperatorsWithGroupSchema = z
  .object({
    metric_id: z.union([z.string(), z.null()]).optional(),
    dimension_id: z.union([z.string(), z.null()]).optional(),
    filter_type: z.lazy(() => filterTypeSchema),
    display_name: z.union([z.string(), z.null()]),
    name: z.string(),
    category: z.lazy(() => filterCategorySchema),
    valid_operators: z.array(z.lazy(() => filterOperatorSchema)),
    expression: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    state: z.union([z.lazy(() => publishStateSchema), z.null()]).optional(),
    group: z.union([z.array(z.lazy(() => namedFilterOptionConditionValidOperatorsSchema)), z.null()]).optional(),
  })
  .describe('Valid Operators for a NamedFilterOptionCondition with group.')