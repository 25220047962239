// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteNamedFiltersSchema.ts"
);
import.meta.hot.lastModified = "1737935767878.746";
}
// REMIX HMR END

// @ts-nocheck
import { deleteRequestSchema } from './deleteRequestSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const deleteNamedFiltersPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const deleteNamedFilters200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const deleteNamedFilters422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteNamedFiltersMutationRequestSchema = z.array(z.lazy(() => deleteRequestSchema))

export const deleteNamedFiltersMutationResponseSchema = z.lazy(() => deleteNamedFilters200Schema)