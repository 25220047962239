// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateJoins.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateJoinsMutationRequest,
  CreateJoinsMutationResponse,
  CreateJoinsPathParams,
  CreateJoinsQueryParams,
  CreateJoins422,
} from '../types/CreateJoins'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createJoinsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/joins' }] as const

export type CreateJoinsMutationKey = ReturnType<typeof createJoinsMutationKey>

/**
 * @description Create Joins for Star Schema Data Source.we will return the ambiguous join groups,i.e. a list of join groups that have more than one bridge tables.
 * @summary Create Joins
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/joins}
 */
async function createJoins(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: CreateJoinsPathParams['account_name']
    starSchemaDataSourceId: CreateJoinsPathParams['star_schema_data_source_id']
    data?: CreateJoinsMutationRequest
    params?: CreateJoinsQueryParams
  },
  config: Partial<RequestConfig<CreateJoinsMutationRequest>> = {},
) {
  const res = await client<CreateJoinsMutationResponse, CreateJoins422, CreateJoinsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/joins`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create Joins for Star Schema Data Source.we will return the ambiguous join groups,i.e. a list of join groups that have more than one bridge tables.
 * @summary Create Joins
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/joins}
 */
export function useCreateJoins(
  options: {
    mutation?: UseMutationOptions<
      CreateJoinsMutationResponse,
      CreateJoins422,
      {
        accountName: CreateJoinsPathParams['account_name']
        starSchemaDataSourceId: CreateJoinsPathParams['star_schema_data_source_id']
        data?: CreateJoinsMutationRequest
        params?: CreateJoinsQueryParams
      }
    >
    client?: Partial<RequestConfig<CreateJoinsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createJoinsMutationKey()

  return useMutation<
    CreateJoinsMutationResponse,
    CreateJoins422,
    {
      accountName: CreateJoinsPathParams['account_name']
      starSchemaDataSourceId: CreateJoinsPathParams['star_schema_data_source_id']
      data?: CreateJoinsMutationRequest
      params?: CreateJoinsQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data, params }) => {
      return createJoins({ accountName, starSchemaDataSourceId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}