// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadModeReportsFromCollection.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadModeReportsFromCollectionQueryResponse,
  ReadModeReportsFromCollectionPathParams,
  ReadModeReportsFromCollection422,
} from '../types/ReadModeReportsFromCollection'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readModeReportsFromCollectionQueryKey = ({
  collectionToken,
  accountName,
  modeConnectionId,
}: {
  collectionToken: ReadModeReportsFromCollectionPathParams['collection_token']
  accountName: ReadModeReportsFromCollectionPathParams['account_name']
  modeConnectionId: ReadModeReportsFromCollectionPathParams['mode_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/mode/:mode_connection_id/collections/:collection_token/reports',
      params: { accountName: accountName, modeConnectionId: modeConnectionId, collectionToken: collectionToken },
    },
  ] as const

export type ReadModeReportsFromCollectionQueryKey = ReturnType<typeof readModeReportsFromCollectionQueryKey>

/**
 * @description Read the mode reports in the given collection.
 * @summary Read Mode Reports From Collection
 * {@link /api/v1/accounts/:account_name/connections/mode/:mode_connection_id/collections/:collection_token/reports}
 */
async function readModeReportsFromCollection(
  {
    collectionToken,
    accountName,
    modeConnectionId,
  }: {
    collectionToken: ReadModeReportsFromCollectionPathParams['collection_token']
    accountName: ReadModeReportsFromCollectionPathParams['account_name']
    modeConnectionId: ReadModeReportsFromCollectionPathParams['mode_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadModeReportsFromCollectionQueryResponse, ReadModeReportsFromCollection422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/mode/${modeConnectionId}/collections/${collectionToken}/reports`,
    ...config,
  })
  return res.data
}

export function readModeReportsFromCollectionQueryOptions(
  {
    collectionToken,
    accountName,
    modeConnectionId,
  }: {
    collectionToken: ReadModeReportsFromCollectionPathParams['collection_token']
    accountName: ReadModeReportsFromCollectionPathParams['account_name']
    modeConnectionId: ReadModeReportsFromCollectionPathParams['mode_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readModeReportsFromCollectionQueryKey({ collectionToken, accountName, modeConnectionId })
  return queryOptions({
    enabled: !!(collectionToken && accountName && modeConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readModeReportsFromCollection({ collectionToken, accountName, modeConnectionId }, config)
    },
  })
}

/**
 * @description Read the mode reports in the given collection.
 * @summary Read Mode Reports From Collection
 * {@link /api/v1/accounts/:account_name/connections/mode/:mode_connection_id/collections/:collection_token/reports}
 */
export function useReadModeReportsFromCollection<
  TData = ReadModeReportsFromCollectionQueryResponse,
  TQueryData = ReadModeReportsFromCollectionQueryResponse,
  TQueryKey extends QueryKey = ReadModeReportsFromCollectionQueryKey,
>(
  {
    collectionToken,
    accountName,
    modeConnectionId,
  }: {
    collectionToken: ReadModeReportsFromCollectionPathParams['collection_token']
    accountName: ReadModeReportsFromCollectionPathParams['account_name']
    modeConnectionId: ReadModeReportsFromCollectionPathParams['mode_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadModeReportsFromCollectionQueryResponse, ReadModeReportsFromCollection422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readModeReportsFromCollectionQueryKey({ collectionToken, accountName, modeConnectionId })

  const query = useQuery({
    ...(readModeReportsFromCollectionQueryOptions({ collectionToken, accountName, modeConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadModeReportsFromCollection422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}