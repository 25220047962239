// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadSnowflakeConnectionColumns.ts"
);
import.meta.hot.lastModified = "1737759762343.0154";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadSnowflakeConnectionColumnsQueryResponse,
  ReadSnowflakeConnectionColumnsPathParams,
  ReadSnowflakeConnectionColumnsQueryParams,
  ReadSnowflakeConnectionColumns422,
} from '../types/ReadSnowflakeConnectionColumns'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readSnowflakeConnectionColumnsQueryKey = (
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionColumnsPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionColumnsPathParams['snowflake_connection_id']
  },
  params: ReadSnowflakeConnectionColumnsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/columns',
      params: { accountName: accountName, snowflakeConnectionId: snowflakeConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadSnowflakeConnectionColumnsQueryKey = ReturnType<typeof readSnowflakeConnectionColumnsQueryKey>

/**
 * @description Read Snowflake table columns.
 * @summary Read Snowflake Connection Columns
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/columns}
 */
async function readSnowflakeConnectionColumns(
  {
    accountName,
    snowflakeConnectionId,
    params,
  }: {
    accountName: ReadSnowflakeConnectionColumnsPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionColumnsPathParams['snowflake_connection_id']
    params: ReadSnowflakeConnectionColumnsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadSnowflakeConnectionColumnsQueryResponse, ReadSnowflakeConnectionColumns422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/${snowflakeConnectionId}/columns`,
    params,
    ...config,
  })
  return res.data
}

export function readSnowflakeConnectionColumnsQueryOptions(
  {
    accountName,
    snowflakeConnectionId,
    params,
  }: {
    accountName: ReadSnowflakeConnectionColumnsPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionColumnsPathParams['snowflake_connection_id']
    params: ReadSnowflakeConnectionColumnsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readSnowflakeConnectionColumnsQueryKey({ accountName, snowflakeConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && snowflakeConnectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readSnowflakeConnectionColumns({ accountName, snowflakeConnectionId, params }, config)
    },
  })
}

/**
 * @description Read Snowflake table columns.
 * @summary Read Snowflake Connection Columns
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/columns}
 */
export function useReadSnowflakeConnectionColumns<
  TData = ReadSnowflakeConnectionColumnsQueryResponse,
  TQueryData = ReadSnowflakeConnectionColumnsQueryResponse,
  TQueryKey extends QueryKey = ReadSnowflakeConnectionColumnsQueryKey,
>(
  {
    accountName,
    snowflakeConnectionId,
    params,
  }: {
    accountName: ReadSnowflakeConnectionColumnsPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionColumnsPathParams['snowflake_connection_id']
    params: ReadSnowflakeConnectionColumnsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadSnowflakeConnectionColumnsQueryResponse, ReadSnowflakeConnectionColumns422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readSnowflakeConnectionColumnsQueryKey({ accountName, snowflakeConnectionId }, params)

  const query = useQuery({
    ...(readSnowflakeConnectionColumnsQueryOptions({ accountName, snowflakeConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadSnowflakeConnectionColumns422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}