// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/BigQueryLocation.ts"
);
import.meta.hot.lastModified = "1737935766030.756";
}
// REMIX HMR END

export enum BigQueryLocation {
  'asia-east1' = 'asia-east1',
  'asia-east2' = 'asia-east2',
  'asia-northeast1' = 'asia-northeast1',
  'asia-northeast2' = 'asia-northeast2',
  'asia-northeast3' = 'asia-northeast3',
  'asia-south1' = 'asia-south1',
  'asia-south2' = 'asia-south2',
  'asia-southeast2' = 'asia-southeast2',
  'asia-southeast3' = 'asia-southeast3',
  'australia-southeast1' = 'australia-southeast1',
  'australia-southeast2' = 'australia-southeast2',
  'EU' = 'EU',
  'europe-central2' = 'europe-central2',
  'europe-north1' = 'europe-north1',
  'europe-southwest1' = 'europe-southwest1',
  'europe-west1' = 'europe-west1',
  'europe-west2' = 'europe-west2',
  'europe-west3' = 'europe-west3',
  'europe-west4' = 'europe-west4',
  'europe-west6' = 'europe-west6',
  'europe-west8' = 'europe-west8',
  'europe-west9' = 'europe-west9',
  'me-west1' = 'me-west1',
  'northamerica-northeast1' = 'northamerica-northeast1',
  'northamerica-northeast2' = 'northamerica-northeast2',
  'southamerica-southeast1' = 'southamerica-southeast1',
  'southamerica-southwest1' = 'southamerica-southwest1',
  'us-central1' = 'us-central1',
  'us-east1' = 'us-east1',
  'us-east4' = 'us-east4',
  'us-east5' = 'us-east5',
  'US' = 'US',
  'us-west1' = 'us-west1',
  'us-west2' = 'us-west2',
  'us-west3' = 'us-west3',
  'us-west4' = 'us-west4',
}