// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCloneChat.ts"
);
import.meta.hot.lastModified = "1737759757951.038";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CloneChatMutationResponse, CloneChatPathParams, CloneChat422 } from '../types/CloneChat'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const cloneChatMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/chat/{chat_id}/clone' }] as const

export type CloneChatMutationKey = ReturnType<typeof cloneChatMutationKey>

/**
 * @description Clone a chat with the new user as the creator.
 * @summary Clone Chat
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/clone}
 */
async function cloneChat(
  { accountName, chatId }: { accountName: CloneChatPathParams['account_name']; chatId: CloneChatPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<CloneChatMutationResponse, CloneChat422, unknown>({
    method: 'POST',
    url: `/api/v3/orgs/${accountName}/chat/${chatId}/clone`,
    ...config,
  })
  return res.data
}

/**
 * @description Clone a chat with the new user as the creator.
 * @summary Clone Chat
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/clone}
 */
export function useCloneChat(
  options: {
    mutation?: UseMutationOptions<
      CloneChatMutationResponse,
      CloneChat422,
      { accountName: CloneChatPathParams['account_name']; chatId: CloneChatPathParams['chat_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? cloneChatMutationKey()

  return useMutation<CloneChatMutationResponse, CloneChat422, { accountName: CloneChatPathParams['account_name']; chatId: CloneChatPathParams['chat_id'] }>({
    mutationFn: async ({ accountName, chatId }) => {
      return cloneChat({ accountName, chatId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}