// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/agentApiSchema.ts"
);
import.meta.hot.lastModified = "1737935764878.7625";
}
// REMIX HMR END

// @ts-nocheck
import { agentDepSchema } from './agentDepSchema'
import { z } from 'zod'

/**
 * @description An agent in the application.\n\nAttributes:\n  name: unique identifier of the agent\n  display_name: human readable name of the agent\n  description: a description of what the agent does\n  default: whether the agent is enabled by default\n  deps: list of agents that must be enabled for this agent to be enabled
 */
export const agentApiSchema = z
  .object({
    name: z.string(),
    display_name: z.string(),
    description: z.string(),
    default: z.boolean(),
    deps: z.array(z.lazy(() => agentDepSchema)),
  })
  .describe(
    'An agent in the application.\n\nAttributes:\n  name: unique identifier of the agent\n  display_name: human readable name of the agent\n  description: a description of what the agent does\n  default: whether the agent is enabled by default\n  deps: list of agents that must be enabled for this agent to be enabled',
  )