// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getAccountSsdsNamedFiltersSchema.ts"
);
import.meta.hot.lastModified = "1737759762067.0168";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { knowledgeStateFilterSchema } from './knowledgeStateFilterSchema'
import { namedFilterBaseSchema } from './namedFilterBaseSchema'
import { z } from 'zod'

export const getAccountSsdsNamedFiltersPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const getAccountSsdsNamedFiltersQueryParamsSchema = z
  .object({
    limit: z.union([z.number().int(), z.null()]).default(200),
    skip: z.number().int().default(0),
    state: z.union([z.lazy(() => knowledgeStateFilterSchema), z.null()]).default('valid'),
    data_source_ids: z.union([z.array(z.string()), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getAccountSsdsNamedFilters200Schema = z.array(z.lazy(() => namedFilterBaseSchema))

/**
 * @description Validation Error
 */
export const getAccountSsdsNamedFilters422Schema = z.lazy(() => httpValidationErrorSchema)

export const getAccountSsdsNamedFiltersQueryResponseSchema = z.lazy(() => getAccountSsdsNamedFilters200Schema)