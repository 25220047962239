// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/cellFeedbackSchema.ts"
);
import.meta.hot.lastModified = "1737759758689.0342";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Cell Feedback Schema.
 */
export const cellFeedbackSchema = z
  .object({
    cell_id: z.string(),
    user_feedback: z.string().default(''),
    is_verified: z.boolean(),
    is_bad_response: z.boolean(),
    is_invalid_query: z.boolean().default(false),
    is_empty_query: z.boolean().default(false),
  })
  .describe('Cell Feedback Schema.')