// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsIdentifiers.ts"
);
import.meta.hot.lastModified = "1737759760463.0251";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetSsdsIdentifiersQueryResponse,
  GetSsdsIdentifiersPathParams,
  GetSsdsIdentifiersQueryParams,
  GetSsdsIdentifiers422,
} from '../types/GetSsdsIdentifiers'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsIdentifiersQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsIdentifiersPathParams['account_name']; starSchemaDataSourceId: GetSsdsIdentifiersPathParams['star_schema_data_source_id'] },
  params?: GetSsdsIdentifiersQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/identifiers',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetSsdsIdentifiersQueryKey = ReturnType<typeof getSsdsIdentifiersQueryKey>

/**
 * @description Get Identifiers for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are returned.
 * @summary Get Ssds Identifiers
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/identifiers}
 */
async function getSsdsIdentifiers(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsIdentifiersPathParams['account_name']
    starSchemaDataSourceId: GetSsdsIdentifiersPathParams['star_schema_data_source_id']
    params?: GetSsdsIdentifiersQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsIdentifiersQueryResponse, GetSsdsIdentifiers422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/identifiers`,
    params,
    ...config,
  })
  return res.data
}

export function getSsdsIdentifiersQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsIdentifiersPathParams['account_name']
    starSchemaDataSourceId: GetSsdsIdentifiersPathParams['star_schema_data_source_id']
    params?: GetSsdsIdentifiersQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsIdentifiersQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsIdentifiers({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get Identifiers for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are returned.
 * @summary Get Ssds Identifiers
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/identifiers}
 */
export function useGetSsdsIdentifiers<
  TData = GetSsdsIdentifiersQueryResponse,
  TQueryData = GetSsdsIdentifiersQueryResponse,
  TQueryKey extends QueryKey = GetSsdsIdentifiersQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsIdentifiersPathParams['account_name']
    starSchemaDataSourceId: GetSsdsIdentifiersPathParams['star_schema_data_source_id']
    params?: GetSsdsIdentifiersQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsIdentifiersQueryResponse, GetSsdsIdentifiers422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsIdentifiersQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getSsdsIdentifiersQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsIdentifiers422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}