// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dmSensitiveKnowledgeImpactSchema.ts"
);
import.meta.hot.lastModified = "1737759762054.0168";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellBasicSchema } from './bumblebeeCellBasicSchema'
import { dimensionViewSchema } from './dimensionViewSchema'
import { dmSensitiveAssignmentSchema } from './dmSensitiveAssignmentSchema'
import { metricViewSchema } from './metricViewSchema'
import { namedFilterBaseSchema } from './namedFilterBaseSchema'
import { z } from 'zod'

/**
 * @description Knowledge Impact for dimension/metric sensitive marking.
 */
export const dmSensitiveKnowledgeImpactSchema = z
  .object({
    dm_reason: z.string().default('These dimensions and metrics will be impacted by the action.'),
    dimensions: z.array(z.lazy(() => dimensionViewSchema)),
    metrics: z.array(z.lazy(() => metricViewSchema)),
    filters: z.union([z.array(z.lazy(() => namedFilterBaseSchema)), z.null()]).optional(),
    cell_reason: z.string().default('These cells will be impacted by the action.'),
    cells: z.array(z.lazy(() => bumblebeeCellBasicSchema)),
    confirmation_payload: z.lazy(() => dmSensitiveAssignmentSchema).default({}),
  })
  .describe('Knowledge Impact for dimension/metric sensitive marking.')