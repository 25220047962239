// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getUpdateJobSchema.ts"
);
import.meta.hot.lastModified = "1737759758959.0327";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { updateJobSchema } from './updateJobSchema'
import { z } from 'zod'

export const getUpdateJobPathParamsSchema = z.object({
  enterprise_name: z.string(),
  job_id: z.string(),
})

/**
 * @description Successful Response
 */
export const getUpdateJob200Schema = z.lazy(() => updateJobSchema)

/**
 * @description Validation Error
 */
export const getUpdateJob422Schema = z.lazy(() => httpValidationErrorSchema)

export const getUpdateJobQueryResponseSchema = z.lazy(() => getUpdateJob200Schema)