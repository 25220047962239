// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/retrievalMethodSchema.ts"
);
import.meta.hot.lastModified = "1737935766766.7522";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Retrieval Method.
 */
export const retrievalMethodSchema = z.enum(['emb', 'txt', 'hybrid']).describe('Retrieval Method.')