// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/listRedshiftColumnsSchema.ts"
);
import.meta.hot.lastModified = "1737935768070.745";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tableColumnSchema } from './tableColumnSchema'
import { z } from 'zod'

export const listRedshiftColumnsPathParamsSchema = z.object({
  account_name: z.string(),
  connection_id: z.string(),
})

export const listRedshiftColumnsQueryParamsSchema = z.object({
  database: z.union([z.string(), z.null()]).optional(),
  schema: z.string().min(1),
  table: z.string().min(1),
})

/**
 * @description Successful Response
 */
export const listRedshiftColumns200Schema = z.array(z.lazy(() => tableColumnSchema))

/**
 * @description Validation Error
 */
export const listRedshiftColumns422Schema = z.lazy(() => httpValidationErrorSchema)

export const listRedshiftColumnsQueryResponseSchema = z.lazy(() => listRedshiftColumns200Schema)