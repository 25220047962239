// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/BigqueryService.ts"
);
import.meta.hot.lastModified = "1737935753830.8232";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BigQueryConnection } from '../models/BigQueryConnection';
import type { BigQueryConnectionAPICreate } from '../models/BigQueryConnectionAPICreate';
import type { BigQueryConnectionAPIUpdate } from '../models/BigQueryConnectionAPIUpdate';
import type { TableColumn } from '../models/TableColumn';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BigqueryService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Bigquery Connection
   * Create a new BigQuery connection.
   * @param accountName
   * @param requestBody
   * @param validate
   * @param sessionId
   * @returns BigQueryConnection Successful Response
   * @throws ApiError
   */
  public createBigqueryConnection(
    accountName: string,
    requestBody: BigQueryConnectionAPICreate,
    validate: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<BigQueryConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/bigquery',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'validate': validate,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Validate Bigquery Connection
   * Validate a BigQuery connection.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns BigQueryConnection Successful Response
   * @throws ApiError
   */
  public validateBigqueryConnection(
    accountName: string,
    connectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<BigQueryConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}/validate',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Bigquery Connection
   * Read a BigQuery connection.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns BigQueryConnection Successful Response
   * @throws ApiError
   */
  public readBigqueryConnection(
    accountName: string,
    connectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<BigQueryConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Bigquery Connection
   * Update a BigQuery connection.
   *
   * Note that all fields for a valid BigQuery connection are required.
   * @param accountName
   * @param connectionId
   * @param requestBody
   * @param validate
   * @param sessionId
   * @returns BigQueryConnection Successful Response
   * @throws ApiError
   */
  public updateBigqueryConnection(
    accountName: string,
    connectionId: string,
    requestBody: BigQueryConnectionAPIUpdate,
    validate: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<BigQueryConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'validate': validate,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Bigquery Connection
   * Delete a BigQuery connection.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns BigQueryConnection Successful Response
   * @throws ApiError
   */
  public deleteBigqueryConnection(
    accountName: string,
    connectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<BigQueryConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Bigquery Datasets
   * List BigQuery datasets.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public listBigqueryDatasets(
    accountName: string,
    connectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}/datasets',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Bigquery Tables
   * List BigQuery tables.
   * @param accountName
   * @param connectionId
   * @param dataset
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public listBigqueryTables(
    accountName: string,
    connectionId: string,
    dataset?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}/tables',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'dataset': dataset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Bigquery Columns
   * List BigQuery columns.
   * @param accountName
   * @param connectionId
   * @param dataset
   * @param table
   * @param sessionId
   * @returns TableColumn Successful Response
   * @throws ApiError
   */
  public listBigqueryColumns(
    accountName: string,
    connectionId: string,
    dataset: string,
    table: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<TableColumn>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}/columns',
      path: {
        'account_name': accountName,
        'connection_id': connectionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'dataset': dataset,
        'table': table,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
