// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/MessagePayloadType.ts"
);
import.meta.hot.lastModified = "1737759759442.0303";
}
// REMIX HMR END

export enum MessagePayloadType {
  'TEXT' = 'TEXT',
  'SEMANTIC_QUERY' = 'SEMANTIC_QUERY',
  'TABLE' = 'TABLE',
  'ERROR' = 'ERROR',
  'PROMPT_OPTIONS' = 'PROMPT_OPTIONS',
}