// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCopyConnection.ts"
);
import.meta.hot.lastModified = "1737935766469.754";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CopyConnectionMutationResponse, CopyConnectionPathParams, CopyConnectionQueryParams, CopyConnection422 } from '../types/CopyConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const copyConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/{connection_id}/copy' }] as const

export type CopyConnectionMutationKey = ReturnType<typeof copyConnectionMutationKey>

/**
 * @description Copy an existing connection to another account.
 * @summary Copy Connection
 * {@link /api/v1/accounts/:account_name/connections/:connection_id/copy}
 */
async function copyConnection(
  {
    accountName,
    connectionId,
    params,
  }: { accountName: CopyConnectionPathParams['account_name']; connectionId: CopyConnectionPathParams['connection_id']; params?: CopyConnectionQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<CopyConnectionMutationResponse, CopyConnection422, unknown>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/connections/${connectionId}/copy`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Copy an existing connection to another account.
 * @summary Copy Connection
 * {@link /api/v1/accounts/:account_name/connections/:connection_id/copy}
 */
export function useCopyConnection(
  options: {
    mutation?: UseMutationOptions<
      CopyConnectionMutationResponse,
      CopyConnection422,
      { accountName: CopyConnectionPathParams['account_name']; connectionId: CopyConnectionPathParams['connection_id']; params?: CopyConnectionQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? copyConnectionMutationKey()

  return useMutation<
    CopyConnectionMutationResponse,
    CopyConnection422,
    { accountName: CopyConnectionPathParams['account_name']; connectionId: CopyConnectionPathParams['connection_id']; params?: CopyConnectionQueryParams }
  >({
    mutationFn: async ({ accountName, connectionId, params }) => {
      return copyConnection({ accountName, connectionId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}