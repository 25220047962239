// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatMessageWithSqlSchema.ts"
);
import.meta.hot.lastModified = "1737935767870.746";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Class for returning chat text-to-SQL examples.
 */
export const chatMessageWithSqlSchema = z
  .object({
    id: z.string(),
    sync_id: z.string(),
    ssds_id: z.string(),
    semantic_query_id: z.union([z.string(), z.null()]),
    knowledge_sql_id: z.string(),
    chat_message_id: z.union([z.string(), z.null()]),
    is_verified: z.boolean(),
    question: z.string(),
    rephrased_user_question: z.union([z.string(), z.null()]),
    sample_values: z.object({}).catchall(z.array(z.union([z.number().int(), z.boolean(), z.string(), z.number()]))),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    knowledge_sql: z.string(),
    prompt_sql: z.string(),
    executable_sql: z.string(),
  })
  .describe('Class for returning chat text-to-SQL examples.')