// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/MessagesService.ts"
);
import.meta.hot.lastModified = "1737759746395.0977";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminMessageAPIResponsePage } from '../models/AdminMessageAPIResponsePage';
import type { Body_get_admin_feedback_api_v3_orgs__account_name__messages_admin_feedback_post } from '../models/Body_get_admin_feedback_api_v3_orgs__account_name__messages_admin_feedback_post';
import type { Body_get_user_feedback_api_v3_orgs__account_name__messages_user_feedback_post } from '../models/Body_get_user_feedback_api_v3_orgs__account_name__messages_user_feedback_post';
import type { ChatAPIResponse } from '../models/ChatAPIResponse';
import type { ChatMessagePromptlogInDB } from '../models/ChatMessagePromptlogInDB';
import type { DataPage } from '../models/DataPage';
import type { MessageAPIResponse } from '../models/MessageAPIResponse';
import type { MessageAPISendV3 } from '../models/MessageAPISendV3';
import type { MessageUpdateAdminFeedbackAPI } from '../models/MessageUpdateAdminFeedbackAPI';
import type { MessageUpdateFeedbackAPI } from '../models/MessageUpdateFeedbackAPI';
import type { ReceivingAgentFilter } from '../models/ReceivingAgentFilter';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MessagesService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Send Message
   * Send a message.
   *
   * This endpoint returns a server sent event stream composed of `MessageAPIResponse` objects.
   * The stream will close when the model is done generating responses and is ready for more
   * input.
   *
   * If a chat_id is provided, the message will be sent to that chat. A chat will be created and
   * the MessageAPIResponse will contain the new chat_id. If the chat does not have a name, then
   * a name will be generated asynchronously. It should be available within a few seconds.
   *
   * If the message_text is empty, then the message will be ignored. If the chat is currently
   * busy, then this will trigger a 409 CONFLICT response. This can be useful to reattach to a
   * chat that is currently running.
   *
   * Note that messages can only be sent by the owner of the chat or by an admin in certain cases.
   * @param accountName The name of the organization or account.
   * @param requestBody
   * @param sessionId
   * @returns MessageAPIResponse Message sent successfully. Returns an event stream of `MessageAPIResponse` objects.
   * @throws ApiError
   */
  public sendMessage(
    accountName: string,
    requestBody: MessageAPISendV3,
    sessionId?: (string | null),
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `The chat or message could not be found.`,
        409: `Chat is busy. Returns an event stream of the running chat.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Message Promptlog
   * Get chat message promptlogs.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns ChatMessagePromptlogInDB Successful Response
   * @throws ApiError
   */
  public getMessagePromptlog(
    accountName: string,
    messageId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<ChatMessagePromptlogInDB>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/promptlogs',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Refresh Query Message
   * Refresh the SQL table in a chat message.
   *
   * This will return an error if the message does not contain a table ID.
   * Note that you will need to refetch the data from the data endpoint to see the updates.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns MessageAPIResponse The table associated with the message was refreshed successfully.
   * @throws ApiError
   */
  public refreshQueryMessage(
    accountName: string,
    messageId: string,
    sessionId?: (string | null),
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/refresh',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Message Feedback (Experimental 🧪)
   * Update the thumbs up / down user-provided feedback on a chat message.
   *
   * Feedback for the system response to a user question is associated with the
   * user question, instead of the response messages. Only user messages can
   * receive feedback at this time.
   *
   * Only the chat creator can provide feedback on messages within the chat.
   *
   * WARNING: This endpoint is experimental and its behavior may change without warning.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param requestBody
   * @param sessionId
   * @returns MessageAPIResponse Successful Response
   * @throws ApiError
   */
  public updateMessageFeedback(
    accountName: string,
    messageId: string,
    requestBody: MessageUpdateFeedbackAPI,
    sessionId?: (string | null),
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/feedback',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Message Admin Feedback (Experimental 🧪)
   * Update the thumbs up / down admin-provided feedback on a chat message.
   *
   * Admin feedback for the system response to a user question is associated with the
   * user question, instead of the response messages. Only admin messages can
   * receive feedback at this time.
   *
   * Any admin can provide admin feedback on messages within the chat. Note that admins
   * can only provide feedback on their own chats or other chats that already have admin
   * feedback.
   *
   * WARNING: This endpoint is experimental and its behavior may change without warning.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param requestBody
   * @param sessionId
   * @returns MessageAPIResponse Successful Response
   * @throws ApiError
   */
  public updateMessageAdminFeedback(
    accountName: string,
    messageId: string,
    requestBody: MessageUpdateAdminFeedbackAPI,
    sessionId?: (string | null),
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/admin_feedback',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Admin Feedback
   * Get saved admin feedback in an account.
   *
   * Returns the feedback messages that have positive admin feedback. This is used to populate
   * the "Admin feedback" tab. We show the rephrased user question for the feedback messages where
   * the rephrased user question is the rephrased thumbs down user message if available or the
   * rephrased user question of the postive admin feedback message if no thumbs down user message.
   * @param accountName The name of the organization or account.
   * @param limit
   * @param offset
   * @param beforeTimestamp
   * @param afterTimestamp
   * @param isAdminFeedbackIncorporated
   * @param search
   * @param sessionId
   * @param requestBody
   * @returns AdminMessageAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getAdminFeedback(
    accountName: string,
    limit: number = 20,
    offset?: number,
    beforeTimestamp?: (string | null),
    afterTimestamp?: (string | null),
    isAdminFeedbackIncorporated?: (boolean | null),
    search?: (string | null),
    sessionId?: (string | null),
    requestBody?: Body_get_admin_feedback_api_v3_orgs__account_name__messages_admin_feedback_post,
  ): CancelablePromise<AdminMessageAPIResponsePage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/admin_feedback',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'offset': offset,
        'before_timestamp': beforeTimestamp,
        'after_timestamp': afterTimestamp,
        'is_admin_feedback_incorporated': isAdminFeedbackIncorporated,
        'search': search,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get User Feedback
   * Get user feedback in an account.
   * @param accountName The name of the organization or account.
   * @param limit
   * @param offset
   * @param beforeTimestamp
   * @param afterTimestamp
   * @param receivingAgentFilter
   * @param sessionId
   * @param requestBody
   * @returns AdminMessageAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getUserFeedback(
    accountName: string,
    limit: number = 20,
    offset?: number,
    beforeTimestamp?: (string | null),
    afterTimestamp?: (string | null),
    receivingAgentFilter?: ReceivingAgentFilter,
    sessionId?: (string | null),
    requestBody?: Body_get_user_feedback_api_v3_orgs__account_name__messages_user_feedback_post,
  ): CancelablePromise<AdminMessageAPIResponsePage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/user_feedback',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'offset': offset,
        'before_timestamp': beforeTimestamp,
        'after_timestamp': afterTimestamp,
        'receiving_agent_filter': receivingAgentFilter,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Message Table Data
   * Get the table data associated with a message.
   *
   * This will return an error if the message does not contain a table ID.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param limit The maximum number of rows to include in the response.
   * @param skip The number of rows to skip. Equivalent of 'skip' in database queries.
   * @param sort The columns to sort by. Prefix with '-' for descending order.
   * @param format If true, format the table data according to display type.
   * @param sessionId
   * @returns DataPage Successful Response
   * @throws ApiError
   */
  public readMessageTableData(
    accountName: string,
    messageId: string,
    limit: number = 100,
    skip?: number,
    sort?: (Array<string> | null),
    format: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<DataPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
        'sort': sort,
        'format': format,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        404: `The item could not be found.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Message Table Data As Csv
   * Read message table data as a CSV.
   *
   * This will return an error if the message does not contain a table ID.
   * @param accountName
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns any The table data as a CSV file.
   * @throws ApiError
   */
  public readMessageTableDataAsCsv(
    accountName: string,
    messageId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data/csv',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        404: `The item could not be found.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Admin Feedback Clone
   * Clone a user chat for admin to provide admin feedback.
   *
   * Args:
   * message: The user message that has user feedback. The copy will conver messages from the
   * first one to the response of the response fo this user message.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public adminFeedbackClone(
    accountName: string,
    messageId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/admin_feedback_clone',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
