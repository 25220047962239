// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCopyDataset.ts"
);
import.meta.hot.lastModified = "1737759758412.0356";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CopyDatasetMutationRequest,
  CopyDatasetMutationResponse,
  CopyDatasetPathParams,
  CopyDatasetQueryParams,
  CopyDataset422,
} from '../types/CopyDataset'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const copyDatasetMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/datasets/{star_schema_data_source_id}/copy' }] as const

export type CopyDatasetMutationKey = ReturnType<typeof copyDatasetMutationKey>

/**
 * @description Copy dataset.[TODO]: remove this endpoint and only use enterprise copyThis endpoint lets users create a copy of a dataset in another account. You can specify thedestination account name (dest_account_name). The users need to be the admin for both accounts.You can copy to a different account you manage, or even the same account. When copying, you havethe option to update the details, including the SSDS name, connection, and data sourcedefinition (data source id in the data source definition must be retained).Example:Given the original SSDS definition:```tables_and_views = [    StarSchemaDataSourceTableOrViewInfo(        data_source_id="592a2851-f849-4dab-a4ca-614e8709dc8d",        name="MAKANA_WAITTIME_1",        db="CI_FOR_PYTEST",        db_schema="PYTEST",        table="MAKANA_WAITTIME_1",    )]new_account_name = "new_account"new_connection_id = uuid.uuid4()new_ssds_name = "MAKANA_WAITTIMES (pytest) - copy from another account"r = client.post(    f"{settings.API_V1_STR}/accounts/{account.name}/datasets/{ssds_id}/copy",    headers=user_header,    params={        "dest_account_name": new_account_name,        "dest_connection_id": new_connection_id,        "dest_ssds_name": new_ssds_name,    },    json=jsonable_encoder(tables_and_views),)
 * @summary Copy Dataset
 * {@link /api/v1/accounts/:account_name/datasets/:star_schema_data_source_id/copy}
 */
async function copyDataset(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: CopyDatasetPathParams['account_name']
    starSchemaDataSourceId: CopyDatasetPathParams['star_schema_data_source_id']
    data?: CopyDatasetMutationRequest
    params?: CopyDatasetQueryParams
  },
  config: Partial<RequestConfig<CopyDatasetMutationRequest>> = {},
) {
  const res = await client<CopyDatasetMutationResponse, CopyDataset422, CopyDatasetMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/datasets/${starSchemaDataSourceId}/copy`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Copy dataset.[TODO]: remove this endpoint and only use enterprise copyThis endpoint lets users create a copy of a dataset in another account. You can specify thedestination account name (dest_account_name). The users need to be the admin for both accounts.You can copy to a different account you manage, or even the same account. When copying, you havethe option to update the details, including the SSDS name, connection, and data sourcedefinition (data source id in the data source definition must be retained).Example:Given the original SSDS definition:```tables_and_views = [    StarSchemaDataSourceTableOrViewInfo(        data_source_id="592a2851-f849-4dab-a4ca-614e8709dc8d",        name="MAKANA_WAITTIME_1",        db="CI_FOR_PYTEST",        db_schema="PYTEST",        table="MAKANA_WAITTIME_1",    )]new_account_name = "new_account"new_connection_id = uuid.uuid4()new_ssds_name = "MAKANA_WAITTIMES (pytest) - copy from another account"r = client.post(    f"{settings.API_V1_STR}/accounts/{account.name}/datasets/{ssds_id}/copy",    headers=user_header,    params={        "dest_account_name": new_account_name,        "dest_connection_id": new_connection_id,        "dest_ssds_name": new_ssds_name,    },    json=jsonable_encoder(tables_and_views),)
 * @summary Copy Dataset
 * {@link /api/v1/accounts/:account_name/datasets/:star_schema_data_source_id/copy}
 */
export function useCopyDataset(
  options: {
    mutation?: UseMutationOptions<
      CopyDatasetMutationResponse,
      CopyDataset422,
      {
        accountName: CopyDatasetPathParams['account_name']
        starSchemaDataSourceId: CopyDatasetPathParams['star_schema_data_source_id']
        data?: CopyDatasetMutationRequest
        params?: CopyDatasetQueryParams
      }
    >
    client?: Partial<RequestConfig<CopyDatasetMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? copyDatasetMutationKey()

  return useMutation<
    CopyDatasetMutationResponse,
    CopyDataset422,
    {
      accountName: CopyDatasetPathParams['account_name']
      starSchemaDataSourceId: CopyDatasetPathParams['star_schema_data_source_id']
      data?: CopyDatasetMutationRequest
      params?: CopyDatasetQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data, params }) => {
      return copyDataset({ accountName, starSchemaDataSourceId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}