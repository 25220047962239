// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateRemappedSsds.ts"
);
import.meta.hot.lastModified = "1737935767653.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateRemappedSsdsMutationRequest,
  CreateRemappedSsdsMutationResponse,
  CreateRemappedSsdsPathParams,
  CreateRemappedSsdsQueryParams,
  CreateRemappedSsds422,
} from '../types/CreateRemappedSsds'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createRemappedSsdsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/remap_ssds' }] as const

export type CreateRemappedSsdsMutationKey = ReturnType<typeof createRemappedSsdsMutationKey>

/**
 * @description Upload SSDS created with remapping from SQL CSV.
 * @summary Create Remapped Ssds
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/remap_ssds}
 */
async function createRemappedSsds(
  {
    accountName,
    data,
    params,
  }: { accountName: CreateRemappedSsdsPathParams['account_name']; data: CreateRemappedSsdsMutationRequest; params: CreateRemappedSsdsQueryParams },
  config: Partial<RequestConfig<CreateRemappedSsdsMutationRequest>> = {},
) {
  const res = await client<CreateRemappedSsdsMutationResponse, CreateRemappedSsds422, CreateRemappedSsdsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/remap_ssds`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Upload SSDS created with remapping from SQL CSV.
 * @summary Create Remapped Ssds
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/remap_ssds}
 */
export function useCreateRemappedSsds(
  options: {
    mutation?: UseMutationOptions<
      CreateRemappedSsdsMutationResponse,
      CreateRemappedSsds422,
      { accountName: CreateRemappedSsdsPathParams['account_name']; data: CreateRemappedSsdsMutationRequest; params: CreateRemappedSsdsQueryParams }
    >
    client?: Partial<RequestConfig<CreateRemappedSsdsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createRemappedSsdsMutationKey()

  return useMutation<
    CreateRemappedSsdsMutationResponse,
    CreateRemappedSsds422,
    { accountName: CreateRemappedSsdsPathParams['account_name']; data: CreateRemappedSsdsMutationRequest; params: CreateRemappedSsdsQueryParams }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return createRemappedSsds({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}