// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteWidget.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteWidgetMutationResponse, DeleteWidgetPathParams, DeleteWidget422 } from '../types/DeleteWidget'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteWidgetMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/widgets/{widget_id}' }] as const

export type DeleteWidgetMutationKey = ReturnType<typeof deleteWidgetMutationKey>

/**
 * @description Delete widget by ID.
 * @summary Delete Widget
 * {@link /api/v3/orgs/:account_name/widgets/:widget_id}
 */
async function deleteWidget(
  { accountName, widgetId }: { accountName: DeleteWidgetPathParams['account_name']; widgetId: DeleteWidgetPathParams['widget_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteWidgetMutationResponse, DeleteWidget422, unknown>({
    method: 'DELETE',
    url: `/api/v3/orgs/${accountName}/widgets/${widgetId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete widget by ID.
 * @summary Delete Widget
 * {@link /api/v3/orgs/:account_name/widgets/:widget_id}
 */
export function useDeleteWidget(
  options: {
    mutation?: UseMutationOptions<
      DeleteWidgetMutationResponse,
      DeleteWidget422,
      { accountName: DeleteWidgetPathParams['account_name']; widgetId: DeleteWidgetPathParams['widget_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteWidgetMutationKey()

  return useMutation<
    DeleteWidgetMutationResponse,
    DeleteWidget422,
    { accountName: DeleteWidgetPathParams['account_name']; widgetId: DeleteWidgetPathParams['widget_id'] }
  >({
    mutationFn: async ({ accountName, widgetId }) => {
      return deleteWidget({ accountName, widgetId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}