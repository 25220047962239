// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useStarConversation.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { StarConversationMutationResponse, StarConversationPathParams, StarConversation422 } from '../types/StarConversation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const starConversationMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/stars' }] as const

export type StarConversationMutationKey = ReturnType<typeof starConversationMutationKey>

/**
 * @description Star a bumblebee conversation.
 * @summary Star Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/stars}
 */
async function starConversation(
  { accountName, conversationId }: { accountName: StarConversationPathParams['account_name']; conversationId: StarConversationPathParams['conversation_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<StarConversationMutationResponse, StarConversation422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/stars`,
    ...config,
  })
  return res.data
}

/**
 * @description Star a bumblebee conversation.
 * @summary Star Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/stars}
 */
export function useStarConversation(
  options: {
    mutation?: UseMutationOptions<
      StarConversationMutationResponse,
      StarConversation422,
      { accountName: StarConversationPathParams['account_name']; conversationId: StarConversationPathParams['conversation_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? starConversationMutationKey()

  return useMutation<
    StarConversationMutationResponse,
    StarConversation422,
    { accountName: StarConversationPathParams['account_name']; conversationId: StarConversationPathParams['conversation_id'] }
  >({
    mutationFn: async ({ accountName, conversationId }) => {
      return starConversation({ accountName, conversationId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}