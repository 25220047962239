// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/SenderType.ts"
);
import.meta.hot.lastModified = "1737759757708.0393";
}
// REMIX HMR END

export enum SenderType {
  'USER' = 'USER',
  'AI' = 'AI',
}