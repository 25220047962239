// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRmEnterpriseAdmins.ts"
);
import.meta.hot.lastModified = "1737935767642.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RmEnterpriseAdminsMutationRequest,
  RmEnterpriseAdminsMutationResponse,
  RmEnterpriseAdminsPathParams,
  RmEnterpriseAdmins422,
} from '../types/RmEnterpriseAdmins'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const rmEnterpriseAdminsMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/admins' }] as const

export type RmEnterpriseAdminsMutationKey = ReturnType<typeof rmEnterpriseAdminsMutationKey>

/**
 * @description Remove admin privileges from the specified users.This does not propogate. That is, users remain in the accounts in the enterprise.Returns: a list of the IDs which were invalid.
 * @summary Rm Enterprise Admins
 * {@link /api/v1/enterprises/:enterprise_name/admins}
 */
async function rmEnterpriseAdmins(
  { enterpriseName, data }: { enterpriseName: RmEnterpriseAdminsPathParams['enterprise_name']; data?: RmEnterpriseAdminsMutationRequest },
  config: Partial<RequestConfig<RmEnterpriseAdminsMutationRequest>> = {},
) {
  const res = await client<RmEnterpriseAdminsMutationResponse, RmEnterpriseAdmins422, RmEnterpriseAdminsMutationRequest>({
    method: 'DELETE',
    url: `/api/v1/enterprises/${enterpriseName}/admins`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Remove admin privileges from the specified users.This does not propogate. That is, users remain in the accounts in the enterprise.Returns: a list of the IDs which were invalid.
 * @summary Rm Enterprise Admins
 * {@link /api/v1/enterprises/:enterprise_name/admins}
 */
export function useRmEnterpriseAdmins(
  options: {
    mutation?: UseMutationOptions<
      RmEnterpriseAdminsMutationResponse,
      RmEnterpriseAdmins422,
      { enterpriseName: RmEnterpriseAdminsPathParams['enterprise_name']; data?: RmEnterpriseAdminsMutationRequest }
    >
    client?: Partial<RequestConfig<RmEnterpriseAdminsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? rmEnterpriseAdminsMutationKey()

  return useMutation<
    RmEnterpriseAdminsMutationResponse,
    RmEnterpriseAdmins422,
    { enterpriseName: RmEnterpriseAdminsPathParams['enterprise_name']; data?: RmEnterpriseAdminsMutationRequest }
  >({
    mutationFn: async ({ enterpriseName, data }) => {
      return rmEnterpriseAdmins({ enterpriseName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}