// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Portal.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Portal.tsx");
  import.meta.hot.lastModified = "1731972305325.2817";
}
// REMIX HMR END

import { createPortal } from 'react-dom';
import { ClientOnly } from '~/utils/ClientOnly';

// We're not using radix-ui's version of Portal because it
// injects a div into the DOM.  This causes an error when
// using it in root.tsx where we're adding the `<Head />`
// component with a Portal, since a div isn't a valid child of <head />
export function Portal({
  children,
  container
}) {
  return createPortal(children, container);
}
_c = Portal;
/**
 * Use ClientPortal when you want to use react's createPortal
 * with an element which isn't safe to use with SSR
 * like document.body or document.head, for example
 */
export function ClientPortal({
  getContainer,
  ...props
}) {
  return <ClientOnly>
      {() => <Portal container={getContainer()} {...props} />}
    </ClientOnly>;
}
_c2 = ClientPortal;
var _c, _c2;
$RefreshReg$(_c, "Portal");
$RefreshReg$(_c2, "ClientPortal");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;