// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/rmAccountsFromEnterpriseSchema.ts"
);
import.meta.hot.lastModified = "1737759761849.0178";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const rmAccountsFromEnterprisePathParamsSchema = z.object({
  enterprise_name: z.string(),
})

/**
 * @description Successful Response
 */
export const rmAccountsFromEnterprise200Schema = z.array(z.string())

/**
 * @description Validation Error
 */
export const rmAccountsFromEnterprise422Schema = z.lazy(() => httpValidationErrorSchema)

export const rmAccountsFromEnterpriseMutationRequestSchema = z.array(z.string())

export const rmAccountsFromEnterpriseMutationResponseSchema = z.lazy(() => rmAccountsFromEnterprise200Schema)