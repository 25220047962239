// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/metricPairToReviewSchema.ts"
);
import.meta.hot.lastModified = "1737759760684.024";
}
// REMIX HMR END

// @ts-nocheck
import { conflictStatusSchema } from './conflictStatusSchema'
import { metricDetailSchema } from './metricDetailSchema'
import { z } from 'zod'

/**
 * @description Properties to return to client.
 */
export const metricPairToReviewSchema = z
  .object({
    update_job_id_for_metrics: z.union([z.string(), z.null()]).optional(),
    update_job_id_for_non_verified_metrics: z.union([z.string(), z.null()]).optional(),
    src_id: z.string(),
    dst_id: z.string(),
    status: z.lazy(() => conflictStatusSchema),
    id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    src: z.lazy(() => metricDetailSchema),
    dst: z.lazy(() => metricDetailSchema),
  })
  .describe('Properties to return to client.')