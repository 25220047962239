// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createRedshiftConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759761849.0178";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { redshiftConnectionCreateSchema } from './redshiftConnectionCreateSchema'
import { redshiftConnectionSchema } from './redshiftConnectionSchema'
import { z } from 'zod'

export const createRedshiftConnectionPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const createRedshiftConnection201Schema = z.lazy(() => redshiftConnectionSchema)

/**
 * @description Validation Error
 */
export const createRedshiftConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const createRedshiftConnectionMutationRequestSchema = z.lazy(() => redshiftConnectionCreateSchema)

export const createRedshiftConnectionMutationResponseSchema = z.lazy(() => createRedshiftConnection201Schema)