// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useAddNewAccountToEnterprise.ts"
);
import.meta.hot.lastModified = "1737759761858.0178";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  AddNewAccountToEnterpriseMutationRequest,
  AddNewAccountToEnterpriseMutationResponse,
  AddNewAccountToEnterprisePathParams,
  AddNewAccountToEnterpriseQueryParams,
  AddNewAccountToEnterprise422,
} from '../types/AddNewAccountToEnterprise'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const addNewAccountToEnterpriseMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/accounts' }] as const

export type AddNewAccountToEnterpriseMutationKey = ReturnType<typeof addNewAccountToEnterpriseMutationKey>

/**
 * @description Create a new account in the enterprise.
 * @summary Add New Account To Enterprise
 * {@link /api/v1/enterprises/:enterprise_name/accounts}
 */
async function addNewAccountToEnterprise(
  {
    enterpriseName,
    data,
    params,
  }: {
    enterpriseName: AddNewAccountToEnterprisePathParams['enterprise_name']
    data: AddNewAccountToEnterpriseMutationRequest
    params?: AddNewAccountToEnterpriseQueryParams
  },
  config: Partial<RequestConfig<AddNewAccountToEnterpriseMutationRequest>> = {},
) {
  const res = await client<AddNewAccountToEnterpriseMutationResponse, AddNewAccountToEnterprise422, AddNewAccountToEnterpriseMutationRequest>({
    method: 'POST',
    url: `/api/v1/enterprises/${enterpriseName}/accounts`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a new account in the enterprise.
 * @summary Add New Account To Enterprise
 * {@link /api/v1/enterprises/:enterprise_name/accounts}
 */
export function useAddNewAccountToEnterprise(
  options: {
    mutation?: UseMutationOptions<
      AddNewAccountToEnterpriseMutationResponse,
      AddNewAccountToEnterprise422,
      {
        enterpriseName: AddNewAccountToEnterprisePathParams['enterprise_name']
        data: AddNewAccountToEnterpriseMutationRequest
        params?: AddNewAccountToEnterpriseQueryParams
      }
    >
    client?: Partial<RequestConfig<AddNewAccountToEnterpriseMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? addNewAccountToEnterpriseMutationKey()

  return useMutation<
    AddNewAccountToEnterpriseMutationResponse,
    AddNewAccountToEnterprise422,
    {
      enterpriseName: AddNewAccountToEnterprisePathParams['enterprise_name']
      data: AddNewAccountToEnterpriseMutationRequest
      params?: AddNewAccountToEnterpriseQueryParams
    }
  >({
    mutationFn: async ({ enterpriseName, data, params }) => {
      return addNewAccountToEnterprise({ enterpriseName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}