// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useValidateSnowflakeConnection.ts"
);
import.meta.hot.lastModified = "1737759762069.0168";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ValidateSnowflakeConnectionMutationResponse,
  ValidateSnowflakeConnectionPathParams,
  ValidateSnowflakeConnection422,
} from '../types/ValidateSnowflakeConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const validateSnowflakeConnectionMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/validate' }] as const

export type ValidateSnowflakeConnectionMutationKey = ReturnType<typeof validateSnowflakeConnectionMutationKey>

/**
 * @description Validate Snowflake connection.
 * @summary Validate Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/validate}
 */
async function validateSnowflakeConnection(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ValidateSnowflakeConnectionPathParams['account_name']
    snowflakeConnectionId: ValidateSnowflakeConnectionPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ValidateSnowflakeConnectionMutationResponse, ValidateSnowflakeConnection422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/${snowflakeConnectionId}/validate`,
    ...config,
  })
  return res.data
}

/**
 * @description Validate Snowflake connection.
 * @summary Validate Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/validate}
 */
export function useValidateSnowflakeConnection(
  options: {
    mutation?: UseMutationOptions<
      ValidateSnowflakeConnectionMutationResponse,
      ValidateSnowflakeConnection422,
      {
        accountName: ValidateSnowflakeConnectionPathParams['account_name']
        snowflakeConnectionId: ValidateSnowflakeConnectionPathParams['snowflake_connection_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? validateSnowflakeConnectionMutationKey()

  return useMutation<
    ValidateSnowflakeConnectionMutationResponse,
    ValidateSnowflakeConnection422,
    {
      accountName: ValidateSnowflakeConnectionPathParams['account_name']
      snowflakeConnectionId: ValidateSnowflakeConnectionPathParams['snowflake_connection_id']
    }
  >({
    mutationFn: async ({ accountName, snowflakeConnectionId }) => {
      return validateSnowflakeConnection({ accountName, snowflakeConnectionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}