// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ZenDimension.ts"
);
import.meta.hot.lastModified = "1737935765108.7612";
}
// REMIX HMR END

import type { Dialect } from './Dialect'
import type { DisplayType } from './DisplayType'
import type { FilterCategory } from './FilterCategory'
import type { OriginType } from './OriginType'
import type { PublishState } from './PublishState'
import type { ZenFieldFilter } from './ZenFieldFilter'

export enum ZenDimensionFieldType {
  'dimension' = 'dimension',
}

/**
 * @description Metrics layer dimension.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/dimension
 */
export type ZenDimension = {
  id?: string | null
  sync_id?: string | null
  group_id?: string | null
  /**
   * @type string
   */
  name: string
  /**
   * @default "dimension"
   * @type string | undefined
   */
  field_type?: ZenDimensionFieldType
  description?: string | null
  /**
   * @default false
   * @type boolean | undefined
   */
  primary_key?: boolean
  /**
   * @type string
   */
  sql: string
  ns_expr?: string | null
  filters?: ZenFieldFilter[] | null
  top_values?: string[] | null
  /**
   * @default "Verified"
   */
  state?: PublishState
  /**
   * @default "unknown"
   */
  expr_type?: FilterCategory
  display_name?: string | null
  data_source_id?: string | null
  /**
   * @default false
   * @type boolean | undefined
   */
  is_sensitive?: boolean
  /**
   * @default false
   * @type boolean | undefined
   */
  is_literal_sensitive?: boolean
  /**
   * @type array | undefined
   */
  origin?: OriginType[]
  /**
   * @default "unknown"
   */
  dialect?: Dialect
  /**
   * @default "unknown"
   */
  display_type?: DisplayType
  /**
   * @default 1
   * @type number | undefined
   */
  popularity?: number
  updated_at?: string | null
  created_at?: string | null
}