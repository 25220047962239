// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatStepSchema.ts"
);
import.meta.hot.lastModified = "1737759757944.038";
}
// REMIX HMR END

// @ts-nocheck
import { semanticQuerySchema } from './semanticQuerySchema'
import { z } from 'zod'

/**
 * @description Chat Step.
 */
export const chatStepSchema = z
  .object({
    question: z.string(),
    explanation: z.union([z.string(), z.null()]).optional(),
    query: z.union([z.lazy(() => semanticQuerySchema), z.null()]).optional(),
  })
  .describe('Chat Step.')