// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSuggestionsForAccount.ts"
);
import.meta.hot.lastModified = "1737935768812.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetSuggestionsForAccountQueryResponse,
  GetSuggestionsForAccountPathParams,
  GetSuggestionsForAccountQueryParams,
  GetSuggestionsForAccount422,
} from '../types/GetSuggestionsForAccount'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSuggestionsForAccountQueryKey = (
  { accountName }: { accountName: GetSuggestionsForAccountPathParams['account_name'] },
  params?: GetSuggestionsForAccountQueryParams,
) => [{ url: '/api/v3/orgs/:account_name/data_assets/suggestions', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type GetSuggestionsForAccountQueryKey = ReturnType<typeof getSuggestionsForAccountQueryKey>

/**
 * @description Get suggested prompts for an account across all datasets in the account.
 * @summary Get Suggestions For Account
 * {@link /api/v3/orgs/:account_name/data_assets/suggestions}
 */
async function getSuggestionsForAccount(
  { accountName, params }: { accountName: GetSuggestionsForAccountPathParams['account_name']; params?: GetSuggestionsForAccountQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSuggestionsForAccountQueryResponse, GetSuggestionsForAccount422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/data_assets/suggestions`,
    params,
    ...config,
  })
  return res.data
}

export function getSuggestionsForAccountQueryOptions(
  { accountName, params }: { accountName: GetSuggestionsForAccountPathParams['account_name']; params?: GetSuggestionsForAccountQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSuggestionsForAccountQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSuggestionsForAccount({ accountName, params }, config)
    },
  })
}

/**
 * @description Get suggested prompts for an account across all datasets in the account.
 * @summary Get Suggestions For Account
 * {@link /api/v3/orgs/:account_name/data_assets/suggestions}
 */
export function useGetSuggestionsForAccount<
  TData = GetSuggestionsForAccountQueryResponse,
  TQueryData = GetSuggestionsForAccountQueryResponse,
  TQueryKey extends QueryKey = GetSuggestionsForAccountQueryKey,
>(
  { accountName, params }: { accountName: GetSuggestionsForAccountPathParams['account_name']; params?: GetSuggestionsForAccountQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetSuggestionsForAccountQueryResponse, GetSuggestionsForAccount422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSuggestionsForAccountQueryKey({ accountName }, params)

  const query = useQuery({
    ...(getSuggestionsForAccountQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSuggestionsForAccount422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}