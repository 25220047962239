// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getSsdsIdentifiersSchema.ts"
);
import.meta.hot.lastModified = "1737935767878.746";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { identifierSchema } from './identifierSchema'
import { z } from 'zod'

export const getSsdsIdentifiersPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const getSsdsIdentifiersQueryParamsSchema = z
  .object({
    data_source_ids: z.union([z.array(z.string()), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getSsdsIdentifiers200Schema = z.array(z.lazy(() => identifierSchema))

/**
 * @description Validation Error
 */
export const getSsdsIdentifiers422Schema = z.lazy(() => httpValidationErrorSchema)

export const getSsdsIdentifiersQueryResponseSchema = z.lazy(() => getSsdsIdentifiers200Schema)