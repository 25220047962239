// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRetrieveTextSearch.ts"
);
import.meta.hot.lastModified = "1737759760463.0251";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RetrieveTextSearchQueryResponse,
  RetrieveTextSearchPathParams,
  RetrieveTextSearchQueryParams,
  RetrieveTextSearch422,
} from '../types/RetrieveTextSearch'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const retrieveTextSearchQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: RetrieveTextSearchPathParams['account_name']; starSchemaDataSourceId: RetrieveTextSearchPathParams['star_schema_data_source_id'] },
  params: RetrieveTextSearchQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/retrieve/text',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type RetrieveTextSearchQueryKey = ReturnType<typeof retrieveTextSearchQueryKey>

/**
 * @description Retrieve sample values using text search.
 * @summary Retrieve Text Search
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/retrieve/text}
 */
async function retrieveTextSearch(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: RetrieveTextSearchPathParams['account_name']
    starSchemaDataSourceId: RetrieveTextSearchPathParams['star_schema_data_source_id']
    params: RetrieveTextSearchQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RetrieveTextSearchQueryResponse, RetrieveTextSearch422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/retrieve/text`,
    params,
    ...config,
  })
  return res.data
}

export function retrieveTextSearchQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: RetrieveTextSearchPathParams['account_name']
    starSchemaDataSourceId: RetrieveTextSearchPathParams['star_schema_data_source_id']
    params: RetrieveTextSearchQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = retrieveTextSearchQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return retrieveTextSearch({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Retrieve sample values using text search.
 * @summary Retrieve Text Search
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/retrieve/text}
 */
export function useRetrieveTextSearch<
  TData = RetrieveTextSearchQueryResponse,
  TQueryData = RetrieveTextSearchQueryResponse,
  TQueryKey extends QueryKey = RetrieveTextSearchQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: RetrieveTextSearchPathParams['account_name']
    starSchemaDataSourceId: RetrieveTextSearchPathParams['star_schema_data_source_id']
    params: RetrieveTextSearchQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<RetrieveTextSearchQueryResponse, RetrieveTextSearch422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? retrieveTextSearchQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(retrieveTextSearchQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, RetrieveTextSearch422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}