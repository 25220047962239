// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useColdStartFromTables.ts"
);
import.meta.hot.lastModified = "1737759760702.024";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ColdStartFromTablesMutationRequest,
  ColdStartFromTablesMutationResponse,
  ColdStartFromTablesPathParams,
  ColdStartFromTablesQueryParams,
  ColdStartFromTables422,
} from '../types/ColdStartFromTables'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const coldStartFromTablesMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/cold_start_from_tables' }] as const

export type ColdStartFromTablesMutationKey = ReturnType<typeof coldStartFromTablesMutationKey>

/**
 * @description Cold start from table.
 * @summary Cold Start From Tables
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/cold_start_from_tables}
 */
async function coldStartFromTables(
  {
    accountName,
    data,
    params,
  }: { accountName: ColdStartFromTablesPathParams['account_name']; data: ColdStartFromTablesMutationRequest; params: ColdStartFromTablesQueryParams },
  config: Partial<RequestConfig<ColdStartFromTablesMutationRequest>> = {},
) {
  const res = await client<ColdStartFromTablesMutationResponse, ColdStartFromTables422, ColdStartFromTablesMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/cold_start_from_tables`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Cold start from table.
 * @summary Cold Start From Tables
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/cold_start_from_tables}
 */
export function useColdStartFromTables(
  options: {
    mutation?: UseMutationOptions<
      ColdStartFromTablesMutationResponse,
      ColdStartFromTables422,
      { accountName: ColdStartFromTablesPathParams['account_name']; data: ColdStartFromTablesMutationRequest; params: ColdStartFromTablesQueryParams }
    >
    client?: Partial<RequestConfig<ColdStartFromTablesMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? coldStartFromTablesMutationKey()

  return useMutation<
    ColdStartFromTablesMutationResponse,
    ColdStartFromTables422,
    { accountName: ColdStartFromTablesPathParams['account_name']; data: ColdStartFromTablesMutationRequest; params: ColdStartFromTablesQueryParams }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return coldStartFromTables({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}