// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateApiKey.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateApiKeyMutationRequest, CreateApiKeyMutationResponse, CreateApiKeyPathParams, CreateApiKey422 } from '../types/CreateApiKey'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createApiKeyMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/api_key' }] as const

export type CreateApiKeyMutationKey = ReturnType<typeof createApiKeyMutationKey>

/**
 * @description Create a new API key.
 * @summary Create Api Key
 * {@link /api/v1/accounts/:account_name/api_key}
 */
async function createApiKey(
  { accountName, data }: { accountName: CreateApiKeyPathParams['account_name']; data?: CreateApiKeyMutationRequest },
  config: Partial<RequestConfig<CreateApiKeyMutationRequest>> = {},
) {
  const res = await client<CreateApiKeyMutationResponse, CreateApiKey422, CreateApiKeyMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/api_key`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a new API key.
 * @summary Create Api Key
 * {@link /api/v1/accounts/:account_name/api_key}
 */
export function useCreateApiKey(
  options: {
    mutation?: UseMutationOptions<
      CreateApiKeyMutationResponse,
      CreateApiKey422,
      { accountName: CreateApiKeyPathParams['account_name']; data?: CreateApiKeyMutationRequest }
    >
    client?: Partial<RequestConfig<CreateApiKeyMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createApiKeyMutationKey()

  return useMutation<
    CreateApiKeyMutationResponse,
    CreateApiKey422,
    { accountName: CreateApiKeyPathParams['account_name']; data?: CreateApiKeyMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return createApiKey({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}