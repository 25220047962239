// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/removeBadResponseSchema.ts"
);
import.meta.hot.lastModified = "1737759760436.0251";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellSchema } from './bumblebeeCellSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const removeBadResponsePathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

/**
 * @description Successful Response
 */
export const removeBadResponse200Schema = z.lazy(() => bumblebeeCellSchema)

/**
 * @description Validation Error
 */
export const removeBadResponse422Schema = z.lazy(() => httpValidationErrorSchema)

export const removeBadResponseMutationResponseSchema = z.lazy(() => removeBadResponse200Schema)