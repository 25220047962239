// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/joinGroupSchema.ts"
);
import.meta.hot.lastModified = "1737759757953.038";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Join Group class.\n\nthis class defines a group of disjoint tables can be bridged by multiple tables.\n\nFor example, we have 3 tables A, B, C, and we have 2 bridge tables D, E. this means\nwe can have the following two joins:\nJoin 1:\nD Left Join A  on ... Left Join B on ... Left Join C on ...\nJoin 2:\nE Left Join A  on ... Left Join B on ... Left Join C on ...
 */
export const joinGroupSchema = z
  .object({
    primary_tables: z.array(z.string()),
    bridge_tables: z.array(z.string()),
  })
  .describe(
    'Join Group class.\n\nthis class defines a group of disjoint tables can be bridged by multiple tables.\n\nFor example, we have 3 tables A, B, C, and we have 2 bridge tables D, E. this means\nwe can have the following two joins:\nJoin 1:\nD Left Join A  on ... Left Join B on ... Left Join C on ...\nJoin 2:\nE Left Join A  on ... Left Join B on ... Left Join C on ...',
  )