// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyMergeMetricsApiV1AccountsAccountNameStarSchemaDataSourceMetricsMetricIdMergePatchSchema.ts"
);
import.meta.hot.lastModified = "1737759762609.014";
}
// REMIX HMR END

// @ts-nocheck
import { metricUpdateSchema } from './metricUpdateSchema'
import { z } from 'zod'

export const bodyMergeMetricsApiV1AccountsAccountNameStarSchemaDataSourceMetricsMetricIdMergePatchSchema = z.object({
  metric_update: z.union([z.lazy(() => metricUpdateSchema), z.null()]),
  ids_to_merge: z.array(z.string()),
})