// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteDatabricksConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759762061.0168";
}
// REMIX HMR END

// @ts-nocheck
import { databricksConnectionSchema } from './databricksConnectionSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const deleteDatabricksConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  databricks_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const deleteDatabricksConnection200Schema = z.lazy(() => databricksConnectionSchema)

/**
 * @description Validation Error
 */
export const deleteDatabricksConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteDatabricksConnectionMutationResponseSchema = z.lazy(() => deleteDatabricksConnection200Schema)