// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/manifestCallSchema.ts"
);
import.meta.hot.lastModified = "1737759758724.034";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description A log for a call to Manifest.
 */
export const manifestCallSchema = z
  .object({
    name: z.string(),
    prompt: z.union([z.array(z.string()), z.array(z.object({})), z.array(z.array(z.object({})))]),
    overwrite_cache: z.boolean().default(false),
    return_response: z.boolean().default(false),
    stop_token: z.union([z.array(z.string()), z.null()]).optional(),
    kwargs: z.union([z.object({}).strict(), z.null()]).optional(),
    response: z.object({}),
    response_time_s: z.number(),
  })
  .describe('A log for a call to Manifest.')