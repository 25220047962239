// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/AdminService.ts"
);
import.meta.hot.lastModified = "1737935753844.8232";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultSetting } from '../models/DefaultSetting';
import type { DefaultSettingUpdate } from '../models/DefaultSettingUpdate';
import type { PromptLogComponent } from '../models/PromptLogComponent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Default Settings
   * Admin endpoint to get a default setting.
   * @param settingName
   * @param sessionId
   * @returns DefaultSetting Successful Response
   * @throws ApiError
   */
  public getDefaultSettings(
    settingName: string,
    sessionId?: (string | null),
  ): CancelablePromise<DefaultSetting> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/default-settings/{setting_name}',
      path: {
        'setting_name': settingName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Default Setting
   * Admin endpoint to update a default setting.
   * @param settingName
   * @param requestBody
   * @param sessionId
   * @returns DefaultSetting Successful Response
   * @throws ApiError
   */
  public updateDefaultSetting(
    settingName: string,
    requestBody: DefaultSettingUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<DefaultSetting> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/admin/default-settings/{setting_name}',
      path: {
        'setting_name': settingName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Star Schema Data Sources
   * Delete all SSDSs in an account.
   * @param accountName
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public deleteStarSchemaDataSources(
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/admin/star_schema_data_source/{account_name}',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Question Modelcalls
   * Admin endpoint to get model call history for a question.
   * Admin endpoints require a superuser token. It's intended that an NS admin could log in, then
   * pass in any bumblebee cell id.
   * Returns:
   * This returns a JSON list of model calls in reverse chronological order, by
   * default only the most recent.
   * @param question
   * @param component
   * @param limit
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getQuestionModelcalls(
    question: string,
    component?: (PromptLogComponent | null),
    limit: number = 5,
    sessionId?: (string | null),
  ): CancelablePromise<Array<any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/promptlog/modelcalls',
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'question': question,
        'component': component,
        'limit': limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Backing Services
   * Admin endpoint to get all backing services.
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getBackingServices(
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/backing-services',
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Search Zen Dimension Value
   * Admin endpoint to search for a Zen Dimension Value.
   * @param value
   * @param limit
   * @param explainAnalyze
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public searchZenDimensionValue(
    value: string,
    limit: number = 10,
    explainAnalyze: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/search/zen_dimension_value',
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'value': value,
        'limit': limit,
        'explain_analyze': explainAnalyze,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
