// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateUserMe.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateUserMeMutationRequest, UpdateUserMeMutationResponse, UpdateUserMe422 } from '../types/UpdateUserMe'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateUserMeMutationKey = () => [{ url: '/api/v1/users/me' }] as const

export type UpdateUserMeMutationKey = ReturnType<typeof updateUserMeMutationKey>

/**
 * @description Update own user.
 * @summary Update User Me
 * {@link /api/v1/users/me}
 */
async function updateUserMe({ data }: { data?: UpdateUserMeMutationRequest }, config: Partial<RequestConfig<UpdateUserMeMutationRequest>> = {}) {
  const res = await client<UpdateUserMeMutationResponse, UpdateUserMe422, UpdateUserMeMutationRequest>({
    method: 'PUT',
    url: `/api/v1/users/me`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update own user.
 * @summary Update User Me
 * {@link /api/v1/users/me}
 */
export function useUpdateUserMe(
  options: {
    mutation?: UseMutationOptions<UpdateUserMeMutationResponse, UpdateUserMe422, { data?: UpdateUserMeMutationRequest }>
    client?: Partial<RequestConfig<UpdateUserMeMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateUserMeMutationKey()

  return useMutation<UpdateUserMeMutationResponse, UpdateUserMe422, { data?: UpdateUserMeMutationRequest }>({
    mutationFn: async ({ data }) => {
      return updateUserMe({ data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}