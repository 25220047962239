// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/HealthzService.ts"
);
import.meta.hot.lastModified = "1737935753784.8235";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HealthzService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Health Check
   * Return an HTTP OK.
   * @returns any Successful Response
   * @throws ApiError
   */
  public healthCheck(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/healthz',
    });
  }

  /**
   * Version
   * Return the version of the mlcore web server.
   * @returns any Successful Response
   * @throws ApiError
   */
  public version(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/version',
    });
  }

}
