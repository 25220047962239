// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readMessageTableDataSchema.ts"
);
import.meta.hot.lastModified = "1737759761029.0222";
}
// REMIX HMR END

// @ts-nocheck
import { dataPageSchema } from './dataPageSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readMessageTableDataPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  message_id: z.string().describe('The id of a message in a chat.'),
})

export const readMessageTableDataQueryParamsSchema = z
  .object({
    limit: z.number().int().min(1).max(1000).default(100).describe('The maximum number of rows to include in the response.'),
    skip: z.number().int().min(0).default(0).describe("The number of rows to skip. Equivalent of 'skip' in database queries."),
    sort: z
      .union([z.array(z.string()), z.null()])
      .describe("The columns to sort by. Prefix with '-' for descending order.")
      .optional(),
    format: z.boolean().default(true).describe('If true, format the table data according to display type.'),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readMessageTableData200Schema = z.lazy(() => dataPageSchema)

/**
 * @description Bad request. Verify that the message contains a table ID.
 */
export const readMessageTableData400Schema = z.unknown()

/**
 * @description The item could not be found.
 */
export const readMessageTableData404Schema = z.unknown()

/**
 * @description Validation Error
 */
export const readMessageTableData422Schema = z.lazy(() => httpValidationErrorSchema)

export const readMessageTableDataQueryResponseSchema = z.lazy(() => readMessageTableData200Schema)