// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/logSchema.ts"
);
import.meta.hot.lastModified = "1737759757505.0403";
}
// REMIX HMR END

// @ts-nocheck
import { browserLogSchema } from './browserLogSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const logPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const log204Schema = z.unknown()

/**
 * @description Validation Error
 */
export const log422Schema = z.lazy(() => httpValidationErrorSchema)

export const logMutationRequestSchema = z.lazy(() => browserLogSchema)

export const logMutationResponseSchema = z.lazy(() => log204Schema)