// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSemanticQuery.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetSemanticQueryQueryResponse, GetSemanticQueryPathParams, GetSemanticQuery422 } from '../types/GetSemanticQuery'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSemanticQueryQueryKey = ({
  accountName,
  semanticQueryId,
}: {
  accountName: GetSemanticQueryPathParams['account_name']
  semanticQueryId: GetSemanticQueryPathParams['semantic_query_id']
}) =>
  [{ url: '/api/v1/accounts/:account_name/semantic_query/:semantic_query_id', params: { accountName: accountName, semanticQueryId: semanticQueryId } }] as const

export type GetSemanticQueryQueryKey = ReturnType<typeof getSemanticQueryQueryKey>

/**
 * @description Get a semantic query.
 * @summary Get Semantic Query
 * {@link /api/v1/accounts/:account_name/semantic_query/:semantic_query_id}
 */
async function getSemanticQuery(
  {
    accountName,
    semanticQueryId,
  }: { accountName: GetSemanticQueryPathParams['account_name']; semanticQueryId: GetSemanticQueryPathParams['semantic_query_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSemanticQueryQueryResponse, GetSemanticQuery422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/semantic_query/${semanticQueryId}`,
    ...config,
  })
  return res.data
}

export function getSemanticQueryQueryOptions(
  {
    accountName,
    semanticQueryId,
  }: { accountName: GetSemanticQueryPathParams['account_name']; semanticQueryId: GetSemanticQueryPathParams['semantic_query_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSemanticQueryQueryKey({ accountName, semanticQueryId })
  return queryOptions({
    enabled: !!(accountName && semanticQueryId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSemanticQuery({ accountName, semanticQueryId }, config)
    },
  })
}

/**
 * @description Get a semantic query.
 * @summary Get Semantic Query
 * {@link /api/v1/accounts/:account_name/semantic_query/:semantic_query_id}
 */
export function useGetSemanticQuery<
  TData = GetSemanticQueryQueryResponse,
  TQueryData = GetSemanticQueryQueryResponse,
  TQueryKey extends QueryKey = GetSemanticQueryQueryKey,
>(
  {
    accountName,
    semanticQueryId,
  }: { accountName: GetSemanticQueryPathParams['account_name']; semanticQueryId: GetSemanticQueryPathParams['semantic_query_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetSemanticQueryQueryResponse, GetSemanticQuery422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSemanticQueryQueryKey({ accountName, semanticQueryId })

  const query = useQuery({
    ...(getSemanticQueryQueryOptions({ accountName, semanticQueryId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSemanticQuery422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}