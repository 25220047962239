// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getWidgetSchema.ts"
);
import.meta.hot.lastModified = "1737759758187.0369";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { widgetApiGetResponseSchema } from './widgetApiGetResponseSchema'
import { z } from 'zod'

export const getWidgetPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  widget_id: z.string(),
})

/**
 * @description Successful Response
 */
export const getWidget200Schema = z.lazy(() => widgetApiGetResponseSchema)

/**
 * @description Validation Error
 */
export const getWidget422Schema = z.lazy(() => httpValidationErrorSchema)

export const getWidgetQueryResponseSchema = z.lazy(() => getWidget200Schema)