// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/TimeGranularity.ts"
);
import.meta.hot.lastModified = "1737759758682.0342";
}
// REMIX HMR END

export enum TimeGranularity {
  'day' = 'day',
  'week' = 'week',
  'month' = 'month',
  'quarter' = 'quarter',
  'year' = 'year',
}