// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/KnowledgeLayerService.ts"
);
import.meta.hot.lastModified = "1737935753894.8228";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KnowledgeLayerContextAPI } from '../models/KnowledgeLayerContextAPI';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class KnowledgeLayerService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Entity Context
   * Get the context associated with an entity.
   *
   * Args:
   * entity_id: The ID of the entity. Note that this can either be a UUID str or a base64 encoded
   * string of last 12-char of UUID string.
   * @param accountName The name of the organization or account.
   * @param entityType Type of the entity
   * @param entityId
   * @param sessionId
   * @returns KnowledgeLayerContextAPI The context associated with the entity was fetched successfully.
   * @throws ApiError
   */
  public getEntityContext(
    accountName: string,
    entityType: 'dimension' | 'metric' | 'dataset' | 'tableau' | 'mode',
    entityId: string,
    sessionId?: (string | null),
  ): CancelablePromise<KnowledgeLayerContextAPI> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/knowledge_layer/context',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'entity_type': entityType,
        'entity_id': entityId,
      },
      errors: {
        404: `The entity was not found.`,
        422: `Validation Error`,
      },
    });
  }

}
