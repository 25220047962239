// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateChat.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateChatMutationRequest, CreateChatMutationResponse, CreateChatPathParams, CreateChat422 } from '../types/CreateChat'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createChatMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/chat/' }] as const

export type CreateChatMutationKey = ReturnType<typeof createChatMutationKey>

/**
 * @description Create a chat. This will not send a message to the chat.Note that you can also create a chat using the send message endpoint. Doing so willlet you skip making a separate call to create the chat.
 * @summary Create Chat
 * {@link /api/v3/orgs/:account_name/chat/}
 */
async function createChat(
  { accountName, data }: { accountName: CreateChatPathParams['account_name']; data?: CreateChatMutationRequest },
  config: Partial<RequestConfig<CreateChatMutationRequest>> = {},
) {
  const res = await client<CreateChatMutationResponse, CreateChat422, CreateChatMutationRequest>({
    method: 'POST',
    url: `/api/v3/orgs/${accountName}/chat/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a chat. This will not send a message to the chat.Note that you can also create a chat using the send message endpoint. Doing so willlet you skip making a separate call to create the chat.
 * @summary Create Chat
 * {@link /api/v3/orgs/:account_name/chat/}
 */
export function useCreateChat(
  options: {
    mutation?: UseMutationOptions<
      CreateChatMutationResponse,
      CreateChat422,
      { accountName: CreateChatPathParams['account_name']; data?: CreateChatMutationRequest }
    >
    client?: Partial<RequestConfig<CreateChatMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createChatMutationKey()

  return useMutation<CreateChatMutationResponse, CreateChat422, { accountName: CreateChatPathParams['account_name']; data?: CreateChatMutationRequest }>({
    mutationFn: async ({ accountName, data }) => {
      return createChat({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}