// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useExecuteUpdateJob.ts"
);
import.meta.hot.lastModified = "1737935767035.7507";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ExecuteUpdateJobMutationResponse, ExecuteUpdateJobPathParams, ExecuteUpdateJob422 } from '../types/ExecuteUpdateJob'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const executeUpdateJobMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/update/{job_id}' }] as const

export type ExecuteUpdateJobMutationKey = ReturnType<typeof executeUpdateJobMutationKey>

/**
 * @description Execute an update job.
 * @summary Execute Update Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/update/:job_id}
 */
async function executeUpdateJob(
  { enterpriseName, jobId }: { enterpriseName: ExecuteUpdateJobPathParams['enterprise_name']; jobId: ExecuteUpdateJobPathParams['job_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ExecuteUpdateJobMutationResponse, ExecuteUpdateJob422, unknown>({
    method: 'POST',
    url: `/api/v1/enterprises/${enterpriseName}/dataset-jobs/update/${jobId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Execute an update job.
 * @summary Execute Update Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/update/:job_id}
 */
export function useExecuteUpdateJob(
  options: {
    mutation?: UseMutationOptions<
      ExecuteUpdateJobMutationResponse,
      ExecuteUpdateJob422,
      { enterpriseName: ExecuteUpdateJobPathParams['enterprise_name']; jobId: ExecuteUpdateJobPathParams['job_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? executeUpdateJobMutationKey()

  return useMutation<
    ExecuteUpdateJobMutationResponse,
    ExecuteUpdateJob422,
    { enterpriseName: ExecuteUpdateJobPathParams['enterprise_name']; jobId: ExecuteUpdateJobPathParams['job_id'] }
  >({
    mutationFn: async ({ enterpriseName, jobId }) => {
      return executeUpdateJob({ enterpriseName, jobId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}