// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/starSchemaDataSourceBasicSchema.ts"
);
import.meta.hot.lastModified = "1737759761867.0178";
}
// REMIX HMR END

// @ts-nocheck
import { connectionSchema } from './connectionSchema'
import { dataAssetVisibilitySchema } from './dataAssetVisibilitySchema'
import { dataSourceMetadataSchema } from './dataSourceMetadataSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

/**
 * @description The basic info of a StarSchemaDataSource in the DB.
 */
export const starSchemaDataSourceBasicSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    data_sources_metadata: z.array(z.lazy(() => dataSourceMetadataSchema)),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    is_draft: z.union([z.boolean(), z.null()]).optional(),
    is_ready: z.union([z.boolean(), z.null()]).optional(),
    is_demo: z.boolean(),
    visibility: z.lazy(() => dataAssetVisibilitySchema),
    tasks: z.array(z.lazy(() => taskSchema)).optional(),
    metrics_count: z.number().int(),
    dimensions_count: z.number().int(),
    cells_count: z.number().int(),
    chats_count: z.number().int(),
    connection: z.union([z.lazy(() => connectionSchema), z.null()]).optional(),
  })
  .describe('The basic info of a StarSchemaDataSource in the DB.')