// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyLoginSessionApiV1LoginSessionPostSchema.ts"
);
import.meta.hot.lastModified = "1737759762549.0142";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const bodyLoginSessionApiV1LoginSessionPostSchema = z.object({
  username: z.string().describe('The email address of the user to authenticate.'),
  password: z.string().describe('The password of the user to authenticate.'),
  grant_type: z
    .union([z.string().regex(/password/), z.null()])
    .default('password')
    .describe("The grant type. Must be 'password' for this endpoint. If not provided, it defaults to 'password'."),
})