// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/sendMessageSchema.ts"
);
import.meta.hot.lastModified = "1737935765553.7588";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { messageApiResponseSchema } from './messageApiResponseSchema'
import { messageApiSendV3Schema } from './messageApiSendV3Schema'
import { z } from 'zod'

export const sendMessagePathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

/**
 * @description Message sent successfully. Returns an event stream of `MessageAPIResponse` objects.
 */
export const sendMessage201Schema = z.lazy(() => messageApiResponseSchema)

/**
 * @description The message is empty and was ignored.
 */
export const sendMessage204Schema = z.unknown()

/**
 * @description The chat or message could not be found.
 */
export const sendMessage404Schema = z.unknown()

/**
 * @description Chat is busy. Returns an event stream of the running chat.
 */
export const sendMessage409Schema = z.lazy(() => messageApiResponseSchema)

/**
 * @description Validation Error
 */
export const sendMessage422Schema = z.lazy(() => httpValidationErrorSchema)

export const sendMessageMutationRequestSchema = z.lazy(() => messageApiSendV3Schema)

export const sendMessageMutationResponseSchema = z.union([z.lazy(() => sendMessage201Schema), z.lazy(() => sendMessage204Schema)])