// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCompeletePreregistration.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CompeletePreregistrationMutationRequest,
  CompeletePreregistrationMutationResponse,
  CompeletePreregistrationPathParams,
  CompeletePreregistration422,
} from '../types/CompeletePreregistration'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const compeletePreregistrationMutationKey = () => [{ url: '/api/v1/users/preregistration/{preregistration_id}' }] as const

export type CompeletePreregistrationMutationKey = ReturnType<typeof compeletePreregistrationMutationKey>

/**
 * @description Register a user.
 * @summary Compelete Preregistration
 * {@link /api/v1/users/preregistration/:preregistration_id}
 */
async function compeletePreregistration(
  { preregistrationId, data }: { preregistrationId: CompeletePreregistrationPathParams['preregistration_id']; data: CompeletePreregistrationMutationRequest },
  config: Partial<RequestConfig<CompeletePreregistrationMutationRequest>> = {},
) {
  const res = await client<CompeletePreregistrationMutationResponse, CompeletePreregistration422, CompeletePreregistrationMutationRequest>({
    method: 'POST',
    url: `/api/v1/users/preregistration/${preregistrationId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Register a user.
 * @summary Compelete Preregistration
 * {@link /api/v1/users/preregistration/:preregistration_id}
 */
export function useCompeletePreregistration(
  options: {
    mutation?: UseMutationOptions<
      CompeletePreregistrationMutationResponse,
      CompeletePreregistration422,
      { preregistrationId: CompeletePreregistrationPathParams['preregistration_id']; data: CompeletePreregistrationMutationRequest }
    >
    client?: Partial<RequestConfig<CompeletePreregistrationMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? compeletePreregistrationMutationKey()

  return useMutation<
    CompeletePreregistrationMutationResponse,
    CompeletePreregistration422,
    { preregistrationId: CompeletePreregistrationPathParams['preregistration_id']; data: CompeletePreregistrationMutationRequest }
  >({
    mutationFn: async ({ preregistrationId, data }) => {
      return compeletePreregistration({ preregistrationId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}