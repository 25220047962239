// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/GetEnterpriseAccounts.ts"
);
import.meta.hot.lastModified = "1737759760438.0251";
}
// REMIX HMR END

import type { Account } from './Account'
import type { HttpValidationError } from './HttpValidationError'

export type GetEnterpriseAccountsPathParams = {
  /**
   * @type string
   */
  enterprise_name: string
}

export enum GetEnterpriseAccountsQueryParamsSortBy {
  'name' = 'name',
  'created_at' = 'created_at',
  'updated_at' = 'updated_at',
}

export type GetEnterpriseAccountsQueryParams = {
  /**
   * @default 0
   * @type integer | undefined
   */
  skip?: number
  /**
   * @default 100
   * @type integer | undefined
   */
  limit?: number
  /**
   * @default "name"
   * @type string | undefined
   */
  sort_by?: GetEnterpriseAccountsQueryParamsSortBy
  /**
   * @default true
   * @type boolean | undefined
   */
  sort_ascending?: boolean
}

/**
 * @description Successful Response
 */
export type GetEnterpriseAccounts200 = Account[]

/**
 * @description Validation Error
 */
export type GetEnterpriseAccounts422 = HttpValidationError

export type GetEnterpriseAccountsQueryResponse = GetEnterpriseAccounts200

export type GetEnterpriseAccountsQuery = {
  Response: GetEnterpriseAccounts200
  PathParams: GetEnterpriseAccountsPathParams
  QueryParams: GetEnterpriseAccountsQueryParams
  Errors: GetEnterpriseAccounts422
}