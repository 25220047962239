// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readRedshiftConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759761449.02";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { redshiftConnectionSchema } from './redshiftConnectionSchema'
import { z } from 'zod'

export const readRedshiftConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const readRedshiftConnection200Schema = z.lazy(() => redshiftConnectionSchema)

/**
 * @description Validation Error
 */
export const readRedshiftConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const readRedshiftConnectionQueryResponseSchema = z.lazy(() => readRedshiftConnection200Schema)