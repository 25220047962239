// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dataAssetApiPageSchema.ts"
);
import.meta.hot.lastModified = "1737759760036.0273";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetApiSchema } from './dataAssetApiSchema'
import { z } from 'zod'

/**
 * @description A page of data assets.
 */
export const dataAssetApiPageSchema = z
  .object({
    data: z.array(z.lazy(() => dataAssetApiSchema)),
    total: z.number().int().describe('The total number of data assets.'),
  })
  .describe('A page of data assets.')