// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/LoginService.ts"
);
import.meta.hot.lastModified = "1737935753786.8235";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_login_access_token_api_v1_login_access_token_post } from '../models/Body_login_access_token_api_v1_login_access_token_post';
import type { Body_login_session_api_v1_login_session_post } from '../models/Body_login_session_api_v1_login_session_post';
import type { Body_recover_password_api_v1_password_recovery__post } from '../models/Body_recover_password_api_v1_password_recovery__post';
import type { Body_reset_password_api_v1_reset_password__post } from '../models/Body_reset_password_api_v1_reset_password__post';
import type { Msg } from '../models/Msg';
import type { Provider } from '../models/Provider';
import type { Token } from '../models/Token';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoginService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Login Access Token
   * OAuth2-compatible token login, get a bearer token for future requests.
   *
   * The bearer token must be included in the Authorization header of all future requests.
   *
   * Note that access token authentication cannot be used to access user-specific resources
   * such as user passwords and superuser status.
   * @param formData
   * @param accountName The name of the account to validate that the user is authorized to access. If the user does not have access to this account, the endpoint will return a 403 even if the user is successfully authenticated. This is useful for third party applications which want users to be restricted to a particular account.
   * @param m2MToken A custom M2M access token in case one is needed to access resources in your environment. This is not relevant for cloud customers. It's only used for VPC deployments.
   * @returns Token Successful Response
   * @throws ApiError
   */
  public loginAccessToken(
    formData: Body_login_access_token_api_v1_login_access_token_post,
    accountName?: (string | null),
    m2MToken?: (string | null),
  ): CancelablePromise<Token> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/access-token',
      query: {
        'account_name': accountName,
        'm2m_token': m2MToken,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Logout Access Token
   * Logout and revoke the access token.
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public logoutAccessToken(
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/logout/access-token',
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Login Access Token Api Key
   * API key login, get a bearer token for future requests.
   *
   * The bearer token must be included in the Authorization header of all future requests.
   *
   * Include your API key in the `x-api-key` header.
   * @param accountName The name of the account to authenticate the API key against.
   * @param email The email address of the user in this account to authenticate.
   * @param create Whether to create the user if it doesn't exist. If set to false and the user with the email is not part of the account, the endpoint will return a 404. If set to true and the user does not exist, the endpoint will create the user and add them to the account. If set to true and the user exists, the endpoint will add the user to the account.
   * @param m2MToken A custom M2M access token in case one is needed to access resources in your environment. This is not relevant for cloud customers. It's only used for VPC deployments.
   * @returns Token Successful Response
   * @throws ApiError
   */
  public loginAccessTokenApiKey(
    accountName: string,
    email: string,
    create: boolean = false,
    m2MToken?: (string | null),
  ): CancelablePromise<Token> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/access-token/api-key',
      query: {
        'account_name': accountName,
        'email': email,
        'create': create,
        'm2m_token': m2MToken,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Recover Password
   * Send a password recovery email to a user.
   * @param requestBody
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public recoverPassword(
    requestBody: Body_recover_password_api_v1_password_recovery__post,
  ): CancelablePromise<Msg> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/password-recovery/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Reset Password
   * Reset a user's password.
   * @param requestBody
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public resetPassword(
    requestBody: Body_reset_password_api_v1_reset_password__post,
  ): CancelablePromise<Msg> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/reset-password/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Login Session
   * Login and get a session-based cookie.
   *
   * The response headers will contain the cookie. Note that you must use the cookie for all
   * other requests to the API.
   * @param formData
   * @param thirdParty Whether to set a third-party cookie for cross-origin iframes.
   * @param slackId An optional base64-encoded Slack {team_id}:{user_id} to link to (for use by the NS Slack App).
   * @param accountName The name of the account to validate that the user is authorized to access. If the user does not have access to this account, the endpoint will return a 403 even if the user is successfully authenticated. This is useful for third party applications which want users to be restricted to a particular account.
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSession(
    formData: Body_login_session_api_v1_login_session_post,
    thirdParty: boolean = false,
    slackId?: (string | null),
    accountName?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/session',
      query: {
        'third_party': thirdParty,
        'slack_id': slackId,
        'account_name': accountName,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Logout Session
   * Logout and unset session-based cookie value.
   * @param thirdParty Whether to set a third-party cookie for cross-origin iframes.
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public logoutSession(
    thirdParty: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/logout/session',
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'third_party': thirdParty,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Login Session Oauth
   * Login via OAuth2.0 authorization code flow with PKCE.
   *
   * The currently supported OAuth2.0 providers are:
   * - Okta
   * - Google
   * @param provider The OAuth provider. At the moment this defaults to 'okta' but this default will be removed in the future. Please specify the provider explicitly to avoid issues.
   * @param accountName The name of the account to validate that the user is authorized to access. If the user does not have access to this account, the endpoint will return a 403 even if the user is successfully authenticated. This is useful for third party applications which want users to be restricted to a particular account.
   * @param redirectHeader Whether to redirect at the HTTP level or not. Browsers set origin=null if a cross-origin resource redirects to yet another origin. This can be problematic for an embedded iframe. Instead, setting redirect to False puts the redirect information in the response body. Then it is up to the client to handle the redirect themselves.
   * @param redirectUri The URI to redirect to after the login is complete. This is needed for clients to redirect back to the original page. When OAuth is complete, the user will be redirected to this URI. If the login is successful, a session cookie will be set. If the login is unsuccessful, an error parameter will be set in the redirect_uri query params. If this value is set to None then the redirect_uri will default to https://app.numbersstation.ai/login.
   * @param thirdParty Whether to set a third-party cookie for cross-origin iframes.
   * @param slackId An optional base64-encoded Slack {team_id}:{user_id} to link to (for use by the NS Slack App).
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSessionOauth(
    provider: Provider,
    accountName?: (string | null),
    redirectHeader: boolean = false,
    redirectUri?: (string | null),
    thirdParty: boolean = false,
    slackId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/login/session/oauth',
      query: {
        'provider': provider,
        'account_name': accountName,
        'redirect_header': redirectHeader,
        'redirect_uri': redirectUri,
        'third_party': thirdParty,
        'slack_id': slackId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Callback
   * Callback handler.
   *
   * Do not call this endpoint directly. It is used by the OAuth2.0 authorization code flow with PKCE.
   * This will redirect the user to the redirect_uri specified in the original request. If the auth
   * is successful, then a session cookie will be set. If the auth is unsuccessful, then an error
   * parameter will be set in the redirect_uri query params.
   * e.g. app.numbersstation.ai/login?error=An%20error%20occurred
   * @returns any Successful Response
   * @throws ApiError
   */
  public callback(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/auth/oauth',
    });
  }

  /**
   * Login Session Firebase
   * Login via Firebase.
   * @param idToken
   * @param accountName
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSessionFirebase(
    idToken: string,
    accountName: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/session/firebase',
      query: {
        'id_token': idToken,
        'account_name': accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
