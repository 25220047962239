// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/NamedFilterOptionCreate.ts"
);
import.meta.hot.lastModified = "1737935754007.8223";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Combination } from './Combination';
import type { FilterOrigin } from './FilterOrigin';
import type { NamedFilterOptionConditionCreate } from './NamedFilterOptionConditionCreate';

/**
 * Create NamedFilterOption Schema.
 */
export type NamedFilterOptionCreate = {
  id?: (string | null);
  query_id?: (string | null);
  name: string;
  combination?: Combination;
  description?: (string | null);
  filter_origin?: FilterOrigin;
  is_enabled?: boolean;
  field_type?: NamedFilterOptionCreate.field_type;
  is_result_filter?: boolean;
  conditions: Array<NamedFilterOptionConditionCreate>;
  named_filter_id?: (string | null);
};

export namespace NamedFilterOptionCreate {

  export enum field_type {
    DIMENSION = 'dimension',
  }


}

