// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/runBumblebeeCellSchema.ts"
);
import.meta.hot.lastModified = "1737759760208.0264";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { moonlightRunParamsSchema } from './moonlightRunParamsSchema'
import { runBbCellResponseSchema } from './runBbCellResponseSchema'
import { z } from 'zod'

export const runBumblebeeCellPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

/**
 * @description Successful Response
 */
export const runBumblebeeCell200Schema = z.lazy(() => runBbCellResponseSchema)

/**
 * @description Validation Error
 */
export const runBumblebeeCell422Schema = z.lazy(() => httpValidationErrorSchema)

export const runBumblebeeCellMutationRequestSchema = z.union([z.lazy(() => moonlightRunParamsSchema), z.null()])

export const runBumblebeeCellMutationResponseSchema = z.lazy(() => runBumblebeeCell200Schema)