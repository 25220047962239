// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetChatAgents.ts"
);
import.meta.hot.lastModified = "1737759758962.0327";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetChatAgentsQueryResponse, GetChatAgentsPathParams, GetChatAgents422 } from '../types/GetChatAgents'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getChatAgentsQueryKey = ({ accountName }: { accountName: GetChatAgentsPathParams['account_name'] }) =>
  [{ url: '/api/v3/orgs/:account_name/chat/agents', params: { accountName: accountName } }] as const

export type GetChatAgentsQueryKey = ReturnType<typeof getChatAgentsQueryKey>

/**
 * @description Get list of available agents by name.
 * @summary Get Chat Agents
 * {@link /api/v3/orgs/:account_name/chat/agents}
 */
async function getChatAgents({ accountName }: { accountName: GetChatAgentsPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<GetChatAgentsQueryResponse, GetChatAgents422, unknown>({ method: 'GET', url: `/api/v3/orgs/${accountName}/chat/agents`, ...config })
  return res.data
}

export function getChatAgentsQueryOptions({ accountName }: { accountName: GetChatAgentsPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const queryKey = getChatAgentsQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getChatAgents({ accountName }, config)
    },
  })
}

/**
 * @description Get list of available agents by name.
 * @summary Get Chat Agents
 * {@link /api/v3/orgs/:account_name/chat/agents}
 */
export function useGetChatAgents<
  TData = GetChatAgentsQueryResponse,
  TQueryData = GetChatAgentsQueryResponse,
  TQueryKey extends QueryKey = GetChatAgentsQueryKey,
>(
  { accountName }: { accountName: GetChatAgentsPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<GetChatAgentsQueryResponse, GetChatAgents422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getChatAgentsQueryKey({ accountName })

  const query = useQuery({
    ...(getChatAgentsQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetChatAgents422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}