// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/SignalTypeApi.ts"
);
import.meta.hot.lastModified = "1737935765335.76";
}
// REMIX HMR END

export enum SignalTypeApi {
  'REQUEST_INPUT' = 'REQUEST_INPUT',
  'REJECTED_INPUT' = 'REJECTED_INPUT',
}