// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getCellTasksSchema.ts"
);
import.meta.hot.lastModified = "1737759758961.0327";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getCellTasksPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

export const getCellTasksQueryParamsSchema = z
  .object({
    limit: z.number().int().default(100),
    skip: z.number().int().default(0),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getCellTasks200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const getCellTasks422Schema = z.lazy(() => httpValidationErrorSchema)

export const getCellTasksQueryResponseSchema = z.lazy(() => getCellTasks200Schema)