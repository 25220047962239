// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readReportsFromFolderSchema.ts"
);
import.meta.hot.lastModified = "1737935768446.743";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { reportFolderSchema } from './reportFolderSchema'
import { reportSchema } from './reportSchema'
import { reportTypeSchema } from './reportTypeSchema'
import { z } from 'zod'

export const readReportsFromFolderPathParamsSchema = z.object({
  account_name: z.string(),
  connection_type: z.lazy(() => reportTypeSchema),
  connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const readReportsFromFolder200Schema = z.array(z.lazy(() => reportSchema))

/**
 * @description Validation Error
 */
export const readReportsFromFolder422Schema = z.lazy(() => httpValidationErrorSchema)

export const readReportsFromFolderMutationRequestSchema = z.lazy(() => reportFolderSchema)

export const readReportsFromFolderMutationResponseSchema = z.lazy(() => readReportsFromFolder200Schema)