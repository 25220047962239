// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetCellTasks.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetCellTasksQueryResponse, GetCellTasksPathParams, GetCellTasksQueryParams, GetCellTasks422 } from '../types/GetCellTasks'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCellTasksQueryKey = (
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetCellTasksPathParams['account_name']
    conversationId: GetCellTasksPathParams['conversation_id']
    cellId: GetCellTasksPathParams['cell_id']
  },
  params?: GetCellTasksQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/tasks',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetCellTasksQueryKey = ReturnType<typeof getCellTasksQueryKey>

/**
 * @description Get all tasks for a cell.
 * @summary Get Cell Tasks
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/tasks}
 */
async function getCellTasks(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: GetCellTasksPathParams['account_name']
    conversationId: GetCellTasksPathParams['conversation_id']
    cellId: GetCellTasksPathParams['cell_id']
    params?: GetCellTasksQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetCellTasksQueryResponse, GetCellTasks422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/tasks`,
    params,
    ...config,
  })
  return res.data
}

export function getCellTasksQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: GetCellTasksPathParams['account_name']
    conversationId: GetCellTasksPathParams['conversation_id']
    cellId: GetCellTasksPathParams['cell_id']
    params?: GetCellTasksQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getCellTasksQueryKey({ accountName, conversationId, cellId }, params)
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCellTasks({ accountName, conversationId, cellId, params }, config)
    },
  })
}

/**
 * @description Get all tasks for a cell.
 * @summary Get Cell Tasks
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/tasks}
 */
export function useGetCellTasks<TData = GetCellTasksQueryResponse, TQueryData = GetCellTasksQueryResponse, TQueryKey extends QueryKey = GetCellTasksQueryKey>(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: GetCellTasksPathParams['account_name']
    conversationId: GetCellTasksPathParams['conversation_id']
    cellId: GetCellTasksPathParams['cell_id']
    params?: GetCellTasksQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetCellTasksQueryResponse, GetCellTasks422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCellTasksQueryKey({ accountName, conversationId, cellId }, params)

  const query = useQuery({
    ...(getCellTasksQueryOptions({ accountName, conversationId, cellId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetCellTasks422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}