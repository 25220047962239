// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRmAccountFromEnterprise.ts"
);
import.meta.hot.lastModified = "1737935768621.742";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RmAccountFromEnterpriseMutationResponse, RmAccountFromEnterprisePathParams, RmAccountFromEnterprise422 } from '../types/RmAccountFromEnterprise'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const rmAccountFromEnterpriseMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/accounts/{account_name}' }] as const

export type RmAccountFromEnterpriseMutationKey = ReturnType<typeof rmAccountFromEnterpriseMutationKey>

/**
 * @description Remove the account from the enterprise, and its users.This does not actually delete the account.
 * @summary Rm Account From Enterprise
 * {@link /api/v1/enterprises/:enterprise_name/accounts/:account_name}
 */
async function rmAccountFromEnterprise(
  {
    enterpriseName,
    accountName,
  }: { enterpriseName: RmAccountFromEnterprisePathParams['enterprise_name']; accountName: RmAccountFromEnterprisePathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RmAccountFromEnterpriseMutationResponse, RmAccountFromEnterprise422, unknown>({
    method: 'DELETE',
    url: `/api/v1/enterprises/${enterpriseName}/accounts/${accountName}`,
    ...config,
  })
  return res.data
}

/**
 * @description Remove the account from the enterprise, and its users.This does not actually delete the account.
 * @summary Rm Account From Enterprise
 * {@link /api/v1/enterprises/:enterprise_name/accounts/:account_name}
 */
export function useRmAccountFromEnterprise(
  options: {
    mutation?: UseMutationOptions<
      RmAccountFromEnterpriseMutationResponse,
      RmAccountFromEnterprise422,
      { enterpriseName: RmAccountFromEnterprisePathParams['enterprise_name']; accountName: RmAccountFromEnterprisePathParams['account_name'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? rmAccountFromEnterpriseMutationKey()

  return useMutation<
    RmAccountFromEnterpriseMutationResponse,
    RmAccountFromEnterprise422,
    { enterpriseName: RmAccountFromEnterprisePathParams['enterprise_name']; accountName: RmAccountFromEnterprisePathParams['account_name'] }
  >({
    mutationFn: async ({ enterpriseName, accountName }) => {
      return rmAccountFromEnterprise({ enterpriseName, accountName }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}