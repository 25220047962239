// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/sortSchema.ts"
);
import.meta.hot.lastModified = "1737759757505.0403";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Sort model.
 */
export const sortSchema = z
  .object({
    column: z.string(),
    ascending: z.boolean(),
    quoted: z.boolean().default(true),
    nulls_last: z.boolean().default(true),
  })
  .describe('Sort model.')