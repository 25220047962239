// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/namedFilterCreateSchema.ts"
);
import.meta.hot.lastModified = "1737935767634.7473";
}
// REMIX HMR END

// @ts-nocheck
import { namedFilterOptionCreateSchema } from './namedFilterOptionCreateSchema'
import { publishStateSchema } from './publishStateSchema'
import { z } from 'zod'

/**
 * @description Create NamedFilter Schema.
 */
export const namedFilterCreateSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    name: z.string(),
    ssds_id: z.union([z.string(), z.null()]).optional(),
    filter_options: z.array(z.lazy(() => namedFilterOptionCreateSchema)),
    description: z.union([z.string(), z.null()]).optional(),
    state: z.lazy(() => publishStateSchema).default('Verified'),
    updated_at: z.union([z.string().datetime(), z.null()]).optional(),
    created_at: z.union([z.string().datetime(), z.null()]).optional(),
  })
  .describe('Create NamedFilter Schema.')