// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getAccountUsersSchema.ts"
);
import.meta.hot.lastModified = "1737759759802.0286";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { userSchema } from './userSchema'
import { z } from 'zod'

export const getAccountUsersPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const getAccountUsers200Schema = z.array(z.lazy(() => userSchema))

/**
 * @description Validation Error
 */
export const getAccountUsers422Schema = z.lazy(() => httpValidationErrorSchema)

export const getAccountUsersQueryResponseSchema = z.lazy(() => getAccountUsers200Schema)