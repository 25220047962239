// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getStarSchemaDataSourceCommonValuesSchema.ts"
);
import.meta.hot.lastModified = "1737759762548.0144";
}
// REMIX HMR END

// @ts-nocheck
import { columnValueSchema } from './columnValueSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getStarSchemaDataSourceCommonValuesPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const getStarSchemaDataSourceCommonValues200Schema = z.object({}).catchall(z.array(z.lazy(() => columnValueSchema)))

/**
 * @description Validation Error
 */
export const getStarSchemaDataSourceCommonValues422Schema = z.lazy(() => httpValidationErrorSchema)

export const getStarSchemaDataSourceCommonValuesQueryResponseSchema = z.lazy(() => getStarSchemaDataSourceCommonValues200Schema)