// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useNumberOfUsersInAccount.ts"
);
import.meta.hot.lastModified = "1737935768446.743";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { NumberOfUsersInAccountQueryResponse, NumberOfUsersInAccountPathParams, NumberOfUsersInAccount422 } from '../types/NumberOfUsersInAccount'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const numberOfUsersInAccountQueryKey = ({ accountName }: { accountName: NumberOfUsersInAccountPathParams['account_name'] }) =>
  [{ url: '/api/v1/accounts/:account_name/num_users', params: { accountName: accountName } }] as const

export type NumberOfUsersInAccountQueryKey = ReturnType<typeof numberOfUsersInAccountQueryKey>

/**
 * @description The total number of distinct users in an account.
 * @summary Number Of Users In Account
 * {@link /api/v1/accounts/:account_name/num_users}
 */
async function numberOfUsersInAccount({ accountName }: { accountName: NumberOfUsersInAccountPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<NumberOfUsersInAccountQueryResponse, NumberOfUsersInAccount422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/num_users`,
    ...config,
  })
  return res.data
}

export function numberOfUsersInAccountQueryOptions(
  { accountName }: { accountName: NumberOfUsersInAccountPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = numberOfUsersInAccountQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return numberOfUsersInAccount({ accountName }, config)
    },
  })
}

/**
 * @description The total number of distinct users in an account.
 * @summary Number Of Users In Account
 * {@link /api/v1/accounts/:account_name/num_users}
 */
export function useNumberOfUsersInAccount<
  TData = NumberOfUsersInAccountQueryResponse,
  TQueryData = NumberOfUsersInAccountQueryResponse,
  TQueryKey extends QueryKey = NumberOfUsersInAccountQueryKey,
>(
  { accountName }: { accountName: NumberOfUsersInAccountPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<NumberOfUsersInAccountQueryResponse, NumberOfUsersInAccount422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? numberOfUsersInAccountQueryKey({ accountName })

  const query = useQuery({
    ...(numberOfUsersInAccountQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, NumberOfUsersInAccount422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}