// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/taskSchema.ts"
);
import.meta.hot.lastModified = "1737935764662.7637";
}
// REMIX HMR END

// @ts-nocheck
import { taskMetaSchema } from './taskMetaSchema'
import { taskNameSchema } from './taskNameSchema'
import { taskStatusSchema } from './taskStatusSchema'
import { z } from 'zod'

/**
 * @description Additional properties to return via API.
 */
export const taskSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    account_id: z.union([z.string(), z.null()]).optional(),
    is_scheduled: z.union([z.boolean(), z.null()]).default(false),
    creator_id: z.union([z.string(), z.null()]).optional(),
    cell_id: z.union([z.string(), z.null()]).optional(),
    conversation_id: z.union([z.string(), z.null()]).optional(),
    ssds_id: z.union([z.string(), z.null()]).optional(),
    chat_id: z.union([z.string(), z.null()]).optional(),
    job_id: z.union([z.string(), z.null()]).optional(),
    name: z.lazy(() => taskNameSchema).optional(),
    uri: z.union([z.string(), z.null()]).optional(),
    meta: z.union([z.lazy(() => taskMetaSchema), z.null()]).optional(),
    status: z.lazy(() => taskStatusSchema),
    completed_at: z.union([z.string().datetime(), z.null()]).optional(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    duration_ms: z.union([z.number().int(), z.null()]).optional(),
  })
  .describe('Additional properties to return via API.')