// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetConversation.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetConversationQueryResponse, GetConversationPathParams, GetConversation422 } from '../types/GetConversation'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getConversationQueryKey = ({
  accountName,
  conversationId,
}: {
  accountName: GetConversationPathParams['account_name']
  conversationId: GetConversationPathParams['conversation_id']
}) => [{ url: '/api/v1/accounts/:account_name/conversations/:conversation_id', params: { accountName: accountName, conversationId: conversationId } }] as const

export type GetConversationQueryKey = ReturnType<typeof getConversationQueryKey>

/**
 * @description Get a full conversation.
 * @summary Get Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id}
 */
async function getConversation(
  { accountName, conversationId }: { accountName: GetConversationPathParams['account_name']; conversationId: GetConversationPathParams['conversation_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetConversationQueryResponse, GetConversation422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}`,
    ...config,
  })
  return res.data
}

export function getConversationQueryOptions(
  { accountName, conversationId }: { accountName: GetConversationPathParams['account_name']; conversationId: GetConversationPathParams['conversation_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getConversationQueryKey({ accountName, conversationId })
  return queryOptions({
    enabled: !!(accountName && conversationId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getConversation({ accountName, conversationId }, config)
    },
  })
}

/**
 * @description Get a full conversation.
 * @summary Get Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id}
 */
export function useGetConversation<
  TData = GetConversationQueryResponse,
  TQueryData = GetConversationQueryResponse,
  TQueryKey extends QueryKey = GetConversationQueryKey,
>(
  { accountName, conversationId }: { accountName: GetConversationPathParams['account_name']; conversationId: GetConversationPathParams['conversation_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetConversationQueryResponse, GetConversation422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getConversationQueryKey({ accountName, conversationId })

  const query = useQuery({
    ...(getConversationQueryOptions({ accountName, conversationId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetConversation422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}