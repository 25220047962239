// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dmSensitiveAssignmentSchema.ts"
);
import.meta.hot.lastModified = "1737935768445.743";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description DM Sensitive Assignment, used for batch update.
 */
export const dmSensitiveAssignmentSchema = z
  .object({
    literal_sensitive_dimension_ids: z.array(z.string().uuid()).optional(),
    literal_sensitive_metric_ids: z.array(z.string().uuid()).optional(),
    generic_sensitive_dimension_ids: z.array(z.string().uuid()).optional(),
    generic_sensitive_metric_ids: z.array(z.string().uuid()).optional(),
  })
  .describe('DM Sensitive Assignment, used for batch update.')