// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUploadDimensionGroups.ts"
);
import.meta.hot.lastModified = "1737935768438.743";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UploadDimensionGroupsMutationRequest,
  UploadDimensionGroupsMutationResponse,
  UploadDimensionGroupsPathParams,
  UploadDimensionGroups422,
} from '../types/UploadDimensionGroups'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const uploadDimensionGroupsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_dimension_groups' }] as const

export type UploadDimensionGroupsMutationKey = ReturnType<typeof uploadDimensionGroupsMutationKey>

/**
 * @description Upload dimension groups from dict mapping dm names to group ids.
 * @summary Upload Dimension Groups
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/upload_dimension_groups}
 */
async function uploadDimensionGroups(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: UploadDimensionGroupsPathParams['account_name']
    starSchemaDataSourceId: UploadDimensionGroupsPathParams['star_schema_data_source_id']
    data?: UploadDimensionGroupsMutationRequest
  },
  config: Partial<RequestConfig<UploadDimensionGroupsMutationRequest>> = {},
) {
  const res = await client<UploadDimensionGroupsMutationResponse, UploadDimensionGroups422, UploadDimensionGroupsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/upload_dimension_groups`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Upload dimension groups from dict mapping dm names to group ids.
 * @summary Upload Dimension Groups
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/upload_dimension_groups}
 */
export function useUploadDimensionGroups(
  options: {
    mutation?: UseMutationOptions<
      UploadDimensionGroupsMutationResponse,
      UploadDimensionGroups422,
      {
        accountName: UploadDimensionGroupsPathParams['account_name']
        starSchemaDataSourceId: UploadDimensionGroupsPathParams['star_schema_data_source_id']
        data?: UploadDimensionGroupsMutationRequest
      }
    >
    client?: Partial<RequestConfig<UploadDimensionGroupsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? uploadDimensionGroupsMutationKey()

  return useMutation<
    UploadDimensionGroupsMutationResponse,
    UploadDimensionGroups422,
    {
      accountName: UploadDimensionGroupsPathParams['account_name']
      starSchemaDataSourceId: UploadDimensionGroupsPathParams['star_schema_data_source_id']
      data?: UploadDimensionGroupsMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return uploadDimensionGroups({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}