// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useResetPassword.ts"
);
import.meta.hot.lastModified = "1737759758962.0327";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ResetPasswordMutationRequest, ResetPasswordMutationResponse, ResetPassword422 } from '../types/ResetPassword'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const resetPasswordMutationKey = () => [{ url: '/api/v1/reset-password/' }] as const

export type ResetPasswordMutationKey = ReturnType<typeof resetPasswordMutationKey>

/**
 * @description Reset a user's password.
 * @summary Reset Password
 * {@link /api/v1/reset-password/}
 */
async function resetPassword({ data }: { data: ResetPasswordMutationRequest }, config: Partial<RequestConfig<ResetPasswordMutationRequest>> = {}) {
  const res = await client<ResetPasswordMutationResponse, ResetPassword422, ResetPasswordMutationRequest>({
    method: 'POST',
    url: `/api/v1/reset-password/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Reset a user's password.
 * @summary Reset Password
 * {@link /api/v1/reset-password/}
 */
export function useResetPassword(
  options: {
    mutation?: UseMutationOptions<ResetPasswordMutationResponse, ResetPassword422, { data: ResetPasswordMutationRequest }>
    client?: Partial<RequestConfig<ResetPasswordMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? resetPasswordMutationKey()

  return useMutation<ResetPasswordMutationResponse, ResetPassword422, { data: ResetPasswordMutationRequest }>({
    mutationFn: async ({ data }) => {
      return resetPassword({ data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}