// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/knowledgeLayerEntityApiSchema.ts"
);
import.meta.hot.lastModified = "1737759761624.019";
}
// REMIX HMR END

// @ts-nocheck
import { knowledgeLayerEntityTypeSchema } from './knowledgeLayerEntityTypeSchema'
import { z } from 'zod'

/**
 * @description Knowledge layer entity.
 */
export const knowledgeLayerEntityApiSchema = z
  .object({
    type: z.lazy(() => knowledgeLayerEntityTypeSchema).describe('The type of the entity.'),
    id: z.string().describe('The ID of the entity.'),
  })
  .describe('Knowledge layer entity.')