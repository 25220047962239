// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useLogoutSession.ts"
);
import.meta.hot.lastModified = "1737935766031.756";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { LogoutSessionMutationResponse, LogoutSessionQueryParams, LogoutSession422 } from '../types/LogoutSession'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const logoutSessionMutationKey = () => [{ url: '/api/v1/logout/session' }] as const

export type LogoutSessionMutationKey = ReturnType<typeof logoutSessionMutationKey>

/**
 * @description Logout and unset session-based cookie value.
 * @summary Logout Session
 * {@link /api/v1/logout/session}
 */
async function logoutSession({ params }: { params?: LogoutSessionQueryParams }, config: Partial<RequestConfig> = {}) {
  const res = await client<LogoutSessionMutationResponse, LogoutSession422, unknown>({ method: 'POST', url: `/api/v1/logout/session`, params, ...config })
  return res.data
}

/**
 * @description Logout and unset session-based cookie value.
 * @summary Logout Session
 * {@link /api/v1/logout/session}
 */
export function useLogoutSession(
  options: {
    mutation?: UseMutationOptions<LogoutSessionMutationResponse, LogoutSession422, { params?: LogoutSessionQueryParams }>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? logoutSessionMutationKey()

  return useMutation<LogoutSessionMutationResponse, LogoutSession422, { params?: LogoutSessionQueryParams }>({
    mutationFn: async ({ params }) => {
      return logoutSession({ params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}