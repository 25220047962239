// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateSsdsConnection.ts"
);
import.meta.hot.lastModified = "1737935768071.745";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateSsdsConnectionMutationResponse,
  UpdateSsdsConnectionPathParams,
  UpdateSsdsConnectionQueryParams,
  UpdateSsdsConnection422,
} from '../types/UpdateSsdsConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateSsdsConnectionMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/update_connection' }] as const

export type UpdateSsdsConnectionMutationKey = ReturnType<typeof updateSsdsConnectionMutationKey>

/**
 * @description Get Connection for Star Schema Data Source.
 * @summary Update Ssds Connection
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/update_connection}
 */
async function updateSsdsConnection(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: UpdateSsdsConnectionPathParams['account_name']
    starSchemaDataSourceId: UpdateSsdsConnectionPathParams['star_schema_data_source_id']
    params: UpdateSsdsConnectionQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateSsdsConnectionMutationResponse, UpdateSsdsConnection422, unknown>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/update_connection`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Get Connection for Star Schema Data Source.
 * @summary Update Ssds Connection
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/update_connection}
 */
export function useUpdateSsdsConnection(
  options: {
    mutation?: UseMutationOptions<
      UpdateSsdsConnectionMutationResponse,
      UpdateSsdsConnection422,
      {
        accountName: UpdateSsdsConnectionPathParams['account_name']
        starSchemaDataSourceId: UpdateSsdsConnectionPathParams['star_schema_data_source_id']
        params: UpdateSsdsConnectionQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateSsdsConnectionMutationKey()

  return useMutation<
    UpdateSsdsConnectionMutationResponse,
    UpdateSsdsConnection422,
    {
      accountName: UpdateSsdsConnectionPathParams['account_name']
      starSchemaDataSourceId: UpdateSsdsConnectionPathParams['star_schema_data_source_id']
      params: UpdateSsdsConnectionQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params }) => {
      return updateSsdsConnection({ accountName, starSchemaDataSourceId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}