// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteAllReportsSchema.ts"
);
import.meta.hot.lastModified = "1737759760211.0264";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { reportTypeSchema } from './reportTypeSchema'
import { z } from 'zod'

export const deleteAllReportsPathParamsSchema = z.object({
  account_name: z.string(),
})

export const deleteAllReportsQueryParamsSchema = z
  .object({
    type: z.union([z.lazy(() => reportTypeSchema), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const deleteAllReports200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const deleteAllReports422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteAllReportsMutationResponseSchema = z.lazy(() => deleteAllReports200Schema)