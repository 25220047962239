// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetAccountAdmins.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetAccountAdminsQueryResponse, GetAccountAdminsPathParams, GetAccountAdmins422 } from '../types/GetAccountAdmins'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAccountAdminsQueryKey = ({ accountName }: { accountName: GetAccountAdminsPathParams['account_name'] }) =>
  [{ url: '/api/v1/accounts/:account_name/admins', params: { accountName: accountName } }] as const

export type GetAccountAdminsQueryKey = ReturnType<typeof getAccountAdminsQueryKey>

/**
 * @description Get account admins.
 * @summary Get Account Admins
 * {@link /api/v1/accounts/:account_name/admins}
 */
async function getAccountAdmins({ accountName }: { accountName: GetAccountAdminsPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<GetAccountAdminsQueryResponse, GetAccountAdmins422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/admins`,
    ...config,
  })
  return res.data
}

export function getAccountAdminsQueryOptions(
  { accountName }: { accountName: GetAccountAdminsPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getAccountAdminsQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAccountAdmins({ accountName }, config)
    },
  })
}

/**
 * @description Get account admins.
 * @summary Get Account Admins
 * {@link /api/v1/accounts/:account_name/admins}
 */
export function useGetAccountAdmins<
  TData = GetAccountAdminsQueryResponse,
  TQueryData = GetAccountAdminsQueryResponse,
  TQueryKey extends QueryKey = GetAccountAdminsQueryKey,
>(
  { accountName }: { accountName: GetAccountAdminsPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<GetAccountAdminsQueryResponse, GetAccountAdmins422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAccountAdminsQueryKey({ accountName })

  const query = useQuery({
    ...(getAccountAdminsQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetAccountAdmins422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}