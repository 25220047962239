// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useExtractSqlsFromCsv.ts"
);
import.meta.hot.lastModified = "1737759760456.0251";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ExtractSqlsFromCsvMutationRequest,
  ExtractSqlsFromCsvMutationResponse,
  ExtractSqlsFromCsvPathParams,
  ExtractSqlsFromCsv422,
} from '../types/ExtractSqlsFromCsv'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const extractSqlsFromCsvMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/extract_sqls_from_csv' }] as const

export type ExtractSqlsFromCsvMutationKey = ReturnType<typeof extractSqlsFromCsvMutationKey>

/**
 * @description Extract SQLs from uploaded csv file for users to review.We expect the csv at least to have the following column:sqlWe currently only use sql column.
 * @summary Extract Sqls From Csv
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/extract_sqls_from_csv}
 */
async function extractSqlsFromCsv(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: ExtractSqlsFromCsvPathParams['account_name']
    starSchemaDataSourceId: ExtractSqlsFromCsvPathParams['star_schema_data_source_id']
    data: ExtractSqlsFromCsvMutationRequest
  },
  config: Partial<RequestConfig<ExtractSqlsFromCsvMutationRequest>> = {},
) {
  const formData = new FormData()
  if (data) {
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof typeof data]
      if (typeof key === 'string' && (typeof value === 'string' || value instanceof Blob)) {
        formData.append(key, value)
      }
    })
  }
  const res = await client<ExtractSqlsFromCsvMutationResponse, ExtractSqlsFromCsv422, ExtractSqlsFromCsvMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/extract_sqls_from_csv`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data', ...config.headers },
    ...config,
  })
  return res.data
}

/**
 * @description Extract SQLs from uploaded csv file for users to review.We expect the csv at least to have the following column:sqlWe currently only use sql column.
 * @summary Extract Sqls From Csv
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/extract_sqls_from_csv}
 */
export function useExtractSqlsFromCsv(
  options: {
    mutation?: UseMutationOptions<
      ExtractSqlsFromCsvMutationResponse,
      ExtractSqlsFromCsv422,
      {
        accountName: ExtractSqlsFromCsvPathParams['account_name']
        starSchemaDataSourceId: ExtractSqlsFromCsvPathParams['star_schema_data_source_id']
        data: ExtractSqlsFromCsvMutationRequest
      }
    >
    client?: Partial<RequestConfig<ExtractSqlsFromCsvMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? extractSqlsFromCsvMutationKey()

  return useMutation<
    ExtractSqlsFromCsvMutationResponse,
    ExtractSqlsFromCsv422,
    {
      accountName: ExtractSqlsFromCsvPathParams['account_name']
      starSchemaDataSourceId: ExtractSqlsFromCsvPathParams['star_schema_data_source_id']
      data: ExtractSqlsFromCsvMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return extractSqlsFromCsv({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}