// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getUserFeedbackSchema.ts"
);
import.meta.hot.lastModified = "1737935767010.7507";
}
// REMIX HMR END

// @ts-nocheck
import { adminMessageApiResponsePageSchema } from './adminMessageApiResponsePageSchema'
import { bodyGetUserFeedbackApiV3OrgsAccountNameMessagesUserFeedbackPostSchema } from './bodyGetUserFeedbackApiV3OrgsAccountNameMessagesUserFeedbackPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { receivingAgentFilterSchema } from './receivingAgentFilterSchema'
import { z } from 'zod'

export const getUserFeedbackPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

export const getUserFeedbackQueryParamsSchema = z
  .object({
    limit: z.number().int().default(20),
    offset: z.number().int().default(0),
    before_timestamp: z.union([z.string().datetime(), z.null()]).optional(),
    after_timestamp: z.union([z.string().datetime(), z.null()]).optional(),
    receiving_agent_filter: z.lazy(() => receivingAgentFilterSchema).default('all'),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getUserFeedback200Schema = z.lazy(() => adminMessageApiResponsePageSchema)

/**
 * @description Validation Error
 */
export const getUserFeedback422Schema = z.lazy(() => httpValidationErrorSchema)

export const getUserFeedbackMutationRequestSchema = z.lazy(() => bodyGetUserFeedbackApiV3OrgsAccountNameMessagesUserFeedbackPostSchema)

export const getUserFeedbackMutationResponseSchema = z.lazy(() => getUserFeedback200Schema)