// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/validateStarSchemaDataSourceSchema.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceSchema } from './starSchemaDataSourceSchema'
import { z } from 'zod'

export const validateStarSchemaDataSourcePathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const validateStarSchemaDataSource200Schema = z.lazy(() => starSchemaDataSourceSchema)

/**
 * @description Validation Error
 */
export const validateStarSchemaDataSource422Schema = z.lazy(() => httpValidationErrorSchema)

export const validateStarSchemaDataSourceMutationResponseSchema = z.lazy(() => validateStarSchemaDataSource200Schema)