// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/semanticQueryCoalescingStrategySchema.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Coalescing strategy.
 */
export const semanticQueryCoalescingStrategySchema = z.enum(['NONE', 'DM_GROUPING', 'DISPLAY_NAME_GROUPING']).describe('Coalescing strategy.')