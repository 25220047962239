// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetCandidateDemonstrationCells.ts"
);
import.meta.hot.lastModified = "1737935769445.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetCandidateDemonstrationCellsMutationRequest,
  GetCandidateDemonstrationCellsMutationResponse,
  GetCandidateDemonstrationCellsPathParams,
  GetCandidateDemonstrationCellsQueryParams,
  GetCandidateDemonstrationCells422,
} from '../types/GetCandidateDemonstrationCells'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const getCandidateDemonstrationCellsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/candidate_demonstration_cells' }] as const

export type GetCandidateDemonstrationCellsMutationKey = ReturnType<typeof getCandidateDemonstrationCellsMutationKey>

/**
 * @description Get candidate demonstration cells for Star Schema Data Source.NOTE: The returned cells do not contain retrieved dimensions or metrics.
 * @summary Get Candidate Demonstration Cells
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/candidate_demonstration_cells}
 */
async function getCandidateDemonstrationCells(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: GetCandidateDemonstrationCellsPathParams['account_name']
    starSchemaDataSourceId: GetCandidateDemonstrationCellsPathParams['star_schema_data_source_id']
    data?: GetCandidateDemonstrationCellsMutationRequest
    params?: GetCandidateDemonstrationCellsQueryParams
  },
  config: Partial<RequestConfig<GetCandidateDemonstrationCellsMutationRequest>> = {},
) {
  const res = await client<GetCandidateDemonstrationCellsMutationResponse, GetCandidateDemonstrationCells422, GetCandidateDemonstrationCellsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/candidate_demonstration_cells`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Get candidate demonstration cells for Star Schema Data Source.NOTE: The returned cells do not contain retrieved dimensions or metrics.
 * @summary Get Candidate Demonstration Cells
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/candidate_demonstration_cells}
 */
export function useGetCandidateDemonstrationCells(
  options: {
    mutation?: UseMutationOptions<
      GetCandidateDemonstrationCellsMutationResponse,
      GetCandidateDemonstrationCells422,
      {
        accountName: GetCandidateDemonstrationCellsPathParams['account_name']
        starSchemaDataSourceId: GetCandidateDemonstrationCellsPathParams['star_schema_data_source_id']
        data?: GetCandidateDemonstrationCellsMutationRequest
        params?: GetCandidateDemonstrationCellsQueryParams
      }
    >
    client?: Partial<RequestConfig<GetCandidateDemonstrationCellsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? getCandidateDemonstrationCellsMutationKey()

  return useMutation<
    GetCandidateDemonstrationCellsMutationResponse,
    GetCandidateDemonstrationCells422,
    {
      accountName: GetCandidateDemonstrationCellsPathParams['account_name']
      starSchemaDataSourceId: GetCandidateDemonstrationCellsPathParams['star_schema_data_source_id']
      data?: GetCandidateDemonstrationCellsMutationRequest
      params?: GetCandidateDemonstrationCellsQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data, params }) => {
      return getCandidateDemonstrationCells({ accountName, starSchemaDataSourceId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}