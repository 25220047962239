// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/loginSessionOauthSchema.ts"
);
import.meta.hot.lastModified = "1737935767639.7473";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { providerSchema } from './providerSchema'
import { z } from 'zod'

export const loginSessionOauthQueryParamsSchema = z.object({
  provider: z
    .lazy(() => providerSchema)
    .describe(
      "The OAuth provider. At the moment this defaults to 'okta' but this default will be removed in the future. Please specify the provider explicitly to avoid issues.",
    ),
  account_name: z
    .union([z.string(), z.null()])
    .describe(
      'The name of the account to validate that the user is authorized to access. If the user does not have access to this account, the endpoint will return a 403 even if the user is successfully authenticated. This is useful for third party applications which want users to be restricted to a particular account.',
    )
    .optional(),
  redirect_header: z
    .boolean()
    .default(false)
    .describe(
      'Whether to redirect at the HTTP level or not. Browsers set origin=null if a cross-origin resource redirects to yet another origin. This can be problematic for an embedded iframe. Instead, setting redirect to False puts the redirect information in the response body. Then it is up to the client to handle the redirect themselves.',
    ),
  redirect_uri: z
    .union([z.string(), z.null()])
    .describe(
      'The URI to redirect to after the login is complete. This is needed for clients to redirect back to the original page. When OAuth is complete, the user will be redirected to this URI. If the login is successful, a session cookie will be set. If the login is unsuccessful, an error parameter will be set in the redirect_uri query params. If this value is set to None then the redirect_uri will default to https://app.numbersstation.ai/login.',
    )
    .optional(),
  third_party: z.boolean().default(false).describe('Whether to set a third-party cookie for cross-origin iframes.'),
  slack_id: z
    .union([z.string(), z.null()])
    .describe('An optional base64-encoded Slack {team_id}:{user_id} to link to (for use by the NS Slack App).')
    .optional(),
})

/**
 * @description Successful Response
 */
export const loginSessionOauth200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const loginSessionOauth422Schema = z.lazy(() => httpValidationErrorSchema)

export const loginSessionOauthQueryResponseSchema = z.lazy(() => loginSessionOauth200Schema)