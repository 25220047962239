// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/zenMetricSchema.ts"
);
import.meta.hot.lastModified = "1737759757980.0378";
}
// REMIX HMR END

// @ts-nocheck
import { dialectSchema } from './dialectSchema'
import { displayTypeSchema } from './displayTypeSchema'
import { filterCategorySchema } from './filterCategorySchema'
import { originTypeSchema } from './originTypeSchema'
import { publishStateSchema } from './publishStateSchema'
import { timeGranularitySchema } from './timeGranularitySchema'
import { zenDimensionSchema } from './zenDimensionSchema'
import { zenFieldFilterSchema } from './zenFieldFilterSchema'
import { z } from 'zod'

/**
 * @description Metrics layer metric.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/measure
 */
export const zenMetricSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    group_id: z.union([z.string(), z.null()]).optional(),
    name: z.string(),
    field_type: z.enum(['measure']).default('measure'),
    description: z.union([z.string(), z.null()]).optional(),
    sql: z.string(),
    ns_expr: z.union([z.string(), z.null()]).optional(),
    measure: z.union([z.string(), z.null()]).optional(),
    filters: z.union([z.array(z.lazy(() => zenFieldFilterSchema)), z.null()]).optional(),
    canon_date: z.union([z.lazy(() => zenDimensionSchema), z.null()]).optional(),
    window_size: z.union([z.number().int(), z.null()]).optional(),
    window_granularity: z.union([z.lazy(() => timeGranularitySchema), z.null()]).optional(),
    display_name: z.union([z.string(), z.null()]).optional(),
    expr_type: z.lazy(() => filterCategorySchema).default('unknown'),
    state: z.lazy(() => publishStateSchema).default('Verified'),
    display_type: z.lazy(() => displayTypeSchema).default('unknown'),
    data_source_id: z.union([z.string(), z.null()]).optional(),
    is_sensitive: z.boolean().default(false),
    is_literal_sensitive: z.boolean().default(false),
    origin: z.array(z.lazy(() => originTypeSchema)).optional(),
    dialect: z.lazy(() => dialectSchema).default('unknown'),
    popularity: z.number().min(0).max(1).default(1),
    updated_at: z.union([z.string().datetime(), z.null()]).optional(),
    created_at: z.union([z.string().datetime(), z.null()]).optional(),
  })
  .describe('Metrics layer metric.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/measure')