// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatMessageTypeSchema.ts"
);
import.meta.hot.lastModified = "1737759759784.0286";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Chat Message Type.
 */
export const chatMessageTypeSchema = z.enum(['STANDARD', 'SIGNAL', 'EPHEMERAL', 'ERROR']).describe('Chat Message Type.')