// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readTeradataConnectionTablesSchema.ts"
);
import.meta.hot.lastModified = "1737759762294.0156";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readTeradataConnectionTablesPathParamsSchema = z.object({
  account_name: z.string(),
  teradata_connection_id: z.string(),
})

export const readTeradataConnectionTablesQueryParamsSchema = z.object({
  database: z.string().min(1),
  schema: z.union([z.string(), z.null()]).optional(),
})

/**
 * @description Successful Response
 */
export const readTeradataConnectionTables200Schema = z.object({}).catchall(z.object({}).catchall(z.array(z.string())))

/**
 * @description Validation Error
 */
export const readTeradataConnectionTables422Schema = z.lazy(() => httpValidationErrorSchema)

export const readTeradataConnectionTablesQueryResponseSchema = z.lazy(() => readTeradataConnectionTables200Schema)