// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetEntityContext.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetEntityContextQueryResponse,
  GetEntityContextPathParams,
  GetEntityContextQueryParams,
  GetEntityContext404,
  GetEntityContext422,
} from '../types/GetEntityContext'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getEntityContextQueryKey = ({ accountName }: { accountName: GetEntityContextPathParams['account_name'] }, params: GetEntityContextQueryParams) =>
  [{ url: '/api/v3/orgs/:account_name/knowledge_layer/context', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type GetEntityContextQueryKey = ReturnType<typeof getEntityContextQueryKey>

/**
 * @description Get the context associated with an entity.
 * @summary Get Entity Context
 * {@link /api/v3/orgs/:account_name/knowledge_layer/context}
 */
async function getEntityContext(
  { accountName, params }: { accountName: GetEntityContextPathParams['account_name']; params: GetEntityContextQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetEntityContextQueryResponse, GetEntityContext404 | GetEntityContext422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/knowledge_layer/context`,
    params,
    ...config,
  })
  return res.data
}

export function getEntityContextQueryOptions(
  { accountName, params }: { accountName: GetEntityContextPathParams['account_name']; params: GetEntityContextQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getEntityContextQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!(accountName && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getEntityContext({ accountName, params }, config)
    },
  })
}

/**
 * @description Get the context associated with an entity.
 * @summary Get Entity Context
 * {@link /api/v3/orgs/:account_name/knowledge_layer/context}
 */
export function useGetEntityContext<
  TData = GetEntityContextQueryResponse,
  TQueryData = GetEntityContextQueryResponse,
  TQueryKey extends QueryKey = GetEntityContextQueryKey,
>(
  { accountName, params }: { accountName: GetEntityContextPathParams['account_name']; params: GetEntityContextQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetEntityContextQueryResponse, GetEntityContext404 | GetEntityContext422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getEntityContextQueryKey({ accountName }, params)

  const query = useQuery({
    ...(getEntityContextQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetEntityContext404 | GetEntityContext422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}