// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messageUpdateAdminFeedbackApiSchema.ts"
);
import.meta.hot.lastModified = "1737759762337.0154";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Request body to update the message admin feedback.
 */
export const messageUpdateAdminFeedbackApiSchema = z
  .object({
    is_positive_admin_feedback: z
      .union([z.boolean(), z.null()])
      .describe(
        'Whether or not to use this chat to improve system. TRUE for positive feedback, FALSE for negative feedback, or NULL to not to use to improve system.',
      ),
  })
  .describe('Request body to update the message admin feedback.')