// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateNamedFilters.ts"
);
import.meta.hot.lastModified = "1737759760457.0251";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateNamedFiltersMutationRequest,
  UpdateNamedFiltersMutationResponse,
  UpdateNamedFiltersPathParams,
  UpdateNamedFilters422,
} from '../types/UpdateNamedFilters'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateNamedFiltersMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters' }] as const

export type UpdateNamedFiltersMutationKey = ReturnType<typeof updateNamedFiltersMutationKey>

/**
 * @description Bulk update multiple named filters in a single API call.
 * @summary Update Named Filters
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters}
 */
async function updateNamedFilters(
  {
    starSchemaDataSourceId,
    accountName,
    data,
  }: {
    starSchemaDataSourceId: UpdateNamedFiltersPathParams['star_schema_data_source_id']
    accountName: UpdateNamedFiltersPathParams['account_name']
    data?: UpdateNamedFiltersMutationRequest
  },
  config: Partial<RequestConfig<UpdateNamedFiltersMutationRequest>> = {},
) {
  const res = await client<UpdateNamedFiltersMutationResponse, UpdateNamedFilters422, UpdateNamedFiltersMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/filters`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Bulk update multiple named filters in a single API call.
 * @summary Update Named Filters
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters}
 */
export function useUpdateNamedFilters(
  options: {
    mutation?: UseMutationOptions<
      UpdateNamedFiltersMutationResponse,
      UpdateNamedFilters422,
      {
        starSchemaDataSourceId: UpdateNamedFiltersPathParams['star_schema_data_source_id']
        accountName: UpdateNamedFiltersPathParams['account_name']
        data?: UpdateNamedFiltersMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateNamedFiltersMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateNamedFiltersMutationKey()

  return useMutation<
    UpdateNamedFiltersMutationResponse,
    UpdateNamedFilters422,
    {
      starSchemaDataSourceId: UpdateNamedFiltersPathParams['star_schema_data_source_id']
      accountName: UpdateNamedFiltersPathParams['account_name']
      data?: UpdateNamedFiltersMutationRequest
    }
  >({
    mutationFn: async ({ starSchemaDataSourceId, accountName, data }) => {
      return updateNamedFilters({ starSchemaDataSourceId, accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}