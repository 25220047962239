// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createStarSchemaDataSourceSchema.ts"
);
import.meta.hot.lastModified = "1737935769208.7388";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceApiCreateSchema } from './starSchemaDataSourceApiCreateSchema'
import { starSchemaDataSourceSchema } from './starSchemaDataSourceSchema'
import { z } from 'zod'

export const createStarSchemaDataSourcePathParamsSchema = z.object({
  account_name: z.string(),
})

export const createStarSchemaDataSourceQueryParamsSchema = z
  .object({
    update_index: z.boolean().default(true),
    update_profiles: z.boolean().default(true),
    update_expr_types: z.boolean().default(true),
    update_display_types: z.boolean().default(true),
    skip_validation_check: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const createStarSchemaDataSource200Schema = z.lazy(() => starSchemaDataSourceSchema)

/**
 * @description Validation Error
 */
export const createStarSchemaDataSource422Schema = z.lazy(() => httpValidationErrorSchema)

export const createStarSchemaDataSourceMutationRequestSchema = z.lazy(() => starSchemaDataSourceApiCreateSchema)

export const createStarSchemaDataSourceMutationResponseSchema = z.lazy(() => createStarSchemaDataSource200Schema)