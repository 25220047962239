// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/checkFeedbackChatHasAdminFeedbackSchema.ts"
);
import.meta.hot.lastModified = "1737759762545.0144";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const checkFeedbackChatHasAdminFeedbackPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  chat_id: z.string().describe('The ID of the chat.'),
})

/**
 * @description Successful Response
 */
export const checkFeedbackChatHasAdminFeedback200Schema = z.boolean()

/**
 * @description Validation Error
 */
export const checkFeedbackChatHasAdminFeedback422Schema = z.lazy(() => httpValidationErrorSchema)

export const checkFeedbackChatHasAdminFeedbackQueryResponseSchema = z.lazy(() => checkFeedbackChatHasAdminFeedback200Schema)