// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRemapSsdsDms.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RemapSsdsDmsMutationRequest, RemapSsdsDmsMutationResponse, RemapSsdsDmsPathParams, RemapSsdsDms422 } from '../types/RemapSsdsDms'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const remapSsdsDmsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/remap_dms' }] as const

export type RemapSsdsDmsMutationKey = ReturnType<typeof remapSsdsDmsMutationKey>

/**
 * @description Remap all semantic queries to use new dimension/metric names.This does not alter any dimension/metric definitions, but only usage in semantic queries.
 * @summary Remap Ssds Dms
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/remap_dms}
 */
async function remapSsdsDms(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: RemapSsdsDmsPathParams['account_name']
    starSchemaDataSourceId: RemapSsdsDmsPathParams['star_schema_data_source_id']
    data?: RemapSsdsDmsMutationRequest
  },
  config: Partial<RequestConfig<RemapSsdsDmsMutationRequest>> = {},
) {
  const res = await client<RemapSsdsDmsMutationResponse, RemapSsdsDms422, RemapSsdsDmsMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/remap_dms`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Remap all semantic queries to use new dimension/metric names.This does not alter any dimension/metric definitions, but only usage in semantic queries.
 * @summary Remap Ssds Dms
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/remap_dms}
 */
export function useRemapSsdsDms(
  options: {
    mutation?: UseMutationOptions<
      RemapSsdsDmsMutationResponse,
      RemapSsdsDms422,
      {
        accountName: RemapSsdsDmsPathParams['account_name']
        starSchemaDataSourceId: RemapSsdsDmsPathParams['star_schema_data_source_id']
        data?: RemapSsdsDmsMutationRequest
      }
    >
    client?: Partial<RequestConfig<RemapSsdsDmsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? remapSsdsDmsMutationKey()

  return useMutation<
    RemapSsdsDmsMutationResponse,
    RemapSsdsDms422,
    {
      accountName: RemapSsdsDmsPathParams['account_name']
      starSchemaDataSourceId: RemapSsdsDmsPathParams['star_schema_data_source_id']
      data?: RemapSsdsDmsMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return remapSsdsDms({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}