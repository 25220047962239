// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateChatSchema.ts"
);
import.meta.hot.lastModified = "1737759758407.0356";
}
// REMIX HMR END

// @ts-nocheck
import { chatApiResponseSchema } from './chatApiResponseSchema'
import { chatApiUpdateSchema } from './chatApiUpdateSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateChatPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  chat_id: z.string().describe('The ID of the chat.'),
})

/**
 * @description Successful Response
 */
export const updateChat200Schema = z.lazy(() => chatApiResponseSchema)

/**
 * @description Validation Error
 */
export const updateChat422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateChatMutationRequestSchema = z.lazy(() => chatApiUpdateSchema).describe('The updates to the chat object.')

export const updateChatMutationResponseSchema = z.lazy(() => updateChat200Schema)