// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bumblebeeCellSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellDataSourceSchema } from './bumblebeeCellDataSourceSchema'
import { cellFeedbackSchema } from './cellFeedbackSchema'
import { cellStateSchema } from './cellStateSchema'
import { chartConfigSchema } from './chartConfigSchema'
import { moonlightStreamedOutputSchema } from './moonlightStreamedOutputSchema'
import { permissionsSchema } from './permissionsSchema'
import { semanticLayerViewSchema } from './semanticLayerViewSchema'
import { tagAssociationSchema } from './tagAssociationSchema'
import { taskSchema } from './taskSchema'
import { userBasicSchema } from './userBasicSchema'
import { z } from 'zod'

/**
 * @description Bumblebee Cell Schema.
 */
export const bumblebeeCellSchema = z
  .object({
    creator_id: z.string(),
    permissions: z.lazy(() => permissionsSchema).default({}),
    sync_id: z.union([z.string(), z.null()]).optional(),
    conversation_id: z.string(),
    user_question: z.string(),
    updated_question: z.union([z.string(), z.null()]),
    question_variants: z.array(z.string()).optional(),
    index: z.union([z.number().int(), z.null()]).optional(),
    sql: z.union([z.string(), z.null()]).optional(),
    data_summary: z.union([z.string(), z.null()]).optional(),
    name: z.union([z.string(), z.null()]).optional(),
    bb_model: z.union([z.string(), z.null()]).optional(),
    semantic_query_id: z.union([z.string(), z.null()]).optional(),
    ssds_id: z.union([z.string(), z.null()]).optional(),
    explanation: z.union([z.string(), z.null()]).optional(),
    is_holdout: z.union([z.boolean(), z.null()]).optional(),
    has_feedback: z.boolean().default(false),
    is_dm_sensitive: z.boolean().default(false),
    is_user_sensitive: z.boolean().default(false),
    copied_from: z.union([z.string(), z.null()]).optional(),
    is_suggested: z.union([z.boolean(), z.null()]).default(false),
    moonlight_predicted_input_type: z.union([z.number().int(), z.null()]).optional(),
    moonlight_prompt: z.union([z.string(), z.null()]).optional(),
    id: z.string(),
    account_id: z.string(),
    is_stale: z.boolean(),
    state: z.union([z.lazy(() => cellStateSchema), z.null()]).optional(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    creator: z.union([z.lazy(() => userBasicSchema), z.null()]).optional(),
    semantic_layer_view: z.union([z.lazy(() => semanticLayerViewSchema), z.null()]).optional(),
    chart_config: z.union([z.lazy(() => chartConfigSchema), z.null()]).optional(),
    tasks: z.array(z.lazy(() => taskSchema)).optional(),
    tags: z.array(z.lazy(() => tagAssociationSchema)).optional(),
    feedback: z.union([z.lazy(() => cellFeedbackSchema), z.null()]).optional(),
    used_datasources: z.union([z.array(z.lazy(() => bumblebeeCellDataSourceSchema)), z.null()]).optional(),
    moonlight_response: z.union([z.lazy(() => moonlightStreamedOutputSchema), z.null()]).optional(),
    is_sensitive: z.boolean().default(false),
  })
  .describe('Bumblebee Cell Schema.')