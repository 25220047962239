// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useBulkCreateFeedback.ts"
);
import.meta.hot.lastModified = "1737935767656.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  BulkCreateFeedbackMutationRequest,
  BulkCreateFeedbackMutationResponse,
  BulkCreateFeedbackPathParams,
  BulkCreateFeedbackQueryParams,
  BulkCreateFeedback422,
} from '../types/BulkCreateFeedback'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const bulkCreateFeedbackMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_feedback' }] as const

export type BulkCreateFeedbackMutationKey = ReturnType<typeof bulkCreateFeedbackMutationKey>

/**
 * @description Upload feedback to an account.Attempts to create with the same user, but will default to the adminif the user doesn't belong to the account.NOTE: If a user is overwritten by an admin, the admin may be re-used to give feedback,which means only the last feedback issued by the admin will apply.
 * @summary Bulk Create Feedback
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/upload_feedback}
 */
async function bulkCreateFeedback(
  {
    accountName,
    starSchemaDataSourceId,
    params,
    data,
  }: {
    accountName: BulkCreateFeedbackPathParams['account_name']
    starSchemaDataSourceId: BulkCreateFeedbackPathParams['star_schema_data_source_id']
    params: BulkCreateFeedbackQueryParams
    data?: BulkCreateFeedbackMutationRequest
  },
  config: Partial<RequestConfig<BulkCreateFeedbackMutationRequest>> = {},
) {
  const res = await client<BulkCreateFeedbackMutationResponse, BulkCreateFeedback422, BulkCreateFeedbackMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/upload_feedback`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Upload feedback to an account.Attempts to create with the same user, but will default to the adminif the user doesn't belong to the account.NOTE: If a user is overwritten by an admin, the admin may be re-used to give feedback,which means only the last feedback issued by the admin will apply.
 * @summary Bulk Create Feedback
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/upload_feedback}
 */
export function useBulkCreateFeedback(
  options: {
    mutation?: UseMutationOptions<
      BulkCreateFeedbackMutationResponse,
      BulkCreateFeedback422,
      {
        accountName: BulkCreateFeedbackPathParams['account_name']
        starSchemaDataSourceId: BulkCreateFeedbackPathParams['star_schema_data_source_id']
        params: BulkCreateFeedbackQueryParams
        data?: BulkCreateFeedbackMutationRequest
      }
    >
    client?: Partial<RequestConfig<BulkCreateFeedbackMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? bulkCreateFeedbackMutationKey()

  return useMutation<
    BulkCreateFeedbackMutationResponse,
    BulkCreateFeedback422,
    {
      accountName: BulkCreateFeedbackPathParams['account_name']
      starSchemaDataSourceId: BulkCreateFeedbackPathParams['star_schema_data_source_id']
      params: BulkCreateFeedbackQueryParams
      data?: BulkCreateFeedbackMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params, data }) => {
      return bulkCreateFeedback({ accountName, starSchemaDataSourceId, params, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}