// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetMetricExprType.ts"
);
import.meta.hot.lastModified = "1737759760235.0261";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetMetricExprTypeQueryResponse,
  GetMetricExprTypePathParams,
  GetMetricExprTypeQueryParams,
  GetMetricExprType422,
} from '../types/GetMetricExprType'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getMetricExprTypeQueryKey = (
  { accountName }: { accountName: GetMetricExprTypePathParams['account_name'] },
  params: GetMetricExprTypeQueryParams,
) =>
  [
    { url: '/api/v1/accounts/:account_name/star_schema_data_source/metrics/expr_type', params: { accountName: accountName } },
    ...(params ? [params] : []),
  ] as const

export type GetMetricExprTypeQueryKey = ReturnType<typeof getMetricExprTypeQueryKey>

/**
 * @description Get the expr_type of a metric, then check if already exists.Returns:    A 409 if the sql/type/data_source_id combo already exists    The expr_type of the expr if a metric with the given sql doesn't exist
 * @summary Get Metric Expr Type
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/expr_type}
 */
async function getMetricExprType(
  { accountName, params }: { accountName: GetMetricExprTypePathParams['account_name']; params: GetMetricExprTypeQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetMetricExprTypeQueryResponse, GetMetricExprType422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/expr_type`,
    params,
    ...config,
  })
  return res.data
}

export function getMetricExprTypeQueryOptions(
  { accountName, params }: { accountName: GetMetricExprTypePathParams['account_name']; params: GetMetricExprTypeQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getMetricExprTypeQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!(accountName && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getMetricExprType({ accountName, params }, config)
    },
  })
}

/**
 * @description Get the expr_type of a metric, then check if already exists.Returns:    A 409 if the sql/type/data_source_id combo already exists    The expr_type of the expr if a metric with the given sql doesn't exist
 * @summary Get Metric Expr Type
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/expr_type}
 */
export function useGetMetricExprType<
  TData = GetMetricExprTypeQueryResponse,
  TQueryData = GetMetricExprTypeQueryResponse,
  TQueryKey extends QueryKey = GetMetricExprTypeQueryKey,
>(
  { accountName, params }: { accountName: GetMetricExprTypePathParams['account_name']; params: GetMetricExprTypeQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetMetricExprTypeQueryResponse, GetMetricExprType422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getMetricExprTypeQueryKey({ accountName }, params)

  const query = useQuery({
    ...(getMetricExprTypeQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetMetricExprType422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}