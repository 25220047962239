// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatsFilterSchema.ts"
);
import.meta.hot.lastModified = "1737759758412.0356";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Filter chats by.
 */
export const chatsFilterSchema = z.enum(['is_pinned', 'is_by_user', 'is_eval', 'is_feedback', 'all']).describe('Filter chats by.')