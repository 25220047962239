// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/userResponseSchema.ts"
);
import.meta.hot.lastModified = "1737759758958.0327";
}
// REMIX HMR END

// @ts-nocheck
import { userRoleSchema } from './userRoleSchema'
import { z } from 'zod'

/**
 * @description Information about the current user.
 */
export const userResponseSchema = z
  .object({
    id: z.string().describe('The ID of the user.'),
    email: z.string().describe("The user's email address. This is the user's unique identifier."),
    name: z.string().describe("The user's name."),
    account_name: z.string().describe('The name of the account the user is associated with.'),
    role: z.lazy(() => userRoleSchema).describe("The user's role in the account."),
  })
  .describe('Information about the current user.')