// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/SemanticQueryCoalescingStrategy.ts"
);
import.meta.hot.lastModified = "1737759762281.0156";
}
// REMIX HMR END

export enum SemanticQueryCoalescingStrategy {
  'NONE' = 'NONE',
  'DM_GROUPING' = 'DM_GROUPING',
  'DISPLAY_NAME_GROUPING' = 'DISPLAY_NAME_GROUPING',
}