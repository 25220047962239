// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/copyJobSchema.ts"
);
import.meta.hot.lastModified = "1737759757712.0393";
}
// REMIX HMR END

// @ts-nocheck
import { conversationCellFilterSchema } from './conversationCellFilterSchema'
import { copyReportSchema } from './copyReportSchema'
import { datasetJobTypeSchema } from './datasetJobTypeSchema'
import { dimensionToReviewSchema } from './dimensionToReviewSchema'
import { metricToReviewSchema } from './metricToReviewSchema'
import { tableOrViewInfoSchema } from './tableOrViewInfoSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

/**
 * @description Properties to return to client.
 */
export const copyJobSchema = z
  .object({
    enterprise_id: z.string(),
    type: z.lazy(() => datasetJobTypeSchema).default('COPY'),
    src_account_name: z.union([z.string(), z.null()]).optional(),
    src_ssds_id: z.union([z.string(), z.null()]),
    dst_connection_id: z.union([z.string(), z.null()]).optional(),
    dst_account_name: z.union([z.string(), z.null()]).optional(),
    dst_ssds_name: z.union([z.string(), z.null()]).optional(),
    conversation_cell_filter: z.lazy(() => conversationCellFilterSchema).default('ALL'),
    overwrite_user_question_for_sensitive_cell: z.boolean().default(false),
    max_eval_messages: z.union([z.number().int(), z.null()]).optional(),
    id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    tasks: z.array(z.lazy(() => taskSchema)).optional(),
    tables_and_views: z.union([z.array(z.lazy(() => tableOrViewInfoSchema)), z.null()]).optional(),
    dst_ssds_id: z.union([z.string(), z.null()]).optional(),
    sensitive_dimensions: z.array(z.lazy(() => dimensionToReviewSchema)).optional(),
    sensitive_metrics: z.array(z.lazy(() => metricToReviewSchema)).optional(),
    report: z.union([z.lazy(() => copyReportSchema), z.null()]).optional(),
  })
  .describe('Properties to return to client.')