// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/metricFilterSchema.ts"
);
import.meta.hot.lastModified = "1737759758724.034";
}
// REMIX HMR END

// @ts-nocheck
import { combinationSchema } from './combinationSchema'
import { filterOriginSchema } from './filterOriginSchema'
import { metricFilterConditionSchema } from './metricFilterConditionSchema'
import { z } from 'zod'

/**
 * @description Filter operating over metrics.
 */
export const metricFilterSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    query_id: z.union([z.string(), z.null()]).optional(),
    name: z.union([z.string(), z.null()]).optional(),
    combination: z.lazy(() => combinationSchema).default('ALL'),
    description: z.union([z.string(), z.null()]).optional(),
    filter_origin: z.lazy(() => filterOriginSchema).default('AI'),
    is_enabled: z.boolean().default(true),
    field_type: z.enum(['metric']).default('metric'),
    conditions: z.array(z.lazy(() => metricFilterConditionSchema)),
  })
  .describe('Filter operating over metrics.')