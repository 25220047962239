// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateDataSourceSchema.ts"
);
import.meta.hot.lastModified = "1737759760211.0264";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceSchema } from './starSchemaDataSourceSchema'
import { starSchemaDataSourceTableOrViewInfoSchema } from './starSchemaDataSourceTableOrViewInfoSchema'
import { z } from 'zod'

export const updateDataSourcePathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
  data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const updateDataSource200Schema = z.lazy(() => starSchemaDataSourceSchema)

/**
 * @description Validation Error
 */
export const updateDataSource422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateDataSourceMutationRequestSchema = z.lazy(() => starSchemaDataSourceTableOrViewInfoSchema)

export const updateDataSourceMutationResponseSchema = z.lazy(() => updateDataSource200Schema)