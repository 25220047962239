// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getCandidateDemonstrationCellsSchema.ts"
);
import.meta.hot.lastModified = "1737759762506.0144";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellBasicSchema } from './bumblebeeCellBasicSchema'
import { cellOrderBySchema } from './cellOrderBySchema'
import { feedbackFilterSchema } from './feedbackFilterSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getCandidateDemonstrationCellsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const getCandidateDemonstrationCellsQueryParamsSchema = z
  .object({
    skip: z.number().int().default(0),
    limit: z.union([z.number().int(), z.null()]).default(100),
    order_by: z.lazy(() => cellOrderBySchema).default('created_at'),
    descending: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getCandidateDemonstrationCells200Schema = z.array(z.lazy(() => bumblebeeCellBasicSchema))

/**
 * @description Validation Error
 */
export const getCandidateDemonstrationCells422Schema = z.lazy(() => httpValidationErrorSchema)

export const getCandidateDemonstrationCellsMutationRequestSchema = z.union([z.lazy(() => feedbackFilterSchema), z.null()])

export const getCandidateDemonstrationCellsMutationResponseSchema = z.lazy(() => getCandidateDemonstrationCells200Schema)