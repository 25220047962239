// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyInjectNewDimensionsAndMetricsApiV1AccountsAccountNameStarSchemaDataSourceInjectNewDimensionsAndMetricsPostSchema.ts"
);
import.meta.hot.lastModified = "1737935769735.7358";
}
// REMIX HMR END

// @ts-nocheck
import { starSchemaDataSourceEnrichmentSchema } from './starSchemaDataSourceEnrichmentSchema'
import { z } from 'zod'

export const bodyInjectNewDimensionsAndMetricsApiV1AccountsAccountNameStarSchemaDataSourceInjectNewDimensionsAndMetricsPostSchema = z.object({
  dimension_metric_validation_ids: z.array(z.string()),
  discard_dimension_metric_validation_ids: z.union([z.array(z.string()), z.null()]).optional(),
  enrich_params: z.union([z.lazy(() => starSchemaDataSourceEnrichmentSchema), z.null()]).optional(),
})