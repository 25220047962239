// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/connectionPageSchema.ts"
);
import.meta.hot.lastModified = "1737759759419.0305";
}
// REMIX HMR END

// @ts-nocheck
import { connectionSchema } from './connectionSchema'
import { z } from 'zod'

/**
 * @description Paginated Connection response.
 */
export const connectionPageSchema = z
  .object({
    connections: z.array(z.lazy(() => connectionSchema)),
    total: z.number().int(),
  })
  .describe('Paginated Connection response.')