// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateTableauConnection.ts"
);
import.meta.hot.lastModified = "1737759761466.0198";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateTableauConnectionMutationRequest,
  CreateTableauConnectionMutationResponse,
  CreateTableauConnectionPathParams,
  CreateTableauConnection422,
} from '../types/CreateTableauConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createTableauConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/tableau/' }] as const

export type CreateTableauConnectionMutationKey = ReturnType<typeof createTableauConnectionMutationKey>

/**
 * @description Create new tableau connection.
 * @summary Create Tableau Connection
 * {@link /api/v1/accounts/:account_name/connections/tableau/}
 */
async function createTableauConnection(
  { accountName, data }: { accountName: CreateTableauConnectionPathParams['account_name']; data: CreateTableauConnectionMutationRequest },
  config: Partial<RequestConfig<CreateTableauConnectionMutationRequest>> = {},
) {
  const res = await client<CreateTableauConnectionMutationResponse, CreateTableauConnection422, CreateTableauConnectionMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/tableau/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create new tableau connection.
 * @summary Create Tableau Connection
 * {@link /api/v1/accounts/:account_name/connections/tableau/}
 */
export function useCreateTableauConnection(
  options: {
    mutation?: UseMutationOptions<
      CreateTableauConnectionMutationResponse,
      CreateTableauConnection422,
      { accountName: CreateTableauConnectionPathParams['account_name']; data: CreateTableauConnectionMutationRequest }
    >
    client?: Partial<RequestConfig<CreateTableauConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createTableauConnectionMutationKey()

  return useMutation<
    CreateTableauConnectionMutationResponse,
    CreateTableauConnection422,
    { accountName: CreateTableauConnectionPathParams['account_name']; data: CreateTableauConnectionMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return createTableauConnection({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}