// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getEnterpriseUsersSchema.ts"
);
import.meta.hot.lastModified = "1737935767878.746";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { userSchema } from './userSchema'
import { z } from 'zod'

export const getEnterpriseUsersPathParamsSchema = z.object({
  enterprise_name: z.string(),
})

export const getEnterpriseUsersQueryParamsSchema = z
  .object({
    skip: z.number().int().default(0),
    limit: z.number().int().default(100),
    sort_by: z.enum(['name', 'created_at', 'updated_at']).default('name'),
    sort_ascending: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getEnterpriseUsers200Schema = z.array(z.lazy(() => userSchema))

/**
 * @description Validation Error
 */
export const getEnterpriseUsers422Schema = z.lazy(() => httpValidationErrorSchema)

export const getEnterpriseUsersQueryResponseSchema = z.lazy(() => getEnterpriseUsers200Schema)