// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadStarSchemaDataSource.ts"
);
import.meta.hot.lastModified = "1737759761669.0188";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadStarSchemaDataSourceQueryResponse,
  ReadStarSchemaDataSourcePathParams,
  ReadStarSchemaDataSourceQueryParams,
  ReadStarSchemaDataSource422,
} from '../types/ReadStarSchemaDataSource'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readStarSchemaDataSourceQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: ReadStarSchemaDataSourcePathParams['account_name']
    starSchemaDataSourceId: ReadStarSchemaDataSourcePathParams['star_schema_data_source_id']
  },
  params?: ReadStarSchemaDataSourceQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadStarSchemaDataSourceQueryKey = ReturnType<typeof readStarSchemaDataSourceQueryKey>

/**
 * @description Read Star Schema Data Source.
 * @summary Read Star Schema Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id}
 */
async function readStarSchemaDataSource(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: ReadStarSchemaDataSourcePathParams['account_name']
    starSchemaDataSourceId: ReadStarSchemaDataSourcePathParams['star_schema_data_source_id']
    params?: ReadStarSchemaDataSourceQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadStarSchemaDataSourceQueryResponse, ReadStarSchemaDataSource422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}`,
    params,
    ...config,
  })
  return res.data
}

export function readStarSchemaDataSourceQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: ReadStarSchemaDataSourcePathParams['account_name']
    starSchemaDataSourceId: ReadStarSchemaDataSourcePathParams['star_schema_data_source_id']
    params?: ReadStarSchemaDataSourceQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readStarSchemaDataSourceQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readStarSchemaDataSource({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Read Star Schema Data Source.
 * @summary Read Star Schema Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id}
 */
export function useReadStarSchemaDataSource<
  TData = ReadStarSchemaDataSourceQueryResponse,
  TQueryData = ReadStarSchemaDataSourceQueryResponse,
  TQueryKey extends QueryKey = ReadStarSchemaDataSourceQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: ReadStarSchemaDataSourcePathParams['account_name']
    starSchemaDataSourceId: ReadStarSchemaDataSourcePathParams['star_schema_data_source_id']
    params?: ReadStarSchemaDataSourceQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadStarSchemaDataSourceQueryResponse, ReadStarSchemaDataSource422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readStarSchemaDataSourceQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(readStarSchemaDataSourceQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadStarSchemaDataSource422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}