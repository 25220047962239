// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getSimilarSsdsFeedbackSchema.ts"
);
import.meta.hot.lastModified = "1737759761449.02";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellBasicSchema } from './bumblebeeCellBasicSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getSimilarSsdsFeedbackPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const getSimilarSsdsFeedbackQueryParamsSchema = z
  .object({
    max_cosine_distance: z.number().default(0.01),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getSimilarSsdsFeedback200Schema = z.array(z.array(z.lazy(() => bumblebeeCellBasicSchema)))

/**
 * @description Validation Error
 */
export const getSimilarSsdsFeedback422Schema = z.lazy(() => httpValidationErrorSchema)

export const getSimilarSsdsFeedbackQueryResponseSchema = z.lazy(() => getSimilarSsdsFeedback200Schema)