// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateBigqueryConnection.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateBigqueryConnectionMutationRequest,
  CreateBigqueryConnectionMutationResponse,
  CreateBigqueryConnectionPathParams,
  CreateBigqueryConnectionQueryParams,
  CreateBigqueryConnection422,
} from '../types/CreateBigqueryConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createBigqueryConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/bigquery' }] as const

export type CreateBigqueryConnectionMutationKey = ReturnType<typeof createBigqueryConnectionMutationKey>

/**
 * @description Create a new BigQuery connection.
 * @summary Create Bigquery Connection
 * {@link /api/v1/accounts/:account_name/connections/bigquery}
 */
async function createBigqueryConnection(
  {
    accountName,
    data,
    params,
  }: {
    accountName: CreateBigqueryConnectionPathParams['account_name']
    data: CreateBigqueryConnectionMutationRequest
    params?: CreateBigqueryConnectionQueryParams
  },
  config: Partial<RequestConfig<CreateBigqueryConnectionMutationRequest>> = {},
) {
  const res = await client<CreateBigqueryConnectionMutationResponse, CreateBigqueryConnection422, CreateBigqueryConnectionMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/bigquery`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a new BigQuery connection.
 * @summary Create Bigquery Connection
 * {@link /api/v1/accounts/:account_name/connections/bigquery}
 */
export function useCreateBigqueryConnection(
  options: {
    mutation?: UseMutationOptions<
      CreateBigqueryConnectionMutationResponse,
      CreateBigqueryConnection422,
      {
        accountName: CreateBigqueryConnectionPathParams['account_name']
        data: CreateBigqueryConnectionMutationRequest
        params?: CreateBigqueryConnectionQueryParams
      }
    >
    client?: Partial<RequestConfig<CreateBigqueryConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createBigqueryConnectionMutationKey()

  return useMutation<
    CreateBigqueryConnectionMutationResponse,
    CreateBigqueryConnection422,
    {
      accountName: CreateBigqueryConnectionPathParams['account_name']
      data: CreateBigqueryConnectionMutationRequest
      params?: CreateBigqueryConnectionQueryParams
    }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return createBigqueryConnection({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}