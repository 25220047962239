// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createCopyJobSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { copyJobCreateSchema } from './copyJobCreateSchema'
import { copyJobSchema } from './copyJobSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const createCopyJobPathParamsSchema = z.object({
  enterprise_name: z.string(),
})

/**
 * @description Successful Response
 */
export const createCopyJob201Schema = z.lazy(() => copyJobSchema)

/**
 * @description Validation Error
 */
export const createCopyJob422Schema = z.lazy(() => httpValidationErrorSchema)

export const createCopyJobMutationRequestSchema = z.lazy(() => copyJobCreateSchema)

export const createCopyJobMutationResponseSchema = z.lazy(() => createCopyJob201Schema)