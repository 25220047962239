// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readUsersSchema.ts"
);
import.meta.hot.lastModified = "1737935765118.7612";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { userSchema } from './userSchema'
import { z } from 'zod'

export const readUsersQueryParamsSchema = z
  .object({
    skip: z.number().int().default(0),
    limit: z.number().int().default(100),
    email: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readUsers200Schema = z.array(z.lazy(() => userSchema))

/**
 * @description Validation Error
 */
export const readUsers422Schema = z.lazy(() => httpValidationErrorSchema)

export const readUsersQueryResponseSchema = z.lazy(() => readUsers200Schema)