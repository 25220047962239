// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tableLocationSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { connectionPolyTypeSchema } from './connectionPolyTypeSchema'
import { z } from 'zod'

/**
 * @description The location of a table in a warehouse.
 */
export const tableLocationSchema = z
  .object({
    connection_id: z.string(),
    ns_database: z.string(),
    ns_schema: z.string(),
    table: z.string(),
    type: z.lazy(() => connectionPolyTypeSchema),
  })
  .describe('The location of a table in a warehouse.')