// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadTeradataConnectionTables.ts"
);
import.meta.hot.lastModified = "1737935769431.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadTeradataConnectionTablesQueryResponse,
  ReadTeradataConnectionTablesPathParams,
  ReadTeradataConnectionTablesQueryParams,
  ReadTeradataConnectionTables422,
} from '../types/ReadTeradataConnectionTables'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readTeradataConnectionTablesQueryKey = (
  {
    accountName,
    teradataConnectionId,
  }: {
    accountName: ReadTeradataConnectionTablesPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionTablesPathParams['teradata_connection_id']
  },
  params: ReadTeradataConnectionTablesQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id/tables',
      params: { accountName: accountName, teradataConnectionId: teradataConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadTeradataConnectionTablesQueryKey = ReturnType<typeof readTeradataConnectionTablesQueryKey>

/**
 * @description Read teradata tables.
 * @summary Read Teradata Connection Tables
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id/tables}
 */
async function readTeradataConnectionTables(
  {
    accountName,
    teradataConnectionId,
    params,
  }: {
    accountName: ReadTeradataConnectionTablesPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionTablesPathParams['teradata_connection_id']
    params: ReadTeradataConnectionTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadTeradataConnectionTablesQueryResponse, ReadTeradataConnectionTables422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/teradata/${teradataConnectionId}/tables`,
    params,
    ...config,
  })
  return res.data
}

export function readTeradataConnectionTablesQueryOptions(
  {
    accountName,
    teradataConnectionId,
    params,
  }: {
    accountName: ReadTeradataConnectionTablesPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionTablesPathParams['teradata_connection_id']
    params: ReadTeradataConnectionTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readTeradataConnectionTablesQueryKey({ accountName, teradataConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && teradataConnectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readTeradataConnectionTables({ accountName, teradataConnectionId, params }, config)
    },
  })
}

/**
 * @description Read teradata tables.
 * @summary Read Teradata Connection Tables
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id/tables}
 */
export function useReadTeradataConnectionTables<
  TData = ReadTeradataConnectionTablesQueryResponse,
  TQueryData = ReadTeradataConnectionTablesQueryResponse,
  TQueryKey extends QueryKey = ReadTeradataConnectionTablesQueryKey,
>(
  {
    accountName,
    teradataConnectionId,
    params,
  }: {
    accountName: ReadTeradataConnectionTablesPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionTablesPathParams['teradata_connection_id']
    params: ReadTeradataConnectionTablesQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadTeradataConnectionTablesQueryResponse, ReadTeradataConnectionTables422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readTeradataConnectionTablesQueryKey({ accountName, teradataConnectionId }, params)

  const query = useQuery({
    ...(readTeradataConnectionTablesQueryOptions({ accountName, teradataConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadTeradataConnectionTables422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}