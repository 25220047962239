// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ChatsSort.ts"
);
import.meta.hot.lastModified = "1737759757506.0403";
}
// REMIX HMR END

export enum ChatsSort {
  'created_at' = 'created_at',
  'last_modified_at' = 'last_modified_at',
  'creator_name' = 'creator_name',
  'name' = 'name',
}