// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateDimensionsSchema.ts"
);
import.meta.hot.lastModified = "1737759760209.0264";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionDetailSchema } from './dimensionDetailSchema'
import { dimensionUpdateWithIdSchema } from './dimensionUpdateWithIdSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateDimensionsPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const updateDimensions200Schema = z.array(z.lazy(() => dimensionDetailSchema))

/**
 * @description Validation Error
 */
export const updateDimensions422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateDimensionsMutationRequestSchema = z.array(z.lazy(() => dimensionUpdateWithIdSchema))

export const updateDimensionsMutationResponseSchema = z.lazy(() => updateDimensions200Schema)