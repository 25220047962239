// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useValidateDatabricksConnection.ts"
);
import.meta.hot.lastModified = "1737759762293.0156";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ValidateDatabricksConnectionMutationResponse,
  ValidateDatabricksConnectionPathParams,
  ValidateDatabricksConnection422,
} from '../types/ValidateDatabricksConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const validateDatabricksConnectionMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/validate' }] as const

export type ValidateDatabricksConnectionMutationKey = ReturnType<typeof validateDatabricksConnectionMutationKey>

/**
 * @description Validate Databricks connection.
 * @summary Validate Databricks Connection
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/validate}
 */
async function validateDatabricksConnection(
  {
    accountName,
    databricksConnectionId,
  }: {
    accountName: ValidateDatabricksConnectionPathParams['account_name']
    databricksConnectionId: ValidateDatabricksConnectionPathParams['databricks_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ValidateDatabricksConnectionMutationResponse, ValidateDatabricksConnection422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/databricks/${databricksConnectionId}/validate`,
    ...config,
  })
  return res.data
}

/**
 * @description Validate Databricks connection.
 * @summary Validate Databricks Connection
 * {@link /api/v1/accounts/:account_name/connections/databricks/:databricks_connection_id/validate}
 */
export function useValidateDatabricksConnection(
  options: {
    mutation?: UseMutationOptions<
      ValidateDatabricksConnectionMutationResponse,
      ValidateDatabricksConnection422,
      {
        accountName: ValidateDatabricksConnectionPathParams['account_name']
        databricksConnectionId: ValidateDatabricksConnectionPathParams['databricks_connection_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? validateDatabricksConnectionMutationKey()

  return useMutation<
    ValidateDatabricksConnectionMutationResponse,
    ValidateDatabricksConnection422,
    {
      accountName: ValidateDatabricksConnectionPathParams['account_name']
      databricksConnectionId: ValidateDatabricksConnectionPathParams['databricks_connection_id']
    }
  >({
    mutationFn: async ({ accountName, databricksConnectionId }) => {
      return validateDatabricksConnection({ accountName, databricksConnectionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}