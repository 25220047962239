// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/systemTagSchema.ts"
);
import.meta.hot.lastModified = "1737759757969.0378";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description System tag filter.
 */
export const systemTagSchema = z
  .enum(['First prompt', 'Follow up', 'AI generated', 'Verified', 'Pending assistance', 'Empty query', 'Sensitive'])
  .describe('System tag filter.')