// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsGroupedDimensionsCount.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetSsdsGroupedDimensionsCountQueryResponse,
  GetSsdsGroupedDimensionsCountPathParams,
  GetSsdsGroupedDimensionsCountQueryParams,
  GetSsdsGroupedDimensionsCount422,
} from '../types/GetSsdsGroupedDimensionsCount'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsGroupedDimensionsCountQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetSsdsGroupedDimensionsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedDimensionsCountPathParams['star_schema_data_source_id']
  },
  params?: GetSsdsGroupedDimensionsCountQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_dimensions_count',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetSsdsGroupedDimensionsCountQueryKey = ReturnType<typeof getSsdsGroupedDimensionsCountQueryKey>

/**
 * @description Get the count of Dimensions for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are counted.
 * @summary Get Ssds Grouped Dimensions Count
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_dimensions_count}
 */
async function getSsdsGroupedDimensionsCount(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedDimensionsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedDimensionsCountPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedDimensionsCountQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsGroupedDimensionsCountQueryResponse, GetSsdsGroupedDimensionsCount422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/grouped_dimensions_count`,
    params,
    ...config,
  })
  return res.data
}

export function getSsdsGroupedDimensionsCountQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedDimensionsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedDimensionsCountPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedDimensionsCountQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsGroupedDimensionsCountQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsGroupedDimensionsCount({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get the count of Dimensions for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are counted.
 * @summary Get Ssds Grouped Dimensions Count
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_dimensions_count}
 */
export function useGetSsdsGroupedDimensionsCount<
  TData = GetSsdsGroupedDimensionsCountQueryResponse,
  TQueryData = GetSsdsGroupedDimensionsCountQueryResponse,
  TQueryKey extends QueryKey = GetSsdsGroupedDimensionsCountQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedDimensionsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedDimensionsCountPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedDimensionsCountQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsGroupedDimensionsCountQueryResponse, GetSsdsGroupedDimensionsCount422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsGroupedDimensionsCountQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getSsdsGroupedDimensionsCountQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsGroupedDimensionsCount422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}