// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/KnowledgeStateFilter.ts"
);
import.meta.hot.lastModified = "1737935767415.7485";
}
// REMIX HMR END

export enum KnowledgeStateFilter {
  'all' = 'all',
  'deprecated' = 'deprecated',
  'verified' = 'verified',
  'unset' = 'unset',
  'invalid' = 'invalid',
  'review' = 'review',
  'valid' = 'valid',
  'sensitive' = 'sensitive',
}