// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readDatasetSchema.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeDatasetSchema } from './bumblebeeDatasetSchema'
import { conversationCellFilterSchema } from './conversationCellFilterSchema'
import { dmFilterSchema } from './dmFilterSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readDatasetPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const readDatasetQueryParamsSchema = z
  .object({
    full_dataset: z.boolean().default(true),
    dm_filter: z.lazy(() => dmFilterSchema).default('ALL'),
    conversation_cell_filter: z.lazy(() => conversationCellFilterSchema).default('ALL'),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readDataset200Schema = z.lazy(() => bumblebeeDatasetSchema)

/**
 * @description Validation Error
 */
export const readDataset422Schema = z.lazy(() => httpValidationErrorSchema)

export const readDatasetQueryResponseSchema = z.lazy(() => readDataset200Schema)