// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsGroupedMetricsCount.ts"
);
import.meta.hot.lastModified = "1737935769208.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetSsdsGroupedMetricsCountQueryResponse,
  GetSsdsGroupedMetricsCountPathParams,
  GetSsdsGroupedMetricsCountQueryParams,
  GetSsdsGroupedMetricsCount422,
} from '../types/GetSsdsGroupedMetricsCount'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsGroupedMetricsCountQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetSsdsGroupedMetricsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedMetricsCountPathParams['star_schema_data_source_id']
  },
  params?: GetSsdsGroupedMetricsCountQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_metrics_count',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetSsdsGroupedMetricsCountQueryKey = ReturnType<typeof getSsdsGroupedMetricsCountQueryKey>

/**
 * @description Get the count of metrics for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are counted.
 * @summary Get Ssds Grouped Metrics Count
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_metrics_count}
 */
async function getSsdsGroupedMetricsCount(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedMetricsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedMetricsCountPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedMetricsCountQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsGroupedMetricsCountQueryResponse, GetSsdsGroupedMetricsCount422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/grouped_metrics_count`,
    params,
    ...config,
  })
  return res.data
}

export function getSsdsGroupedMetricsCountQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedMetricsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedMetricsCountPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedMetricsCountQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsGroupedMetricsCountQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsGroupedMetricsCount({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get the count of metrics for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are counted.
 * @summary Get Ssds Grouped Metrics Count
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_metrics_count}
 */
export function useGetSsdsGroupedMetricsCount<
  TData = GetSsdsGroupedMetricsCountQueryResponse,
  TQueryData = GetSsdsGroupedMetricsCountQueryResponse,
  TQueryKey extends QueryKey = GetSsdsGroupedMetricsCountQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedMetricsCountPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedMetricsCountPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedMetricsCountQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsGroupedMetricsCountQueryResponse, GetSsdsGroupedMetricsCount422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsGroupedMetricsCountQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getSsdsGroupedMetricsCountQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsGroupedMetricsCount422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}