// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDimensionIsSensitiveImpact.ts"
);
import.meta.hot.lastModified = "1737935769655.7363";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateDimensionIsSensitiveImpactMutationResponse,
  UpdateDimensionIsSensitiveImpactPathParams,
  UpdateDimensionIsSensitiveImpactQueryParams,
  UpdateDimensionIsSensitiveImpact422,
} from '../types/UpdateDimensionIsSensitiveImpact'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDimensionIsSensitiveImpactMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/is_sensitive_impact' }] as const

export type UpdateDimensionIsSensitiveImpactMutationKey = ReturnType<typeof updateDimensionIsSensitiveImpactMutationKey>

/**
 * @description Compute the impact of updating a dimension for is_sensitive.
 * @summary Update Dimension Is Sensitive Impact
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/is_sensitive_impact}
 */
async function updateDimensionIsSensitiveImpact(
  {
    accountName,
    dimensionId,
    params,
  }: {
    accountName: UpdateDimensionIsSensitiveImpactPathParams['account_name']
    dimensionId: UpdateDimensionIsSensitiveImpactPathParams['dimension_id']
    params: UpdateDimensionIsSensitiveImpactQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateDimensionIsSensitiveImpactMutationResponse, UpdateDimensionIsSensitiveImpact422, unknown>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions/${dimensionId}/is_sensitive_impact`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Compute the impact of updating a dimension for is_sensitive.
 * @summary Update Dimension Is Sensitive Impact
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/is_sensitive_impact}
 */
export function useUpdateDimensionIsSensitiveImpact(
  options: {
    mutation?: UseMutationOptions<
      UpdateDimensionIsSensitiveImpactMutationResponse,
      UpdateDimensionIsSensitiveImpact422,
      {
        accountName: UpdateDimensionIsSensitiveImpactPathParams['account_name']
        dimensionId: UpdateDimensionIsSensitiveImpactPathParams['dimension_id']
        params: UpdateDimensionIsSensitiveImpactQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDimensionIsSensitiveImpactMutationKey()

  return useMutation<
    UpdateDimensionIsSensitiveImpactMutationResponse,
    UpdateDimensionIsSensitiveImpact422,
    {
      accountName: UpdateDimensionIsSensitiveImpactPathParams['account_name']
      dimensionId: UpdateDimensionIsSensitiveImpactPathParams['dimension_id']
      params: UpdateDimensionIsSensitiveImpactQueryParams
    }
  >({
    mutationFn: async ({ accountName, dimensionId, params }) => {
      return updateDimensionIsSensitiveImpact({ accountName, dimensionId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}