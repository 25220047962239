// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/KnowledgeLayerEntityType.ts"
);
import.meta.hot.lastModified = "1737935753984.8223";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Knowledge layer entity type.
 */
export enum KnowledgeLayerEntityType {
  DIMENSION = 'dimension',
  METRIC = 'metric',
  DATASET = 'dataset',
  TABLEAU = 'tableau',
  MODE = 'mode',
}
