// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/conversationApiStartSchema.ts"
);
import.meta.hot.lastModified = "1737759761027.0222";
}
// REMIX HMR END

// @ts-nocheck
import { messagePayloadTypeSchema } from './messagePayloadTypeSchema'
import { z } from 'zod'

/**
 * @description Payload for starting a conversation with the API\n\nNote that this is the same as MessageAPISend, but it is a separate class to make\nthe API more clear.
 */
export const conversationApiStartSchema = z
  .object({
    message_text: z.string(),
    ssds_id: z.string(),
    accepted_payload_types: z.union([z.array(z.lazy(() => messagePayloadTypeSchema)), z.null()]).optional(),
  })
  .describe(
    'Payload for starting a conversation with the API\n\nNote that this is the same as MessageAPISend, but it is a separate class to make\nthe API more clear.',
  )