// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateAccountSuperuserSettings.ts"
);
import.meta.hot.lastModified = "1737935769440.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateAccountSuperuserSettingsMutationRequest,
  UpdateAccountSuperuserSettingsMutationResponse,
  UpdateAccountSuperuserSettingsPathParams,
  UpdateAccountSuperuserSettings422,
} from '../types/UpdateAccountSuperuserSettings'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateAccountSuperuserSettingsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/superuser-settings' }] as const

export type UpdateAccountSuperuserSettingsMutationKey = ReturnType<typeof updateAccountSuperuserSettingsMutationKey>

/**
 * @description Update superuser settings for an account.Superuser settings take precedence over regular account settings.
 * @summary Update Account Superuser Settings
 * {@link /api/v1/accounts/:account_name/superuser-settings}
 */
async function updateAccountSuperuserSettings(
  { accountName, data }: { accountName: UpdateAccountSuperuserSettingsPathParams['account_name']; data?: UpdateAccountSuperuserSettingsMutationRequest },
  config: Partial<RequestConfig<UpdateAccountSuperuserSettingsMutationRequest>> = {},
) {
  const res = await client<UpdateAccountSuperuserSettingsMutationResponse, UpdateAccountSuperuserSettings422, UpdateAccountSuperuserSettingsMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/superuser-settings`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update superuser settings for an account.Superuser settings take precedence over regular account settings.
 * @summary Update Account Superuser Settings
 * {@link /api/v1/accounts/:account_name/superuser-settings}
 */
export function useUpdateAccountSuperuserSettings(
  options: {
    mutation?: UseMutationOptions<
      UpdateAccountSuperuserSettingsMutationResponse,
      UpdateAccountSuperuserSettings422,
      { accountName: UpdateAccountSuperuserSettingsPathParams['account_name']; data?: UpdateAccountSuperuserSettingsMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateAccountSuperuserSettingsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateAccountSuperuserSettingsMutationKey()

  return useMutation<
    UpdateAccountSuperuserSettingsMutationResponse,
    UpdateAccountSuperuserSettings422,
    { accountName: UpdateAccountSuperuserSettingsPathParams['account_name']; data?: UpdateAccountSuperuserSettingsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return updateAccountSuperuserSettings({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}