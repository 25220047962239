// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadSqlValidationsBySsds.ts"
);
import.meta.hot.lastModified = "1737935768806.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadSqlValidationsBySsdsQueryResponse,
  ReadSqlValidationsBySsdsPathParams,
  ReadSqlValidationsBySsdsQueryParams,
  ReadSqlValidationsBySsds422,
} from '../types/ReadSqlValidationsBySsds'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readSqlValidationsBySsdsQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: ReadSqlValidationsBySsdsPathParams['account_name']
    starSchemaDataSourceId: ReadSqlValidationsBySsdsPathParams['star_schema_data_source_id']
  },
  params?: ReadSqlValidationsBySsdsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sql_validation',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadSqlValidationsBySsdsQueryKey = ReturnType<typeof readSqlValidationsBySsdsQueryKey>

/**
 * @description Get the learned DMs from an SSDS.
 * @summary Read Sql Validations By Ssds
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sql_validation}
 */
async function readSqlValidationsBySsds(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: ReadSqlValidationsBySsdsPathParams['account_name']
    starSchemaDataSourceId: ReadSqlValidationsBySsdsPathParams['star_schema_data_source_id']
    params?: ReadSqlValidationsBySsdsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadSqlValidationsBySsdsQueryResponse, ReadSqlValidationsBySsds422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/sql_validation`,
    params,
    ...config,
  })
  return res.data
}

export function readSqlValidationsBySsdsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: ReadSqlValidationsBySsdsPathParams['account_name']
    starSchemaDataSourceId: ReadSqlValidationsBySsdsPathParams['star_schema_data_source_id']
    params?: ReadSqlValidationsBySsdsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readSqlValidationsBySsdsQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readSqlValidationsBySsds({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get the learned DMs from an SSDS.
 * @summary Read Sql Validations By Ssds
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sql_validation}
 */
export function useReadSqlValidationsBySsds<
  TData = ReadSqlValidationsBySsdsQueryResponse,
  TQueryData = ReadSqlValidationsBySsdsQueryResponse,
  TQueryKey extends QueryKey = ReadSqlValidationsBySsdsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: ReadSqlValidationsBySsdsPathParams['account_name']
    starSchemaDataSourceId: ReadSqlValidationsBySsdsPathParams['star_schema_data_source_id']
    params?: ReadSqlValidationsBySsdsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadSqlValidationsBySsdsQueryResponse, ReadSqlValidationsBySsds422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readSqlValidationsBySsdsQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(readSqlValidationsBySsdsQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadSqlValidationsBySsds422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}