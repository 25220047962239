// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/oauthSchema.ts"
);
import.meta.hot.lastModified = "1737935764663.7637";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const oauthPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

/**
 * @description Successful Response
 */
export const oauth200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const oauth422Schema = z.lazy(() => httpValidationErrorSchema)

export const oauthQueryResponseSchema = z.lazy(() => oauth200Schema)