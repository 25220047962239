// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useBatchUpdateDmSensitive.ts"
);
import.meta.hot.lastModified = "1737759761442.02";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  BatchUpdateDmSensitiveMutationRequest,
  BatchUpdateDmSensitiveMutationResponse,
  BatchUpdateDmSensitivePathParams,
  BatchUpdateDmSensitive422,
} from '../types/BatchUpdateDmSensitive'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const batchUpdateDmSensitiveMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/batch_update_dm_sensitive' }] as const

export type BatchUpdateDmSensitiveMutationKey = ReturnType<typeof batchUpdateDmSensitiveMutationKey>

/**
 * @description Batch update DMs for sensitive given the assignment.
 * @summary Batch Update Dm Sensitive
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/batch_update_dm_sensitive}
 */
async function batchUpdateDmSensitive(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: BatchUpdateDmSensitivePathParams['account_name']
    starSchemaDataSourceId: BatchUpdateDmSensitivePathParams['star_schema_data_source_id']
    data?: BatchUpdateDmSensitiveMutationRequest
  },
  config: Partial<RequestConfig<BatchUpdateDmSensitiveMutationRequest>> = {},
) {
  const res = await client<BatchUpdateDmSensitiveMutationResponse, BatchUpdateDmSensitive422, BatchUpdateDmSensitiveMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/batch_update_dm_sensitive`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Batch update DMs for sensitive given the assignment.
 * @summary Batch Update Dm Sensitive
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/batch_update_dm_sensitive}
 */
export function useBatchUpdateDmSensitive(
  options: {
    mutation?: UseMutationOptions<
      BatchUpdateDmSensitiveMutationResponse,
      BatchUpdateDmSensitive422,
      {
        accountName: BatchUpdateDmSensitivePathParams['account_name']
        starSchemaDataSourceId: BatchUpdateDmSensitivePathParams['star_schema_data_source_id']
        data?: BatchUpdateDmSensitiveMutationRequest
      }
    >
    client?: Partial<RequestConfig<BatchUpdateDmSensitiveMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? batchUpdateDmSensitiveMutationKey()

  return useMutation<
    BatchUpdateDmSensitiveMutationResponse,
    BatchUpdateDmSensitive422,
    {
      accountName: BatchUpdateDmSensitivePathParams['account_name']
      starSchemaDataSourceId: BatchUpdateDmSensitivePathParams['star_schema_data_source_id']
      data?: BatchUpdateDmSensitiveMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return batchUpdateDmSensitive({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}