// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetConversations.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetConversationsQueryResponse, GetConversationsPathParams, GetConversationsQueryParams, GetConversations422 } from '../types/GetConversations'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getConversationsQueryKey = ({ accountName }: { accountName: GetConversationsPathParams['account_name'] }, params?: GetConversationsQueryParams) =>
  [{ url: '/api/v1/accounts/:account_name/conversations/', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type GetConversationsQueryKey = ReturnType<typeof getConversationsQueryKey>

/**
 * @description Get all conversations for the bumblebee dashboard.If show_moonlight is True, show only moonlight conversationsIf show_moonlight is False, show only non-moonlight conversationsIf show_moonlight is None, show everything.
 * @summary Get Conversations
 * {@link /api/v1/accounts/:account_name/conversations/}
 */
async function getConversations(
  { accountName, params }: { accountName: GetConversationsPathParams['account_name']; params?: GetConversationsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetConversationsQueryResponse, GetConversations422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/`,
    params,
    ...config,
  })
  return res.data
}

export function getConversationsQueryOptions(
  { accountName, params }: { accountName: GetConversationsPathParams['account_name']; params?: GetConversationsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getConversationsQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getConversations({ accountName, params }, config)
    },
  })
}

/**
 * @description Get all conversations for the bumblebee dashboard.If show_moonlight is True, show only moonlight conversationsIf show_moonlight is False, show only non-moonlight conversationsIf show_moonlight is None, show everything.
 * @summary Get Conversations
 * {@link /api/v1/accounts/:account_name/conversations/}
 */
export function useGetConversations<
  TData = GetConversationsQueryResponse,
  TQueryData = GetConversationsQueryResponse,
  TQueryKey extends QueryKey = GetConversationsQueryKey,
>(
  { accountName, params }: { accountName: GetConversationsPathParams['account_name']; params?: GetConversationsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetConversationsQueryResponse, GetConversations422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getConversationsQueryKey({ accountName }, params)

  const query = useQuery({
    ...(getConversationsQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetConversations422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}