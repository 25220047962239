// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadCellRunEventByRunId.ts"
);
import.meta.hot.lastModified = "1737759761466.0198";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadCellRunEventByRunIdQueryResponse, ReadCellRunEventByRunIdPathParams, ReadCellRunEventByRunId422 } from '../types/ReadCellRunEventByRunId'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readCellRunEventByRunIdQueryKey = ({
  runId,
  accountName,
}: {
  runId: ReadCellRunEventByRunIdPathParams['run_id']
  accountName: ReadCellRunEventByRunIdPathParams['account_name']
}) => [{ url: '/api/v1/accounts/:account_name/cell_run_events/run/:run_id', params: { accountName: accountName, runId: runId } }] as const

export type ReadCellRunEventByRunIdQueryKey = ReturnType<typeof readCellRunEventByRunIdQueryKey>

/**
 * @description Read cell run event by run id.
 * @summary Read Cell Run Event By Run Id
 * {@link /api/v1/accounts/:account_name/cell_run_events/run/:run_id}
 */
async function readCellRunEventByRunId(
  { runId, accountName }: { runId: ReadCellRunEventByRunIdPathParams['run_id']; accountName: ReadCellRunEventByRunIdPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadCellRunEventByRunIdQueryResponse, ReadCellRunEventByRunId422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/cell_run_events/run/${runId}`,
    ...config,
  })
  return res.data
}

export function readCellRunEventByRunIdQueryOptions(
  { runId, accountName }: { runId: ReadCellRunEventByRunIdPathParams['run_id']; accountName: ReadCellRunEventByRunIdPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readCellRunEventByRunIdQueryKey({ runId, accountName })
  return queryOptions({
    enabled: !!(runId && accountName),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readCellRunEventByRunId({ runId, accountName }, config)
    },
  })
}

/**
 * @description Read cell run event by run id.
 * @summary Read Cell Run Event By Run Id
 * {@link /api/v1/accounts/:account_name/cell_run_events/run/:run_id}
 */
export function useReadCellRunEventByRunId<
  TData = ReadCellRunEventByRunIdQueryResponse,
  TQueryData = ReadCellRunEventByRunIdQueryResponse,
  TQueryKey extends QueryKey = ReadCellRunEventByRunIdQueryKey,
>(
  { runId, accountName }: { runId: ReadCellRunEventByRunIdPathParams['run_id']; accountName: ReadCellRunEventByRunIdPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadCellRunEventByRunIdQueryResponse, ReadCellRunEventByRunId422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readCellRunEventByRunIdQueryKey({ runId, accountName })

  const query = useQuery({
    ...(readCellRunEventByRunIdQueryOptions({ runId, accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadCellRunEventByRunId422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}