// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useListRedshiftColumns.ts"
);
import.meta.hot.lastModified = "1737759760700.024";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ListRedshiftColumnsQueryResponse,
  ListRedshiftColumnsPathParams,
  ListRedshiftColumnsQueryParams,
  ListRedshiftColumns422,
} from '../types/ListRedshiftColumns'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listRedshiftColumnsQueryKey = (
  { accountName, connectionId }: { accountName: ListRedshiftColumnsPathParams['account_name']; connectionId: ListRedshiftColumnsPathParams['connection_id'] },
  params: ListRedshiftColumnsQueryParams,
) =>
  [
    { url: '/api/v1/accounts/:account_name/connections/redshift/:connection_id/columns', params: { accountName: accountName, connectionId: connectionId } },
    ...(params ? [params] : []),
  ] as const

export type ListRedshiftColumnsQueryKey = ReturnType<typeof listRedshiftColumnsQueryKey>

/**
 * @description List Redshift columns.
 * @summary List Redshift Columns
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id/columns}
 */
async function listRedshiftColumns(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListRedshiftColumnsPathParams['account_name']
    connectionId: ListRedshiftColumnsPathParams['connection_id']
    params: ListRedshiftColumnsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ListRedshiftColumnsQueryResponse, ListRedshiftColumns422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/redshift/${connectionId}/columns`,
    params,
    ...config,
  })
  return res.data
}

export function listRedshiftColumnsQueryOptions(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListRedshiftColumnsPathParams['account_name']
    connectionId: ListRedshiftColumnsPathParams['connection_id']
    params: ListRedshiftColumnsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = listRedshiftColumnsQueryKey({ accountName, connectionId }, params)
  return queryOptions({
    enabled: !!(accountName && connectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listRedshiftColumns({ accountName, connectionId, params }, config)
    },
  })
}

/**
 * @description List Redshift columns.
 * @summary List Redshift Columns
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id/columns}
 */
export function useListRedshiftColumns<
  TData = ListRedshiftColumnsQueryResponse,
  TQueryData = ListRedshiftColumnsQueryResponse,
  TQueryKey extends QueryKey = ListRedshiftColumnsQueryKey,
>(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListRedshiftColumnsPathParams['account_name']
    connectionId: ListRedshiftColumnsPathParams['connection_id']
    params: ListRedshiftColumnsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ListRedshiftColumnsQueryResponse, ListRedshiftColumns422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listRedshiftColumnsQueryKey({ accountName, connectionId }, params)

  const query = useQuery({
    ...(listRedshiftColumnsQueryOptions({ accountName, connectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListRedshiftColumns422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}