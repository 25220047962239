// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/FilterCategory.ts"
);
import.meta.hot.lastModified = "1737935765540.7588";
}
// REMIX HMR END

export enum FilterCategory {
  'text' = 'text',
  'numeric' = 'numeric',
  'date' = 'date',
  'datetime' = 'datetime',
  'boolean' = 'boolean',
  'unknown' = 'unknown',
}