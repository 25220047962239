// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateStarSchemaDataSourceNameAndDescription.ts"
);
import.meta.hot.lastModified = "1737935769735.7358";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateStarSchemaDataSourceNameAndDescriptionMutationResponse,
  UpdateStarSchemaDataSourceNameAndDescriptionPathParams,
  UpdateStarSchemaDataSourceNameAndDescriptionQueryParams,
  UpdateStarSchemaDataSourceNameAndDescription422,
} from '../types/UpdateStarSchemaDataSourceNameAndDescription'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateStarSchemaDataSourceNameAndDescriptionMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/name_and_description' }] as const

export type UpdateStarSchemaDataSourceNameAndDescriptionMutationKey = ReturnType<typeof updateStarSchemaDataSourceNameAndDescriptionMutationKey>

/**
 * @description Update Star Schema Data Source name and description.
 * @summary Update Star Schema Data Source Name And Description
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/name_and_description}
 */
async function updateStarSchemaDataSourceNameAndDescription(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: UpdateStarSchemaDataSourceNameAndDescriptionPathParams['account_name']
    starSchemaDataSourceId: UpdateStarSchemaDataSourceNameAndDescriptionPathParams['star_schema_data_source_id']
    params?: UpdateStarSchemaDataSourceNameAndDescriptionQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateStarSchemaDataSourceNameAndDescriptionMutationResponse, UpdateStarSchemaDataSourceNameAndDescription422, unknown>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/name_and_description`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Update Star Schema Data Source name and description.
 * @summary Update Star Schema Data Source Name And Description
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/name_and_description}
 */
export function useUpdateStarSchemaDataSourceNameAndDescription(
  options: {
    mutation?: UseMutationOptions<
      UpdateStarSchemaDataSourceNameAndDescriptionMutationResponse,
      UpdateStarSchemaDataSourceNameAndDescription422,
      {
        accountName: UpdateStarSchemaDataSourceNameAndDescriptionPathParams['account_name']
        starSchemaDataSourceId: UpdateStarSchemaDataSourceNameAndDescriptionPathParams['star_schema_data_source_id']
        params?: UpdateStarSchemaDataSourceNameAndDescriptionQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateStarSchemaDataSourceNameAndDescriptionMutationKey()

  return useMutation<
    UpdateStarSchemaDataSourceNameAndDescriptionMutationResponse,
    UpdateStarSchemaDataSourceNameAndDescription422,
    {
      accountName: UpdateStarSchemaDataSourceNameAndDescriptionPathParams['account_name']
      starSchemaDataSourceId: UpdateStarSchemaDataSourceNameAndDescriptionPathParams['star_schema_data_source_id']
      params?: UpdateStarSchemaDataSourceNameAndDescriptionQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params }) => {
      return updateStarSchemaDataSourceNameAndDescription({ accountName, starSchemaDataSourceId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}