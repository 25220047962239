// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetVerifiedConflicts.ts"
);
import.meta.hot.lastModified = "1737935768071.745";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetVerifiedConflictsMutationResponse, GetVerifiedConflictsPathParams, GetVerifiedConflicts422 } from '../types/GetVerifiedConflicts'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const getVerifiedConflictsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/verified_conflicts' }] as const

export type GetVerifiedConflictsMutationKey = ReturnType<typeof getVerifiedConflictsMutationKey>

/**
 * @description Get list of cells with conflicting feedback.If there are any conflicting cells, the user should be notified before verifying the cell.
 * @summary Get Verified Conflicts
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/verified_conflicts}
 */
async function getVerifiedConflicts(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetVerifiedConflictsPathParams['account_name']
    conversationId: GetVerifiedConflictsPathParams['conversation_id']
    cellId: GetVerifiedConflictsPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetVerifiedConflictsMutationResponse, GetVerifiedConflicts422, unknown>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/verified_conflicts`,
    ...config,
  })
  return res.data
}

/**
 * @description Get list of cells with conflicting feedback.If there are any conflicting cells, the user should be notified before verifying the cell.
 * @summary Get Verified Conflicts
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/verified_conflicts}
 */
export function useGetVerifiedConflicts(
  options: {
    mutation?: UseMutationOptions<
      GetVerifiedConflictsMutationResponse,
      GetVerifiedConflicts422,
      {
        accountName: GetVerifiedConflictsPathParams['account_name']
        conversationId: GetVerifiedConflictsPathParams['conversation_id']
        cellId: GetVerifiedConflictsPathParams['cell_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? getVerifiedConflictsMutationKey()

  return useMutation<
    GetVerifiedConflictsMutationResponse,
    GetVerifiedConflicts422,
    {
      accountName: GetVerifiedConflictsPathParams['account_name']
      conversationId: GetVerifiedConflictsPathParams['conversation_id']
      cellId: GetVerifiedConflictsPathParams['cell_id']
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId }) => {
      return getVerifiedConflicts({ accountName, conversationId, cellId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}