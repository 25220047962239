// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bulkCreateSemanticQueriesSchema.ts"
);
import.meta.hot.lastModified = "1737935769018.7397";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { semanticQueryCreateSchema } from './semanticQueryCreateSchema'
import { semanticQuerySchema } from './semanticQuerySchema'
import { z } from 'zod'

export const bulkCreateSemanticQueriesPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const bulkCreateSemanticQueries201Schema = z.array(z.lazy(() => semanticQuerySchema))

/**
 * @description Validation Error
 */
export const bulkCreateSemanticQueries422Schema = z.lazy(() => httpValidationErrorSchema)

export const bulkCreateSemanticQueriesMutationRequestSchema = z.array(z.lazy(() => semanticQueryCreateSchema))

export const bulkCreateSemanticQueriesMutationResponseSchema = z.lazy(() => bulkCreateSemanticQueries201Schema)