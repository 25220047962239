// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/rmEnterpriseUserSchema.ts"
);
import.meta.hot.lastModified = "1737759760208.0264";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { userSchema } from './userSchema'
import { z } from 'zod'

export const rmEnterpriseUserPathParamsSchema = z.object({
  enterprise_name: z.string(),
  user_id: z.union([z.string(), z.null()]),
})

export const rmEnterpriseUserQueryParamsSchema = z
  .object({
    user_email: z.union([z.string().email(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const rmEnterpriseUser200Schema = z.lazy(() => userSchema)

/**
 * @description Validation Error
 */
export const rmEnterpriseUser422Schema = z.lazy(() => httpValidationErrorSchema)

export const rmEnterpriseUserMutationResponseSchema = z.lazy(() => rmEnterpriseUser200Schema)