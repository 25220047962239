// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readSnowflakeConnectionTablesSchema.ts"
);
import.meta.hot.lastModified = "1737935769440.7375";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readSnowflakeConnectionTablesPathParamsSchema = z.object({
  account_name: z.string(),
  snowflake_connection_id: z.string(),
})

export const readSnowflakeConnectionTablesQueryParamsSchema = z.object({
  database: z.string().min(1),
  schema: z.union([z.string(), z.null()]).optional(),
})

/**
 * @description Successful Response
 */
export const readSnowflakeConnectionTables200Schema = z.object({}).catchall(z.object({}).catchall(z.array(z.string())))

/**
 * @description Validation Error
 */
export const readSnowflakeConnectionTables422Schema = z.lazy(() => httpValidationErrorSchema)

export const readSnowflakeConnectionTablesQueryResponseSchema = z.lazy(() => readSnowflakeConnectionTables200Schema)