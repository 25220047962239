// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/DataAssetConnectionTypeAPI.ts"
);
import.meta.hot.lastModified = "1737935753956.8225";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The connection type of the data asset.
 */
export enum DataAssetConnectionTypeAPI {
  SNOWFLAKE = 'snowflake',
  BIGQUERY = 'bigquery',
  DATABRICKS = 'databricks',
  REDSHIFT = 'redshift',
  TABLEAU = 'tableau',
  MODE = 'mode',
  UNKNOWN = 'unknown',
}
