// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ConversationCellFilter.ts"
);
import.meta.hot.lastModified = "1737759760689.024";
}
// REMIX HMR END

export enum ConversationCellFilter {
  'ALL' = 'ALL',
  'VERIFIED_CONV_ONLY' = 'VERIFIED_CONV_ONLY',
  'VERIFIED_CELL_ONLY' = 'VERIFIED_CELL_ONLY',
}