// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRmAccountEvalMaintainers.ts"
);
import.meta.hot.lastModified = "1737759761632.019";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RmAccountEvalMaintainersMutationRequest,
  RmAccountEvalMaintainersMutationResponse,
  RmAccountEvalMaintainersPathParams,
  RmAccountEvalMaintainers422,
} from '../types/RmAccountEvalMaintainers'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const rmAccountEvalMaintainersMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/eval_maintainers' }] as const

export type RmAccountEvalMaintainersMutationKey = ReturnType<typeof rmAccountEvalMaintainersMutationKey>

/**
 * @description Remove eval maintainer privileges from users of the account.Note that this does NOT remove the user themselves from the account.
 * @summary Rm Account Eval Maintainers
 * {@link /api/v1/accounts/:account_name/eval_maintainers}
 */
async function rmAccountEvalMaintainers(
  { accountName, data }: { accountName: RmAccountEvalMaintainersPathParams['account_name']; data?: RmAccountEvalMaintainersMutationRequest },
  config: Partial<RequestConfig<RmAccountEvalMaintainersMutationRequest>> = {},
) {
  const res = await client<RmAccountEvalMaintainersMutationResponse, RmAccountEvalMaintainers422, RmAccountEvalMaintainersMutationRequest>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/eval_maintainers`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Remove eval maintainer privileges from users of the account.Note that this does NOT remove the user themselves from the account.
 * @summary Rm Account Eval Maintainers
 * {@link /api/v1/accounts/:account_name/eval_maintainers}
 */
export function useRmAccountEvalMaintainers(
  options: {
    mutation?: UseMutationOptions<
      RmAccountEvalMaintainersMutationResponse,
      RmAccountEvalMaintainers422,
      { accountName: RmAccountEvalMaintainersPathParams['account_name']; data?: RmAccountEvalMaintainersMutationRequest }
    >
    client?: Partial<RequestConfig<RmAccountEvalMaintainersMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? rmAccountEvalMaintainersMutationKey()

  return useMutation<
    RmAccountEvalMaintainersMutationResponse,
    RmAccountEvalMaintainers422,
    { accountName: RmAccountEvalMaintainersPathParams['account_name']; data?: RmAccountEvalMaintainersMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return rmAccountEvalMaintainers({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}