// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/connectionLookupSchema.ts"
);
import.meta.hot.lastModified = "1737759760028.0273";
}
// REMIX HMR END

// @ts-nocheck
import { bigQueryConnectionLookupSchema } from './bigQueryConnectionLookupSchema'
import { databricksConnectionLookupSchema } from './databricksConnectionLookupSchema'
import { postgresConnectionLookupSchema } from './postgresConnectionLookupSchema'
import { redshiftConnectionLookupSchema } from './redshiftConnectionLookupSchema'
import { snowflakeConnectionLookupSchema } from './snowflakeConnectionLookupSchema'
import { teradataConnectionLookupSchema } from './teradataConnectionLookupSchema'
import { z } from 'zod'

/**
 * @description Connection Type to look up.
 */
export const connectionLookupSchema = z
  .object({
    snowflake: z.union([z.lazy(() => snowflakeConnectionLookupSchema), z.null()]).optional(),
    databricks: z.union([z.lazy(() => databricksConnectionLookupSchema), z.null()]).optional(),
    bigquery: z.union([z.lazy(() => bigQueryConnectionLookupSchema), z.null()]).optional(),
    postgres: z.union([z.lazy(() => postgresConnectionLookupSchema), z.null()]).optional(),
    teradata: z.union([z.lazy(() => teradataConnectionLookupSchema), z.null()]).optional(),
    redshift: z.union([z.lazy(() => redshiftConnectionLookupSchema), z.null()]).optional(),
    ns_database: z.union([z.string(), z.null()]),
    ns_schema: z.union([z.string(), z.null()]),
  })
  .describe('Connection Type to look up.')