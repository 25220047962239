// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/promptTokensDetailsSchema.ts"
);
import.meta.hot.lastModified = "1737935768070.745";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const promptTokensDetailsSchema = z
  .object({
    audio_tokens: z.union([z.number().int(), z.null()]).optional(),
    cached_tokens: z.union([z.number().int(), z.null()]).optional(),
  })
  .catchall(z.unknown())