// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteCellSchema.ts"
);
import.meta.hot.lastModified = "1737759758214.0366";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const deleteCellPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

/**
 * @description Successful Response
 */
export const deleteCell200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const deleteCell422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteCellMutationResponseSchema = z.lazy(() => deleteCell200Schema)