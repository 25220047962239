// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chartConfigDmmSchema.ts"
);
import.meta.hot.lastModified = "1737935766487.7537";
}
// REMIX HMR END

// @ts-nocheck
import { axisSchema } from './axisSchema'
import { chartSelectDmmTypeSchema } from './chartSelectDmmTypeSchema'
import { dimensionViewSchema } from './dimensionViewSchema'
import { metricViewSchema } from './metricViewSchema'
import { z } from 'zod'

/**
 * @description Properties for Chart Config DMM.
 */
export const chartConfigDmmSchema = z
  .object({
    id: z.string(),
    chart_config_id: z.string(),
    axis: z.lazy(() => axisSchema),
    dimension: z.union([z.lazy(() => dimensionViewSchema), z.null()]).optional(),
    metric: z.union([z.lazy(() => metricViewSchema), z.null()]).optional(),
    type: z.lazy(() => chartSelectDmmTypeSchema),
  })
  .describe('Properties for Chart Config DMM.')