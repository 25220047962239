// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bigQueryConnectionApiCreateSchema.ts"
);
import.meta.hot.lastModified = "1737935769212.7388";
}
// REMIX HMR END

// @ts-nocheck
import { bigQueryLocationSchema } from './bigQueryLocationSchema'
import { connectionPolyTypeSchema } from './connectionPolyTypeSchema'
import { z } from 'zod'

/**
 * @description Properties to receive via API on creation.
 */
export const bigQueryConnectionApiCreateSchema = z
  .object({
    name: z.string(),
    type: z.lazy(() => connectionPolyTypeSchema).default('bigquery'),
    keyfile: z.string(),
    location: z.lazy(() => bigQueryLocationSchema),
    ns_schema: z.union([z.string(), z.null()]).optional(),
    ns_database: z.union([z.string(), z.null()]).optional(),
    max_tables: z.number().int().default(8000),
  })
  .describe('Properties to receive via API on creation.')