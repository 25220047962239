// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useBulkCreateNamedFilters.ts"
);
import.meta.hot.lastModified = "1737759761442.02";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  BulkCreateNamedFiltersMutationRequest,
  BulkCreateNamedFiltersMutationResponse,
  BulkCreateNamedFiltersPathParams,
  BulkCreateNamedFilters422,
} from '../types/BulkCreateNamedFilters'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const bulkCreateNamedFiltersMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_named_filters' }] as const

export type BulkCreateNamedFiltersMutationKey = ReturnType<typeof bulkCreateNamedFiltersMutationKey>

/**
 * @description Add Named Filters with the appropriate SSDS id.
 * @summary Bulk Create Named Filters
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/upload_named_filters}
 */
async function bulkCreateNamedFilters(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: BulkCreateNamedFiltersPathParams['account_name']
    starSchemaDataSourceId: BulkCreateNamedFiltersPathParams['star_schema_data_source_id']
    data?: BulkCreateNamedFiltersMutationRequest
  },
  config: Partial<RequestConfig<BulkCreateNamedFiltersMutationRequest>> = {},
) {
  const res = await client<BulkCreateNamedFiltersMutationResponse, BulkCreateNamedFilters422, BulkCreateNamedFiltersMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/upload_named_filters`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Add Named Filters with the appropriate SSDS id.
 * @summary Bulk Create Named Filters
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/upload_named_filters}
 */
export function useBulkCreateNamedFilters(
  options: {
    mutation?: UseMutationOptions<
      BulkCreateNamedFiltersMutationResponse,
      BulkCreateNamedFilters422,
      {
        accountName: BulkCreateNamedFiltersPathParams['account_name']
        starSchemaDataSourceId: BulkCreateNamedFiltersPathParams['star_schema_data_source_id']
        data?: BulkCreateNamedFiltersMutationRequest
      }
    >
    client?: Partial<RequestConfig<BulkCreateNamedFiltersMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? bulkCreateNamedFiltersMutationKey()

  return useMutation<
    BulkCreateNamedFiltersMutationResponse,
    BulkCreateNamedFilters422,
    {
      accountName: BulkCreateNamedFiltersPathParams['account_name']
      starSchemaDataSourceId: BulkCreateNamedFiltersPathParams['star_schema_data_source_id']
      data?: BulkCreateNamedFiltersMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return bulkCreateNamedFilters({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}