// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useAddDemoDataset.ts"
);
import.meta.hot.lastModified = "1737759759414.0305";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { AddDemoDatasetMutationResponse, AddDemoDatasetPathParams, AddDemoDataset422 } from '../types/AddDemoDataset'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const addDemoDatasetMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/datasets/add_demo_dataset' }] as const

export type AddDemoDatasetMutationKey = ReturnType<typeof addDemoDatasetMutationKey>

/**
 * @description Add a demo dataset to the account
 * @summary Add Demo Dataset
 * {@link /api/v1/accounts/:account_name/datasets/add_demo_dataset}
 */
async function addDemoDataset({ accountName }: { accountName: AddDemoDatasetPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<AddDemoDatasetMutationResponse, AddDemoDataset422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/datasets/add_demo_dataset`,
    ...config,
  })
  return res.data
}

/**
 * @description Add a demo dataset to the account
 * @summary Add Demo Dataset
 * {@link /api/v1/accounts/:account_name/datasets/add_demo_dataset}
 */
export function useAddDemoDataset(
  options: {
    mutation?: UseMutationOptions<AddDemoDatasetMutationResponse, AddDemoDataset422, { accountName: AddDemoDatasetPathParams['account_name'] }>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? addDemoDatasetMutationKey()

  return useMutation<AddDemoDatasetMutationResponse, AddDemoDataset422, { accountName: AddDemoDatasetPathParams['account_name'] }>({
    mutationFn: async ({ accountName }) => {
      return addDemoDataset({ accountName }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}