// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/ReportService.ts"
);
import.meta.hot.lastModified = "1737935753870.823";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataAssetVisibility } from '../models/DataAssetVisibility';
import type { Report } from '../models/Report';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReportService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Update Report Visibility
   * Update Report visibility.
   * @param accountName
   * @param reportId
   * @param visibility
   * @param sessionId
   * @returns Report Successful Response
   * @throws ApiError
   */
  public updateReportVisibility(
    accountName: string,
    reportId: string,
    visibility: DataAssetVisibility,
    sessionId?: (string | null),
  ): CancelablePromise<Report> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/reports/{report_id}/visibility',
      path: {
        'account_name': accountName,
        'report_id': reportId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'visibility': visibility,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
