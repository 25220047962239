// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getAccountAdminsSchema.ts"
);
import.meta.hot.lastModified = "1737759760038.0273";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { userSchema } from './userSchema'
import { z } from 'zod'

export const getAccountAdminsPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const getAccountAdmins200Schema = z.array(z.lazy(() => userSchema))

/**
 * @description Validation Error
 */
export const getAccountAdmins422Schema = z.lazy(() => httpValidationErrorSchema)

export const getAccountAdminsQueryResponseSchema = z.lazy(() => getAccountAdmins200Schema)