// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadSnowflakeConnections.ts"
);
import.meta.hot.lastModified = "1737935768805.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadSnowflakeConnectionsQueryResponse,
  ReadSnowflakeConnectionsPathParams,
  ReadSnowflakeConnectionsQueryParams,
  ReadSnowflakeConnections422,
} from '../types/ReadSnowflakeConnections'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readSnowflakeConnectionsQueryKey = (
  { accountName }: { accountName: ReadSnowflakeConnectionsPathParams['account_name'] },
  params?: ReadSnowflakeConnectionsQueryParams,
) => [{ url: '/api/v1/accounts/:account_name/connections/snowflake/', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type ReadSnowflakeConnectionsQueryKey = ReturnType<typeof readSnowflakeConnectionsQueryKey>

/**
 * @description Read Snowflake connections.This endpoint is deprecated. Use the /connections endpoint to get all connections instead.This endpoint will be removed in a coming version.
 * @summary Read Snowflake Connections
 * {@link /api/v1/accounts/:account_name/connections/snowflake/}
 * @deprecated
 */
async function readSnowflakeConnections(
  { accountName, params }: { accountName: ReadSnowflakeConnectionsPathParams['account_name']; params?: ReadSnowflakeConnectionsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadSnowflakeConnectionsQueryResponse, ReadSnowflakeConnections422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/`,
    params,
    ...config,
  })
  return res.data
}

export function readSnowflakeConnectionsQueryOptions(
  { accountName, params }: { accountName: ReadSnowflakeConnectionsPathParams['account_name']; params?: ReadSnowflakeConnectionsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readSnowflakeConnectionsQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readSnowflakeConnections({ accountName, params }, config)
    },
  })
}

/**
 * @description Read Snowflake connections.This endpoint is deprecated. Use the /connections endpoint to get all connections instead.This endpoint will be removed in a coming version.
 * @summary Read Snowflake Connections
 * {@link /api/v1/accounts/:account_name/connections/snowflake/}
 * @deprecated
 */
export function useReadSnowflakeConnections<
  TData = ReadSnowflakeConnectionsQueryResponse,
  TQueryData = ReadSnowflakeConnectionsQueryResponse,
  TQueryKey extends QueryKey = ReadSnowflakeConnectionsQueryKey,
>(
  { accountName, params }: { accountName: ReadSnowflakeConnectionsPathParams['account_name']; params?: ReadSnowflakeConnectionsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadSnowflakeConnectionsQueryResponse, ReadSnowflakeConnections422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readSnowflakeConnectionsQueryKey({ accountName }, params)

  const query = useQuery({
    ...(readSnowflakeConnectionsQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadSnowflakeConnections422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}