// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/SemanticQueryService.ts"
);
import.meta.hot.lastModified = "1737935753813.8232";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SemanticQueryService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Semantic Query
   * Get a semantic query.
   * @param accountName
   * @param semanticQueryId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getSemanticQuery(
    accountName: string,
    semanticQueryId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/semantic_query/{semantic_query_id}',
      path: {
        'account_name': accountName,
        'semantic_query_id': semanticQueryId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
