// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/columnValueSchema.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Column value.
 */
export const columnValueSchema = z
  .object({
    value: z.union([z.number().int(), z.boolean(), z.array(z.unknown()), z.string(), z.string().datetime(), z.number(), z.null()]).optional(),
    count: z.number().int(),
  })
  .describe('Column value.')