// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadReportsFromFolder.ts"
);
import.meta.hot.lastModified = "1737759761255.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadReportsFromFolderMutationRequest,
  ReadReportsFromFolderMutationResponse,
  ReadReportsFromFolderPathParams,
  ReadReportsFromFolder422,
} from '../types/ReadReportsFromFolder'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const readReportsFromFolderMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/connections/reports/{connection_type}/{connection_id}/folders/reports' }] as const

export type ReadReportsFromFolderMutationKey = ReturnType<typeof readReportsFromFolderMutationKey>

/**
 * @description Read the reports in the given folder.
 * @summary Read Reports From Folder
 * {@link /api/v1/accounts/:account_name/connections/reports/:connection_type/:connection_id/folders/reports}
 */
async function readReportsFromFolder(
  {
    accountName,
    connectionType,
    connectionId,
    data,
  }: {
    accountName: ReadReportsFromFolderPathParams['account_name']
    connectionType: ReadReportsFromFolderPathParams['connection_type']
    connectionId: ReadReportsFromFolderPathParams['connection_id']
    data: ReadReportsFromFolderMutationRequest
  },
  config: Partial<RequestConfig<ReadReportsFromFolderMutationRequest>> = {},
) {
  const res = await client<ReadReportsFromFolderMutationResponse, ReadReportsFromFolder422, ReadReportsFromFolderMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/reports/${connectionType}/${connectionId}/folders/reports`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Read the reports in the given folder.
 * @summary Read Reports From Folder
 * {@link /api/v1/accounts/:account_name/connections/reports/:connection_type/:connection_id/folders/reports}
 */
export function useReadReportsFromFolder(
  options: {
    mutation?: UseMutationOptions<
      ReadReportsFromFolderMutationResponse,
      ReadReportsFromFolder422,
      {
        accountName: ReadReportsFromFolderPathParams['account_name']
        connectionType: ReadReportsFromFolderPathParams['connection_type']
        connectionId: ReadReportsFromFolderPathParams['connection_id']
        data: ReadReportsFromFolderMutationRequest
      }
    >
    client?: Partial<RequestConfig<ReadReportsFromFolderMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? readReportsFromFolderMutationKey()

  return useMutation<
    ReadReportsFromFolderMutationResponse,
    ReadReportsFromFolder422,
    {
      accountName: ReadReportsFromFolderPathParams['account_name']
      connectionType: ReadReportsFromFolderPathParams['connection_type']
      connectionId: ReadReportsFromFolderPathParams['connection_id']
      data: ReadReportsFromFolderMutationRequest
    }
  >({
    mutationFn: async ({ accountName, connectionType, connectionId, data }) => {
      return readReportsFromFolder({ accountName, connectionType, connectionId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}