// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadCellDataAsCsv.ts"
);
import.meta.hot.lastModified = "1737759760223.0264";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadCellDataAsCsvQueryResponse, ReadCellDataAsCsvPathParams, ReadCellDataAsCsv422 } from '../types/ReadCellDataAsCsv'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readCellDataAsCsvQueryKey = ({
  accountName,
  conversationId,
  cellId,
}: {
  accountName: ReadCellDataAsCsvPathParams['account_name']
  conversationId: ReadCellDataAsCsvPathParams['conversation_id']
  cellId: ReadCellDataAsCsvPathParams['cell_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/data/csv',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId },
    },
  ] as const

export type ReadCellDataAsCsvQueryKey = ReturnType<typeof readCellDataAsCsvQueryKey>

/**
 * @description Read Cell data as a CSV.
 * @summary Read Cell Data As Csv
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/data/csv}
 */
async function readCellDataAsCsv(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: ReadCellDataAsCsvPathParams['account_name']
    conversationId: ReadCellDataAsCsvPathParams['conversation_id']
    cellId: ReadCellDataAsCsvPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadCellDataAsCsvQueryResponse, ReadCellDataAsCsv422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/data/csv`,
    ...config,
  })
  return res.data
}

export function readCellDataAsCsvQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: ReadCellDataAsCsvPathParams['account_name']
    conversationId: ReadCellDataAsCsvPathParams['conversation_id']
    cellId: ReadCellDataAsCsvPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readCellDataAsCsvQueryKey({ accountName, conversationId, cellId })
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readCellDataAsCsv({ accountName, conversationId, cellId }, config)
    },
  })
}

/**
 * @description Read Cell data as a CSV.
 * @summary Read Cell Data As Csv
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/data/csv}
 */
export function useReadCellDataAsCsv<
  TData = ReadCellDataAsCsvQueryResponse,
  TQueryData = ReadCellDataAsCsvQueryResponse,
  TQueryKey extends QueryKey = ReadCellDataAsCsvQueryKey,
>(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: ReadCellDataAsCsvPathParams['account_name']
    conversationId: ReadCellDataAsCsvPathParams['conversation_id']
    cellId: ReadCellDataAsCsvPathParams['cell_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadCellDataAsCsvQueryResponse, ReadCellDataAsCsv422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readCellDataAsCsvQueryKey({ accountName, conversationId, cellId })

  const query = useQuery({
    ...(readCellDataAsCsvQueryOptions({ accountName, conversationId, cellId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadCellDataAsCsv422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}