// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateEnterprise.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateEnterpriseMutationRequest,
  UpdateEnterpriseMutationResponse,
  UpdateEnterprisePathParams,
  UpdateEnterprise422,
} from '../types/UpdateEnterprise'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateEnterpriseMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}' }] as const

export type UpdateEnterpriseMutationKey = ReturnType<typeof updateEnterpriseMutationKey>

/**
 * @description Update a enterprise.
 * @summary Update Enterprise
 * {@link /api/v1/enterprises/:enterprise_name}
 */
async function updateEnterprise(
  { enterpriseName, data }: { enterpriseName: UpdateEnterprisePathParams['enterprise_name']; data?: UpdateEnterpriseMutationRequest },
  config: Partial<RequestConfig<UpdateEnterpriseMutationRequest>> = {},
) {
  const res = await client<UpdateEnterpriseMutationResponse, UpdateEnterprise422, UpdateEnterpriseMutationRequest>({
    method: 'PUT',
    url: `/api/v1/enterprises/${enterpriseName}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a enterprise.
 * @summary Update Enterprise
 * {@link /api/v1/enterprises/:enterprise_name}
 */
export function useUpdateEnterprise(
  options: {
    mutation?: UseMutationOptions<
      UpdateEnterpriseMutationResponse,
      UpdateEnterprise422,
      { enterpriseName: UpdateEnterprisePathParams['enterprise_name']; data?: UpdateEnterpriseMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateEnterpriseMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateEnterpriseMutationKey()

  return useMutation<
    UpdateEnterpriseMutationResponse,
    UpdateEnterprise422,
    { enterpriseName: UpdateEnterprisePathParams['enterprise_name']; data?: UpdateEnterpriseMutationRequest }
  >({
    mutationFn: async ({ enterpriseName, data }) => {
      return updateEnterprise({ enterpriseName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}