// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/evalBumblebeeCellApiCreateSchema.ts"
);
import.meta.hot.lastModified = "1737759762054.0168";
}
// REMIX HMR END

// @ts-nocheck
import { semanticLayerViewSchema } from './semanticLayerViewSchema'
import { z } from 'zod'

/**
 * @description Create Eval Bumblebee Cell Schema.\n\nThis copies from BumblebeeCellAPICreate, but allows hard-coding\nthe Moonlight routing decision.
 */
export const evalBumblebeeCellApiCreateSchema = z
  .object({
    user_question: z.string(),
    updated_question: z.union([z.string(), z.null()]).optional(),
    name: z.union([z.string(), z.null()]).optional(),
    ssds_id: z.string(),
    moonlight_predicted_input_type: z.number().int(),
    moonlight_response: z.union([z.string(), z.null()]).optional(),
    semantic_layer_view: z.union([z.lazy(() => semanticLayerViewSchema), z.null()]).optional(),
  })
  .describe('Create Eval Bumblebee Cell Schema.\n\nThis copies from BumblebeeCellAPICreate, but allows hard-coding\nthe Moonlight routing decision.')