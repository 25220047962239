// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadFullSnowflakeConnection.ts"
);
import.meta.hot.lastModified = "1737759762069.0168";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadFullSnowflakeConnectionQueryResponse,
  ReadFullSnowflakeConnectionPathParams,
  ReadFullSnowflakeConnection422,
} from '../types/ReadFullSnowflakeConnection'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readFullSnowflakeConnectionQueryKey = ({
  accountName,
  snowflakeConnectionId,
}: {
  accountName: ReadFullSnowflakeConnectionPathParams['account_name']
  snowflakeConnectionId: ReadFullSnowflakeConnectionPathParams['snowflake_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/full_connection_info',
      params: { accountName: accountName, snowflakeConnectionId: snowflakeConnectionId },
    },
  ] as const

export type ReadFullSnowflakeConnectionQueryKey = ReturnType<typeof readFullSnowflakeConnectionQueryKey>

/**
 * @description Read Snowflake connection with secrets.
 * @summary Read Full Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/full_connection_info}
 */
async function readFullSnowflakeConnection(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadFullSnowflakeConnectionPathParams['account_name']
    snowflakeConnectionId: ReadFullSnowflakeConnectionPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadFullSnowflakeConnectionQueryResponse, ReadFullSnowflakeConnection422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/${snowflakeConnectionId}/full_connection_info`,
    ...config,
  })
  return res.data
}

export function readFullSnowflakeConnectionQueryOptions(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadFullSnowflakeConnectionPathParams['account_name']
    snowflakeConnectionId: ReadFullSnowflakeConnectionPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readFullSnowflakeConnectionQueryKey({ accountName, snowflakeConnectionId })
  return queryOptions({
    enabled: !!(accountName && snowflakeConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readFullSnowflakeConnection({ accountName, snowflakeConnectionId }, config)
    },
  })
}

/**
 * @description Read Snowflake connection with secrets.
 * @summary Read Full Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/full_connection_info}
 */
export function useReadFullSnowflakeConnection<
  TData = ReadFullSnowflakeConnectionQueryResponse,
  TQueryData = ReadFullSnowflakeConnectionQueryResponse,
  TQueryKey extends QueryKey = ReadFullSnowflakeConnectionQueryKey,
>(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadFullSnowflakeConnectionPathParams['account_name']
    snowflakeConnectionId: ReadFullSnowflakeConnectionPathParams['snowflake_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadFullSnowflakeConnectionQueryResponse, ReadFullSnowflakeConnection422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readFullSnowflakeConnectionQueryKey({ accountName, snowflakeConnectionId })

  const query = useQuery({
    ...(readFullSnowflakeConnectionQueryOptions({ accountName, snowflakeConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadFullSnowflakeConnection422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}