// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/knowledgeLayerEntityTypeSchema.ts"
);
import.meta.hot.lastModified = "1737759761840.0178";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Knowledge layer entity type.
 */
export const knowledgeLayerEntityTypeSchema = z.enum(['dimension', 'metric', 'dataset', 'tableau', 'mode']).describe('Knowledge layer entity type.')