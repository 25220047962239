// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/namedFilterOptionCreateSchema.ts"
);
import.meta.hot.lastModified = "1737935768791.741";
}
// REMIX HMR END

// @ts-nocheck
import { combinationSchema } from './combinationSchema'
import { filterOriginSchema } from './filterOriginSchema'
import { namedFilterOptionConditionCreateSchema } from './namedFilterOptionConditionCreateSchema'
import { z } from 'zod'

/**
 * @description Create NamedFilterOption Schema.
 */
export const namedFilterOptionCreateSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    query_id: z.union([z.string(), z.null()]).optional(),
    name: z.string(),
    combination: z.lazy(() => combinationSchema).default('ALL'),
    description: z.union([z.string(), z.null()]).optional(),
    filter_origin: z.lazy(() => filterOriginSchema).default('NAMED_FILTER'),
    is_enabled: z.boolean().default(true),
    field_type: z.enum(['dimension']).default('dimension'),
    is_result_filter: z.boolean().default(false),
    conditions: z.array(z.lazy(() => namedFilterOptionConditionCreateSchema)),
    named_filter_id: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Create NamedFilterOption Schema.')