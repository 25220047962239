// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/preregisterUserSchema.ts"
);
import.meta.hot.lastModified = "1737935766771.7522";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { preregistrationCreateSchema } from './preregistrationCreateSchema'
import { preregistrationSchema } from './preregistrationSchema'
import { z } from 'zod'

export const preregisterUserQueryParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const preregisterUser200Schema = z.lazy(() => preregistrationSchema)

/**
 * @description Validation Error
 */
export const preregisterUser422Schema = z.lazy(() => httpValidationErrorSchema)

export const preregisterUserMutationRequestSchema = z.lazy(() => preregistrationCreateSchema)

export const preregisterUserMutationResponseSchema = z.lazy(() => preregisterUser200Schema)