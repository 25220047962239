// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadCellsBasic.ts"
);
import.meta.hot.lastModified = "1737759759258.0312";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadCellsBasicQueryResponse, ReadCellsBasicPathParams, ReadCellsBasicQueryParams, ReadCellsBasic422 } from '../types/ReadCellsBasic'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readCellsBasicQueryKey = (
  { accountName, conversationId }: { accountName: ReadCellsBasicPathParams['account_name']; conversationId: ReadCellsBasicPathParams['conversation_id'] },
  params?: ReadCellsBasicQueryParams,
) =>
  [
    { url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/basic', params: { accountName: accountName, conversationId: conversationId } },
    ...(params ? [params] : []),
  ] as const

export type ReadCellsBasicQueryKey = ReturnType<typeof readCellsBasicQueryKey>

/**
 * @description Read Bumblebee Cells for a conversation.
 * @summary Read Cells Basic
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/basic}
 */
async function readCellsBasic(
  {
    accountName,
    conversationId,
    params,
  }: { accountName: ReadCellsBasicPathParams['account_name']; conversationId: ReadCellsBasicPathParams['conversation_id']; params?: ReadCellsBasicQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadCellsBasicQueryResponse, ReadCellsBasic422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/basic`,
    params,
    ...config,
  })
  return res.data
}

export function readCellsBasicQueryOptions(
  {
    accountName,
    conversationId,
    params,
  }: { accountName: ReadCellsBasicPathParams['account_name']; conversationId: ReadCellsBasicPathParams['conversation_id']; params?: ReadCellsBasicQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readCellsBasicQueryKey({ accountName, conversationId }, params)
  return queryOptions({
    enabled: !!(accountName && conversationId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readCellsBasic({ accountName, conversationId, params }, config)
    },
  })
}

/**
 * @description Read Bumblebee Cells for a conversation.
 * @summary Read Cells Basic
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/basic}
 */
export function useReadCellsBasic<
  TData = ReadCellsBasicQueryResponse,
  TQueryData = ReadCellsBasicQueryResponse,
  TQueryKey extends QueryKey = ReadCellsBasicQueryKey,
>(
  {
    accountName,
    conversationId,
    params,
  }: { accountName: ReadCellsBasicPathParams['account_name']; conversationId: ReadCellsBasicPathParams['conversation_id']; params?: ReadCellsBasicQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadCellsBasicQueryResponse, ReadCellsBasic422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readCellsBasicQueryKey({ accountName, conversationId }, params)

  const query = useQuery({
    ...(readCellsBasicQueryOptions({ accountName, conversationId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadCellsBasic422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}