// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createUserOpenSchema.ts"
);
import.meta.hot.lastModified = "1737759759433.0303";
}
// REMIX HMR END

// @ts-nocheck
import { bodyCreateUserOpenApiV1UsersOpenPostSchema } from './bodyCreateUserOpenApiV1UsersOpenPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { userSchema } from './userSchema'
import { z } from 'zod'

/**
 * @description Successful Response
 */
export const createUserOpen200Schema = z.lazy(() => userSchema)

/**
 * @description Validation Error
 */
export const createUserOpen422Schema = z.lazy(() => httpValidationErrorSchema)

export const createUserOpenMutationRequestSchema = z.lazy(() => bodyCreateUserOpenApiV1UsersOpenPostSchema)

export const createUserOpenMutationResponseSchema = z.lazy(() => createUserOpen200Schema)