// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateDimensionIsSensitiveSchema.ts"
);
import.meta.hot.lastModified = "1737759762064.0168";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionDetailSchema } from './dimensionDetailSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateDimensionIsSensitivePathParamsSchema = z.object({
  account_name: z.string(),
  dimension_id: z.string(),
})

export const updateDimensionIsSensitiveQueryParamsSchema = z.object({
  is_sensitive: z.boolean(),
})

/**
 * @description Successful Response
 */
export const updateDimensionIsSensitive200Schema = z.lazy(() => dimensionDetailSchema)

/**
 * @description Validation Error
 */
export const updateDimensionIsSensitive422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateDimensionIsSensitiveMutationResponseSchema = z.lazy(() => updateDimensionIsSensitive200Schema)