// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/client/api.ts"
);
import.meta.hot.lastModified = "1733439085416.0662";
}
// REMIX HMR END

import { type useMutation, type useQuery } from '@tanstack/react-query'

import { unknownToErrorContent } from '@/utils'

import {
  API,
  BaseHttpRequest,
  type CancelablePromise,
  type ErrorContent,
  type OpenAPIConfig,
} from '~/api'

import { type ApiRequestOptions } from '~/api/core/ApiRequestOptions'
import { type ApiResult } from '~/api/core/ApiResult'
import { request } from '~/client/request'
import { useEnv } from '~/utils/env'

export type MutationOptions<T, V = void> = Omit<
  Parameters<typeof useMutation<T, ErrorContent, V>>[0],
  'mutationKey' | 'mutationFn'
>

export type QueryOptions<T> = Omit<
  Parameters<typeof useQuery<T, ErrorContent>>[0],
  'queryKey' | 'queryFn'
>

function forwardError(
  _config: OpenAPIConfig,
  _options: ApiRequestOptions,
  result: ApiResult,
): void {
  if (!result.ok) {
    const { body } = result
    throw unknownToErrorContent(body)
  }
}

class ForwardErrorRequest extends BaseHttpRequest {
  public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    return request(this.config, options, forwardError)
  }
}

/**
 *
 * @param csrf If true, the CSRF token will be set as the response body.
 * @returns
 */
export function useAPI() {
  // We have to refresh the token before each request to ensure that it is not
  // expired. Eventually, the backend will handle this for us (NS-9859).
  const base = useEnv()?.API_URL
  return new API({ BASE: base, WITH_CREDENTIALS: true }, ForwardErrorRequest)
}
