// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/searchVerifiedQuestionsSchema.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const searchVerifiedQuestionsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const searchVerifiedQuestionsQueryParamsSchema = z.object({
  question: z.string(),
  limit: z.number().int().default(10),
})

/**
 * @description Successful Response
 */
export const searchVerifiedQuestions200Schema = z.array(z.string())

/**
 * @description Validation Error
 */
export const searchVerifiedQuestions422Schema = z.lazy(() => httpValidationErrorSchema)

export const searchVerifiedQuestionsMutationResponseSchema = z.lazy(() => searchVerifiedQuestions200Schema)