// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRunEvalOnChat.ts"
);
import.meta.hot.lastModified = "1737935766031.756";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RunEvalOnChatMutationResponse, RunEvalOnChatPathParams, RunEvalOnChatQueryParams, RunEvalOnChat422 } from '../types/RunEvalOnChat'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const runEvalOnChatMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/chat/{chat_id}/eval' }] as const

export type RunEvalOnChatMutationKey = ReturnType<typeof runEvalOnChatMutationKey>

/**
 * @description Run eval on all of the messages sent in the reference chat.
 * @summary Run Eval On Chat
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/eval}
 */
async function runEvalOnChat(
  {
    accountName,
    chatId,
    params,
  }: { accountName: RunEvalOnChatPathParams['account_name']; chatId: RunEvalOnChatPathParams['chat_id']; params?: RunEvalOnChatQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RunEvalOnChatMutationResponse, RunEvalOnChat422, unknown>({
    method: 'POST',
    url: `/api/v3/orgs/${accountName}/chat/${chatId}/eval`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Run eval on all of the messages sent in the reference chat.
 * @summary Run Eval On Chat
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/eval}
 */
export function useRunEvalOnChat(
  options: {
    mutation?: UseMutationOptions<
      RunEvalOnChatMutationResponse,
      RunEvalOnChat422,
      { accountName: RunEvalOnChatPathParams['account_name']; chatId: RunEvalOnChatPathParams['chat_id']; params?: RunEvalOnChatQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? runEvalOnChatMutationKey()

  return useMutation<
    RunEvalOnChatMutationResponse,
    RunEvalOnChat422,
    { accountName: RunEvalOnChatPathParams['account_name']; chatId: RunEvalOnChatPathParams['chat_id']; params?: RunEvalOnChatQueryParams }
  >({
    mutationFn: async ({ accountName, chatId, params }) => {
      return runEvalOnChat({ accountName, chatId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}