// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chartConfigApiUpdateSchema.ts"
);
import.meta.hot.lastModified = "1737935768204.7444";
}
// REMIX HMR END

// @ts-nocheck
import { chartConfigDmmSelectSchema } from './chartConfigDmmSelectSchema'
import { chartTypeSchema } from './chartTypeSchema'
import { z } from 'zod'

/**
 * @description Properties to receive on Chart Config update.
 */
export const chartConfigApiUpdateSchema = z
  .object({
    type: z.union([z.lazy(() => chartTypeSchema), z.null()]).optional(),
    flip_axis: z.union([z.boolean(), z.null()]).optional(),
    semantic_query_id: z.union([z.string(), z.null()]).optional(),
    country_name: z.union([z.string(), z.null()]).optional(),
    limit: z.union([z.number().int(), z.null()]).optional(),
    selected: z.union([z.array(z.lazy(() => chartConfigDmmSelectSchema)), z.null()]).optional(),
  })
  .describe('Properties to receive on Chart Config update.')