// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/zenFieldFilterSchema.ts"
);
import.meta.hot.lastModified = "1737759759420.0305";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Metrics layer field filter.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/field_filter
 */
export const zenFieldFilterSchema = z
  .object({
    field: z.string(),
    value: z.string(),
  })
  .describe('Metrics layer field filter.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/field_filter')