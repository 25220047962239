// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSendInterrupt.ts"
);
import.meta.hot.lastModified = "1737759758962.0327";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { SendInterruptMutationResponse, SendInterruptPathParams, SendInterrupt422 } from '../types/SendInterrupt'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const sendInterruptMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/chat/{chat_id}/interrupt' }] as const

export type SendInterruptMutationKey = ReturnType<typeof sendInterruptMutationKey>

/**
 * @description Send a interrupt signal in a chat.Note that this sends the signal regardless of whether the AI is currentlygenerating
 * @summary Send Interrupt
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/interrupt}
 */
async function sendInterrupt(
  { accountName, chatId }: { accountName: SendInterruptPathParams['account_name']; chatId: SendInterruptPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<SendInterruptMutationResponse, SendInterrupt422, unknown>({
    method: 'POST',
    url: `/api/v3/orgs/${accountName}/chat/${chatId}/interrupt`,
    ...config,
  })
  return res.data
}

/**
 * @description Send a interrupt signal in a chat.Note that this sends the signal regardless of whether the AI is currentlygenerating
 * @summary Send Interrupt
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/interrupt}
 */
export function useSendInterrupt(
  options: {
    mutation?: UseMutationOptions<
      SendInterruptMutationResponse,
      SendInterrupt422,
      { accountName: SendInterruptPathParams['account_name']; chatId: SendInterruptPathParams['chat_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? sendInterruptMutationKey()

  return useMutation<
    SendInterruptMutationResponse,
    SendInterrupt422,
    { accountName: SendInterruptPathParams['account_name']; chatId: SendInterruptPathParams['chat_id'] }
  >({
    mutationFn: async ({ accountName, chatId }) => {
      return sendInterrupt({ accountName, chatId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}