// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRecomputeExprTypes.ts"
);
import.meta.hot.lastModified = "1737935767656.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RecomputeExprTypesMutationResponse, RecomputeExprTypesPathParams, RecomputeExprTypes422 } from '../types/RecomputeExprTypes'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const recomputeExprTypesMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/compute-expr-types' }] as const

export type RecomputeExprTypesMutationKey = ReturnType<typeof recomputeExprTypesMutationKey>

/**
 * @description Recompute the expr_type for all dimensions and metrics.
 * @summary Recompute Expr Types
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/compute-expr-types}
 */
async function recomputeExprTypes(
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: RecomputeExprTypesPathParams['account_name']; starSchemaDataSourceId: RecomputeExprTypesPathParams['star_schema_data_source_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RecomputeExprTypesMutationResponse, RecomputeExprTypes422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/compute-expr-types`,
    ...config,
  })
  return res.data
}

/**
 * @description Recompute the expr_type for all dimensions and metrics.
 * @summary Recompute Expr Types
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/compute-expr-types}
 */
export function useRecomputeExprTypes(
  options: {
    mutation?: UseMutationOptions<
      RecomputeExprTypesMutationResponse,
      RecomputeExprTypes422,
      { accountName: RecomputeExprTypesPathParams['account_name']; starSchemaDataSourceId: RecomputeExprTypesPathParams['star_schema_data_source_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? recomputeExprTypesMutationKey()

  return useMutation<
    RecomputeExprTypesMutationResponse,
    RecomputeExprTypes422,
    { accountName: RecomputeExprTypesPathParams['account_name']; starSchemaDataSourceId: RecomputeExprTypesPathParams['star_schema_data_source_id'] }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId }) => {
      return recomputeExprTypes({ accountName, starSchemaDataSourceId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}