// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateNamedFiltersState.ts"
);
import.meta.hot.lastModified = "1737935768622.742";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateNamedFiltersStateMutationRequest,
  UpdateNamedFiltersStateMutationResponse,
  UpdateNamedFiltersStatePathParams,
  UpdateNamedFiltersState422,
} from '../types/UpdateNamedFiltersState'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateNamedFiltersStateMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filter_states' }] as const

export type UpdateNamedFiltersStateMutationKey = ReturnType<typeof updateNamedFiltersStateMutationKey>

/**
 * @description Bulk update multiple filter's state.
 * @summary Update Named Filters State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filter_states}
 */
async function updateNamedFiltersState(
  {
    starSchemaDataSourceId,
    accountName,
    data,
  }: {
    starSchemaDataSourceId: UpdateNamedFiltersStatePathParams['star_schema_data_source_id']
    accountName: UpdateNamedFiltersStatePathParams['account_name']
    data?: UpdateNamedFiltersStateMutationRequest
  },
  config: Partial<RequestConfig<UpdateNamedFiltersStateMutationRequest>> = {},
) {
  const res = await client<UpdateNamedFiltersStateMutationResponse, UpdateNamedFiltersState422, UpdateNamedFiltersStateMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/filter_states`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Bulk update multiple filter's state.
 * @summary Update Named Filters State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filter_states}
 */
export function useUpdateNamedFiltersState(
  options: {
    mutation?: UseMutationOptions<
      UpdateNamedFiltersStateMutationResponse,
      UpdateNamedFiltersState422,
      {
        starSchemaDataSourceId: UpdateNamedFiltersStatePathParams['star_schema_data_source_id']
        accountName: UpdateNamedFiltersStatePathParams['account_name']
        data?: UpdateNamedFiltersStateMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateNamedFiltersStateMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateNamedFiltersStateMutationKey()

  return useMutation<
    UpdateNamedFiltersStateMutationResponse,
    UpdateNamedFiltersState422,
    {
      starSchemaDataSourceId: UpdateNamedFiltersStatePathParams['star_schema_data_source_id']
      accountName: UpdateNamedFiltersStatePathParams['account_name']
      data?: UpdateNamedFiltersStateMutationRequest
    }
  >({
    mutationFn: async ({ starSchemaDataSourceId, accountName, data }) => {
      return updateNamedFiltersState({ starSchemaDataSourceId, accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}