// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/taskMetaSchema.ts"
);
import.meta.hot.lastModified = "1737759757948.038";
}
// REMIX HMR END

// @ts-nocheck
import { errorContentSchema } from './errorContentSchema'
import { z } from 'zod'

/**
 * @description Metadata about the celery task.
 */
export const taskMetaSchema = z
  .object({
    progress_current: z.number().int().default(0),
    progress_total: z.number().int().default(100),
    num_results: z.union([z.number().int(), z.null()]).optional(),
    error: z.union([z.lazy(() => errorContentSchema), z.null()]).optional(),
    retrieval_ready: z.union([z.boolean(), z.null()]).optional(),
    first_query_ready: z.union([z.boolean(), z.null()]).optional(),
    second_query_ready: z.union([z.boolean(), z.null()]).optional(),
    table_result_ready: z.union([z.boolean(), z.null()]).optional(),
    sql_timed_out: z.union([z.boolean(), z.null()]).optional(),
  })
  .describe('Metadata about the celery task.')