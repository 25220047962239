// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetChats.ts"
);
import.meta.hot.lastModified = "1737935764870.7627";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetChatsQueryResponse, GetChatsPathParams, GetChatsQueryParams, GetChats422 } from '../types/GetChats'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getChatsQueryKey = ({ accountName }: { accountName: GetChatsPathParams['account_name'] }, params?: GetChatsQueryParams) =>
  [{ url: '/api/v3/orgs/:account_name/chat/', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type GetChatsQueryKey = ReturnType<typeof getChatsQueryKey>

/**
 * @description Read all chats.
 * @summary Get Chats
 * {@link /api/v3/orgs/:account_name/chat/}
 */
async function getChats(
  { accountName, params }: { accountName: GetChatsPathParams['account_name']; params?: GetChatsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetChatsQueryResponse, GetChats422, unknown>({ method: 'GET', url: `/api/v3/orgs/${accountName}/chat/`, params, ...config })
  return res.data
}

export function getChatsQueryOptions(
  { accountName, params }: { accountName: GetChatsPathParams['account_name']; params?: GetChatsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getChatsQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getChats({ accountName, params }, config)
    },
  })
}

/**
 * @description Read all chats.
 * @summary Get Chats
 * {@link /api/v3/orgs/:account_name/chat/}
 */
export function useGetChats<TData = GetChatsQueryResponse, TQueryData = GetChatsQueryResponse, TQueryKey extends QueryKey = GetChatsQueryKey>(
  { accountName, params }: { accountName: GetChatsPathParams['account_name']; params?: GetChatsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetChatsQueryResponse, GetChats422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getChatsQueryKey({ accountName }, params)

  const query = useQuery({
    ...(getChatsQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetChats422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}