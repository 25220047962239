// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tokenSchema.ts"
);
import.meta.hot.lastModified = "1737935764663.7637";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description An access token.
 */
export const tokenSchema = z
  .object({
    access_token: z.string(),
    token_type: z.string(),
  })
  .describe('An access token.')