// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteSnowflakeConnection.ts"
);
import.meta.hot.lastModified = "1737935769012.7397";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  DeleteSnowflakeConnectionMutationResponse,
  DeleteSnowflakeConnectionPathParams,
  DeleteSnowflakeConnection422,
} from '../types/DeleteSnowflakeConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteSnowflakeConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}' }] as const

export type DeleteSnowflakeConnectionMutationKey = ReturnType<typeof deleteSnowflakeConnectionMutationKey>

/**
 * @description Delete Snowflake connection.
 * @summary Delete Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id}
 */
async function deleteSnowflakeConnection(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: DeleteSnowflakeConnectionPathParams['account_name']
    snowflakeConnectionId: DeleteSnowflakeConnectionPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteSnowflakeConnectionMutationResponse, DeleteSnowflakeConnection422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/${snowflakeConnectionId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete Snowflake connection.
 * @summary Delete Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id}
 */
export function useDeleteSnowflakeConnection(
  options: {
    mutation?: UseMutationOptions<
      DeleteSnowflakeConnectionMutationResponse,
      DeleteSnowflakeConnection422,
      {
        accountName: DeleteSnowflakeConnectionPathParams['account_name']
        snowflakeConnectionId: DeleteSnowflakeConnectionPathParams['snowflake_connection_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteSnowflakeConnectionMutationKey()

  return useMutation<
    DeleteSnowflakeConnectionMutationResponse,
    DeleteSnowflakeConnection422,
    { accountName: DeleteSnowflakeConnectionPathParams['account_name']; snowflakeConnectionId: DeleteSnowflakeConnectionPathParams['snowflake_connection_id'] }
  >({
    mutationFn: async ({ accountName, snowflakeConnectionId }) => {
      return deleteSnowflakeConnection({ accountName, snowflakeConnectionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}