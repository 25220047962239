// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readPostgresConnectionTablesSchema.ts"
);
import.meta.hot.lastModified = "1737759762294.0156";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readPostgresConnectionTablesPathParamsSchema = z.object({
  account_name: z.string(),
  postgres_connection_id: z.string(),
})

export const readPostgresConnectionTablesQueryParamsSchema = z
  .object({
    schema: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readPostgresConnectionTables200Schema = z.object({}).catchall(z.object({}).catchall(z.array(z.string())))

/**
 * @description Validation Error
 */
export const readPostgresConnectionTables422Schema = z.lazy(() => httpValidationErrorSchema)

export const readPostgresConnectionTablesQueryResponseSchema = z.lazy(() => readPostgresConnectionTables200Schema)