// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetAccountUsers.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetAccountUsersQueryResponse, GetAccountUsersPathParams, GetAccountUsers422 } from '../types/GetAccountUsers'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAccountUsersQueryKey = ({ accountName }: { accountName: GetAccountUsersPathParams['account_name'] }) =>
  [{ url: '/api/v1/accounts/:account_name/users', params: { accountName: accountName } }] as const

export type GetAccountUsersQueryKey = ReturnType<typeof getAccountUsersQueryKey>

/**
 * @description Get all users in the account.
 * @summary Get Account Users
 * {@link /api/v1/accounts/:account_name/users}
 */
async function getAccountUsers({ accountName }: { accountName: GetAccountUsersPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<GetAccountUsersQueryResponse, GetAccountUsers422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/users`,
    ...config,
  })
  return res.data
}

export function getAccountUsersQueryOptions({ accountName }: { accountName: GetAccountUsersPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const queryKey = getAccountUsersQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAccountUsers({ accountName }, config)
    },
  })
}

/**
 * @description Get all users in the account.
 * @summary Get Account Users
 * {@link /api/v1/accounts/:account_name/users}
 */
export function useGetAccountUsers<
  TData = GetAccountUsersQueryResponse,
  TQueryData = GetAccountUsersQueryResponse,
  TQueryKey extends QueryKey = GetAccountUsersQueryKey,
>(
  { accountName }: { accountName: GetAccountUsersPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<GetAccountUsersQueryResponse, GetAccountUsers422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAccountUsersQueryKey({ accountName })

  const query = useQuery({
    ...(getAccountUsersQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetAccountUsers422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}