// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/displayTypeV3Schema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Display type enum.
 */
export const displayTypeV3Schema = z
  .enum([
    'unknown',
    'text',
    'numeric',
    'date',
    'datetime',
    'boolean',
    'month',
    'week',
    'quarter',
    'day_of_week',
    'year',
    'year_quarter',
    'year_month',
    'year_week',
    'year_month_day',
    'country',
    'country_code',
    'continent',
    'continent_code',
    'region',
    'region_code',
    'lat_long',
    'zip_code',
    'id',
    'url',
    'email',
    'phone',
    'count',
    'float',
    'percent',
    'percent_decimal',
    'percent_standard',
    'feet',
    'meters',
    'square_feet',
    'square_meters',
    'currency_usd',
    'currency_gbp',
    'currency_eur',
    'currency_aud',
    'currency_usd_per_square_feet',
    'currency_usd_per_square_meters',
  ])
  .describe('Display type enum.')