// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCloneConversation.ts"
);
import.meta.hot.lastModified = "1737935767415.7485";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CloneConversationMutationResponse,
  CloneConversationPathParams,
  CloneConversationQueryParams,
  CloneConversation422,
} from '../types/CloneConversation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const cloneConversationMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/clone' }] as const

export type CloneConversationMutationKey = ReturnType<typeof cloneConversationMutationKey>

/**
 * @description Clone a conversation with the new user as the creator.
 * @summary Clone Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/clone}
 */
async function cloneConversation(
  {
    accountName,
    conversationId,
    params,
  }: {
    accountName: CloneConversationPathParams['account_name']
    conversationId: CloneConversationPathParams['conversation_id']
    params?: CloneConversationQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<CloneConversationMutationResponse, CloneConversation422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/clone`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Clone a conversation with the new user as the creator.
 * @summary Clone Conversation
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/clone}
 */
export function useCloneConversation(
  options: {
    mutation?: UseMutationOptions<
      CloneConversationMutationResponse,
      CloneConversation422,
      {
        accountName: CloneConversationPathParams['account_name']
        conversationId: CloneConversationPathParams['conversation_id']
        params?: CloneConversationQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? cloneConversationMutationKey()

  return useMutation<
    CloneConversationMutationResponse,
    CloneConversation422,
    {
      accountName: CloneConversationPathParams['account_name']
      conversationId: CloneConversationPathParams['conversation_id']
      params?: CloneConversationQueryParams
    }
  >({
    mutationFn: async ({ accountName, conversationId, params }) => {
      return cloneConversation({ accountName, conversationId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}