// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadDataSourceData.ts"
);
import.meta.hot.lastModified = "1737935767656.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadDataSourceDataMutationRequest,
  ReadDataSourceDataMutationResponse,
  ReadDataSourceDataPathParams,
  ReadDataSourceDataQueryParams,
  ReadDataSourceData422,
} from '../types/ReadDataSourceData'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const readDataSourceDataMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}/data' }] as const

export type ReadDataSourceDataMutationKey = ReturnType<typeof readDataSourceDataMutationKey>

/**
 * @description Get the data for a specific data source within an SSDS.
 * @summary Read Data Source Data
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id/data}
 */
async function readDataSourceData(
  {
    accountName,
    starSchemaDataSourceId,
    dataSourceId,
    data,
    params,
  }: {
    accountName: ReadDataSourceDataPathParams['account_name']
    starSchemaDataSourceId: ReadDataSourceDataPathParams['star_schema_data_source_id']
    dataSourceId: ReadDataSourceDataPathParams['data_source_id']
    data?: ReadDataSourceDataMutationRequest
    params?: ReadDataSourceDataQueryParams
  },
  config: Partial<RequestConfig<ReadDataSourceDataMutationRequest>> = {},
) {
  const res = await client<ReadDataSourceDataMutationResponse, ReadDataSourceData422, ReadDataSourceDataMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/data_sources/${dataSourceId}/data`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Get the data for a specific data source within an SSDS.
 * @summary Read Data Source Data
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id/data}
 */
export function useReadDataSourceData(
  options: {
    mutation?: UseMutationOptions<
      ReadDataSourceDataMutationResponse,
      ReadDataSourceData422,
      {
        accountName: ReadDataSourceDataPathParams['account_name']
        starSchemaDataSourceId: ReadDataSourceDataPathParams['star_schema_data_source_id']
        dataSourceId: ReadDataSourceDataPathParams['data_source_id']
        data?: ReadDataSourceDataMutationRequest
        params?: ReadDataSourceDataQueryParams
      }
    >
    client?: Partial<RequestConfig<ReadDataSourceDataMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? readDataSourceDataMutationKey()

  return useMutation<
    ReadDataSourceDataMutationResponse,
    ReadDataSourceData422,
    {
      accountName: ReadDataSourceDataPathParams['account_name']
      starSchemaDataSourceId: ReadDataSourceDataPathParams['star_schema_data_source_id']
      dataSourceId: ReadDataSourceDataPathParams['data_source_id']
      data?: ReadDataSourceDataMutationRequest
      params?: ReadDataSourceDataQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, dataSourceId, data, params }) => {
      return readDataSourceData({ accountName, starSchemaDataSourceId, dataSourceId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}