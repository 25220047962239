// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetDmUsage.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetDmUsageQueryRequest, GetDmUsageQueryResponse, GetDmUsagePathParams, GetDmUsageQueryParams, GetDmUsage422 } from '../types/GetDmUsage'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getDmUsageQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetDmUsagePathParams['account_name']; starSchemaDataSourceId: GetDmUsagePathParams['star_schema_data_source_id'] },
  data?: GetDmUsageQueryRequest,
  params?: GetDmUsageQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/dm_usage',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
    ...(data ? [data] : []),
  ] as const

export type GetDmUsageQueryKey = ReturnType<typeof getDmUsageQueryKey>

/**
 * @description Get histogram of DM usage in semantic queries.
 * @summary Get Dm Usage
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/dm_usage}
 */
async function getDmUsage(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: GetDmUsagePathParams['account_name']
    starSchemaDataSourceId: GetDmUsagePathParams['star_schema_data_source_id']
    data?: GetDmUsageQueryRequest
    params?: GetDmUsageQueryParams
  },
  config: Partial<RequestConfig<GetDmUsageQueryRequest>> = {},
) {
  const res = await client<GetDmUsageQueryResponse, GetDmUsage422, GetDmUsageQueryRequest>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/dm_usage`,
    params,
    data,
    ...config,
  })
  return res.data
}

export function getDmUsageQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: GetDmUsagePathParams['account_name']
    starSchemaDataSourceId: GetDmUsagePathParams['star_schema_data_source_id']
    data?: GetDmUsageQueryRequest
    params?: GetDmUsageQueryParams
  },
  config: Partial<RequestConfig<GetDmUsageQueryRequest>> = {},
) {
  const queryKey = getDmUsageQueryKey({ accountName, starSchemaDataSourceId }, data, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getDmUsage({ accountName, starSchemaDataSourceId, data, params }, config)
    },
  })
}

/**
 * @description Get histogram of DM usage in semantic queries.
 * @summary Get Dm Usage
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/dm_usage}
 */
export function useGetDmUsage<TData = GetDmUsageQueryResponse, TQueryData = GetDmUsageQueryResponse, TQueryKey extends QueryKey = GetDmUsageQueryKey>(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: GetDmUsagePathParams['account_name']
    starSchemaDataSourceId: GetDmUsagePathParams['star_schema_data_source_id']
    data?: GetDmUsageQueryRequest
    params?: GetDmUsageQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetDmUsageQueryResponse, GetDmUsage422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig<GetDmUsageQueryRequest>>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getDmUsageQueryKey({ accountName, starSchemaDataSourceId }, data, params)

  const query = useQuery({
    ...(getDmUsageQueryOptions({ accountName, starSchemaDataSourceId, data, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetDmUsage422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}