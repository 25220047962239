// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useLoginAccessTokenApiKey.ts"
);
import.meta.hot.lastModified = "1737759761269.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { LoginAccessTokenApiKeyMutationResponse, LoginAccessTokenApiKeyQueryParams, LoginAccessTokenApiKey422 } from '../types/LoginAccessTokenApiKey'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const loginAccessTokenApiKeyMutationKey = () => [{ url: '/api/v1/login/access-token/api-key' }] as const

export type LoginAccessTokenApiKeyMutationKey = ReturnType<typeof loginAccessTokenApiKeyMutationKey>

/**
 * @description API key login, get a bearer token for future requests.The bearer token must be included in the Authorization header of all future requests.Include your API key in the `x-api-key` header.
 * @summary Login Access Token Api Key
 * {@link /api/v1/login/access-token/api-key}
 */
async function loginAccessTokenApiKey({ params }: { params: LoginAccessTokenApiKeyQueryParams }, config: Partial<RequestConfig> = {}) {
  const res = await client<LoginAccessTokenApiKeyMutationResponse, LoginAccessTokenApiKey422, unknown>({
    method: 'POST',
    url: `/api/v1/login/access-token/api-key`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description API key login, get a bearer token for future requests.The bearer token must be included in the Authorization header of all future requests.Include your API key in the `x-api-key` header.
 * @summary Login Access Token Api Key
 * {@link /api/v1/login/access-token/api-key}
 */
export function useLoginAccessTokenApiKey(
  options: {
    mutation?: UseMutationOptions<LoginAccessTokenApiKeyMutationResponse, LoginAccessTokenApiKey422, { params: LoginAccessTokenApiKeyQueryParams }>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? loginAccessTokenApiKeyMutationKey()

  return useMutation<LoginAccessTokenApiKeyMutationResponse, LoginAccessTokenApiKey422, { params: LoginAccessTokenApiKeyQueryParams }>({
    mutationFn: async ({ params }) => {
      return loginAccessTokenApiKey({ params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}