// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/jwtTokenSchema.ts"
);
import.meta.hot.lastModified = "1737935765096.7612";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description JWT Token info.
 */
export const jwtTokenSchema = z
  .object({
    token: z.string(),
    ttl: z.number().int(),
  })
  .describe('JWT Token info.')