// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/windowAggregationSpecificationSchema.ts"
);
import.meta.hot.lastModified = "1737759762488.0146";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionViewSchema } from './dimensionViewSchema'
import { timeGranularitySchema } from './timeGranularitySchema'
import { z } from 'zod'

/**
 * @description Window aggregation specification.
 */
export const windowAggregationSpecificationSchema = z
  .object({
    canon_date: z.lazy(() => dimensionViewSchema),
    window_size: z.number().int(),
    window_granularity: z.lazy(() => timeGranularitySchema),
  })
  .describe('Window aggregation specification.')