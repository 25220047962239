// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dimensionFilterConditionSchema.ts"
);
import.meta.hot.lastModified = "1737935768984.74";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionViewSchema } from './dimensionViewSchema'
import { tableFilterConditionSchema } from './tableFilterConditionSchema'
import { z } from 'zod'

/**
 * @description Dimension filter condition.
 */
export const dimensionFilterConditionSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    filter: z.lazy(() => tableFilterConditionSchema),
    dimension: z.lazy(() => dimensionViewSchema),
  })
  .describe('Dimension filter condition.')