// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateDatasetSchema.ts"
);
import.meta.hot.lastModified = "1737759759189.0317";
}
// REMIX HMR END

// @ts-nocheck
import { conversationCellFilterSchema } from './conversationCellFilterSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const updateDatasetPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const updateDatasetQueryParamsSchema = z.object({
  dest_ssds_id: z.string(),
  conversation_cell_filter: z.lazy(() => conversationCellFilterSchema).default('ALL'),
  overwrite_user_question_for_sensitive_cell: z.boolean().default(false),
  only_copy_conversations: z.boolean().default(false),
})

/**
 * @description Successful Response
 */
export const updateDataset201Schema = z.lazy(() => taskSchema)

/**
 * @description Validation Error
 */
export const updateDataset422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateDatasetMutationResponseSchema = z.lazy(() => updateDataset201Schema)