// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useHealthCheck.ts"
);
import.meta.hot.lastModified = "1737759758412.0356";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { HealthCheckQueryResponse } from '../types/HealthCheck'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const healthCheckQueryKey = () => [{ url: '/api/v1/healthz' }] as const

export type HealthCheckQueryKey = ReturnType<typeof healthCheckQueryKey>

/**
 * @description Return an HTTP OK.
 * @summary Health Check
 * {@link /api/v1/healthz}
 */
async function healthCheck(config: Partial<RequestConfig> = {}) {
  const res = await client<HealthCheckQueryResponse, Error, unknown>({ method: 'GET', url: `/api/v1/healthz`, ...config })
  return res.data
}

export function healthCheckQueryOptions(config: Partial<RequestConfig> = {}) {
  const queryKey = healthCheckQueryKey()
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return healthCheck(config)
    },
  })
}

/**
 * @description Return an HTTP OK.
 * @summary Health Check
 * {@link /api/v1/healthz}
 */
export function useHealthCheck<TData = HealthCheckQueryResponse, TQueryData = HealthCheckQueryResponse, TQueryKey extends QueryKey = HealthCheckQueryKey>(
  options: {
    query?: Partial<QueryObserverOptions<HealthCheckQueryResponse, Error, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? healthCheckQueryKey()

  const query = useQuery({
    ...(healthCheckQueryOptions(config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, Error> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}