// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSyncBbVerifiedCellToFeedbackChat.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  SyncBbVerifiedCellToFeedbackChatMutationRequest,
  SyncBbVerifiedCellToFeedbackChatMutationResponse,
  SyncBbVerifiedCellToFeedbackChatPathParams,
  SyncBbVerifiedCellToFeedbackChat422,
} from '../types/SyncBbVerifiedCellToFeedbackChat'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const syncBbVerifiedCellToFeedbackChatMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sync_bb_verified_cell_to_feedback_chat' }] as const

export type SyncBbVerifiedCellToFeedbackChatMutationKey = ReturnType<typeof syncBbVerifiedCellToFeedbackChatMutationKey>

/**
 * @description Convert bumblebee verified questions to chats.
 * @summary Sync Bb Verified Cell To Feedback Chat
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sync_bb_verified_cell_to_feedback_chat}
 */
async function syncBbVerifiedCellToFeedbackChat(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: SyncBbVerifiedCellToFeedbackChatPathParams['account_name']
    starSchemaDataSourceId: SyncBbVerifiedCellToFeedbackChatPathParams['star_schema_data_source_id']
    data?: SyncBbVerifiedCellToFeedbackChatMutationRequest
  },
  config: Partial<RequestConfig<SyncBbVerifiedCellToFeedbackChatMutationRequest>> = {},
) {
  const res = await client<
    SyncBbVerifiedCellToFeedbackChatMutationResponse,
    SyncBbVerifiedCellToFeedbackChat422,
    SyncBbVerifiedCellToFeedbackChatMutationRequest
  >({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/sync_bb_verified_cell_to_feedback_chat`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Convert bumblebee verified questions to chats.
 * @summary Sync Bb Verified Cell To Feedback Chat
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sync_bb_verified_cell_to_feedback_chat}
 */
export function useSyncBbVerifiedCellToFeedbackChat(
  options: {
    mutation?: UseMutationOptions<
      SyncBbVerifiedCellToFeedbackChatMutationResponse,
      SyncBbVerifiedCellToFeedbackChat422,
      {
        accountName: SyncBbVerifiedCellToFeedbackChatPathParams['account_name']
        starSchemaDataSourceId: SyncBbVerifiedCellToFeedbackChatPathParams['star_schema_data_source_id']
        data?: SyncBbVerifiedCellToFeedbackChatMutationRequest
      }
    >
    client?: Partial<RequestConfig<SyncBbVerifiedCellToFeedbackChatMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? syncBbVerifiedCellToFeedbackChatMutationKey()

  return useMutation<
    SyncBbVerifiedCellToFeedbackChatMutationResponse,
    SyncBbVerifiedCellToFeedbackChat422,
    {
      accountName: SyncBbVerifiedCellToFeedbackChatPathParams['account_name']
      starSchemaDataSourceId: SyncBbVerifiedCellToFeedbackChatPathParams['star_schema_data_source_id']
      data?: SyncBbVerifiedCellToFeedbackChatMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return syncBbVerifiedCellToFeedbackChat({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}