// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateTeradataConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935769010.7397";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { teradataConnectionSchema } from './teradataConnectionSchema'
import { teradataConnectionUpdateSchema } from './teradataConnectionUpdateSchema'
import { z } from 'zod'

export const updateTeradataConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  teradata_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const updateTeradataConnection200Schema = z.lazy(() => teradataConnectionSchema)

/**
 * @description Validation Error
 */
export const updateTeradataConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateTeradataConnectionMutationRequestSchema = z.lazy(() => teradataConnectionUpdateSchema)

export const updateTeradataConnectionMutationResponseSchema = z.lazy(() => updateTeradataConnection200Schema)