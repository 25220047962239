// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRemoveSqlValidationsForSsds.ts"
);
import.meta.hot.lastModified = "1737759762071.0168";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RemoveSqlValidationsForSsdsMutationResponse,
  RemoveSqlValidationsForSsdsPathParams,
  RemoveSqlValidationsForSsdsQueryParams,
  RemoveSqlValidationsForSsds422,
} from '../types/RemoveSqlValidationsForSsds'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const removeSqlValidationsForSsdsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation' }] as const

export type RemoveSqlValidationsForSsdsMutationKey = ReturnType<typeof removeSqlValidationsForSsdsMutationKey>

/**
 * @description Delete the learned DMs an SSDS. This effectively resets the drafted learned DMs.
 * @summary Remove Sql Validations For Ssds
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sql_validation}
 */
async function removeSqlValidationsForSsds(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: RemoveSqlValidationsForSsdsPathParams['account_name']
    starSchemaDataSourceId: RemoveSqlValidationsForSsdsPathParams['star_schema_data_source_id']
    params?: RemoveSqlValidationsForSsdsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RemoveSqlValidationsForSsdsMutationResponse, RemoveSqlValidationsForSsds422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/sql_validation`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Delete the learned DMs an SSDS. This effectively resets the drafted learned DMs.
 * @summary Remove Sql Validations For Ssds
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/sql_validation}
 */
export function useRemoveSqlValidationsForSsds(
  options: {
    mutation?: UseMutationOptions<
      RemoveSqlValidationsForSsdsMutationResponse,
      RemoveSqlValidationsForSsds422,
      {
        accountName: RemoveSqlValidationsForSsdsPathParams['account_name']
        starSchemaDataSourceId: RemoveSqlValidationsForSsdsPathParams['star_schema_data_source_id']
        params?: RemoveSqlValidationsForSsdsQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? removeSqlValidationsForSsdsMutationKey()

  return useMutation<
    RemoveSqlValidationsForSsdsMutationResponse,
    RemoveSqlValidationsForSsds422,
    {
      accountName: RemoveSqlValidationsForSsdsPathParams['account_name']
      starSchemaDataSourceId: RemoveSqlValidationsForSsdsPathParams['star_schema_data_source_id']
      params?: RemoveSqlValidationsForSsdsQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params }) => {
      return removeSqlValidationsForSsds({ accountName, starSchemaDataSourceId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}