// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/TaskStatusV2.ts"
);
import.meta.hot.lastModified = "1737759746544.097";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumerates the possible task statuses.
 *
 * Attributes:
 * PROGRESS: The task started and is in progress.
 * SUCCESS: The task completed successfully.
 * FAILURE: The task failed.
 * PENDING: The task is waiting to start.
 * CANCELLED: The task was cancelled.
 */
export enum TaskStatusV2 {
  PROGRESS = 'PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
}
