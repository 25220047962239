// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bigQueryConnectionLookupSchema.ts"
);
import.meta.hot.lastModified = "1737935768922.7402";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description BigQuery Connection Lookup.
 */
export const bigQueryConnectionLookupSchema = z
  .object({
    project_id: z.string(),
    location: z.string(),
  })
  .describe('BigQuery Connection Lookup.')