// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetMessagePromptlog.ts"
);
import.meta.hot.lastModified = "1737759760708.0237";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetMessagePromptlogQueryResponse, GetMessagePromptlogPathParams, GetMessagePromptlog422 } from '../types/GetMessagePromptlog'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getMessagePromptlogQueryKey = ({
  accountName,
  messageId,
}: {
  accountName: GetMessagePromptlogPathParams['account_name']
  messageId: GetMessagePromptlogPathParams['message_id']
}) => [{ url: '/api/v3/orgs/:account_name/messages/:message_id/promptlogs', params: { accountName: accountName, messageId: messageId } }] as const

export type GetMessagePromptlogQueryKey = ReturnType<typeof getMessagePromptlogQueryKey>

/**
 * @description Get chat message promptlogs.
 * @summary Get Message Promptlog
 * {@link /api/v3/orgs/:account_name/messages/:message_id/promptlogs}
 */
async function getMessagePromptlog(
  { accountName, messageId }: { accountName: GetMessagePromptlogPathParams['account_name']; messageId: GetMessagePromptlogPathParams['message_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetMessagePromptlogQueryResponse, GetMessagePromptlog422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/messages/${messageId}/promptlogs`,
    ...config,
  })
  return res.data
}

export function getMessagePromptlogQueryOptions(
  { accountName, messageId }: { accountName: GetMessagePromptlogPathParams['account_name']; messageId: GetMessagePromptlogPathParams['message_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getMessagePromptlogQueryKey({ accountName, messageId })
  return queryOptions({
    enabled: !!(accountName && messageId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getMessagePromptlog({ accountName, messageId }, config)
    },
  })
}

/**
 * @description Get chat message promptlogs.
 * @summary Get Message Promptlog
 * {@link /api/v3/orgs/:account_name/messages/:message_id/promptlogs}
 */
export function useGetMessagePromptlog<
  TData = GetMessagePromptlogQueryResponse,
  TQueryData = GetMessagePromptlogQueryResponse,
  TQueryKey extends QueryKey = GetMessagePromptlogQueryKey,
>(
  { accountName, messageId }: { accountName: GetMessagePromptlogPathParams['account_name']; messageId: GetMessagePromptlogPathParams['message_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetMessagePromptlogQueryResponse, GetMessagePromptlog422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getMessagePromptlogQueryKey({ accountName, messageId })

  const query = useQuery({
    ...(getMessagePromptlogQueryOptions({ accountName, messageId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetMessagePromptlog422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}