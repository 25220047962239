// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateMessageAdminFeedback.ts"
);
import.meta.hot.lastModified = "1737935769208.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateMessageAdminFeedbackMutationRequest,
  UpdateMessageAdminFeedbackMutationResponse,
  UpdateMessageAdminFeedbackPathParams,
  UpdateMessageAdminFeedback422,
} from '../types/UpdateMessageAdminFeedback'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateMessageAdminFeedbackMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/messages/{message_id}/admin_feedback' }] as const

export type UpdateMessageAdminFeedbackMutationKey = ReturnType<typeof updateMessageAdminFeedbackMutationKey>

/**
 * @description Update the thumbs up / down admin-provided feedback on a chat message.Admin feedback for the system response to a user question is associated with theuser question, instead of the response messages. Only admin messages canreceive feedback at this time.Any admin can provide admin feedback on messages within the chat. Note that adminscan only provide feedback on their own chats or other chats that already have adminfeedback.WARNING: This endpoint is experimental and its behavior may change without warning.
 * @summary Update Message Admin Feedback (Experimental 🧪)
 * {@link /api/v3/orgs/:account_name/messages/:message_id/admin_feedback}
 */
async function updateMessageAdminFeedback(
  {
    accountName,
    messageId,
    data,
  }: {
    accountName: UpdateMessageAdminFeedbackPathParams['account_name']
    messageId: UpdateMessageAdminFeedbackPathParams['message_id']
    data: UpdateMessageAdminFeedbackMutationRequest
  },
  config: Partial<RequestConfig<UpdateMessageAdminFeedbackMutationRequest>> = {},
) {
  const res = await client<UpdateMessageAdminFeedbackMutationResponse, UpdateMessageAdminFeedback422, UpdateMessageAdminFeedbackMutationRequest>({
    method: 'PUT',
    url: `/api/v3/orgs/${accountName}/messages/${messageId}/admin_feedback`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update the thumbs up / down admin-provided feedback on a chat message.Admin feedback for the system response to a user question is associated with theuser question, instead of the response messages. Only admin messages canreceive feedback at this time.Any admin can provide admin feedback on messages within the chat. Note that adminscan only provide feedback on their own chats or other chats that already have adminfeedback.WARNING: This endpoint is experimental and its behavior may change without warning.
 * @summary Update Message Admin Feedback (Experimental 🧪)
 * {@link /api/v3/orgs/:account_name/messages/:message_id/admin_feedback}
 */
export function useUpdateMessageAdminFeedback(
  options: {
    mutation?: UseMutationOptions<
      UpdateMessageAdminFeedbackMutationResponse,
      UpdateMessageAdminFeedback422,
      {
        accountName: UpdateMessageAdminFeedbackPathParams['account_name']
        messageId: UpdateMessageAdminFeedbackPathParams['message_id']
        data: UpdateMessageAdminFeedbackMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateMessageAdminFeedbackMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMessageAdminFeedbackMutationKey()

  return useMutation<
    UpdateMessageAdminFeedbackMutationResponse,
    UpdateMessageAdminFeedback422,
    {
      accountName: UpdateMessageAdminFeedbackPathParams['account_name']
      messageId: UpdateMessageAdminFeedbackPathParams['message_id']
      data: UpdateMessageAdminFeedbackMutationRequest
    }
  >({
    mutationFn: async ({ accountName, messageId, data }) => {
      return updateMessageAdminFeedback({ accountName, messageId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}