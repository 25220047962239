// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/healthCheckSchema.ts"
);
import.meta.hot.lastModified = "1737759758413.0356";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Successful Response
 */
export const healthCheck200Schema = z.unknown()

export const healthCheckQueryResponseSchema = z.lazy(() => healthCheck200Schema)