// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/indexReportsSchema.ts"
);
import.meta.hot.lastModified = "1737759758961.0327";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { reportSchema } from './reportSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const indexReportsPathParamsSchema = z.object({
  account_name: z.string(),
})

export const indexReportsQueryParamsSchema = z
  .object({
    force: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const indexReports201Schema = z.lazy(() => taskSchema)

/**
 * @description Validation Error
 */
export const indexReports422Schema = z.lazy(() => httpValidationErrorSchema)

export const indexReportsMutationRequestSchema = z.array(z.lazy(() => reportSchema))

export const indexReportsMutationResponseSchema = z.lazy(() => indexReports201Schema)