// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/LoginV2Service.ts"
);
import.meta.hot.lastModified = "1737759746380.0977";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Token } from '../models/Token';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoginV2Service {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Login Api Key
   * API key login, get an access token for future requests.
   *
   * Args:
   * email: The email address of the user to associate usage with.
   * display_name: The display name of the user.
   * m2m_token: The machine-to-machine token to store in the cache.
   * @param accountName
   * @param email
   * @param displayName
   * @param m2MToken
   * @returns Token Successful Response
   * @throws ApiError
   */
  public loginApiKey(
    accountName: string,
    email: string,
    displayName: string,
    m2MToken?: (string | null),
  ): CancelablePromise<Token> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orgs/{account_name}/login/api-key',
      path: {
        'account_name': accountName,
      },
      query: {
        'email': email,
        'display_name': displayName,
        'm2m_token': m2MToken,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
