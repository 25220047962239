// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateMetric.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateMetricMutationRequest, CreateMetricMutationResponse, CreateMetricPathParams, CreateMetric422 } from '../types/CreateMetric'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createMetricMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/metrics/' }] as const

export type CreateMetricMutationKey = ReturnType<typeof createMetricMutationKey>

/**
 * @description Create a metric.
 * @summary Create Metric
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/metrics/}
 */
async function createMetric(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: CreateMetricPathParams['account_name']
    starSchemaDataSourceId: CreateMetricPathParams['star_schema_data_source_id']
    data: CreateMetricMutationRequest
  },
  config: Partial<RequestConfig<CreateMetricMutationRequest>> = {},
) {
  const res = await client<CreateMetricMutationResponse, CreateMetric422, CreateMetricMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/metrics/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a metric.
 * @summary Create Metric
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/metrics/}
 */
export function useCreateMetric(
  options: {
    mutation?: UseMutationOptions<
      CreateMetricMutationResponse,
      CreateMetric422,
      {
        accountName: CreateMetricPathParams['account_name']
        starSchemaDataSourceId: CreateMetricPathParams['star_schema_data_source_id']
        data: CreateMetricMutationRequest
      }
    >
    client?: Partial<RequestConfig<CreateMetricMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createMetricMutationKey()

  return useMutation<
    CreateMetricMutationResponse,
    CreateMetric422,
    {
      accountName: CreateMetricPathParams['account_name']
      starSchemaDataSourceId: CreateMetricPathParams['star_schema_data_source_id']
      data: CreateMetricMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return createMetric({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}