// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateMetricName.ts"
);
import.meta.hot.lastModified = "1737935767038.7507";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateMetricNameMutationResponse, UpdateMetricNamePathParams, UpdateMetricNameQueryParams, UpdateMetricName422 } from '../types/UpdateMetricName'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateMetricNameMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/name' }] as const

export type UpdateMetricNameMutationKey = ReturnType<typeof updateMetricNameMutationKey>

/**
 * @description Update a metric's name.
 * @summary Update Metric Name
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/name}
 */
async function updateMetricName(
  {
    accountName,
    metricId,
    params,
  }: { accountName: UpdateMetricNamePathParams['account_name']; metricId: UpdateMetricNamePathParams['metric_id']; params: UpdateMetricNameQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateMetricNameMutationResponse, UpdateMetricName422, unknown>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/${metricId}/name`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Update a metric's name.
 * @summary Update Metric Name
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/name}
 */
export function useUpdateMetricName(
  options: {
    mutation?: UseMutationOptions<
      UpdateMetricNameMutationResponse,
      UpdateMetricName422,
      { accountName: UpdateMetricNamePathParams['account_name']; metricId: UpdateMetricNamePathParams['metric_id']; params: UpdateMetricNameQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMetricNameMutationKey()

  return useMutation<
    UpdateMetricNameMutationResponse,
    UpdateMetricName422,
    { accountName: UpdateMetricNamePathParams['account_name']; metricId: UpdateMetricNamePathParams['metric_id']; params: UpdateMetricNameQueryParams }
  >({
    mutationFn: async ({ accountName, metricId, params }) => {
      return updateMetricName({ accountName, metricId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}