// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useLogoutAccessToken.ts"
);
import.meta.hot.lastModified = "1737759760212.0264";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { LogoutAccessTokenMutationResponse, LogoutAccessToken422 } from '../types/LogoutAccessToken'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const logoutAccessTokenMutationKey = () => [{ url: '/api/v1/logout/access-token' }] as const

export type LogoutAccessTokenMutationKey = ReturnType<typeof logoutAccessTokenMutationKey>

/**
 * @description Logout and revoke the access token.
 * @summary Logout Access Token
 * {@link /api/v1/logout/access-token}
 */
async function logoutAccessToken(config: Partial<RequestConfig> = {}) {
  const res = await client<LogoutAccessTokenMutationResponse, LogoutAccessToken422, unknown>({ method: 'POST', url: `/api/v1/logout/access-token`, ...config })
  return res.data
}

/**
 * @description Logout and revoke the access token.
 * @summary Logout Access Token
 * {@link /api/v1/logout/access-token}
 */
export function useLogoutAccessToken(
  options: {
    mutation?: UseMutationOptions<LogoutAccessTokenMutationResponse, LogoutAccessToken422>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? logoutAccessTokenMutationKey()

  return useMutation<LogoutAccessTokenMutationResponse, LogoutAccessToken422>({
    mutationFn: async () => {
      return logoutAccessToken(config)
    },
    mutationKey,
    ...mutationOptions,
  })
}