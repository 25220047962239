// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ConnectionStatus.ts"
);
import.meta.hot.lastModified = "1737759758961.0327";
}
// REMIX HMR END

export enum ConnectionStatus {
  'success' = 'success',
  'failure' = 'failure',
}