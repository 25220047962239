// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readModeConnectionsSchema.ts"
);
import.meta.hot.lastModified = "1737935768070.745";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { modeConnectionSchema } from './modeConnectionSchema'
import { z } from 'zod'

export const readModeConnectionsPathParamsSchema = z.object({
  account_name: z.string(),
})

export const readModeConnectionsQueryParamsSchema = z
  .object({
    limit: z.number().int().default(100),
    skip: z.number().int().default(0),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readModeConnections200Schema = z.array(z.lazy(() => modeConnectionSchema))

/**
 * @description Validation Error
 */
export const readModeConnections422Schema = z.lazy(() => httpValidationErrorSchema)

export const readModeConnectionsQueryResponseSchema = z.lazy(() => readModeConnections200Schema)