// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/rmAccountAdminsSchema.ts"
);
import.meta.hot.lastModified = "1737935766769.7522";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const rmAccountAdminsPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const rmAccountAdmins200Schema = z.array(z.string())

/**
 * @description Validation Error
 */
export const rmAccountAdmins422Schema = z.lazy(() => httpValidationErrorSchema)

export const rmAccountAdminsMutationRequestSchema = z.array(z.string())

export const rmAccountAdminsMutationResponseSchema = z.lazy(() => rmAccountAdmins200Schema)