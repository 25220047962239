// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bigQueryLocationSchema.ts"
);
import.meta.hot.lastModified = "1737935767038.7507";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Enum of all supported BigQuery locations.\n\nRef: https://cloud.google.com/bigquery/docs/locations
 */
export const bigQueryLocationSchema = z
  .enum([
    'asia-east1',
    'asia-east2',
    'asia-northeast1',
    'asia-northeast2',
    'asia-northeast3',
    'asia-south1',
    'asia-south2',
    'asia-southeast2',
    'asia-southeast3',
    'australia-southeast1',
    'australia-southeast2',
    'EU',
    'europe-central2',
    'europe-north1',
    'europe-southwest1',
    'europe-west1',
    'europe-west2',
    'europe-west3',
    'europe-west4',
    'europe-west6',
    'europe-west8',
    'europe-west9',
    'me-west1',
    'northamerica-northeast1',
    'northamerica-northeast2',
    'southamerica-southeast1',
    'southamerica-southwest1',
    'us-central1',
    'us-east1',
    'us-east4',
    'us-east5',
    'US',
    'us-west1',
    'us-west2',
    'us-west3',
    'us-west4',
  ])
  .describe('Enum of all supported BigQuery locations.\n\nRef: https://cloud.google.com/bigquery/docs/locations')