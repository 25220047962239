// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createTableFromReportReturnInfoSchema.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Information used in creating a table from a report.
 */
export const createTableFromReportReturnInfoSchema = z
  .object({
    report_name: z.string(),
    query_token: z.string(),
    run_token: z.string(),
    table_database: z.string(),
    table_schema: z.string(),
    table_name: z.string(),
    statements: z.array(z.string()),
  })
  .describe('Information used in creating a table from a report.')