// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/validateCellFilterSchema.ts"
);
import.meta.hot.lastModified = "1737935767878.746";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionFilterSchema } from './dimensionFilterSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { metricFilterSchema } from './metricFilterSchema'
import { z } from 'zod'

export const validateCellFilterPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

/**
 * @description Successful Response
 */
export const validateCellFilter200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const validateCellFilter422Schema = z.lazy(() => httpValidationErrorSchema)

export const validateCellFilterMutationRequestSchema = z.union([z.lazy(() => dimensionFilterSchema), z.lazy(() => metricFilterSchema)])

export const validateCellFilterMutationResponseSchema = z.lazy(() => validateCellFilter200Schema)