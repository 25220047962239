// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateMetricState.ts"
);
import.meta.hot.lastModified = "1737935767420.7485";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateMetricStateMutationRequest,
  UpdateMetricStateMutationResponse,
  UpdateMetricStatePathParams,
  UpdateMetricStateQueryParams,
  UpdateMetricState422,
} from '../types/UpdateMetricState'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateMetricStateMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/state' }] as const

export type UpdateMetricStateMutationKey = ReturnType<typeof updateMetricStateMutationKey>

/**
 * @description Update a metric's state.
 * @summary Update Metric State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/state}
 */
async function updateMetricState(
  {
    accountName,
    metricId,
    data,
    params,
  }: {
    accountName: UpdateMetricStatePathParams['account_name']
    metricId: UpdateMetricStatePathParams['metric_id']
    data: UpdateMetricStateMutationRequest
    params?: UpdateMetricStateQueryParams
  },
  config: Partial<RequestConfig<UpdateMetricStateMutationRequest>> = {},
) {
  const res = await client<UpdateMetricStateMutationResponse, UpdateMetricState422, UpdateMetricStateMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/${metricId}/state`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a metric's state.
 * @summary Update Metric State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/state}
 */
export function useUpdateMetricState(
  options: {
    mutation?: UseMutationOptions<
      UpdateMetricStateMutationResponse,
      UpdateMetricState422,
      {
        accountName: UpdateMetricStatePathParams['account_name']
        metricId: UpdateMetricStatePathParams['metric_id']
        data: UpdateMetricStateMutationRequest
        params?: UpdateMetricStateQueryParams
      }
    >
    client?: Partial<RequestConfig<UpdateMetricStateMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMetricStateMutationKey()

  return useMutation<
    UpdateMetricStateMutationResponse,
    UpdateMetricState422,
    {
      accountName: UpdateMetricStatePathParams['account_name']
      metricId: UpdateMetricStatePathParams['metric_id']
      data: UpdateMetricStateMutationRequest
      params?: UpdateMetricStateQueryParams
    }
  >({
    mutationFn: async ({ accountName, metricId, data, params }) => {
      return updateMetricState({ accountName, metricId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}