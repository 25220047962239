// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ChatMessageType.ts"
);
import.meta.hot.lastModified = "1737759758432.0356";
}
// REMIX HMR END

export enum ChatMessageType {
  'STANDARD' = 'STANDARD',
  'SIGNAL' = 'SIGNAL',
  'EPHEMERAL' = 'EPHEMERAL',
  'ERROR' = 'ERROR',
}