// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useClearOpenSearchIndex.ts"
);
import.meta.hot.lastModified = "1737935768071.745";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ClearOpenSearchIndexMutationResponse, ClearOpenSearchIndexPathParams, ClearOpenSearchIndex422 } from '../types/ClearOpenSearchIndex'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const clearOpenSearchIndexMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/datasets/clear_open_search_index' }] as const

export type ClearOpenSearchIndexMutationKey = ReturnType<typeof clearOpenSearchIndexMutationKey>

/**
 * @description Clear the open search index for the account.This endpoint is used to clear the open search index for the account. This is useful when thefields in the index are updated and need to be reindexed.
 * @summary Clear Open Search Index
 * {@link /api/v1/accounts/:account_name/datasets/clear_open_search_index}
 */
async function clearOpenSearchIndex({ accountName }: { accountName: ClearOpenSearchIndexPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<ClearOpenSearchIndexMutationResponse, ClearOpenSearchIndex422, unknown>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/datasets/clear_open_search_index`,
    ...config,
  })
  return res.data
}

/**
 * @description Clear the open search index for the account.This endpoint is used to clear the open search index for the account. This is useful when thefields in the index are updated and need to be reindexed.
 * @summary Clear Open Search Index
 * {@link /api/v1/accounts/:account_name/datasets/clear_open_search_index}
 */
export function useClearOpenSearchIndex(
  options: {
    mutation?: UseMutationOptions<
      ClearOpenSearchIndexMutationResponse,
      ClearOpenSearchIndex422,
      { accountName: ClearOpenSearchIndexPathParams['account_name'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? clearOpenSearchIndexMutationKey()

  return useMutation<ClearOpenSearchIndexMutationResponse, ClearOpenSearchIndex422, { accountName: ClearOpenSearchIndexPathParams['account_name'] }>({
    mutationFn: async ({ accountName }) => {
      return clearOpenSearchIndex({ accountName }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}