// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bigQueryConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759760680.024";
}
// REMIX HMR END

// @ts-nocheck
import { bigQueryLocationSchema } from './bigQueryLocationSchema'
import { connectionPolyTypeSchema } from './connectionPolyTypeSchema'
import { z } from 'zod'

/**
 * @description Additional properties to return via API.
 */
export const bigQueryConnectionSchema = z
  .object({
    name: z.string(),
    account_id: z.union([z.string(), z.null()]).optional(),
    is_demo: z.boolean(),
    is_draft: z.boolean().default(false),
    status: z.union([z.string(), z.null()]).optional(),
    status_at: z.union([z.string().datetime(), z.null()]).optional(),
    type: z.union([z.lazy(() => connectionPolyTypeSchema), z.null()]).optional(),
    max_tables: z.number().int().default(8000),
    warehouse_access: z.union([z.boolean(), z.null()]).optional(),
    result_cache_access: z.union([z.boolean(), z.null()]).optional(),
    error_msg: z.union([z.string(), z.null()]).optional(),
    ns_database: z.union([z.string(), z.null()]).optional(),
    ns_schema: z.string(),
    location: z.lazy(() => bigQueryLocationSchema),
    id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    project_id: z.union([z.string(), z.null()]).optional(),
    private_key_id: z.string(),
  })
  .describe('Additional properties to return via API.')