// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetAccountSsdsNamedFiltersCount.ts"
);
import.meta.hot.lastModified = "1737759762516.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetAccountSsdsNamedFiltersCountQueryResponse,
  GetAccountSsdsNamedFiltersCountPathParams,
  GetAccountSsdsNamedFiltersCountQueryParams,
  GetAccountSsdsNamedFiltersCount422,
} from '../types/GetAccountSsdsNamedFiltersCount'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getAccountSsdsNamedFiltersCountQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetAccountSsdsNamedFiltersCountPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsNamedFiltersCountPathParams['star_schema_data_source_id']
  },
  params?: GetAccountSsdsNamedFiltersCountQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters_count',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetAccountSsdsNamedFiltersCountQueryKey = ReturnType<typeof getAccountSsdsNamedFiltersCountQueryKey>

/**
 * @description Get Named Filters from the given account and ssds.If DataSourceIds are specified, then only metrics that referencethe specified Data Sources are returned.
 * @summary Get Account Ssds Named Filters Count
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters_count}
 */
async function getAccountSsdsNamedFiltersCount(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetAccountSsdsNamedFiltersCountPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsNamedFiltersCountPathParams['star_schema_data_source_id']
    params?: GetAccountSsdsNamedFiltersCountQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetAccountSsdsNamedFiltersCountQueryResponse, GetAccountSsdsNamedFiltersCount422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/filters_count`,
    params,
    ...config,
  })
  return res.data
}

export function getAccountSsdsNamedFiltersCountQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetAccountSsdsNamedFiltersCountPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsNamedFiltersCountPathParams['star_schema_data_source_id']
    params?: GetAccountSsdsNamedFiltersCountQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getAccountSsdsNamedFiltersCountQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getAccountSsdsNamedFiltersCount({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get Named Filters from the given account and ssds.If DataSourceIds are specified, then only metrics that referencethe specified Data Sources are returned.
 * @summary Get Account Ssds Named Filters Count
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters_count}
 */
export function useGetAccountSsdsNamedFiltersCount<
  TData = GetAccountSsdsNamedFiltersCountQueryResponse,
  TQueryData = GetAccountSsdsNamedFiltersCountQueryResponse,
  TQueryKey extends QueryKey = GetAccountSsdsNamedFiltersCountQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetAccountSsdsNamedFiltersCountPathParams['account_name']
    starSchemaDataSourceId: GetAccountSsdsNamedFiltersCountPathParams['star_schema_data_source_id']
    params?: GetAccountSsdsNamedFiltersCountQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetAccountSsdsNamedFiltersCountQueryResponse, GetAccountSsdsNamedFiltersCount422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getAccountSsdsNamedFiltersCountQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getAccountSsdsNamedFiltersCountQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetAccountSsdsNamedFiltersCount422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}