// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRmAccountUsers.ts"
);
import.meta.hot.lastModified = "1737935766295.755";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RmAccountUsersMutationRequest, RmAccountUsersMutationResponse, RmAccountUsersPathParams, RmAccountUsers422 } from '../types/RmAccountUsers'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const rmAccountUsersMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/users' }] as const

export type RmAccountUsersMutationKey = ReturnType<typeof rmAccountUsersMutationKey>

/**
 * @description Remove users to the account.Returns a list of the IDs which were invalid.
 * @summary Rm Account Users
 * {@link /api/v1/accounts/:account_name/users}
 */
async function rmAccountUsers(
  { accountName, data }: { accountName: RmAccountUsersPathParams['account_name']; data?: RmAccountUsersMutationRequest },
  config: Partial<RequestConfig<RmAccountUsersMutationRequest>> = {},
) {
  const res = await client<RmAccountUsersMutationResponse, RmAccountUsers422, RmAccountUsersMutationRequest>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/users`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Remove users to the account.Returns a list of the IDs which were invalid.
 * @summary Rm Account Users
 * {@link /api/v1/accounts/:account_name/users}
 */
export function useRmAccountUsers(
  options: {
    mutation?: UseMutationOptions<
      RmAccountUsersMutationResponse,
      RmAccountUsers422,
      { accountName: RmAccountUsersPathParams['account_name']; data?: RmAccountUsersMutationRequest }
    >
    client?: Partial<RequestConfig<RmAccountUsersMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? rmAccountUsersMutationKey()

  return useMutation<
    RmAccountUsersMutationResponse,
    RmAccountUsers422,
    { accountName: RmAccountUsersPathParams['account_name']; data?: RmAccountUsersMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return rmAccountUsers({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}