// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/PublicService.ts"
);
import.meta.hot.lastModified = "1737935753795.8235";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_login_access_token_api_v1_login_access_token_post } from '../models/Body_login_access_token_api_v1_login_access_token_post';
import type { Body_login_session_api_v1_login_session_post } from '../models/Body_login_session_api_v1_login_session_post';
import type { ChatAPICreate } from '../models/ChatAPICreate';
import type { ChatAPIResponse } from '../models/ChatAPIResponse';
import type { ChatAPIResponsePage } from '../models/ChatAPIResponsePage';
import type { ChatAPIUpdate } from '../models/ChatAPIUpdate';
import type { ChatsFilter } from '../models/ChatsFilter';
import type { ChatsSort } from '../models/ChatsSort';
import type { DataAssetAPIPage } from '../models/DataAssetAPIPage';
import type { DataPage } from '../models/DataPage';
import type { KnowledgeLayerContextAPI } from '../models/KnowledgeLayerContextAPI';
import type { MessageAPIResponse } from '../models/MessageAPIResponse';
import type { MessageAPIResponsePage } from '../models/MessageAPIResponsePage';
import type { MessageAPISendV3 } from '../models/MessageAPISendV3';
import type { MessageUpdateAdminFeedbackAPI } from '../models/MessageUpdateAdminFeedbackAPI';
import type { MessageUpdateFeedbackAPI } from '../models/MessageUpdateFeedbackAPI';
import type { Provider } from '../models/Provider';
import type { SuggestionAPI } from '../models/SuggestionAPI';
import type { Token } from '../models/Token';
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PublicService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Login Access Token
   * OAuth2-compatible token login, get a bearer token for future requests.
   *
   * The bearer token must be included in the Authorization header of all future requests.
   *
   * Note that access token authentication cannot be used to access user-specific resources
   * such as user passwords and superuser status.
   * @param formData
   * @param accountName The name of the account to validate that the user is authorized to access. If the user does not have access to this account, the endpoint will return a 403 even if the user is successfully authenticated. This is useful for third party applications which want users to be restricted to a particular account.
   * @param m2MToken A custom M2M access token in case one is needed to access resources in your environment. This is not relevant for cloud customers. It's only used for VPC deployments.
   * @returns Token Successful Response
   * @throws ApiError
   */
  public loginAccessToken(
    formData: Body_login_access_token_api_v1_login_access_token_post,
    accountName?: (string | null),
    m2MToken?: (string | null),
  ): CancelablePromise<Token> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/access-token',
      query: {
        'account_name': accountName,
        'm2m_token': m2MToken,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Logout Access Token
   * Logout and revoke the access token.
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public logoutAccessToken(
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/logout/access-token',
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Login Access Token Api Key
   * API key login, get a bearer token for future requests.
   *
   * The bearer token must be included in the Authorization header of all future requests.
   *
   * Include your API key in the `x-api-key` header.
   * @param accountName The name of the account to authenticate the API key against.
   * @param email The email address of the user in this account to authenticate.
   * @param create Whether to create the user if it doesn't exist. If set to false and the user with the email is not part of the account, the endpoint will return a 404. If set to true and the user does not exist, the endpoint will create the user and add them to the account. If set to true and the user exists, the endpoint will add the user to the account.
   * @param m2MToken A custom M2M access token in case one is needed to access resources in your environment. This is not relevant for cloud customers. It's only used for VPC deployments.
   * @returns Token Successful Response
   * @throws ApiError
   */
  public loginAccessTokenApiKey(
    accountName: string,
    email: string,
    create: boolean = false,
    m2MToken?: (string | null),
  ): CancelablePromise<Token> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/access-token/api-key',
      query: {
        'account_name': accountName,
        'email': email,
        'create': create,
        'm2m_token': m2MToken,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Login Session
   * Login and get a session-based cookie.
   *
   * The response headers will contain the cookie. Note that you must use the cookie for all
   * other requests to the API.
   * @param formData
   * @param thirdParty Whether to set a third-party cookie for cross-origin iframes.
   * @param slackId An optional base64-encoded Slack {team_id}:{user_id} to link to (for use by the NS Slack App).
   * @param accountName The name of the account to validate that the user is authorized to access. If the user does not have access to this account, the endpoint will return a 403 even if the user is successfully authenticated. This is useful for third party applications which want users to be restricted to a particular account.
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSession(
    formData: Body_login_session_api_v1_login_session_post,
    thirdParty: boolean = false,
    slackId?: (string | null),
    accountName?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/session',
      query: {
        'third_party': thirdParty,
        'slack_id': slackId,
        'account_name': accountName,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Logout Session
   * Logout and unset session-based cookie value.
   * @param thirdParty Whether to set a third-party cookie for cross-origin iframes.
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public logoutSession(
    thirdParty: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/logout/session',
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'third_party': thirdParty,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Login Session Oauth
   * Login via OAuth2.0 authorization code flow with PKCE.
   *
   * The currently supported OAuth2.0 providers are:
   * - Okta
   * - Google
   * @param provider The OAuth provider. At the moment this defaults to 'okta' but this default will be removed in the future. Please specify the provider explicitly to avoid issues.
   * @param accountName The name of the account to validate that the user is authorized to access. If the user does not have access to this account, the endpoint will return a 403 even if the user is successfully authenticated. This is useful for third party applications which want users to be restricted to a particular account.
   * @param redirectHeader Whether to redirect at the HTTP level or not. Browsers set origin=null if a cross-origin resource redirects to yet another origin. This can be problematic for an embedded iframe. Instead, setting redirect to False puts the redirect information in the response body. Then it is up to the client to handle the redirect themselves.
   * @param redirectUri The URI to redirect to after the login is complete. This is needed for clients to redirect back to the original page. When OAuth is complete, the user will be redirected to this URI. If the login is successful, a session cookie will be set. If the login is unsuccessful, an error parameter will be set in the redirect_uri query params. If this value is set to None then the redirect_uri will default to https://app.numbersstation.ai/login.
   * @param thirdParty Whether to set a third-party cookie for cross-origin iframes.
   * @param slackId An optional base64-encoded Slack {team_id}:{user_id} to link to (for use by the NS Slack App).
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSessionOauth(
    provider: Provider,
    accountName?: (string | null),
    redirectHeader: boolean = false,
    redirectUri?: (string | null),
    thirdParty: boolean = false,
    slackId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/login/session/oauth',
      query: {
        'provider': provider,
        'account_name': accountName,
        'redirect_header': redirectHeader,
        'redirect_uri': redirectUri,
        'third_party': thirdParty,
        'slack_id': slackId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Chat
   * Create a chat. This will not send a message to the chat.
   *
   * Note that you can also create a chat using the send message endpoint. Doing so will
   * let you skip making a separate call to create the chat.
   * @param accountName The name of the organization or account.
   * @param requestBody
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public createChat(
    accountName: string,
    requestBody: ChatAPICreate,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Chats
   * Read all chats.
   * @param accountName The name of the organization or account.
   * @param sortAscending Sort ascending. If false, sort descending.
   * @param sortBy The field to sort by.
   * @param filterBy Filter for a particular kind of chats.
   * @param limit The maximum number of chats to include in the response.
   * @param offset The maximum number of chats to include in the response.
   * @param creatorIds
   * @param beforeTimestamp
   * @param afterTimestamp
   * @param sessionId
   * @returns ChatAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getChats(
    accountName: string,
    sortAscending: boolean = true,
    sortBy?: ChatsSort,
    filterBy?: ChatsFilter,
    limit: number = 100,
    offset?: number,
    creatorIds?: Array<string>,
    beforeTimestamp?: (string | null),
    afterTimestamp?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponsePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'sort_ascending': sortAscending,
        'sort_by': sortBy,
        'filter_by': filterBy,
        'limit': limit,
        'offset': offset,
        'creator_ids': creatorIds,
        'before_timestamp': beforeTimestamp,
        'after_timestamp': afterTimestamp,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Chat
   * Delete a chat.
   *
   * CAUTION: This is unrecoverable. All messages associated with the chat will be deleted.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public deleteChat(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Chat
   * Update a chat.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param requestBody
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public updateChat(
    accountName: string,
    chatId: string,
    requestBody: ChatAPIUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Chat
   * Read a single chat.
   *
   * Note that this does NOT include the messages of the chat. See the get
   * chat messages endpoint (GET api/v3/orgs/{account_name}/chats/{chat_id}/messages/) for that.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public getChat(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Chat Messages
   * Get messages in a chat.
   *
   * This paginated endpoint returns a chat with up to the requested number of
   * messages. Note that the messages are ordered from newest to oldest.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param limit The maximum number of messages to include in the response.
   * @param offset The messages to skip. The response will not include indexes greater than the offset. Equivalent of 'skip' in database queries.
   * @param sessionId
   * @returns MessageAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getChatMessages(
    accountName: string,
    chatId: string,
    limit: number = 20,
    offset?: number,
    sessionId?: (string | null),
  ): CancelablePromise<MessageAPIResponsePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/messages',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'offset': offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Send Interrupt
   * Send a interrupt signal in a chat.
   *
   * Note that this sends the signal regardless of whether the AI is currently
   * generating
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public sendInterrupt(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/interrupt',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Clone Chat
   * Clone a chat with the new user as the creator.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public cloneChat(
    accountName: string,
    chatId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/clone',
      path: {
        'account_name': accountName,
        'chat_id': chatId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Send Message
   * Send a message.
   *
   * This endpoint returns a server sent event stream composed of `MessageAPIResponse` objects.
   * The stream will close when the model is done generating responses and is ready for more
   * input.
   *
   * If a chat_id is provided, the message will be sent to that chat. A chat will be created and
   * the MessageAPIResponse will contain the new chat_id. If the chat does not have a name, then
   * a name will be generated asynchronously. It should be available within a few seconds.
   *
   * If the message_text is empty, then the message will be ignored. If the chat is currently
   * busy, then this will trigger a 409 CONFLICT response. This can be useful to reattach to a
   * chat that is currently running.
   *
   * Note that messages can only be sent by the owner of the chat or by an admin in certain cases.
   * @param accountName The name of the organization or account.
   * @param requestBody
   * @param sessionId
   * @returns MessageAPIResponse Message sent successfully. Returns an event stream of `MessageAPIResponse` objects.
   * @throws ApiError
   */
  public sendMessage(
    accountName: string,
    requestBody: MessageAPISendV3,
    sessionId?: (string | null),
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `The chat or message could not be found.`,
        409: `Chat is busy. Returns an event stream of the running chat.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Refresh Query Message
   * Refresh the SQL table in a chat message.
   *
   * This will return an error if the message does not contain a table ID.
   * Note that you will need to refetch the data from the data endpoint to see the updates.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns MessageAPIResponse The table associated with the message was refreshed successfully.
   * @throws ApiError
   */
  public refreshQueryMessage(
    accountName: string,
    messageId: string,
    sessionId?: (string | null),
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/refresh',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Message Feedback (Experimental 🧪)
   * Update the thumbs up / down user-provided feedback on a chat message.
   *
   * Feedback for the system response to a user question is associated with the
   * user question, instead of the response messages. Only user messages can
   * receive feedback at this time.
   *
   * Only the chat creator can provide feedback on messages within the chat.
   *
   * WARNING: This endpoint is experimental and its behavior may change without warning.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param requestBody
   * @param sessionId
   * @returns MessageAPIResponse Successful Response
   * @throws ApiError
   */
  public updateMessageFeedback(
    accountName: string,
    messageId: string,
    requestBody: MessageUpdateFeedbackAPI,
    sessionId?: (string | null),
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/feedback',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Message Admin Feedback (Experimental 🧪)
   * Update the thumbs up / down admin-provided feedback on a chat message.
   *
   * Admin feedback for the system response to a user question is associated with the
   * user question, instead of the response messages. Only admin messages can
   * receive feedback at this time.
   *
   * Any admin can provide admin feedback on messages within the chat. Note that admins
   * can only provide feedback on their own chats or other chats that already have admin
   * feedback.
   *
   * WARNING: This endpoint is experimental and its behavior may change without warning.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param requestBody
   * @param sessionId
   * @returns MessageAPIResponse Successful Response
   * @throws ApiError
   */
  public updateMessageAdminFeedback(
    accountName: string,
    messageId: string,
    requestBody: MessageUpdateAdminFeedbackAPI,
    sessionId?: (string | null),
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/admin_feedback',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Message Table Data
   * Get the table data associated with a message.
   *
   * This will return an error if the message does not contain a table ID.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param limit The maximum number of rows to include in the response.
   * @param skip The number of rows to skip. Equivalent of 'skip' in database queries.
   * @param sort The columns to sort by. Prefix with '-' for descending order.
   * @param format If true, format the table data according to display type.
   * @param sessionId
   * @returns DataPage Successful Response
   * @throws ApiError
   */
  public readMessageTableData(
    accountName: string,
    messageId: string,
    limit: number = 100,
    skip?: number,
    sort?: (Array<string> | null),
    format: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<DataPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
        'sort': sort,
        'format': format,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        404: `The item could not be found.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Message Table Data As Csv
   * Read message table data as a CSV.
   *
   * This will return an error if the message does not contain a table ID.
   * @param accountName
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns any The table data as a CSV file.
   * @throws ApiError
   */
  public readMessageTableDataAsCsv(
    accountName: string,
    messageId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data/csv',
      path: {
        'account_name': accountName,
        'message_id': messageId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        404: `The item could not be found.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Current User
   * Get the current user.
   * @param accountName The name of the organization or account.
   * @param sessionId
   * @returns UserResponse Successful Response
   * @throws ApiError
   */
  public getCurrentUser(
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/users/me',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Data Assets For Account
   * Get data assets for an account.
   * @param accountName The name of the organization or account.
   * @param limit
   * @param offset
   * @param sessionId
   * @returns DataAssetAPIPage Successful Response
   * @throws ApiError
   */
  public getDataAssetsForAccount(
    accountName: string,
    limit: number = 100,
    offset?: number,
    sessionId?: (string | null),
  ): CancelablePromise<DataAssetAPIPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/data_assets/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'offset': offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Suggestions For Account
   * Get suggested prompts for an account across all datasets in the account.
   * @param accountName The name of the organization or account.
   * @param limit
   * @param sessionId
   * @returns SuggestionAPI Successful Response
   * @throws ApiError
   */
  public getSuggestionsForAccount(
    accountName: string,
    limit: number = 5,
    sessionId?: (string | null),
  ): CancelablePromise<Array<SuggestionAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/data_assets/suggestions',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Entity Context
   * Get the context associated with an entity.
   *
   * Args:
   * entity_id: The ID of the entity. Note that this can either be a UUID str or a base64 encoded
   * string of last 12-char of UUID string.
   * @param accountName The name of the organization or account.
   * @param entityType Type of the entity
   * @param entityId
   * @param sessionId
   * @returns KnowledgeLayerContextAPI The context associated with the entity was fetched successfully.
   * @throws ApiError
   */
  public getEntityContext(
    accountName: string,
    entityType: 'dimension' | 'metric' | 'dataset' | 'tableau' | 'mode',
    entityId: string,
    sessionId?: (string | null),
  ): CancelablePromise<KnowledgeLayerContextAPI> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/knowledge_layer/context',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'entity_type': entityType,
        'entity_id': entityId,
      },
      errors: {
        404: `The entity was not found.`,
        422: `Validation Error`,
      },
    });
  }

}
