// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/Axis.ts"
);
import.meta.hot.lastModified = "1737759746410.0974";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumerates the X and Y axis.
 */
export enum Axis {
  X = 'x',
  Y = 'y',
  SIZE = 'size',
  GROUP = 'group',
}
