// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useMergeDimensions.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { MergeDimensionsMutationRequest, MergeDimensionsMutationResponse, MergeDimensionsPathParams, MergeDimensions422 } from '../types/MergeDimensions'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const mergeDimensionsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/merge' }] as const

export type MergeDimensionsMutationKey = ReturnType<typeof mergeDimensionsMutationKey>

/**
 * @description Merge multiple dimensions into one dimension.This endpoint lets users merge multiple (similar) dimensions (ids_to_merge) into one dimension(dimension) to maintain SSDS better. Users can also update the dimension using dimension_update.
 * @summary Merge Dimensions
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/merge}
 */
async function mergeDimensions(
  {
    accountName,
    dimensionId,
    data,
  }: { accountName: MergeDimensionsPathParams['account_name']; dimensionId: MergeDimensionsPathParams['dimension_id']; data: MergeDimensionsMutationRequest },
  config: Partial<RequestConfig<MergeDimensionsMutationRequest>> = {},
) {
  const res = await client<MergeDimensionsMutationResponse, MergeDimensions422, MergeDimensionsMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions/${dimensionId}/merge`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Merge multiple dimensions into one dimension.This endpoint lets users merge multiple (similar) dimensions (ids_to_merge) into one dimension(dimension) to maintain SSDS better. Users can also update the dimension using dimension_update.
 * @summary Merge Dimensions
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id/merge}
 */
export function useMergeDimensions(
  options: {
    mutation?: UseMutationOptions<
      MergeDimensionsMutationResponse,
      MergeDimensions422,
      { accountName: MergeDimensionsPathParams['account_name']; dimensionId: MergeDimensionsPathParams['dimension_id']; data: MergeDimensionsMutationRequest }
    >
    client?: Partial<RequestConfig<MergeDimensionsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? mergeDimensionsMutationKey()

  return useMutation<
    MergeDimensionsMutationResponse,
    MergeDimensions422,
    { accountName: MergeDimensionsPathParams['account_name']; dimensionId: MergeDimensionsPathParams['dimension_id']; data: MergeDimensionsMutationRequest }
  >({
    mutationFn: async ({ accountName, dimensionId, data }) => {
      return mergeDimensions({ accountName, dimensionId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}