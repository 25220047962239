// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/executeCopyJobSchema.ts"
);
import.meta.hot.lastModified = "1737759759420.0305";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const executeCopyJobPathParamsSchema = z.object({
  enterprise_name: z.string(),
  job_id: z.string(),
})

/**
 * @description Successful Response
 */
export const executeCopyJob201Schema = z.array(z.lazy(() => taskSchema))

/**
 * @description Validation Error
 */
export const executeCopyJob422Schema = z.lazy(() => httpValidationErrorSchema)

export const executeCopyJobMutationResponseSchema = z.lazy(() => executeCopyJob201Schema)