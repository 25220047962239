// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/columnMetadataV2Schema.ts"
);
import.meta.hot.lastModified = "1737759760028.0273";
}
// REMIX HMR END

// @ts-nocheck
import { displayTypeV2Schema } from './displayTypeV2Schema'
import { z } from 'zod'

/**
 * @description Metadata for a column in a table.\n\nCAUTION: This definition is in flux. It may change.\n\nAttributes:\n    dm_name: The name of the column matching a dimension or metric.\n    display_name: The display name of the column.\n    description: The description of the column.\n    display_type: The display type of the column.
 */
export const columnMetadataV2Schema = z
  .object({
    dm_name: z.string(),
    display_name: z.string(),
    description: z.union([z.string(), z.null()]),
    display_type: z.union([z.lazy(() => displayTypeV2Schema), z.null()]),
  })
  .describe(
    'Metadata for a column in a table.\n\nCAUTION: This definition is in flux. It may change.\n\nAttributes:\n    dm_name: The name of the column matching a dimension or metric.\n    display_name: The display name of the column.\n    description: The description of the column.\n    display_type: The display type of the column.',
  )