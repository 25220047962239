// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreatePostgresConnection.ts"
);
import.meta.hot.lastModified = "1737759761660.0188";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreatePostgresConnectionMutationRequest,
  CreatePostgresConnectionMutationResponse,
  CreatePostgresConnectionPathParams,
  CreatePostgresConnection422,
} from '../types/CreatePostgresConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createPostgresConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/postgres/' }] as const

export type CreatePostgresConnectionMutationKey = ReturnType<typeof createPostgresConnectionMutationKey>

/**
 * @description Create new Postgres connection.
 * @summary Create Postgres Connection
 * {@link /api/v1/accounts/:account_name/connections/postgres/}
 */
async function createPostgresConnection(
  { accountName, data }: { accountName: CreatePostgresConnectionPathParams['account_name']; data: CreatePostgresConnectionMutationRequest },
  config: Partial<RequestConfig<CreatePostgresConnectionMutationRequest>> = {},
) {
  const res = await client<CreatePostgresConnectionMutationResponse, CreatePostgresConnection422, CreatePostgresConnectionMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/postgres/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create new Postgres connection.
 * @summary Create Postgres Connection
 * {@link /api/v1/accounts/:account_name/connections/postgres/}
 */
export function useCreatePostgresConnection(
  options: {
    mutation?: UseMutationOptions<
      CreatePostgresConnectionMutationResponse,
      CreatePostgresConnection422,
      { accountName: CreatePostgresConnectionPathParams['account_name']; data: CreatePostgresConnectionMutationRequest }
    >
    client?: Partial<RequestConfig<CreatePostgresConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createPostgresConnectionMutationKey()

  return useMutation<
    CreatePostgresConnectionMutationResponse,
    CreatePostgresConnection422,
    { accountName: CreatePostgresConnectionPathParams['account_name']; data: CreatePostgresConnectionMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return createPostgresConnection({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}