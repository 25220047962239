// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadConnections.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadConnectionsQueryResponse, ReadConnectionsPathParams, ReadConnectionsQueryParams, ReadConnections422 } from '../types/ReadConnections'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readConnectionsQueryKey = ({ accountName }: { accountName: ReadConnectionsPathParams['account_name'] }, params?: ReadConnectionsQueryParams) =>
  [{ url: '/api/v1/accounts/:account_name/connections/', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type ReadConnectionsQueryKey = ReturnType<typeof readConnectionsQueryKey>

/**
 * @description Read all connections, independent of type.
 * @summary Read Connections
 * {@link /api/v1/accounts/:account_name/connections/}
 */
async function readConnections(
  { accountName, params }: { accountName: ReadConnectionsPathParams['account_name']; params?: ReadConnectionsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadConnectionsQueryResponse, ReadConnections422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/`,
    params,
    ...config,
  })
  return res.data
}

export function readConnectionsQueryOptions(
  { accountName, params }: { accountName: ReadConnectionsPathParams['account_name']; params?: ReadConnectionsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readConnectionsQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readConnections({ accountName, params }, config)
    },
  })
}

/**
 * @description Read all connections, independent of type.
 * @summary Read Connections
 * {@link /api/v1/accounts/:account_name/connections/}
 */
export function useReadConnections<
  TData = ReadConnectionsQueryResponse,
  TQueryData = ReadConnectionsQueryResponse,
  TQueryKey extends QueryKey = ReadConnectionsQueryKey,
>(
  { accountName, params }: { accountName: ReadConnectionsPathParams['account_name']; params?: ReadConnectionsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadConnectionsQueryResponse, ReadConnections422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readConnectionsQueryKey({ accountName }, params)

  const query = useQuery({
    ...(readConnectionsQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadConnections422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}