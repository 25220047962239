// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/foreignKeySchema.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

// @ts-nocheck
import { tableColumnSchema } from './tableColumnSchema'
import { z } from 'zod'

/**
 * @description Foreign key.
 */
export const foreignKeySchema = z
  .object({
    column: z.lazy(() => tableColumnSchema),
    references_name: z.string(),
    references_column: z.lazy(() => tableColumnSchema),
  })
  .describe('Foreign key.')