// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateIsSuggested.ts"
);
import.meta.hot.lastModified = "1737935767415.7485";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateIsSuggestedMutationResponse,
  UpdateIsSuggestedPathParams,
  UpdateIsSuggestedQueryParams,
  UpdateIsSuggested422,
} from '../types/UpdateIsSuggested'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateIsSuggestedMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/suggested' }] as const

export type UpdateIsSuggestedMutationKey = ReturnType<typeof updateIsSuggestedMutationKey>

/**
 * @description Mark cell as Suggestion
 * @summary Update Is Suggested
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/suggested}
 */
async function updateIsSuggested(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: UpdateIsSuggestedPathParams['account_name']
    conversationId: UpdateIsSuggestedPathParams['conversation_id']
    cellId: UpdateIsSuggestedPathParams['cell_id']
    params: UpdateIsSuggestedQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateIsSuggestedMutationResponse, UpdateIsSuggested422, unknown>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/suggested`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Mark cell as Suggestion
 * @summary Update Is Suggested
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/suggested}
 */
export function useUpdateIsSuggested(
  options: {
    mutation?: UseMutationOptions<
      UpdateIsSuggestedMutationResponse,
      UpdateIsSuggested422,
      {
        accountName: UpdateIsSuggestedPathParams['account_name']
        conversationId: UpdateIsSuggestedPathParams['conversation_id']
        cellId: UpdateIsSuggestedPathParams['cell_id']
        params: UpdateIsSuggestedQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateIsSuggestedMutationKey()

  return useMutation<
    UpdateIsSuggestedMutationResponse,
    UpdateIsSuggested422,
    {
      accountName: UpdateIsSuggestedPathParams['account_name']
      conversationId: UpdateIsSuggestedPathParams['conversation_id']
      cellId: UpdateIsSuggestedPathParams['cell_id']
      params: UpdateIsSuggestedQueryParams
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, params }) => {
      return updateIsSuggested({ accountName, conversationId, cellId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}