// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDimension.ts"
);
import.meta.hot.lastModified = "1737935766761.7522";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateDimensionMutationRequest, UpdateDimensionMutationResponse, UpdateDimensionPathParams, UpdateDimension422 } from '../types/UpdateDimension'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDimensionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}' }] as const

export type UpdateDimensionMutationKey = ReturnType<typeof updateDimensionMutationKey>

/**
 * @description Update a dimension.
 * @summary Update Dimension
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id}
 */
async function updateDimension(
  {
    accountName,
    dimensionId,
    data,
  }: { accountName: UpdateDimensionPathParams['account_name']; dimensionId: UpdateDimensionPathParams['dimension_id']; data?: UpdateDimensionMutationRequest },
  config: Partial<RequestConfig<UpdateDimensionMutationRequest>> = {},
) {
  const res = await client<UpdateDimensionMutationResponse, UpdateDimension422, UpdateDimensionMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions/${dimensionId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a dimension.
 * @summary Update Dimension
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions/:dimension_id}
 */
export function useUpdateDimension(
  options: {
    mutation?: UseMutationOptions<
      UpdateDimensionMutationResponse,
      UpdateDimension422,
      { accountName: UpdateDimensionPathParams['account_name']; dimensionId: UpdateDimensionPathParams['dimension_id']; data?: UpdateDimensionMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateDimensionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDimensionMutationKey()

  return useMutation<
    UpdateDimensionMutationResponse,
    UpdateDimension422,
    { accountName: UpdateDimensionPathParams['account_name']; dimensionId: UpdateDimensionPathParams['dimension_id']; data?: UpdateDimensionMutationRequest }
  >({
    mutationFn: async ({ accountName, dimensionId, data }) => {
      return updateDimension({ accountName, dimensionId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}