// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createTeradataConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935769010.7397";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { teradataConnectionCreateSchema } from './teradataConnectionCreateSchema'
import { teradataConnectionSchema } from './teradataConnectionSchema'
import { z } from 'zod'

export const createTeradataConnectionPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const createTeradataConnection200Schema = z.lazy(() => teradataConnectionSchema)

/**
 * @description Validation Error
 */
export const createTeradataConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const createTeradataConnectionMutationRequestSchema = z.lazy(() => teradataConnectionCreateSchema)

export const createTeradataConnectionMutationResponseSchema = z.lazy(() => createTeradataConnection200Schema)