// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getRelevantConversationsCellsByMetricSchema.ts"
);
import.meta.hot.lastModified = "1737759762549.0142";
}
// REMIX HMR END

// @ts-nocheck
import { conversationCellTupleSchema } from './conversationCellTupleSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getRelevantConversationsCellsByMetricPathParamsSchema = z.object({
  account_name: z.string(),
  metric_id: z.string(),
})

export const getRelevantConversationsCellsByMetricQueryParamsSchema = z
  .object({
    limit: z.number().int().default(200),
    skip: z.number().int().default(0),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getRelevantConversationsCellsByMetric200Schema = z.array(z.lazy(() => conversationCellTupleSchema))

/**
 * @description Validation Error
 */
export const getRelevantConversationsCellsByMetric422Schema = z.lazy(() => httpValidationErrorSchema)

export const getRelevantConversationsCellsByMetricQueryResponseSchema = z.lazy(() => getRelevantConversationsCellsByMetric200Schema)