// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSuggestDatasetFixes.ts"
);
import.meta.hot.lastModified = "1737935767879.746";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  SuggestDatasetFixesMutationRequest,
  SuggestDatasetFixesMutationResponse,
  SuggestDatasetFixesPathParams,
  SuggestDatasetFixesQueryParams,
  SuggestDatasetFixes422,
} from '../types/SuggestDatasetFixes'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const suggestDatasetFixesMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/suggest_fixes' }] as const

export type SuggestDatasetFixesMutationKey = ReturnType<typeof suggestDatasetFixesMutationKey>

/**
 * @description Given a list of chats with feedback, suggest fixes to the dataset.Args:    chats_with_feedback: list of chat ids with feedback. When None, we pull the latest `limit` chats with admin feedback which is not yet incorporated.    limit: number of chats to pull when `chats_with_feedback` is None.    offset: offset to start pulling chats when `chats_with_feedback` is None.
 * @summary Suggest Dataset Fixes
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/suggest_fixes}
 */
async function suggestDatasetFixes(
  {
    accountName,
    data,
    params,
  }: { accountName: SuggestDatasetFixesPathParams['account_name']; data?: SuggestDatasetFixesMutationRequest; params?: SuggestDatasetFixesQueryParams },
  config: Partial<RequestConfig<SuggestDatasetFixesMutationRequest>> = {},
) {
  const res = await client<SuggestDatasetFixesMutationResponse, SuggestDatasetFixes422, SuggestDatasetFixesMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/suggest_fixes`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Given a list of chats with feedback, suggest fixes to the dataset.Args:    chats_with_feedback: list of chat ids with feedback. When None, we pull the latest `limit` chats with admin feedback which is not yet incorporated.    limit: number of chats to pull when `chats_with_feedback` is None.    offset: offset to start pulling chats when `chats_with_feedback` is None.
 * @summary Suggest Dataset Fixes
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/suggest_fixes}
 */
export function useSuggestDatasetFixes(
  options: {
    mutation?: UseMutationOptions<
      SuggestDatasetFixesMutationResponse,
      SuggestDatasetFixes422,
      { accountName: SuggestDatasetFixesPathParams['account_name']; data?: SuggestDatasetFixesMutationRequest; params?: SuggestDatasetFixesQueryParams }
    >
    client?: Partial<RequestConfig<SuggestDatasetFixesMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? suggestDatasetFixesMutationKey()

  return useMutation<
    SuggestDatasetFixesMutationResponse,
    SuggestDatasetFixes422,
    { accountName: SuggestDatasetFixesPathParams['account_name']; data?: SuggestDatasetFixesMutationRequest; params?: SuggestDatasetFixesQueryParams }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return suggestDatasetFixes({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}