// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/cn.ts"
);
import.meta.hot.lastModified = "1733439085436.0662";
}
// REMIX HMR END

import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * Utility function to merge class names together. Ensures that multiple
 * Tailwind class names will not conflict with each other.
 * @see {@link https://github.com/dcastil/tailwind-merge}
 * @see {@link https://github.com/lukeed/clsx}
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
