// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/zenViewSchema.ts"
);
import.meta.hot.lastModified = "1737935764870.7627";
}
// REMIX HMR END

// @ts-nocheck
import { zenDimensionSchema } from './zenDimensionSchema'
import { zenIdentifierSchema } from './zenIdentifierSchema'
import { zenMetricSchema } from './zenMetricSchema'
import { z } from 'zod'

/**
 * @description Metrics layer view.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/view
 */
export const zenViewSchema = z
  .object({
    sync_id: z.union([z.string(), z.null()]).optional(),
    type: z.enum(['view']).default('view'),
    name: z.string(),
    index: z.number().int().default(0),
    model_name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    sql_table: z.union([z.string(), z.null()]).optional(),
    derived_table_sql: z.union([z.string(), z.null()]).optional(),
    identifiers: z.array(z.lazy(() => zenIdentifierSchema)).optional(),
    dimensions: z.array(z.lazy(() => zenDimensionSchema)).optional(),
    metrics: z.array(z.lazy(() => zenMetricSchema)).optional(),
    default_date: z.union([z.lazy(() => zenDimensionSchema), z.null()]).optional(),
  })
  .describe('Metrics layer view.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/view')