// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useExecuteCopyJob.ts"
);
import.meta.hot.lastModified = "1737759759257.0312";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ExecuteCopyJobMutationResponse, ExecuteCopyJobPathParams, ExecuteCopyJob422 } from '../types/ExecuteCopyJob'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const executeCopyJobMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/dataset-jobs/copy/{job_id}' }] as const

export type ExecuteCopyJobMutationKey = ReturnType<typeof executeCopyJobMutationKey>

/**
 * @description Execute a copy job.
 * @summary Execute Copy Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/copy/:job_id}
 */
async function executeCopyJob(
  { enterpriseName, jobId }: { enterpriseName: ExecuteCopyJobPathParams['enterprise_name']; jobId: ExecuteCopyJobPathParams['job_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ExecuteCopyJobMutationResponse, ExecuteCopyJob422, unknown>({
    method: 'POST',
    url: `/api/v1/enterprises/${enterpriseName}/dataset-jobs/copy/${jobId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Execute a copy job.
 * @summary Execute Copy Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/copy/:job_id}
 */
export function useExecuteCopyJob(
  options: {
    mutation?: UseMutationOptions<
      ExecuteCopyJobMutationResponse,
      ExecuteCopyJob422,
      { enterpriseName: ExecuteCopyJobPathParams['enterprise_name']; jobId: ExecuteCopyJobPathParams['job_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? executeCopyJobMutationKey()

  return useMutation<
    ExecuteCopyJobMutationResponse,
    ExecuteCopyJob422,
    { enterpriseName: ExecuteCopyJobPathParams['enterprise_name']; jobId: ExecuteCopyJobPathParams['job_id'] }
  >({
    mutationFn: async ({ enterpriseName, jobId }) => {
      return executeCopyJob({ enterpriseName, jobId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}