// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createSnowflakeConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935769016.7397";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { snowflakeConnectionApiCreateSchema } from './snowflakeConnectionApiCreateSchema'
import { snowflakeConnectionSchema } from './snowflakeConnectionSchema'
import { z } from 'zod'

export const createSnowflakeConnectionPathParamsSchema = z.object({
  account_name: z.string(),
})

export const createSnowflakeConnectionQueryParamsSchema = z
  .object({
    validate: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const createSnowflakeConnection200Schema = z.lazy(() => snowflakeConnectionSchema)

/**
 * @description Validation Error
 */
export const createSnowflakeConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const createSnowflakeConnectionMutationRequestSchema = z.lazy(() => snowflakeConnectionApiCreateSchema)

export const createSnowflakeConnectionMutationResponseSchema = z.lazy(() => createSnowflakeConnection200Schema)