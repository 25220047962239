// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/DataAssetVisibility.ts"
);
import.meta.hot.lastModified = "1737935767023.7507";
}
// REMIX HMR END

export enum DataAssetVisibility {
  'PUBLISHED' = 'PUBLISHED',
  'DRAFT' = 'DRAFT',
  'ARCHIVED' = 'ARCHIVED',
  'INITIALIZING' = 'INITIALIZING',
}