// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetKnowledgeSql.ts"
);
import.meta.hot.lastModified = "1737759759782.0286";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetKnowledgeSqlQueryResponse, GetKnowledgeSqlPathParams, GetKnowledgeSql422 } from '../types/GetKnowledgeSql'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getKnowledgeSqlQueryKey = ({
  accountName,
  conversationId,
  cellId,
}: {
  accountName: GetKnowledgeSqlPathParams['account_name']
  conversationId: GetKnowledgeSqlPathParams['conversation_id']
  cellId: GetKnowledgeSqlPathParams['cell_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/knowledge_sql',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId },
    },
  ] as const

export type GetKnowledgeSqlQueryKey = ReturnType<typeof getKnowledgeSqlQueryKey>

/**
 * @description Get the knowledge sql for the cell.When knowledge sql doesn't exist, create it from the bumblebee cell.
 * @summary Get Knowledge Sql
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/knowledge_sql}
 */
async function getKnowledgeSql(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetKnowledgeSqlPathParams['account_name']
    conversationId: GetKnowledgeSqlPathParams['conversation_id']
    cellId: GetKnowledgeSqlPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetKnowledgeSqlQueryResponse, GetKnowledgeSql422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/knowledge_sql`,
    ...config,
  })
  return res.data
}

export function getKnowledgeSqlQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetKnowledgeSqlPathParams['account_name']
    conversationId: GetKnowledgeSqlPathParams['conversation_id']
    cellId: GetKnowledgeSqlPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getKnowledgeSqlQueryKey({ accountName, conversationId, cellId })
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getKnowledgeSql({ accountName, conversationId, cellId }, config)
    },
  })
}

/**
 * @description Get the knowledge sql for the cell.When knowledge sql doesn't exist, create it from the bumblebee cell.
 * @summary Get Knowledge Sql
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/knowledge_sql}
 */
export function useGetKnowledgeSql<
  TData = GetKnowledgeSqlQueryResponse,
  TQueryData = GetKnowledgeSqlQueryResponse,
  TQueryKey extends QueryKey = GetKnowledgeSqlQueryKey,
>(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetKnowledgeSqlPathParams['account_name']
    conversationId: GetKnowledgeSqlPathParams['conversation_id']
    cellId: GetKnowledgeSqlPathParams['cell_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<GetKnowledgeSqlQueryResponse, GetKnowledgeSql422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getKnowledgeSqlQueryKey({ accountName, conversationId, cellId })

  const query = useQuery({
    ...(getKnowledgeSqlQueryOptions({ accountName, conversationId, cellId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetKnowledgeSql422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}