// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/userReferenceSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description User Reference.
 */
export const userReferenceSchema = z
  .object({
    id: z.string(),
    name: z.string(),
  })
  .describe('User Reference.')