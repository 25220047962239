// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bigQueryConnectionApiUpdateSchema.ts"
);
import.meta.hot.lastModified = "1737759762076.0166";
}
// REMIX HMR END

// @ts-nocheck
import { bigQueryLocationSchema } from './bigQueryLocationSchema'
import { z } from 'zod'

/**
 * @description Properties to receive via API on update.
 */
export const bigQueryConnectionApiUpdateSchema = z
  .object({
    name: z.union([z.string(), z.null()]).optional(),
    keyfile: z.union([z.string(), z.null()]).optional(),
    location: z.union([z.lazy(() => bigQueryLocationSchema), z.null()]).optional(),
    ns_database: z.union([z.string(), z.null()]).optional(),
    ns_schema: z.union([z.string(), z.null()]).optional(),
    max_tables: z.union([z.number().int(), z.null()]).optional(),
  })
  .describe('Properties to receive via API on update.')