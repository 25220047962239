// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/usageSchema.ts"
);
import.meta.hot.lastModified = "1737759757506.0403";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Prompt usage class.
 */
export const usageSchema = z
  .object({
    completion_tokens: z.number().int().default(0),
    prompt_tokens: z.number().int().default(0),
    total_tokens: z.number().int().default(0),
  })
  .describe('Prompt usage class.')