// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dataAssetVisibilitySchema.ts"
);
import.meta.hot.lastModified = "1737759760886.023";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Possible data asset visibility states.
 */
export const dataAssetVisibilitySchema = z.enum(['PUBLISHED', 'DRAFT', 'ARCHIVED', 'INITIALIZING']).describe('Possible data asset visibility states.')