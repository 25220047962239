// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/client/hooks/user.ts"
);
import.meta.hot.lastModified = "1735944196088.6309";
}
// REMIX HMR END

import { useQuery } from '@tanstack/react-query'

import { type ErrorContent, type User } from '~/api'

import { useAPI } from '~/client/api'

export function getUserMeKey() {
  return '/api/v1/users/me'
}

export function useUserMe() {
  const api = useAPI()
  return useQuery<User, ErrorContent>({
    queryKey: [getUserMeKey()],
    queryFn: () => api.users.readUserMe(),
    retry: false,
  })
}
