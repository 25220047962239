// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateDimensionMetricValidation.ts"
);
import.meta.hot.lastModified = "1737759762512.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateDimensionMetricValidationMutationRequest,
  UpdateDimensionMetricValidationMutationResponse,
  UpdateDimensionMetricValidationPathParams,
  UpdateDimensionMetricValidation422,
} from '../types/UpdateDimensionMetricValidation'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateDimensionMetricValidationMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dimension_metric_validation/{dm_validation_id}' }] as const

export type UpdateDimensionMetricValidationMutationKey = ReturnType<typeof updateDimensionMetricValidationMutationKey>

/**
 * @description Updates the DimensionMetricValidation.
 * @summary Update Dimension Metric Validation
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/dimension_metric_validation/:dm_validation_id}
 */
async function updateDimensionMetricValidation(
  {
    accountName,
    starSchemaDataSourceId,
    dmValidationId,
    data,
  }: {
    accountName: UpdateDimensionMetricValidationPathParams['account_name']
    starSchemaDataSourceId: UpdateDimensionMetricValidationPathParams['star_schema_data_source_id']
    dmValidationId: UpdateDimensionMetricValidationPathParams['dm_validation_id']
    data?: UpdateDimensionMetricValidationMutationRequest
  },
  config: Partial<RequestConfig<UpdateDimensionMetricValidationMutationRequest>> = {},
) {
  const res = await client<UpdateDimensionMetricValidationMutationResponse, UpdateDimensionMetricValidation422, UpdateDimensionMetricValidationMutationRequest>(
    {
      method: 'PUT',
      url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/dimension_metric_validation/${dmValidationId}`,
      data,
      ...config,
    },
  )
  return res.data
}

/**
 * @description Updates the DimensionMetricValidation.
 * @summary Update Dimension Metric Validation
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/dimension_metric_validation/:dm_validation_id}
 */
export function useUpdateDimensionMetricValidation(
  options: {
    mutation?: UseMutationOptions<
      UpdateDimensionMetricValidationMutationResponse,
      UpdateDimensionMetricValidation422,
      {
        accountName: UpdateDimensionMetricValidationPathParams['account_name']
        starSchemaDataSourceId: UpdateDimensionMetricValidationPathParams['star_schema_data_source_id']
        dmValidationId: UpdateDimensionMetricValidationPathParams['dm_validation_id']
        data?: UpdateDimensionMetricValidationMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateDimensionMetricValidationMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateDimensionMetricValidationMutationKey()

  return useMutation<
    UpdateDimensionMetricValidationMutationResponse,
    UpdateDimensionMetricValidation422,
    {
      accountName: UpdateDimensionMetricValidationPathParams['account_name']
      starSchemaDataSourceId: UpdateDimensionMetricValidationPathParams['star_schema_data_source_id']
      dmValidationId: UpdateDimensionMetricValidationPathParams['dm_validation_id']
      data?: UpdateDimensionMetricValidationMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, dmValidationId, data }) => {
      return updateDimensionMetricValidation({ accountName, starSchemaDataSourceId, dmValidationId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}