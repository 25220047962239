// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/namedFilterUpdateApiSchema.ts"
);
import.meta.hot.lastModified = "1737935768205.7444";
}
// REMIX HMR END

// @ts-nocheck
import { combinationSchema } from './combinationSchema'
import { filterConditionCreateApiSchema } from './filterConditionCreateApiSchema'
import { filterTypeSchema } from './filterTypeSchema'
import { z } from 'zod'

/**
 * @description Update Named Filter via an endpoint.\n\nThis mimics SemanticQueryFilterUpdateAPI until multiple options and/or conditions are allowed.
 */
export const namedFilterUpdateApiSchema = z
  .object({
    display_name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    combination: z.lazy(() => combinationSchema).default('ALL'),
    conditions: z.union([z.array(z.lazy(() => filterConditionCreateApiSchema)), z.null()]).optional(),
    filter_type: z.union([z.lazy(() => filterTypeSchema), z.null()]).optional(),
    is_enabled: z.union([z.boolean(), z.null()]).optional(),
  })
  .describe('Update Named Filter via an endpoint.\n\nThis mimics SemanticQueryFilterUpdateAPI until multiple options and/or conditions are allowed.')