// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/textPayloadSchema.ts"
);
import.meta.hot.lastModified = "1737759758413.0356";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Payload for a text message.\n\nAttributes:\n    text: The text of the message.
 */
export const textPayloadSchema = z
  .object({
    text: z.string(),
  })
  .describe('Payload for a text message.\n\nAttributes:\n    text: The text of the message.')