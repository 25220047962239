// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetUpdateJob.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetUpdateJobQueryResponse, GetUpdateJobPathParams, GetUpdateJob422 } from '../types/GetUpdateJob'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getUpdateJobQueryKey = ({
  enterpriseName,
  jobId,
}: {
  enterpriseName: GetUpdateJobPathParams['enterprise_name']
  jobId: GetUpdateJobPathParams['job_id']
}) => [{ url: '/api/v1/enterprises/:enterprise_name/dataset-jobs/update/:job_id', params: { enterpriseName: enterpriseName, jobId: jobId } }] as const

export type GetUpdateJobQueryKey = ReturnType<typeof getUpdateJobQueryKey>

/**
 * @description Get an update job.
 * @summary Get Update Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/update/:job_id}
 */
async function getUpdateJob(
  { enterpriseName, jobId }: { enterpriseName: GetUpdateJobPathParams['enterprise_name']; jobId: GetUpdateJobPathParams['job_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetUpdateJobQueryResponse, GetUpdateJob422, unknown>({
    method: 'GET',
    url: `/api/v1/enterprises/${enterpriseName}/dataset-jobs/update/${jobId}`,
    ...config,
  })
  return res.data
}

export function getUpdateJobQueryOptions(
  { enterpriseName, jobId }: { enterpriseName: GetUpdateJobPathParams['enterprise_name']; jobId: GetUpdateJobPathParams['job_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getUpdateJobQueryKey({ enterpriseName, jobId })
  return queryOptions({
    enabled: !!(enterpriseName && jobId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getUpdateJob({ enterpriseName, jobId }, config)
    },
  })
}

/**
 * @description Get an update job.
 * @summary Get Update Job
 * {@link /api/v1/enterprises/:enterprise_name/dataset-jobs/update/:job_id}
 */
export function useGetUpdateJob<TData = GetUpdateJobQueryResponse, TQueryData = GetUpdateJobQueryResponse, TQueryKey extends QueryKey = GetUpdateJobQueryKey>(
  { enterpriseName, jobId }: { enterpriseName: GetUpdateJobPathParams['enterprise_name']; jobId: GetUpdateJobPathParams['job_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetUpdateJobQueryResponse, GetUpdateJob422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getUpdateJobQueryKey({ enterpriseName, jobId })

  const query = useQuery({
    ...(getUpdateJobQueryOptions({ enterpriseName, jobId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetUpdateJob422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}