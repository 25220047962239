// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/datasetStatsSchema.ts"
);
import.meta.hot.lastModified = "1737759758724.034";
}
// REMIX HMR END

// @ts-nocheck
import { detailedStatsSchema } from './detailedStatsSchema'
import { z } from 'zod'

/**
 * @description Report on the state of the copied dataset.
 */
export const datasetStatsSchema = z
  .object({
    dimensions: z.number().int().min(0),
    metrics: z.number().int().min(0),
    filters: z.number().int().min(0),
    sensitive_dimensions: z.number().int().min(0),
    sensitive_metrics: z.number().int().min(0),
    feedback_chats: z.number().int().min(0),
    dimension_details: z.lazy(() => detailedStatsSchema),
    metric_details: z.lazy(() => detailedStatsSchema),
    filter_details: z.lazy(() => detailedStatsSchema),
  })
  .describe('Report on the state of the copied dataset.')