// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRemoveAccountAllowedDomain.ts"
);
import.meta.hot.lastModified = "1737935769206.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RemoveAccountAllowedDomainMutationResponse,
  RemoveAccountAllowedDomainPathParams,
  RemoveAccountAllowedDomain422,
} from '../types/RemoveAccountAllowedDomain'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const removeAccountAllowedDomainMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/domains/{domain_id}' }] as const

export type RemoveAccountAllowedDomainMutationKey = ReturnType<typeof removeAccountAllowedDomainMutationKey>

/**
 * @description Remove an allowed email domain to an account.
 * @summary Remove Account Allowed Domain
 * {@link /api/v1/accounts/:account_name/domains/:domain_id}
 */
async function removeAccountAllowedDomain(
  { domainId, accountName }: { domainId: RemoveAccountAllowedDomainPathParams['domain_id']; accountName: RemoveAccountAllowedDomainPathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RemoveAccountAllowedDomainMutationResponse, RemoveAccountAllowedDomain422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/domains/${domainId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Remove an allowed email domain to an account.
 * @summary Remove Account Allowed Domain
 * {@link /api/v1/accounts/:account_name/domains/:domain_id}
 */
export function useRemoveAccountAllowedDomain(
  options: {
    mutation?: UseMutationOptions<
      RemoveAccountAllowedDomainMutationResponse,
      RemoveAccountAllowedDomain422,
      { domainId: RemoveAccountAllowedDomainPathParams['domain_id']; accountName: RemoveAccountAllowedDomainPathParams['account_name'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? removeAccountAllowedDomainMutationKey()

  return useMutation<
    RemoveAccountAllowedDomainMutationResponse,
    RemoveAccountAllowedDomain422,
    { domainId: RemoveAccountAllowedDomainPathParams['domain_id']; accountName: RemoveAccountAllowedDomainPathParams['account_name'] }
  >({
    mutationFn: async ({ domainId, accountName }) => {
      return removeAccountAllowedDomain({ domainId, accountName }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}