// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/logging/log.client.ts"
);
import.meta.hot.lastModified = "1733439085416.0662";
}
// REMIX HMR END

import { DEFAULT_LOG_LEVEL } from '@/constants'
import { userStore } from '@/stores/userStore'
import { type Nullish, type POJO } from '@/types'
import { type Env } from '@/types/env'
import { unknownToErrorContent } from '@/utils'

import { type BrowserLog, LogLevel } from '~/api'

import { devConsole } from './devConsole'

interface BrowserLogRequest {
  message: string
  level: LogLevel
  timestamp: number
  current_url: string
  data?: Nullish<POJO>
}

type ClientLogLevel = LogLevel | 'OFF'

const LOG_LEVELS: Record<ClientLogLevel, number> = {
  OFF: 4,
  [LogLevel.EXCEPTION]: 3,
  [LogLevel.WARNING]: 2,
  [LogLevel.INFO]: 1,
}

let logLevel: ClientLogLevel = DEFAULT_LOG_LEVEL
let initialized = false
export function initBrowserLogger(env: Nullish<Env>) {
  if (initialized) return
  if (env?.LOG_LEVEL != null) {
    logLevel = env.LOG_LEVEL
  }
  initialized = true
}

async function sendBrowserLog(logContext: BrowserLogRequest): Promise<void> {
  try {
    const accountName = userStore.account?.name
    if ((accountName?.trim() ?? '') === '') {
      throw new Error('userStore.accountName is null')
    }
    const res = await fetch(
      `/resources/logging/create?accountName=${accountName}`,
      {
        method: 'POST',
        body: JSON.stringify(logContext),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    if (!res.ok) {
      // res.json() breaks when data is not json, so wrap in a try/catch.
      // Also throw the error if it exists, and the catch will be hit either way
      throw await res.json()
    }
  } catch (e) {
    devConsole('error', 'Error sending browser log:', e)
  }
}

function shouldLogForLevel(level: LogLevel) {
  return LOG_LEVELS[level] >= LOG_LEVELS[logLevel]
}

function sendLog(
  level: LogLevel,
  message: string,
  additionalData: POJO = {},
): Promise<void> {
  const shouldLog = shouldLogForLevel(level)
  const log: BrowserLog = {
    level,
    message,
    current_url: window.location.href,
    timestamp: Date.now(),
    data: additionalData,
  }
  devConsole('log', `${shouldLog ? 'Sending ' : ''}${level}: `)
  devConsole('dir', log)
  if (shouldLog) {
    return sendBrowserLog(log)
  }
  return Promise.resolve()
}

function error(
  message: string,
  err: unknown = null,
  additionalData: POJO = {},
) {
  const errorContent = unknownToErrorContent(err)
  return sendLog(LogLevel.EXCEPTION, message, {
    ...additionalData,
    errorContent,
  })
}

function warning(message: string, additionalData: POJO = {}) {
  return sendLog(LogLevel.EXCEPTION, message, additionalData)
}

function info(message: string, additionalData: POJO = {}) {
  return sendLog(LogLevel.INFO, message, additionalData)
}

export const logger = {
  console: devConsole,
  info,
  warning,
  error,
}
