// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateSnowflakeConnection.ts"
);
import.meta.hot.lastModified = "1737759761863.0178";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateSnowflakeConnectionMutationRequest,
  UpdateSnowflakeConnectionMutationResponse,
  UpdateSnowflakeConnectionPathParams,
  UpdateSnowflakeConnection422,
} from '../types/UpdateSnowflakeConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateSnowflakeConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}' }] as const

export type UpdateSnowflakeConnectionMutationKey = ReturnType<typeof updateSnowflakeConnectionMutationKey>

/**
 * @description Update Snowflake connection.Note that all fields for a valid Snowflake connection are required.
 * @summary Update Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id}
 */
async function updateSnowflakeConnection(
  {
    accountName,
    snowflakeConnectionId,
    data,
  }: {
    accountName: UpdateSnowflakeConnectionPathParams['account_name']
    snowflakeConnectionId: UpdateSnowflakeConnectionPathParams['snowflake_connection_id']
    data?: UpdateSnowflakeConnectionMutationRequest
  },
  config: Partial<RequestConfig<UpdateSnowflakeConnectionMutationRequest>> = {},
) {
  const res = await client<UpdateSnowflakeConnectionMutationResponse, UpdateSnowflakeConnection422, UpdateSnowflakeConnectionMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/${snowflakeConnectionId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update Snowflake connection.Note that all fields for a valid Snowflake connection are required.
 * @summary Update Snowflake Connection
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id}
 */
export function useUpdateSnowflakeConnection(
  options: {
    mutation?: UseMutationOptions<
      UpdateSnowflakeConnectionMutationResponse,
      UpdateSnowflakeConnection422,
      {
        accountName: UpdateSnowflakeConnectionPathParams['account_name']
        snowflakeConnectionId: UpdateSnowflakeConnectionPathParams['snowflake_connection_id']
        data?: UpdateSnowflakeConnectionMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateSnowflakeConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateSnowflakeConnectionMutationKey()

  return useMutation<
    UpdateSnowflakeConnectionMutationResponse,
    UpdateSnowflakeConnection422,
    {
      accountName: UpdateSnowflakeConnectionPathParams['account_name']
      snowflakeConnectionId: UpdateSnowflakeConnectionPathParams['snowflake_connection_id']
      data?: UpdateSnowflakeConnectionMutationRequest
    }
  >({
    mutationFn: async ({ accountName, snowflakeConnectionId, data }) => {
      return updateSnowflakeConnection({ accountName, snowflakeConnectionId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}