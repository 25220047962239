// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteModeConnection.ts"
);
import.meta.hot.lastModified = "1737759760889.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteModeConnectionMutationResponse, DeleteModeConnectionPathParams, DeleteModeConnection422 } from '../types/DeleteModeConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteModeConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/mode/{mode_connection_id}' }] as const

export type DeleteModeConnectionMutationKey = ReturnType<typeof deleteModeConnectionMutationKey>

/**
 * @description Delete Mode connection.Note: This will delete the connection only if it is not used by any report.
 * @summary Delete Mode Connection
 * {@link /api/v1/accounts/:account_name/connections/mode/:mode_connection_id}
 */
async function deleteModeConnection(
  {
    accountName,
    modeConnectionId,
  }: { accountName: DeleteModeConnectionPathParams['account_name']; modeConnectionId: DeleteModeConnectionPathParams['mode_connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteModeConnectionMutationResponse, DeleteModeConnection422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/connections/mode/${modeConnectionId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete Mode connection.Note: This will delete the connection only if it is not used by any report.
 * @summary Delete Mode Connection
 * {@link /api/v1/accounts/:account_name/connections/mode/:mode_connection_id}
 */
export function useDeleteModeConnection(
  options: {
    mutation?: UseMutationOptions<
      DeleteModeConnectionMutationResponse,
      DeleteModeConnection422,
      { accountName: DeleteModeConnectionPathParams['account_name']; modeConnectionId: DeleteModeConnectionPathParams['mode_connection_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteModeConnectionMutationKey()

  return useMutation<
    DeleteModeConnectionMutationResponse,
    DeleteModeConnection422,
    { accountName: DeleteModeConnectionPathParams['account_name']; modeConnectionId: DeleteModeConnectionPathParams['mode_connection_id'] }
  >({
    mutationFn: async ({ accountName, modeConnectionId }) => {
      return deleteModeConnection({ accountName, modeConnectionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}