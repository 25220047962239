// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/conversationsSortSchema.ts"
);
import.meta.hot.lastModified = "1737935767491.7483";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Sort conversations by.
 */
export const conversationsSortSchema = z.enum(['created_at', 'last_modified_at', 'creator_id', 'name']).describe('Sort conversations by.')