// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/mostCommonValueColumnStatisticsSchema.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

// @ts-nocheck
import { columnValueSchema } from './columnValueSchema'
import { z } from 'zod'

/**
 * @description Common column statistics.
 */
export const mostCommonValueColumnStatisticsSchema = z
  .object({
    name: z.string(),
    type: z.string(),
    count: z.number().int(),
    num_nulls: z.number().int(),
    num_distinct_values: z.union([z.number().int(), z.null()]).optional(),
    stat_type: z.enum(['most_common_value']).default('most_common_value'),
    most_common_values: z.array(z.lazy(() => columnValueSchema)),
  })
  .describe('Common column statistics.')