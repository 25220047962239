// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateBigqueryConnection.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateBigqueryConnectionMutationRequest,
  UpdateBigqueryConnectionMutationResponse,
  UpdateBigqueryConnectionPathParams,
  UpdateBigqueryConnectionQueryParams,
  UpdateBigqueryConnection422,
} from '../types/UpdateBigqueryConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateBigqueryConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}' }] as const

export type UpdateBigqueryConnectionMutationKey = ReturnType<typeof updateBigqueryConnectionMutationKey>

/**
 * @description Update a BigQuery connection.Note that all fields for a valid BigQuery connection are required.
 * @summary Update Bigquery Connection
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id}
 */
async function updateBigqueryConnection(
  {
    accountName,
    connectionId,
    data,
    params,
  }: {
    accountName: UpdateBigqueryConnectionPathParams['account_name']
    connectionId: UpdateBigqueryConnectionPathParams['connection_id']
    data?: UpdateBigqueryConnectionMutationRequest
    params?: UpdateBigqueryConnectionQueryParams
  },
  config: Partial<RequestConfig<UpdateBigqueryConnectionMutationRequest>> = {},
) {
  const res = await client<UpdateBigqueryConnectionMutationResponse, UpdateBigqueryConnection422, UpdateBigqueryConnectionMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/connections/bigquery/${connectionId}`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a BigQuery connection.Note that all fields for a valid BigQuery connection are required.
 * @summary Update Bigquery Connection
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id}
 */
export function useUpdateBigqueryConnection(
  options: {
    mutation?: UseMutationOptions<
      UpdateBigqueryConnectionMutationResponse,
      UpdateBigqueryConnection422,
      {
        accountName: UpdateBigqueryConnectionPathParams['account_name']
        connectionId: UpdateBigqueryConnectionPathParams['connection_id']
        data?: UpdateBigqueryConnectionMutationRequest
        params?: UpdateBigqueryConnectionQueryParams
      }
    >
    client?: Partial<RequestConfig<UpdateBigqueryConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateBigqueryConnectionMutationKey()

  return useMutation<
    UpdateBigqueryConnectionMutationResponse,
    UpdateBigqueryConnection422,
    {
      accountName: UpdateBigqueryConnectionPathParams['account_name']
      connectionId: UpdateBigqueryConnectionPathParams['connection_id']
      data?: UpdateBigqueryConnectionMutationRequest
      params?: UpdateBigqueryConnectionQueryParams
    }
  >({
    mutationFn: async ({ accountName, connectionId, data, params }) => {
      return updateBigqueryConnection({ accountName, connectionId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}