// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUploadExamples.ts"
);
import.meta.hot.lastModified = "1737759759414.0305";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UploadExamplesMutationRequest, UploadExamplesMutationResponse, UploadExamplesPathParams, UploadExamples422 } from '../types/UploadExamples'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const uploadExamplesMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_examples' }] as const

export type UploadExamplesMutationKey = ReturnType<typeof uploadExamplesMutationKey>

/**
 * @description Upload examples to an existing ssds.
 * @summary Upload Examples
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/upload_examples}
 */
async function uploadExamples(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: UploadExamplesPathParams['account_name']
    starSchemaDataSourceId: UploadExamplesPathParams['star_schema_data_source_id']
    data?: UploadExamplesMutationRequest
  },
  config: Partial<RequestConfig<UploadExamplesMutationRequest>> = {},
) {
  const res = await client<UploadExamplesMutationResponse, UploadExamples422, UploadExamplesMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/upload_examples`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Upload examples to an existing ssds.
 * @summary Upload Examples
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/upload_examples}
 */
export function useUploadExamples(
  options: {
    mutation?: UseMutationOptions<
      UploadExamplesMutationResponse,
      UploadExamples422,
      {
        accountName: UploadExamplesPathParams['account_name']
        starSchemaDataSourceId: UploadExamplesPathParams['star_schema_data_source_id']
        data?: UploadExamplesMutationRequest
      }
    >
    client?: Partial<RequestConfig<UploadExamplesMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? uploadExamplesMutationKey()

  return useMutation<
    UploadExamplesMutationResponse,
    UploadExamples422,
    {
      accountName: UploadExamplesPathParams['account_name']
      starSchemaDataSourceId: UploadExamplesPathParams['star_schema_data_source_id']
      data?: UploadExamplesMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return uploadExamples({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}