// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadCellChart.ts"
);
import.meta.hot.lastModified = "1737759758962.0327";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadCellChartQueryResponse, ReadCellChartPathParams, ReadCellChart422 } from '../types/ReadCellChart'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readCellChartQueryKey = ({
  accountName,
  conversationId,
  cellId,
  chartConfigId,
}: {
  accountName: ReadCellChartPathParams['account_name']
  conversationId: ReadCellChartPathParams['conversation_id']
  cellId: ReadCellChartPathParams['cell_id']
  chartConfigId: ReadCellChartPathParams['chart_config_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/chart/:chart_config_id',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId, chartConfigId: chartConfigId },
    },
  ] as const

export type ReadCellChartQueryKey = ReturnType<typeof readCellChartQueryKey>

/**
 * @description Get Bumblebee Cell Chart Data.
 * @summary Read Cell Chart
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/chart/:chart_config_id}
 */
async function readCellChart(
  {
    accountName,
    conversationId,
    cellId,
    chartConfigId,
  }: {
    accountName: ReadCellChartPathParams['account_name']
    conversationId: ReadCellChartPathParams['conversation_id']
    cellId: ReadCellChartPathParams['cell_id']
    chartConfigId: ReadCellChartPathParams['chart_config_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadCellChartQueryResponse, ReadCellChart422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/chart/${chartConfigId}`,
    ...config,
  })
  return res.data
}

export function readCellChartQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
    chartConfigId,
  }: {
    accountName: ReadCellChartPathParams['account_name']
    conversationId: ReadCellChartPathParams['conversation_id']
    cellId: ReadCellChartPathParams['cell_id']
    chartConfigId: ReadCellChartPathParams['chart_config_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readCellChartQueryKey({ accountName, conversationId, cellId, chartConfigId })
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId && chartConfigId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readCellChart({ accountName, conversationId, cellId, chartConfigId }, config)
    },
  })
}

/**
 * @description Get Bumblebee Cell Chart Data.
 * @summary Read Cell Chart
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/chart/:chart_config_id}
 */
export function useReadCellChart<
  TData = ReadCellChartQueryResponse,
  TQueryData = ReadCellChartQueryResponse,
  TQueryKey extends QueryKey = ReadCellChartQueryKey,
>(
  {
    accountName,
    conversationId,
    cellId,
    chartConfigId,
  }: {
    accountName: ReadCellChartPathParams['account_name']
    conversationId: ReadCellChartPathParams['conversation_id']
    cellId: ReadCellChartPathParams['cell_id']
    chartConfigId: ReadCellChartPathParams['chart_config_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadCellChartQueryResponse, ReadCellChart422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readCellChartQueryKey({ accountName, conversationId, cellId, chartConfigId })

  const query = useQuery({
    ...(readCellChartQueryOptions({ accountName, conversationId, cellId, chartConfigId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadCellChart422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}