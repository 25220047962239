// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSsdsSuggestIdentifiers.ts"
);
import.meta.hot.lastModified = "1737759761442.02";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  SsdsSuggestIdentifiersMutationResponse,
  SsdsSuggestIdentifiersPathParams,
  SsdsSuggestIdentifiersQueryParams,
  SsdsSuggestIdentifiers422,
} from '../types/SsdsSuggestIdentifiers'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const ssdsSuggestIdentifiersMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/identifiers/suggestions' }] as const

export type SsdsSuggestIdentifiersMutationKey = ReturnType<typeof ssdsSuggestIdentifiersMutationKey>

/**
 * @description Suggest Identifiers for Star Schema Data Source.This only works for primary/foreign key relationships. The model won't suggest anythingfancier like a join with a condition or any an inner join.Args:    - overwrite_cache: whether to force the model to recompute, rather than using a cached result    - temperature: the randomness of the model. 0.0 is deterministic, 1.0 is random        ref: https://platform.openai.com/docs/api-reference/audio    - max_tokens: the maximum number of tokens to generate. This is model dependent.
 * @summary Ssds Suggest Identifiers
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/identifiers/suggestions}
 */
async function ssdsSuggestIdentifiers(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: SsdsSuggestIdentifiersPathParams['account_name']
    starSchemaDataSourceId: SsdsSuggestIdentifiersPathParams['star_schema_data_source_id']
    params?: SsdsSuggestIdentifiersQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<SsdsSuggestIdentifiersMutationResponse, SsdsSuggestIdentifiers422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/identifiers/suggestions`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Suggest Identifiers for Star Schema Data Source.This only works for primary/foreign key relationships. The model won't suggest anythingfancier like a join with a condition or any an inner join.Args:    - overwrite_cache: whether to force the model to recompute, rather than using a cached result    - temperature: the randomness of the model. 0.0 is deterministic, 1.0 is random        ref: https://platform.openai.com/docs/api-reference/audio    - max_tokens: the maximum number of tokens to generate. This is model dependent.
 * @summary Ssds Suggest Identifiers
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/identifiers/suggestions}
 */
export function useSsdsSuggestIdentifiers(
  options: {
    mutation?: UseMutationOptions<
      SsdsSuggestIdentifiersMutationResponse,
      SsdsSuggestIdentifiers422,
      {
        accountName: SsdsSuggestIdentifiersPathParams['account_name']
        starSchemaDataSourceId: SsdsSuggestIdentifiersPathParams['star_schema_data_source_id']
        params?: SsdsSuggestIdentifiersQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? ssdsSuggestIdentifiersMutationKey()

  return useMutation<
    SsdsSuggestIdentifiersMutationResponse,
    SsdsSuggestIdentifiers422,
    {
      accountName: SsdsSuggestIdentifiersPathParams['account_name']
      starSchemaDataSourceId: SsdsSuggestIdentifiersPathParams['star_schema_data_source_id']
      params?: SsdsSuggestIdentifiersQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params }) => {
      return ssdsSuggestIdentifiers({ accountName, starSchemaDataSourceId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}