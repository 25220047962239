// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRmEnterpriseUser.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RmEnterpriseUserMutationResponse, RmEnterpriseUserPathParams, RmEnterpriseUserQueryParams, RmEnterpriseUser422 } from '../types/RmEnterpriseUser'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const rmEnterpriseUserMutationKey = () => [{ url: '/api/v1/enterprises/{enterprise_name}/users/{user_id}' }] as const

export type RmEnterpriseUserMutationKey = ReturnType<typeof rmEnterpriseUserMutationKey>

/**
 * @description Remove the user from all accounts in the enterprise.This will NOT delete the user itself.
 * @summary Rm Enterprise User
 * {@link /api/v1/enterprises/:enterprise_name/users/:user_id}
 */
async function rmEnterpriseUser(
  {
    enterpriseName,
    userId,
    params,
  }: { enterpriseName: RmEnterpriseUserPathParams['enterprise_name']; userId: RmEnterpriseUserPathParams['user_id']; params?: RmEnterpriseUserQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RmEnterpriseUserMutationResponse, RmEnterpriseUser422, unknown>({
    method: 'DELETE',
    url: `/api/v1/enterprises/${enterpriseName}/users/${userId}`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Remove the user from all accounts in the enterprise.This will NOT delete the user itself.
 * @summary Rm Enterprise User
 * {@link /api/v1/enterprises/:enterprise_name/users/:user_id}
 */
export function useRmEnterpriseUser(
  options: {
    mutation?: UseMutationOptions<
      RmEnterpriseUserMutationResponse,
      RmEnterpriseUser422,
      { enterpriseName: RmEnterpriseUserPathParams['enterprise_name']; userId: RmEnterpriseUserPathParams['user_id']; params?: RmEnterpriseUserQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? rmEnterpriseUserMutationKey()

  return useMutation<
    RmEnterpriseUserMutationResponse,
    RmEnterpriseUser422,
    { enterpriseName: RmEnterpriseUserPathParams['enterprise_name']; userId: RmEnterpriseUserPathParams['user_id']; params?: RmEnterpriseUserQueryParams }
  >({
    mutationFn: async ({ enterpriseName, userId, params }) => {
      return rmEnterpriseUser({ enterpriseName, userId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}