// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyReadConnectionDataApiV1AccountsAccountNameConnectionsConnectionIdDataGetSchema.ts"
);
import.meta.hot.lastModified = "1737759762609.014";
}
// REMIX HMR END

// @ts-nocheck
import { sortSchema } from './sortSchema'
import { tableFilterSchema } from './tableFilterSchema'
import { z } from 'zod'

export const bodyReadConnectionDataApiV1AccountsAccountNameConnectionsConnectionIdDataGetSchema = z.object({
  filters: z.union([z.array(z.lazy(() => tableFilterSchema)), z.null()]).optional(),
  sorts: z.union([z.array(z.lazy(() => sortSchema)), z.null()]).optional(),
})