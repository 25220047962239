// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/StarSchemaDataSourceService.ts"
);
import.meta.hot.lastModified = "1737935753869.823";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BBExample } from '../models/BBExample';
import type { Body_create_dimension_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__dimensions__post } from '../models/Body_create_dimension_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__dimensions__post';
import type { Body_create_metric_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__metrics__post } from '../models/Body_create_metric_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__metrics__post';
import type { Body_create_remapped_ssds_api_v1_accounts__account_name__star_schema_data_source_remap_ssds_post } from '../models/Body_create_remapped_ssds_api_v1_accounts__account_name__star_schema_data_source_remap_ssds_post';
import type { Body_enrich_ssds_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__enrich_ssds_post } from '../models/Body_enrich_ssds_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__enrich_ssds_post';
import type { Body_extract_sqls_from_csv_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__extract_sqls_from_csv_post } from '../models/Body_extract_sqls_from_csv_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__extract_sqls_from_csv_post';
import type { Body_import_from_twb_api_v1_accounts__account_name__star_schema_data_source_import_from_twb_post } from '../models/Body_import_from_twb_api_v1_accounts__account_name__star_schema_data_source_import_from_twb_post';
import type { Body_inject_new_dimensions_and_metrics_api_v1_accounts__account_name__star_schema_data_source_inject_new_dimensions_and_metrics_post } from '../models/Body_inject_new_dimensions_and_metrics_api_v1_accounts__account_name__star_schema_data_source_inject_new_dimensions_and_metrics_post';
import type { Body_merge_dimensions_api_v1_accounts__account_name__star_schema_data_source_dimensions__dimension_id__merge_patch } from '../models/Body_merge_dimensions_api_v1_accounts__account_name__star_schema_data_source_dimensions__dimension_id__merge_patch';
import type { Body_merge_metrics_api_v1_accounts__account_name__star_schema_data_source_metrics__metric_id__merge_patch } from '../models/Body_merge_metrics_api_v1_accounts__account_name__star_schema_data_source_metrics__metric_id__merge_patch';
import type { Body_read_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_sources__data_source_id__data_post } from '../models/Body_read_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_sources__data_source_id__data_post';
import type { Body_read_star_schema_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_post } from '../models/Body_read_star_schema_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_post';
import type { Body_run_eval_on_reference_conversations_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__eval_reference_conversations_post } from '../models/Body_run_eval_on_reference_conversations_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__eval_reference_conversations_post';
import type { BumblebeeCellBasic } from '../models/BumblebeeCellBasic';
import type { CellOrderBy } from '../models/CellOrderBy';
import type { ChatMessageWithSQL } from '../models/ChatMessageWithSQL';
import type { ColumnValue } from '../models/ColumnValue';
import type { Connection } from '../models/Connection';
import type { ConversationBasic } from '../models/ConversationBasic';
import type { ConversationCellTuple } from '../models/ConversationCellTuple';
import type { ConversationsSort } from '../models/ConversationsSort';
import type { CreateTableFromReportReturnInfo } from '../models/CreateTableFromReportReturnInfo';
import type { DataAssetVisibility } from '../models/DataAssetVisibility';
import type { DataSourceProfileInfo } from '../models/DataSourceProfileInfo';
import type { DeleteRequest } from '../models/DeleteRequest';
import type { Dialect } from '../models/Dialect';
import type { DimensionDetail } from '../models/DimensionDetail';
import type { DimensionMetricValidation } from '../models/DimensionMetricValidation';
import type { DimensionMetricValidationAPIUpdate } from '../models/DimensionMetricValidationAPIUpdate';
import type { DimensionUpdate } from '../models/DimensionUpdate';
import type { DimensionUpdateWithId } from '../models/DimensionUpdateWithId';
import type { DimensionView } from '../models/DimensionView';
import type { DisplayFieldsUpdateAPI } from '../models/DisplayFieldsUpdateAPI';
import type { DMSensitiveAssignment } from '../models/DMSensitiveAssignment';
import type { DMSensitiveKnowledgeImpact } from '../models/DMSensitiveKnowledgeImpact';
import type { EvalResultBase } from '../models/EvalResultBase';
import type { Feedback } from '../models/Feedback';
import type { FeedbackFilter } from '../models/FeedbackFilter';
import type { Identifier } from '../models/Identifier';
import type { IdentifierSuggestionResponse } from '../models/IdentifierSuggestionResponse';
import type { Join } from '../models/Join';
import type { JoinGroup } from '../models/JoinGroup';
import type { JoinValidationResponse } from '../models/JoinValidationResponse';
import type { KnowledgeOriginFilter } from '../models/KnowledgeOriginFilter';
import type { KnowledgeSort } from '../models/KnowledgeSort';
import type { KnowledgeStateFilter } from '../models/KnowledgeStateFilter';
import type { M2MTokenCreds } from '../models/M2MTokenCreds';
import type { MetricDetail } from '../models/MetricDetail';
import type { MetricUpdate } from '../models/MetricUpdate';
import type { MetricUpdateWithId } from '../models/MetricUpdateWithId';
import type { MetricView } from '../models/MetricView';
import type { NamedFilterBase } from '../models/NamedFilterBase';
import type { NamedFilterCreate } from '../models/NamedFilterCreate';
import type { NamedFilterCreateAPI } from '../models/NamedFilterCreateAPI';
import type { NamedFilterOptionConditionValidOperatorsWithGroup } from '../models/NamedFilterOptionConditionValidOperatorsWithGroup';
import type { NamedFilterUpdateAPI } from '../models/NamedFilterUpdateAPI';
import type { NamedFilterUpdateAPIWithId } from '../models/NamedFilterUpdateAPIWithId';
import type { NodeDataPage } from '../models/NodeDataPage';
import type { OriginType } from '../models/OriginType';
import type { PublishStateFilterOption } from '../models/PublishStateFilterOption';
import type { SemanticQuery } from '../models/SemanticQuery';
import type { SemanticQueryCoalescingStrategy } from '../models/SemanticQueryCoalescingStrategy';
import type { SemanticQueryCreate } from '../models/SemanticQueryCreate';
import type { SqlValidation } from '../models/SqlValidation';
import type { SqlValidationAPICreate } from '../models/SqlValidationAPICreate';
import type { SqlValidationAPIUpdate } from '../models/SqlValidationAPIUpdate';
import type { SqlWithValidation } from '../models/SqlWithValidation';
import type { SSDSJoin } from '../models/SSDSJoin';
import type { StarSchemaDataSource } from '../models/StarSchemaDataSource';
import type { StarSchemaDataSourceAPICreate } from '../models/StarSchemaDataSourceAPICreate';
import type { StarSchemaDataSourceBase } from '../models/StarSchemaDataSourceBase';
import type { StarSchemaDataSourceColdStart } from '../models/StarSchemaDataSourceColdStart';
import type { StarSchemaDataSourceSort } from '../models/StarSchemaDataSourceSort';
import type { StarSchemaDataSourcesResponse } from '../models/StarSchemaDataSourcesResponse';
import type { StarSchemaDataSourceTableOrViewInfo } from '../models/StarSchemaDataSourceTableOrViewInfo';
import type { StateUpdate } from '../models/StateUpdate';
import type { StateUpdateWithId } from '../models/StateUpdateWithId';
import type { TableOrViewInfoValidation } from '../models/TableOrViewInfoValidation';
import type { Task } from '../models/Task';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StarSchemaDataSourceService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Ssds Connection
   * Get Connection for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns Connection Successful Response
   * @throws ApiError
   */
  public getSsdsConnection(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Connection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/connection',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Ssds Connection
   * Get Connection for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param newConnectionId
   * @param sessionId
   * @returns Connection Successful Response
   * @throws ApiError
   */
  public updateSsdsConnection(
    accountName: string,
    starSchemaDataSourceId: string,
    newConnectionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Connection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/update_connection',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'new_connection_id': newConnectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Extract Sqls From Csv
   * Extract SQLs from uploaded csv file for users to review.
   *
   * We expect the csv at least to have the following column:
   * sql
   *
   * We currently only use sql column.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param formData
   * @param sessionId
   * @returns SqlWithValidation Successful Response
   * @throws ApiError
   */
  public extractSqlsFromCsv(
    accountName: string,
    starSchemaDataSourceId: string,
    formData: Body_extract_sqls_from_csv_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__extract_sqls_from_csv_post,
    sessionId?: (string | null),
  ): CancelablePromise<Array<SqlWithValidation>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/extract_sqls_from_csv',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Validate Sql
   * Validate the SQL.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns SqlWithValidation Successful Response
   * @throws ApiError
   */
  public validateSql(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: SqlWithValidation,
    sessionId?: (string | null),
  ): CancelablePromise<SqlWithValidation> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/validate_sql',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Learn Dms From Sqls
   * Learn DMs from list of SQLs.
   *
   * This endpoint will first remove all temporarily extracted DMs from SQLs from previous run in the SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public learnDmsFromSqls(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<SqlValidationAPICreate>,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/learn_dms_from_sqls',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Sql Validation
   * Recreates the SQL validation, effectively updating it.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sqlValidationId
   * @param requestBody
   * @param sessionId
   * @returns SqlValidation Successful Response
   * @throws ApiError
   */
  public updateSqlValidation(
    accountName: string,
    starSchemaDataSourceId: string,
    sqlValidationId: string,
    requestBody: SqlValidationAPIUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<SqlValidation> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation/{sql_validation_id}',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
        'sql_validation_id': sqlValidationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove Sql Validation
   * Delete one SqlValidation.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sqlValidationId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public removeSqlValidation(
    accountName: string,
    starSchemaDataSourceId: string,
    sqlValidationId: string,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation/{sql_validation_id}',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
        'sql_validation_id': sqlValidationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Dimension Metric Validation
   * Updates the DimensionMetricValidation.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dmValidationId
   * @param requestBody
   * @param sessionId
   * @returns DimensionMetricValidation Successful Response
   * @throws ApiError
   */
  public updateDimensionMetricValidation(
    accountName: string,
    starSchemaDataSourceId: string,
    dmValidationId: string,
    requestBody: DimensionMetricValidationAPIUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<DimensionMetricValidation> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dimension_metric_validation/{dm_validation_id}',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
        'dm_validation_id': dmValidationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Sql Validations By Ssds
   * Get the learned DMs from an SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param origin
   * @param sessionId
   * @returns SqlValidation Successful Response
   * @throws ApiError
   */
  public readSqlValidationsBySsds(
    accountName: string,
    starSchemaDataSourceId: string,
    origin?: (OriginType | null),
    sessionId?: (string | null),
  ): CancelablePromise<Array<SqlValidation>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'origin': origin,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove Sql Validations For Ssds
   * Delete the learned DMs an SSDS. This effectively resets the drafted learned DMs.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param origin
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public removeSqlValidationsForSsds(
    accountName: string,
    starSchemaDataSourceId: string,
    origin?: (OriginType | null),
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'origin': origin,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Sql Validations By Account
   * Get the learned DMs from an account.
   * @param accountName
   * @param origin
   * @param sessionId
   * @returns SqlValidation Successful Response
   * @throws ApiError
   */
  public readSqlValidationsByAccount(
    accountName: string,
    origin?: (OriginType | null),
    sessionId?: (string | null),
  ): CancelablePromise<Array<SqlValidation>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/sql_validation',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'origin': origin,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Inject New Dimensions And Metrics
   * Inject new dimensions and metrics from the current SqlValidation objects in the SSDS.
   * @param accountName
   * @param requestBody
   * @param updateExistingDimensionsMetrics
   * @param updateExistingState
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public injectNewDimensionsAndMetrics(
    accountName: string,
    requestBody: Body_inject_new_dimensions_and_metrics_api_v1_accounts__account_name__star_schema_data_source_inject_new_dimensions_and_metrics_post,
    updateExistingDimensionsMetrics: boolean = true,
    updateExistingState: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/inject_new_dimensions_and_metrics',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'update_existing_dimensions_metrics': updateExistingDimensionsMetrics,
        'update_existing_state': updateExistingState,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Sync Bb Verified Cell To Feedback Chat
   * Convert bumblebee verified questions to chats.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @param requestBody
   * @returns Task Successful Response
   * @throws ApiError
   */
  public syncBbVerifiedCellToFeedbackChat(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
    requestBody?: (M2MTokenCreds | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sync_bb_verified_cell_to_feedback_chat',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Clear Bb Verified Cell Feedback Chat
   * Clear bumblebee verified question converted feedback chats.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public clearBbVerifiedCellFeedbackChat(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/clear_bb_verified_cell_feedback_chat',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Remapped Ssds
   * Upload SSDS created with remapping from SQL CSV.
   * @param accountName
   * @param defaultDatabase
   * @param defaultSchema
   * @param dialect
   * @param requestBody
   * @param slimSsds
   * @param sessionId
   * @returns StarSchemaDataSourceBase Successful Response
   * @throws ApiError
   */
  public createRemappedSsds(
    accountName: string,
    defaultDatabase: string,
    defaultSchema: string,
    dialect: Dialect,
    requestBody: Body_create_remapped_ssds_api_v1_accounts__account_name__star_schema_data_source_remap_ssds_post,
    slimSsds: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<StarSchemaDataSourceBase> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/remap_ssds',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'default_database': defaultDatabase,
        'default_schema': defaultSchema,
        'dialect': dialect,
        'slim_ssds': slimSsds,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Candidate Demonstration Cells Count
   * Count candidate demonstration cells for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @param requestBody
   * @returns number Successful Response
   * @throws ApiError
   */
  public getCandidateDemonstrationCellsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
    requestBody?: (FeedbackFilter | null),
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/candidate_demonstration_cells_count',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Candidate Demonstration Cells
   * Get candidate demonstration cells for Star Schema Data Source.
   *
   * NOTE: The returned cells do not contain retrieved dimensions or metrics.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param skip
   * @param limit
   * @param orderBy
   * @param descending
   * @param sessionId
   * @param requestBody
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public getCandidateDemonstrationCells(
    accountName: string,
    starSchemaDataSourceId: string,
    skip?: number,
    limit?: (number | null),
    orderBy?: CellOrderBy,
    descending: boolean = false,
    sessionId?: (string | null),
    requestBody?: (FeedbackFilter | null),
  ): CancelablePromise<Array<BumblebeeCellBasic>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/candidate_demonstration_cells',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'skip': skip,
        'limit': limit,
        'order_by': orderBy,
        'descending': descending,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Account Ssds Feedback
   * Get feedback for the given account and ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns Feedback Successful Response
   * @throws ApiError
   */
  public getAccountSsdsFeedback(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Feedback>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/feedback',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Search Verified Questions
   * Get similar ai-reference questions for an SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param question
   * @param limit
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public searchVerifiedQuestions(
    accountName: string,
    starSchemaDataSourceId: string,
    question: string,
    limit: number = 10,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/feedback',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'question': question,
        'limit': limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Similar Ssds Feedback
   * Get conflicting feedback for the given account and ssds.
   *
   * Conflicting feedback are entries that have the same or similar questions
   * which map to different semantic queries.
   *
   * If cosine_similarity_threshold is provided, then we use cosine_similarity of the
   * embeddings to group feedback, not just textual similarity.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param maxCosineDistance
   * @param sessionId
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public getSimilarSsdsFeedback(
    accountName: string,
    starSchemaDataSourceId: string,
    maxCosineDistance: number = 0.01,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Array<BumblebeeCellBasic>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/similar_feedback',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'max_cosine_distance': maxCosineDistance,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Knowledge Sqls
   * Get knowledge SQLs for the given account and ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param isVerified
   * @param limit
   * @param skip
   * @param sessionId
   * @returns ChatMessageWithSQL Successful Response
   * @throws ApiError
   */
  public getKnowledgeSqls(
    accountName: string,
    starSchemaDataSourceId: string,
    isVerified?: (boolean | null),
    limit?: (number | null),
    skip?: number,
    sessionId?: (string | null),
  ): CancelablePromise<Array<ChatMessageWithSQL>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/knowledge_sqls',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'is_verified': isVerified,
        'limit': limit,
        'skip': skip,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Dimension Expr Type
   * Get the expr_type of a dimension, then check if already exists.
   *
   * Returns:
   * A 409 if the sql/data_source_id combo already exists
   * The expr_type of the expr if a dimension with the given sql doesn't exist
   * @param accountName
   * @param sql
   * @param dataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getDimensionExprType(
    accountName: string,
    sql: string,
    dataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/expr_type',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'sql': sql,
        'data_source_id': dataSourceId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Dimension
   * Create a dimension.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public createDimension(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Body_create_dimension_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__dimensions__post,
    sessionId?: (string | null),
  ): CancelablePromise<(DimensionDetail | null)> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dimensions/',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Dimension
   * Read a dimension.
   * @param dimensionId
   * @param accountName
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public readDimension(
    dimensionId: string,
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}',
      path: {
        'dimension_id': dimensionId,
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Dimension
   * Update a dimension.
   * @param accountName
   * @param dimensionId
   * @param requestBody
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimension(
    accountName: string,
    dimensionId: string,
    requestBody: DimensionUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}',
      path: {
        'account_name': accountName,
        'dimension_id': dimensionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Dimension
   * Remove dimension from database.
   *
   * Note: This endpoint is for simple deletion of a dimension. It will not check for dimension usage and
   * handle the full flow for dimension deletion (invalidating examples and derived dms that use this dimension).
   * Use with caution, delete only when you are sure the dimension is not needed.
   * @param accountName
   * @param dimensionId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteDimension(
    accountName: string,
    dimensionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}',
      path: {
        'account_name': accountName,
        'dimension_id': dimensionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Dimension Is Sensitive
   * Update a dimension for is_sensitive.
   * @param accountName
   * @param dimensionId
   * @param isSensitive
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimensionIsSensitive(
    accountName: string,
    dimensionId: string,
    isSensitive: boolean,
    sessionId?: (string | null),
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/is_sensitive',
      path: {
        'account_name': accountName,
        'dimension_id': dimensionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'is_sensitive': isSensitive,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Dimension Is Sensitive Impact
   * Compute the impact of updating a dimension for is_sensitive.
   * @param accountName
   * @param dimensionId
   * @param isSensitive
   * @param sessionId
   * @returns DMSensitiveKnowledgeImpact Successful Response
   * @throws ApiError
   */
  public updateDimensionIsSensitiveImpact(
    accountName: string,
    dimensionId: string,
    isSensitive: boolean,
    sessionId?: (string | null),
  ): CancelablePromise<DMSensitiveKnowledgeImpact> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/is_sensitive_impact',
      path: {
        'account_name': accountName,
        'dimension_id': dimensionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'is_sensitive': isSensitive,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Dimensions
   * Bulk update multiple dimensions at once.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimensions(
    accountName: string,
    requestBody: Array<DimensionUpdateWithId>,
    sessionId?: (string | null),
  ): CancelablePromise<Array<DimensionDetail>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Dimensions
   * Remove dimensions from database.
   *
   * Note: This endpoint is for simple deletion of a dimension. It will not check for dimension usage and
   * handle the full flow for dimension deletion (invalidating examples and derived dms that use this dimension).
   * Use with caution, delete only when you are sure the dimension is not needed.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteDimensions(
    accountName: string,
    requestBody: Array<DeleteRequest>,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Dimension Name
   * Update a dimension's name.
   * @param accountName
   * @param dimensionId
   * @param name
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimensionName(
    accountName: string,
    dimensionId: string,
    name: string,
    sessionId?: (string | null),
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/name',
      path: {
        'account_name': accountName,
        'dimension_id': dimensionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'name': name,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Dimension State
   * Update a dimension's state.
   * @param accountName
   * @param dimensionId
   * @param requestBody
   * @param validateTransition
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimensionState(
    accountName: string,
    dimensionId: string,
    requestBody: StateUpdate,
    validateTransition: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/state',
      path: {
        'account_name': accountName,
        'dimension_id': dimensionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'validate_transition': validateTransition,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Dimensions State
   * Bulk update multiple dimension's state.
   * @param accountName
   * @param requestBody
   * @param validateTransition
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimensionsState(
    accountName: string,
    requestBody: Array<StateUpdateWithId>,
    validateTransition: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<Array<DimensionDetail>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimension_states',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'validate_transition': validateTransition,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Set Dimension Display Type
   * Set a dimension's display_type
   * @param dimensionId
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public setDimensionDisplayType(
    dimensionId: string,
    accountName: string,
    requestBody: DisplayFieldsUpdateAPI,
    sessionId?: (string | null),
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/display',
      path: {
        'dimension_id': dimensionId,
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Dimensions Count
   * Get count of Dimensions for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are counted.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getSsdsDimensionsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    search: string = '',
    state?: (KnowledgeStateFilter | null),
    origin?: (KnowledgeOriginFilter | null),
    dataSourceIds?: (Array<string> | null),
    sessionId?: (string | null),
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dimensions_count',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'search': search,
        'state': state,
        'origin': origin,
        'data_source_ids': dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Dimensions
   * Get Dimensions for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are returned.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param sessionId
   * @returns DimensionView Successful Response
   * @throws ApiError
   */
  public getSsdsDimensions(
    accountName: string,
    starSchemaDataSourceId: string,
    limit?: (number | null),
    skip?: number,
    search: string = '',
    state?: (KnowledgeStateFilter | null),
    origin?: (KnowledgeOriginFilter | null),
    dataSourceIds?: (Array<string> | null),
    sessionId?: (string | null),
  ): CancelablePromise<Array<DimensionView>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dimensions',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
        'search': search,
        'state': state,
        'origin': origin,
        'data_source_ids': dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Merge Dimensions
   * Merge multiple dimensions into one dimension.
   *
   * This endpoint lets users merge multiple (similar) dimensions (ids_to_merge) into one dimension
   * (dimension) to maintain SSDS better. Users can also update the dimension using dimension_update.
   * @param accountName
   * @param dimensionId
   * @param requestBody
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public mergeDimensions(
    accountName: string,
    dimensionId: string,
    requestBody: Body_merge_dimensions_api_v1_accounts__account_name__star_schema_data_source_dimensions__dimension_id__merge_patch,
    sessionId?: (string | null),
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/merge',
      path: {
        'account_name': accountName,
        'dimension_id': dimensionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Relevant Conversations Cells By Dimension
   * Get conversations and cells that use the given dimension.
   * @param accountName
   * @param dimensionId
   * @param limit
   * @param skip
   * @param sessionId
   * @returns ConversationCellTuple Successful Response
   * @throws ApiError
   */
  public getRelevantConversationsCellsByDimension(
    accountName: string,
    dimensionId: string,
    limit: number = 200,
    skip?: number,
    sessionId?: (string | null),
  ): CancelablePromise<Array<ConversationCellTuple>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/relevant_conversations_cells',
      path: {
        'account_name': accountName,
        'dimension_id': dimensionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Dimension Sample Values
   * Get dimension sample values.
   * @param accountName
   * @param dimensionId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getDimensionSampleValues(
    accountName: string,
    dimensionId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/sample_values',
      path: {
        'account_name': accountName,
        'dimension_id': dimensionId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Grouped Dimensions Count
   * Get the count of Dimensions for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are counted.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getSsdsGroupedDimensionsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    search: string = '',
    state?: (KnowledgeStateFilter | null),
    origin?: (KnowledgeOriginFilter | null),
    dataSourceIds?: (Array<string> | null),
    sessionId?: (string | null),
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/grouped_dimensions_count',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'search': search,
        'state': state,
        'origin': origin,
        'data_source_ids': dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Grouped Dimensions
   * Get Dimensions for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are returned.
   *
   * The first dimension in each list will be the one to display in the UI.
   * If cell_id is specified, then the displayed dim is chosen to be relevant
   * for that specific cell, otherwise the first dimension in the group is chosen.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param cellId
   * @param sortAscending
   * @param sortBy
   * @param sessionId
   * @returns DimensionView Successful Response
   * @throws ApiError
   */
  public getSsdsGroupedDimensions(
    accountName: string,
    starSchemaDataSourceId: string,
    limit?: (number | null),
    skip?: number,
    search: string = '',
    state?: (KnowledgeStateFilter | null),
    origin?: (KnowledgeOriginFilter | null),
    dataSourceIds?: (Array<string> | null),
    cellId?: (string | null),
    sortAscending: boolean = true,
    sortBy?: KnowledgeSort,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Array<DimensionView>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/grouped_dimensions',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
        'search': search,
        'state': state,
        'origin': origin,
        'data_source_ids': dataSourceIds,
        'cell_id': cellId,
        'sort_ascending': sortAscending,
        'sort_by': sortBy,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Metric Expr Type
   * Get the expr_type of a metric, then check if already exists.
   *
   * Returns:
   * A 409 if the sql/type/data_source_id combo already exists
   * The expr_type of the expr if a metric with the given sql doesn't exist
   * @param accountName
   * @param sql
   * @param dataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getMetricExprType(
    accountName: string,
    sql: string,
    dataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/expr_type',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'sql': sql,
        'data_source_id': dataSourceId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Metric
   * Create a metric.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public createMetric(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Body_create_metric_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__metrics__post,
    sessionId?: (string | null),
  ): CancelablePromise<(MetricDetail | null)> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/metrics/',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Metric
   * Read a metric.
   * @param metricId
   * @param accountName
   * @param sessionId
   * @returns MetricDetail Successful Response
   * @throws ApiError
   */
  public readMetric(
    metricId: string,
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<MetricDetail> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}',
      path: {
        'metric_id': metricId,
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Metric
   * Update a metric.
   * @param accountName
   * @param metricId
   * @param requestBody
   * @param sessionId
   * @returns MetricDetail Successful Response
   * @throws ApiError
   */
  public updateMetric(
    accountName: string,
    metricId: string,
    requestBody: MetricUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<MetricDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}',
      path: {
        'account_name': accountName,
        'metric_id': metricId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Metric
   * Remove metric from database.
   *
   * Note: This endpoint is for simple deletion of a metric. It will not check for metric usage and
   * handle the full flow for metric deletion (invalidating examples and derived dms that use this metric).
   * Use with caution, delete only when you are sure the metric is not needed.
   * @param accountName
   * @param metricId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteMetric(
    accountName: string,
    metricId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}',
      path: {
        'account_name': accountName,
        'metric_id': metricId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Metric Is Sensitive
   * Update a metric for is_sensitive.
   * @param accountName
   * @param metricId
   * @param isSensitive
   * @param sessionId
   * @returns MetricDetail Successful Response
   * @throws ApiError
   */
  public updateMetricIsSensitive(
    accountName: string,
    metricId: string,
    isSensitive: boolean,
    sessionId?: (string | null),
  ): CancelablePromise<MetricDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/is_sensitive',
      path: {
        'account_name': accountName,
        'metric_id': metricId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'is_sensitive': isSensitive,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Metric Is Sensitive Impact
   * Compute the impact of updating a metric for is_sensitive.
   * @param accountName
   * @param metricId
   * @param isSensitive
   * @param sessionId
   * @returns DMSensitiveKnowledgeImpact Successful Response
   * @throws ApiError
   */
  public updateMetricIsSensitiveImpact(
    accountName: string,
    metricId: string,
    isSensitive: boolean,
    sessionId?: (string | null),
  ): CancelablePromise<DMSensitiveKnowledgeImpact> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/is_sensitive_impact',
      path: {
        'account_name': accountName,
        'metric_id': metricId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'is_sensitive': isSensitive,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Metrics
   * Bulk update multiple metrics at once.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns MetricDetail Successful Response
   * @throws ApiError
   */
  public updateMetrics(
    accountName: string,
    requestBody: Array<MetricUpdateWithId>,
    sessionId?: (string | null),
  ): CancelablePromise<Array<MetricDetail>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Metrics
   * Remove metrics from database.
   *
   * Note: This endpoint is for simple deletion of a metric. It will not check for metric usage and
   * handle the full flow for metric deletion (invalidating examples and derived dms that use this metric).
   * Use with caution, delete only when you are sure the metric is not needed.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteMetrics(
    accountName: string,
    requestBody: Array<DeleteRequest>,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Metric Name
   * Update a metric's name.
   * @param accountName
   * @param metricId
   * @param name
   * @param sessionId
   * @returns MetricDetail Successful Response
   * @throws ApiError
   */
  public updateMetricName(
    accountName: string,
    metricId: string,
    name: string,
    sessionId?: (string | null),
  ): CancelablePromise<MetricDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/name',
      path: {
        'account_name': accountName,
        'metric_id': metricId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'name': name,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Metric State
   * Update a metric's state.
   * @param accountName
   * @param metricId
   * @param requestBody
   * @param validateTransition
   * @param sessionId
   * @returns MetricDetail Successful Response
   * @throws ApiError
   */
  public updateMetricState(
    accountName: string,
    metricId: string,
    requestBody: StateUpdate,
    validateTransition: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<MetricDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/state',
      path: {
        'account_name': accountName,
        'metric_id': metricId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'validate_transition': validateTransition,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Metrics State
   * Bulk update multiple metric's state.
   * @param accountName
   * @param requestBody
   * @param validateTransition
   * @param sessionId
   * @returns MetricDetail Successful Response
   * @throws ApiError
   */
  public updateMetricsState(
    accountName: string,
    requestBody: Array<StateUpdateWithId>,
    validateTransition: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<Array<MetricDetail>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metric_states',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'validate_transition': validateTransition,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Set Metric Display Type
   * Set a metric's display_type.
   * @param metricId
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns MetricDetail Successful Response
   * @throws ApiError
   */
  public setMetricDisplayType(
    metricId: string,
    accountName: string,
    requestBody: DisplayFieldsUpdateAPI,
    sessionId?: (string | null),
  ): CancelablePromise<MetricDetail> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/display',
      path: {
        'metric_id': metricId,
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Metrics Count
   * Get the count of Metrics for Star Schema Data Source.
   *
   * This endpoint combines the metrics from the SSDS with all the metrics from each Data Source.
   * If DataSourceIds are specified, then only metrics that reference
   * the specified Data Sources are counted.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getSsdsMetricsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    search: string = '',
    state?: (KnowledgeStateFilter | null),
    origin?: (KnowledgeOriginFilter | null),
    dataSourceIds?: (Array<string> | null),
    sessionId?: (string | null),
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/metrics_count',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'search': search,
        'state': state,
        'origin': origin,
        'data_source_ids': dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Metrics
   * Get Metrics for Star Schema Data Source.
   *
   * This endpoint combines the metrics from the SSDS with all the metrics from each Data Source.
   * If DataSourceIds are specified, then only metrics that reference
   * the specified Data Sources are returned.
   *
   * If cell_id is specified, then the metrics will be filtered out if they are
   * state NEW and not present in the cell's conversation.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param cellId
   * @param sortAscending
   * @param sortBy
   * @param sessionId
   * @returns MetricView Successful Response
   * @throws ApiError
   */
  public getSsdsMetrics(
    accountName: string,
    starSchemaDataSourceId: string,
    limit?: (number | null),
    skip?: number,
    search: string = '',
    state?: (KnowledgeStateFilter | null),
    origin?: (KnowledgeOriginFilter | null),
    dataSourceIds?: (Array<string> | null),
    cellId?: (string | null),
    sortAscending: boolean = true,
    sortBy?: KnowledgeSort,
    sessionId?: (string | null),
  ): CancelablePromise<Array<MetricView>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/metrics',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
        'search': search,
        'state': state,
        'origin': origin,
        'data_source_ids': dataSourceIds,
        'cell_id': cellId,
        'sort_ascending': sortAscending,
        'sort_by': sortBy,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Merge Metrics
   * Merge multiple metrics into one metric.
   *
   * This endpoint lets users merge multiple (similar) metrics (ids_to_merge) into one metric
   * (metric) to maintain SSDS better. Users can also update the metric using metric_update.
   * @param accountName
   * @param metricId
   * @param requestBody
   * @param sessionId
   * @returns MetricDetail Successful Response
   * @throws ApiError
   */
  public mergeMetrics(
    accountName: string,
    metricId: string,
    requestBody: Body_merge_metrics_api_v1_accounts__account_name__star_schema_data_source_metrics__metric_id__merge_patch,
    sessionId?: (string | null),
  ): CancelablePromise<MetricDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/merge',
      path: {
        'account_name': accountName,
        'metric_id': metricId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Relevant Conversations Cells By Metric
   * Get conversations and cells that use the given metric.
   * @param accountName
   * @param metricId
   * @param limit
   * @param skip
   * @param sessionId
   * @returns ConversationCellTuple Successful Response
   * @throws ApiError
   */
  public getRelevantConversationsCellsByMetric(
    accountName: string,
    metricId: string,
    limit: number = 200,
    skip?: number,
    sessionId?: (string | null),
  ): CancelablePromise<Array<ConversationCellTuple>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/relevant_conversations_cells',
      path: {
        'account_name': accountName,
        'metric_id': metricId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Grouped Metrics Count
   * Get the count of metrics for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are counted.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getSsdsGroupedMetricsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    search: string = '',
    state?: (KnowledgeStateFilter | null),
    origin?: (KnowledgeOriginFilter | null),
    dataSourceIds?: (Array<string> | null),
    sessionId?: (string | null),
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/grouped_metrics_count',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'search': search,
        'state': state,
        'origin': origin,
        'data_source_ids': dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Grouped Metrics
   * Get Dimensions for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are returned.
   *
   * The first dimension in each list will be the one to display in the UI.
   * If cell_id is specified, then the displayed dim is chosen to be relevant
   * for that specific cell, otherwise the first dimension in the group is chosen.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param cellId
   * @param sortAscending
   * @param sortBy
   * @param sessionId
   * @returns MetricView Successful Response
   * @throws ApiError
   */
  public getSsdsGroupedMetrics(
    accountName: string,
    starSchemaDataSourceId: string,
    limit?: (number | null),
    skip?: number,
    search: string = '',
    state?: (KnowledgeStateFilter | null),
    origin?: (KnowledgeOriginFilter | null),
    dataSourceIds?: (Array<string> | null),
    cellId?: (string | null),
    sortAscending: boolean = true,
    sortBy?: KnowledgeSort,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Array<MetricView>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/grouped_metrics',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
        'search': search,
        'state': state,
        'origin': origin,
        'data_source_ids': dataSourceIds,
        'cell_id': cellId,
        'sort_ascending': sortAscending,
        'sort_by': sortBy,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Account Ssds Named Filters
   * Get Named Filters from the given account and ssds.
   *
   * If DataSourceIds are specified, then only metrics that reference
   * the specified Data Sources are returned.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param state
   * @param dataSourceIds
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public getAccountSsdsNamedFilters(
    accountName: string,
    starSchemaDataSourceId: string,
    limit?: (number | null),
    skip?: number,
    state?: (KnowledgeStateFilter | null),
    dataSourceIds?: (Array<string> | null),
    sessionId?: (string | null),
  ): CancelablePromise<Array<NamedFilterBase>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
        'state': state,
        'data_source_ids': dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Named Filter
   * Create a Named Filter.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public createNamedFilter(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: NamedFilterCreateAPI,
    sessionId?: (string | null),
  ): CancelablePromise<NamedFilterBase> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Named Filters
   * Bulk update multiple named filters in a single API call.
   * @param starSchemaDataSourceId
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public updateNamedFilters(
    starSchemaDataSourceId: string,
    accountName: string,
    requestBody: Array<NamedFilterUpdateAPIWithId>,
    sessionId?: (string | null),
  ): CancelablePromise<Array<NamedFilterBase>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters',
      path: {
        'star_schema_data_source_id': starSchemaDataSourceId,
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Named Filters
   * Delete the specified named filter from the ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteNamedFilters(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<DeleteRequest>,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Account Ssds Named Filters Count
   * Get Named Filters from the given account and ssds.
   *
   * If DataSourceIds are specified, then only metrics that reference
   * the specified Data Sources are returned.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param state
   * @param dataSourceIds
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getAccountSsdsNamedFiltersCount(
    accountName: string,
    starSchemaDataSourceId: string,
    state?: (KnowledgeStateFilter | null),
    dataSourceIds?: (Array<string> | null),
    sessionId?: (string | null),
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters_count',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'state': state,
        'data_source_ids': dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Named Filter State
   * Update a filter's state.
   * @param accountName
   * @param namedFilterId
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public updateNamedFilterState(
    accountName: string,
    namedFilterId: string,
    starSchemaDataSourceId: string,
    requestBody: StateUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<NamedFilterBase> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters/{named_filter_id}/state',
      path: {
        'account_name': accountName,
        'named_filter_id': namedFilterId,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Named Filters State
   * Bulk update multiple filter's state.
   * @param starSchemaDataSourceId
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public updateNamedFiltersState(
    starSchemaDataSourceId: string,
    accountName: string,
    requestBody: Array<StateUpdateWithId>,
    sessionId?: (string | null),
  ): CancelablePromise<Array<NamedFilterBase>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filter_states',
      path: {
        'star_schema_data_source_id': starSchemaDataSourceId,
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Named Filter
   * Update a Named Filter.
   * @param accountName
   * @param namedFilterId
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public updateNamedFilter(
    accountName: string,
    namedFilterId: string,
    starSchemaDataSourceId: string,
    requestBody: NamedFilterUpdateAPI,
    sessionId?: (string | null),
  ): CancelablePromise<NamedFilterBase> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters/{named_filter_id}',
      path: {
        'account_name': accountName,
        'named_filter_id': namedFilterId,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Named Filter
   * Delete the specified named filter from the ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param namedFilterId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteNamedFilter(
    accountName: string,
    starSchemaDataSourceId: string,
    namedFilterId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters/{named_filter_id}',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
        'named_filter_id': namedFilterId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Named Filter Valid Operators
   * Get named filter options for SSDS Dimensions.
   *
   * This is analogous to BB Cell's filter options.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns NamedFilterOptionConditionValidOperatorsWithGroup Successful Response
   * @throws ApiError
   */
  public getNamedFilterValidOperators(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<NamedFilterOptionConditionValidOperatorsWithGroup>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filter_options',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Identifiers
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param updateIndex
   * @param updateExprTypes
   * @param updateDisplayTypes
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public createIdentifiers(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<Identifier>,
    updateIndex: boolean = true,
    updateExprTypes: boolean = true,
    updateDisplayTypes: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/identifiers',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'update_index': updateIndex,
        'update_expr_types': updateExprTypes,
        'update_display_types': updateDisplayTypes,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Identifiers
   * Get Identifiers for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are returned.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dataSourceIds
   * @param sessionId
   * @returns Identifier Successful Response
   * @throws ApiError
   */
  public getSsdsIdentifiers(
    accountName: string,
    starSchemaDataSourceId: string,
    dataSourceIds?: (Array<string> | null),
    sessionId?: (string | null),
  ): CancelablePromise<Array<Identifier>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/identifiers',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'data_source_ids': dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Join Sqls
   * Get the joins implied by the identifiers on an ssds.
   *
   * Zenlytics always does <foreign key> left join <primary key>.
   *
   * This function makes a lot of assumptions e.g.
   * there must always exist exactly one foreign key for each identifier name
   *
   * This assumption should always be true in the datasets we control. This may break
   * later for datasets we don't control.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns SSDSJoin Successful Response
   * @throws ApiError
   */
  public getSsdsJoinSqls(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<SSDSJoin>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/join_sqls',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Ssds Suggest Identifiers
   * Suggest Identifiers for Star Schema Data Source.
   *
   * This only works for primary/foreign key relationships. The model won't suggest anything
   * fancier like a join with a condition or any an inner join.
   *
   * Args:
   * - overwrite_cache: whether to force the model to recompute, rather than using a cached result
   * - temperature: the randomness of the model. 0.0 is deterministic, 1.0 is random
   * ref: https://platform.openai.com/docs/api-reference/audio
   * - max_tokens: the maximum number of tokens to generate. This is model dependent.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param maxTokens
   * @param temperature
   * @param overwriteCache
   * @param sessionId
   * @returns IdentifierSuggestionResponse Successful Response
   * @throws ApiError
   */
  public ssdsSuggestIdentifiers(
    accountName: string,
    starSchemaDataSourceId: string,
    maxTokens: number = 4000,
    temperature?: number,
    overwriteCache: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<IdentifierSuggestionResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/identifiers/suggestions',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'max_tokens': maxTokens,
        'temperature': temperature,
        'overwrite_cache': overwriteCache,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Ssds Joins
   * Get Joins for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns Join Successful Response
   * @throws ApiError
   */
  public getSsdsJoins(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<Join>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/joins',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Joins
   * Create Joins for Star Schema Data Source.
   *
   * we will return the ambiguous join groups,
   * i.e. a list of join groups that have more than one bridge tables.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param overwrite
   * @param sessionId
   * @returns JoinGroup Successful Response
   * @throws ApiError
   */
  public createJoins(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<Join>,
    overwrite: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<Array<JoinGroup>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/joins',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'overwrite': overwrite,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Composite Key Joins
   * Create Joins for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns JoinGroup Successful Response
   * @throws ApiError
   */
  public createCompositeKeyJoins(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: JoinGroup,
    sessionId?: (string | null),
  ): CancelablePromise<JoinGroup> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/composite_key',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Validate Join
   * Validate Joins for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param numRowsOfLeftTable
   * @param numRowsOfRightTable
   * @param sessionId
   * @returns JoinValidationResponse Successful Response
   * @throws ApiError
   */
  public validateJoin(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Join,
    numRowsOfLeftTable?: (number | null),
    numRowsOfRightTable?: (number | null),
    sessionId?: (string | null),
  ): CancelablePromise<JoinValidationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/joins/validate',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'num_rows_of_left_table': numRowsOfLeftTable,
        'num_rows_of_right_table': numRowsOfRightTable,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Star Schema Data Sources
   * Read Star Schema Data Sources.
   * @param accountName
   * @param sortAscending
   * @param sortBy
   * @param limit
   * @param offset
   * @param search
   * @param syncId
   * @param sessionId
   * @returns StarSchemaDataSourcesResponse Successful Response
   * @throws ApiError
   */
  public readStarSchemaDataSources(
    accountName: string,
    sortAscending: boolean = false,
    sortBy?: (StarSchemaDataSourceSort | null),
    limit?: (number | null),
    offset?: (number | null),
    search?: (string | null),
    syncId?: (string | null),
    sessionId?: (string | null),
  ): CancelablePromise<StarSchemaDataSourcesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'sort_ascending': sortAscending,
        'sort_by': sortBy,
        'limit': limit,
        'offset': offset,
        'search': search,
        'sync_id': syncId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Star Schema Data Source
   * Create Star Schema Data Source.
   * @param accountName
   * @param requestBody
   * @param updateIndex
   * @param updateProfiles
   * @param updateExprTypes
   * @param updateDisplayTypes
   * @param skipValidationCheck
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public createStarSchemaDataSource(
    accountName: string,
    requestBody: StarSchemaDataSourceAPICreate,
    updateIndex: boolean = true,
    updateProfiles: boolean = true,
    updateExprTypes: boolean = true,
    updateDisplayTypes: boolean = true,
    skipValidationCheck: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'update_index': updateIndex,
        'update_profiles': updateProfiles,
        'update_expr_types': updateExprTypes,
        'update_display_types': updateDisplayTypes,
        'skip_validation_check': skipValidationCheck,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Star Schema Data Source
   * Read Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param publishStateFilter
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public readStarSchemaDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    publishStateFilter?: PublishStateFilterOption,
    sessionId?: (string | null),
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'publish_state_filter': publishStateFilter,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Star Schema Data Source
   * Delete Star Schema Data Source.
   *
   * This will delete all associated conversations that end up empty after cells are removed.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteStarSchemaDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Star Schema Data Source Name And Description
   * Update Star Schema Data Source name and description.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param name
   * @param description
   * @param isDemo
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public updateStarSchemaDataSourceNameAndDescription(
    accountName: string,
    starSchemaDataSourceId: string,
    name?: (string | null),
    description?: (string | null),
    isDemo?: (boolean | null),
    sessionId?: (string | null),
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/name_and_description',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'name': name,
        'description': description,
        'is_demo': isDemo,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Star Schema Data Source Visibility
   * Update Star Schema Data Source visibility.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param visibility
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public updateStarSchemaDataSourceVisibility(
    accountName: string,
    starSchemaDataSourceId: string,
    visibility: DataAssetVisibility,
    sessionId?: (string | null),
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/visibility',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'visibility': visibility,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Suggested Prompts
   * Get a dataset's suggested prompts from the SSDS examples.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param numberSuggestions
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getSuggestedPrompts(
    accountName: string,
    starSchemaDataSourceId: string,
    numberSuggestions: number = 5,
    sessionId?: (string | null),
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/suggestions',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'number_suggestions': numberSuggestions,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Star Schema Data Source Common Values
   * Get the common values for an SSDS's columns.
   *
   * Returns:
   * A dictionary mapping the column name to ColumnValue's. Only
   * the value attribute on each ColumnValue is meaningful. The
   * count in each ColumnValue is hard-coded to -1.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns ColumnValue Successful Response
   * @throws ApiError
   */
  public getStarSchemaDataSourceCommonValues(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Record<string, Array<ColumnValue>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/common_values',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Star Schema Data Source Sql
   * Get the SQL SELECT statement that is used to return the SSDS data.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readStarSchemaDataSourceSql(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Star Schema Data Source Data
   * Get the joined data for everything in an SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param sessionId
   * @param requestBody
   * @returns NodeDataPage Successful Response
   * @throws ApiError
   */
  public readStarSchemaDataSourceData(
    accountName: string,
    starSchemaDataSourceId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: (string | null),
    requestBody?: Body_read_star_schema_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_post,
  ): CancelablePromise<NodeDataPage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Profile Data Source
   * Get the data for a specific data source within an SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dataSourceId
   * @param sessionId
   * @returns DataSourceProfileInfo Successful Response
   * @throws ApiError
   */
  public profileDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    dataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<DataSourceProfileInfo> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}/profile',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
        'data_source_id': dataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Data Source Data
   * Get the data for a specific data source within an SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dataSourceId
   * @param limit
   * @param skip
   * @param sessionId
   * @param requestBody
   * @returns NodeDataPage Successful Response
   * @throws ApiError
   */
  public readDataSourceData(
    accountName: string,
    starSchemaDataSourceId: string,
    dataSourceId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: (string | null),
    requestBody?: Body_read_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_sources__data_source_id__data_post,
  ): CancelablePromise<NodeDataPage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}/data',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
        'data_source_id': dataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Star Schema Data Source Conversations
   * Read Star Schema Data Source Conversations.
   *
   * If show_moonlight is True, show only moonlight conversations
   * If show_moonlight is False, show only non-moonlight conversations
   * If show_moonlight is None, show everything.
   *
   * If show_training is True, show only is_training conversations
   * If show_training is False, show only non-is_training conversations
   * If show_training is None, show everything.
   *
   * If show_eval is True, show only eval conversations
   * If show_eval is False, show only non-eval conversations
   * If show_eval is None, show everything.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param showHidden
   * @param showEval
   * @param showMoonlight
   * @param showTraining
   * @param searchQuery
   * @param creatorIds
   * @param orderBy
   * @param descending
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public getStarSchemaDataSourceConversations(
    accountName: string,
    starSchemaDataSourceId: string,
    limit: number = 100,
    skip?: number,
    showHidden: boolean = false,
    showEval?: (boolean | null),
    showMoonlight?: (boolean | null),
    showTraining?: (boolean | null),
    searchQuery?: (string | null),
    creatorIds?: (Array<string> | null),
    orderBy?: ConversationsSort,
    descending: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<Array<ConversationBasic>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/conversations',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
        'show_hidden': showHidden,
        'show_eval': showEval,
        'show_moonlight': showMoonlight,
        'show_training': showTraining,
        'search_query': searchQuery,
        'creator_ids': creatorIds,
        'order_by': orderBy,
        'descending': descending,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Star Schema Data Source Conversations Count
   * Read Star Schema Data Source Conversations.
   *
   * If show_moonlight is True, show only moonlight conversations
   * If show_moonlight is False, show only non-moonlight conversations
   * If show_moonlight is None, show everything.
   *
   * If show_training is True, show only is_training conversations
   * If show_training is False, show only non-is_training conversations
   * If show_training is None, show everything.
   *
   * If show_eval is True, show only eval conversations
   * If show_eval is False, show only non-eval conversations
   * If show_eval is None, show everything.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param showHidden
   * @param showEval
   * @param showMoonlight
   * @param showTraining
   * @param searchQuery
   * @param creatorIds
   * @param orderBy
   * @param descending
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getStarSchemaDataSourceConversationsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    showHidden: boolean = false,
    showEval?: (boolean | null),
    showMoonlight?: (boolean | null),
    showTraining?: (boolean | null),
    searchQuery?: (string | null),
    creatorIds?: (Array<string> | null),
    orderBy?: ConversationsSort,
    descending: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/conversations_count',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'show_hidden': showHidden,
        'show_eval': showEval,
        'show_moonlight': showMoonlight,
        'show_training': showTraining,
        'search_query': searchQuery,
        'creator_ids': creatorIds,
        'order_by': orderBy,
        'descending': descending,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Recompute Expr Types
   * Recompute the expr_type for all dimensions and metrics.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public recomputeExprTypes(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/compute-expr-types',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Account Ssds Semantic Queries
   * Get semantic queries for the given account and ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns SemanticQuery Successful Response
   * @throws ApiError
   */
  public getAccountSsdsSemanticQueries(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<SemanticQuery>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/semantic_queries',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Upload Dimension Groups
   * Upload dimension groups from dict mapping dm names to group ids.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public uploadDimensionGroups(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Record<string, string>,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_dimension_groups',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove Dm Groups
   * Create dimension groups for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public removeDmGroups(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/remove_dimension_groups',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Enrich Ssds
   * Upload dms to an existing ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @param formData
   * @returns Task Successful Response
   * @throws ApiError
   */
  public enrichSsds(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
    formData?: Body_enrich_ssds_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__enrich_ssds_post,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/enrich_ssds',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Upload Examples
   * Upload examples to an existing ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public uploadExamples(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<BBExample>,
    sessionId?: (string | null),
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_examples',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Validate Star Schema Data Source
   * Validate Star Schema Data Source.
   *
   * Check all dimensions, metrics, and filters and mark invalid ones as Deprecated.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public validateStarSchemaDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: (string | null),
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/validate',
      path: {
        'account_name': accountName,
        'star_schema_data_source_id': starSchemaDataSourceId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Star Schema Data Source
   * Update Star Schema Data Source.
   *
   * This endpoint allows users to update the star schema data schema with a new definition.
   * It requires the input of StarSchemaDataSourceColdStart, which is the same as a cold start.
   * The endpoint will scan the tables and views and add new data sources (StarSchemaDataSourceTableOrViewInfo without data_source_id),
   * delete data sources that exist in the existing SSDS but not in the tables_and_views,
   * and update the existing data sources (StarSchemaDataSourceTableOrViewInfo with data_source_id)
   * if either the table or SQL changes.
   *
   * Moreover, the endpoint introduces a new argument called `force_update` that allows users to
   * update existing data sources to reflect the underlying database schema changes, even if the
   * table or SQL remains the same. By default, this argument is set to false, and it will only
   * update the dimensions and metrics related to modified data sources.
   *
   * Example:
   *
   * Given the original SSDS definition:
   * ```
   * tables_and_views = [
     * StarSchemaDataSourceTableOrViewInfo(
       * name="MAKANA_WAITTIME_1",
       * db="CI_FOR_PYTEST",
       * db_schema="PYTEST",
       * table="MAKANA_WAITTIME_1",
       * ),
       * StarSchemaDataSourceTableOrViewInfo(
         * name="MAKANA_WAITTIME_2_100",
         * sql="SELECT * FROM CI_FOR_PYTEST.PYTEST.MAKANA_WAITTIME_2 LIMIT 100",
         * ),
         * ]
         * body = {
           * "tables_and_views": cold_start_tables,
           * "ssds_name": "MAKANA_WAITTIMES (pytest)",
           * }
           * r = client.post(
             * f"{settings.API_V1_STR}/accounts/{account.name}/star_schema_data_source/cold_start_from_tables",
             * cookies=user_cookie,
             * params={"connection_id": connection_db.id},  # type: ignore
             * json=jsonable_encoder(body),
             * )
             * ```
             *
             * We need to perform the following updates to the SSDS:
             * 1. Update the first data source with sql `SELECT *, 1 AS CNT FROM CI_FOR_PYTEST.PYTEST.MAKANA_WAITTIME_1 LIMIT 100;`
             * 2. Delete the second data source
             * 3. Create a new data source with table `CI_FOR_PYTEST.PYTEST.MAKANA_WAITTIME_3`
             *
             * ```
             * updated_tables = [
               * # Update the first data source since the data_source_id is filled in
               * StarSchemaDataSourceTableOrViewInfo(
                 * data_source_id=ssds["data_sources"][0]["id"],
                 * name="MAKANA_WAITTIME_1",
                 * sql="SELECT *, 1 AS CNT FROM CI_FOR_PYTEST.PYTEST.MAKANA_WAITTIME_1 LIMIT 100;",
                 * ),
                 * # Delete the second data source since the original second data source is not in the new list
                 * # Create a new data source with table `CI_FOR_PYTEST.PYTEST.MAKANA_WAITTIME_3`
                 * StarSchemaDataSourceTableOrViewInfo(
                   * name="MAKANA_WAITTIME_3",
                   * db="CI_FOR_PYTEST",
                   * db_schema="PYTEST",
                   * table="MAKANA_WAITTIME_3",
                   * ),
                   * ]
                   * body = {
                     * "tables_and_views": updated_tables,
                     * "ssds_name": "updated ssds",
                     * "update_identifiers": False,
                     * "ssds_enrich_params": {"enable": True},
                     * }
                     * r = client.post(
                       * f"{settings.API_V1_STR}/accounts/{account.name}/star_schema_data_source/{ssds['id']}/update",
                       * cookies=user_cookie,
                       * params={"force_update": False},
                       * json=jsonable_encoder(body),
                       * )
                       * ```
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param forceUpdate
                       * @param sessionId
                       * @param requestBody
                       * @returns StarSchemaDataSource Successful Response
                       * @throws ApiError
                       */
                      public updateStarSchemaDataSource(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        forceUpdate: boolean = false,
                        sessionId?: (string | null),
                        requestBody?: (StarSchemaDataSourceColdStart | null),
                      ): CancelablePromise<StarSchemaDataSource> {
                        return this.httpRequest.request({
                          method: 'PUT',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/update',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'force_update': forceUpdate,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Add Data Sources
                       * Add new data sources to the Star Schema Data Source.
                       *
                       * This endpoint allows users to add data sources to the existing star schema data schema.
                       * It requires the input of a list of StarSchemaDataSourceTableOrViewInfo without data source id.
                       * The endpoint will scan the StarSchemaDataSourceTableOrViewInfo and perform cold start for the
                       * new data sources and do enrich_ssds with related dimensions and metrics.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param requestBody
                       * @param sessionId
                       * @returns StarSchemaDataSource Successful Response
                       * @throws ApiError
                       */
                      public addDataSources(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        requestBody: Array<StarSchemaDataSourceTableOrViewInfo>,
                        sessionId?: (string | null),
                      ): CancelablePromise<StarSchemaDataSource> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/add_data_sources',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Delete Data Source
                       * Delete a data source and set the states of all related dimensions and metrics invalid.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param dataSourceId
                       * @param sessionId
                       * @returns void
                       * @throws ApiError
                       */
                      public deleteDataSource(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        dataSourceId: string,
                        sessionId?: (string | null),
                      ): CancelablePromise<void> {
                        return this.httpRequest.request({
                          method: 'DELETE',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                            'data_source_id': dataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Update Data Source
                       * Update existing data source in the Star Schema Data Source.
                       *
                       * This endpoint allows users to update existing data source in the existing star schema data schema.
                       * It requires the input of StarSchemaDataSourceTableOrViewInfo without data source id.
                       * The endpoint will scan the StarSchemaDataSourceTableOrViewInfo and perform cold start for the
                       * updated data source and do enrich_ssds with related dimensions and metrics.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param dataSourceId
                       * @param requestBody
                       * @param sessionId
                       * @returns StarSchemaDataSource Successful Response
                       * @throws ApiError
                       */
                      public updateDataSource(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        dataSourceId: string,
                        requestBody: StarSchemaDataSourceTableOrViewInfo,
                        sessionId?: (string | null),
                      ): CancelablePromise<StarSchemaDataSource> {
                        return this.httpRequest.request({
                          method: 'PUT',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                            'data_source_id': dataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Trim Text Columns Sql For Data Source
                       * Generates a SQL that trims all the text columns from an existing data source.
                       *
                       * All the text columns will be applied with a trim.
                       *
                       * Returns:
                       * the modified sql
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param dataSourceId
                       * @param sessionId
                       * @returns string Successful Response
                       * @throws ApiError
                       */
                      public trimTextColumnsSqlForDataSource(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        dataSourceId: string,
                        sessionId?: (string | null),
                      ): CancelablePromise<string> {
                        return this.httpRequest.request({
                          method: 'GET',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}/trim_text_columns_sql',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                            'data_source_id': dataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Get Ssds Table And View Info
                       * Get table and view info for the given ssds.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param sessionId
                       * @returns StarSchemaDataSourceTableOrViewInfo Successful Response
                       * @throws ApiError
                       */
                      public getSsdsTableAndViewInfo(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        sessionId?: (string | null),
                      ): CancelablePromise<Array<StarSchemaDataSourceTableOrViewInfo>> {
                        return this.httpRequest.request({
                          method: 'GET',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/table_and_view_info',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Batch Update Dm Sensitive
                       * Batch update DMs for sensitive given the assignment.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param requestBody
                       * @param sessionId
                       * @returns any Successful Response
                       * @throws ApiError
                       */
                      public batchUpdateDmSensitive(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        requestBody: DMSensitiveAssignment,
                        sessionId?: (string | null),
                      ): CancelablePromise<any> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/batch_update_dm_sensitive',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Cold Start From Tables
                       * Cold start from table.
                       * @param accountName
                       * @param connectionId
                       * @param requestBody
                       * @param ssdsId
                       * @param sessionId
                       * @returns Task Successful Response
                       * @throws ApiError
                       */
                      public coldStartFromTables(
                        accountName: string,
                        connectionId: string,
                        requestBody: StarSchemaDataSourceColdStart,
                        ssdsId?: (string | null),
                        sessionId?: (string | null),
                      ): CancelablePromise<Task> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/cold_start_from_tables',
                          path: {
                            'account_name': accountName,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'ssds_id': ssdsId,
                            'connection_id': connectionId,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Enrich From Mode
                       * Enrich an SSDS from a mode report.
                       *
                       * This is intended to be applied to data sources that are created via
                       * `create_table_from_report` since we assume there is a 1-1 linking between
                       * the data source and report.
                       * @param accountName
                       * @param reportId
                       * @param connectionId
                       * @param modeConnectionId
                       * @param dataSourceId
                       * @param sessionId
                       * @returns Task Successful Response
                       * @throws ApiError
                       */
                      public enrichFromMode(
                        accountName: string,
                        reportId: string,
                        connectionId: string,
                        modeConnectionId: string,
                        dataSourceId: string,
                        sessionId?: (string | null),
                      ): CancelablePromise<Task> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/enrich_from_mode',
                          path: {
                            'account_name': accountName,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'report_id': reportId,
                            'connection_id': connectionId,
                            'mode_connection_id': modeConnectionId,
                            'data_source_id': dataSourceId,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Create Table From Mode
                       * Create a table from a mode report.
                       *
                       * The database, schema, and table name indicate the path at which the table will be created.
                       * @param accountName
                       * @param reportId
                       * @param tableDatabase
                       * @param tableSchema
                       * @param tableName
                       * @param connectionId
                       * @param modeConnectionId
                       * @param createTable
                       * @param sessionId
                       * @returns CreateTableFromReportReturnInfo Successful Response
                       * @throws ApiError
                       */
                      public createTableFromMode(
                        accountName: string,
                        reportId: string,
                        tableDatabase: string,
                        tableSchema: string,
                        tableName: string,
                        connectionId: string,
                        modeConnectionId: string,
                        createTable: boolean = true,
                        sessionId?: (string | null),
                      ): CancelablePromise<CreateTableFromReportReturnInfo> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/create_table_from_mode',
                          path: {
                            'account_name': accountName,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'report_id': reportId,
                            'table_database': tableDatabase,
                            'table_schema': tableSchema,
                            'table_name': tableName,
                            'create_table': createTable,
                            'connection_id': connectionId,
                            'mode_connection_id': modeConnectionId,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Refresh Table From Mode
                       * Refresh a table from a mode report.
                       *
                       * We read the query token from the data source's table and use it to refresh the table.
                       *
                       * This assumes that the table was created via `create_table_from_mode`.
                       * @param accountName
                       * @param connectionId
                       * @param modeConnectionId
                       * @param dataSourceId
                       * @param runToken
                       * @param sessionId
                       * @returns CreateTableFromReportReturnInfo Successful Response
                       * @throws ApiError
                       */
                      public refreshTableFromMode(
                        accountName: string,
                        connectionId: string,
                        modeConnectionId: string,
                        dataSourceId: string,
                        runToken?: (string | null),
                        sessionId?: (string | null),
                      ): CancelablePromise<CreateTableFromReportReturnInfo> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/refresh_table_from_mode',
                          path: {
                            'account_name': accountName,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'run_token': runToken,
                            'connection_id': connectionId,
                            'mode_connection_id': modeConnectionId,
                            'data_source_id': dataSourceId,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Suggest Dataset Fixes
                       * Given a list of chats with feedback, suggest fixes to the dataset.
                       *
                       * Args:
                       * chats_with_feedback: list of chat ids with feedback. When None, we pull the latest `limit` chats with admin feedback which is not yet incorporated.
                       * limit: number of chats to pull when `chats_with_feedback` is None.
                       * offset: offset to start pulling chats when `chats_with_feedback` is None.
                       * @param accountName The name of the organization or account.
                       * @param limit
                       * @param offset
                       * @param sessionId
                       * @param requestBody
                       * @returns SqlValidation Successful Response
                       * @throws ApiError
                       */
                      public suggestDatasetFixes(
                        accountName: string,
                        limit: number = 20,
                        offset?: number,
                        sessionId?: (string | null),
                        requestBody?: (Array<string> | null),
                      ): CancelablePromise<Array<SqlValidation>> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/suggest_fixes',
                          path: {
                            'account_name': accountName,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'limit': limit,
                            'offset': offset,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Update Dm Popularity
                       * Refresh Dimension and Metric Popularity scores from verified questions.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param useSemanticQueries
                       * @param dryRun
                       * @param sessionId
                       * @returns number Successful Response
                       * @throws ApiError
                       */
                      public updateDmPopularity(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        useSemanticQueries: boolean = false,
                        dryRun: boolean = false,
                        sessionId?: (string | null),
                      ): CancelablePromise<Record<string, number>> {
                        return this.httpRequest.request({
                          method: 'PUT',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/refresh_dm_popularity',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'use_semantic_queries': useSemanticQueries,
                            'dry_run': dryRun,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Validate Table And View Infos
                       * Validate the table or view definition.
                       * @param accountName
                       * @param connectionId
                       * @param requestBody
                       * @param sessionId
                       * @returns TableOrViewInfoValidation Successful Response
                       * @throws ApiError
                       */
                      public validateTableAndViewInfos(
                        accountName: string,
                        connectionId: string,
                        requestBody: Array<StarSchemaDataSourceTableOrViewInfo>,
                        sessionId?: (string | null),
                      ): CancelablePromise<Array<TableOrViewInfoValidation>> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/validate_table_and_view_infos',
                          path: {
                            'account_name': accountName,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'connection_id': connectionId,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Bulk Create Cells
                       * Upload cells to an account.
                       *
                       * Attempts to create with the same user, but will default to the admin
                       * if the user doesn't belong to the account.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param requestBody
                       * @param sessionId
                       * @returns BumblebeeCellBasic Successful Response
                       * @throws ApiError
                       */
                      public bulkCreateCells(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        requestBody: Array<BumblebeeCellBasic>,
                        sessionId?: (string | null),
                      ): CancelablePromise<Array<BumblebeeCellBasic>> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_cells',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Bulk Create Feedback
                       * Upload feedback to an account.
                       *
                       * Attempts to create with the same user, but will default to the admin
                       * if the user doesn't belong to the account.
                       *
                       * NOTE: If a user is overwritten by an admin, the admin may be re-used to give feedback,
                       * which means only the last feedback issued by the admin will apply.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param allowConflictingFeedback
                       * @param requestBody
                       * @param sessionId
                       * @returns Feedback Successful Response
                       * @throws ApiError
                       */
                      public bulkCreateFeedback(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        allowConflictingFeedback: boolean,
                        requestBody: Array<Feedback>,
                        sessionId?: (string | null),
                      ): CancelablePromise<Array<Feedback>> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_feedback',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'allow_conflicting_feedback': allowConflictingFeedback,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Delete All Named Filters
                       * Delete all Named Filters matching SSDS id.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param sessionId
                       * @returns any Successful Response
                       * @throws ApiError
                       */
                      public deleteAllNamedFilters(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        sessionId?: (string | null),
                      ): CancelablePromise<any> {
                        return this.httpRequest.request({
                          method: 'DELETE',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/named_filters',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Bulk Create Named Filters
                       * Add Named Filters with the appropriate SSDS id.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param requestBody
                       * @param sessionId
                       * @returns NamedFilterBase Successful Response
                       * @throws ApiError
                       */
                      public bulkCreateNamedFilters(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        requestBody: Array<NamedFilterCreate>,
                        sessionId?: (string | null),
                      ): CancelablePromise<Array<NamedFilterBase>> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_named_filters',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Bulk Create Semantic Queries
                       * Upload Semantic Queries associated with ssds_id.
                       * @param accountName
                       * @param requestBody
                       * @param sessionId
                       * @returns SemanticQuery Successful Response
                       * @throws ApiError
                       */
                      public bulkCreateSemanticQueries(
                        accountName: string,
                        requestBody: Array<SemanticQueryCreate>,
                        sessionId?: (string | null),
                      ): CancelablePromise<Array<SemanticQuery>> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/upload_semantic_queries',
                          path: {
                            'account_name': accountName,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Import From Twb
                       * Import DMs from a twb/twbx file.
                       *
                       * This parses the twb and creates a star schema data source.
                       * @param accountName
                       * @param ssdsId
                       * @param connectionId
                       * @param formData
                       * @param updateIdentifiers
                       * @param sessionId
                       * @returns any Successful Response
                       * @throws ApiError
                       */
                      public importFromTwb(
                        accountName: string,
                        ssdsId: string,
                        connectionId: string,
                        formData: Body_import_from_twb_api_v1_accounts__account_name__star_schema_data_source_import_from_twb_post,
                        updateIdentifiers: boolean = true,
                        sessionId?: (string | null),
                      ): CancelablePromise<any> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/import_from_twb',
                          path: {
                            'account_name': accountName,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'ssds_id': ssdsId,
                            'update_identifiers': updateIdentifiers,
                            'connection_id': connectionId,
                          },
                          formData: formData,
                          mediaType: 'multipart/form-data',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Run Eval On Reference Conversations
                       * Run eval on reference conversations.
                       *
                       * Args:
                       * max_eval_conversations: The upper limit of conversations to run.
                       * eval_filter: Select which cells to include as ground-truth cells.
                       * moonlight_run_params: Run parameters for cells.
                       * run_in_reverse_order: Preserve upstream context by running cells in reverse order to independently evaluate each cell in a conversation.
                       * We clone a conversation from reference and then rerun from the last (when this is set True) to reuse the reference context instead of the predicted context.
                       * delete_conversations: Remove newly-created conversations after evaluation.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param requestBody
                       * @param maxEvalConversations
                       * @param runInReverseOrder
                       * @param deleteNewChats
                       * @param sessionId
                       * @returns Task Successful Response
                       * @throws ApiError
                       */
                      public runEvalOnReferenceConversations(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        requestBody: Body_run_eval_on_reference_conversations_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__eval_reference_conversations_post,
                        maxEvalConversations?: (number | null),
                        runInReverseOrder: boolean = true,
                        deleteNewChats: boolean = true,
                        sessionId?: (string | null),
                      ): CancelablePromise<Task> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/eval_reference_conversations',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'max_eval_conversations': maxEvalConversations,
                            'run_in_reverse_order': runInReverseOrder,
                            'delete_new_chats': deleteNewChats,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Run Regression Test
                       * Run regression test on verified cells.
                       *
                       * Args:
                       * max_eval_messages: The upper limit of messages to run.
                       * delete_new_chats: Remove newly-created chats after evaluation.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param maxEvalMessages
                       * @param deleteNewChats
                       * @param sessionId
                       * @param requestBody
                       * @returns Task Successful Response
                       * @throws ApiError
                       */
                      public runRegressionTest(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        maxEvalMessages?: (number | null),
                        deleteNewChats: boolean = true,
                        sessionId?: (string | null),
                        requestBody?: (M2MTokenCreds | null),
                      ): CancelablePromise<Task> {
                        return this.httpRequest.request({
                          method: 'POST',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/regression_test',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'max_eval_messages': maxEvalMessages,
                            'delete_new_chats': deleteNewChats,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Get Eval On Reference Conversations Results
                       * Get eval results for an SSDS.
                       *
                       * This returns results metadata for the SSDS. Full eval results with conversations can be retrieved by ID.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param taskId
                       * @param sessionId
                       * @returns EvalResultBase Successful Response
                       * @throws ApiError
                       */
                      public getEvalOnReferenceConversationsResults(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        taskId?: (string | null),
                        sessionId?: (string | null),
                      ): CancelablePromise<Array<EvalResultBase>> {
                        return this.httpRequest.request({
                          method: 'GET',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/eval_reference_conversations_results',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'task_id': taskId,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Get Eval On Reference Conversation Result By Id
                       * Get eval result for an SSDS by ID.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param evalResultId
                       * @param sessionId
                       * @returns any Successful Response
                       * @throws ApiError
                       */
                      public getEvalOnReferenceConversationResultById(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        evalResultId: string,
                        sessionId?: (string | null),
                      ): CancelablePromise<any> {
                        return this.httpRequest.request({
                          method: 'GET',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/eval_reference_conversations_results/{eval_result_id}',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                            'eval_result_id': evalResultId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Delete Eval On Reference Conversations Result
                       * Delete an eval result
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param evalResultId
                       * @param sessionId
                       * @returns void
                       * @throws ApiError
                       */
                      public deleteEvalOnReferenceConversationsResult(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        evalResultId: string,
                        sessionId?: (string | null),
                      ): CancelablePromise<void> {
                        return this.httpRequest.request({
                          method: 'DELETE',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/eval_reference_conversations_results/{eval_result_id}',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                            'eval_result_id': evalResultId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Get Dm Usage
                       * Get histogram of DM usage in semantic queries.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param augmentDms
                       * @param sessionId
                       * @param requestBody
                       * @returns any Successful Response
                       * @throws ApiError
                       */
                      public getDmUsage(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        augmentDms: boolean = true,
                        sessionId?: (string | null),
                        requestBody?: (FeedbackFilter | null),
                      ): CancelablePromise<any> {
                        return this.httpRequest.request({
                          method: 'GET',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dm_usage',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'augment_dms': augmentDms,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Remap Ssds Dms
                       * Remap all semantic queries to use new dimension/metric names.
                       *
                       * This does not alter any dimension/metric definitions, but only usage in semantic queries.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param requestBody
                       * @param sessionId
                       * @returns any Successful Response
                       * @throws ApiError
                       */
                      public remapSsdsDms(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        requestBody: Record<string, string>,
                        sessionId?: (string | null),
                      ): CancelablePromise<any> {
                        return this.httpRequest.request({
                          method: 'PUT',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/remap_dms',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                      /**
                       * Coalesce Dms
                       * Coalesce all semantic queries in selected cells.
                       *
                       * We accept bumblebee cells as input, because we don't relate semantic queries directly to SSDSs.
                       * If none are specified, this will coalesce all existing bumblebee cells.
                       * @param accountName
                       * @param starSchemaDataSourceId
                       * @param groupingStrategy
                       * @param dryRun
                       * @param sessionId
                       * @param requestBody
                       * @returns SemanticQuery Successful Response
                       * @throws ApiError
                       */
                      public coalesceDms(
                        accountName: string,
                        starSchemaDataSourceId: string,
                        groupingStrategy: SemanticQueryCoalescingStrategy,
                        dryRun: boolean = false,
                        sessionId?: (string | null),
                        requestBody?: (Array<string> | null),
                      ): CancelablePromise<Array<Array<SemanticQuery>>> {
                        return this.httpRequest.request({
                          method: 'PUT',
                          url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/coalesce_dms',
                          path: {
                            'account_name': accountName,
                            'star_schema_data_source_id': starSchemaDataSourceId,
                          },
                          cookies: {
                            'session_id': sessionId,
                          },
                          query: {
                            'grouping_strategy': groupingStrategy,
                            'dry_run': dryRun,
                          },
                          body: requestBody,
                          mediaType: 'application/json',
                          errors: {
                            422: `Validation Error`,
                          },
                        });
                      }

                    }
