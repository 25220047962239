// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/Axis.ts"
);
import.meta.hot.lastModified = "1737759757503.0403";
}
// REMIX HMR END

export enum Axis {
  'x' = 'x',
  'y' = 'y',
  'size' = 'size',
  'group' = 'group',
}