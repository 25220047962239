// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyRunEvalOnReferenceConversationsApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdEvalReferenceConversationsPostSchema.ts"
);
import.meta.hot.lastModified = "1737935769735.7358";
}
// REMIX HMR END

// @ts-nocheck
import { evalFilterSchema } from './evalFilterSchema'
import { moonlightRunParamsSchema } from './moonlightRunParamsSchema'
import { z } from 'zod'

export const bodyRunEvalOnReferenceConversationsApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdEvalReferenceConversationsPostSchema =
  z.object({
    eval_filter: z.lazy(() => evalFilterSchema),
    moonlight_run_params: z.lazy(() => moonlightRunParamsSchema),
  })