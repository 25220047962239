// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUnverifyCell.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UnverifyCellMutationResponse, UnverifyCellPathParams, UnverifyCell422 } from '../types/UnverifyCell'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const unverifyCellMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/verified' }] as const

export type UnverifyCellMutationKey = ReturnType<typeof unverifyCellMutationKey>

/**
 * @description Add feedback to a bumblebee cell.
 * @summary Unverify Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/verified}
 */
async function unverifyCell(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: UnverifyCellPathParams['account_name']
    conversationId: UnverifyCellPathParams['conversation_id']
    cellId: UnverifyCellPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UnverifyCellMutationResponse, UnverifyCell422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/verified`,
    ...config,
  })
  return res.data
}

/**
 * @description Add feedback to a bumblebee cell.
 * @summary Unverify Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/verified}
 */
export function useUnverifyCell(
  options: {
    mutation?: UseMutationOptions<
      UnverifyCellMutationResponse,
      UnverifyCell422,
      {
        accountName: UnverifyCellPathParams['account_name']
        conversationId: UnverifyCellPathParams['conversation_id']
        cellId: UnverifyCellPathParams['cell_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? unverifyCellMutationKey()

  return useMutation<
    UnverifyCellMutationResponse,
    UnverifyCell422,
    {
      accountName: UnverifyCellPathParams['account_name']
      conversationId: UnverifyCellPathParams['conversation_id']
      cellId: UnverifyCellPathParams['cell_id']
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId }) => {
      return unverifyCell({ accountName, conversationId, cellId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}