// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/taskPageSchema.ts"
);
import.meta.hot.lastModified = "1737759757948.038";
}
// REMIX HMR END

// @ts-nocheck
import { taskSchema } from './taskSchema'
import { z } from 'zod'

/**
 * @description A page of tasks.
 */
export const taskPageSchema = z
  .object({
    tasks: z.array(z.lazy(() => taskSchema)),
    total: z.number().int(),
  })
  .describe('A page of tasks.')