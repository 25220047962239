// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateUserOpen.ts"
);
import.meta.hot.lastModified = "1737759759257.0312";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateUserOpenMutationRequest, CreateUserOpenMutationResponse, CreateUserOpen422 } from '../types/CreateUserOpen'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createUserOpenMutationKey = () => [{ url: '/api/v1/users/open' }] as const

export type CreateUserOpenMutationKey = ReturnType<typeof createUserOpenMutationKey>

/**
 * @description Create new user without the need to be logged in.
 * @summary Create User Open
 * {@link /api/v1/users/open}
 */
async function createUserOpen({ data }: { data: CreateUserOpenMutationRequest }, config: Partial<RequestConfig<CreateUserOpenMutationRequest>> = {}) {
  const res = await client<CreateUserOpenMutationResponse, CreateUserOpen422, CreateUserOpenMutationRequest>({
    method: 'POST',
    url: `/api/v1/users/open`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create new user without the need to be logged in.
 * @summary Create User Open
 * {@link /api/v1/users/open}
 */
export function useCreateUserOpen(
  options: {
    mutation?: UseMutationOptions<CreateUserOpenMutationResponse, CreateUserOpen422, { data: CreateUserOpenMutationRequest }>
    client?: Partial<RequestConfig<CreateUserOpenMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createUserOpenMutationKey()

  return useMutation<CreateUserOpenMutationResponse, CreateUserOpen422, { data: CreateUserOpenMutationRequest }>({
    mutationFn: async ({ data }) => {
      return createUserOpen({ data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}