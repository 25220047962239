// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadPostgresConnectionTables.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadPostgresConnectionTablesQueryResponse,
  ReadPostgresConnectionTablesPathParams,
  ReadPostgresConnectionTablesQueryParams,
  ReadPostgresConnectionTables422,
} from '../types/ReadPostgresConnectionTables'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readPostgresConnectionTablesQueryKey = (
  {
    accountName,
    postgresConnectionId,
  }: {
    accountName: ReadPostgresConnectionTablesPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionTablesPathParams['postgres_connection_id']
  },
  params?: ReadPostgresConnectionTablesQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/:postgres_connection_id/tables',
      params: { accountName: accountName, postgresConnectionId: postgresConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadPostgresConnectionTablesQueryKey = ReturnType<typeof readPostgresConnectionTablesQueryKey>

/**
 * @description Read Postgres tables.This endpoint returns a dictionary of tables for each schema in the database.Args:    db: SQLAlchemy session.    current_user: The current user.    account: The current account.    connection: The Postgres connection.    schema: Optional schema to filter tables by.
 * @summary Read Postgres Connection Tables
 * {@link /api/v1/accounts/:account_name/connections/:postgres_connection_id/tables}
 */
async function readPostgresConnectionTables(
  {
    accountName,
    postgresConnectionId,
    params,
  }: {
    accountName: ReadPostgresConnectionTablesPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionTablesPathParams['postgres_connection_id']
    params?: ReadPostgresConnectionTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadPostgresConnectionTablesQueryResponse, ReadPostgresConnectionTables422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/${postgresConnectionId}/tables`,
    params,
    ...config,
  })
  return res.data
}

export function readPostgresConnectionTablesQueryOptions(
  {
    accountName,
    postgresConnectionId,
    params,
  }: {
    accountName: ReadPostgresConnectionTablesPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionTablesPathParams['postgres_connection_id']
    params?: ReadPostgresConnectionTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readPostgresConnectionTablesQueryKey({ accountName, postgresConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && postgresConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readPostgresConnectionTables({ accountName, postgresConnectionId, params }, config)
    },
  })
}

/**
 * @description Read Postgres tables.This endpoint returns a dictionary of tables for each schema in the database.Args:    db: SQLAlchemy session.    current_user: The current user.    account: The current account.    connection: The Postgres connection.    schema: Optional schema to filter tables by.
 * @summary Read Postgres Connection Tables
 * {@link /api/v1/accounts/:account_name/connections/:postgres_connection_id/tables}
 */
export function useReadPostgresConnectionTables<
  TData = ReadPostgresConnectionTablesQueryResponse,
  TQueryData = ReadPostgresConnectionTablesQueryResponse,
  TQueryKey extends QueryKey = ReadPostgresConnectionTablesQueryKey,
>(
  {
    accountName,
    postgresConnectionId,
    params,
  }: {
    accountName: ReadPostgresConnectionTablesPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionTablesPathParams['postgres_connection_id']
    params?: ReadPostgresConnectionTablesQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadPostgresConnectionTablesQueryResponse, ReadPostgresConnectionTables422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readPostgresConnectionTablesQueryKey({ accountName, postgresConnectionId }, params)

  const query = useQuery({
    ...(readPostgresConnectionTablesQueryOptions({ accountName, postgresConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadPostgresConnectionTables422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}