// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readMessageTableDataAsCsvSchema.ts"
);
import.meta.hot.lastModified = "1737759761869.0178";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readMessageTableDataAsCsvPathParamsSchema = z.object({
  account_name: z.string(),
  message_id: z.string().describe('The id of a message in a chat.'),
})

/**
 * @description The table data as a CSV file.
 */
export const readMessageTableDataAsCsv200Schema = z.unknown()

/**
 * @description Bad request. Verify that the message contains a table ID.
 */
export const readMessageTableDataAsCsv400Schema = z.unknown()

/**
 * @description The item could not be found.
 */
export const readMessageTableDataAsCsv404Schema = z.unknown()

/**
 * @description Validation Error
 */
export const readMessageTableDataAsCsv422Schema = z.lazy(() => httpValidationErrorSchema)

export const readMessageTableDataAsCsvQueryResponseSchema = z.lazy(() => readMessageTableDataAsCsv200Schema)