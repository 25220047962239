// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatMessagePromptlogInDbSchema.ts"
);
import.meta.hot.lastModified = "1737935768984.74";
}
// REMIX HMR END

// @ts-nocheck
import { chatMessageModelParametersSchema } from './chatMessageModelParametersSchema'
import { z } from 'zod'

/**
 * @description Class to map Pydantic ChatMessagePromptlog to ORM.
 */
export const chatMessagePromptlogInDbSchema = z
  .object({
    message_id: z.string(),
    agent_name: z.string(),
    messages: z.array(z.object({})),
    model_params: z.union([z.lazy(() => chatMessageModelParametersSchema), z.null()]),
    id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
  })
  .describe('Class to map Pydantic ChatMessagePromptlog to ORM.')