// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/refreshBumblebeeCellSchema.ts"
);
import.meta.hot.lastModified = "1737935768205.7444";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const refreshBumblebeeCellPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

/**
 * @description Successful Response
 */
export const refreshBumblebeeCell200Schema = z.lazy(() => taskSchema)

/**
 * @description Validation Error
 */
export const refreshBumblebeeCell422Schema = z.lazy(() => httpValidationErrorSchema)

export const refreshBumblebeeCellMutationResponseSchema = z.lazy(() => refreshBumblebeeCell200Schema)