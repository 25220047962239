// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useBulkCreateCells.ts"
);
import.meta.hot.lastModified = "1737759759784.0286";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { BulkCreateCellsMutationRequest, BulkCreateCellsMutationResponse, BulkCreateCellsPathParams, BulkCreateCells422 } from '../types/BulkCreateCells'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const bulkCreateCellsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_cells' }] as const

export type BulkCreateCellsMutationKey = ReturnType<typeof bulkCreateCellsMutationKey>

/**
 * @description Upload cells to an account.Attempts to create with the same user, but will default to the adminif the user doesn't belong to the account.
 * @summary Bulk Create Cells
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/upload_cells}
 */
async function bulkCreateCells(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: BulkCreateCellsPathParams['account_name']
    starSchemaDataSourceId: BulkCreateCellsPathParams['star_schema_data_source_id']
    data?: BulkCreateCellsMutationRequest
  },
  config: Partial<RequestConfig<BulkCreateCellsMutationRequest>> = {},
) {
  const res = await client<BulkCreateCellsMutationResponse, BulkCreateCells422, BulkCreateCellsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/upload_cells`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Upload cells to an account.Attempts to create with the same user, but will default to the adminif the user doesn't belong to the account.
 * @summary Bulk Create Cells
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/upload_cells}
 */
export function useBulkCreateCells(
  options: {
    mutation?: UseMutationOptions<
      BulkCreateCellsMutationResponse,
      BulkCreateCells422,
      {
        accountName: BulkCreateCellsPathParams['account_name']
        starSchemaDataSourceId: BulkCreateCellsPathParams['star_schema_data_source_id']
        data?: BulkCreateCellsMutationRequest
      }
    >
    client?: Partial<RequestConfig<BulkCreateCellsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? bulkCreateCellsMutationKey()

  return useMutation<
    BulkCreateCellsMutationResponse,
    BulkCreateCells422,
    {
      accountName: BulkCreateCellsPathParams['account_name']
      starSchemaDataSourceId: BulkCreateCellsPathParams['star_schema_data_source_id']
      data?: BulkCreateCellsMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return bulkCreateCells({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}