// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/copyConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737935766487.7537";
}
// REMIX HMR END

// @ts-nocheck
import { bigQueryConnectionSchema } from './bigQueryConnectionSchema'
import { connectionSchema } from './connectionSchema'
import { databricksConnectionSchema } from './databricksConnectionSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { postgresConnectionSchema } from './postgresConnectionSchema'
import { redshiftConnectionSchema } from './redshiftConnectionSchema'
import { snowflakeConnectionSchema } from './snowflakeConnectionSchema'
import { teradataConnectionSchema } from './teradataConnectionSchema'
import { z } from 'zod'

export const copyConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  connection_id: z.string(),
})

export const copyConnectionQueryParamsSchema = z
  .object({
    dest_account_name: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const copyConnection200Schema = z.union([
  z.lazy(() => bigQueryConnectionSchema),
  z.lazy(() => postgresConnectionSchema),
  z.lazy(() => redshiftConnectionSchema),
  z.lazy(() => snowflakeConnectionSchema),
  z.lazy(() => databricksConnectionSchema),
  z.lazy(() => teradataConnectionSchema),
  z.lazy(() => connectionSchema),
])

/**
 * @description Validation Error
 */
export const copyConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const copyConnectionMutationResponseSchema = z.lazy(() => copyConnection200Schema)