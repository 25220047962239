// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/copyDatasetSchema.ts"
);
import.meta.hot.lastModified = "1737935765548.7588";
}
// REMIX HMR END

// @ts-nocheck
import { conversationCellFilterSchema } from './conversationCellFilterSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceTableOrViewInfoSchema } from './starSchemaDataSourceTableOrViewInfoSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const copyDatasetPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const copyDatasetQueryParamsSchema = z
  .object({
    dest_account_name: z.union([z.string(), z.null()]).optional(),
    dest_connection_id: z.union([z.string(), z.null()]).optional(),
    dest_ssds_name: z.union([z.string(), z.null()]).optional(),
    conversation_cell_filter: z.lazy(() => conversationCellFilterSchema).default('ALL'),
    overwrite_user_question_for_sensitive_cell: z.boolean().default(false),
    only_copy_conversations: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const copyDataset201Schema = z.lazy(() => taskSchema)

/**
 * @description Validation Error
 */
export const copyDataset422Schema = z.lazy(() => httpValidationErrorSchema)

export const copyDatasetMutationRequestSchema = z.union([z.array(z.lazy(() => starSchemaDataSourceTableOrViewInfoSchema)), z.null()])

export const copyDatasetMutationResponseSchema = z.lazy(() => copyDataset201Schema)