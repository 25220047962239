// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateDimensionNameSchema.ts"
);
import.meta.hot.lastModified = "1737759760888.023";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionDetailSchema } from './dimensionDetailSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateDimensionNamePathParamsSchema = z.object({
  account_name: z.string(),
  dimension_id: z.string(),
})

export const updateDimensionNameQueryParamsSchema = z.object({
  name: z.string(),
})

/**
 * @description Successful Response
 */
export const updateDimensionName200Schema = z.lazy(() => dimensionDetailSchema)

/**
 * @description Validation Error
 */
export const updateDimensionName422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateDimensionNameMutationResponseSchema = z.lazy(() => updateDimensionName200Schema)