// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateNamedFilterState.ts"
);
import.meta.hot.lastModified = "1737935768609.742";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateNamedFilterStateMutationRequest,
  UpdateNamedFilterStateMutationResponse,
  UpdateNamedFilterStatePathParams,
  UpdateNamedFilterState422,
} from '../types/UpdateNamedFilterState'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateNamedFilterStateMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters/{named_filter_id}/state' }] as const

export type UpdateNamedFilterStateMutationKey = ReturnType<typeof updateNamedFilterStateMutationKey>

/**
 * @description Update a filter's state.
 * @summary Update Named Filter State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters/:named_filter_id/state}
 */
async function updateNamedFilterState(
  {
    accountName,
    namedFilterId,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: UpdateNamedFilterStatePathParams['account_name']
    namedFilterId: UpdateNamedFilterStatePathParams['named_filter_id']
    starSchemaDataSourceId: UpdateNamedFilterStatePathParams['star_schema_data_source_id']
    data: UpdateNamedFilterStateMutationRequest
  },
  config: Partial<RequestConfig<UpdateNamedFilterStateMutationRequest>> = {},
) {
  const res = await client<UpdateNamedFilterStateMutationResponse, UpdateNamedFilterState422, UpdateNamedFilterStateMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/filters/${namedFilterId}/state`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a filter's state.
 * @summary Update Named Filter State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters/:named_filter_id/state}
 */
export function useUpdateNamedFilterState(
  options: {
    mutation?: UseMutationOptions<
      UpdateNamedFilterStateMutationResponse,
      UpdateNamedFilterState422,
      {
        accountName: UpdateNamedFilterStatePathParams['account_name']
        namedFilterId: UpdateNamedFilterStatePathParams['named_filter_id']
        starSchemaDataSourceId: UpdateNamedFilterStatePathParams['star_schema_data_source_id']
        data: UpdateNamedFilterStateMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateNamedFilterStateMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateNamedFilterStateMutationKey()

  return useMutation<
    UpdateNamedFilterStateMutationResponse,
    UpdateNamedFilterState422,
    {
      accountName: UpdateNamedFilterStatePathParams['account_name']
      namedFilterId: UpdateNamedFilterStatePathParams['named_filter_id']
      starSchemaDataSourceId: UpdateNamedFilterStatePathParams['star_schema_data_source_id']
      data: UpdateNamedFilterStateMutationRequest
    }
  >({
    mutationFn: async ({ accountName, namedFilterId, starSchemaDataSourceId, data }) => {
      return updateNamedFilterState({ accountName, namedFilterId, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}