// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/zenIdentifierTypeSchema.ts"
);
import.meta.hot.lastModified = "1737759760436.0251";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Possible ZenIdentifier types.
 */
export const zenIdentifierTypeSchema = z.enum(['primary', 'foreign', 'join']).describe('Possible ZenIdentifier types.')