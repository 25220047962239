// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/ApiKeyService.ts"
);
import.meta.hot.lastModified = "1737759746299.0981";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { APIKey } from '../models/APIKey';
import type { APIKeyAPICreate } from '../models/APIKeyAPICreate';
import type { APIKeyCreateResponse } from '../models/APIKeyCreateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApiKeyService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Api Key
   * Create a new API key.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns APIKeyCreateResponse Successful Response
   * @throws ApiError
   */
  public createApiKey(
    accountName: string,
    requestBody: APIKeyAPICreate,
    sessionId?: (string | null),
  ): CancelablePromise<APIKeyCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/api_key',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Api Key
   * Get the account's API Key.
   * @param accountName
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getApiKey(
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<(APIKey | null)> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/api_key',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Api Key
   * Delete the account's API Key.
   * @param accountName
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteApiKey(
    accountName: string,
    sessionId?: (string | null),
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/api_key',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
