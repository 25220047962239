// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/timeGranularitySchema.ts"
);
import.meta.hot.lastModified = "1737935766766.7522";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Time granularity enum.
 */
export const timeGranularitySchema = z.enum(['day', 'week', 'month', 'quarter', 'year']).describe('Time granularity enum.')