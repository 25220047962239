// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSetMetricDisplayType.ts"
);
import.meta.hot.lastModified = "1737935768071.745";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  SetMetricDisplayTypeMutationRequest,
  SetMetricDisplayTypeMutationResponse,
  SetMetricDisplayTypePathParams,
  SetMetricDisplayType422,
} from '../types/SetMetricDisplayType'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const setMetricDisplayTypeMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics/{metric_id}/display' }] as const

export type SetMetricDisplayTypeMutationKey = ReturnType<typeof setMetricDisplayTypeMutationKey>

/**
 * @description Set a metric's display_type.
 * @summary Set Metric Display Type
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/display}
 */
async function setMetricDisplayType(
  {
    metricId,
    accountName,
    data,
  }: {
    metricId: SetMetricDisplayTypePathParams['metric_id']
    accountName: SetMetricDisplayTypePathParams['account_name']
    data: SetMetricDisplayTypeMutationRequest
  },
  config: Partial<RequestConfig<SetMetricDisplayTypeMutationRequest>> = {},
) {
  const res = await client<SetMetricDisplayTypeMutationResponse, SetMetricDisplayType422, SetMetricDisplayTypeMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics/${metricId}/display`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Set a metric's display_type.
 * @summary Set Metric Display Type
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics/:metric_id/display}
 */
export function useSetMetricDisplayType(
  options: {
    mutation?: UseMutationOptions<
      SetMetricDisplayTypeMutationResponse,
      SetMetricDisplayType422,
      {
        metricId: SetMetricDisplayTypePathParams['metric_id']
        accountName: SetMetricDisplayTypePathParams['account_name']
        data: SetMetricDisplayTypeMutationRequest
      }
    >
    client?: Partial<RequestConfig<SetMetricDisplayTypeMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? setMetricDisplayTypeMutationKey()

  return useMutation<
    SetMetricDisplayTypeMutationResponse,
    SetMetricDisplayType422,
    {
      metricId: SetMetricDisplayTypePathParams['metric_id']
      accountName: SetMetricDisplayTypePathParams['account_name']
      data: SetMetricDisplayTypeMutationRequest
    }
  >({
    mutationFn: async ({ metricId, accountName, data }) => {
      return setMetricDisplayType({ metricId, accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}