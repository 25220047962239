// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateNamedFiltersStateSchema.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { namedFilterBaseSchema } from './namedFilterBaseSchema'
import { stateUpdateWithIdSchema } from './stateUpdateWithIdSchema'
import { z } from 'zod'

export const updateNamedFiltersStatePathParamsSchema = z.object({
  star_schema_data_source_id: z.string(),
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const updateNamedFiltersState200Schema = z.array(z.lazy(() => namedFilterBaseSchema))

/**
 * @description Validation Error
 */
export const updateNamedFiltersState422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateNamedFiltersStateMutationRequestSchema = z.array(z.lazy(() => stateUpdateWithIdSchema))

export const updateNamedFiltersStateMutationResponseSchema = z.lazy(() => updateNamedFiltersState200Schema)