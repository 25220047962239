// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/widgetOptionsSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Schema that defines the JSON dict to store widget options.
 */
export const widgetOptionsSchema = z
  .object({
    colors: z.union([z.array(z.string()).min(10), z.null()]).optional(),
  })
  .describe('Schema that defines the JSON dict to store widget options.')