// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadTableauConnectionWorkbooks.ts"
);
import.meta.hot.lastModified = "1737759762348.0154";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadTableauConnectionWorkbooksQueryResponse,
  ReadTableauConnectionWorkbooksPathParams,
  ReadTableauConnectionWorkbooksQueryParams,
  ReadTableauConnectionWorkbooks422,
} from '../types/ReadTableauConnectionWorkbooks'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readTableauConnectionWorkbooksQueryKey = (
  {
    accountName,
    tableauConnectionId,
  }: {
    accountName: ReadTableauConnectionWorkbooksPathParams['account_name']
    tableauConnectionId: ReadTableauConnectionWorkbooksPathParams['tableau_connection_id']
  },
  params?: ReadTableauConnectionWorkbooksQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/tableau/:tableau_connection_id/workbooks',
      params: { accountName: accountName, tableauConnectionId: tableauConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadTableauConnectionWorkbooksQueryKey = ReturnType<typeof readTableauConnectionWorkbooksQueryKey>

/**
 * @description Read Tableau workbooks.If project is provided, only workbooks in that project are returned. Otherwise, all workbooksare returned.
 * @summary Read Tableau Connection Workbooks
 * {@link /api/v1/accounts/:account_name/connections/tableau/:tableau_connection_id/workbooks}
 */
async function readTableauConnectionWorkbooks(
  {
    accountName,
    tableauConnectionId,
    params,
  }: {
    accountName: ReadTableauConnectionWorkbooksPathParams['account_name']
    tableauConnectionId: ReadTableauConnectionWorkbooksPathParams['tableau_connection_id']
    params?: ReadTableauConnectionWorkbooksQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadTableauConnectionWorkbooksQueryResponse, ReadTableauConnectionWorkbooks422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/tableau/${tableauConnectionId}/workbooks`,
    params,
    ...config,
  })
  return res.data
}

export function readTableauConnectionWorkbooksQueryOptions(
  {
    accountName,
    tableauConnectionId,
    params,
  }: {
    accountName: ReadTableauConnectionWorkbooksPathParams['account_name']
    tableauConnectionId: ReadTableauConnectionWorkbooksPathParams['tableau_connection_id']
    params?: ReadTableauConnectionWorkbooksQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readTableauConnectionWorkbooksQueryKey({ accountName, tableauConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && tableauConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readTableauConnectionWorkbooks({ accountName, tableauConnectionId, params }, config)
    },
  })
}

/**
 * @description Read Tableau workbooks.If project is provided, only workbooks in that project are returned. Otherwise, all workbooksare returned.
 * @summary Read Tableau Connection Workbooks
 * {@link /api/v1/accounts/:account_name/connections/tableau/:tableau_connection_id/workbooks}
 */
export function useReadTableauConnectionWorkbooks<
  TData = ReadTableauConnectionWorkbooksQueryResponse,
  TQueryData = ReadTableauConnectionWorkbooksQueryResponse,
  TQueryKey extends QueryKey = ReadTableauConnectionWorkbooksQueryKey,
>(
  {
    accountName,
    tableauConnectionId,
    params,
  }: {
    accountName: ReadTableauConnectionWorkbooksPathParams['account_name']
    tableauConnectionId: ReadTableauConnectionWorkbooksPathParams['tableau_connection_id']
    params?: ReadTableauConnectionWorkbooksQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadTableauConnectionWorkbooksQueryResponse, ReadTableauConnectionWorkbooks422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readTableauConnectionWorkbooksQueryKey({ accountName, tableauConnectionId }, params)

  const query = useQuery({
    ...(readTableauConnectionWorkbooksQueryOptions({ accountName, tableauConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadTableauConnectionWorkbooks422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}