// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ReportState.ts"
);
import.meta.hot.lastModified = "1737935754021.8223";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Report state.
 */
export enum ReportState {
  SYNCED = 'synced',
  OUT_OF_SYNC = 'out_of_sync',
  NOT_REACHABLE = 'not_reachable',
}
