// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteNamedFilter.ts"
);
import.meta.hot.lastModified = "1737935767422.7485";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteNamedFilterMutationResponse, DeleteNamedFilterPathParams, DeleteNamedFilter422 } from '../types/DeleteNamedFilter'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteNamedFilterMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters/{named_filter_id}' }] as const

export type DeleteNamedFilterMutationKey = ReturnType<typeof deleteNamedFilterMutationKey>

/**
 * @description Delete the specified named filter from the ssds.
 * @summary Delete Named Filter
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters/:named_filter_id}
 */
async function deleteNamedFilter(
  {
    accountName,
    starSchemaDataSourceId,
    namedFilterId,
  }: {
    accountName: DeleteNamedFilterPathParams['account_name']
    starSchemaDataSourceId: DeleteNamedFilterPathParams['star_schema_data_source_id']
    namedFilterId: DeleteNamedFilterPathParams['named_filter_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteNamedFilterMutationResponse, DeleteNamedFilter422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/filters/${namedFilterId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete the specified named filter from the ssds.
 * @summary Delete Named Filter
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters/:named_filter_id}
 */
export function useDeleteNamedFilter(
  options: {
    mutation?: UseMutationOptions<
      DeleteNamedFilterMutationResponse,
      DeleteNamedFilter422,
      {
        accountName: DeleteNamedFilterPathParams['account_name']
        starSchemaDataSourceId: DeleteNamedFilterPathParams['star_schema_data_source_id']
        namedFilterId: DeleteNamedFilterPathParams['named_filter_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteNamedFilterMutationKey()

  return useMutation<
    DeleteNamedFilterMutationResponse,
    DeleteNamedFilter422,
    {
      accountName: DeleteNamedFilterPathParams['account_name']
      starSchemaDataSourceId: DeleteNamedFilterPathParams['star_schema_data_source_id']
      namedFilterId: DeleteNamedFilterPathParams['named_filter_id']
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, namedFilterId }) => {
      return deleteNamedFilter({ accountName, starSchemaDataSourceId, namedFilterId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}