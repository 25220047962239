// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetLinkedUserChatId.ts"
);
import.meta.hot.lastModified = "1737935767879.746";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetLinkedUserChatIdQueryResponse, GetLinkedUserChatIdPathParams, GetLinkedUserChatId422 } from '../types/GetLinkedUserChatId'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getLinkedUserChatIdQueryKey = ({
  accountName,
  chatId,
}: {
  accountName: GetLinkedUserChatIdPathParams['account_name']
  chatId: GetLinkedUserChatIdPathParams['chat_id']
}) => [{ url: '/api/v3/orgs/:account_name/chat/:chat_id/linked_user_chat_id', params: { accountName: accountName, chatId: chatId } }] as const

export type GetLinkedUserChatIdQueryKey = ReturnType<typeof getLinkedUserChatIdQueryKey>

/**
 * @description Check if the feedback chat has admin feedback.
 * @summary Get Linked User Chat Id
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/linked_user_chat_id}
 */
async function getLinkedUserChatId(
  { accountName, chatId }: { accountName: GetLinkedUserChatIdPathParams['account_name']; chatId: GetLinkedUserChatIdPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetLinkedUserChatIdQueryResponse, GetLinkedUserChatId422, unknown>({
    method: 'GET',
    url: `/api/v3/orgs/${accountName}/chat/${chatId}/linked_user_chat_id`,
    ...config,
  })
  return res.data
}

export function getLinkedUserChatIdQueryOptions(
  { accountName, chatId }: { accountName: GetLinkedUserChatIdPathParams['account_name']; chatId: GetLinkedUserChatIdPathParams['chat_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getLinkedUserChatIdQueryKey({ accountName, chatId })
  return queryOptions({
    enabled: !!(accountName && chatId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getLinkedUserChatId({ accountName, chatId }, config)
    },
  })
}

/**
 * @description Check if the feedback chat has admin feedback.
 * @summary Get Linked User Chat Id
 * {@link /api/v3/orgs/:account_name/chat/:chat_id/linked_user_chat_id}
 */
export function useGetLinkedUserChatId<
  TData = GetLinkedUserChatIdQueryResponse,
  TQueryData = GetLinkedUserChatIdQueryResponse,
  TQueryKey extends QueryKey = GetLinkedUserChatIdQueryKey,
>(
  { accountName, chatId }: { accountName: GetLinkedUserChatIdPathParams['account_name']; chatId: GetLinkedUserChatIdPathParams['chat_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetLinkedUserChatIdQueryResponse, GetLinkedUserChatId422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getLinkedUserChatIdQueryKey({ accountName, chatId })

  const query = useQuery({
    ...(getLinkedUserChatIdQueryOptions({ accountName, chatId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetLinkedUserChatId422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}