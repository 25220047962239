// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRunMoonlightCell.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  RunMoonlightCellMutationRequest,
  RunMoonlightCellMutationResponse,
  RunMoonlightCellPathParams,
  RunMoonlightCell422,
} from '../types/RunMoonlightCell'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const runMoonlightCellMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cell/{cell_id}/run_moonlight_cell' }] as const

export type RunMoonlightCellMutationKey = ReturnType<typeof runMoonlightCellMutationKey>

/**
 * @description Run moonlight for an already created cell.
 * @summary Run Moonlight Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cell/:cell_id/run_moonlight_cell}
 */
async function runMoonlightCell(
  {
    accountName,
    conversationId,
    cellId,
    data,
  }: {
    accountName: RunMoonlightCellPathParams['account_name']
    conversationId: RunMoonlightCellPathParams['conversation_id']
    cellId: RunMoonlightCellPathParams['cell_id']
    data: RunMoonlightCellMutationRequest
  },
  config: Partial<RequestConfig<RunMoonlightCellMutationRequest>> = {},
) {
  const res = await client<RunMoonlightCellMutationResponse, RunMoonlightCell422, RunMoonlightCellMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cell/${cellId}/run_moonlight_cell`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Run moonlight for an already created cell.
 * @summary Run Moonlight Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cell/:cell_id/run_moonlight_cell}
 */
export function useRunMoonlightCell(
  options: {
    mutation?: UseMutationOptions<
      RunMoonlightCellMutationResponse,
      RunMoonlightCell422,
      {
        accountName: RunMoonlightCellPathParams['account_name']
        conversationId: RunMoonlightCellPathParams['conversation_id']
        cellId: RunMoonlightCellPathParams['cell_id']
        data: RunMoonlightCellMutationRequest
      }
    >
    client?: Partial<RequestConfig<RunMoonlightCellMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? runMoonlightCellMutationKey()

  return useMutation<
    RunMoonlightCellMutationResponse,
    RunMoonlightCell422,
    {
      accountName: RunMoonlightCellPathParams['account_name']
      conversationId: RunMoonlightCellPathParams['conversation_id']
      cellId: RunMoonlightCellPathParams['cell_id']
      data: RunMoonlightCellMutationRequest
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, data }) => {
      return runMoonlightCell({ accountName, conversationId, cellId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}