// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadCellData.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadCellDataMutationRequest,
  ReadCellDataMutationResponse,
  ReadCellDataPathParams,
  ReadCellDataQueryParams,
  ReadCellData422,
} from '../types/ReadCellData'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const readCellDataMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/data' }] as const

export type ReadCellDataMutationKey = ReturnType<typeof readCellDataMutationKey>

/**
 * @description Get Bumblebee Cell Data.
 * @summary Read Cell Data
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/data}
 */
async function readCellData(
  {
    accountName,
    conversationId,
    cellId,
    data,
    params,
  }: {
    accountName: ReadCellDataPathParams['account_name']
    conversationId: ReadCellDataPathParams['conversation_id']
    cellId: ReadCellDataPathParams['cell_id']
    data?: ReadCellDataMutationRequest
    params?: ReadCellDataQueryParams
  },
  config: Partial<RequestConfig<ReadCellDataMutationRequest>> = {},
) {
  const res = await client<ReadCellDataMutationResponse, ReadCellData422, ReadCellDataMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/data`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Get Bumblebee Cell Data.
 * @summary Read Cell Data
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/data}
 */
export function useReadCellData(
  options: {
    mutation?: UseMutationOptions<
      ReadCellDataMutationResponse,
      ReadCellData422,
      {
        accountName: ReadCellDataPathParams['account_name']
        conversationId: ReadCellDataPathParams['conversation_id']
        cellId: ReadCellDataPathParams['cell_id']
        data?: ReadCellDataMutationRequest
        params?: ReadCellDataQueryParams
      }
    >
    client?: Partial<RequestConfig<ReadCellDataMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? readCellDataMutationKey()

  return useMutation<
    ReadCellDataMutationResponse,
    ReadCellData422,
    {
      accountName: ReadCellDataPathParams['account_name']
      conversationId: ReadCellDataPathParams['conversation_id']
      cellId: ReadCellDataPathParams['cell_id']
      data?: ReadCellDataMutationRequest
      params?: ReadCellDataQueryParams
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, data, params }) => {
      return readCellData({ accountName, conversationId, cellId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}