// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/starSchemaDataSourceApiCreateSchema.ts"
);
import.meta.hot.lastModified = "1737935769440.7375";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetVisibilitySchema } from './dataAssetVisibilitySchema'
import { zenViewSchema } from './zenViewSchema'
import { z } from 'zod'

/**
 * @description Create StarSchemaDataSource Schema.
 */
export const starSchemaDataSourceApiCreateSchema = z
  .object({
    sync_id: z.union([z.string(), z.null()]).optional(),
    visibility: z.lazy(() => dataAssetVisibilitySchema).default('DRAFT'),
    name: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    num_data_source_created: z.number().int().default(0),
    zen_views: z.array(z.lazy(() => zenViewSchema)),
    connection_id: z.string(),
    is_demo: z.union([z.boolean(), z.null()]).default(false),
    del_col_values_index: z.boolean().default(false),
  })
  .describe('Create StarSchemaDataSource Schema.')