// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/rmAccountFromEnterpriseSchema.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

// @ts-nocheck
import { accountSchema } from './accountSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const rmAccountFromEnterprisePathParamsSchema = z.object({
  enterprise_name: z.string(),
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const rmAccountFromEnterprise200Schema = z.lazy(() => accountSchema)

/**
 * @description Validation Error
 */
export const rmAccountFromEnterprise422Schema = z.lazy(() => httpValidationErrorSchema)

export const rmAccountFromEnterpriseMutationResponseSchema = z.lazy(() => rmAccountFromEnterprise200Schema)