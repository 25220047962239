// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/PromptLogComponent.ts"
);
import.meta.hot.lastModified = "1737935754014.8223";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Component of BB being logged.
 */
export enum PromptLogComponent {
  QUERY = 'QUERY',
  MOONLIGHT = 'MOONLIGHT',
  REPHRASE = 'REPHRASE',
  ALL = 'ALL',
  MOONLIGHT_REPHRASE = 'MOONLIGHT_REPHRASE',
}
