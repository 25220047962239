// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/ui/atoms/Toast/useToast.ts"
);
import.meta.hot.lastModified = "1733439085428.0662";
}
// REMIX HMR END

// Inspired by react-hot-toast library
import * as React from 'react'
import { useCallback } from 'react'

import { type PickPartial } from '@/types'
import { errorToString, unknownToErrorContent } from '@/utils/errorHandling'

import { invariant } from '~/utils/invariant'

import { type ToastActionElement, type ToastProps } from '.'

const TOAST_LIMIT = 3
const TOAST_REMOVE_DELAY = 1000000

export type ToastVariant = 'success' | 'error' | 'warning' | 'info'

type ToasterToast = ToastProps & {
  id: string
  title?: React.ReactNode
  description?: React.ReactNode
  action?: ToastActionElement
  variant?: ToastVariant
  dismissible?: boolean
}

const actionTypes = {
  ADD_TOAST: 'ADD_TOAST',
  UPDATE_TOAST: 'UPDATE_TOAST',
  DISMISS_TOAST: 'DISMISS_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST',
} as const

let count = 0

function genId() {
  count = (count + 1) % Number.MAX_VALUE
  return count.toString()
}

type ActionType = typeof actionTypes

type Action =
  | {
      type: ActionType['ADD_TOAST']
      toast: ToasterToast
    }
  | {
      type: ActionType['UPDATE_TOAST']
      toast: Partial<ToasterToast>
    }
  | {
      type: ActionType['DISMISS_TOAST']
      toastId?: ToasterToast['id']
    }
  | {
      type: ActionType['REMOVE_TOAST']
      toastId?: ToasterToast['id']
    }

interface State {
  toasts: ToasterToast[]
}

const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>()

const addToRemoveQueue = (toastId: string) => {
  if (toastTimeouts.has(toastId)) {
    return
  }

  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId)
    dispatch({
      type: 'REMOVE_TOAST',
      toastId,
    })
  }, TOAST_REMOVE_DELAY)

  toastTimeouts.set(toastId, timeout)
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ADD_TOAST':
      return {
        ...state,
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT),
      }

    case 'UPDATE_TOAST':
      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === action.toast.id ? { ...t, ...action.toast } : t,
        ),
      }

    case 'DISMISS_TOAST': {
      const { toastId } = action

      // ! Side effects ! - This could be extracted into a dismissToast() action,
      // but I'll keep it here for simplicity
      if (toastId) {
        addToRemoveQueue(toastId)
      } else {
        state.toasts.forEach((tst) => {
          addToRemoveQueue(tst.id)
        })
      }

      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === toastId || toastId === undefined
            ? {
                ...t,
                open: false,
              }
            : t,
        ),
      }
    }
    case 'REMOVE_TOAST':
      if (action.toastId === undefined) {
        return {
          ...state,
          toasts: [],
        }
      }
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.toastId),
      }
    default:
      invariant(false, 'Invalid action type')
  }
}

const listeners: Array<(state: State) => void> = []

let memoryState: State = { toasts: [] }

function getActiveToasts() {
  return memoryState.toasts.filter((t) => t.open)
}

function dispatch(action: Action) {
  memoryState = reducer(memoryState, action)
  listeners.forEach((listener) => {
    listener(memoryState)
  })
}

function toast({
  dismissible = true,
  ...props
}: PickPartial<ToasterToast, 'id'>) {
  const id = props.id ?? genId()

  const update = (config: ToasterToast) =>
    dispatch({
      type: 'UPDATE_TOAST',
      toast: { ...config, id },
    })
  const dismiss = () => dispatch({ type: 'DISMISS_TOAST', toastId: id })

  dispatch({
    type: 'ADD_TOAST',
    toast: {
      ...props,
      dismissible,
      id,
      open: true,
      onOpenChange: (open: boolean) => {
        props.onOpenChange?.(open)
        if (!open && dismissible) dismiss()
      },
    },
  })

  return {
    id,
    dismiss,
    update,
  }
}

function useToast() {
  const [state, setState] = React.useState<State>(memoryState)

  React.useEffect(() => {
    listeners.push(setState)
    return () => {
      const index = listeners.indexOf(setState)
      if (index > -1) {
        listeners.splice(index, 1)
      }
    }
  }, [state])

  return {
    ...state,
    toast,
    dismiss: (toastId?: string) => dispatch({ type: 'DISMISS_TOAST', toastId }),
    /**
     * Allows the user to generate a new toast id instead of the hook.
     * This lets you have access to the new id within the context of the
     * toast() function's parameters eg:
     *
     * ```tsx
     * const { genId, toast } = useToast()
     * const newToastId = genId()
     * myRef.current = newToastId
     * toast({
     *   // !Make sure you provide the new id to the toast or it will generate its own
     *   id: newToastId,
     *   action: (
     *     <Toast.Action
     *       onClick={() => {
     *         console.log({`do something with ${myRef.current}`})
     *       }}>
     *         Do Something
     *     </Toast.Action>
     * })
     * ```
     */
    genId,
  }
}

/**
 * Takes an error of "unknown" type, uses `unknownToErrorContent` on it,
 * and then displays the result in an error variant toast message
 */
function useErrorContentToast() {
  const { toast: origToast } = useToast()
  const errorContentToast = useCallback(
    (e: unknown, title?: string) => {
      const errContent = unknownToErrorContent(e)
      const errString = errorToString(errContent)
      origToast({
        variant: 'error',
        title,
        description: errString,
      })
    },
    [origToast],
  )

  return errorContentToast
}

export { getActiveToasts, toast, useErrorContentToast, useToast }
