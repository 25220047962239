// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getSsdsDimensionsCountSchema.ts"
);
import.meta.hot.lastModified = "1737759761449.02";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { knowledgeOriginFilterSchema } from './knowledgeOriginFilterSchema'
import { knowledgeStateFilterSchema } from './knowledgeStateFilterSchema'
import { z } from 'zod'

export const getSsdsDimensionsCountPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const getSsdsDimensionsCountQueryParamsSchema = z
  .object({
    search: z.string().default(''),
    state: z.union([z.lazy(() => knowledgeStateFilterSchema), z.null()]).default('valid'),
    origin: z.union([z.lazy(() => knowledgeOriginFilterSchema), z.null()]).default('all'),
    data_source_ids: z.union([z.array(z.string()), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getSsdsDimensionsCount200Schema = z.number().int()

/**
 * @description Validation Error
 */
export const getSsdsDimensionsCount422Schema = z.lazy(() => httpValidationErrorSchema)

export const getSsdsDimensionsCountQueryResponseSchema = z.lazy(() => getSsdsDimensionsCount200Schema)