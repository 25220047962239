// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/m2MTokenCredsSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description To allow tasks to refresh this token themselves.
 */
export const m2MTokenCredsSchema = z
  .object({
    endpoint: z.string().url().min(1),
    client_id: z.string(),
    client_secret: z.string(),
  })
  .describe('To allow tasks to refresh this token themselves.')