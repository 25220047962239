// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readUserJwtTokenSchema.ts"
);
import.meta.hot.lastModified = "1737935767404.7488";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { jwtTokenSchema } from './jwtTokenSchema'
import { z } from 'zod'

/**
 * @description Successful Response
 */
export const readUserJwtToken200Schema = z.union([z.lazy(() => jwtTokenSchema), z.null()])

/**
 * @description Validation Error
 */
export const readUserJwtToken422Schema = z.lazy(() => httpValidationErrorSchema)

export const readUserJwtTokenQueryResponseSchema = z.lazy(() => readUserJwtToken200Schema)