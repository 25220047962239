// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteDimensions.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  DeleteDimensionsMutationRequest,
  DeleteDimensionsMutationResponse,
  DeleteDimensionsPathParams,
  DeleteDimensions422,
} from '../types/DeleteDimensions'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteDimensionsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions' }] as const

export type DeleteDimensionsMutationKey = ReturnType<typeof deleteDimensionsMutationKey>

/**
 * @description Remove dimensions from database.Note: This endpoint is for simple deletion of a dimension. It will not check for dimension usage andhandle the full flow for dimension deletion (invalidating examples and derived dms that use this dimension).Use with caution, delete only when you are sure the dimension is not needed.
 * @summary Delete Dimensions
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions}
 */
async function deleteDimensions(
  { accountName, data }: { accountName: DeleteDimensionsPathParams['account_name']; data?: DeleteDimensionsMutationRequest },
  config: Partial<RequestConfig<DeleteDimensionsMutationRequest>> = {},
) {
  const res = await client<DeleteDimensionsMutationResponse, DeleteDimensions422, DeleteDimensionsMutationRequest>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/dimensions`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Remove dimensions from database.Note: This endpoint is for simple deletion of a dimension. It will not check for dimension usage andhandle the full flow for dimension deletion (invalidating examples and derived dms that use this dimension).Use with caution, delete only when you are sure the dimension is not needed.
 * @summary Delete Dimensions
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/dimensions}
 */
export function useDeleteDimensions(
  options: {
    mutation?: UseMutationOptions<
      DeleteDimensionsMutationResponse,
      DeleteDimensions422,
      { accountName: DeleteDimensionsPathParams['account_name']; data?: DeleteDimensionsMutationRequest }
    >
    client?: Partial<RequestConfig<DeleteDimensionsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteDimensionsMutationKey()

  return useMutation<
    DeleteDimensionsMutationResponse,
    DeleteDimensions422,
    { accountName: DeleteDimensionsPathParams['account_name']; data?: DeleteDimensionsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return deleteDimensions({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}