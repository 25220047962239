// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/zenIdentifierRelationshipSchema.ts"
);
import.meta.hot.lastModified = "1737935769016.7397";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Possible ZenIdentifierRelationship types.\n\nhttps://docs.zenlytic.com/docs/data_modeling/join
 */
export const zenIdentifierRelationshipSchema = z
  .enum(['one_to_one', 'many_to_one', 'one_to_many', 'many_to_many'])
  .describe('Possible ZenIdentifierRelationship types.\n\nhttps://docs.zenlytic.com/docs/data_modeling/join')