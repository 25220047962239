// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetCellMetricOptions.ts"
);
import.meta.hot.lastModified = "1737759760889.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetCellMetricOptionsQueryResponse,
  GetCellMetricOptionsPathParams,
  GetCellMetricOptionsQueryParams,
  GetCellMetricOptions422,
} from '../types/GetCellMetricOptions'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCellMetricOptionsQueryKey = (
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetCellMetricOptionsPathParams['account_name']
    conversationId: GetCellMetricOptionsPathParams['conversation_id']
    cellId: GetCellMetricOptionsPathParams['cell_id']
  },
  params?: GetCellMetricOptionsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/metric_options',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetCellMetricOptionsQueryKey = ReturnType<typeof getCellMetricOptionsQueryKey>

/**
 * @description Get cell metric options.DEPRECATED. Use get_ssds_metrics instead.This represents the available metrics for the cell that could be manually added.
 * @summary Get Cell Metric Options
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/metric_options}
 * @deprecated
 */
async function getCellMetricOptions(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: GetCellMetricOptionsPathParams['account_name']
    conversationId: GetCellMetricOptionsPathParams['conversation_id']
    cellId: GetCellMetricOptionsPathParams['cell_id']
    params?: GetCellMetricOptionsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetCellMetricOptionsQueryResponse, GetCellMetricOptions422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/metric_options`,
    params,
    ...config,
  })
  return res.data
}

export function getCellMetricOptionsQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: GetCellMetricOptionsPathParams['account_name']
    conversationId: GetCellMetricOptionsPathParams['conversation_id']
    cellId: GetCellMetricOptionsPathParams['cell_id']
    params?: GetCellMetricOptionsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getCellMetricOptionsQueryKey({ accountName, conversationId, cellId }, params)
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCellMetricOptions({ accountName, conversationId, cellId, params }, config)
    },
  })
}

/**
 * @description Get cell metric options.DEPRECATED. Use get_ssds_metrics instead.This represents the available metrics for the cell that could be manually added.
 * @summary Get Cell Metric Options
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/metric_options}
 * @deprecated
 */
export function useGetCellMetricOptions<
  TData = GetCellMetricOptionsQueryResponse,
  TQueryData = GetCellMetricOptionsQueryResponse,
  TQueryKey extends QueryKey = GetCellMetricOptionsQueryKey,
>(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: GetCellMetricOptionsPathParams['account_name']
    conversationId: GetCellMetricOptionsPathParams['conversation_id']
    cellId: GetCellMetricOptionsPathParams['cell_id']
    params?: GetCellMetricOptionsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetCellMetricOptionsQueryResponse, GetCellMetricOptions422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCellMetricOptionsQueryKey({ accountName, conversationId, cellId }, params)

  const query = useQuery({
    ...(getCellMetricOptionsQueryOptions({ accountName, conversationId, cellId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetCellMetricOptions422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}