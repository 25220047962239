// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/coldStartFromTablesSchema.ts"
);
import.meta.hot.lastModified = "1737759760888.023";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceColdStartSchema } from './starSchemaDataSourceColdStartSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const coldStartFromTablesPathParamsSchema = z.object({
  account_name: z.string(),
})

export const coldStartFromTablesQueryParamsSchema = z.object({
  ssds_id: z.union([z.string(), z.null()]).optional(),
  connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const coldStartFromTables200Schema = z.lazy(() => taskSchema)

/**
 * @description Validation Error
 */
export const coldStartFromTables422Schema = z.lazy(() => httpValidationErrorSchema)

export const coldStartFromTablesMutationRequestSchema = z.lazy(() => starSchemaDataSourceColdStartSchema)

export const coldStartFromTablesMutationResponseSchema = z.lazy(() => coldStartFromTables200Schema)