// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateStarSchemaDataSourceVisibility.ts"
);
import.meta.hot.lastModified = "1737759762548.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateStarSchemaDataSourceVisibilityMutationResponse,
  UpdateStarSchemaDataSourceVisibilityPathParams,
  UpdateStarSchemaDataSourceVisibilityQueryParams,
  UpdateStarSchemaDataSourceVisibility422,
} from '../types/UpdateStarSchemaDataSourceVisibility'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateStarSchemaDataSourceVisibilityMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/visibility' }] as const

export type UpdateStarSchemaDataSourceVisibilityMutationKey = ReturnType<typeof updateStarSchemaDataSourceVisibilityMutationKey>

/**
 * @description Update Star Schema Data Source visibility.
 * @summary Update Star Schema Data Source Visibility
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/visibility}
 */
async function updateStarSchemaDataSourceVisibility(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: UpdateStarSchemaDataSourceVisibilityPathParams['account_name']
    starSchemaDataSourceId: UpdateStarSchemaDataSourceVisibilityPathParams['star_schema_data_source_id']
    params: UpdateStarSchemaDataSourceVisibilityQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<UpdateStarSchemaDataSourceVisibilityMutationResponse, UpdateStarSchemaDataSourceVisibility422, unknown>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/visibility`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Update Star Schema Data Source visibility.
 * @summary Update Star Schema Data Source Visibility
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/visibility}
 */
export function useUpdateStarSchemaDataSourceVisibility(
  options: {
    mutation?: UseMutationOptions<
      UpdateStarSchemaDataSourceVisibilityMutationResponse,
      UpdateStarSchemaDataSourceVisibility422,
      {
        accountName: UpdateStarSchemaDataSourceVisibilityPathParams['account_name']
        starSchemaDataSourceId: UpdateStarSchemaDataSourceVisibilityPathParams['star_schema_data_source_id']
        params: UpdateStarSchemaDataSourceVisibilityQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateStarSchemaDataSourceVisibilityMutationKey()

  return useMutation<
    UpdateStarSchemaDataSourceVisibilityMutationResponse,
    UpdateStarSchemaDataSourceVisibility422,
    {
      accountName: UpdateStarSchemaDataSourceVisibilityPathParams['account_name']
      starSchemaDataSourceId: UpdateStarSchemaDataSourceVisibilityPathParams['star_schema_data_source_id']
      params: UpdateStarSchemaDataSourceVisibilityQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params }) => {
      return updateStarSchemaDataSourceVisibility({ accountName, starSchemaDataSourceId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}