// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/removeAccountAllowedDomainSchema.ts"
);
import.meta.hot.lastModified = "1737759762054.0168";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const removeAccountAllowedDomainPathParamsSchema = z.object({
  domain_id: z.string(),
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const removeAccountAllowedDomain204Schema = z.unknown()

/**
 * @description Validation Error
 */
export const removeAccountAllowedDomain422Schema = z.lazy(() => httpValidationErrorSchema)

export const removeAccountAllowedDomainMutationResponseSchema = z.lazy(() => removeAccountAllowedDomain204Schema)