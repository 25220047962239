// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getNamedFilterValidOperatorsSchema.ts"
);
import.meta.hot.lastModified = "1737759762296.0156";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { namedFilterOptionConditionValidOperatorsWithGroupSchema } from './namedFilterOptionConditionValidOperatorsWithGroupSchema'
import { z } from 'zod'

export const getNamedFilterValidOperatorsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const getNamedFilterValidOperators200Schema = z.array(z.lazy(() => namedFilterOptionConditionValidOperatorsWithGroupSchema))

/**
 * @description Validation Error
 */
export const getNamedFilterValidOperators422Schema = z.lazy(() => httpValidationErrorSchema)

export const getNamedFilterValidOperatorsQueryResponseSchema = z.lazy(() => getNamedFilterValidOperators200Schema)