// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tableauConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759760436.0251";
}
// REMIX HMR END

// @ts-nocheck
import { connectionStatusSchema } from './connectionStatusSchema'
import { z } from 'zod'

/**
 * @description Additional properties to return via API.\n\nWe do not want to return the password.
 */
export const tableauConnectionSchema = z
  .object({
    name: z.string(),
    account_id: z.string(),
    status: z.union([z.lazy(() => connectionStatusSchema), z.null()]).optional(),
    status_at: z.union([z.string().datetime(), z.null()]).optional(),
    user_name: z.string(),
    secret_id: z.string(),
    client_id: z.string(),
    site_id: z.string(),
    server_address: z.string().url().min(1).max(2083),
    id: z.string(),
  })
  .describe('Additional properties to return via API.\n\nWe do not want to return the password.')