// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useWriteCsvToConnection.ts"
);
import.meta.hot.lastModified = "1737759760889.023";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  WriteCsvToConnectionMutationRequest,
  WriteCsvToConnectionMutationResponse,
  WriteCsvToConnectionPathParams,
  WriteCsvToConnection422,
} from '../types/WriteCsvToConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const writeCsvToConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/csv/' }] as const

export type WriteCsvToConnectionMutationKey = ReturnType<typeof writeCsvToConnectionMutationKey>

/**
 * @description Upload CSV to the warehouse creating a table.If no connection_id is provided, a demo connection will be used/created.We use File and Form data together since we cannot declare Body fields we want toaccept as JSON to do the HTTP protocol specification.Ref: https://fastapi.tiangolo.com/tutorial/request-forms-and-files/
 * @summary Write Csv To Connection
 * {@link /api/v1/accounts/:account_name/connections/csv/}
 */
async function writeCsvToConnection(
  { accountName, data }: { accountName: WriteCsvToConnectionPathParams['account_name']; data: WriteCsvToConnectionMutationRequest },
  config: Partial<RequestConfig<WriteCsvToConnectionMutationRequest>> = {},
) {
  const formData = new FormData()
  if (data) {
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof typeof data]
      if (typeof key === 'string' && (typeof value === 'string' || value instanceof Blob)) {
        formData.append(key, value)
      }
    })
  }
  const res = await client<WriteCsvToConnectionMutationResponse, WriteCsvToConnection422, WriteCsvToConnectionMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/csv/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data', ...config.headers },
    ...config,
  })
  return res.data
}

/**
 * @description Upload CSV to the warehouse creating a table.If no connection_id is provided, a demo connection will be used/created.We use File and Form data together since we cannot declare Body fields we want toaccept as JSON to do the HTTP protocol specification.Ref: https://fastapi.tiangolo.com/tutorial/request-forms-and-files/
 * @summary Write Csv To Connection
 * {@link /api/v1/accounts/:account_name/connections/csv/}
 */
export function useWriteCsvToConnection(
  options: {
    mutation?: UseMutationOptions<
      WriteCsvToConnectionMutationResponse,
      WriteCsvToConnection422,
      { accountName: WriteCsvToConnectionPathParams['account_name']; data: WriteCsvToConnectionMutationRequest }
    >
    client?: Partial<RequestConfig<WriteCsvToConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? writeCsvToConnectionMutationKey()

  return useMutation<
    WriteCsvToConnectionMutationResponse,
    WriteCsvToConnection422,
    { accountName: WriteCsvToConnectionPathParams['account_name']; data: WriteCsvToConnectionMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return writeCsvToConnection({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}