// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/moonlightRunParamsSchema.ts"
);
import.meta.hot.lastModified = "1737935767878.746";
}
// REMIX HMR END

// @ts-nocheck
import { bumblebeeCellRunParamsSchema } from './bumblebeeCellRunParamsSchema'
import { retrievalParamsSchema } from './retrievalParamsSchema'
import { z } from 'zod'

/**
 * @description Moonlight Run parameters args.
 */
export const moonlightRunParamsSchema = z
  .object({
    skip_rephrasing: z.boolean().default(false),
    skip_routing: z.boolean().default(false),
    skip_bumblebee: z.boolean().default(false),
    bb_run_params: z.lazy(() => bumblebeeCellRunParamsSchema).default({}),
    skip_retrieval: z.boolean().default(false),
    retrieval_params: z.lazy(() => retrievalParamsSchema).default({}),
    overwrite_cache: z.boolean().default(false),
    use_reference_rephrased: z.boolean().default(false),
  })
  .describe('Moonlight Run parameters args.')