// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/apiKeyCreateResponseSchema.ts"
);
import.meta.hot.lastModified = "1737759761027.0222";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Properties to return after creating an API Key.
 */
export const apiKeyCreateResponseSchema = z
  .object({
    name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    id: z.string(),
    account_id: z.string(),
    creator_id: z.string(),
    key: z.string(),
  })
  .describe('Properties to return after creating an API Key.')