// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetNamedFilterValidOperators.ts"
);
import.meta.hot.lastModified = "1737759762294.0156";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetNamedFilterValidOperatorsQueryResponse,
  GetNamedFilterValidOperatorsPathParams,
  GetNamedFilterValidOperators422,
} from '../types/GetNamedFilterValidOperators'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getNamedFilterValidOperatorsQueryKey = ({
  accountName,
  starSchemaDataSourceId,
}: {
  accountName: GetNamedFilterValidOperatorsPathParams['account_name']
  starSchemaDataSourceId: GetNamedFilterValidOperatorsPathParams['star_schema_data_source_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filter_options',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
  ] as const

export type GetNamedFilterValidOperatorsQueryKey = ReturnType<typeof getNamedFilterValidOperatorsQueryKey>

/**
 * @description Get named filter options for SSDS Dimensions.This is analogous to BB Cell's filter options.
 * @summary Get Named Filter Valid Operators
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filter_options}
 */
async function getNamedFilterValidOperators(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetNamedFilterValidOperatorsPathParams['account_name']
    starSchemaDataSourceId: GetNamedFilterValidOperatorsPathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetNamedFilterValidOperatorsQueryResponse, GetNamedFilterValidOperators422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/filter_options`,
    ...config,
  })
  return res.data
}

export function getNamedFilterValidOperatorsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetNamedFilterValidOperatorsPathParams['account_name']
    starSchemaDataSourceId: GetNamedFilterValidOperatorsPathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getNamedFilterValidOperatorsQueryKey({ accountName, starSchemaDataSourceId })
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getNamedFilterValidOperators({ accountName, starSchemaDataSourceId }, config)
    },
  })
}

/**
 * @description Get named filter options for SSDS Dimensions.This is analogous to BB Cell's filter options.
 * @summary Get Named Filter Valid Operators
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filter_options}
 */
export function useGetNamedFilterValidOperators<
  TData = GetNamedFilterValidOperatorsQueryResponse,
  TQueryData = GetNamedFilterValidOperatorsQueryResponse,
  TQueryKey extends QueryKey = GetNamedFilterValidOperatorsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetNamedFilterValidOperatorsPathParams['account_name']
    starSchemaDataSourceId: GetNamedFilterValidOperatorsPathParams['star_schema_data_source_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<GetNamedFilterValidOperatorsQueryResponse, GetNamedFilterValidOperators422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getNamedFilterValidOperatorsQueryKey({ accountName, starSchemaDataSourceId })

  const query = useQuery({
    ...(getNamedFilterValidOperatorsQueryOptions({ accountName, starSchemaDataSourceId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetNamedFilterValidOperators422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}