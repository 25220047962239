// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeletePostgresConnection.ts"
);
import.meta.hot.lastModified = "1737935768806.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  DeletePostgresConnectionMutationResponse,
  DeletePostgresConnectionPathParams,
  DeletePostgresConnection422,
} from '../types/DeletePostgresConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deletePostgresConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}' }] as const

export type DeletePostgresConnectionMutationKey = ReturnType<typeof deletePostgresConnectionMutationKey>

/**
 * @description Delete Postgres connection.
 * @summary Delete Postgres Connection
 * {@link /api/v1/accounts/:account_name/connections/:postgres_connection_id}
 */
async function deletePostgresConnection(
  {
    accountName,
    postgresConnectionId,
  }: { accountName: DeletePostgresConnectionPathParams['account_name']; postgresConnectionId: DeletePostgresConnectionPathParams['postgres_connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeletePostgresConnectionMutationResponse, DeletePostgresConnection422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/connections/${postgresConnectionId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete Postgres connection.
 * @summary Delete Postgres Connection
 * {@link /api/v1/accounts/:account_name/connections/:postgres_connection_id}
 */
export function useDeletePostgresConnection(
  options: {
    mutation?: UseMutationOptions<
      DeletePostgresConnectionMutationResponse,
      DeletePostgresConnection422,
      { accountName: DeletePostgresConnectionPathParams['account_name']; postgresConnectionId: DeletePostgresConnectionPathParams['postgres_connection_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deletePostgresConnectionMutationKey()

  return useMutation<
    DeletePostgresConnectionMutationResponse,
    DeletePostgresConnection422,
    { accountName: DeletePostgresConnectionPathParams['account_name']; postgresConnectionId: DeletePostgresConnectionPathParams['postgres_connection_id'] }
  >({
    mutationFn: async ({ accountName, postgresConnectionId }) => {
      return deletePostgresConnection({ accountName, postgresConnectionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}