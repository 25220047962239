// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateMetrics.ts"
);
import.meta.hot.lastModified = "1737759758962.0327";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateMetricsMutationRequest, UpdateMetricsMutationResponse, UpdateMetricsPathParams, UpdateMetrics422 } from '../types/UpdateMetrics'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateMetricsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metrics' }] as const

export type UpdateMetricsMutationKey = ReturnType<typeof updateMetricsMutationKey>

/**
 * @description Bulk update multiple metrics at once.
 * @summary Update Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics}
 */
async function updateMetrics(
  { accountName, data }: { accountName: UpdateMetricsPathParams['account_name']; data?: UpdateMetricsMutationRequest },
  config: Partial<RequestConfig<UpdateMetricsMutationRequest>> = {},
) {
  const res = await client<UpdateMetricsMutationResponse, UpdateMetrics422, UpdateMetricsMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metrics`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Bulk update multiple metrics at once.
 * @summary Update Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metrics}
 */
export function useUpdateMetrics(
  options: {
    mutation?: UseMutationOptions<
      UpdateMetricsMutationResponse,
      UpdateMetrics422,
      { accountName: UpdateMetricsPathParams['account_name']; data?: UpdateMetricsMutationRequest }
    >
    client?: Partial<RequestConfig<UpdateMetricsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMetricsMutationKey()

  return useMutation<
    UpdateMetricsMutationResponse,
    UpdateMetrics422,
    { accountName: UpdateMetricsPathParams['account_name']; data?: UpdateMetricsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return updateMetrics({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}