// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadCells.ts"
);
import.meta.hot.lastModified = "1737759757951.038";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadCellsQueryResponse, ReadCellsPathParams, ReadCellsQueryParams, ReadCells422 } from '../types/ReadCells'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readCellsQueryKey = (
  { accountName, conversationId }: { accountName: ReadCellsPathParams['account_name']; conversationId: ReadCellsPathParams['conversation_id'] },
  params?: ReadCellsQueryParams,
) =>
  [
    { url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/', params: { accountName: accountName, conversationId: conversationId } },
    ...(params ? [params] : []),
  ] as const

export type ReadCellsQueryKey = ReturnType<typeof readCellsQueryKey>

/**
 * @description Read Bumblebee Cells for a conversation.
 * @summary Read Cells
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/}
 */
async function readCells(
  {
    accountName,
    conversationId,
    params,
  }: { accountName: ReadCellsPathParams['account_name']; conversationId: ReadCellsPathParams['conversation_id']; params?: ReadCellsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadCellsQueryResponse, ReadCells422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/`,
    params,
    ...config,
  })
  return res.data
}

export function readCellsQueryOptions(
  {
    accountName,
    conversationId,
    params,
  }: { accountName: ReadCellsPathParams['account_name']; conversationId: ReadCellsPathParams['conversation_id']; params?: ReadCellsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readCellsQueryKey({ accountName, conversationId }, params)
  return queryOptions({
    enabled: !!(accountName && conversationId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readCells({ accountName, conversationId, params }, config)
    },
  })
}

/**
 * @description Read Bumblebee Cells for a conversation.
 * @summary Read Cells
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/}
 */
export function useReadCells<TData = ReadCellsQueryResponse, TQueryData = ReadCellsQueryResponse, TQueryKey extends QueryKey = ReadCellsQueryKey>(
  {
    accountName,
    conversationId,
    params,
  }: { accountName: ReadCellsPathParams['account_name']; conversationId: ReadCellsPathParams['conversation_id']; params?: ReadCellsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadCellsQueryResponse, ReadCells422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readCellsQueryKey({ accountName, conversationId }, params)

  const query = useQuery({
    ...(readCellsQueryOptions({ accountName, conversationId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadCells422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}