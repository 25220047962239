// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/StarSchemaDataSourceSort.ts"
);
import.meta.hot.lastModified = "1737935768223.7441";
}
// REMIX HMR END

export enum StarSchemaDataSourceSort {
  'created_at' = 'created_at',
  'updated_at' = 'updated_at',
  'name' = 'name',
}