// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dataSourceProfileInfoSchema.ts"
);
import.meta.hot.lastModified = "1737935768445.743";
}
// REMIX HMR END

// @ts-nocheck
import { tableSchema } from './tableSchema'
import { z } from 'zod'

/**
 * @description Profiling information returned by data source profile API.
 */
export const dataSourceProfileInfoSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    table_info: z.lazy(() => tableSchema),
    num_of_rows: z.number().int(),
  })
  .describe('Profiling information returned by data source profile API.')