// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/evalResultBaseSchema.ts"
);
import.meta.hot.lastModified = "1737759759420.0305";
}
// REMIX HMR END

// @ts-nocheck
import { evalFilterSchema } from './evalFilterSchema'
import { z } from 'zod'

/**
 * @description Base EvalResult Schema.
 */
export const evalResultBaseSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    account_id: z.string(),
    creator_id: z.string(),
    ssds_id: z.string(),
    task_id: z.string(),
    eval_filter: z.lazy(() => evalFilterSchema),
    num_conversations: z.number().int(),
    num_cells: z.number().int(),
    model_name: z.string(),
    run_params: z.object({}),
    eval_score: z.object({}),
    created_at: z.union([z.string().datetime(), z.null()]).optional(),
    updated_at: z.union([z.string().datetime(), z.null()]).optional(),
  })
  .describe('Base EvalResult Schema.')