// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateMetricsState.ts"
);
import.meta.hot.lastModified = "1737935767653.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateMetricsStateMutationRequest,
  UpdateMetricsStateMutationResponse,
  UpdateMetricsStatePathParams,
  UpdateMetricsStateQueryParams,
  UpdateMetricsState422,
} from '../types/UpdateMetricsState'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateMetricsStateMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/metric_states' }] as const

export type UpdateMetricsStateMutationKey = ReturnType<typeof updateMetricsStateMutationKey>

/**
 * @description Bulk update multiple metric's state.
 * @summary Update Metrics State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metric_states}
 */
async function updateMetricsState(
  {
    accountName,
    data,
    params,
  }: { accountName: UpdateMetricsStatePathParams['account_name']; data?: UpdateMetricsStateMutationRequest; params?: UpdateMetricsStateQueryParams },
  config: Partial<RequestConfig<UpdateMetricsStateMutationRequest>> = {},
) {
  const res = await client<UpdateMetricsStateMutationResponse, UpdateMetricsState422, UpdateMetricsStateMutationRequest>({
    method: 'PATCH',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/metric_states`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Bulk update multiple metric's state.
 * @summary Update Metrics State
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/metric_states}
 */
export function useUpdateMetricsState(
  options: {
    mutation?: UseMutationOptions<
      UpdateMetricsStateMutationResponse,
      UpdateMetricsState422,
      { accountName: UpdateMetricsStatePathParams['account_name']; data?: UpdateMetricsStateMutationRequest; params?: UpdateMetricsStateQueryParams }
    >
    client?: Partial<RequestConfig<UpdateMetricsStateMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMetricsStateMutationKey()

  return useMutation<
    UpdateMetricsStateMutationResponse,
    UpdateMetricsState422,
    { accountName: UpdateMetricsStatePathParams['account_name']; data?: UpdateMetricsStateMutationRequest; params?: UpdateMetricsStateQueryParams }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return updateMetricsState({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}