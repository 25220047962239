// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ReportState.ts"
);
import.meta.hot.lastModified = "1737935764870.7627";
}
// REMIX HMR END

export enum ReportState {
  'synced' = 'synced',
  'out_of_sync' = 'out_of_sync',
  'not_reachable' = 'not_reachable',
}