// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetStarSchemaDataSourceConversations.ts"
);
import.meta.hot.lastModified = "1737935769669.7363";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetStarSchemaDataSourceConversationsQueryResponse,
  GetStarSchemaDataSourceConversationsPathParams,
  GetStarSchemaDataSourceConversationsQueryParams,
  GetStarSchemaDataSourceConversations422,
} from '../types/GetStarSchemaDataSourceConversations'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getStarSchemaDataSourceConversationsQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetStarSchemaDataSourceConversationsPathParams['account_name']
    starSchemaDataSourceId: GetStarSchemaDataSourceConversationsPathParams['star_schema_data_source_id']
  },
  params?: GetStarSchemaDataSourceConversationsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/conversations',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetStarSchemaDataSourceConversationsQueryKey = ReturnType<typeof getStarSchemaDataSourceConversationsQueryKey>

/**
 * @description Read Star Schema Data Source Conversations.If show_moonlight is True, show only moonlight conversationsIf show_moonlight is False, show only non-moonlight conversationsIf show_moonlight is None, show everything.If show_training is True, show only is_training conversationsIf show_training is False, show only non-is_training conversationsIf show_training is None, show everything.If show_eval is True, show only eval conversationsIf show_eval is False, show only non-eval conversationsIf show_eval is None, show everything.
 * @summary Get Star Schema Data Source Conversations
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/conversations}
 */
async function getStarSchemaDataSourceConversations(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetStarSchemaDataSourceConversationsPathParams['account_name']
    starSchemaDataSourceId: GetStarSchemaDataSourceConversationsPathParams['star_schema_data_source_id']
    params?: GetStarSchemaDataSourceConversationsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetStarSchemaDataSourceConversationsQueryResponse, GetStarSchemaDataSourceConversations422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/conversations`,
    params,
    ...config,
  })
  return res.data
}

export function getStarSchemaDataSourceConversationsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetStarSchemaDataSourceConversationsPathParams['account_name']
    starSchemaDataSourceId: GetStarSchemaDataSourceConversationsPathParams['star_schema_data_source_id']
    params?: GetStarSchemaDataSourceConversationsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getStarSchemaDataSourceConversationsQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getStarSchemaDataSourceConversations({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Read Star Schema Data Source Conversations.If show_moonlight is True, show only moonlight conversationsIf show_moonlight is False, show only non-moonlight conversationsIf show_moonlight is None, show everything.If show_training is True, show only is_training conversationsIf show_training is False, show only non-is_training conversationsIf show_training is None, show everything.If show_eval is True, show only eval conversationsIf show_eval is False, show only non-eval conversationsIf show_eval is None, show everything.
 * @summary Get Star Schema Data Source Conversations
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/conversations}
 */
export function useGetStarSchemaDataSourceConversations<
  TData = GetStarSchemaDataSourceConversationsQueryResponse,
  TQueryData = GetStarSchemaDataSourceConversationsQueryResponse,
  TQueryKey extends QueryKey = GetStarSchemaDataSourceConversationsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetStarSchemaDataSourceConversationsPathParams['account_name']
    starSchemaDataSourceId: GetStarSchemaDataSourceConversationsPathParams['star_schema_data_source_id']
    params?: GetStarSchemaDataSourceConversationsQueryParams
  },
  options: {
    query?: Partial<
      QueryObserverOptions<GetStarSchemaDataSourceConversationsQueryResponse, GetStarSchemaDataSourceConversations422, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getStarSchemaDataSourceConversationsQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getStarSchemaDataSourceConversationsQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetStarSchemaDataSourceConversations422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}