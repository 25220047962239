// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ZenMetric.ts"
);
import.meta.hot.lastModified = "1737935764663.7637";
}
// REMIX HMR END

import type { Dialect } from './Dialect'
import type { DisplayType } from './DisplayType'
import type { FilterCategory } from './FilterCategory'
import type { OriginType } from './OriginType'
import type { PublishState } from './PublishState'
import type { TimeGranularity } from './TimeGranularity'
import type { ZenDimension } from './ZenDimension'
import type { ZenFieldFilter } from './ZenFieldFilter'

export enum ZenMetricFieldType {
  'measure' = 'measure',
}

/**
 * @description Metrics layer metric.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/measure
 */
export type ZenMetric = {
  id?: string | null
  sync_id?: string | null
  group_id?: string | null
  /**
   * @type string
   */
  name: string
  /**
   * @default "measure"
   * @type string | undefined
   */
  field_type?: ZenMetricFieldType
  description?: string | null
  /**
   * @type string
   */
  sql: string
  ns_expr?: string | null
  measure?: string | null
  filters?: ZenFieldFilter[] | null
  canon_date?: ZenDimension | null
  window_size?: number | null
  window_granularity?: TimeGranularity | null
  display_name?: string | null
  /**
   * @default "unknown"
   */
  expr_type?: FilterCategory
  /**
   * @default "Verified"
   */
  state?: PublishState
  /**
   * @default "unknown"
   */
  display_type?: DisplayType
  data_source_id?: string | null
  /**
   * @default false
   * @type boolean | undefined
   */
  is_sensitive?: boolean
  /**
   * @default false
   * @type boolean | undefined
   */
  is_literal_sensitive?: boolean
  /**
   * @type array | undefined
   */
  origin?: OriginType[]
  /**
   * @default "unknown"
   */
  dialect?: Dialect
  /**
   * @default 1
   * @type number | undefined
   */
  popularity?: number
  updated_at?: string | null
  created_at?: string | null
}