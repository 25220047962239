// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadAllDatasets.ts"
);
import.meta.hot.lastModified = "1737759759784.0286";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadAllDatasetsQueryResponse, ReadAllDatasetsPathParams, ReadAllDatasetsQueryParams, ReadAllDatasets422 } from '../types/ReadAllDatasets'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readAllDatasetsQueryKey = ({ accountName }: { accountName: ReadAllDatasetsPathParams['account_name'] }, params?: ReadAllDatasetsQueryParams) =>
  [{ url: '/api/v1/accounts/:account_name/datasets/', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type ReadAllDatasetsQueryKey = ReturnType<typeof readAllDatasetsQueryKey>

/**
 * @description Read all datasets.This includes both star schema data sources and reports.
 * @summary Read All Datasets
 * {@link /api/v1/accounts/:account_name/datasets/}
 */
async function readAllDatasets(
  { accountName, params }: { accountName: ReadAllDatasetsPathParams['account_name']; params?: ReadAllDatasetsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadAllDatasetsQueryResponse, ReadAllDatasets422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/datasets/`,
    params,
    ...config,
  })
  return res.data
}

export function readAllDatasetsQueryOptions(
  { accountName, params }: { accountName: ReadAllDatasetsPathParams['account_name']; params?: ReadAllDatasetsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readAllDatasetsQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readAllDatasets({ accountName, params }, config)
    },
  })
}

/**
 * @description Read all datasets.This includes both star schema data sources and reports.
 * @summary Read All Datasets
 * {@link /api/v1/accounts/:account_name/datasets/}
 */
export function useReadAllDatasets<
  TData = ReadAllDatasetsQueryResponse,
  TQueryData = ReadAllDatasetsQueryResponse,
  TQueryKey extends QueryKey = ReadAllDatasetsQueryKey,
>(
  { accountName, params }: { accountName: ReadAllDatasetsPathParams['account_name']; params?: ReadAllDatasetsQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<ReadAllDatasetsQueryResponse, ReadAllDatasets422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readAllDatasetsQueryKey({ accountName }, params)

  const query = useQuery({
    ...(readAllDatasetsQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadAllDatasets422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}