// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/starSchemaDataSourceSearchAgentMetadataSchema.ts"
);
import.meta.hot.lastModified = "1737759762550.0142";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description The metadata we use about SSDSs in the agent search code.
 */
export const starSchemaDataSourceSearchAgentMetadataSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
  })
  .describe('The metadata we use about SSDSs in the agent search code.')