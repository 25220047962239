// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateReportVisibilitySchema.ts"
);
import.meta.hot.lastModified = "1737759761449.02";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetVisibilitySchema } from './dataAssetVisibilitySchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { reportSchema } from './reportSchema'
import { z } from 'zod'

export const updateReportVisibilityPathParamsSchema = z.object({
  account_name: z.string(),
  report_id: z.string(),
})

export const updateReportVisibilityQueryParamsSchema = z.object({
  visibility: z.lazy(() => dataAssetVisibilitySchema),
})

/**
 * @description Successful Response
 */
export const updateReportVisibility200Schema = z.lazy(() => reportSchema)

/**
 * @description Validation Error
 */
export const updateReportVisibility422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateReportVisibilityMutationResponseSchema = z.lazy(() => updateReportVisibility200Schema)