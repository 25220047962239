// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateMetricsSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { metricDetailSchema } from './metricDetailSchema'
import { metricUpdateWithIdSchema } from './metricUpdateWithIdSchema'
import { z } from 'zod'

export const updateMetricsPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const updateMetrics200Schema = z.array(z.lazy(() => metricDetailSchema))

/**
 * @description Validation Error
 */
export const updateMetrics422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateMetricsMutationRequestSchema = z.array(z.lazy(() => metricUpdateWithIdSchema))

export const updateMetricsMutationResponseSchema = z.lazy(() => updateMetrics200Schema)