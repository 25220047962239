// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/sendMessageToConversationSchema.ts"
);
import.meta.hot.lastModified = "1737935769018.7397";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { messageApiSendResponseSchema } from './messageApiSendResponseSchema'
import { messageApiSendSchema } from './messageApiSendSchema'
import { z } from 'zod'

export const sendMessageToConversationPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  conversation_id: z.string(),
})

/**
 * @description Successful Response
 */
export const sendMessageToConversation201Schema = z.lazy(() => messageApiSendResponseSchema)

/**
 * @description Validation Error
 */
export const sendMessageToConversation422Schema = z.lazy(() => httpValidationErrorSchema)

export const sendMessageToConversationMutationRequestSchema = z.lazy(() => messageApiSendSchema)

export const sendMessageToConversationMutationResponseSchema = z.lazy(() => sendMessageToConversation201Schema)