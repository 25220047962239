// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useAddDataSources.ts"
);
import.meta.hot.lastModified = "1737759759414.0305";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { AddDataSourcesMutationRequest, AddDataSourcesMutationResponse, AddDataSourcesPathParams, AddDataSources422 } from '../types/AddDataSources'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const addDataSourcesMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/add_data_sources' }] as const

export type AddDataSourcesMutationKey = ReturnType<typeof addDataSourcesMutationKey>

/**
 * @description Add new data sources to the Star Schema Data Source.This endpoint allows users to add data sources to the existing star schema data schema.It requires the input of a list of StarSchemaDataSourceTableOrViewInfo without data source id.The endpoint will scan the StarSchemaDataSourceTableOrViewInfo and perform cold start for thenew data sources and do enrich_ssds with related dimensions and metrics.
 * @summary Add Data Sources
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/add_data_sources}
 */
async function addDataSources(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: AddDataSourcesPathParams['account_name']
    starSchemaDataSourceId: AddDataSourcesPathParams['star_schema_data_source_id']
    data?: AddDataSourcesMutationRequest
  },
  config: Partial<RequestConfig<AddDataSourcesMutationRequest>> = {},
) {
  const res = await client<AddDataSourcesMutationResponse, AddDataSources422, AddDataSourcesMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/add_data_sources`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Add new data sources to the Star Schema Data Source.This endpoint allows users to add data sources to the existing star schema data schema.It requires the input of a list of StarSchemaDataSourceTableOrViewInfo without data source id.The endpoint will scan the StarSchemaDataSourceTableOrViewInfo and perform cold start for thenew data sources and do enrich_ssds with related dimensions and metrics.
 * @summary Add Data Sources
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/add_data_sources}
 */
export function useAddDataSources(
  options: {
    mutation?: UseMutationOptions<
      AddDataSourcesMutationResponse,
      AddDataSources422,
      {
        accountName: AddDataSourcesPathParams['account_name']
        starSchemaDataSourceId: AddDataSourcesPathParams['star_schema_data_source_id']
        data?: AddDataSourcesMutationRequest
      }
    >
    client?: Partial<RequestConfig<AddDataSourcesMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? addDataSourcesMutationKey()

  return useMutation<
    AddDataSourcesMutationResponse,
    AddDataSources422,
    {
      accountName: AddDataSourcesPathParams['account_name']
      starSchemaDataSourceId: AddDataSourcesPathParams['star_schema_data_source_id']
      data?: AddDataSourcesMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return addDataSources({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}