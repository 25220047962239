// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadStarSchemaDataSourceData.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadStarSchemaDataSourceDataMutationRequest,
  ReadStarSchemaDataSourceDataMutationResponse,
  ReadStarSchemaDataSourceDataPathParams,
  ReadStarSchemaDataSourceDataQueryParams,
  ReadStarSchemaDataSourceData422,
} from '../types/ReadStarSchemaDataSourceData'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const readStarSchemaDataSourceDataMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data' }] as const

export type ReadStarSchemaDataSourceDataMutationKey = ReturnType<typeof readStarSchemaDataSourceDataMutationKey>

/**
 * @description Get the joined data for everything in an SSDS.
 * @summary Read Star Schema Data Source Data
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data}
 */
async function readStarSchemaDataSourceData(
  {
    accountName,
    starSchemaDataSourceId,
    data,
    params,
  }: {
    accountName: ReadStarSchemaDataSourceDataPathParams['account_name']
    starSchemaDataSourceId: ReadStarSchemaDataSourceDataPathParams['star_schema_data_source_id']
    data?: ReadStarSchemaDataSourceDataMutationRequest
    params?: ReadStarSchemaDataSourceDataQueryParams
  },
  config: Partial<RequestConfig<ReadStarSchemaDataSourceDataMutationRequest>> = {},
) {
  const res = await client<ReadStarSchemaDataSourceDataMutationResponse, ReadStarSchemaDataSourceData422, ReadStarSchemaDataSourceDataMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/data`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Get the joined data for everything in an SSDS.
 * @summary Read Star Schema Data Source Data
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data}
 */
export function useReadStarSchemaDataSourceData(
  options: {
    mutation?: UseMutationOptions<
      ReadStarSchemaDataSourceDataMutationResponse,
      ReadStarSchemaDataSourceData422,
      {
        accountName: ReadStarSchemaDataSourceDataPathParams['account_name']
        starSchemaDataSourceId: ReadStarSchemaDataSourceDataPathParams['star_schema_data_source_id']
        data?: ReadStarSchemaDataSourceDataMutationRequest
        params?: ReadStarSchemaDataSourceDataQueryParams
      }
    >
    client?: Partial<RequestConfig<ReadStarSchemaDataSourceDataMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? readStarSchemaDataSourceDataMutationKey()

  return useMutation<
    ReadStarSchemaDataSourceDataMutationResponse,
    ReadStarSchemaDataSourceData422,
    {
      accountName: ReadStarSchemaDataSourceDataPathParams['account_name']
      starSchemaDataSourceId: ReadStarSchemaDataSourceDataPathParams['star_schema_data_source_id']
      data?: ReadStarSchemaDataSourceDataMutationRequest
      params?: ReadStarSchemaDataSourceDataQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data, params }) => {
      return readStarSchemaDataSourceData({ accountName, starSchemaDataSourceId, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}