// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/metricViewWithoutDialectSchema.ts"
);
import.meta.hot.lastModified = "1737935769002.74";
}
// REMIX HMR END

// @ts-nocheck
import { dialectSchema } from './dialectSchema'
import { displayTypeSchema } from './displayTypeSchema'
import { filterCategorySchema } from './filterCategorySchema'
import { originTypeSchema } from './originTypeSchema'
import { publishStateSchema } from './publishStateSchema'
import { windowAggregationSpecificationSchema } from './windowAggregationSpecificationSchema'
import { z } from 'zod'

/**
 * @description Metric View without dialect.\n\nThis is almost identical to MetricView, but dialect is optional.\nThis way the FE can call the endpoint below without providing dialect.
 */
export const metricViewWithoutDialectSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    group_id: z.union([z.string(), z.null()]).optional(),
    name: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    dialect: z.union([z.lazy(() => dialectSchema), z.null()]).optional(),
    expr: z.union([z.string(), z.null()]).optional(),
    zen_sql: z.union([z.string(), z.null()]).optional(),
    expr_type: z.lazy(() => filterCategorySchema).default('unknown'),
    state: z.lazy(() => publishStateSchema).default('Verified'),
    display_name: z.union([z.string(), z.null()]).optional(),
    display_type: z.lazy(() => displayTypeSchema).default('unknown'),
    data_source_id: z.union([z.string(), z.null()]).optional(),
    origin: z.array(z.lazy(() => originTypeSchema)).optional(),
    window_specification: z.union([z.lazy(() => windowAggregationSpecificationSchema), z.null()]).optional(),
    popularity: z.number().min(0).max(1).default(1),
    is_sensitive: z.boolean().default(false),
    is_literal_sensitive: z.boolean().default(false),
    is_display_sensitive: z.boolean().default(false),
    updated_at: z.union([z.string().datetime(), z.null()]).optional(),
    created_at: z.union([z.string().datetime(), z.null()]).optional(),
  })
  .describe(
    'Metric View without dialect.\n\nThis is almost identical to MetricView, but dialect is optional.\nThis way the FE can call the endpoint below without providing dialect.',
  )