// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/GetEnterpriseUsers.ts"
);
import.meta.hot.lastModified = "1737759759463.0303";
}
// REMIX HMR END

import type { HttpValidationError } from './HttpValidationError'
import type { User } from './User'

export type GetEnterpriseUsersPathParams = {
  /**
   * @type string
   */
  enterprise_name: string
}

export enum GetEnterpriseUsersQueryParamsSortBy {
  'name' = 'name',
  'created_at' = 'created_at',
  'updated_at' = 'updated_at',
}

export type GetEnterpriseUsersQueryParams = {
  /**
   * @default 0
   * @type integer | undefined
   */
  skip?: number
  /**
   * @default 100
   * @type integer | undefined
   */
  limit?: number
  /**
   * @default "name"
   * @type string | undefined
   */
  sort_by?: GetEnterpriseUsersQueryParamsSortBy
  /**
   * @default true
   * @type boolean | undefined
   */
  sort_ascending?: boolean
}

/**
 * @description Successful Response
 */
export type GetEnterpriseUsers200 = User[]

/**
 * @description Validation Error
 */
export type GetEnterpriseUsers422 = HttpValidationError

export type GetEnterpriseUsersQueryResponse = GetEnterpriseUsers200

export type GetEnterpriseUsersQuery = {
  Response: GetEnterpriseUsers200
  PathParams: GetEnterpriseUsersPathParams
  QueryParams: GetEnterpriseUsersQueryParams
  Errors: GetEnterpriseUsers422
}