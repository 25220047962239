// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetCurrentUser.ts"
);
import.meta.hot.lastModified = "1737759759417.0305";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetCurrentUserQueryResponse, GetCurrentUserPathParams, GetCurrentUser422 } from '../types/GetCurrentUser'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCurrentUserQueryKey = ({ accountName }: { accountName: GetCurrentUserPathParams['account_name'] }) =>
  [{ url: '/api/v3/orgs/:account_name/users/me', params: { accountName: accountName } }] as const

export type GetCurrentUserQueryKey = ReturnType<typeof getCurrentUserQueryKey>

/**
 * @description Get the current user.
 * @summary Get Current User
 * {@link /api/v3/orgs/:account_name/users/me}
 */
async function getCurrentUser({ accountName }: { accountName: GetCurrentUserPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<GetCurrentUserQueryResponse, GetCurrentUser422, unknown>({ method: 'GET', url: `/api/v3/orgs/${accountName}/users/me`, ...config })
  return res.data
}

export function getCurrentUserQueryOptions({ accountName }: { accountName: GetCurrentUserPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const queryKey = getCurrentUserQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCurrentUser({ accountName }, config)
    },
  })
}

/**
 * @description Get the current user.
 * @summary Get Current User
 * {@link /api/v3/orgs/:account_name/users/me}
 */
export function useGetCurrentUser<
  TData = GetCurrentUserQueryResponse,
  TQueryData = GetCurrentUserQueryResponse,
  TQueryKey extends QueryKey = GetCurrentUserQueryKey,
>(
  { accountName }: { accountName: GetCurrentUserPathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<GetCurrentUserQueryResponse, GetCurrentUser422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCurrentUserQueryKey({ accountName })

  const query = useQuery({
    ...(getCurrentUserQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetCurrentUser422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}