// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createEnterpriseSchema.ts"
);
import.meta.hot.lastModified = "1737759760053.027";
}
// REMIX HMR END

// @ts-nocheck
import { enterpriseCreateSchema } from './enterpriseCreateSchema'
import { enterpriseSchema } from './enterpriseSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

/**
 * @description Successful Response
 */
export const createEnterprise201Schema = z.lazy(() => enterpriseSchema)

/**
 * @description Validation Error
 */
export const createEnterprise422Schema = z.lazy(() => httpValidationErrorSchema)

export const createEnterpriseMutationRequestSchema = z.lazy(() => enterpriseCreateSchema)

export const createEnterpriseMutationResponseSchema = z.lazy(() => createEnterprise201Schema)