// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRemoveFeedback.ts"
);
import.meta.hot.lastModified = "1737759759417.0305";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RemoveFeedbackMutationResponse, RemoveFeedbackPathParams, RemoveFeedback422 } from '../types/RemoveFeedback'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const removeFeedbackMutationKey = () => [{ url: '/api/v2/orgs/{account_name}/messages/{message_id}/feedback' }] as const

export type RemoveFeedbackMutationKey = ReturnType<typeof removeFeedbackMutationKey>

/**
 * @description Remove feedback for a message.
 * @summary Remove Feedback
 * {@link /api/v2/orgs/:account_name/messages/:message_id/feedback}
 */
async function removeFeedback(
  { accountName, messageId }: { accountName: RemoveFeedbackPathParams['account_name']; messageId: RemoveFeedbackPathParams['message_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RemoveFeedbackMutationResponse, RemoveFeedback422, unknown>({
    method: 'DELETE',
    url: `/api/v2/orgs/${accountName}/messages/${messageId}/feedback`,
    ...config,
  })
  return res.data
}

/**
 * @description Remove feedback for a message.
 * @summary Remove Feedback
 * {@link /api/v2/orgs/:account_name/messages/:message_id/feedback}
 */
export function useRemoveFeedback(
  options: {
    mutation?: UseMutationOptions<
      RemoveFeedbackMutationResponse,
      RemoveFeedback422,
      { accountName: RemoveFeedbackPathParams['account_name']; messageId: RemoveFeedbackPathParams['message_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? removeFeedbackMutationKey()

  return useMutation<
    RemoveFeedbackMutationResponse,
    RemoveFeedback422,
    { accountName: RemoveFeedbackPathParams['account_name']; messageId: RemoveFeedbackPathParams['message_id'] }
  >({
    mutationFn: async ({ accountName, messageId }) => {
      return removeFeedback({ accountName, messageId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}