// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateMetricsStateSchema.ts"
);
import.meta.hot.lastModified = "1737759760686.024";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { metricDetailSchema } from './metricDetailSchema'
import { stateUpdateWithIdSchema } from './stateUpdateWithIdSchema'
import { z } from 'zod'

export const updateMetricsStatePathParamsSchema = z.object({
  account_name: z.string(),
})

export const updateMetricsStateQueryParamsSchema = z
  .object({
    validate_transition: z.boolean().default(true),
  })
  .optional()

/**
 * @description Successful Response
 */
export const updateMetricsState200Schema = z.array(z.lazy(() => metricDetailSchema))

/**
 * @description Validation Error
 */
export const updateMetricsState422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateMetricsStateMutationRequestSchema = z.array(z.lazy(() => stateUpdateWithIdSchema))

export const updateMetricsStateMutationResponseSchema = z.lazy(() => updateMetricsState200Schema)