// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/namedFilterOptionConditionCreateSchema.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionViewSchema } from './dimensionViewSchema'
import { dmmTypeSchema } from './dmmTypeSchema'
import { filterCategorySchema } from './filterCategorySchema'
import { filterOperatorSchema } from './filterOperatorSchema'
import { metricViewSchema } from './metricViewSchema'
import { z } from 'zod'

/**
 * @description Create NamedFilterOptionCondition Schema.
 */
export const namedFilterOptionConditionCreateSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    column: z.string(),
    category: z.union([z.lazy(() => filterCategorySchema), z.null()]).optional(),
    value: z.union([z.number().int(), z.string(), z.number(), z.null()]).optional(),
    display_value: z.union([z.number().int(), z.string(), z.number(), z.null()]).optional(),
    operator: z.lazy(() => filterOperatorSchema),
    type: z.union([z.lazy(() => dmmTypeSchema), z.null()]).optional(),
    named_filter_option_id: z.union([z.string(), z.null()]).optional(),
    zen_dimension_id: z.union([z.string(), z.null()]).optional(),
    dimension: z.union([z.lazy(() => dimensionViewSchema), z.null()]).optional(),
    zen_measure_id: z.union([z.string(), z.null()]).optional(),
    metric: z.union([z.lazy(() => metricViewSchema), z.null()]).optional(),
  })
  .describe('Create NamedFilterOptionCondition Schema.')