// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadSnowflakeConnectionDatabases.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadSnowflakeConnectionDatabasesQueryResponse,
  ReadSnowflakeConnectionDatabasesPathParams,
  ReadSnowflakeConnectionDatabases422,
} from '../types/ReadSnowflakeConnectionDatabases'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readSnowflakeConnectionDatabasesQueryKey = ({
  accountName,
  snowflakeConnectionId,
}: {
  accountName: ReadSnowflakeConnectionDatabasesPathParams['account_name']
  snowflakeConnectionId: ReadSnowflakeConnectionDatabasesPathParams['snowflake_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/databases',
      params: { accountName: accountName, snowflakeConnectionId: snowflakeConnectionId },
    },
  ] as const

export type ReadSnowflakeConnectionDatabasesQueryKey = ReturnType<typeof readSnowflakeConnectionDatabasesQueryKey>

/**
 * @description Read Snowflake databases.
 * @summary Read Snowflake Connection Databases
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/databases}
 */
async function readSnowflakeConnectionDatabases(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionDatabasesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionDatabasesPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadSnowflakeConnectionDatabasesQueryResponse, ReadSnowflakeConnectionDatabases422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/${snowflakeConnectionId}/databases`,
    ...config,
  })
  return res.data
}

export function readSnowflakeConnectionDatabasesQueryOptions(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionDatabasesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionDatabasesPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readSnowflakeConnectionDatabasesQueryKey({ accountName, snowflakeConnectionId })
  return queryOptions({
    enabled: !!(accountName && snowflakeConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readSnowflakeConnectionDatabases({ accountName, snowflakeConnectionId }, config)
    },
  })
}

/**
 * @description Read Snowflake databases.
 * @summary Read Snowflake Connection Databases
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/databases}
 */
export function useReadSnowflakeConnectionDatabases<
  TData = ReadSnowflakeConnectionDatabasesQueryResponse,
  TQueryData = ReadSnowflakeConnectionDatabasesQueryResponse,
  TQueryKey extends QueryKey = ReadSnowflakeConnectionDatabasesQueryKey,
>(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionDatabasesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionDatabasesPathParams['snowflake_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadSnowflakeConnectionDatabasesQueryResponse, ReadSnowflakeConnectionDatabases422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readSnowflakeConnectionDatabasesQueryKey({ accountName, snowflakeConnectionId })

  const query = useQuery({
    ...(readSnowflakeConnectionDatabasesQueryOptions({ accountName, snowflakeConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadSnowflakeConnectionDatabases422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}