// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useTrimTextColumnsSqlForDataSource.ts"
);
import.meta.hot.lastModified = "1737935769644.7363";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  TrimTextColumnsSqlForDataSourceQueryResponse,
  TrimTextColumnsSqlForDataSourcePathParams,
  TrimTextColumnsSqlForDataSource422,
} from '../types/TrimTextColumnsSqlForDataSource'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const trimTextColumnsSqlForDataSourceQueryKey = ({
  accountName,
  starSchemaDataSourceId,
  dataSourceId,
}: {
  accountName: TrimTextColumnsSqlForDataSourcePathParams['account_name']
  starSchemaDataSourceId: TrimTextColumnsSqlForDataSourcePathParams['star_schema_data_source_id']
  dataSourceId: TrimTextColumnsSqlForDataSourcePathParams['data_source_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id/trim_text_columns_sql',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId, dataSourceId: dataSourceId },
    },
  ] as const

export type TrimTextColumnsSqlForDataSourceQueryKey = ReturnType<typeof trimTextColumnsSqlForDataSourceQueryKey>

/**
 * @description Generates a SQL that trims all the text columns from an existing data source.All the text columns will be applied with a trim.Returns:    the modified sql
 * @summary Trim Text Columns Sql For Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id/trim_text_columns_sql}
 */
async function trimTextColumnsSqlForDataSource(
  {
    accountName,
    starSchemaDataSourceId,
    dataSourceId,
  }: {
    accountName: TrimTextColumnsSqlForDataSourcePathParams['account_name']
    starSchemaDataSourceId: TrimTextColumnsSqlForDataSourcePathParams['star_schema_data_source_id']
    dataSourceId: TrimTextColumnsSqlForDataSourcePathParams['data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<TrimTextColumnsSqlForDataSourceQueryResponse, TrimTextColumnsSqlForDataSource422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/data_sources/${dataSourceId}/trim_text_columns_sql`,
    ...config,
  })
  return res.data
}

export function trimTextColumnsSqlForDataSourceQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    dataSourceId,
  }: {
    accountName: TrimTextColumnsSqlForDataSourcePathParams['account_name']
    starSchemaDataSourceId: TrimTextColumnsSqlForDataSourcePathParams['star_schema_data_source_id']
    dataSourceId: TrimTextColumnsSqlForDataSourcePathParams['data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = trimTextColumnsSqlForDataSourceQueryKey({ accountName, starSchemaDataSourceId, dataSourceId })
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId && dataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return trimTextColumnsSqlForDataSource({ accountName, starSchemaDataSourceId, dataSourceId }, config)
    },
  })
}

/**
 * @description Generates a SQL that trims all the text columns from an existing data source.All the text columns will be applied with a trim.Returns:    the modified sql
 * @summary Trim Text Columns Sql For Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/data_sources/:data_source_id/trim_text_columns_sql}
 */
export function useTrimTextColumnsSqlForDataSource<
  TData = TrimTextColumnsSqlForDataSourceQueryResponse,
  TQueryData = TrimTextColumnsSqlForDataSourceQueryResponse,
  TQueryKey extends QueryKey = TrimTextColumnsSqlForDataSourceQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    dataSourceId,
  }: {
    accountName: TrimTextColumnsSqlForDataSourcePathParams['account_name']
    starSchemaDataSourceId: TrimTextColumnsSqlForDataSourcePathParams['star_schema_data_source_id']
    dataSourceId: TrimTextColumnsSqlForDataSourcePathParams['data_source_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<TrimTextColumnsSqlForDataSourceQueryResponse, TrimTextColumnsSqlForDataSource422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? trimTextColumnsSqlForDataSourceQueryKey({ accountName, starSchemaDataSourceId, dataSourceId })

  const query = useQuery({
    ...(trimTextColumnsSqlForDataSourceQueryOptions({ accountName, starSchemaDataSourceId, dataSourceId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, TrimTextColumnsSqlForDataSource422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}