// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readSnowflakeConnectionSchemasSchema.ts"
);
import.meta.hot.lastModified = "1737935769623.7366";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readSnowflakeConnectionSchemasPathParamsSchema = z.object({
  account_name: z.string(),
  snowflake_connection_id: z.string(),
})

export const readSnowflakeConnectionSchemasQueryParamsSchema = z
  .object({
    database: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readSnowflakeConnectionSchemas200Schema = z.object({}).catchall(z.array(z.string()))

/**
 * @description Validation Error
 */
export const readSnowflakeConnectionSchemas422Schema = z.lazy(() => httpValidationErrorSchema)

export const readSnowflakeConnectionSchemasQueryResponseSchema = z.lazy(() => readSnowflakeConnectionSchemas200Schema)