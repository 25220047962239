// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getCandidateDemonstrationCellsCountSchema.ts"
);
import.meta.hot.lastModified = "1737935769669.7363";
}
// REMIX HMR END

// @ts-nocheck
import { feedbackFilterSchema } from './feedbackFilterSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getCandidateDemonstrationCellsCountPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const getCandidateDemonstrationCellsCount200Schema = z.number().int()

/**
 * @description Validation Error
 */
export const getCandidateDemonstrationCellsCount422Schema = z.lazy(() => httpValidationErrorSchema)

export const getCandidateDemonstrationCellsCountMutationRequestSchema = z.union([z.lazy(() => feedbackFilterSchema), z.null()])

export const getCandidateDemonstrationCellsCountMutationResponseSchema = z.lazy(() => getCandidateDemonstrationCellsCount200Schema)