// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteTableauConnection.ts"
);
import.meta.hot.lastModified = "1737759761466.0198";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteTableauConnectionMutationResponse, DeleteTableauConnectionPathParams, DeleteTableauConnection422 } from '../types/DeleteTableauConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteTableauConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/tableau/{tableau_connection_id}' }] as const

export type DeleteTableauConnectionMutationKey = ReturnType<typeof deleteTableauConnectionMutationKey>

/**
 * @description Delete Tableau connection.Note: This will delete the connection only if it is not used by any report.
 * @summary Delete Tableau Connection
 * {@link /api/v1/accounts/:account_name/connections/tableau/:tableau_connection_id}
 */
async function deleteTableauConnection(
  {
    accountName,
    tableauConnectionId,
  }: { accountName: DeleteTableauConnectionPathParams['account_name']; tableauConnectionId: DeleteTableauConnectionPathParams['tableau_connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<DeleteTableauConnectionMutationResponse, DeleteTableauConnection422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/connections/tableau/${tableauConnectionId}`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete Tableau connection.Note: This will delete the connection only if it is not used by any report.
 * @summary Delete Tableau Connection
 * {@link /api/v1/accounts/:account_name/connections/tableau/:tableau_connection_id}
 */
export function useDeleteTableauConnection(
  options: {
    mutation?: UseMutationOptions<
      DeleteTableauConnectionMutationResponse,
      DeleteTableauConnection422,
      { accountName: DeleteTableauConnectionPathParams['account_name']; tableauConnectionId: DeleteTableauConnectionPathParams['tableau_connection_id'] }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteTableauConnectionMutationKey()

  return useMutation<
    DeleteTableauConnectionMutationResponse,
    DeleteTableauConnection422,
    { accountName: DeleteTableauConnectionPathParams['account_name']; tableauConnectionId: DeleteTableauConnectionPathParams['tableau_connection_id'] }
  >({
    mutationFn: async ({ accountName, tableauConnectionId }) => {
      return deleteTableauConnection({ accountName, tableauConnectionId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}