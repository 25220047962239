// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/starSchemaDataSourcesResponseSchema.ts"
);
import.meta.hot.lastModified = "1737935769440.7375";
}
// REMIX HMR END

// @ts-nocheck
import { starSchemaDataSourceBasicSchema } from './starSchemaDataSourceBasicSchema'
import { z } from 'zod'

/**
 * @description Star Schema Data Sources Response.
 */
export const starSchemaDataSourcesResponseSchema = z
  .object({
    data: z.array(z.lazy(() => starSchemaDataSourceBasicSchema)),
    count: z.number().int(),
  })
  .describe('Star Schema Data Sources Response.')