// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/loginApiKeySchema.ts"
);
import.meta.hot.lastModified = "1737935765551.7588";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tokenSchema } from './tokenSchema'
import { z } from 'zod'

export const loginApiKeyPathParamsSchema = z.object({
  account_name: z.string(),
})

export const loginApiKeyQueryParamsSchema = z.object({
  email: z.string().email(),
  display_name: z.string(),
  m2m_token: z.union([z.string(), z.null()]).optional(),
})

/**
 * @description Successful Response
 */
export const loginApiKey200Schema = z.lazy(() => tokenSchema)

/**
 * @description Validation Error
 */
export const loginApiKey422Schema = z.lazy(() => httpValidationErrorSchema)

export const loginApiKeyMutationResponseSchema = z.lazy(() => loginApiKey200Schema)