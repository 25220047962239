// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/addAccountAllowedDomainSchema.ts"
);
import.meta.hot.lastModified = "1737935768797.741";
}
// REMIX HMR END

// @ts-nocheck
import { domainSchema } from './domainSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const addAccountAllowedDomainPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const addAccountAllowedDomain201Schema = z.lazy(() => domainSchema)

/**
 * @description Validation Error
 */
export const addAccountAllowedDomain422Schema = z.lazy(() => httpValidationErrorSchema)

export const addAccountAllowedDomainMutationRequestSchema = z.string()

export const addAccountAllowedDomainMutationResponseSchema = z.lazy(() => addAccountAllowedDomain201Schema)