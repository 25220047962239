// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readMessageSchema.ts"
);
import.meta.hot.lastModified = "1737759758432.0356";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { messageApiGetResponseSchema } from './messageApiGetResponseSchema'
import { messagePayloadTypeSchema } from './messagePayloadTypeSchema'
import { z } from 'zod'

export const readMessagePathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  message_id: z.string(),
})

export const readMessageQueryParamsSchema = z
  .object({
    accepted_payload_types: z.union([z.array(z.lazy(() => messagePayloadTypeSchema)), z.null()]).optional(),
    table_limit: z.number().int().min(1).max(1000).default(20),
    table_offset: z.number().int().min(0).default(0),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readMessage200Schema = z.lazy(() => messageApiGetResponseSchema)

/**
 * @description Validation Error
 */
export const readMessage422Schema = z.lazy(() => httpValidationErrorSchema)

export const readMessageQueryResponseSchema = z.lazy(() => readMessage200Schema)