// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messageUpdateFeedbackApiSchema.ts"
);
import.meta.hot.lastModified = "1737759761840.0178";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Request body to update the message feedback.
 */
export const messageUpdateFeedbackApiSchema = z
  .object({
    is_positive_feedback: z
      .union([z.boolean(), z.null()])
      .describe('Whether or not the chat is correct. TRUE for thumbs up, FALSE for thumbs down, or NULL to remove the feedback.'),
  })
  .describe('Request body to update the message feedback.')