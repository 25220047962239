// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/snowflakeConnectionApiCreateSchema.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

// @ts-nocheck
import { connectionPolyTypeSchema } from './connectionPolyTypeSchema'
import { z } from 'zod'

/**
 * @description Properties to receive via API on creation.
 */
export const snowflakeConnectionApiCreateSchema = z
  .object({
    name: z.string(),
    account_id: z.union([z.string(), z.null()]).optional(),
    is_demo: z.boolean().default(false),
    is_draft: z.boolean().default(false),
    status: z.union([z.string(), z.null()]).optional(),
    status_at: z.union([z.string().datetime(), z.null()]).optional(),
    type: z.lazy(() => connectionPolyTypeSchema).default('snowflake'),
    max_tables: z.number().int().default(8000),
    warehouse_access: z.union([z.boolean(), z.null()]).optional(),
    result_cache_access: z.union([z.boolean(), z.null()]).optional(),
    error_msg: z.union([z.string(), z.null()]).optional(),
    user: z.string(),
    account: z.string(),
    region: z.union([z.string(), z.null()]).optional(),
    cloud: z.union([z.string(), z.null()]).optional(),
    account_locator: z.union([z.string(), z.null()]).optional(),
    role: z.union([z.string(), z.null()]).optional(),
    ns_warehouse: z.union([z.string(), z.null()]).optional(),
    ns_database: z.string().default('NUMBERS_STATION'),
    ns_schema: z.string().default('NUMBERS_STATION'),
    password: z.string(),
    create_db_and_schema: z.boolean().default(true),
  })
  .describe('Properties to receive via API on creation.')