// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateEvalCell.ts"
);
import.meta.hot.lastModified = "1737935766299.7546";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateEvalCellMutationRequest,
  CreateEvalCellMutationResponse,
  CreateEvalCellPathParams,
  CreateEvalCellQueryParams,
  CreateEvalCell422,
} from '../types/CreateEvalCell'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createEvalCellMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/eval_cell' }] as const

export type CreateEvalCellMutationKey = ReturnType<typeof createEvalCellMutationKey>

/**
 * @description Create a new Evaluation Bumblebee Cell in an Evaluation Conversation.Args:    eval_bb_cell_in: Eval cell creation API. Only required if cell_id is None.    source_cell_id: source id to create eval cell from.    conversation_id: Optional, to append cell to an existing eval conversation.
 * @summary Create Eval Cell
 * {@link /api/v1/accounts/:account_name/conversations/eval_cell}
 */
async function createEvalCell(
  {
    accountName,
    data,
    params,
  }: { accountName: CreateEvalCellPathParams['account_name']; data?: CreateEvalCellMutationRequest; params?: CreateEvalCellQueryParams },
  config: Partial<RequestConfig<CreateEvalCellMutationRequest>> = {},
) {
  const res = await client<CreateEvalCellMutationResponse, CreateEvalCell422, CreateEvalCellMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/eval_cell`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a new Evaluation Bumblebee Cell in an Evaluation Conversation.Args:    eval_bb_cell_in: Eval cell creation API. Only required if cell_id is None.    source_cell_id: source id to create eval cell from.    conversation_id: Optional, to append cell to an existing eval conversation.
 * @summary Create Eval Cell
 * {@link /api/v1/accounts/:account_name/conversations/eval_cell}
 */
export function useCreateEvalCell(
  options: {
    mutation?: UseMutationOptions<
      CreateEvalCellMutationResponse,
      CreateEvalCell422,
      { accountName: CreateEvalCellPathParams['account_name']; data?: CreateEvalCellMutationRequest; params?: CreateEvalCellQueryParams }
    >
    client?: Partial<RequestConfig<CreateEvalCellMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createEvalCellMutationKey()

  return useMutation<
    CreateEvalCellMutationResponse,
    CreateEvalCell422,
    { accountName: CreateEvalCellPathParams['account_name']; data?: CreateEvalCellMutationRequest; params?: CreateEvalCellQueryParams }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return createEvalCell({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}