// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/preregistrationCreateSchema.ts"
);
import.meta.hot.lastModified = "1737935768445.743";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Properties to be used by crud.create\n\nIf account_id is used, the account must already exist. When the user registers,\nthey\'ll be added as a user in that account.
 */
export const preregistrationCreateSchema = z
  .object({
    email: z.string().email(),
    account_id: z.union([z.string(), z.null()]).optional(),
    creator_id: z.union([z.string(), z.null()]).optional(),
    registered: z.boolean().default(false),
  })
  .describe(
    "Properties to be used by crud.create\n\nIf account_id is used, the account must already exist. When the user registers,\nthey'll be added as a user in that account.",
  )