// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tagAssociationSchema.ts"
);
import.meta.hot.lastModified = "1737935766487.7537";
}
// REMIX HMR END

// @ts-nocheck
import { tagSchema } from './tagSchema'
import { userBasicSchema } from './userBasicSchema'
import { z } from 'zod'

/**
 * @description Tag association schema.
 */
export const tagAssociationSchema = z
  .object({
    tag: z.lazy(() => tagSchema),
    creator: z.lazy(() => userBasicSchema),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
  })
  .describe('Tag association schema.')