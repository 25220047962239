// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dataPageSchema.ts"
);
import.meta.hot.lastModified = "1737759757945.038";
}
// REMIX HMR END

// @ts-nocheck
import { displayTypeV3Schema } from './displayTypeV3Schema'
import { z } from 'zod'

/**
 * @description Paginated data response.
 */
export const dataPageSchema = z
  .object({
    data: z.string(),
    display_types: z
      .object({})
      .catchall(z.lazy(() => displayTypeV3Schema))
      .describe('Mapping of column name to its display type.'),
    total: z.number().int(),
  })
  .describe('Paginated data response.')