// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/zenIdentifierSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { identifierJoinTypeSchema } from './identifierJoinTypeSchema'
import { zenIdentifierRelationshipSchema } from './zenIdentifierRelationshipSchema'
import { zenIdentifierTypeSchema } from './zenIdentifierTypeSchema'
import { z } from 'zod'

/**
 * @description Metrics layer identifier.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/view#identifiers
 */
export const zenIdentifierSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    name: z.string(),
    type: z.lazy(() => zenIdentifierTypeSchema),
    sql: z.union([z.string(), z.null()]).optional(),
    reference: z.union([z.string(), z.null()]).optional(),
    relationship: z.lazy(() => zenIdentifierRelationshipSchema).default('many_to_one'),
    sql_on: z.union([z.string(), z.null()]).optional(),
    join_type: z.union([z.lazy(() => identifierJoinTypeSchema), z.null()]).optional(),
    identifiers: z.union([z.array(z.string()), z.null()]).optional(),
    data_source_id: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Metrics layer identifier.\n\nRef: https://docs.zenlytic.com/docs/data_modeling/view#identifiers')