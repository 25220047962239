// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadBigqueryConnection.ts"
);
import.meta.hot.lastModified = "1737935768446.743";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadBigqueryConnectionQueryResponse, ReadBigqueryConnectionPathParams, ReadBigqueryConnection422 } from '../types/ReadBigqueryConnection'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readBigqueryConnectionQueryKey = ({
  accountName,
  connectionId,
}: {
  accountName: ReadBigqueryConnectionPathParams['account_name']
  connectionId: ReadBigqueryConnectionPathParams['connection_id']
}) => [{ url: '/api/v1/accounts/:account_name/connections/bigquery/:connection_id', params: { accountName: accountName, connectionId: connectionId } }] as const

export type ReadBigqueryConnectionQueryKey = ReturnType<typeof readBigqueryConnectionQueryKey>

/**
 * @description Read a BigQuery connection.
 * @summary Read Bigquery Connection
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id}
 */
async function readBigqueryConnection(
  {
    accountName,
    connectionId,
  }: { accountName: ReadBigqueryConnectionPathParams['account_name']; connectionId: ReadBigqueryConnectionPathParams['connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadBigqueryConnectionQueryResponse, ReadBigqueryConnection422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/bigquery/${connectionId}`,
    ...config,
  })
  return res.data
}

export function readBigqueryConnectionQueryOptions(
  {
    accountName,
    connectionId,
  }: { accountName: ReadBigqueryConnectionPathParams['account_name']; connectionId: ReadBigqueryConnectionPathParams['connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readBigqueryConnectionQueryKey({ accountName, connectionId })
  return queryOptions({
    enabled: !!(accountName && connectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readBigqueryConnection({ accountName, connectionId }, config)
    },
  })
}

/**
 * @description Read a BigQuery connection.
 * @summary Read Bigquery Connection
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id}
 */
export function useReadBigqueryConnection<
  TData = ReadBigqueryConnectionQueryResponse,
  TQueryData = ReadBigqueryConnectionQueryResponse,
  TQueryKey extends QueryKey = ReadBigqueryConnectionQueryKey,
>(
  {
    accountName,
    connectionId,
  }: { accountName: ReadBigqueryConnectionPathParams['account_name']; connectionId: ReadBigqueryConnectionPathParams['connection_id'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadBigqueryConnectionQueryResponse, ReadBigqueryConnection422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readBigqueryConnectionQueryKey({ accountName, connectionId })

  const query = useQuery({
    ...(readBigqueryConnectionQueryOptions({ accountName, connectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadBigqueryConnection422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}