// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateRedshiftConnection.ts"
);
import.meta.hot.lastModified = "1737935768800.741";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateRedshiftConnectionMutationRequest,
  CreateRedshiftConnectionMutationResponse,
  CreateRedshiftConnectionPathParams,
  CreateRedshiftConnection422,
} from '../types/CreateRedshiftConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createRedshiftConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/redshift/' }] as const

export type CreateRedshiftConnectionMutationKey = ReturnType<typeof createRedshiftConnectionMutationKey>

/**
 * @description Create new Redshift connection.
 * @summary Create Redshift Connection
 * {@link /api/v1/accounts/:account_name/connections/redshift/}
 */
async function createRedshiftConnection(
  { accountName, data }: { accountName: CreateRedshiftConnectionPathParams['account_name']; data: CreateRedshiftConnectionMutationRequest },
  config: Partial<RequestConfig<CreateRedshiftConnectionMutationRequest>> = {},
) {
  const res = await client<CreateRedshiftConnectionMutationResponse, CreateRedshiftConnection422, CreateRedshiftConnectionMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/redshift/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create new Redshift connection.
 * @summary Create Redshift Connection
 * {@link /api/v1/accounts/:account_name/connections/redshift/}
 */
export function useCreateRedshiftConnection(
  options: {
    mutation?: UseMutationOptions<
      CreateRedshiftConnectionMutationResponse,
      CreateRedshiftConnection422,
      { accountName: CreateRedshiftConnectionPathParams['account_name']; data: CreateRedshiftConnectionMutationRequest }
    >
    client?: Partial<RequestConfig<CreateRedshiftConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createRedshiftConnectionMutationKey()

  return useMutation<
    CreateRedshiftConnectionMutationResponse,
    CreateRedshiftConnection422,
    { accountName: CreateRedshiftConnectionPathParams['account_name']; data: CreateRedshiftConnectionMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return createRedshiftConnection({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}