// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteApiKey.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { DeleteApiKeyMutationResponse, DeleteApiKeyPathParams, DeleteApiKey422 } from '../types/DeleteApiKey'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteApiKeyMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/api_key' }] as const

export type DeleteApiKeyMutationKey = ReturnType<typeof deleteApiKeyMutationKey>

/**
 * @description Delete the account's API Key.
 * @summary Delete Api Key
 * {@link /api/v1/accounts/:account_name/api_key}
 */
async function deleteApiKey({ accountName }: { accountName: DeleteApiKeyPathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<DeleteApiKeyMutationResponse, DeleteApiKey422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/api_key`,
    ...config,
  })
  return res.data
}

/**
 * @description Delete the account's API Key.
 * @summary Delete Api Key
 * {@link /api/v1/accounts/:account_name/api_key}
 */
export function useDeleteApiKey(
  options: {
    mutation?: UseMutationOptions<DeleteApiKeyMutationResponse, DeleteApiKey422, { accountName: DeleteApiKeyPathParams['account_name'] }>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteApiKeyMutationKey()

  return useMutation<DeleteApiKeyMutationResponse, DeleteApiKey422, { accountName: DeleteApiKeyPathParams['account_name'] }>({
    mutationFn: async ({ accountName }) => {
      return deleteApiKey({ accountName }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}