// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/senderTypeSchema.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description The type of sender that sent a message.\n\nAttributes:\n    USER: A user sent the message.\n    AI: The AI model sent the message.
 */
export const senderTypeSchema = z
  .enum(['USER', 'AI'])
  .describe('The type of sender that sent a message.\n\nAttributes:\n    USER: A user sent the message.\n    AI: The AI model sent the message.')