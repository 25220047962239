// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readStarSchemaDataSourcesSchema.ts"
);
import.meta.hot.lastModified = "1737935769018.7397";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { starSchemaDataSourceSortSchema } from './starSchemaDataSourceSortSchema'
import { starSchemaDataSourcesResponseSchema } from './starSchemaDataSourcesResponseSchema'
import { z } from 'zod'

export const readStarSchemaDataSourcesPathParamsSchema = z.object({
  account_name: z.string(),
})

export const readStarSchemaDataSourcesQueryParamsSchema = z
  .object({
    sort_ascending: z.boolean().default(false),
    sort_by: z.union([z.lazy(() => starSchemaDataSourceSortSchema), z.null()]).optional(),
    limit: z.union([z.number().int(), z.null()]).optional(),
    offset: z.union([z.number().int(), z.null()]).optional(),
    search: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readStarSchemaDataSources200Schema = z.lazy(() => starSchemaDataSourcesResponseSchema)

/**
 * @description Validation Error
 */
export const readStarSchemaDataSources422Schema = z.lazy(() => httpValidationErrorSchema)

export const readStarSchemaDataSourcesQueryResponseSchema = z.lazy(() => readStarSchemaDataSources200Schema)