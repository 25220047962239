// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/profileCellDataSchema.ts"
);
import.meta.hot.lastModified = "1737759759806.0283";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tableFilterSchema } from './tableFilterSchema'
import { unionStatisticSchema } from './unionStatisticSchema'
import { z } from 'zod'

export const profileCellDataPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

export const profileCellDataQueryParamsSchema = z
  .object({
    limit: z.number().int().default(100000),
  })
  .optional()

/**
 * @description Successful Response
 */
export const profileCellData200Schema = z.object({}).catchall(z.lazy(() => unionStatisticSchema))

/**
 * @description Validation Error
 */
export const profileCellData422Schema = z.lazy(() => httpValidationErrorSchema)

export const profileCellDataMutationRequestSchema = z.union([z.array(z.lazy(() => tableFilterSchema)), z.null()])

export const profileCellDataMutationResponseSchema = z.lazy(() => profileCellData200Schema)