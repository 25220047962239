// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/stringColumnStatisticsSchema.ts"
);
import.meta.hot.lastModified = "1737935768612.742";
}
// REMIX HMR END

// @ts-nocheck
import { columnValueSchema } from './columnValueSchema'
import { z } from 'zod'

/**
 * @description Statistics for string columns.
 */
export const stringColumnStatisticsSchema = z
  .object({
    name: z.string(),
    type: z.string(),
    count: z.number().int(),
    num_nulls: z.number().int(),
    num_distinct_values: z.union([z.number().int(), z.null()]).optional(),
    stat_type: z.enum(['string']).default('string'),
    num_empty: z.number().int(),
    min: z.union([z.number().int(), z.null()]).optional(),
    mean: z.union([z.number(), z.null()]).optional(),
    max: z.union([z.number().int(), z.null()]).optional(),
    most_common_values: z.array(z.lazy(() => columnValueSchema)),
  })
  .describe('Statistics for string columns.')