// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readConnectionDataSchema.ts"
);
import.meta.hot.lastModified = "1737759760686.024";
}
// REMIX HMR END

// @ts-nocheck
import { bodyReadConnectionDataApiV1AccountsAccountNameConnectionsConnectionIdDataGetSchema } from './bodyReadConnectionDataApiV1AccountsAccountNameConnectionsConnectionIdDataGetSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { nodeDataPageSchema } from './nodeDataPageSchema'
import { z } from 'zod'

export const readConnectionDataPathParamsSchema = z.object({
  account_name: z.string(),
  connection_id: z.string(),
})

export const readConnectionDataQueryParamsSchema = z.object({
  skip: z.number().int().default(0),
  limit: z.number().int().default(100),
  database: z.string(),
  schema: z.string(),
  table: z.string(),
})

/**
 * @description Successful Response
 */
export const readConnectionData200Schema = z.lazy(() => nodeDataPageSchema)

/**
 * @description Validation Error
 */
export const readConnectionData422Schema = z.lazy(() => httpValidationErrorSchema)

export const readConnectionDataQueryRequestSchema = z.lazy(() => bodyReadConnectionDataApiV1AccountsAccountNameConnectionsConnectionIdDataGetSchema)

export const readConnectionDataQueryResponseSchema = z.lazy(() => readConnectionData200Schema)