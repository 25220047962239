// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatApiCreateSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Payload for creating a chat.\n\nUser can optionally name the chat if desired.
 */
export const chatApiCreateSchema = z
  .object({
    chat_name: z
      .union([z.string(), z.null()])
      .describe('The name of the chat. If one is not provided, the chat is auto-named based on the first message.')
      .optional(),
    slack_id: z.union([z.string(), z.null()]).describe('The ID of the Slack conversation associated with the chat.').optional(),
  })
  .describe('Payload for creating a chat.\n\nUser can optionally name the chat if desired.')