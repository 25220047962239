// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/filterConditionCreateApiSchema.ts"
);
import.meta.hot.lastModified = "1737935768991.74";
}
// REMIX HMR END

// @ts-nocheck
import { filterCategorySchema } from './filterCategorySchema'
import { filterOperatorSchema } from './filterOperatorSchema'
import { z } from 'zod'

/**
 * @description Create a FilterCondition via an endpoint.
 */
export const filterConditionCreateApiSchema = z
  .object({
    column: z.string(),
    category: z.union([z.lazy(() => filterCategorySchema), z.null()]).optional(),
    value: z.union([z.number().int(), z.string(), z.number(), z.null()]).optional(),
    operator: z.lazy(() => filterOperatorSchema),
    display_value: z.union([z.number().int(), z.string(), z.number(), z.null()]).optional(),
    id: z.union([z.string(), z.null()]).optional(),
    dimension_id: z.union([z.string(), z.null()]).optional(),
    metric_id: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Create a FilterCondition via an endpoint.')