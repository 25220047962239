// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getApiKeySchema.ts"
);
import.meta.hot.lastModified = "1737935765118.7612";
}
// REMIX HMR END

// @ts-nocheck
import { apiKeySchema } from './apiKeySchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getApiKeyPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const getApiKey200Schema = z.union([z.lazy(() => apiKeySchema), z.null()])

/**
 * @description Validation Error
 */
export const getApiKey422Schema = z.lazy(() => httpValidationErrorSchema)

export const getApiKeyQueryResponseSchema = z.lazy(() => getApiKey200Schema)