// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateConversationSchema.ts"
);
import.meta.hot.lastModified = "1737759760685.024";
}
// REMIX HMR END

// @ts-nocheck
import { conversationApiUpdateSchema } from './conversationApiUpdateSchema'
import { conversationBasicSchema } from './conversationBasicSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateConversationPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
})

/**
 * @description Successful Response
 */
export const updateConversation200Schema = z.lazy(() => conversationBasicSchema)

/**
 * @description Validation Error
 */
export const updateConversation422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateConversationMutationRequestSchema = z.lazy(() => conversationApiUpdateSchema)

export const updateConversationMutationResponseSchema = z.lazy(() => updateConversation200Schema)