// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useVerifyCell.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { VerifyCellMutationResponse, VerifyCellPathParams, VerifyCellQueryParams, VerifyCell422 } from '../types/VerifyCell'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const verifyCellMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/verified' }] as const

export type VerifyCellMutationKey = ReturnType<typeof verifyCellMutationKey>

/**
 * @description Add positive feedback to a bumblebee cell.
 * @summary Verify Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/verified}
 */
async function verifyCell(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: VerifyCellPathParams['account_name']
    conversationId: VerifyCellPathParams['conversation_id']
    cellId: VerifyCellPathParams['cell_id']
    params?: VerifyCellQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<VerifyCellMutationResponse, VerifyCell422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/verified`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Add positive feedback to a bumblebee cell.
 * @summary Verify Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/verified}
 */
export function useVerifyCell(
  options: {
    mutation?: UseMutationOptions<
      VerifyCellMutationResponse,
      VerifyCell422,
      {
        accountName: VerifyCellPathParams['account_name']
        conversationId: VerifyCellPathParams['conversation_id']
        cellId: VerifyCellPathParams['cell_id']
        params?: VerifyCellQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? verifyCellMutationKey()

  return useMutation<
    VerifyCellMutationResponse,
    VerifyCell422,
    {
      accountName: VerifyCellPathParams['account_name']
      conversationId: VerifyCellPathParams['conversation_id']
      cellId: VerifyCellPathParams['cell_id']
      params?: VerifyCellQueryParams
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, params }) => {
      return verifyCell({ accountName, conversationId, cellId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}