// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateMetricSchema.ts"
);
import.meta.hot.lastModified = "1737935766030.756";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { metricDetailSchema } from './metricDetailSchema'
import { metricUpdateSchema } from './metricUpdateSchema'
import { z } from 'zod'

export const updateMetricPathParamsSchema = z.object({
  account_name: z.string(),
  metric_id: z.string(),
})

/**
 * @description Successful Response
 */
export const updateMetric200Schema = z.lazy(() => metricDetailSchema)

/**
 * @description Validation Error
 */
export const updateMetric422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateMetricMutationRequestSchema = z.lazy(() => metricUpdateSchema)

export const updateMetricMutationResponseSchema = z.lazy(() => updateMetric200Schema)