// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/SystemTag.ts"
);
import.meta.hot.lastModified = "1737935764663.7637";
}
// REMIX HMR END

export enum SystemTag {
  'First prompt' = 'First prompt',
  'Follow up' = 'Follow up',
  'AI generated' = 'AI generated',
  'Verified' = 'Verified',
  'Pending assistance' = 'Pending assistance',
  'Empty query' = 'Empty query',
  'Sensitive' = 'Sensitive',
}