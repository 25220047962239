// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/RetrievalMethod.ts"
);
import.meta.hot.lastModified = "1737759758680.0342";
}
// REMIX HMR END

export enum RetrievalMethod {
  'emb' = 'emb',
  'txt' = 'txt',
  'hybrid' = 'hybrid',
}