// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/namedFilterUpdateApiWithIdSchema.ts"
);
import.meta.hot.lastModified = "1737935769208.7388";
}
// REMIX HMR END

// @ts-nocheck
import { combinationSchema } from './combinationSchema'
import { filterConditionCreateApiSchema } from './filterConditionCreateApiSchema'
import { filterTypeSchema } from './filterTypeSchema'
import { z } from 'zod'

/**
 * @description Bulk update named filters via an endpoint.
 */
export const namedFilterUpdateApiWithIdSchema = z
  .object({
    display_name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    combination: z.lazy(() => combinationSchema).default('ALL'),
    conditions: z.union([z.array(z.lazy(() => filterConditionCreateApiSchema)), z.null()]).optional(),
    filter_type: z.union([z.lazy(() => filterTypeSchema), z.null()]).optional(),
    is_enabled: z.union([z.boolean(), z.null()]).optional(),
    id: z.string(),
  })
  .describe('Bulk update named filters via an endpoint.')