// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getAccountSsdsNamedFiltersCountSchema.ts"
);
import.meta.hot.lastModified = "1737935769655.7363";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { knowledgeStateFilterSchema } from './knowledgeStateFilterSchema'
import { z } from 'zod'

export const getAccountSsdsNamedFiltersCountPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const getAccountSsdsNamedFiltersCountQueryParamsSchema = z
  .object({
    state: z.union([z.lazy(() => knowledgeStateFilterSchema), z.null()]).default('valid'),
    data_source_ids: z.union([z.array(z.string()), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getAccountSsdsNamedFiltersCount200Schema = z.number().int()

/**
 * @description Validation Error
 */
export const getAccountSsdsNamedFiltersCount422Schema = z.lazy(() => httpValidationErrorSchema)

export const getAccountSsdsNamedFiltersCountQueryResponseSchema = z.lazy(() => getAccountSsdsNamedFiltersCount200Schema)