// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/SqlValidation.ts"
);
import.meta.hot.lastModified = "1737935765335.76";
}
// REMIX HMR END

import type { DimensionMetricValidation } from './DimensionMetricValidation'

export enum SqlValidationOrigin {
  'SQL_CSV' = 'SQL_CSV',
  'FEEDBACK_SUGGESTION' = 'FEEDBACK_SUGGESTION',
}

/**
 * @description Properties of SqlValidation.
 */
export type SqlValidation = {
  /**
   * @type string, uuid4
   */
  id: string
  /**
   * @type string, uuid4
   */
  account_id: string
  /**
   * @type string, uuid4
   */
  ssds_id: string
  message_id?: string | null
  /**
   * @type string
   */
  origin: SqlValidationOrigin
  /**
   * @type string
   */
  sql: string
  /**
   * @type boolean
   */
  is_valid: boolean
  error?: string | null
  name?: string | null
  display_name?: string | null
  description?: string | null
  /**
   * @type array
   */
  extracted: DimensionMetricValidation[]
}