// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateReportSchema.ts"
);
import.meta.hot.lastModified = "1737759758958.0327";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Report comparing the stats of source dataset and its copy.
 */
export const updateReportSchema = z
  .object({
    num_new_dimension: z.number().int().min(0),
    num_new_metric: z.number().int().min(0),
    num_dimension_to_review: z.number().int().min(0),
    num_metric_to_review: z.number().int().min(0),
    num_non_verified_dimension_to_review: z.number().int().min(0),
    num_non_verified_metric_to_review: z.number().int().min(0),
    execution_accuracy: z.number().min(0).max(1),
    smoke_test_passed: z.boolean(),
  })
  .describe('Report comparing the stats of source dataset and its copy.')