// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dataAssetConnectionTypeApiSchema.ts"
);
import.meta.hot.lastModified = "1737935769208.7388";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description The connection type of the data asset.
 */
export const dataAssetConnectionTypeApiSchema = z
  .enum(['snowflake', 'bigquery', 'databricks', 'redshift', 'tableau', 'mode', 'unknown'])
  .describe('The connection type of the data asset.')