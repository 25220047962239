// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useIndexReports.ts"
);
import.meta.hot.lastModified = "1737759758685.0342";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  IndexReportsMutationRequest,
  IndexReportsMutationResponse,
  IndexReportsPathParams,
  IndexReportsQueryParams,
  IndexReports422,
} from '../types/IndexReports'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const indexReportsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/index_reports' }] as const

export type IndexReportsMutationKey = ReturnType<typeof indexReportsMutationKey>

/**
 * @description Index reports.This will index the reports in the database and search index.Note: We require the connection_id to be present in the report object so we can index the reportwith different connections.For tableau workbook, we need to have connection_id and report_id.Args:    db: Database session to perform the operation.    user_session: User session to perform the operation.    account: Account to perform the operation.    reports: Reports to index.    force: Whether to force the indexing of the reports even the report is already indexed        and this will update the index. This is useful when the content of the report is updated.        If force=False, the report will be indexed when it is not already indexed or the report        is stale.
 * @summary Index Reports
 * {@link /api/v1/accounts/:account_name/index_reports}
 */
async function indexReports(
  { accountName, data, params }: { accountName: IndexReportsPathParams['account_name']; data?: IndexReportsMutationRequest; params?: IndexReportsQueryParams },
  config: Partial<RequestConfig<IndexReportsMutationRequest>> = {},
) {
  const res = await client<IndexReportsMutationResponse, IndexReports422, IndexReportsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/index_reports`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Index reports.This will index the reports in the database and search index.Note: We require the connection_id to be present in the report object so we can index the reportwith different connections.For tableau workbook, we need to have connection_id and report_id.Args:    db: Database session to perform the operation.    user_session: User session to perform the operation.    account: Account to perform the operation.    reports: Reports to index.    force: Whether to force the indexing of the reports even the report is already indexed        and this will update the index. This is useful when the content of the report is updated.        If force=False, the report will be indexed when it is not already indexed or the report        is stale.
 * @summary Index Reports
 * {@link /api/v1/accounts/:account_name/index_reports}
 */
export function useIndexReports(
  options: {
    mutation?: UseMutationOptions<
      IndexReportsMutationResponse,
      IndexReports422,
      { accountName: IndexReportsPathParams['account_name']; data?: IndexReportsMutationRequest; params?: IndexReportsQueryParams }
    >
    client?: Partial<RequestConfig<IndexReportsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? indexReportsMutationKey()

  return useMutation<
    IndexReportsMutationResponse,
    IndexReports422,
    { accountName: IndexReportsPathParams['account_name']; data?: IndexReportsMutationRequest; params?: IndexReportsQueryParams }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return indexReports({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}