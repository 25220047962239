// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateMetricIsSensitiveImpactSchema.ts"
);
import.meta.hot.lastModified = "1737759762343.0154";
}
// REMIX HMR END

// @ts-nocheck
import { dmSensitiveKnowledgeImpactSchema } from './dmSensitiveKnowledgeImpactSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const updateMetricIsSensitiveImpactPathParamsSchema = z.object({
  account_name: z.string(),
  metric_id: z.string(),
})

export const updateMetricIsSensitiveImpactQueryParamsSchema = z.object({
  is_sensitive: z.boolean(),
})

/**
 * @description Successful Response
 */
export const updateMetricIsSensitiveImpact200Schema = z.lazy(() => dmSensitiveKnowledgeImpactSchema)

/**
 * @description Validation Error
 */
export const updateMetricIsSensitiveImpact422Schema = z.lazy(() => httpValidationErrorSchema)

export const updateMetricIsSensitiveImpactMutationResponseSchema = z.lazy(() => updateMetricIsSensitiveImpact200Schema)