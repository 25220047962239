// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/createConversationSchema.ts"
);
import.meta.hot.lastModified = "1737759760685.024";
}
// REMIX HMR END

// @ts-nocheck
import { conversationApiCreateSchema } from './conversationApiCreateSchema'
import { conversationFullSchema } from './conversationFullSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const createConversationPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const createConversation201Schema = z.lazy(() => conversationFullSchema)

/**
 * @description Validation Error
 */
export const createConversation422Schema = z.lazy(() => httpValidationErrorSchema)

export const createConversationMutationRequestSchema = z.lazy(() => conversationApiCreateSchema)

export const createConversationMutationResponseSchema = z.lazy(() => createConversation201Schema)