// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateStarSchemaDataSource.ts"
);
import.meta.hot.lastModified = "1737935769208.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateStarSchemaDataSourceMutationRequest,
  CreateStarSchemaDataSourceMutationResponse,
  CreateStarSchemaDataSourcePathParams,
  CreateStarSchemaDataSourceQueryParams,
  CreateStarSchemaDataSource422,
} from '../types/CreateStarSchemaDataSource'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createStarSchemaDataSourceMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/' }] as const

export type CreateStarSchemaDataSourceMutationKey = ReturnType<typeof createStarSchemaDataSourceMutationKey>

/**
 * @description Create Star Schema Data Source.
 * @summary Create Star Schema Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/}
 */
async function createStarSchemaDataSource(
  {
    accountName,
    data,
    params,
  }: {
    accountName: CreateStarSchemaDataSourcePathParams['account_name']
    data: CreateStarSchemaDataSourceMutationRequest
    params?: CreateStarSchemaDataSourceQueryParams
  },
  config: Partial<RequestConfig<CreateStarSchemaDataSourceMutationRequest>> = {},
) {
  const res = await client<CreateStarSchemaDataSourceMutationResponse, CreateStarSchemaDataSource422, CreateStarSchemaDataSourceMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create Star Schema Data Source.
 * @summary Create Star Schema Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/}
 */
export function useCreateStarSchemaDataSource(
  options: {
    mutation?: UseMutationOptions<
      CreateStarSchemaDataSourceMutationResponse,
      CreateStarSchemaDataSource422,
      {
        accountName: CreateStarSchemaDataSourcePathParams['account_name']
        data: CreateStarSchemaDataSourceMutationRequest
        params?: CreateStarSchemaDataSourceQueryParams
      }
    >
    client?: Partial<RequestConfig<CreateStarSchemaDataSourceMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createStarSchemaDataSourceMutationKey()

  return useMutation<
    CreateStarSchemaDataSourceMutationResponse,
    CreateStarSchemaDataSource422,
    {
      accountName: CreateStarSchemaDataSourcePathParams['account_name']
      data: CreateStarSchemaDataSourceMutationRequest
      params?: CreateStarSchemaDataSourceQueryParams
    }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return createStarSchemaDataSource({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}