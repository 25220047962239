// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/validateSqlSchema.ts"
);
import.meta.hot.lastModified = "1737759758413.0356";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { sqlWithValidationSchema } from './sqlWithValidationSchema'
import { z } from 'zod'

export const validateSqlPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

/**
 * @description Successful Response
 */
export const validateSql200Schema = z.lazy(() => sqlWithValidationSchema)

/**
 * @description Validation Error
 */
export const validateSql422Schema = z.lazy(() => httpValidationErrorSchema)

export const validateSqlMutationRequestSchema = z.lazy(() => sqlWithValidationSchema)

export const validateSqlMutationResponseSchema = z.lazy(() => validateSql200Schema)