// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/feedbackSchema.ts"
);
import.meta.hot.lastModified = "1737935765096.7612";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description CRUD Feedback Schema.
 */
export const feedbackSchema = z
  .object({
    account_id: z.string(),
    creator_id: z.string(),
    cell_id: z.string(),
    user_feedback: z.string(),
    binary_feedback: z.boolean(),
    id: z.string(),
  })
  .describe('CRUD Feedback Schema.')