// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useSearchZenDimensionValue.ts"
);
import.meta.hot.lastModified = "1737935768622.742";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { SearchZenDimensionValueQueryResponse, SearchZenDimensionValueQueryParams, SearchZenDimensionValue422 } from '../types/SearchZenDimensionValue'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const searchZenDimensionValueQueryKey = (params: SearchZenDimensionValueQueryParams) =>
  [{ url: '/api/v1/admin/search/zen_dimension_value' }, ...(params ? [params] : [])] as const

export type SearchZenDimensionValueQueryKey = ReturnType<typeof searchZenDimensionValueQueryKey>

/**
 * @description Admin endpoint to search for a Zen Dimension Value.
 * @summary Search Zen Dimension Value
 * {@link /api/v1/admin/search/zen_dimension_value}
 */
async function searchZenDimensionValue({ params }: { params: SearchZenDimensionValueQueryParams }, config: Partial<RequestConfig> = {}) {
  const res = await client<SearchZenDimensionValueQueryResponse, SearchZenDimensionValue422, unknown>({
    method: 'GET',
    url: `/api/v1/admin/search/zen_dimension_value`,
    params,
    ...config,
  })
  return res.data
}

export function searchZenDimensionValueQueryOptions({ params }: { params: SearchZenDimensionValueQueryParams }, config: Partial<RequestConfig> = {}) {
  const queryKey = searchZenDimensionValueQueryKey(params)
  return queryOptions({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return searchZenDimensionValue({ params }, config)
    },
  })
}

/**
 * @description Admin endpoint to search for a Zen Dimension Value.
 * @summary Search Zen Dimension Value
 * {@link /api/v1/admin/search/zen_dimension_value}
 */
export function useSearchZenDimensionValue<
  TData = SearchZenDimensionValueQueryResponse,
  TQueryData = SearchZenDimensionValueQueryResponse,
  TQueryKey extends QueryKey = SearchZenDimensionValueQueryKey,
>(
  { params }: { params: SearchZenDimensionValueQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<SearchZenDimensionValueQueryResponse, SearchZenDimensionValue422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? searchZenDimensionValueQueryKey(params)

  const query = useQuery({
    ...(searchZenDimensionValueQueryOptions({ params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, SearchZenDimensionValue422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}