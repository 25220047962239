// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useResendEmail.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ResendEmailMutationResponse, ResendEmailPathParams, ResendEmailQueryParams, ResendEmail422 } from '../types/ResendEmail'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const resendEmailMutationKey = () => [{ url: '/api/v1/users/preregistration/{preregistration_id}/resend-invite' }] as const

export type ResendEmailMutationKey = ReturnType<typeof resendEmailMutationKey>

/**
 * @description Re-send a preregistration welcome email.
 * @summary Resend Email
 * {@link /api/v1/users/preregistration/:preregistration_id/resend-invite}
 */
async function resendEmail(
  { preregistrationId, params }: { preregistrationId: ResendEmailPathParams['preregistration_id']; params: ResendEmailQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ResendEmailMutationResponse, ResendEmail422, unknown>({
    method: 'POST',
    url: `/api/v1/users/preregistration/${preregistrationId}/resend-invite`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Re-send a preregistration welcome email.
 * @summary Resend Email
 * {@link /api/v1/users/preregistration/:preregistration_id/resend-invite}
 */
export function useResendEmail(
  options: {
    mutation?: UseMutationOptions<
      ResendEmailMutationResponse,
      ResendEmail422,
      { preregistrationId: ResendEmailPathParams['preregistration_id']; params: ResendEmailQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? resendEmailMutationKey()

  return useMutation<
    ResendEmailMutationResponse,
    ResendEmail422,
    { preregistrationId: ResendEmailPathParams['preregistration_id']; params: ResendEmailQueryParams }
  >({
    mutationFn: async ({ preregistrationId, params }) => {
      return resendEmail({ preregistrationId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}