// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateCellChartConfig.ts"
);
import.meta.hot.lastModified = "1737935768428.7432";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateCellChartConfigMutationResponse, CreateCellChartConfigPathParams, CreateCellChartConfig422 } from '../types/CreateCellChartConfig'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createCellChartConfigMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/chart' }] as const

export type CreateCellChartConfigMutationKey = ReturnType<typeof createCellChartConfigMutationKey>

/**
 * @description Create a Bumblebee Cell Chart Config.Creating will infer the best chart configuration based on the semantic query.
 * @summary Create Cell Chart Config
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/chart}
 */
async function createCellChartConfig(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: CreateCellChartConfigPathParams['account_name']
    conversationId: CreateCellChartConfigPathParams['conversation_id']
    cellId: CreateCellChartConfigPathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<CreateCellChartConfigMutationResponse, CreateCellChartConfig422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/chart`,
    ...config,
  })
  return res.data
}

/**
 * @description Create a Bumblebee Cell Chart Config.Creating will infer the best chart configuration based on the semantic query.
 * @summary Create Cell Chart Config
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/chart}
 */
export function useCreateCellChartConfig(
  options: {
    mutation?: UseMutationOptions<
      CreateCellChartConfigMutationResponse,
      CreateCellChartConfig422,
      {
        accountName: CreateCellChartConfigPathParams['account_name']
        conversationId: CreateCellChartConfigPathParams['conversation_id']
        cellId: CreateCellChartConfigPathParams['cell_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createCellChartConfigMutationKey()

  return useMutation<
    CreateCellChartConfigMutationResponse,
    CreateCellChartConfig422,
    {
      accountName: CreateCellChartConfigPathParams['account_name']
      conversationId: CreateCellChartConfigPathParams['conversation_id']
      cellId: CreateCellChartConfigPathParams['cell_id']
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId }) => {
      return createCellChartConfig({ accountName, conversationId, cellId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}