// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsGroupedMetrics.ts"
);
import.meta.hot.lastModified = "1737759761256.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetSsdsGroupedMetricsQueryResponse,
  GetSsdsGroupedMetricsPathParams,
  GetSsdsGroupedMetricsQueryParams,
  GetSsdsGroupedMetrics422,
} from '../types/GetSsdsGroupedMetrics'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsGroupedMetricsQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsGroupedMetricsPathParams['account_name']; starSchemaDataSourceId: GetSsdsGroupedMetricsPathParams['star_schema_data_source_id'] },
  params?: GetSsdsGroupedMetricsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_metrics',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetSsdsGroupedMetricsQueryKey = ReturnType<typeof getSsdsGroupedMetricsQueryKey>

/**
 * @description Get Dimensions for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are returned.The first dimension in each list will be the one to display in the UI.If cell_id is specified, then the displayed dim is chosen to be relevantfor that specific cell, otherwise the first dimension in the group is chosen.
 * @summary Get Ssds Grouped Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_metrics}
 */
async function getSsdsGroupedMetrics(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedMetricsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedMetricsPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedMetricsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsGroupedMetricsQueryResponse, GetSsdsGroupedMetrics422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/grouped_metrics`,
    params,
    ...config,
  })
  return res.data
}

export function getSsdsGroupedMetricsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedMetricsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedMetricsPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedMetricsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsGroupedMetricsQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsGroupedMetrics({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get Dimensions for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are returned.The first dimension in each list will be the one to display in the UI.If cell_id is specified, then the displayed dim is chosen to be relevantfor that specific cell, otherwise the first dimension in the group is chosen.
 * @summary Get Ssds Grouped Metrics
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/grouped_metrics}
 */
export function useGetSsdsGroupedMetrics<
  TData = GetSsdsGroupedMetricsQueryResponse,
  TQueryData = GetSsdsGroupedMetricsQueryResponse,
  TQueryKey extends QueryKey = GetSsdsGroupedMetricsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsGroupedMetricsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsGroupedMetricsPathParams['star_schema_data_source_id']
    params?: GetSsdsGroupedMetricsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsGroupedMetricsQueryResponse, GetSsdsGroupedMetrics422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsGroupedMetricsQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getSsdsGroupedMetricsQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsGroupedMetrics422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}