// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/moonlightRunInputApiSchema.ts"
);
import.meta.hot.lastModified = "1737935768205.7444";
}
// REMIX HMR END

// @ts-nocheck
import { moonlightRunParamsSchema } from './moonlightRunParamsSchema'
import { z } from 'zod'

/**
 * @description The input of /run_moonlight.
 */
export const moonlightRunInputApiSchema = z
  .object({
    question: z.string(),
    ssds_id: z.string(),
    run_params: z.lazy(() => moonlightRunParamsSchema).default({}),
  })
  .describe('The input of /run_moonlight.')