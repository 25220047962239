// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/identifierSchema.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

// @ts-nocheck
import { identifierJoinTypeSchema } from './identifierJoinTypeSchema'
import { zenIdentifierRelationshipSchema } from './zenIdentifierRelationshipSchema'
import { z } from 'zod'

/**
 * @description Identifier class.\n\nIdentifiers are the join key columns in your data source that can be used to join\nto other data sources.\n\ne.g.\nidentifiers:\n  - name: transaction\n    type: primary\n    expr: id_transaction\n  - name: order\n    type: foreign\n    expr: id_order\n  - name: user\n    type: foreign\n    expr: SUBSTRING(id_order FROM 2)\n\nref: https://docs.transform.co/docs/metricflow/reference/data-sources/identifiers\n\ncomposite key:\nidentifiers:\n- name: user_id\ntype: foreign\nsql: ${user_id}\n- name: workspace_id\ntype: foreign\nsql: ${workspace_id}\n# This one is the composite key. It references the existing foreign keys specified\n# in the table, and tells Zenlytic that this table is unique on a key that\n# would concatenate ${workspace_id} and ${user_id}\n- name: user_workspace\ntype: primary\nidentifiers:\n    - name: workspace_id\n    - name: user_id
 */
export const identifierSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    name: z.string(),
    type: z.string(),
    expr: z.union([z.string(), z.null()]).optional(),
    data_source_id: z.union([z.string(), z.null()]).optional(),
    reference: z.union([z.string(), z.null()]).optional(),
    relationship: z.lazy(() => zenIdentifierRelationshipSchema).default('many_to_one'),
    sql_on: z.union([z.string(), z.null()]).optional(),
    join_type: z.union([z.lazy(() => identifierJoinTypeSchema), z.null()]).optional(),
    identifiers: z.union([z.array(z.string()), z.null()]).optional(),
  })
  .describe(
    'Identifier class.\n\nIdentifiers are the join key columns in your data source that can be used to join\nto other data sources.\n\ne.g.\nidentifiers:\n  - name: transaction\n    type: primary\n    expr: id_transaction\n  - name: order\n    type: foreign\n    expr: id_order\n  - name: user\n    type: foreign\n    expr: SUBSTRING(id_order FROM 2)\n\nref: https://docs.transform.co/docs/metricflow/reference/data-sources/identifiers\n\ncomposite key:\nidentifiers:\n- name: user_id\ntype: foreign\nsql: ${user_id}\n- name: workspace_id\ntype: foreign\nsql: ${workspace_id}\n# This one is the composite key. It references the existing foreign keys specified\n# in the table, and tells Zenlytic that this table is unique on a key that\n# would concatenate ${workspace_id} and ${user_id}\n- name: user_workspace\ntype: primary\nidentifiers:\n    - name: workspace_id\n    - name: user_id',
  )