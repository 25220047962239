// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCoalesceDms.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CoalesceDmsMutationRequest,
  CoalesceDmsMutationResponse,
  CoalesceDmsPathParams,
  CoalesceDmsQueryParams,
  CoalesceDms422,
} from '../types/CoalesceDms'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const coalesceDmsMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/coalesce_dms' }] as const

export type CoalesceDmsMutationKey = ReturnType<typeof coalesceDmsMutationKey>

/**
 * @description Coalesce all semantic queries in selected cells.We accept bumblebee cells as input, because we don't relate semantic queries directly to SSDSs.If none are specified, this will coalesce all existing bumblebee cells.
 * @summary Coalesce Dms
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/coalesce_dms}
 */
async function coalesceDms(
  {
    accountName,
    starSchemaDataSourceId,
    params,
    data,
  }: {
    accountName: CoalesceDmsPathParams['account_name']
    starSchemaDataSourceId: CoalesceDmsPathParams['star_schema_data_source_id']
    params: CoalesceDmsQueryParams
    data?: CoalesceDmsMutationRequest
  },
  config: Partial<RequestConfig<CoalesceDmsMutationRequest>> = {},
) {
  const res = await client<CoalesceDmsMutationResponse, CoalesceDms422, CoalesceDmsMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/coalesce_dms`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Coalesce all semantic queries in selected cells.We accept bumblebee cells as input, because we don't relate semantic queries directly to SSDSs.If none are specified, this will coalesce all existing bumblebee cells.
 * @summary Coalesce Dms
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/coalesce_dms}
 */
export function useCoalesceDms(
  options: {
    mutation?: UseMutationOptions<
      CoalesceDmsMutationResponse,
      CoalesceDms422,
      {
        accountName: CoalesceDmsPathParams['account_name']
        starSchemaDataSourceId: CoalesceDmsPathParams['star_schema_data_source_id']
        params: CoalesceDmsQueryParams
        data?: CoalesceDmsMutationRequest
      }
    >
    client?: Partial<RequestConfig<CoalesceDmsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? coalesceDmsMutationKey()

  return useMutation<
    CoalesceDmsMutationResponse,
    CoalesceDms422,
    {
      accountName: CoalesceDmsPathParams['account_name']
      starSchemaDataSourceId: CoalesceDmsPathParams['star_schema_data_source_id']
      params: CoalesceDmsQueryParams
      data?: CoalesceDmsMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params, data }) => {
      return coalesceDms({ accountName, starSchemaDataSourceId, params, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}