// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messageApiResponsePageSchema.ts"
);
import.meta.hot.lastModified = "1737935768612.742";
}
// REMIX HMR END

// @ts-nocheck
import { messageApiResponseSchema } from './messageApiResponseSchema'
import { z } from 'zod'

/**
 * @description Paginated message response.
 */
export const messageApiResponsePageSchema = z
  .object({
    data: z.array(z.lazy(() => messageApiResponseSchema)),
    total: z.number().int(),
  })
  .describe('Paginated message response.')