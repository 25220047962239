// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useAddAccountAdmins.ts"
);
import.meta.hot.lastModified = "1737935767035.7507";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  AddAccountAdminsMutationRequest,
  AddAccountAdminsMutationResponse,
  AddAccountAdminsPathParams,
  AddAccountAdmins422,
} from '../types/AddAccountAdmins'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const addAccountAdminsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/admins' }] as const

export type AddAccountAdminsMutationKey = ReturnType<typeof addAccountAdminsMutationKey>

/**
 * @description Add admin users to the account.This will add users to the account if they are not already included.
 * @summary Add Account Admins
 * {@link /api/v1/accounts/:account_name/admins}
 */
async function addAccountAdmins(
  { accountName, data }: { accountName: AddAccountAdminsPathParams['account_name']; data?: AddAccountAdminsMutationRequest },
  config: Partial<RequestConfig<AddAccountAdminsMutationRequest>> = {},
) {
  const res = await client<AddAccountAdminsMutationResponse, AddAccountAdmins422, AddAccountAdminsMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/admins`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Add admin users to the account.This will add users to the account if they are not already included.
 * @summary Add Account Admins
 * {@link /api/v1/accounts/:account_name/admins}
 */
export function useAddAccountAdmins(
  options: {
    mutation?: UseMutationOptions<
      AddAccountAdminsMutationResponse,
      AddAccountAdmins422,
      { accountName: AddAccountAdminsPathParams['account_name']; data?: AddAccountAdminsMutationRequest }
    >
    client?: Partial<RequestConfig<AddAccountAdminsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? addAccountAdminsMutationKey()

  return useMutation<
    AddAccountAdminsMutationResponse,
    AddAccountAdmins422,
    { accountName: AddAccountAdminsPathParams['account_name']; data?: AddAccountAdminsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return addAccountAdmins({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}