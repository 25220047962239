// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRefreshReports.ts"
);
import.meta.hot.lastModified = "1737935766475.7537";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RefreshReportsMutationResponse, RefreshReportsPathParams, RefreshReportsQueryParams, RefreshReports422 } from '../types/RefreshReports'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const refreshReportsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/refresh_reports' }] as const

export type RefreshReportsMutationKey = ReturnType<typeof refreshReportsMutationKey>

/**
 * @description Refresh reports.Args:    db: Database session to perform the operation.    user_session: User session to perform the operation.    account: Account to perform the operation.    force: Whether to force the refresh of the reports even the report is already indexed        and this will update the index. This is useful when the content of the report is updated.        If force=False, the report will be indexed when it is not already indexed or the report        is stale.
 * @summary Refresh Reports
 * {@link /api/v1/accounts/:account_name/refresh_reports}
 */
async function refreshReports(
  { accountName, params }: { accountName: RefreshReportsPathParams['account_name']; params?: RefreshReportsQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RefreshReportsMutationResponse, RefreshReports422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/refresh_reports`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Refresh reports.Args:    db: Database session to perform the operation.    user_session: User session to perform the operation.    account: Account to perform the operation.    force: Whether to force the refresh of the reports even the report is already indexed        and this will update the index. This is useful when the content of the report is updated.        If force=False, the report will be indexed when it is not already indexed or the report        is stale.
 * @summary Refresh Reports
 * {@link /api/v1/accounts/:account_name/refresh_reports}
 */
export function useRefreshReports(
  options: {
    mutation?: UseMutationOptions<
      RefreshReportsMutationResponse,
      RefreshReports422,
      { accountName: RefreshReportsPathParams['account_name']; params?: RefreshReportsQueryParams }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? refreshReportsMutationKey()

  return useMutation<
    RefreshReportsMutationResponse,
    RefreshReports422,
    { accountName: RefreshReportsPathParams['account_name']; params?: RefreshReportsQueryParams }
  >({
    mutationFn: async ({ accountName, params }) => {
      return refreshReports({ accountName, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}