// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getBackingServicesSchema.ts"
);
import.meta.hot.lastModified = "1737935767878.746";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

/**
 * @description Successful Response
 */
export const getBackingServices200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const getBackingServices422Schema = z.lazy(() => httpValidationErrorSchema)

export const getBackingServicesQueryResponseSchema = z.lazy(() => getBackingServices200Schema)