// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/MetricFilter.ts"
);
import.meta.hot.lastModified = "1737759757950.038";
}
// REMIX HMR END

import type { Combination } from './Combination'
import type { FilterOrigin } from './FilterOrigin'
import type { MetricFilterCondition } from './MetricFilterCondition'

export enum MetricFilterFieldType {
  'metric' = 'metric',
}

/**
 * @description Filter operating over metrics.
 */
export type MetricFilter = {
  id?: string | null
  query_id?: string | null
  name?: string | null
  /**
   * @default "ALL"
   */
  combination?: Combination
  description?: string | null
  /**
   * @default "AI"
   */
  filter_origin?: FilterOrigin
  /**
   * @default true
   * @type boolean | undefined
   */
  is_enabled?: boolean
  /**
   * @default "metric"
   * @type string | undefined
   */
  field_type?: MetricFilterFieldType
  /**
   * @type array
   */
  conditions: MetricFilterCondition[]
}