// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getConversationsSchema.ts"
);
import.meta.hot.lastModified = "1737935767404.7488";
}
// REMIX HMR END

// @ts-nocheck
import { conversationBasicSchema } from './conversationBasicSchema'
import { conversationsFilterSchema } from './conversationsFilterSchema'
import { conversationsSortSchema } from './conversationsSortSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getConversationsPathParamsSchema = z.object({
  account_name: z.string(),
})

export const getConversationsQueryParamsSchema = z
  .object({
    sort_ascending: z.boolean().default(true),
    sort_by: z.union([z.lazy(() => conversationsSortSchema), z.null()]).optional(),
    filter_by: z.union([z.lazy(() => conversationsFilterSchema), z.null()]).optional(),
    limit: z.union([z.number().int(), z.null()]).optional(),
    skip: z.number().int().default(0),
    show_moonlight: z.union([z.boolean(), z.null()]).optional(),
    show_eval: z.boolean().default(false),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getConversations200Schema = z.array(z.lazy(() => conversationBasicSchema))

/**
 * @description Validation Error
 */
export const getConversations422Schema = z.lazy(() => httpValidationErrorSchema)

export const getConversationsQueryResponseSchema = z.lazy(() => getConversations200Schema)