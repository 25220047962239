// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/knowledgeSqlSchema.ts"
);
import.meta.hot.lastModified = "1737935765803.7576";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Knowledge SQL based on unified data source of SSDS.
 */
export const knowledgeSqlSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    ssds_id: z.string(),
    semantic_query_id: z.union([z.string(), z.null()]).optional(),
    chat_message_id: z.union([z.string(), z.null()]).optional(),
    is_verified: z.boolean().default(false),
    knowledge_sql: z.string(),
    question: z.string(),
    rephrased_user_question: z.union([z.string(), z.null()]).optional(),
    question_openai_embedding: z.union([z.array(z.number()), z.null()]).optional(),
    rephrased_user_question_openai_embedding: z.union([z.array(z.number()), z.null()]).optional(),
    sample_values: z
      .object({})
      .catchall(z.array(z.union([z.number().int(), z.boolean(), z.string(), z.number()])))
      .default({}),
    is_dm_sensitive: z.boolean().default(false),
    is_user_sensitive: z.boolean().default(false),
  })
  .describe('Knowledge SQL based on unified data source of SSDS.')