// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/nodeDataPageSchema.ts"
);
import.meta.hot.lastModified = "1737935765828.7573";
}
// REMIX HMR END

// @ts-nocheck
import { columnMetadataSchema } from './columnMetadataSchema'
import { z } from 'zod'

/**
 * @description Paginated node data response.
 */
export const nodeDataPageSchema = z
  .object({
    data: z.string(),
    total: z.number().int(),
    description: z.union([z.string(), z.null()]).optional(),
    column_metadata: z.union([z.array(z.lazy(() => columnMetadataSchema)), z.null()]).optional(),
  })
  .describe('Paginated node data response.')