// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/filterOriginSchema.ts"
);
import.meta.hot.lastModified = "1737935765797.7576";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Filter Origin.
 */
export const filterOriginSchema = z.enum(['AI', 'NAMED_FILTER', 'CELL_FILTER']).describe('Filter Origin.')