// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/starSchemaDataSourceBaseSchema.ts"
);
import.meta.hot.lastModified = "1737935769005.74";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetVisibilitySchema } from './dataAssetVisibilitySchema'
import { dataSourceSchema } from './dataSourceSchema'
import { z } from 'zod'

/**
 * @description StarSchemaDataSource Base Schema.
 */
export const starSchemaDataSourceBaseSchema = z
  .object({
    sync_id: z.union([z.string(), z.null()]).optional(),
    name: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    visibility: z.lazy(() => dataAssetVisibilitySchema).default('PUBLISHED'),
    data_sources: z.array(z.lazy(() => dataSourceSchema)),
    num_data_source_created: z.number().int().default(0),
  })
  .describe('StarSchemaDataSource Base Schema.')