// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ConnectionStatus.ts"
);
import.meta.hot.lastModified = "1737759746450.0974";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumerates the possible connection statuses.
 */
export enum ConnectionStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}
