// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetConversationBasic.ts"
);
import.meta.hot.lastModified = "1737935768071.745";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetConversationBasicQueryResponse, GetConversationBasicPathParams, GetConversationBasic422 } from '../types/GetConversationBasic'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getConversationBasicQueryKey = ({
  accountName,
  conversationId,
}: {
  accountName: GetConversationBasicPathParams['account_name']
  conversationId: GetConversationBasicPathParams['conversation_id']
}) =>
  [
    { url: '/api/v1/accounts/:account_name/conversations/:conversation_id/basic', params: { accountName: accountName, conversationId: conversationId } },
  ] as const

export type GetConversationBasicQueryKey = ReturnType<typeof getConversationBasicQueryKey>

/**
 * @description Get a basic conversation.
 * @summary Get Conversation Basic
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/basic}
 */
async function getConversationBasic(
  {
    accountName,
    conversationId,
  }: { accountName: GetConversationBasicPathParams['account_name']; conversationId: GetConversationBasicPathParams['conversation_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetConversationBasicQueryResponse, GetConversationBasic422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/basic`,
    ...config,
  })
  return res.data
}

export function getConversationBasicQueryOptions(
  {
    accountName,
    conversationId,
  }: { accountName: GetConversationBasicPathParams['account_name']; conversationId: GetConversationBasicPathParams['conversation_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getConversationBasicQueryKey({ accountName, conversationId })
  return queryOptions({
    enabled: !!(accountName && conversationId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getConversationBasic({ accountName, conversationId }, config)
    },
  })
}

/**
 * @description Get a basic conversation.
 * @summary Get Conversation Basic
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/basic}
 */
export function useGetConversationBasic<
  TData = GetConversationBasicQueryResponse,
  TQueryData = GetConversationBasicQueryResponse,
  TQueryKey extends QueryKey = GetConversationBasicQueryKey,
>(
  {
    accountName,
    conversationId,
  }: { accountName: GetConversationBasicPathParams['account_name']; conversationId: GetConversationBasicPathParams['conversation_id'] },
  options: {
    query?: Partial<QueryObserverOptions<GetConversationBasicQueryResponse, GetConversationBasic422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getConversationBasicQueryKey({ accountName, conversationId })

  const query = useQuery({
    ...(getConversationBasicQueryOptions({ accountName, conversationId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetConversationBasic422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}