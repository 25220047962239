// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/providerSchema.ts"
);
import.meta.hot.lastModified = "1737759757945.038";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const providerSchema = z.enum(['okta', 'google'])