// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateEnterprise.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateEnterpriseMutationRequest, CreateEnterpriseMutationResponse, CreateEnterprise422 } from '../types/CreateEnterprise'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createEnterpriseMutationKey = () => [{ url: '/api/v1/enterprises/' }] as const

export type CreateEnterpriseMutationKey = ReturnType<typeof createEnterpriseMutationKey>

/**
 * @description Create new enterprise.
 * @summary Create Enterprise
 * {@link /api/v1/enterprises/}
 */
async function createEnterprise({ data }: { data: CreateEnterpriseMutationRequest }, config: Partial<RequestConfig<CreateEnterpriseMutationRequest>> = {}) {
  const res = await client<CreateEnterpriseMutationResponse, CreateEnterprise422, CreateEnterpriseMutationRequest>({
    method: 'POST',
    url: `/api/v1/enterprises/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create new enterprise.
 * @summary Create Enterprise
 * {@link /api/v1/enterprises/}
 */
export function useCreateEnterprise(
  options: {
    mutation?: UseMutationOptions<CreateEnterpriseMutationResponse, CreateEnterprise422, { data: CreateEnterpriseMutationRequest }>
    client?: Partial<RequestConfig<CreateEnterpriseMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createEnterpriseMutationKey()

  return useMutation<CreateEnterpriseMutationResponse, CreateEnterprise422, { data: CreateEnterpriseMutationRequest }>({
    mutationFn: async ({ data }) => {
      return createEnterprise({ data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}