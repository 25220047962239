// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chartSeriesSchema.ts"
);
import.meta.hot.lastModified = "1737759758412.0356";
}
// REMIX HMR END

// @ts-nocheck
import { chartTypeSchema } from './chartTypeSchema'
import { z } from 'zod'

/**
 * @description Properties of Chart Series.\n\nFields:\n    - type: ChartType, non-Empty\n    - source: List of lists describing the chart data. First row is the column names\n        and the rest are the data.\n    - unique_group_values: List of unique group values if a group column is selected.
 */
export const chartSeriesSchema = z
  .object({
    type: z.lazy(() => chartTypeSchema),
    source: z.array(z.array(z.unknown())),
    unique_group_values: z.array(z.unknown()),
  })
  .describe(
    'Properties of Chart Series.\n\nFields:\n    - type: ChartType, non-Empty\n    - source: List of lists describing the chart data. First row is the column names\n        and the rest are the data.\n    - unique_group_values: List of unique group values if a group column is selected.',
  )