// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getSuggestionsForAccountSchema.ts"
);
import.meta.hot.lastModified = "1737759761850.0178";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { suggestionApiSchema } from './suggestionApiSchema'
import { z } from 'zod'

export const getSuggestionsForAccountPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

export const getSuggestionsForAccountQueryParamsSchema = z
  .object({
    limit: z.number().int().min(1).max(100).default(5),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getSuggestionsForAccount200Schema = z.array(z.lazy(() => suggestionApiSchema))

/**
 * @description Validation Error
 */
export const getSuggestionsForAccount422Schema = z.lazy(() => httpValidationErrorSchema)

export const getSuggestionsForAccountQueryResponseSchema = z.lazy(() => getSuggestionsForAccount200Schema)