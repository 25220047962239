// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/TasksV2Service.ts"
);
import.meta.hot.lastModified = "1737935753879.823";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaskAPI } from '../models/TaskAPI';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TasksV2Service {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read Tasks By Ids V2
   * Get specific task(s) by id(s).
   * @param accountName The name of the organization or account.
   * @param taskId A list of task ids to retrieve.
   * @returns TaskAPI Successful Response
   * @throws ApiError
   */
  public readTasksByIdsV2(
    accountName: string,
    taskId?: Array<string>,
  ): CancelablePromise<Array<TaskAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/orgs/{account_name}/tasks/ids',
      path: {
        'account_name': accountName,
      },
      query: {
        'task_id': taskId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
