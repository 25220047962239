// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadPostgresConnectionSchemas.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadPostgresConnectionSchemasQueryResponse,
  ReadPostgresConnectionSchemasPathParams,
  ReadPostgresConnectionSchemas422,
} from '../types/ReadPostgresConnectionSchemas'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readPostgresConnectionSchemasQueryKey = ({
  accountName,
  postgresConnectionId,
}: {
  accountName: ReadPostgresConnectionSchemasPathParams['account_name']
  postgresConnectionId: ReadPostgresConnectionSchemasPathParams['postgres_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/:postgres_connection_id/schemas',
      params: { accountName: accountName, postgresConnectionId: postgresConnectionId },
    },
  ] as const

export type ReadPostgresConnectionSchemasQueryKey = ReturnType<typeof readPostgresConnectionSchemasQueryKey>

/**
 * @description Read Postgres schemas.
 * @summary Read Postgres Connection Schemas
 * {@link /api/v1/accounts/:account_name/connections/:postgres_connection_id/schemas}
 */
async function readPostgresConnectionSchemas(
  {
    accountName,
    postgresConnectionId,
  }: {
    accountName: ReadPostgresConnectionSchemasPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionSchemasPathParams['postgres_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadPostgresConnectionSchemasQueryResponse, ReadPostgresConnectionSchemas422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/${postgresConnectionId}/schemas`,
    ...config,
  })
  return res.data
}

export function readPostgresConnectionSchemasQueryOptions(
  {
    accountName,
    postgresConnectionId,
  }: {
    accountName: ReadPostgresConnectionSchemasPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionSchemasPathParams['postgres_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readPostgresConnectionSchemasQueryKey({ accountName, postgresConnectionId })
  return queryOptions({
    enabled: !!(accountName && postgresConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readPostgresConnectionSchemas({ accountName, postgresConnectionId }, config)
    },
  })
}

/**
 * @description Read Postgres schemas.
 * @summary Read Postgres Connection Schemas
 * {@link /api/v1/accounts/:account_name/connections/:postgres_connection_id/schemas}
 */
export function useReadPostgresConnectionSchemas<
  TData = ReadPostgresConnectionSchemasQueryResponse,
  TQueryData = ReadPostgresConnectionSchemasQueryResponse,
  TQueryKey extends QueryKey = ReadPostgresConnectionSchemasQueryKey,
>(
  {
    accountName,
    postgresConnectionId,
  }: {
    accountName: ReadPostgresConnectionSchemasPathParams['account_name']
    postgresConnectionId: ReadPostgresConnectionSchemasPathParams['postgres_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadPostgresConnectionSchemasQueryResponse, ReadPostgresConnectionSchemas422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readPostgresConnectionSchemasQueryKey({ accountName, postgresConnectionId })

  const query = useQuery({
    ...(readPostgresConnectionSchemasQueryOptions({ accountName, postgresConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadPostgresConnectionSchemas422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}