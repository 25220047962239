// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useBulkCreateConversations.ts"
);
import.meta.hot.lastModified = "1737935768621.742";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  BulkCreateConversationsMutationRequest,
  BulkCreateConversationsMutationResponse,
  BulkCreateConversationsPathParams,
  BulkCreateConversations422,
} from '../types/BulkCreateConversations'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const bulkCreateConversationsMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/upload_conversations' }] as const

export type BulkCreateConversationsMutationKey = ReturnType<typeof bulkCreateConversationsMutationKey>

/**
 * @description Upload conversations to an account.Attempts to create with the same user, but will default to the adminif the user doesn't belong to the account.
 * @summary Bulk Create Conversations
 * {@link /api/v1/accounts/:account_name/upload_conversations}
 */
async function bulkCreateConversations(
  { accountName, data }: { accountName: BulkCreateConversationsPathParams['account_name']; data?: BulkCreateConversationsMutationRequest },
  config: Partial<RequestConfig<BulkCreateConversationsMutationRequest>> = {},
) {
  const res = await client<BulkCreateConversationsMutationResponse, BulkCreateConversations422, BulkCreateConversationsMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/upload_conversations`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Upload conversations to an account.Attempts to create with the same user, but will default to the adminif the user doesn't belong to the account.
 * @summary Bulk Create Conversations
 * {@link /api/v1/accounts/:account_name/upload_conversations}
 */
export function useBulkCreateConversations(
  options: {
    mutation?: UseMutationOptions<
      BulkCreateConversationsMutationResponse,
      BulkCreateConversations422,
      { accountName: BulkCreateConversationsPathParams['account_name']; data?: BulkCreateConversationsMutationRequest }
    >
    client?: Partial<RequestConfig<BulkCreateConversationsMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? bulkCreateConversationsMutationKey()

  return useMutation<
    BulkCreateConversationsMutationResponse,
    BulkCreateConversations422,
    { accountName: BulkCreateConversationsPathParams['account_name']; data?: BulkCreateConversationsMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return bulkCreateConversations({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}