// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/validateSnowflakeConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759762081.0166";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { snowflakeConnectionSchema } from './snowflakeConnectionSchema'
import { z } from 'zod'

export const validateSnowflakeConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  snowflake_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const validateSnowflakeConnection200Schema = z.lazy(() => snowflakeConnectionSchema)

/**
 * @description Validation Error
 */
export const validateSnowflakeConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const validateSnowflakeConnectionMutationResponseSchema = z.lazy(() => validateSnowflakeConnection200Schema)