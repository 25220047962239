// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadSnowflakeConnectionWarehouses.ts"
);
import.meta.hot.lastModified = "1737759762545.0144";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadSnowflakeConnectionWarehousesQueryResponse,
  ReadSnowflakeConnectionWarehousesPathParams,
  ReadSnowflakeConnectionWarehouses422,
} from '../types/ReadSnowflakeConnectionWarehouses'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readSnowflakeConnectionWarehousesQueryKey = ({
  accountName,
  snowflakeConnectionId,
}: {
  accountName: ReadSnowflakeConnectionWarehousesPathParams['account_name']
  snowflakeConnectionId: ReadSnowflakeConnectionWarehousesPathParams['snowflake_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/warehouses',
      params: { accountName: accountName, snowflakeConnectionId: snowflakeConnectionId },
    },
  ] as const

export type ReadSnowflakeConnectionWarehousesQueryKey = ReturnType<typeof readSnowflakeConnectionWarehousesQueryKey>

/**
 * @description Read Snowflake warehouses.
 * @summary Read Snowflake Connection Warehouses
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/warehouses}
 */
async function readSnowflakeConnectionWarehouses(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionWarehousesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionWarehousesPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadSnowflakeConnectionWarehousesQueryResponse, ReadSnowflakeConnectionWarehouses422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/${snowflakeConnectionId}/warehouses`,
    ...config,
  })
  return res.data
}

export function readSnowflakeConnectionWarehousesQueryOptions(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionWarehousesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionWarehousesPathParams['snowflake_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readSnowflakeConnectionWarehousesQueryKey({ accountName, snowflakeConnectionId })
  return queryOptions({
    enabled: !!(accountName && snowflakeConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readSnowflakeConnectionWarehouses({ accountName, snowflakeConnectionId }, config)
    },
  })
}

/**
 * @description Read Snowflake warehouses.
 * @summary Read Snowflake Connection Warehouses
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/warehouses}
 */
export function useReadSnowflakeConnectionWarehouses<
  TData = ReadSnowflakeConnectionWarehousesQueryResponse,
  TQueryData = ReadSnowflakeConnectionWarehousesQueryResponse,
  TQueryKey extends QueryKey = ReadSnowflakeConnectionWarehousesQueryKey,
>(
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionWarehousesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionWarehousesPathParams['snowflake_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadSnowflakeConnectionWarehousesQueryResponse, ReadSnowflakeConnectionWarehouses422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readSnowflakeConnectionWarehousesQueryKey({ accountName, snowflakeConnectionId })

  const query = useQuery({
    ...(readSnowflakeConnectionWarehousesQueryOptions({ accountName, snowflakeConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadSnowflakeConnectionWarehouses422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}