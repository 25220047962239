// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readSnowflakeConnectionRegionSchema.ts"
);
import.meta.hot.lastModified = "1737759762338.0154";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readSnowflakeConnectionRegionPathParamsSchema = z.object({
  account_name: z.string(),
  snowflake_connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const readSnowflakeConnectionRegion200Schema = z.string()

/**
 * @description Validation Error
 */
export const readSnowflakeConnectionRegion422Schema = z.lazy(() => httpValidationErrorSchema)

export const readSnowflakeConnectionRegionQueryResponseSchema = z.lazy(() => readSnowflakeConnectionRegion200Schema)