// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/suggestDatasetFixesSchema.ts"
);
import.meta.hot.lastModified = "1737935768070.745";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { sqlValidationSchema } from './sqlValidationSchema'
import { z } from 'zod'

export const suggestDatasetFixesPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

export const suggestDatasetFixesQueryParamsSchema = z
  .object({
    limit: z.number().int().default(20),
    offset: z.number().int().default(0),
  })
  .optional()

/**
 * @description Successful Response
 */
export const suggestDatasetFixes200Schema = z.array(z.lazy(() => sqlValidationSchema))

/**
 * @description Validation Error
 */
export const suggestDatasetFixes422Schema = z.lazy(() => httpValidationErrorSchema)

export const suggestDatasetFixesMutationRequestSchema = z.union([z.array(z.string()), z.null()])

export const suggestDatasetFixesMutationResponseSchema = z.lazy(() => suggestDatasetFixes200Schema)