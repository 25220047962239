// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/retrieveSchema.ts"
);
import.meta.hot.lastModified = "1737935765100.7612";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { retrievalParamsSchema } from './retrievalParamsSchema'
import { z } from 'zod'

export const retrievePathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const retrieveQueryParamsSchema = z.object({
  question: z.string(),
})

/**
 * @description Successful Response
 */
export const retrieve200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const retrieve422Schema = z.lazy(() => httpValidationErrorSchema)

export const retrieveMutationRequestSchema = z.union([z.lazy(() => retrievalParamsSchema), z.null()])

export const retrieveMutationResponseSchema = z.lazy(() => retrieve200Schema)