// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/callbackSchema.ts"
);
import.meta.hot.lastModified = "1737759757948.038";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Successful Response
 */
export const callback200Schema = z.unknown()

export const callbackQueryResponseSchema = z.lazy(() => callback200Schema)