// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useLoginSessionOauth.ts"
);
import.meta.hot.lastModified = "1737759760212.0264";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { LoginSessionOauthQueryResponse, LoginSessionOauthQueryParams, LoginSessionOauth422 } from '../types/LoginSessionOauth'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const loginSessionOauthQueryKey = (params: LoginSessionOauthQueryParams) =>
  [{ url: '/api/v1/login/session/oauth' }, ...(params ? [params] : [])] as const

export type LoginSessionOauthQueryKey = ReturnType<typeof loginSessionOauthQueryKey>

/**
 * @description Login via OAuth2.0 authorization code flow with PKCE.The currently supported OAuth2.0 providers are:- Okta- Google
 * @summary Login Session Oauth
 * {@link /api/v1/login/session/oauth}
 */
async function loginSessionOauth({ params }: { params: LoginSessionOauthQueryParams }, config: Partial<RequestConfig> = {}) {
  const res = await client<LoginSessionOauthQueryResponse, LoginSessionOauth422, unknown>({
    method: 'GET',
    url: `/api/v1/login/session/oauth`,
    params,
    ...config,
  })
  return res.data
}

export function loginSessionOauthQueryOptions({ params }: { params: LoginSessionOauthQueryParams }, config: Partial<RequestConfig> = {}) {
  const queryKey = loginSessionOauthQueryKey(params)
  return queryOptions({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return loginSessionOauth({ params }, config)
    },
  })
}

/**
 * @description Login via OAuth2.0 authorization code flow with PKCE.The currently supported OAuth2.0 providers are:- Okta- Google
 * @summary Login Session Oauth
 * {@link /api/v1/login/session/oauth}
 */
export function useLoginSessionOauth<
  TData = LoginSessionOauthQueryResponse,
  TQueryData = LoginSessionOauthQueryResponse,
  TQueryKey extends QueryKey = LoginSessionOauthQueryKey,
>(
  { params }: { params: LoginSessionOauthQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<LoginSessionOauthQueryResponse, LoginSessionOauth422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? loginSessionOauthQueryKey(params)

  const query = useQuery({
    ...(loginSessionOauthQueryOptions({ params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, LoginSessionOauth422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}