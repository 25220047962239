// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ConversationsSort.ts"
);
import.meta.hot.lastModified = "1737935766269.755";
}
// REMIX HMR END

export enum ConversationsSort {
  'created_at' = 'created_at',
  'last_modified_at' = 'last_modified_at',
  'creator_id' = 'creator_id',
  'name' = 'name',
}