// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/adminMessageApiResponsePageSchema.ts"
);
import.meta.hot.lastModified = "1737935769212.7388";
}
// REMIX HMR END

// @ts-nocheck
import { adminMessageApiResponseSchema } from './adminMessageApiResponseSchema'
import { z } from 'zod'

/**
 * @description Paginated admin message response.
 */
export const adminMessageApiResponsePageSchema = z
  .object({
    data: z.array(z.lazy(() => adminMessageApiResponseSchema)),
    total: z.number().int(),
  })
  .describe('Paginated admin message response.')