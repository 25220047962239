// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dimensionFilterSchema.ts"
);
import.meta.hot.lastModified = "1737935766766.7522";
}
// REMIX HMR END

// @ts-nocheck
import { combinationSchema } from './combinationSchema'
import { dimensionFilterConditionSchema } from './dimensionFilterConditionSchema'
import { filterOriginSchema } from './filterOriginSchema'
import { z } from 'zod'

/**
 * @description Filter operating over dimensions.
 */
export const dimensionFilterSchema = z
  .object({
    id: z.union([z.string(), z.null()]).optional(),
    query_id: z.union([z.string(), z.null()]).optional(),
    name: z.union([z.string(), z.null()]).optional(),
    combination: z.lazy(() => combinationSchema).default('ALL'),
    description: z.union([z.string(), z.null()]).optional(),
    filter_origin: z.lazy(() => filterOriginSchema).default('AI'),
    is_enabled: z.boolean().default(true),
    field_type: z.enum(['dimension']).default('dimension'),
    is_result_filter: z.boolean().default(false),
    conditions: z.array(z.lazy(() => dimensionFilterConditionSchema)),
  })
  .describe('Filter operating over dimensions.')