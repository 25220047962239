// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getAdminFeedbackSchema.ts"
);
import.meta.hot.lastModified = "1737759760211.0264";
}
// REMIX HMR END

// @ts-nocheck
import { adminMessageApiResponsePageSchema } from './adminMessageApiResponsePageSchema'
import { bodyGetAdminFeedbackApiV3OrgsAccountNameMessagesAdminFeedbackPostSchema } from './bodyGetAdminFeedbackApiV3OrgsAccountNameMessagesAdminFeedbackPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getAdminFeedbackPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
})

export const getAdminFeedbackQueryParamsSchema = z
  .object({
    limit: z.number().int().default(20),
    offset: z.number().int().default(0),
    before_timestamp: z.union([z.string().datetime(), z.null()]).optional(),
    after_timestamp: z.union([z.string().datetime(), z.null()]).optional(),
    is_admin_feedback_incorporated: z.union([z.boolean(), z.null()]).optional(),
    search: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getAdminFeedback200Schema = z.lazy(() => adminMessageApiResponsePageSchema)

/**
 * @description Validation Error
 */
export const getAdminFeedback422Schema = z.lazy(() => httpValidationErrorSchema)

export const getAdminFeedbackMutationRequestSchema = z.lazy(() => bodyGetAdminFeedbackApiV3OrgsAccountNameMessagesAdminFeedbackPostSchema)

export const getAdminFeedbackMutationResponseSchema = z.lazy(() => getAdminFeedback200Schema)