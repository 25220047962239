// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tableauConnectionCreateSchema.ts"
);
import.meta.hot.lastModified = "1737759761631.019";
}
// REMIX HMR END

// @ts-nocheck
import { connectionStatusSchema } from './connectionStatusSchema'
import { z } from 'zod'

/**
 * @description Properties to receive via API on creation.
 */
export const tableauConnectionCreateSchema = z
  .object({
    name: z.string(),
    account_id: z.union([z.string(), z.null()]).optional(),
    status: z.union([z.lazy(() => connectionStatusSchema), z.null()]).optional(),
    status_at: z.union([z.string().datetime(), z.null()]).optional(),
    user_name: z.string(),
    secret_id: z.string(),
    client_id: z.string(),
    site_id: z.string(),
    server_address: z.string().url().min(1).max(2083),
    secret_value: z.string(),
  })
  .describe('Properties to receive via API on creation.')