// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetCellPromptlog.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetCellPromptlogQueryResponse, GetCellPromptlogPathParams, GetCellPromptlogQueryParams, GetCellPromptlog422 } from '../types/GetCellPromptlog'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getCellPromptlogQueryKey = (
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: GetCellPromptlogPathParams['account_name']
    conversationId: GetCellPromptlogPathParams['conversation_id']
    cellId: GetCellPromptlogPathParams['cell_id']
  },
  params?: GetCellPromptlogQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/promptlog',
      params: { accountName: accountName, conversationId: conversationId, cellId: cellId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetCellPromptlogQueryKey = ReturnType<typeof getCellPromptlogQueryKey>

/**
 * @description Admin endpoint to the promptlog history of a bumblebee cell.Component can be query to filter to that component.Component of ALL will return unified promptlogs for an entire run(this is not backwards compatible)Component of None will return any component ordered by time and not unified.Returns:    This returns a JSON list of PromptLogs for a cell in reverse chronological order, by    default only the most recent. The component filters to return logs for everything or query.
 * @summary Get Cell Promptlog
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/promptlog}
 */
async function getCellPromptlog(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: GetCellPromptlogPathParams['account_name']
    conversationId: GetCellPromptlogPathParams['conversation_id']
    cellId: GetCellPromptlogPathParams['cell_id']
    params?: GetCellPromptlogQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetCellPromptlogQueryResponse, GetCellPromptlog422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/promptlog`,
    params,
    ...config,
  })
  return res.data
}

export function getCellPromptlogQueryOptions(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: GetCellPromptlogPathParams['account_name']
    conversationId: GetCellPromptlogPathParams['conversation_id']
    cellId: GetCellPromptlogPathParams['cell_id']
    params?: GetCellPromptlogQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getCellPromptlogQueryKey({ accountName, conversationId, cellId }, params)
  return queryOptions({
    enabled: !!(accountName && conversationId && cellId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getCellPromptlog({ accountName, conversationId, cellId, params }, config)
    },
  })
}

/**
 * @description Admin endpoint to the promptlog history of a bumblebee cell.Component can be query to filter to that component.Component of ALL will return unified promptlogs for an entire run(this is not backwards compatible)Component of None will return any component ordered by time and not unified.Returns:    This returns a JSON list of PromptLogs for a cell in reverse chronological order, by    default only the most recent. The component filters to return logs for everything or query.
 * @summary Get Cell Promptlog
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/promptlog}
 */
export function useGetCellPromptlog<
  TData = GetCellPromptlogQueryResponse,
  TQueryData = GetCellPromptlogQueryResponse,
  TQueryKey extends QueryKey = GetCellPromptlogQueryKey,
>(
  {
    accountName,
    conversationId,
    cellId,
    params,
  }: {
    accountName: GetCellPromptlogPathParams['account_name']
    conversationId: GetCellPromptlogPathParams['conversation_id']
    cellId: GetCellPromptlogPathParams['cell_id']
    params?: GetCellPromptlogQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetCellPromptlogQueryResponse, GetCellPromptlog422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getCellPromptlogQueryKey({ accountName, conversationId, cellId }, params)

  const query = useQuery({
    ...(getCellPromptlogQueryOptions({ accountName, conversationId, cellId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetCellPromptlog422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}