// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRunEvalChats.ts"
);
import.meta.hot.lastModified = "1737935765789.7576";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RunEvalChatsMutationResponse, RunEvalChatsPathParams, RunEvalChatsQueryParams, RunEvalChats422 } from '../types/RunEvalChats'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const runEvalChatsMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/chat/{star_schema_data_source_id}/eval_chats' }] as const

export type RunEvalChatsMutationKey = ReturnType<typeof runEvalChatsMutationKey>

/**
 * @description Run eval on reference chats.
 * @summary Run Eval Chats
 * {@link /api/v3/orgs/:account_name/chat/:star_schema_data_source_id/eval_chats}
 */
async function runEvalChats(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: RunEvalChatsPathParams['account_name']
    starSchemaDataSourceId: RunEvalChatsPathParams['star_schema_data_source_id']
    params?: RunEvalChatsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RunEvalChatsMutationResponse, RunEvalChats422, unknown>({
    method: 'POST',
    url: `/api/v3/orgs/${accountName}/chat/${starSchemaDataSourceId}/eval_chats`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Run eval on reference chats.
 * @summary Run Eval Chats
 * {@link /api/v3/orgs/:account_name/chat/:star_schema_data_source_id/eval_chats}
 */
export function useRunEvalChats(
  options: {
    mutation?: UseMutationOptions<
      RunEvalChatsMutationResponse,
      RunEvalChats422,
      {
        accountName: RunEvalChatsPathParams['account_name']
        starSchemaDataSourceId: RunEvalChatsPathParams['star_schema_data_source_id']
        params?: RunEvalChatsQueryParams
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? runEvalChatsMutationKey()

  return useMutation<
    RunEvalChatsMutationResponse,
    RunEvalChats422,
    {
      accountName: RunEvalChatsPathParams['account_name']
      starSchemaDataSourceId: RunEvalChatsPathParams['star_schema_data_source_id']
      params?: RunEvalChatsQueryParams
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params }) => {
      return runEvalChats({ accountName, starSchemaDataSourceId, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}