// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateDimension.ts"
);
import.meta.hot.lastModified = "1737759759782.0286";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateDimensionMutationRequest, CreateDimensionMutationResponse, CreateDimensionPathParams, CreateDimension422 } from '../types/CreateDimension'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createDimensionMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dimensions/' }] as const

export type CreateDimensionMutationKey = ReturnType<typeof createDimensionMutationKey>

/**
 * @description Create a dimension.
 * @summary Create Dimension
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/dimensions/}
 */
async function createDimension(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: CreateDimensionPathParams['account_name']
    starSchemaDataSourceId: CreateDimensionPathParams['star_schema_data_source_id']
    data: CreateDimensionMutationRequest
  },
  config: Partial<RequestConfig<CreateDimensionMutationRequest>> = {},
) {
  const res = await client<CreateDimensionMutationResponse, CreateDimension422, CreateDimensionMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/dimensions/`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a dimension.
 * @summary Create Dimension
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/dimensions/}
 */
export function useCreateDimension(
  options: {
    mutation?: UseMutationOptions<
      CreateDimensionMutationResponse,
      CreateDimension422,
      {
        accountName: CreateDimensionPathParams['account_name']
        starSchemaDataSourceId: CreateDimensionPathParams['star_schema_data_source_id']
        data: CreateDimensionMutationRequest
      }
    >
    client?: Partial<RequestConfig<CreateDimensionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createDimensionMutationKey()

  return useMutation<
    CreateDimensionMutationResponse,
    CreateDimension422,
    {
      accountName: CreateDimensionPathParams['account_name']
      starSchemaDataSourceId: CreateDimensionPathParams['star_schema_data_source_id']
      data: CreateDimensionMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return createDimension({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}