// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/settingValueUnionBoolIntStrSchema.ts"
);
import.meta.hot.lastModified = "1737935769216.7388";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const settingValueUnionBoolIntStrSchema = z.object({
  value: z.union([z.boolean(), z.number().int(), z.string(), z.null()]),
})