// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyCreateRemappedSsdsApiV1AccountsAccountNameStarSchemaDataSourceRemapSsdsPostSchema.ts"
);
import.meta.hot.lastModified = "1737935769735.7358";
}
// REMIX HMR END

// @ts-nocheck
import { remappedQueryJoinInfoSchema } from './remappedQueryJoinInfoSchema'
import { z } from 'zod'

export const bodyCreateRemappedSsdsApiV1AccountsAccountNameStarSchemaDataSourceRemapSsdsPostSchema = z.object({
  inputs: z.array(z.object({}).catchall(z.string())),
  database_schema: z.object({}).catchall(z.object({}).catchall(z.string())),
  manual_joins: z.array(z.lazy(() => remappedQueryJoinInfoSchema)).optional(),
})