// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/chatApiResponseSchema.ts"
);
import.meta.hot.lastModified = "1737935766766.7522";
}
// REMIX HMR END

// @ts-nocheck
import { userBasicApiResponseSchema } from './userBasicApiResponseSchema'
import { z } from 'zod'

/**
 * @description Response object containing information about a single chat.
 */
export const chatApiResponseSchema = z
  .object({
    id: z.string().describe('The ID of the chat.'),
    name: z.union([z.string(), z.null()]).describe('The name of the chat.'),
    is_feedback_chat: z.boolean().describe('Whether the chat is a feedback chat or not.'),
    created_at: z.string().datetime().describe('The time the chat was created.'),
    last_modified_at: z.string().datetime().describe('The time the chat was last modified. This includes adding messages.'),
    creator: z.lazy(() => userBasicApiResponseSchema).describe('The user who created the chat.'),
  })
  .describe('Response object containing information about a single chat.')