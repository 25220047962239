// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateUser.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { CreateUserMutationRequest, CreateUserMutationResponse, CreateUser422 } from '../types/CreateUser'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createUserMutationKey = () => [{ url: '/api/v1/users/' }] as const

export type CreateUserMutationKey = ReturnType<typeof createUserMutationKey>

/**
 * @description Create new user.
 * @summary Create User
 * {@link /api/v1/users/}
 */
async function createUser({ data }: { data: CreateUserMutationRequest }, config: Partial<RequestConfig<CreateUserMutationRequest>> = {}) {
  const res = await client<CreateUserMutationResponse, CreateUser422, CreateUserMutationRequest>({ method: 'POST', url: `/api/v1/users/`, data, ...config })
  return res.data
}

/**
 * @description Create new user.
 * @summary Create User
 * {@link /api/v1/users/}
 */
export function useCreateUser(
  options: {
    mutation?: UseMutationOptions<CreateUserMutationResponse, CreateUser422, { data: CreateUserMutationRequest }>
    client?: Partial<RequestConfig<CreateUserMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createUserMutationKey()

  return useMutation<CreateUserMutationResponse, CreateUser422, { data: CreateUserMutationRequest }>({
    mutationFn: async ({ data }) => {
      return createUser({ data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}