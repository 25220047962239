// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetPendingAssistanceCount.ts"
);
import.meta.hot.lastModified = "1737759761852.0178";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetPendingAssistanceCountQueryResponse,
  GetPendingAssistanceCountPathParams,
  GetPendingAssistanceCountQueryParams,
  GetPendingAssistanceCount422,
} from '../types/GetPendingAssistanceCount'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getPendingAssistanceCountQueryKey = (
  { accountName }: { accountName: GetPendingAssistanceCountPathParams['account_name'] },
  params?: GetPendingAssistanceCountQueryParams,
) => [{ url: '/api/v1/accounts/:account_name/pending_assistance_count', params: { accountName: accountName } }, ...(params ? [params] : [])] as const

export type GetPendingAssistanceCountQueryKey = ReturnType<typeof getPendingAssistanceCountQueryKey>

/**
 * @description Get the count of all pending assistance requests for an account, grouped by ssds_id.Pending assistance requests, previously known as Bad responses, are cellsthat users have requested a data admin inspect and verify.This endpoint is used in the application to show the admin how manycells are pending assistance.
 * @summary Get Pending Assistance Count
 * {@link /api/v1/accounts/:account_name/pending_assistance_count}
 */
async function getPendingAssistanceCount(
  { accountName, params }: { accountName: GetPendingAssistanceCountPathParams['account_name']; params?: GetPendingAssistanceCountQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetPendingAssistanceCountQueryResponse, GetPendingAssistanceCount422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/pending_assistance_count`,
    params,
    ...config,
  })
  return res.data
}

export function getPendingAssistanceCountQueryOptions(
  { accountName, params }: { accountName: GetPendingAssistanceCountPathParams['account_name']; params?: GetPendingAssistanceCountQueryParams },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getPendingAssistanceCountQueryKey({ accountName }, params)
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getPendingAssistanceCount({ accountName, params }, config)
    },
  })
}

/**
 * @description Get the count of all pending assistance requests for an account, grouped by ssds_id.Pending assistance requests, previously known as Bad responses, are cellsthat users have requested a data admin inspect and verify.This endpoint is used in the application to show the admin how manycells are pending assistance.
 * @summary Get Pending Assistance Count
 * {@link /api/v1/accounts/:account_name/pending_assistance_count}
 */
export function useGetPendingAssistanceCount<
  TData = GetPendingAssistanceCountQueryResponse,
  TQueryData = GetPendingAssistanceCountQueryResponse,
  TQueryKey extends QueryKey = GetPendingAssistanceCountQueryKey,
>(
  { accountName, params }: { accountName: GetPendingAssistanceCountPathParams['account_name']; params?: GetPendingAssistanceCountQueryParams },
  options: {
    query?: Partial<QueryObserverOptions<GetPendingAssistanceCountQueryResponse, GetPendingAssistanceCount422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getPendingAssistanceCountQueryKey({ accountName }, params)

  const query = useQuery({
    ...(getPendingAssistanceCountQueryOptions({ accountName, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetPendingAssistanceCount422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}