// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messageApiSendSchema.ts"
);
import.meta.hot.lastModified = "1737759759420.0305";
}
// REMIX HMR END

// @ts-nocheck
import { messagePayloadTypeSchema } from './messagePayloadTypeSchema'
import { z } from 'zod'

/**
 * @description Payload for sending a text message to the API\n\nAttributes:\n    message_text: The message that the user sent.\n    ssds_id: The ID of the SSDS (star schema data source a.k.a. dataset) that the\n        message is asking about.\n    accepted_payload_types: The payload types that can be returned in the conversation.
 */
export const messageApiSendSchema = z
  .object({
    message_text: z.string(),
    ssds_id: z.string(),
    accepted_payload_types: z.union([z.array(z.lazy(() => messagePayloadTypeSchema)), z.null()]).optional(),
  })
  .describe(
    'Payload for sending a text message to the API\n\nAttributes:\n    message_text: The message that the user sent.\n    ssds_id: The ID of the SSDS (star schema data source a.k.a. dataset) that the\n        message is asking about.\n    accepted_payload_types: The payload types that can be returned in the conversation.',
  )