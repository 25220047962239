// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateNamedFilter.ts"
);
import.meta.hot.lastModified = "1737935767422.7485";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateNamedFilterMutationRequest,
  CreateNamedFilterMutationResponse,
  CreateNamedFilterPathParams,
  CreateNamedFilter422,
} from '../types/CreateNamedFilter'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createNamedFilterMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters' }] as const

export type CreateNamedFilterMutationKey = ReturnType<typeof createNamedFilterMutationKey>

/**
 * @description Create a Named Filter.
 * @summary Create Named Filter
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters}
 */
async function createNamedFilter(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: CreateNamedFilterPathParams['account_name']
    starSchemaDataSourceId: CreateNamedFilterPathParams['star_schema_data_source_id']
    data: CreateNamedFilterMutationRequest
  },
  config: Partial<RequestConfig<CreateNamedFilterMutationRequest>> = {},
) {
  const res = await client<CreateNamedFilterMutationResponse, CreateNamedFilter422, CreateNamedFilterMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/filters`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a Named Filter.
 * @summary Create Named Filter
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters}
 */
export function useCreateNamedFilter(
  options: {
    mutation?: UseMutationOptions<
      CreateNamedFilterMutationResponse,
      CreateNamedFilter422,
      {
        accountName: CreateNamedFilterPathParams['account_name']
        starSchemaDataSourceId: CreateNamedFilterPathParams['star_schema_data_source_id']
        data: CreateNamedFilterMutationRequest
      }
    >
    client?: Partial<RequestConfig<CreateNamedFilterMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createNamedFilterMutationKey()

  return useMutation<
    CreateNamedFilterMutationResponse,
    CreateNamedFilter422,
    {
      accountName: CreateNamedFilterPathParams['account_name']
      starSchemaDataSourceId: CreateNamedFilterPathParams['star_schema_data_source_id']
      data: CreateNamedFilterMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return createNamedFilter({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}