// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadTableauConnectionProjects.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadTableauConnectionProjectsQueryResponse,
  ReadTableauConnectionProjectsPathParams,
  ReadTableauConnectionProjects422,
} from '../types/ReadTableauConnectionProjects'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readTableauConnectionProjectsQueryKey = ({
  accountName,
  tableauConnectionId,
}: {
  accountName: ReadTableauConnectionProjectsPathParams['account_name']
  tableauConnectionId: ReadTableauConnectionProjectsPathParams['tableau_connection_id']
}) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/tableau/:tableau_connection_id/projects',
      params: { accountName: accountName, tableauConnectionId: tableauConnectionId },
    },
  ] as const

export type ReadTableauConnectionProjectsQueryKey = ReturnType<typeof readTableauConnectionProjectsQueryKey>

/**
 * @description Read Tableau projects.
 * @summary Read Tableau Connection Projects
 * {@link /api/v1/accounts/:account_name/connections/tableau/:tableau_connection_id/projects}
 */
async function readTableauConnectionProjects(
  {
    accountName,
    tableauConnectionId,
  }: {
    accountName: ReadTableauConnectionProjectsPathParams['account_name']
    tableauConnectionId: ReadTableauConnectionProjectsPathParams['tableau_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadTableauConnectionProjectsQueryResponse, ReadTableauConnectionProjects422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/tableau/${tableauConnectionId}/projects`,
    ...config,
  })
  return res.data
}

export function readTableauConnectionProjectsQueryOptions(
  {
    accountName,
    tableauConnectionId,
  }: {
    accountName: ReadTableauConnectionProjectsPathParams['account_name']
    tableauConnectionId: ReadTableauConnectionProjectsPathParams['tableau_connection_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readTableauConnectionProjectsQueryKey({ accountName, tableauConnectionId })
  return queryOptions({
    enabled: !!(accountName && tableauConnectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readTableauConnectionProjects({ accountName, tableauConnectionId }, config)
    },
  })
}

/**
 * @description Read Tableau projects.
 * @summary Read Tableau Connection Projects
 * {@link /api/v1/accounts/:account_name/connections/tableau/:tableau_connection_id/projects}
 */
export function useReadTableauConnectionProjects<
  TData = ReadTableauConnectionProjectsQueryResponse,
  TQueryData = ReadTableauConnectionProjectsQueryResponse,
  TQueryKey extends QueryKey = ReadTableauConnectionProjectsQueryKey,
>(
  {
    accountName,
    tableauConnectionId,
  }: {
    accountName: ReadTableauConnectionProjectsPathParams['account_name']
    tableauConnectionId: ReadTableauConnectionProjectsPathParams['tableau_connection_id']
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadTableauConnectionProjectsQueryResponse, ReadTableauConnectionProjects422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readTableauConnectionProjectsQueryKey({ accountName, tableauConnectionId })

  const query = useQuery({
    ...(readTableauConnectionProjectsQueryOptions({ accountName, tableauConnectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadTableauConnectionProjects422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}