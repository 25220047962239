// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadTeradataConnectionSchemas.ts"
);
import.meta.hot.lastModified = "1737759762296.0156";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadTeradataConnectionSchemasQueryResponse,
  ReadTeradataConnectionSchemasPathParams,
  ReadTeradataConnectionSchemasQueryParams,
  ReadTeradataConnectionSchemas422,
} from '../types/ReadTeradataConnectionSchemas'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readTeradataConnectionSchemasQueryKey = (
  {
    accountName,
    teradataConnectionId,
  }: {
    accountName: ReadTeradataConnectionSchemasPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionSchemasPathParams['teradata_connection_id']
  },
  params: ReadTeradataConnectionSchemasQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id/schemas',
      params: { accountName: accountName, teradataConnectionId: teradataConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadTeradataConnectionSchemasQueryKey = ReturnType<typeof readTeradataConnectionSchemasQueryKey>

/**
 * @description Read teradata schemas.
 * @summary Read Teradata Connection Schemas
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id/schemas}
 */
async function readTeradataConnectionSchemas(
  {
    accountName,
    teradataConnectionId,
    params,
  }: {
    accountName: ReadTeradataConnectionSchemasPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionSchemasPathParams['teradata_connection_id']
    params: ReadTeradataConnectionSchemasQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadTeradataConnectionSchemasQueryResponse, ReadTeradataConnectionSchemas422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/teradata/${teradataConnectionId}/schemas`,
    params,
    ...config,
  })
  return res.data
}

export function readTeradataConnectionSchemasQueryOptions(
  {
    accountName,
    teradataConnectionId,
    params,
  }: {
    accountName: ReadTeradataConnectionSchemasPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionSchemasPathParams['teradata_connection_id']
    params: ReadTeradataConnectionSchemasQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readTeradataConnectionSchemasQueryKey({ accountName, teradataConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && teradataConnectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readTeradataConnectionSchemas({ accountName, teradataConnectionId, params }, config)
    },
  })
}

/**
 * @description Read teradata schemas.
 * @summary Read Teradata Connection Schemas
 * {@link /api/v1/accounts/:account_name/connections/teradata/:teradata_connection_id/schemas}
 */
export function useReadTeradataConnectionSchemas<
  TData = ReadTeradataConnectionSchemasQueryResponse,
  TQueryData = ReadTeradataConnectionSchemasQueryResponse,
  TQueryKey extends QueryKey = ReadTeradataConnectionSchemasQueryKey,
>(
  {
    accountName,
    teradataConnectionId,
    params,
  }: {
    accountName: ReadTeradataConnectionSchemasPathParams['account_name']
    teradataConnectionId: ReadTeradataConnectionSchemasPathParams['teradata_connection_id']
    params: ReadTeradataConnectionSchemasQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadTeradataConnectionSchemasQueryResponse, ReadTeradataConnectionSchemas422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readTeradataConnectionSchemasQueryKey({ accountName, teradataConnectionId }, params)

  const query = useQuery({
    ...(readTeradataConnectionSchemasQueryOptions({ accountName, teradataConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadTeradataConnectionSchemas422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}