// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/PublishState.ts"
);
import.meta.hot.lastModified = "1737759746514.097";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * PublishState class.
 */
export enum PublishState {
  NEW = 'New',
  VERIFIED = 'Verified',
  INVALID = 'Invalid',
  DEPRECATED = 'Deprecated',
  REVIEW = 'Review',
}
