// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateMessageFeedback.ts"
);
import.meta.hot.lastModified = "1737759761257.021";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  UpdateMessageFeedbackMutationRequest,
  UpdateMessageFeedbackMutationResponse,
  UpdateMessageFeedbackPathParams,
  UpdateMessageFeedback422,
} from '../types/UpdateMessageFeedback'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateMessageFeedbackMutationKey = () => [{ url: '/api/v3/orgs/{account_name}/messages/{message_id}/feedback' }] as const

export type UpdateMessageFeedbackMutationKey = ReturnType<typeof updateMessageFeedbackMutationKey>

/**
 * @description Update the thumbs up / down user-provided feedback on a chat message.Feedback for the system response to a user question is associated with theuser question, instead of the response messages. Only user messages canreceive feedback at this time.Only the chat creator can provide feedback on messages within the chat.WARNING: This endpoint is experimental and its behavior may change without warning.
 * @summary Update Message Feedback (Experimental 🧪)
 * {@link /api/v3/orgs/:account_name/messages/:message_id/feedback}
 */
async function updateMessageFeedback(
  {
    accountName,
    messageId,
    data,
  }: {
    accountName: UpdateMessageFeedbackPathParams['account_name']
    messageId: UpdateMessageFeedbackPathParams['message_id']
    data: UpdateMessageFeedbackMutationRequest
  },
  config: Partial<RequestConfig<UpdateMessageFeedbackMutationRequest>> = {},
) {
  const res = await client<UpdateMessageFeedbackMutationResponse, UpdateMessageFeedback422, UpdateMessageFeedbackMutationRequest>({
    method: 'PUT',
    url: `/api/v3/orgs/${accountName}/messages/${messageId}/feedback`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update the thumbs up / down user-provided feedback on a chat message.Feedback for the system response to a user question is associated with theuser question, instead of the response messages. Only user messages canreceive feedback at this time.Only the chat creator can provide feedback on messages within the chat.WARNING: This endpoint is experimental and its behavior may change without warning.
 * @summary Update Message Feedback (Experimental 🧪)
 * {@link /api/v3/orgs/:account_name/messages/:message_id/feedback}
 */
export function useUpdateMessageFeedback(
  options: {
    mutation?: UseMutationOptions<
      UpdateMessageFeedbackMutationResponse,
      UpdateMessageFeedback422,
      {
        accountName: UpdateMessageFeedbackPathParams['account_name']
        messageId: UpdateMessageFeedbackPathParams['message_id']
        data: UpdateMessageFeedbackMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateMessageFeedbackMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateMessageFeedbackMutationKey()

  return useMutation<
    UpdateMessageFeedbackMutationResponse,
    UpdateMessageFeedback422,
    {
      accountName: UpdateMessageFeedbackPathParams['account_name']
      messageId: UpdateMessageFeedbackPathParams['message_id']
      data: UpdateMessageFeedbackMutationRequest
    }
  >({
    mutationFn: async ({ accountName, messageId, data }) => {
      return updateMessageFeedback({ accountName, messageId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}