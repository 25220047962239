// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/httpValidationErrorSchema.ts"
);
import.meta.hot.lastModified = "1737759760886.023";
}
// REMIX HMR END

// @ts-nocheck
import { validationErrorSchema } from './validationErrorSchema'
import { z } from 'zod'

export const httpValidationErrorSchema = z.object({
  detail: z.array(z.lazy(() => validationErrorSchema)).optional(),
})