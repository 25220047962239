// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useValidateStarSchemaDataSource.ts"
);
import.meta.hot.lastModified = "1737935769432.7375";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ValidateStarSchemaDataSourceMutationResponse,
  ValidateStarSchemaDataSourcePathParams,
  ValidateStarSchemaDataSource422,
} from '../types/ValidateStarSchemaDataSource'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const validateStarSchemaDataSourceMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/validate' }] as const

export type ValidateStarSchemaDataSourceMutationKey = ReturnType<typeof validateStarSchemaDataSourceMutationKey>

/**
 * @description Validate Star Schema Data Source.Check all dimensions, metrics, and filters and mark invalid ones as Deprecated.
 * @summary Validate Star Schema Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/validate}
 */
async function validateStarSchemaDataSource(
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: ValidateStarSchemaDataSourcePathParams['account_name']
    starSchemaDataSourceId: ValidateStarSchemaDataSourcePathParams['star_schema_data_source_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ValidateStarSchemaDataSourceMutationResponse, ValidateStarSchemaDataSource422, unknown>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/validate`,
    ...config,
  })
  return res.data
}

/**
 * @description Validate Star Schema Data Source.Check all dimensions, metrics, and filters and mark invalid ones as Deprecated.
 * @summary Validate Star Schema Data Source
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/validate}
 */
export function useValidateStarSchemaDataSource(
  options: {
    mutation?: UseMutationOptions<
      ValidateStarSchemaDataSourceMutationResponse,
      ValidateStarSchemaDataSource422,
      {
        accountName: ValidateStarSchemaDataSourcePathParams['account_name']
        starSchemaDataSourceId: ValidateStarSchemaDataSourcePathParams['star_schema_data_source_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? validateStarSchemaDataSourceMutationKey()

  return useMutation<
    ValidateStarSchemaDataSourceMutationResponse,
    ValidateStarSchemaDataSource422,
    {
      accountName: ValidateStarSchemaDataSourcePathParams['account_name']
      starSchemaDataSourceId: ValidateStarSchemaDataSourcePathParams['star_schema_data_source_id']
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId }) => {
      return validateStarSchemaDataSource({ accountName, starSchemaDataSourceId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}