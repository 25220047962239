// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dialectSchema.ts"
);
import.meta.hot.lastModified = "1737759757712.0393";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description SQLGlot dialects.\n\nRef: https://github.com/tobymao/sqlglot/blob/main/sqlglot/dialects/__init__.py
 */
export const dialectSchema = z
  .enum([
    'bigquery',
    'databricks',
    'casesensitivebigquery',
    'casesensitivedatabricks',
    'casesensitiveredshift',
    'postgres',
    'redshift',
    'snowflake',
    'teradata',
    'unknown',
  ])
  .describe('SQLGlot dialects.\n\nRef: https://github.com/tobymao/sqlglot/blob/main/sqlglot/dialects/__init__.py')