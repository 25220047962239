// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/knowledgeLayerLinkedQuestionApiSchema.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const knowledgeLayerLinkedQuestionApiSchema = z.object({
  question: z.string(),
})