// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ChartSelectDmmType.ts"
);
import.meta.hot.lastModified = "1737759759433.0303";
}
// REMIX HMR END

export enum ChartSelectDmmType {
  'metric' = 'metric',
  'dimension' = 'dimension',
}