// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetKnowledgeSqls.ts"
);
import.meta.hot.lastModified = "1737935767038.7507";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { GetKnowledgeSqlsQueryResponse, GetKnowledgeSqlsPathParams, GetKnowledgeSqlsQueryParams, GetKnowledgeSqls422 } from '../types/GetKnowledgeSqls'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getKnowledgeSqlsQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetKnowledgeSqlsPathParams['account_name']; starSchemaDataSourceId: GetKnowledgeSqlsPathParams['star_schema_data_source_id'] },
  params?: GetKnowledgeSqlsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/knowledge_sqls',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetKnowledgeSqlsQueryKey = ReturnType<typeof getKnowledgeSqlsQueryKey>

/**
 * @description Get knowledge SQLs for the given account and ssds.
 * @summary Get Knowledge Sqls
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/knowledge_sqls}
 */
async function getKnowledgeSqls(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetKnowledgeSqlsPathParams['account_name']
    starSchemaDataSourceId: GetKnowledgeSqlsPathParams['star_schema_data_source_id']
    params?: GetKnowledgeSqlsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetKnowledgeSqlsQueryResponse, GetKnowledgeSqls422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/knowledge_sqls`,
    params,
    ...config,
  })
  return res.data
}

export function getKnowledgeSqlsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetKnowledgeSqlsPathParams['account_name']
    starSchemaDataSourceId: GetKnowledgeSqlsPathParams['star_schema_data_source_id']
    params?: GetKnowledgeSqlsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getKnowledgeSqlsQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getKnowledgeSqls({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get knowledge SQLs for the given account and ssds.
 * @summary Get Knowledge Sqls
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/knowledge_sqls}
 */
export function useGetKnowledgeSqls<
  TData = GetKnowledgeSqlsQueryResponse,
  TQueryData = GetKnowledgeSqlsQueryResponse,
  TQueryKey extends QueryKey = GetKnowledgeSqlsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetKnowledgeSqlsPathParams['account_name']
    starSchemaDataSourceId: GetKnowledgeSqlsPathParams['star_schema_data_source_id']
    params?: GetKnowledgeSqlsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetKnowledgeSqlsQueryResponse, GetKnowledgeSqls422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getKnowledgeSqlsQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getKnowledgeSqlsQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetKnowledgeSqls422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}