// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/updateJobUpdateSchema.ts"
);
import.meta.hot.lastModified = "1737759759784.0286";
}
// REMIX HMR END

// @ts-nocheck
import { conversationCellFilterSchema } from './conversationCellFilterSchema'
import { datasetJobTypeSchema } from './datasetJobTypeSchema'
import { dimensionPairToReviewUpdateSchema } from './dimensionPairToReviewUpdateSchema'
import { dimensionToReviewUpdateSchema } from './dimensionToReviewUpdateSchema'
import { metricPairToReviewUpdateSchema } from './metricPairToReviewUpdateSchema'
import { metricToReviewUpdateSchema } from './metricToReviewUpdateSchema'
import { tableOrViewInfoCreateSchema } from './tableOrViewInfoCreateSchema'
import { tableOrViewInfoUpdateSchema } from './tableOrViewInfoUpdateSchema'
import { updateReportSchema } from './updateReportSchema'
import { z } from 'zod'

/**
 * @description Properties to receive on item update.
 */
export const updateJobUpdateSchema = z
  .object({
    enterprise_id: z.union([z.string(), z.null()]).optional(),
    type: z.lazy(() => datasetJobTypeSchema).default('UPDATE'),
    src_account_name: z.union([z.string(), z.null()]).optional(),
    dst_account_name: z.union([z.string(), z.null()]).optional(),
    src_ssds_id: z.union([z.string(), z.null()]).optional(),
    dst_ssds_id: z.union([z.string(), z.null()]).optional(),
    conversation_cell_filter: z.lazy(() => conversationCellFilterSchema).default('ALL'),
    overwrite_user_question_for_sensitive_cell: z.boolean().default(false),
    max_eval_messages: z.union([z.number().int(), z.null()]).optional(),
    tables_and_views: z.union([z.array(z.union([z.lazy(() => tableOrViewInfoUpdateSchema), z.lazy(() => tableOrViewInfoCreateSchema)])), z.null()]).optional(),
    new_dimensions: z.union([z.array(z.lazy(() => dimensionToReviewUpdateSchema)), z.null()]).optional(),
    new_metrics: z.union([z.array(z.lazy(() => metricToReviewUpdateSchema)), z.null()]).optional(),
    dimensions_to_review: z.union([z.array(z.lazy(() => dimensionPairToReviewUpdateSchema)), z.null()]).optional(),
    non_verified_dimensions_to_review: z.union([z.array(z.lazy(() => dimensionPairToReviewUpdateSchema)), z.null()]).optional(),
    metrics_to_review: z.union([z.array(z.lazy(() => metricPairToReviewUpdateSchema)), z.null()]).optional(),
    non_verified_metrics_to_review: z.union([z.array(z.lazy(() => metricPairToReviewUpdateSchema)), z.null()]).optional(),
    report: z.union([z.lazy(() => updateReportSchema), z.null()]).optional(),
  })
  .describe('Properties to receive on item update.')