// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyMergeDimensionsApiV1AccountsAccountNameStarSchemaDataSourceDimensionsDimensionIdMergePatchSchema.ts"
);
import.meta.hot.lastModified = "1737759762609.014";
}
// REMIX HMR END

// @ts-nocheck
import { dimensionUpdateSchema } from './dimensionUpdateSchema'
import { z } from 'zod'

export const bodyMergeDimensionsApiV1AccountsAccountNameStarSchemaDataSourceDimensionsDimensionIdMergePatchSchema = z.object({
  dimension_update: z.union([z.lazy(() => dimensionUpdateSchema), z.null()]),
  ids_to_merge: z.array(z.string()),
})