// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/feedbackChatLinkedChatIdResponseSchema.ts"
);
import.meta.hot.lastModified = "1737759762517.0144";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Feedback chat linked chat id response.
 */
export const feedbackChatLinkedChatIdResponseSchema = z
  .object({
    linked_chat_id: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Feedback chat linked chat id response.')