// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useListRedshiftTables.ts"
);
import.meta.hot.lastModified = "1737935767642.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ListRedshiftTablesQueryResponse,
  ListRedshiftTablesPathParams,
  ListRedshiftTablesQueryParams,
  ListRedshiftTables422,
} from '../types/ListRedshiftTables'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listRedshiftTablesQueryKey = (
  { accountName, connectionId }: { accountName: ListRedshiftTablesPathParams['account_name']; connectionId: ListRedshiftTablesPathParams['connection_id'] },
  params?: ListRedshiftTablesQueryParams,
) =>
  [
    { url: '/api/v1/accounts/:account_name/connections/redshift/:connection_id/tables', params: { accountName: accountName, connectionId: connectionId } },
    ...(params ? [params] : []),
  ] as const

export type ListRedshiftTablesQueryKey = ReturnType<typeof listRedshiftTablesQueryKey>

/**
 * @description Read Redshift tables.
 * @summary List Redshift Tables
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id/tables}
 */
async function listRedshiftTables(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListRedshiftTablesPathParams['account_name']
    connectionId: ListRedshiftTablesPathParams['connection_id']
    params?: ListRedshiftTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ListRedshiftTablesQueryResponse, ListRedshiftTables422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/redshift/${connectionId}/tables`,
    params,
    ...config,
  })
  return res.data
}

export function listRedshiftTablesQueryOptions(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListRedshiftTablesPathParams['account_name']
    connectionId: ListRedshiftTablesPathParams['connection_id']
    params?: ListRedshiftTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = listRedshiftTablesQueryKey({ accountName, connectionId }, params)
  return queryOptions({
    enabled: !!(accountName && connectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listRedshiftTables({ accountName, connectionId, params }, config)
    },
  })
}

/**
 * @description Read Redshift tables.
 * @summary List Redshift Tables
 * {@link /api/v1/accounts/:account_name/connections/redshift/:connection_id/tables}
 */
export function useListRedshiftTables<
  TData = ListRedshiftTablesQueryResponse,
  TQueryData = ListRedshiftTablesQueryResponse,
  TQueryKey extends QueryKey = ListRedshiftTablesQueryKey,
>(
  {
    accountName,
    connectionId,
    params,
  }: {
    accountName: ListRedshiftTablesPathParams['account_name']
    connectionId: ListRedshiftTablesPathParams['connection_id']
    params?: ListRedshiftTablesQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ListRedshiftTablesQueryResponse, ListRedshiftTables422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listRedshiftTablesQueryKey({ accountName, connectionId }, params)

  const query = useQuery({
    ...(listRedshiftTablesQueryOptions({ accountName, connectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListRedshiftTables422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}