// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/taskStatusSchema.ts"
);
import.meta.hot.lastModified = "1737935765341.76";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Enumerates the possible task statuses.
 */
export const taskStatusSchema = z.enum(['PROGRESS', 'SUCCESS', 'FAILURE', 'PENDING', 'CANCELLED']).describe('Enumerates the possible task statuses.')