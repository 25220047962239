// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/dateTimeColumnStatisticsSchema.ts"
);
import.meta.hot.lastModified = "1737759761840.0178";
}
// REMIX HMR END

// @ts-nocheck
import { columnValueSchema } from './columnValueSchema'
import { histogramBucketStrSchema } from './histogramBucketStrSchema'
import { z } from 'zod'

/**
 * @description Statistics for datetime/timestamp columns.
 */
export const dateTimeColumnStatisticsSchema = z
  .object({
    name: z.string(),
    type: z.string(),
    count: z.number().int(),
    num_nulls: z.number().int(),
    num_distinct_values: z.union([z.number().int(), z.null()]).optional(),
    stat_type: z.enum(['datetime']).default('datetime'),
    min_datetime: z.union([z.string(), z.null()]).optional(),
    max_datetime: z.union([z.string(), z.null()]).optional(),
    histogram: z.union([z.array(z.lazy(() => histogramBucketStrSchema)), z.null()]).optional(),
    most_common_values: z.array(z.lazy(() => columnValueSchema)),
  })
  .describe('Statistics for datetime/timestamp columns.')