// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getEvalOnReferenceConversationsResultsSchema.ts"
);
import.meta.hot.lastModified = "1737935769670.7363";
}
// REMIX HMR END

// @ts-nocheck
import { evalResultBaseSchema } from './evalResultBaseSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getEvalOnReferenceConversationsResultsPathParamsSchema = z.object({
  account_name: z.string(),
  star_schema_data_source_id: z.string(),
})

export const getEvalOnReferenceConversationsResultsQueryParamsSchema = z
  .object({
    task_id: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const getEvalOnReferenceConversationsResults200Schema = z.array(z.lazy(() => evalResultBaseSchema))

/**
 * @description Validation Error
 */
export const getEvalOnReferenceConversationsResults422Schema = z.lazy(() => httpValidationErrorSchema)

export const getEvalOnReferenceConversationsResultsQueryResponseSchema = z.lazy(() => getEvalOnReferenceConversationsResults200Schema)