// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/uploadDatasetSchema.ts"
);
import.meta.hot.lastModified = "1737759759189.0317";
}
// REMIX HMR END

// @ts-nocheck
import { bodyUploadDatasetApiV1AccountsAccountNameDatasetsUploadPostSchema } from './bodyUploadDatasetApiV1AccountsAccountNameDatasetsUploadPostSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { taskSchema } from './taskSchema'
import { z } from 'zod'

export const uploadDatasetPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const uploadDataset201Schema = z.lazy(() => taskSchema)

/**
 * @description Validation Error
 */
export const uploadDataset422Schema = z.lazy(() => httpValidationErrorSchema)

export const uploadDatasetMutationRequestSchema = z.lazy(() => bodyUploadDatasetApiV1AccountsAccountNameDatasetsUploadPostSchema)

export const uploadDatasetMutationResponseSchema = z.lazy(() => uploadDataset201Schema)