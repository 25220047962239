// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRetrieve.ts"
);
import.meta.hot.lastModified = "1737759757712.0393";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RetrieveMutationRequest, RetrieveMutationResponse, RetrievePathParams, RetrieveQueryParams, Retrieve422 } from '../types/Retrieve'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const retrieveMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/retrieve' }] as const

export type RetrieveMutationKey = ReturnType<typeof retrieveMutationKey>

/**
 * @description Retrieve columns, dimensions, metrics and top values.Endpoint used for evaluation purposes only.
 * @summary Retrieve
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/retrieve}
 */
async function retrieve(
  {
    accountName,
    starSchemaDataSourceId,
    params,
    data,
  }: {
    accountName: RetrievePathParams['account_name']
    starSchemaDataSourceId: RetrievePathParams['star_schema_data_source_id']
    params: RetrieveQueryParams
    data?: RetrieveMutationRequest
  },
  config: Partial<RequestConfig<RetrieveMutationRequest>> = {},
) {
  const res = await client<RetrieveMutationResponse, Retrieve422, RetrieveMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/retrieve`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Retrieve columns, dimensions, metrics and top values.Endpoint used for evaluation purposes only.
 * @summary Retrieve
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/retrieve}
 */
export function useRetrieve(
  options: {
    mutation?: UseMutationOptions<
      RetrieveMutationResponse,
      Retrieve422,
      {
        accountName: RetrievePathParams['account_name']
        starSchemaDataSourceId: RetrievePathParams['star_schema_data_source_id']
        params: RetrieveQueryParams
        data?: RetrieveMutationRequest
      }
    >
    client?: Partial<RequestConfig<RetrieveMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? retrieveMutationKey()

  return useMutation<
    RetrieveMutationResponse,
    Retrieve422,
    {
      accountName: RetrievePathParams['account_name']
      starSchemaDataSourceId: RetrievePathParams['star_schema_data_source_id']
      params: RetrieveQueryParams
      data?: RetrieveMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, params, data }) => {
      return retrieve({ accountName, starSchemaDataSourceId, params, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}