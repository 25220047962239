// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyWriteCsvToConnectionApiV1AccountsAccountNameConnectionsCsvPostSchema.ts"
);
import.meta.hot.lastModified = "1737935769735.7358";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const bodyWriteCsvToConnectionApiV1AccountsAccountNameConnectionsCsvPostSchema = z.object({
  connection_id: z.union([z.string(), z.null()]).optional(),
  file: z.instanceof(File),
})