// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/permissionsSchema.ts"
);
import.meta.hot.lastModified = "1737935765547.7588";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description To be added to other objects to specify a user\'s permissions on that object.
 */
export const permissionsSchema = z
  .object({
    read: z.boolean(),
    write: z.boolean(),
  })
  .describe("To be added to other objects to specify a user's permissions on that object.")