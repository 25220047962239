// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getBbCellSqlSchema.ts"
);
import.meta.hot.lastModified = "1737759758961.0327";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getBbCellSqlPathParamsSchema = z.object({
  account_name: z.string(),
  conversation_id: z.string(),
  cell_id: z.string(),
})

/**
 * @description Successful Response
 */
export const getBbCellSql200Schema = z.string()

/**
 * @description Validation Error
 */
export const getBbCellSql422Schema = z.lazy(() => httpValidationErrorSchema)

export const getBbCellSqlQueryResponseSchema = z.lazy(() => getBbCellSql200Schema)