// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useUpdateCell.ts"
);
import.meta.hot.lastModified = "1737759758193.0369";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { UpdateCellMutationRequest, UpdateCellMutationResponse, UpdateCellPathParams, UpdateCell422 } from '../types/UpdateCell'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const updateCellMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}' }] as const

export type UpdateCellMutationKey = ReturnType<typeof updateCellMutationKey>

/**
 * @description Update a Bumblebee Cell.
 * @summary Update Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id}
 */
async function updateCell(
  {
    accountName,
    conversationId,
    cellId,
    data,
  }: {
    accountName: UpdateCellPathParams['account_name']
    conversationId: UpdateCellPathParams['conversation_id']
    cellId: UpdateCellPathParams['cell_id']
    data?: UpdateCellMutationRequest
  },
  config: Partial<RequestConfig<UpdateCellMutationRequest>> = {},
) {
  const res = await client<UpdateCellMutationResponse, UpdateCell422, UpdateCellMutationRequest>({
    method: 'PUT',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Update a Bumblebee Cell.
 * @summary Update Cell
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id}
 */
export function useUpdateCell(
  options: {
    mutation?: UseMutationOptions<
      UpdateCellMutationResponse,
      UpdateCell422,
      {
        accountName: UpdateCellPathParams['account_name']
        conversationId: UpdateCellPathParams['conversation_id']
        cellId: UpdateCellPathParams['cell_id']
        data?: UpdateCellMutationRequest
      }
    >
    client?: Partial<RequestConfig<UpdateCellMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? updateCellMutationKey()

  return useMutation<
    UpdateCellMutationResponse,
    UpdateCell422,
    {
      accountName: UpdateCellPathParams['account_name']
      conversationId: UpdateCellPathParams['conversation_id']
      cellId: UpdateCellPathParams['cell_id']
      data?: UpdateCellMutationRequest
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId, data }) => {
      return updateCell({ accountName, conversationId, cellId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}