// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ChartType.ts"
);
import.meta.hot.lastModified = "1737935764663.7637";
}
// REMIX HMR END

export enum ChartType {
  'empty' = 'empty',
  'bar' = 'bar',
  'line' = 'line',
  'scatter' = 'scatter',
  'geo' = 'geo',
  'big_number' = 'big_number',
}