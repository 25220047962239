// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetEvalOnReferenceConversationsResults.ts"
);
import.meta.hot.lastModified = "1737935769670.7363";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetEvalOnReferenceConversationsResultsQueryResponse,
  GetEvalOnReferenceConversationsResultsPathParams,
  GetEvalOnReferenceConversationsResultsQueryParams,
  GetEvalOnReferenceConversationsResults422,
} from '../types/GetEvalOnReferenceConversationsResults'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getEvalOnReferenceConversationsResultsQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: {
    accountName: GetEvalOnReferenceConversationsResultsPathParams['account_name']
    starSchemaDataSourceId: GetEvalOnReferenceConversationsResultsPathParams['star_schema_data_source_id']
  },
  params?: GetEvalOnReferenceConversationsResultsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/eval_reference_conversations_results',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetEvalOnReferenceConversationsResultsQueryKey = ReturnType<typeof getEvalOnReferenceConversationsResultsQueryKey>

/**
 * @description Get eval results for an SSDS.This returns results metadata for the SSDS. Full eval results with conversations can be retrieved by ID.
 * @summary Get Eval On Reference Conversations Results
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/eval_reference_conversations_results}
 */
async function getEvalOnReferenceConversationsResults(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetEvalOnReferenceConversationsResultsPathParams['account_name']
    starSchemaDataSourceId: GetEvalOnReferenceConversationsResultsPathParams['star_schema_data_source_id']
    params?: GetEvalOnReferenceConversationsResultsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetEvalOnReferenceConversationsResultsQueryResponse, GetEvalOnReferenceConversationsResults422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/eval_reference_conversations_results`,
    params,
    ...config,
  })
  return res.data
}

export function getEvalOnReferenceConversationsResultsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetEvalOnReferenceConversationsResultsPathParams['account_name']
    starSchemaDataSourceId: GetEvalOnReferenceConversationsResultsPathParams['star_schema_data_source_id']
    params?: GetEvalOnReferenceConversationsResultsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getEvalOnReferenceConversationsResultsQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getEvalOnReferenceConversationsResults({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get eval results for an SSDS.This returns results metadata for the SSDS. Full eval results with conversations can be retrieved by ID.
 * @summary Get Eval On Reference Conversations Results
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/eval_reference_conversations_results}
 */
export function useGetEvalOnReferenceConversationsResults<
  TData = GetEvalOnReferenceConversationsResultsQueryResponse,
  TQueryData = GetEvalOnReferenceConversationsResultsQueryResponse,
  TQueryKey extends QueryKey = GetEvalOnReferenceConversationsResultsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetEvalOnReferenceConversationsResultsPathParams['account_name']
    starSchemaDataSourceId: GetEvalOnReferenceConversationsResultsPathParams['star_schema_data_source_id']
    params?: GetEvalOnReferenceConversationsResultsQueryParams
  },
  options: {
    query?: Partial<
      QueryObserverOptions<GetEvalOnReferenceConversationsResultsQueryResponse, GetEvalOnReferenceConversationsResults422, TData, TQueryData, TQueryKey>
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getEvalOnReferenceConversationsResultsQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getEvalOnReferenceConversationsResultsQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetEvalOnReferenceConversationsResults422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}