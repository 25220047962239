// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateAccountTag.ts"
);
import.meta.hot.lastModified = "1737759760008.0273";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateAccountTagMutationRequest,
  CreateAccountTagMutationResponse,
  CreateAccountTagPathParams,
  CreateAccountTag422,
} from '../types/CreateAccountTag'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createAccountTagMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/tags' }] as const

export type CreateAccountTagMutationKey = ReturnType<typeof createAccountTagMutationKey>

/**
 * @description Create a tag for an account.
 * @summary Create Account Tag
 * {@link /api/v1/accounts/:account_name/tags}
 */
async function createAccountTag(
  { accountName, data }: { accountName: CreateAccountTagPathParams['account_name']; data?: CreateAccountTagMutationRequest },
  config: Partial<RequestConfig<CreateAccountTagMutationRequest>> = {},
) {
  const res = await client<CreateAccountTagMutationResponse, CreateAccountTag422, CreateAccountTagMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/tags`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create a tag for an account.
 * @summary Create Account Tag
 * {@link /api/v1/accounts/:account_name/tags}
 */
export function useCreateAccountTag(
  options: {
    mutation?: UseMutationOptions<
      CreateAccountTagMutationResponse,
      CreateAccountTag422,
      { accountName: CreateAccountTagPathParams['account_name']; data?: CreateAccountTagMutationRequest }
    >
    client?: Partial<RequestConfig<CreateAccountTagMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createAccountTagMutationKey()

  return useMutation<
    CreateAccountTagMutationResponse,
    CreateAccountTag422,
    { accountName: CreateAccountTagPathParams['account_name']; data?: CreateAccountTagMutationRequest }
  >({
    mutationFn: async ({ accountName, data }) => {
      return createAccountTag({ accountName, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}