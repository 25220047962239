// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/queryWithValidationSchema.ts"
);
import.meta.hot.lastModified = "1737935768070.745";
}
// REMIX HMR END

// @ts-nocheck
import { dialectSchema } from './dialectSchema'
import { z } from 'zod'

/**
 * @description Query with validation used by model.\n\nThis is different than a SemanticQuery in that QueryWithValidation is\njust strings that the model sees. SemanticQuery has actual full DMs\ndefinitions.\n\nInstantiating this class will parse the dimension_where and metric_where and could\nexceptions.\n\nRaises:\n    sqlglot.ParseError: if the where clause is not valid sql.\n    ValueError: if the where clause has an OR clause.
 */
export const queryWithValidationSchema = z
  .object({
    dialect: z.lazy(() => dialectSchema),
    dimensions: z.array(z.string()).optional(),
    metrics: z.array(z.string()),
    dimension_where: z.string().default(''),
    metric_where: z.string().default(''),
    order: z.array(z.string()).optional(),
    limit: z.union([z.number().int(), z.null()]).optional(),
  })
  .describe(
    'Query with validation used by model.\n\nThis is different than a SemanticQuery in that QueryWithValidation is\njust strings that the model sees. SemanticQuery has actual full DMs\ndefinitions.\n\nInstantiating this class will parse the dimension_where and metric_where and could\nexceptions.\n\nRaises:\n    sqlglot.ParseError: if the where clause is not valid sql.\n    ValueError: if the where clause has an OR clause.',
  )