// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/DatasetJobType.ts"
);
import.meta.hot.lastModified = "1737935765532.759";
}
// REMIX HMR END

export enum DatasetJobType {
  'UPDATE' = 'UPDATE',
  'COPY' = 'COPY',
}