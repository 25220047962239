// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/zenStarSchemaDataSourceBaseSchema.ts"
);
import.meta.hot.lastModified = "1737759762076.0166";
}
// REMIX HMR END

// @ts-nocheck
import { dataAssetVisibilitySchema } from './dataAssetVisibilitySchema'
import { zenViewSchema } from './zenViewSchema'
import { z } from 'zod'

/**
 * @description Zenlytic StarSchemaDataSource Base Schema.
 */
export const zenStarSchemaDataSourceBaseSchema = z
  .object({
    sync_id: z.union([z.string(), z.null()]).optional(),
    visibility: z.lazy(() => dataAssetVisibilitySchema).default('PUBLISHED'),
    name: z.string(),
    description: z.union([z.string(), z.null()]).optional(),
    zen_views: z.array(z.lazy(() => zenViewSchema)),
    num_data_source_created: z.number().int().default(0),
  })
  .describe('Zenlytic StarSchemaDataSource Base Schema.')