// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ConflictStatus.ts"
);
import.meta.hot.lastModified = "1737759758407.0356";
}
// REMIX HMR END

export enum ConflictStatus {
  'CONFIRMED' = 'CONFIRMED',
  'UNCONFIRMED' = 'UNCONFIRMED',
}