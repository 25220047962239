// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/TableFilter.ts"
);
import.meta.hot.lastModified = "1737759746537.097";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TableFilterCondition } from './TableFilterCondition';

/**
 * Table filter model.
 */
export type TableFilter = {
  conditions: Array<TableFilterCondition>;
  conjunction?: TableFilter.conjunction;
};

export namespace TableFilter {

  export enum conjunction {
    AND = 'AND',
    OR = 'OR',
  }


}

