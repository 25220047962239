// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/DataAssetsService.ts"
);
import.meta.hot.lastModified = "1737759746398.0977";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataAssetAPIPage } from '../models/DataAssetAPIPage';
import type { SuggestionAPI } from '../models/SuggestionAPI';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DataAssetsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Data Assets For Account
   * Get data assets for an account.
   * @param accountName The name of the organization or account.
   * @param limit
   * @param offset
   * @param sessionId
   * @returns DataAssetAPIPage Successful Response
   * @throws ApiError
   */
  public getDataAssetsForAccount(
    accountName: string,
    limit: number = 100,
    offset?: number,
    sessionId?: (string | null),
  ): CancelablePromise<DataAssetAPIPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/data_assets/',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'offset': offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Suggestions For Account
   * Get suggested prompts for an account across all datasets in the account.
   * @param accountName The name of the organization or account.
   * @param limit
   * @param sessionId
   * @returns SuggestionAPI Successful Response
   * @throws ApiError
   */
  public getSuggestionsForAccount(
    accountName: string,
    limit: number = 5,
    sessionId?: (string | null),
  ): CancelablePromise<Array<SuggestionAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/data_assets/suggestions',
      path: {
        'account_name': accountName,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
