// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/FilterOrigin.ts"
);
import.meta.hot.lastModified = "1737935765108.7612";
}
// REMIX HMR END

export enum FilterOrigin {
  'AI' = 'AI',
  'NAMED_FILTER' = 'NAMED_FILTER',
  'CELL_FILTER' = 'CELL_FILTER',
}