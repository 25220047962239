// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useGetSsdsDimensions.ts"
);
import.meta.hot.lastModified = "1737759760235.0261";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  GetSsdsDimensionsQueryResponse,
  GetSsdsDimensionsPathParams,
  GetSsdsDimensionsQueryParams,
  GetSsdsDimensions422,
} from '../types/GetSsdsDimensions'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getSsdsDimensionsQueryKey = (
  {
    accountName,
    starSchemaDataSourceId,
  }: { accountName: GetSsdsDimensionsPathParams['account_name']; starSchemaDataSourceId: GetSsdsDimensionsPathParams['star_schema_data_source_id'] },
  params?: GetSsdsDimensionsQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/dimensions',
      params: { accountName: accountName, starSchemaDataSourceId: starSchemaDataSourceId },
    },
    ...(params ? [params] : []),
  ] as const

export type GetSsdsDimensionsQueryKey = ReturnType<typeof getSsdsDimensionsQueryKey>

/**
 * @description Get Dimensions for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are returned.
 * @summary Get Ssds Dimensions
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/dimensions}
 */
async function getSsdsDimensions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsDimensionsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsDimensionsPathParams['star_schema_data_source_id']
    params?: GetSsdsDimensionsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetSsdsDimensionsQueryResponse, GetSsdsDimensions422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/dimensions`,
    params,
    ...config,
  })
  return res.data
}

export function getSsdsDimensionsQueryOptions(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsDimensionsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsDimensionsPathParams['star_schema_data_source_id']
    params?: GetSsdsDimensionsQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = getSsdsDimensionsQueryKey({ accountName, starSchemaDataSourceId }, params)
  return queryOptions({
    enabled: !!(accountName && starSchemaDataSourceId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getSsdsDimensions({ accountName, starSchemaDataSourceId, params }, config)
    },
  })
}

/**
 * @description Get Dimensions for Star Schema Data Source.If DataSourceIds are specified, then only dimensions that referencethe specified Data Sources are returned.
 * @summary Get Ssds Dimensions
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/dimensions}
 */
export function useGetSsdsDimensions<
  TData = GetSsdsDimensionsQueryResponse,
  TQueryData = GetSsdsDimensionsQueryResponse,
  TQueryKey extends QueryKey = GetSsdsDimensionsQueryKey,
>(
  {
    accountName,
    starSchemaDataSourceId,
    params,
  }: {
    accountName: GetSsdsDimensionsPathParams['account_name']
    starSchemaDataSourceId: GetSsdsDimensionsPathParams['star_schema_data_source_id']
    params?: GetSsdsDimensionsQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<GetSsdsDimensionsQueryResponse, GetSsdsDimensions422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getSsdsDimensionsQueryKey({ accountName, starSchemaDataSourceId }, params)

  const query = useQuery({
    ...(getSsdsDimensionsQueryOptions({ accountName, starSchemaDataSourceId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, GetSsdsDimensions422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}