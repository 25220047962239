// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/KnowledgeLayerEntityType.ts"
);
import.meta.hot.lastModified = "1737935768206.7444";
}
// REMIX HMR END

export enum KnowledgeLayerEntityType {
  'dimension' = 'dimension',
  'metric' = 'metric',
  'dataset' = 'dataset',
  'tableau' = 'tableau',
  'mode' = 'mode',
}