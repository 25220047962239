// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/SqlValidation.ts"
);
import.meta.hot.lastModified = "1737759746528.097";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DimensionMetricValidation } from './DimensionMetricValidation';

/**
 * Properties of SqlValidation.
 */
export type SqlValidation = {
  id: string;
  account_id: string;
  ssds_id: string;
  message_id?: (string | null);
  origin: SqlValidation.origin;
  sql: string;
  is_valid: boolean;
  error?: (string | null);
  name?: (string | null);
  display_name?: (string | null);
  description?: (string | null);
  extracted: Array<DimensionMetricValidation>;
};

export namespace SqlValidation {

  export enum origin {
    SQL_CSV = 'SQL_CSV',
    FEEDBACK_SUGGESTION = 'FEEDBACK_SUGGESTION',
  }


}

