// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readAccountTagsSchema.ts"
);
import.meta.hot.lastModified = "1737759759794.0286";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { tagSchema } from './tagSchema'
import { z } from 'zod'

export const readAccountTagsPathParamsSchema = z.object({
  account_name: z.string(),
})

export const readAccountTagsQueryParamsSchema = z
  .object({
    filter_str: z.union([z.string(), z.null()]).optional(),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readAccountTags200Schema = z.array(z.lazy(() => tagSchema))

/**
 * @description Validation Error
 */
export const readAccountTags422Schema = z.lazy(() => httpValidationErrorSchema)

export const readAccountTagsQueryResponseSchema = z.lazy(() => readAccountTags200Schema)