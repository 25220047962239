// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/retrievalParamsSchema.ts"
);
import.meta.hot.lastModified = "1737759759784.0286";
}
// REMIX HMR END

// @ts-nocheck
import { retrievalMethodSchema } from './retrievalMethodSchema'
import { z } from 'zod'

/**
 * @description Retrieval Params.
 */
export const retrievalParamsSchema = z
  .object({
    retrieval_method: z.lazy(() => retrievalMethodSchema).default('hybrid'),
    num_dms_to_retrieve: z.number().int().default(30),
    num_examples_to_retrieve: z.number().int().default(5),
    include_user_question_as_variant: z.boolean().default(false),
    single_example_max_cosine_distance_threshold: z.union([z.number(), z.null()]).optional(),
    num_closest_examples_to_skip: z.number().int().default(0),
    num_values_to_retrieve: z.number().int().default(5),
    use_values_dms: z.boolean().default(true),
    use_examples_dms: z.boolean().default(true),
    use_entities_for_values_dms: z.boolean().default(true),
    use_popularity: z.boolean().default(true),
    use_knowledge_sql: z.boolean().default(false),
    augment_dms: z.boolean().default(true),
  })
  .describe('Retrieval Params.')