// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadAccountByName.ts"
);
import.meta.hot.lastModified = "1737759760212.0264";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ReadAccountByNameQueryResponse, ReadAccountByNamePathParams, ReadAccountByName422 } from '../types/ReadAccountByName'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readAccountByNameQueryKey = ({ accountName }: { accountName: ReadAccountByNamePathParams['account_name'] }) =>
  [{ url: '/api/v1/accounts/:account_name', params: { accountName: accountName } }] as const

export type ReadAccountByNameQueryKey = ReturnType<typeof readAccountByNameQueryKey>

/**
 * @description Get a specific account by name.
 * @summary Read Account By Name
 * {@link /api/v1/accounts/:account_name}
 */
async function readAccountByName({ accountName }: { accountName: ReadAccountByNamePathParams['account_name'] }, config: Partial<RequestConfig> = {}) {
  const res = await client<ReadAccountByNameQueryResponse, ReadAccountByName422, unknown>({ method: 'GET', url: `/api/v1/accounts/${accountName}`, ...config })
  return res.data
}

export function readAccountByNameQueryOptions(
  { accountName }: { accountName: ReadAccountByNamePathParams['account_name'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readAccountByNameQueryKey({ accountName })
  return queryOptions({
    enabled: !!accountName,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readAccountByName({ accountName }, config)
    },
  })
}

/**
 * @description Get a specific account by name.
 * @summary Read Account By Name
 * {@link /api/v1/accounts/:account_name}
 */
export function useReadAccountByName<
  TData = ReadAccountByNameQueryResponse,
  TQueryData = ReadAccountByNameQueryResponse,
  TQueryKey extends QueryKey = ReadAccountByNameQueryKey,
>(
  { accountName }: { accountName: ReadAccountByNamePathParams['account_name'] },
  options: {
    query?: Partial<QueryObserverOptions<ReadAccountByNameQueryResponse, ReadAccountByName422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readAccountByNameQueryKey({ accountName })

  const query = useQuery({
    ...(readAccountByNameQueryOptions({ accountName }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadAccountByName422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}