// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messageApiSendV3Schema.ts"
);
import.meta.hot.lastModified = "1737935767112.7502";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Payload for sending a message to a chat.
 */
export const messageApiSendV3Schema = z
  .object({
    message_text: z
      .union([z.string(), z.null()])
      .describe(
        'The message that the user sent. To refer to a particular dataset, client should format it like a markdown link. For example, `[dataset_name](dataset_id)`. Note that for the time being, we only support referring to datasets, not other asset types (e.g. reports).If the message is null or empty, then the message will be ignored. Sending an empty message is useful for pinging the system to get a current event stream.',
      ),
    chat_id: z
      .union([z.string(), z.null()])
      .describe('The existing chat to send a message to. If not provided, a new chat will be created. Cannot be null if the message is null or empty.')
      .optional(),
    allowed_agent_names: z
      .union([z.array(z.string()), z.null()])
      .describe(
        "The agents that are allowed to respond to this message. At the moment the only supported values are null and ['search']. If null or not provided, then all agents are allowed to respond. If ['search'] is provided, then only the search agent is allowed to respond.",
      )
      .optional(),
  })
  .describe('Payload for sending a message to a chat.')