// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bodyExtractSqlsFromCsvApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdExtractSqlsFromCsvPostSchema.ts"
);
import.meta.hot.lastModified = "1737759762609.014";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

export const bodyExtractSqlsFromCsvApiV1AccountsAccountNameStarSchemaDataSourceStarSchemaDataSourceIdExtractSqlsFromCsvPostSchema = z.object({
  csv_file: z.instanceof(File),
})