// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/deleteChatSchema.ts"
);
import.meta.hot.lastModified = "1737759758407.0356";
}
// REMIX HMR END

// @ts-nocheck
import { chatApiResponseSchema } from './chatApiResponseSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const deleteChatPathParamsSchema = z.object({
  account_name: z.string().describe('The name of the organization or account.'),
  chat_id: z.string().describe('The ID of the chat.'),
})

/**
 * @description Successful Response
 */
export const deleteChat200Schema = z.lazy(() => chatApiResponseSchema)

/**
 * @description Validation Error
 */
export const deleteChat422Schema = z.lazy(() => httpValidationErrorSchema)

export const deleteChatMutationResponseSchema = z.lazy(() => deleteChat200Schema)