// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useRemoveBadResponse.ts"
);
import.meta.hot.lastModified = "1737935767415.7485";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { RemoveBadResponseMutationResponse, RemoveBadResponsePathParams, RemoveBadResponse422 } from '../types/RemoveBadResponse'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const removeBadResponseMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/bad_response' }] as const

export type RemoveBadResponseMutationKey = ReturnType<typeof removeBadResponseMutationKey>

/**
 * @description Remove bad responses from a bumblebee cell.
 * @summary Remove Bad Response
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/bad_response}
 */
async function removeBadResponse(
  {
    accountName,
    conversationId,
    cellId,
  }: {
    accountName: RemoveBadResponsePathParams['account_name']
    conversationId: RemoveBadResponsePathParams['conversation_id']
    cellId: RemoveBadResponsePathParams['cell_id']
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<RemoveBadResponseMutationResponse, RemoveBadResponse422, unknown>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/conversations/${conversationId}/cells/${cellId}/bad_response`,
    ...config,
  })
  return res.data
}

/**
 * @description Remove bad responses from a bumblebee cell.
 * @summary Remove Bad Response
 * {@link /api/v1/accounts/:account_name/conversations/:conversation_id/cells/:cell_id/bad_response}
 */
export function useRemoveBadResponse(
  options: {
    mutation?: UseMutationOptions<
      RemoveBadResponseMutationResponse,
      RemoveBadResponse422,
      {
        accountName: RemoveBadResponsePathParams['account_name']
        conversationId: RemoveBadResponsePathParams['conversation_id']
        cellId: RemoveBadResponsePathParams['cell_id']
      }
    >
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? removeBadResponseMutationKey()

  return useMutation<
    RemoveBadResponseMutationResponse,
    RemoveBadResponse422,
    {
      accountName: RemoveBadResponsePathParams['account_name']
      conversationId: RemoveBadResponsePathParams['conversation_id']
      cellId: RemoveBadResponsePathParams['cell_id']
    }
  >({
    mutationFn: async ({ accountName, conversationId, cellId }) => {
      return removeBadResponse({ accountName, conversationId, cellId }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}