// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/bumblebeeCellUpdateSchema.ts"
);
import.meta.hot.lastModified = "1737935768069.745";
}
// REMIX HMR END

// @ts-nocheck
import { cellStateSchema } from './cellStateSchema'
import { dimensionViewSchema } from './dimensionViewSchema'
import { metricViewSchema } from './metricViewSchema'
import { z } from 'zod'

/**
 * @description Update Bumblebee Cell Schema.
 */
export const bumblebeeCellUpdateSchema = z
  .object({
    sync_id: z.union([z.string(), z.null()]).optional(),
    conversation_id: z.union([z.string(), z.null()]).optional(),
    user_question: z.union([z.string(), z.null()]).optional(),
    updated_question: z.union([z.string(), z.null()]).optional(),
    question_variants: z.array(z.string()).optional(),
    index: z.union([z.number().int(), z.null()]).optional(),
    sql: z.union([z.string(), z.null()]).optional(),
    data_summary: z.union([z.string(), z.null()]).optional(),
    name: z.union([z.string(), z.null()]).optional(),
    bb_model: z.union([z.string(), z.null()]).optional(),
    semantic_query_id: z.union([z.string(), z.null()]).optional(),
    ssds_id: z.union([z.string(), z.null()]).optional(),
    explanation: z.union([z.string(), z.null()]).optional(),
    is_holdout: z.union([z.boolean(), z.null()]).optional(),
    has_feedback: z.boolean().default(false),
    is_dm_sensitive: z.boolean().default(false),
    is_user_sensitive: z.boolean().default(false),
    copied_from: z.union([z.string(), z.null()]).optional(),
    is_suggested: z.union([z.boolean(), z.null()]).default(false),
    moonlight_predicted_input_type: z.union([z.number().int(), z.null()]).optional(),
    moonlight_prompt: z.union([z.string(), z.null()]).optional(),
    is_stale: z.union([z.boolean(), z.null()]).optional(),
    question_openai_embedding: z.union([z.array(z.number()), z.null()]).optional(),
    retrieved_metrics: z.union([z.array(z.lazy(() => metricViewSchema)), z.null()]).optional(),
    retrieved_dimensions: z.union([z.array(z.lazy(() => dimensionViewSchema)), z.null()]).optional(),
    state: z.union([z.lazy(() => cellStateSchema), z.null()]).optional(),
    moonlight_response: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Update Bumblebee Cell Schema.')