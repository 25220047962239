// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/ZenIdentifierRelationship.ts"
);
import.meta.hot.lastModified = "1737759761269.021";
}
// REMIX HMR END

export enum ZenIdentifierRelationship {
  'one_to_one' = 'one_to_one',
  'many_to_one' = 'many_to_one',
  'one_to_many' = 'one_to_many',
  'many_to_many' = 'many_to_many',
}