// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/BaseColumnStatistics.ts"
);
import.meta.hot.lastModified = "1737935767415.7485";
}
// REMIX HMR END

export enum BaseColumnStatisticsStatType {
  'base' = 'base',
}

/**
 * @description Common column statistics with discriminator.
 */
export type BaseColumnStatistics = {
  /**
   * @type string
   */
  name: string
  /**
   * @type string
   */
  type: string
  /**
   * @type integer
   */
  count: number
  /**
   * @type integer
   */
  num_nulls: number
  num_distinct_values?: number | null
  /**
   * @default "base"
   * @type string
   */
  stat_type: BaseColumnStatisticsStatType
}