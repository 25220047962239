// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readConnectionSchema.ts"
);
import.meta.hot.lastModified = "1737759759433.0303";
}
// REMIX HMR END

// @ts-nocheck
import { bigQueryConnectionSchema } from './bigQueryConnectionSchema'
import { connectionSchema } from './connectionSchema'
import { databricksConnectionSchema } from './databricksConnectionSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { postgresConnectionSchema } from './postgresConnectionSchema'
import { redshiftConnectionSchema } from './redshiftConnectionSchema'
import { snowflakeConnectionSchema } from './snowflakeConnectionSchema'
import { teradataConnectionSchema } from './teradataConnectionSchema'
import { z } from 'zod'

export const readConnectionPathParamsSchema = z.object({
  account_name: z.string(),
  connection_id: z.string(),
})

/**
 * @description Successful Response
 */
export const readConnection200Schema = z.union([
  z.lazy(() => bigQueryConnectionSchema),
  z.lazy(() => postgresConnectionSchema),
  z.lazy(() => redshiftConnectionSchema),
  z.lazy(() => snowflakeConnectionSchema),
  z.lazy(() => databricksConnectionSchema),
  z.lazy(() => teradataConnectionSchema),
  z.lazy(() => connectionSchema),
])

/**
 * @description Validation Error
 */
export const readConnection422Schema = z.lazy(() => httpValidationErrorSchema)

export const readConnectionQueryResponseSchema = z.lazy(() => readConnection200Schema)