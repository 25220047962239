// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useCreateDatabricksConnection.ts"
);
import.meta.hot.lastModified = "1737935769206.7388";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  CreateDatabricksConnectionMutationRequest,
  CreateDatabricksConnectionMutationResponse,
  CreateDatabricksConnectionPathParams,
  CreateDatabricksConnectionQueryParams,
  CreateDatabricksConnection422,
} from '../types/CreateDatabricksConnection'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const createDatabricksConnectionMutationKey = () => [{ url: '/api/v1/accounts/{account_name}/connections/databricks/' }] as const

export type CreateDatabricksConnectionMutationKey = ReturnType<typeof createDatabricksConnectionMutationKey>

/**
 * @description Create new Databricks connection.
 * @summary Create Databricks Connection
 * {@link /api/v1/accounts/:account_name/connections/databricks/}
 */
async function createDatabricksConnection(
  {
    accountName,
    data,
    params,
  }: {
    accountName: CreateDatabricksConnectionPathParams['account_name']
    data: CreateDatabricksConnectionMutationRequest
    params?: CreateDatabricksConnectionQueryParams
  },
  config: Partial<RequestConfig<CreateDatabricksConnectionMutationRequest>> = {},
) {
  const res = await client<CreateDatabricksConnectionMutationResponse, CreateDatabricksConnection422, CreateDatabricksConnectionMutationRequest>({
    method: 'POST',
    url: `/api/v1/accounts/${accountName}/connections/databricks/`,
    params,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Create new Databricks connection.
 * @summary Create Databricks Connection
 * {@link /api/v1/accounts/:account_name/connections/databricks/}
 */
export function useCreateDatabricksConnection(
  options: {
    mutation?: UseMutationOptions<
      CreateDatabricksConnectionMutationResponse,
      CreateDatabricksConnection422,
      {
        accountName: CreateDatabricksConnectionPathParams['account_name']
        data: CreateDatabricksConnectionMutationRequest
        params?: CreateDatabricksConnectionQueryParams
      }
    >
    client?: Partial<RequestConfig<CreateDatabricksConnectionMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? createDatabricksConnectionMutationKey()

  return useMutation<
    CreateDatabricksConnectionMutationResponse,
    CreateDatabricksConnection422,
    {
      accountName: CreateDatabricksConnectionPathParams['account_name']
      data: CreateDatabricksConnectionMutationRequest
      params?: CreateDatabricksConnectionQueryParams
    }
  >({
    mutationFn: async ({ accountName, data, params }) => {
      return createDatabricksConnection({ accountName, data, params }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}