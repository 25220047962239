// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/CellsService.ts"
);
import.meta.hot.lastModified = "1737759746328.098";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BadResponseAPICreate } from '../models/BadResponseAPICreate';
import type { Body_read_cell_data_api_v1_accounts__account_name__conversations__conversation_id__cells__cell_id__data_post } from '../models/Body_read_cell_data_api_v1_accounts__account_name__conversations__conversation_id__cells__cell_id__data_post';
import type { BumblebeeCell } from '../models/BumblebeeCell';
import type { BumblebeeCellAPICreate } from '../models/BumblebeeCellAPICreate';
import type { BumblebeeCellAPIUpdate } from '../models/BumblebeeCellAPIUpdate';
import type { BumblebeeCellBasic } from '../models/BumblebeeCellBasic';
import type { BumblebeeCellFilterOptionWithGroup } from '../models/BumblebeeCellFilterOptionWithGroup';
import type { BumblebeeCellUpdate } from '../models/BumblebeeCellUpdate';
import type { Chart } from '../models/Chart';
import type { ChartConfig } from '../models/ChartConfig';
import type { ChartConfigAPIUpdate } from '../models/ChartConfigAPIUpdate';
import type { DimensionFilter } from '../models/DimensionFilter';
import type { KnowledgeSQL } from '../models/KnowledgeSQL';
import type { MetricFilter } from '../models/MetricFilter';
import type { MetricView } from '../models/MetricView';
import type { NodeDataPage } from '../models/NodeDataPage';
import type { PromptLog } from '../models/PromptLog';
import type { PromptLogComponent } from '../models/PromptLogComponent';
import type { SemanticLayerView } from '../models/SemanticLayerView';
import type { TableFilter } from '../models/TableFilter';
import type { TagAPICreate } from '../models/TagAPICreate';
import type { Task } from '../models/Task';
import type { UnionStatistic } from '../models/UnionStatistic';
import type { UpdateSemanticLayerViewResponse } from '../models/UpdateSemanticLayerViewResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CellsService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read Cells
   * Read Bumblebee Cells for a conversation.
   * @param accountName
   * @param conversationId
   * @param limit
   * @param skip
   * @param withFeedback
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public readCells(
    accountName: string,
    conversationId: string,
    limit: number = 100,
    skip?: number,
    withFeedback: boolean = true,
    sessionId?: (string | null),
  ): CancelablePromise<Array<BumblebeeCell>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
        'with_feedback': withFeedback,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Cell
   * Create a new Bumblebee Cell.
   * @param accountName
   * @param conversationId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public createCell(
    accountName: string,
    conversationId: string,
    requestBody: BumblebeeCellAPICreate,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Cells Basic
   * Read Bumblebee Cells for a conversation.
   * @param accountName
   * @param conversationId
   * @param limit
   * @param skip
   * @param sessionId
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public readCellsBasic(
    accountName: string,
    conversationId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: (string | null),
  ): CancelablePromise<Array<BumblebeeCellBasic>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/basic',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Cell
   * Get Bumblebee Cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public readCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Cell
   * Update a Bumblebee Cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public updateCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: BumblebeeCellAPIUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Cell
   * Delete a Bumblebee Cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * @deprecated
   * Get Cell Metric Options
   * Get cell metric options.
   *
   * DEPRECATED. Use get_ssds_metrics instead.
   *
   * This represents the available metrics for the cell that could be manually added.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param limit
   * @param skip
   * @param sessionId
   * @returns MetricView Successful Response
   * @throws ApiError
   */
  public getCellMetricOptions(
    accountName: string,
    conversationId: string,
    cellId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: (string | null),
  ): CancelablePromise<Array<MetricView>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/metric_options',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Cell Filter Options
   * Get cell filter options.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCellFilterOptionWithGroup Successful Response
   * @throws ApiError
   */
  public getCellFilterOptions(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<BumblebeeCellFilterOptionWithGroup>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/filter_options',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Cell Admin Only
   * Update a Bumblebee Cell's question only.
   *
   * This is an admin endpoint. One use case is question-only update of sensitive cells.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public updateCellAdminOnly(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: BumblebeeCellUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/admin_only',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Refresh Bumblebee Cell
   * Refresh the bumblebee cell's data.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public refreshBumblebeeCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/refresh',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Cell Semanticlayerview
   * Update the semantic layer view of a cell.
   *
   * The `name` field of each Dimension or Metric in the `order` parameter should be prefixed
   * with '-' if the sort order is descending.
   *
   * Args:
   * skip_cell_refresh: instead of issuing a cell refresh, delete the underlying cell table and don't issue a new SQL query.
   * remove_feedback: remove any existing cell feedback when we update the cell. This defaults to false, but should be set to true for user edits.
   * Raises:
   * NSRuntimeError if the semantic layer view is invalid.
   * NSUnauthorizedError if the cell is verified and the user is not an admin.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param skipCellRefresh
   * @param removeFeedback
   * @param sessionId
   * @returns UpdateSemanticLayerViewResponse Successful Response
   * @throws ApiError
   */
  public updateCellSemanticlayerview(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: SemanticLayerView,
    skipCellRefresh: boolean = false,
    removeFeedback: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<UpdateSemanticLayerViewResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/semanticlayerview',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'skip_cell_refresh': skipCellRefresh,
        'remove_feedback': removeFeedback,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Tag
   * Add a tag to a bumblebee cell. Creates it if it does not exist.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public addTag(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: TagAPICreate,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/tags',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove Tag
   * Remove a tag from a bumblebee cell.
   * @param tagId
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public removeTag(
    tagId: string,
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/tags/{tag_id}',
      path: {
        'tag_id': tagId,
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Bad Response
   * Add bad repsonse to a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public addBadResponse(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: BadResponseAPICreate,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/bad_response',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove Bad Response
   * Remove bad responses from a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public removeBadResponse(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/bad_response',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Verify Cell
   * Add positive feedback to a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param allowConflictingFeedback
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public verifyCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    allowConflictingFeedback: boolean = false,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/verified',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'allow_conflicting_feedback': allowConflictingFeedback,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Unverify Cell
   * Add feedback to a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public unverifyCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/verified',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Prompt Knowledge Sql
   * Get the prompt knowledge sql from the semantic query.
   *
   * This is the knowledge sql that is used as demonstration for the prompt.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getPromptKnowledgeSql(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/prompt_knowledge_sql',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Knowledge Sql
   * Get the knowledge sql for the cell.
   *
   * When knowledge sql doesn't exist, create it from the bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getKnowledgeSql(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<(KnowledgeSQL | null)> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/knowledge_sql',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Mark Cell Sensitive
   * Add positive feedback to a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public markCellSensitive(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/is_user_sensitive',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Unmark Cell Sensitive
   * Unmark cell sensitive.
   *
   * If the cell uses DMs that are sensitive, it cannot be unmarked.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public unmarkCellSensitive(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/is_user_sensitive',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Verified Conflicts
   * Get list of cells with conflicting feedback.
   *
   * If there are any conflicting cells, the user should be notified before verifying the cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public getVerifiedConflicts(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<BumblebeeCellBasic>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/verified_conflicts',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Is Suggested
   * Mark cell as Suggestion
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param isSuggested
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public updateIsSuggested(
    accountName: string,
    conversationId: string,
    cellId: string,
    isSuggested: boolean,
    sessionId?: (string | null),
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/suggested',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'is_suggested': isSuggested,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Cell Filter
   * Read the specified filter.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param filterId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readCellFilter(
    accountName: string,
    conversationId: string,
    cellId: string,
    filterId: string,
    sessionId?: (string | null),
  ): CancelablePromise<(DimensionFilter | MetricFilter)> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/filters/{filter_id}',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
        'filter_id': filterId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Cell Filters
   * Get the filters for a cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readCellFilters(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Array<(DimensionFilter | MetricFilter)>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/filters',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Profile Cell Data
   * Profile the bumblebee cell table columns.
   *
   * Raises:
   * NSCannotReadNodeDataError: If the bumblebee cell table does not exist.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param limit
   * @param sessionId
   * @param requestBody
   * @returns UnionStatistic Successful Response
   * @throws ApiError
   */
  public profileCellData(
    accountName: string,
    conversationId: string,
    cellId: string,
    limit: number = 100000,
    sessionId?: (string | null),
    requestBody?: (Array<TableFilter> | null),
  ): CancelablePromise<Record<string, UnionStatistic>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/profile',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Cell Data
   * Get Bumblebee Cell Data.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param limit
   * @param skip
   * @param sessionId
   * @param requestBody
   * @returns NodeDataPage Successful Response
   * @throws ApiError
   */
  public readCellData(
    accountName: string,
    conversationId: string,
    cellId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: (string | null),
    requestBody?: Body_read_cell_data_api_v1_accounts__account_name__conversations__conversation_id__cells__cell_id__data_post,
  ): CancelablePromise<NodeDataPage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/data',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Cell Data As Csv
   * Read Cell data as a CSV.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readCellDataAsCsv(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/data/csv',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Cell Chart Config
   * Update Bumblebee Cell Chart Config.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param chartConfigId
   * @param requestBody
   * @param sessionId
   * @returns ChartConfig Successful Response
   * @throws ApiError
   */
  public updateCellChartConfig(
    accountName: string,
    conversationId: string,
    cellId: string,
    chartConfigId: string,
    requestBody: ChartConfigAPIUpdate,
    sessionId?: (string | null),
  ): CancelablePromise<ChartConfig> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/chart/{chart_config_id}',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
        'chart_config_id': chartConfigId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Read Cell Chart
   * Get Bumblebee Cell Chart Data.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param chartConfigId
   * @param sessionId
   * @returns Chart Successful Response
   * @throws ApiError
   */
  public readCellChart(
    accountName: string,
    conversationId: string,
    cellId: string,
    chartConfigId: string,
    sessionId?: (string | null),
  ): CancelablePromise<Chart> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/chart/{chart_config_id}',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
        'chart_config_id': chartConfigId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Cell Chart Config
   * Create a Bumblebee Cell Chart Config.
   *
   * Creating will infer the best chart configuration based on the semantic query.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns ChartConfig Successful Response
   * @throws ApiError
   */
  public createCellChartConfig(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<ChartConfig> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/chart',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Cell Tasks
   * Get all tasks for a cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param limit
   * @param skip
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getCellTasks(
    accountName: string,
    conversationId: string,
    cellId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/tasks',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
        'skip': skip,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Bb Cell Sql
   * Get SQL from a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getBbCellSql(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: (string | null),
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/sql',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Validate Cell Filter
   * Check that the filter is valid.
   *
   * Raises:
   * NSInvalidFilterSQLError if the filter leads to an error in the data warehouse.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public validateCellFilter(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: (DimensionFilter | MetricFilter),
    sessionId?: (string | null),
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/validate-filter',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Search Similar Verified Cells
   * Search for similar verified cells.
   *
   * NOTE: These are not the same cells used during retrieval when the cell was created.
   * This uses the same function to retrieve similar cells, but because this endpoint is
   * used each time the cell is loaded, it is possible to retrieve cells that are newly verified
   * and similar to the current cell (which the cell did not use when it was run.)
   *
   * Ideally we can make the BBRetrievedMetadata.retrieved_examples stateful and persisted
   * in the cell objects which would allow us to retrieve the same examples each time the cell is loaded.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param limit
   * @param sessionId
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public searchSimilarVerifiedCells(
    accountName: string,
    conversationId: string,
    cellId: string,
    limit: number = 5,
    sessionId?: (string | null),
  ): CancelablePromise<Array<BumblebeeCellBasic>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/search_similar',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'limit': limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Cell Promptlog
   * Admin endpoint to the promptlog history of a bumblebee cell.
   *
   * Component can be query to filter to that component.
   * Component of ALL will return unified promptlogs for an entire run
   * (this is not backwards compatible)
   * Component of None will return any component ordered by time and not unified.
   *
   * Returns:
   * This returns a JSON list of PromptLogs for a cell in reverse chronological order, by
   * default only the most recent. The component filters to return logs for everything or query.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param component
   * @param limit
   * @param sessionId
   * @returns PromptLog Successful Response
   * @throws ApiError
   */
  public getCellPromptlog(
    accountName: string,
    conversationId: string,
    cellId: string,
    component?: (PromptLogComponent | null),
    limit: number = 1,
    sessionId?: (string | null),
  ): CancelablePromise<Array<PromptLog>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/promptlog',
      path: {
        'account_name': accountName,
        'conversation_id': conversationId,
        'cell_id': cellId,
      },
      cookies: {
        'session_id': sessionId,
      },
      query: {
        'component': component,
        'limit': limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
