// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useListBigqueryDatasets.ts"
);
import.meta.hot.lastModified = "1737935768071.745";
}
// REMIX HMR END

import client from '~/client/kubb'
import type { ListBigqueryDatasetsQueryResponse, ListBigqueryDatasetsPathParams, ListBigqueryDatasets422 } from '../types/ListBigqueryDatasets'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const listBigqueryDatasetsQueryKey = ({
  accountName,
  connectionId,
}: {
  accountName: ListBigqueryDatasetsPathParams['account_name']
  connectionId: ListBigqueryDatasetsPathParams['connection_id']
}) =>
  [
    { url: '/api/v1/accounts/:account_name/connections/bigquery/:connection_id/datasets', params: { accountName: accountName, connectionId: connectionId } },
  ] as const

export type ListBigqueryDatasetsQueryKey = ReturnType<typeof listBigqueryDatasetsQueryKey>

/**
 * @description List BigQuery datasets.
 * @summary List Bigquery Datasets
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id/datasets}
 */
async function listBigqueryDatasets(
  { accountName, connectionId }: { accountName: ListBigqueryDatasetsPathParams['account_name']; connectionId: ListBigqueryDatasetsPathParams['connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ListBigqueryDatasetsQueryResponse, ListBigqueryDatasets422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/bigquery/${connectionId}/datasets`,
    ...config,
  })
  return res.data
}

export function listBigqueryDatasetsQueryOptions(
  { accountName, connectionId }: { accountName: ListBigqueryDatasetsPathParams['account_name']; connectionId: ListBigqueryDatasetsPathParams['connection_id'] },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = listBigqueryDatasetsQueryKey({ accountName, connectionId })
  return queryOptions({
    enabled: !!(accountName && connectionId),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return listBigqueryDatasets({ accountName, connectionId }, config)
    },
  })
}

/**
 * @description List BigQuery datasets.
 * @summary List Bigquery Datasets
 * {@link /api/v1/accounts/:account_name/connections/bigquery/:connection_id/datasets}
 */
export function useListBigqueryDatasets<
  TData = ListBigqueryDatasetsQueryResponse,
  TQueryData = ListBigqueryDatasetsQueryResponse,
  TQueryKey extends QueryKey = ListBigqueryDatasetsQueryKey,
>(
  { accountName, connectionId }: { accountName: ListBigqueryDatasetsPathParams['account_name']; connectionId: ListBigqueryDatasetsPathParams['connection_id'] },
  options: {
    query?: Partial<QueryObserverOptions<ListBigqueryDatasetsQueryResponse, ListBigqueryDatasets422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listBigqueryDatasetsQueryKey({ accountName, connectionId })

  const query = useQuery({
    ...(listBigqueryDatasetsQueryOptions({ accountName, connectionId }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ListBigqueryDatasets422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}