// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/readAccountsSchema.ts"
);
import.meta.hot.lastModified = "1737759758959.0327";
}
// REMIX HMR END

// @ts-nocheck
import { accountSchema } from './accountSchema'
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const readAccountsQueryParamsSchema = z
  .object({
    skip: z.number().int().default(0),
    limit: z.number().int().default(100),
    name: z.string().default(''),
  })
  .optional()

/**
 * @description Successful Response
 */
export const readAccounts200Schema = z.array(z.lazy(() => accountSchema))

/**
 * @description Validation Error
 */
export const readAccounts422Schema = z.lazy(() => httpValidationErrorSchema)

export const readAccountsQueryResponseSchema = z.lazy(() => readAccounts200Schema)