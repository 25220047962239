// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/streamedOutputQuestionApiSchema.ts"
);
import.meta.hot.lastModified = "1737759761867.0178";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Suggested question content.
 */
export const streamedOutputQuestionApiSchema = z
  .object({
    question: z.string(),
  })
  .describe('Suggested question content.')