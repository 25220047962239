// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/getAccountEnginesSchema.ts"
);
import.meta.hot.lastModified = "1737759760436.0251";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const getAccountEnginesPathParamsSchema = z.object({
  account_name: z.string(),
})

/**
 * @description Successful Response
 */
export const getAccountEngines200Schema = z.array(z.string())

/**
 * @description Validation Error
 */
export const getAccountEngines422Schema = z.lazy(() => httpValidationErrorSchema)

export const getAccountEnginesQueryResponseSchema = z.lazy(() => getAccountEngines200Schema)