// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/unionStatisticSchema.ts"
);
import.meta.hot.lastModified = "1737935766487.7537";
}
// REMIX HMR END

// @ts-nocheck
import { baseColumnStatisticsSchema } from './baseColumnStatisticsSchema'
import { boolColumnStatisticsSchema } from './boolColumnStatisticsSchema'
import { dateTimeColumnStatisticsSchema } from './dateTimeColumnStatisticsSchema'
import { mostCommonValueColumnStatisticsSchema } from './mostCommonValueColumnStatisticsSchema'
import { numericColumnStatisticsSchema } from './numericColumnStatisticsSchema'
import { stringColumnStatisticsSchema } from './stringColumnStatisticsSchema'
import { z } from 'zod'

/**
 * @description Union type to combine the different statistics types.
 */
export const unionStatisticSchema = z
  .union([
    z.lazy(() => stringColumnStatisticsSchema),
    z.lazy(() => numericColumnStatisticsSchema),
    z.lazy(() => dateTimeColumnStatisticsSchema),
    z.lazy(() => boolColumnStatisticsSchema),
    z.lazy(() => mostCommonValueColumnStatisticsSchema),
    z.lazy(() => baseColumnStatisticsSchema),
  ])
  .describe('Union type to combine the different statistics types.')