// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/errorContentSchema.ts"
);
import.meta.hot.lastModified = "1737935765797.7576";
}
// REMIX HMR END

// @ts-nocheck
import { errorInfoSchema } from './errorInfoSchema'
import { z } from 'zod'

/**
 * @description Content of an NSError in the form of a pydantic class.
 */
export const errorContentSchema = z
  .object({
    detail: z.string(),
    status_code: z.number().int(),
    errors: z.array(z.lazy(() => errorInfoSchema)),
    warnings: z.array(z.lazy(() => errorInfoSchema)),
    error_name: z.string(),
  })
  .describe('Content of an NSError in the form of a pydantic class.')