// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/conversationBasicSchema.ts"
);
import.meta.hot.lastModified = "1737935767429.7485";
}
// REMIX HMR END

// @ts-nocheck
import { permissionsSchema } from './permissionsSchema'
import { userSchema } from './userSchema'
import { z } from 'zod'

/**
 * @description Reduced properties to use when getting Conversations in a list.
 */
export const conversationBasicSchema = z
  .object({
    creator_id: z.union([z.string(), z.null()]).optional(),
    permissions: z.lazy(() => permissionsSchema).default({}),
    sync_id: z.union([z.string(), z.null()]).optional(),
    name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    account_id: z.union([z.string(), z.null()]).optional(),
    is_pinned: z.union([z.boolean(), z.null()]).default(false),
    is_eval_conversation: z.boolean().default(false),
    is_hidden: z.union([z.boolean(), z.null()]).default(false),
    is_moonlight: z.union([z.boolean(), z.null()]).default(false),
    is_training: z.union([z.boolean(), z.null()]).default(false),
    id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    creator: z.union([z.lazy(() => userSchema), z.null()]).optional(),
    is_starred: z.union([z.boolean(), z.null()]).optional(),
    bumblebee_cells_count: z.union([z.number().int(), z.null()]).optional(),
  })
  .describe('Reduced properties to use when getting Conversations in a list.')