// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/messageFeedbackFilterSchema.ts"
);
import.meta.hot.lastModified = "1737759761259.021";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Message feedback filter.
 */
export const messageFeedbackFilterSchema = z.enum(['True', 'False', 'None']).describe('Message feedback filter.')