// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/detailedStatsSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Detailed dimension/metric/filter statistics.
 */
export const detailedStatsSchema = z
  .object({
    deprecated: z.number().int().min(0),
    invalid: z.number().int().min(0),
    new: z.number().int().min(0),
    review: z.number().int().min(0),
    verified: z.number().int().min(0),
  })
  .describe('Detailed dimension/metric/filter statistics.')