// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadConnectionData.ts"
);
import.meta.hot.lastModified = "1737759760438.0251";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadConnectionDataQueryRequest,
  ReadConnectionDataQueryResponse,
  ReadConnectionDataPathParams,
  ReadConnectionDataQueryParams,
  ReadConnectionData422,
} from '../types/ReadConnectionData'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readConnectionDataQueryKey = (
  { accountName, connectionId }: { accountName: ReadConnectionDataPathParams['account_name']; connectionId: ReadConnectionDataPathParams['connection_id'] },
  params: ReadConnectionDataQueryParams,
  data?: ReadConnectionDataQueryRequest,
) =>
  [
    { url: '/api/v1/accounts/:account_name/connections/:connection_id/data', params: { accountName: accountName, connectionId: connectionId } },
    ...(params ? [params] : []),
    ...(data ? [data] : []),
  ] as const

export type ReadConnectionDataQueryKey = ReturnType<typeof readConnectionDataQueryKey>

/**
 * @description Read data from a data warehouse table without creating a node.
 * @summary Read Connection Data
 * {@link /api/v1/accounts/:account_name/connections/:connection_id/data}
 */
async function readConnectionData(
  {
    accountName,
    connectionId,
    params,
    data,
  }: {
    accountName: ReadConnectionDataPathParams['account_name']
    connectionId: ReadConnectionDataPathParams['connection_id']
    params: ReadConnectionDataQueryParams
    data?: ReadConnectionDataQueryRequest
  },
  config: Partial<RequestConfig<ReadConnectionDataQueryRequest>> = {},
) {
  const res = await client<ReadConnectionDataQueryResponse, ReadConnectionData422, ReadConnectionDataQueryRequest>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/${connectionId}/data`,
    params,
    data,
    ...config,
  })
  return res.data
}

export function readConnectionDataQueryOptions(
  {
    accountName,
    connectionId,
    params,
    data,
  }: {
    accountName: ReadConnectionDataPathParams['account_name']
    connectionId: ReadConnectionDataPathParams['connection_id']
    params: ReadConnectionDataQueryParams
    data?: ReadConnectionDataQueryRequest
  },
  config: Partial<RequestConfig<ReadConnectionDataQueryRequest>> = {},
) {
  const queryKey = readConnectionDataQueryKey({ accountName, connectionId }, params, data)
  return queryOptions({
    enabled: !!(accountName && connectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readConnectionData({ accountName, connectionId, params, data }, config)
    },
  })
}

/**
 * @description Read data from a data warehouse table without creating a node.
 * @summary Read Connection Data
 * {@link /api/v1/accounts/:account_name/connections/:connection_id/data}
 */
export function useReadConnectionData<
  TData = ReadConnectionDataQueryResponse,
  TQueryData = ReadConnectionDataQueryResponse,
  TQueryKey extends QueryKey = ReadConnectionDataQueryKey,
>(
  {
    accountName,
    connectionId,
    params,
    data,
  }: {
    accountName: ReadConnectionDataPathParams['account_name']
    connectionId: ReadConnectionDataPathParams['connection_id']
    params: ReadConnectionDataQueryParams
    data?: ReadConnectionDataQueryRequest
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadConnectionDataQueryResponse, ReadConnectionData422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig<ReadConnectionDataQueryRequest>>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readConnectionDataQueryKey({ accountName, connectionId }, params, data)

  const query = useQuery({
    ...(readConnectionDataQueryOptions({ accountName, connectionId, params, data }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadConnectionData422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}