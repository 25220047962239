// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/logoutSessionSchema.ts"
);
import.meta.hot.lastModified = "1737935766268.755";
}
// REMIX HMR END

// @ts-nocheck
import { httpValidationErrorSchema } from './httpValidationErrorSchema'
import { z } from 'zod'

export const logoutSessionQueryParamsSchema = z
  .object({
    third_party: z.boolean().default(false).describe('Whether to set a third-party cookie for cross-origin iframes.'),
  })
  .optional()

/**
 * @description Successful Response
 */
export const logoutSession200Schema = z.unknown()

/**
 * @description Validation Error
 */
export const logoutSession422Schema = z.lazy(() => httpValidationErrorSchema)

export const logoutSessionMutationResponseSchema = z.lazy(() => logoutSession200Schema)