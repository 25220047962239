// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/tableFilterSchema.ts"
);
import.meta.hot.lastModified = "1737759758413.0356";
}
// REMIX HMR END

// @ts-nocheck
import { tableFilterConditionSchema } from './tableFilterConditionSchema'
import { z } from 'zod'

/**
 * @description Table filter model.
 */
export const tableFilterSchema = z
  .object({
    conditions: z.array(z.lazy(() => tableFilterConditionSchema)),
    conjunction: z.enum(['AND', 'OR']).default('AND'),
  })
  .describe('Table filter model.')