// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/sqlValidationApiCreateSchema.ts"
);
import.meta.hot.lastModified = "1737759761448.02";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Properties of SqlValidationBase.
 */
export const sqlValidationApiCreateSchema = z
  .object({
    sql: z.string(),
    name: z.union([z.string(), z.null()]).optional(),
    display_name: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
  })
  .describe('Properties of SqlValidationBase.')