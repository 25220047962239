// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/types/BumblebeeDatasetUploadOptions.ts"
);
import.meta.hot.lastModified = "1737935769122.7393";
}
// REMIX HMR END

import type { DataAssetVisibility } from './DataAssetVisibility'
import type { M2MTokenCreds } from './M2MTokenCreds'
import type { StarSchemaDataSourceEnrichment } from './StarSchemaDataSourceEnrichment'

export enum BumblebeeDatasetUploadOptionsExistingDataset {
  'clear' = 'clear',
  'ignore' = 'ignore',
  'reuse' = 'reuse',
}

/**
 * @description Options for BumblebeeDataset Upload.
 */
export type BumblebeeDatasetUploadOptions = {
  /**
   * @default "ignore"
   * @type string | undefined
   */
  existing_dataset?: BumblebeeDatasetUploadOptionsExistingDataset
  /**
   * @default false
   * @type boolean | undefined
   */
  skip_validation_check?: boolean
  /**
   * @default [object Object]
   */
  enrich_params?: StarSchemaDataSourceEnrichment
  visibility?: DataAssetVisibility | null
  /**
   * @default false
   * @type boolean | undefined
   */
  allow_conflicting_feedback?: boolean
  /**
   * @default false
   * @type boolean | undefined
   */
  clear_named_filters?: boolean
  /**
   * @default false
   * @type boolean | undefined
   */
  clear_cells?: boolean
  /**
   * @default false
   * @type boolean | undefined
   */
  only_upload_conversations?: boolean
  /**
   * @default false
   * @type boolean | undefined
   */
  mark_as_demo?: boolean
  m2m_creds?: M2MTokenCreds | null
}