// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/zod/starSchemaDataSourceTableOrViewInfoSchema.ts"
);
import.meta.hot.lastModified = "1737759762548.0144";
}
// REMIX HMR END

// @ts-nocheck
import { z } from 'zod'

/**
 * @description Star schema data source table or view definition.
 */
export const starSchemaDataSourceTableOrViewInfoSchema = z
  .object({
    name: z.string(),
    data_source_id: z.union([z.string(), z.null()]).optional(),
    sync_id: z.union([z.string(), z.null()]).optional(),
    description: z.union([z.string(), z.null()]).optional(),
    sql: z.union([z.string(), z.null()]).optional(),
    db: z.union([z.string(), z.null()]).optional(),
    db_schema: z.union([z.string(), z.null()]).optional(),
    table: z.union([z.string(), z.null()]).optional(),
    index: z.number().int().default(0),
  })
  .describe('Star schema data source table or view definition.')