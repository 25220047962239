// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useReadSnowflakeConnectionTables.ts"
);
import.meta.hot.lastModified = "1737759762296.0156";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  ReadSnowflakeConnectionTablesQueryResponse,
  ReadSnowflakeConnectionTablesPathParams,
  ReadSnowflakeConnectionTablesQueryParams,
  ReadSnowflakeConnectionTables422,
} from '../types/ReadSnowflakeConnectionTables'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestConfig } from '~/client/kubb'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const readSnowflakeConnectionTablesQueryKey = (
  {
    accountName,
    snowflakeConnectionId,
  }: {
    accountName: ReadSnowflakeConnectionTablesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionTablesPathParams['snowflake_connection_id']
  },
  params: ReadSnowflakeConnectionTablesQueryParams,
) =>
  [
    {
      url: '/api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/tables',
      params: { accountName: accountName, snowflakeConnectionId: snowflakeConnectionId },
    },
    ...(params ? [params] : []),
  ] as const

export type ReadSnowflakeConnectionTablesQueryKey = ReturnType<typeof readSnowflakeConnectionTablesQueryKey>

/**
 * @description Read Snowflake tables.
 * @summary Read Snowflake Connection Tables
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/tables}
 */
async function readSnowflakeConnectionTables(
  {
    accountName,
    snowflakeConnectionId,
    params,
  }: {
    accountName: ReadSnowflakeConnectionTablesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionTablesPathParams['snowflake_connection_id']
    params: ReadSnowflakeConnectionTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const res = await client<ReadSnowflakeConnectionTablesQueryResponse, ReadSnowflakeConnectionTables422, unknown>({
    method: 'GET',
    url: `/api/v1/accounts/${accountName}/connections/snowflake/${snowflakeConnectionId}/tables`,
    params,
    ...config,
  })
  return res.data
}

export function readSnowflakeConnectionTablesQueryOptions(
  {
    accountName,
    snowflakeConnectionId,
    params,
  }: {
    accountName: ReadSnowflakeConnectionTablesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionTablesPathParams['snowflake_connection_id']
    params: ReadSnowflakeConnectionTablesQueryParams
  },
  config: Partial<RequestConfig> = {},
) {
  const queryKey = readSnowflakeConnectionTablesQueryKey({ accountName, snowflakeConnectionId }, params)
  return queryOptions({
    enabled: !!(accountName && snowflakeConnectionId && params),
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return readSnowflakeConnectionTables({ accountName, snowflakeConnectionId, params }, config)
    },
  })
}

/**
 * @description Read Snowflake tables.
 * @summary Read Snowflake Connection Tables
 * {@link /api/v1/accounts/:account_name/connections/snowflake/:snowflake_connection_id/tables}
 */
export function useReadSnowflakeConnectionTables<
  TData = ReadSnowflakeConnectionTablesQueryResponse,
  TQueryData = ReadSnowflakeConnectionTablesQueryResponse,
  TQueryKey extends QueryKey = ReadSnowflakeConnectionTablesQueryKey,
>(
  {
    accountName,
    snowflakeConnectionId,
    params,
  }: {
    accountName: ReadSnowflakeConnectionTablesPathParams['account_name']
    snowflakeConnectionId: ReadSnowflakeConnectionTablesPathParams['snowflake_connection_id']
    params: ReadSnowflakeConnectionTablesQueryParams
  },
  options: {
    query?: Partial<QueryObserverOptions<ReadSnowflakeConnectionTablesQueryResponse, ReadSnowflakeConnectionTables422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? readSnowflakeConnectionTablesQueryKey({ accountName, snowflakeConnectionId }, params)

  const query = useQuery({
    ...(readSnowflakeConnectionTablesQueryOptions({ accountName, snowflakeConnectionId, params }, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ReadSnowflakeConnectionTables422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}