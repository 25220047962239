// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/kubb/hooks/useDeleteNamedFilters.ts"
);
import.meta.hot.lastModified = "1737935767656.7473";
}
// REMIX HMR END

import client from '~/client/kubb'
import type {
  DeleteNamedFiltersMutationRequest,
  DeleteNamedFiltersMutationResponse,
  DeleteNamedFiltersPathParams,
  DeleteNamedFilters422,
} from '../types/DeleteNamedFilters'
import type { RequestConfig, UseMutationOptions } from '~/client/kubb'
import { useMutation } from '~/client/kubb'

export const deleteNamedFiltersMutationKey = () =>
  [{ url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters' }] as const

export type DeleteNamedFiltersMutationKey = ReturnType<typeof deleteNamedFiltersMutationKey>

/**
 * @description Delete the specified named filter from the ssds.
 * @summary Delete Named Filters
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters}
 */
async function deleteNamedFilters(
  {
    accountName,
    starSchemaDataSourceId,
    data,
  }: {
    accountName: DeleteNamedFiltersPathParams['account_name']
    starSchemaDataSourceId: DeleteNamedFiltersPathParams['star_schema_data_source_id']
    data?: DeleteNamedFiltersMutationRequest
  },
  config: Partial<RequestConfig<DeleteNamedFiltersMutationRequest>> = {},
) {
  const res = await client<DeleteNamedFiltersMutationResponse, DeleteNamedFilters422, DeleteNamedFiltersMutationRequest>({
    method: 'DELETE',
    url: `/api/v1/accounts/${accountName}/star_schema_data_source/${starSchemaDataSourceId}/filters`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Delete the specified named filter from the ssds.
 * @summary Delete Named Filters
 * {@link /api/v1/accounts/:account_name/star_schema_data_source/:star_schema_data_source_id/filters}
 */
export function useDeleteNamedFilters(
  options: {
    mutation?: UseMutationOptions<
      DeleteNamedFiltersMutationResponse,
      DeleteNamedFilters422,
      {
        accountName: DeleteNamedFiltersPathParams['account_name']
        starSchemaDataSourceId: DeleteNamedFiltersPathParams['star_schema_data_source_id']
        data?: DeleteNamedFiltersMutationRequest
      }
    >
    client?: Partial<RequestConfig<DeleteNamedFiltersMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? deleteNamedFiltersMutationKey()

  return useMutation<
    DeleteNamedFiltersMutationResponse,
    DeleteNamedFilters422,
    {
      accountName: DeleteNamedFiltersPathParams['account_name']
      starSchemaDataSourceId: DeleteNamedFiltersPathParams['star_schema_data_source_id']
      data?: DeleteNamedFiltersMutationRequest
    }
  >({
    mutationFn: async ({ accountName, starSchemaDataSourceId, data }) => {
      return deleteNamedFilters({ accountName, starSchemaDataSourceId, data }, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}